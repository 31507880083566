import {createSlice} from '@reduxjs/toolkit'
import {
    benefitModel,
    bestSellerModel,
    claimModel,
    csrModel,
    flashSaleModel,
    recommendationModel,
    rubiModel,
    seoModel,
    seriesModel,
    shopByCategoryModel,
    sliderModel,
    sustainabilityModel,
    testimonialModel,
    theCarouselModel
} from "../models/main-page.model";

export const mainPageSlice = createSlice({
    name: 'mainPage',
    initialState: {
        sliderDetail: {...sliderModel},
        seriesDetail: {...seriesModel},
        flashSaleDetail: {...flashSaleModel},
        bestSellerDetail: {...bestSellerModel},
        recommendationDetail: {...recommendationModel},
        testimonialDetail: {...testimonialModel},
        theCarouselDetail: {...theCarouselModel},
        shopByCategoryDetail: {...shopByCategoryModel},
        benefitDetail: {...benefitModel},
        sustainabilityDetail: {...sustainabilityModel},
        rubiDetail: {...rubiModel},
        csrDetail: {...csrModel},
        claimDetail: {...claimModel},
        seoDetail: {...seoModel},
        mainPageErrorMessage: {},
    },
    reducers: {
        setMainPageSliderDetail: (state, action) => {
            state.sliderDetail = {...state.sliderDetail, ...action.payload}
        },
        setMainPageSeriesDetail: (state, action) => {
            state.seriesDetail = {...state.seriesDetail, ...action.payload}
        },
        setMainPageFlashSaleDetail: (state, action) => {
            state.flashSaleDetail = {...state.flashSaleDetail, ...action.payload}
        },
        setMainPageBestSellerDetail: (state, action) => {
            state.bestSellerDetail = {...state.bestSellerDetail, ...action.payload}
        },
        setMainPageRecommendationDetail: (state, action) => {
            state.recommendationDetail = {...state.recommendationDetail, ...action.payload}
        },
        setMainPageTestimonialDetail: (state, action) => {
            state.testimonialDetail = {...state.testimonialDetail, ...action.payload}
        },
        setMainPageTheCarouselDetail: (state, action) => {
            state.theCarouselDetail = {...state.theCarouselDetail, ...action.payload}
        },
        setMainPageShopByCategoryDetail: (state, action) => {
            state.shopByCategoryDetail = {...state.shopByCategoryDetail, ...action.payload}
        },
        setMainPageBenefitDetail: (state, action) => {
            state.benefitDetail = {...state.benefitDetail, ...action.payload}
        },
        setMainPageSustainabilityDetail: (state, action) => {
            state.sustainabilityDetail = {...state.sustainabilityDetail, ...action.payload}
        },
        setMainPageRubiDetail: (state, action) => {
            state.rubiDetail = {...state.rubiDetail, ...action.payload}
        },
        setMainPageCsrDetail: (state, action) => {
            state.csrDetail = {...state.csrDetail, ...action.payload}
        },
        setMainPageClaimDetail: (state, action) => {
            state.claimDetail = {...state.claimDetail, ...action.payload}
        },
        setMainPageSeoDetail: (state, action) => {
            state.seoDetail = {...state.seoDetail, ...action.payload}
        },
        setMainPageErrorMessage: (state, action) => {
            state.mainPageErrorMessage = action.payload
        }
    },
})

export const {
    setMainPageSliderDetail,
    setMainPageSeriesDetail,
    setMainPageFlashSaleDetail,
    setMainPageBestSellerDetail,
    setMainPageRecommendationDetail,
    setMainPageTestimonialDetail,
    setMainPageTheCarouselDetail,
    setMainPageShopByCategoryDetail,
    setMainPageBenefitDetail,
    setMainPageSustainabilityDetail,
    setMainPageRubiDetail,
    setMainPageCsrDetail,
    setMainPageClaimDetail,
    setMainPageSeoDetail,
    setMainPageErrorMessage
} = mainPageSlice.actions
const mainPageReducer = mainPageSlice.reducer

export default mainPageReducer