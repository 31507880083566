import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_TERMS_AND_CONDITION} from "../../utils/input-field-list-static-page";
import InputComponent from "../../components/input.component";
import React, {useEffect} from "react";
import {
    setMoreInfoDetail,
    setMoreInfoErrorMessage,
    setMoreInfoInitialMoreInfoDetail
} from "../../reducers/more-info.reducer";
import {cloneDeep} from "lodash";

const ReturnPolicyPage = () => {
    const dispatch = useDispatch()
    const {moreInfoDetail, moreInfoErrorMessage} = useSelector(state => state.moreInfoReducer)
    let fieldList = FIELD_LIST_TERMS_AND_CONDITION

    const onChangeInput = (key, value) => {
        dispatch(setMoreInfoDetail({[key]: value}))
    }

    useEffect(() => {
        return () => {
            dispatch(setMoreInfoErrorMessage({}))
            dispatch(setMoreInfoInitialMoreInfoDetail())
        }
    }, [])

    const dataChecking = (field, value) => {
        dispatch(setMoreInfoErrorMessage({}))
        const moreInfoDetailCopy = cloneDeep(moreInfoDetail)
        let error = {}
        field.forEach((item) => {
            switch (item.key) {
                case 'content':
                    if (!moreInfoDetailCopy[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                default:
                    if (item.required && !value[item.key]?.value) {
                        error[item.key] = `${item.label} is required`
                    } else {
                        moreInfoDetailCopy[item.key] = value[item.key]?.value || null
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setMoreInfoErrorMessage({}))
            return {isValid: true, returnPolicy: moreInfoDetailCopy}
        } else {
            dispatch(setMoreInfoErrorMessage(error))
            return {isValid: false, returnPolicy: moreInfoDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let formValue = document.getElementById("form").elements
        const {isValid, returnPolicy} = dataChecking([...fieldList], formValue)
        if(isValid){
            console.log(returnPolicy)
        }
    }

    return (
        <div className={"col px-10"}>
            <form id={"form"} className={"space-y-4"}>
                {fieldList.map((item, index) => (
                    <InputComponent key={index} item={item} data={moreInfoDetail[item.key]} onChange={onChangeInput} error={moreInfoErrorMessage}/>
                ))}
                <div className={"row font-semibold justify-center py-8"}>
                    <button onClick={(e) => onSubmitHandler(e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        Save All Changes
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ReturnPolicyPage
