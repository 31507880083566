import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRoleIds} from "../../reducers/roles.reducer";

export const AccessAllItem = ({allItem = []}) => {
    const dispatch = useDispatch()
    const {selectedIds} = useSelector(state => state.roleReducer)
    const allItemIds = allItem.map(i => i?.id)
    
    const checkedHandler = (e) => {
        let newSelectedIds = []
        const isChecked = e?.target?.checked
        if (!isChecked) {
            newSelectedIds = selectedIds.filter(i => !allItemIds.includes(i))
        } else {
            newSelectedIds = [...selectedIds, ...allItemIds]
        }
        dispatch(setSelectedRoleIds(newSelectedIds))
    }

    return (
        <label
            className={"row items-center space-x-5 border border-pinball h-8 rounded-md px-3 font-semibold text-forged-steel hover:border-monstera hover:bg-doctor hover:font-Montserrat-Bold hover:text-monstera cursor-pointer"}>
            <input type={"checkbox"} className={"accent-monstera"} checked={allItemIds.every(i => selectedIds.includes(i))}
                   onChange={checkedHandler}/>
            <p className={"capitalize"}>Select All</p>
        </label>
    )
}
