import {createSlice} from '@reduxjs/toolkit'
import {avostoreAreaModel} from "../models/avostore.model";

export const areaSlice = createSlice({
    name: 'area',
    initialState: {
        selectedRowKeysArea: [],
        areaDetail: {...avostoreAreaModel},
        areaErrorMessage: {},
        modalVisibility: {
            addArea: false,
            editArea: false
        }
    },
    reducers: {
        setAreaSelectedRowKeys: (state, action) => {
            state.selectedRowKeysArea = action.payload
        },
        setAreaDetail: (state, action) => {
            state.areaDetail = {...state.areaDetail, ...action.payload}
        },
        setAreaErrorMessage: (state, action) => {
            state.areaErrorMessage = action.payload
        },
        setAreaModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
        setAreaInitialAreaDetail: (state, action) => {
            state.areaDetail = {...avostoreAreaModel}
        }
    },
})

export const {
    setAreaSelectedRowKeys,
    setAreaDetail,
    setAreaErrorMessage,
    setAreaModalVisibility,
    setAreaInitialAreaDetail
} = areaSlice.actions
const areaReducer = areaSlice.reducer

export default areaReducer
