import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES} from "../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import {setAvoStoriesCampaignBannerDetail, setAvoStoriesErrorMessage} from "../../reducers/avo-stories.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const CampaignBanner = () => {
    const dispatch = useDispatch()
    const {campaignBannerDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)
    const {fetching: getCampaignBanner} = useFetch(API.AVO_STORIES_GET_CAMPAIGN_BANNER)
    const {fetching: updateCampaignBanner, isLoading} = useFetch(API.AVO_STORIES_UPDATE_CAMPAIGN_BANNER)
    let fieldList = FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES
    let showCampaignBanner = {
        label: 'Campaign Banner',
        key: 'show_campaign_banner',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStoriesCampaignBannerDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateCampaignBanner(campaignBannerDetail).then(response => {
            dispatch(setAvoStoriesErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Campaign Banner Updated'))
            dispatch(setAvoStoriesCampaignBannerDetail(response?.data))
        }).catch(err => {
            dispatch(setAvoStoriesErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getCampaignBanner().then(response => {
            dispatch(setAvoStoriesCampaignBannerDetail(response?.data))
        })

        return () => {
            dispatch(setAvoStoriesErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={campaignBannerDetail}
            switchToggleField={showCampaignBanner}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={avoStoriesErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default CampaignBanner
