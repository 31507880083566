import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_CREATE_GIFT} from "../../utils/input-field-list";
import React, {useEffect} from "react";
import {setGiftErrorMessage, setGiftInitialGiftDetail} from "../../reducers/gift.reducer";
import TabToggleComponent from "../../components/tab-toggle.component";
import {LoadingSvg} from "../../utils/image";
import UploadImageForm from "../create-product/upload-image-form";
import InformationForm from "./information-form";
import {cloneDeep} from "lodash";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";

const CreateGiftPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const {giftDetail} = useSelector(state => state.giftReducer)
    const {fetching: postCreateGift, isLoading: loadingCreateGift} = useFetch(API.GIFT_CREATE_GIFT)
    const {fetching: postUpdateGift, isLoading: loadingUpdateGift} = useFetch({...API.GIFT_UPDATE_GIFT, ...{initPathVariable: id}})
    const {fetching: postSaveAsDraft, isLoading: loadingSaveAsDraft} = useFetch({...API.GIFT_SAVE_AS_DRAFT_GIFT, ...{initPathVariable: id}})
    let informationField = FIELD_LIST_CREATE_GIFT

    useEffect(() => {
        return () => {
            dispatch(setGiftErrorMessage({}))
            dispatch(setGiftInitialGiftDetail())
        }
    }, [])

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        navigate('/product/gift-list')
    }

    const dataChecking = (field, value, buttonType) => {
        dispatch(setGiftErrorMessage({}))
        const giftDetailCopy = cloneDeep(giftDetail)
        let error = {}
        field.forEach((item) => {
            switch (true) {
                case item.key === 'image_url' && !giftDetail[item.key]:
                case item.required && !value[item.key]?.value:
                    return error[item.key] = `${item.label} is required`
                case item.type === 'number':
                    return giftDetailCopy[item.key] = value.namedItem(item.key)?.value ? Number(value.namedItem(item.key)?.value) : null
                case item.key === 'image_url':
                    return giftDetailCopy[item.key] = giftDetail[item.key]
                default:
                    return giftDetailCopy[item.key] = value[item.key]?.value || null
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setGiftErrorMessage({}))
            return {isValid: true, gift: giftDetailCopy}
        } else if (buttonType === 'draft') {
            dispatch(setGiftErrorMessage({name: error?.name}))
            return {isValid: !error?.name, gift: giftDetailCopy}
        } else {
            dispatch(setGiftErrorMessage(error))
            return {isValid: false, gift: giftDetailCopy}
        }
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = [...informationField[0], ...informationField[1], ...informationField[2]]
        let formValue = document.getElementById("form").elements
        const {isValid, gift} = dataChecking(field, formValue, buttonType)
        if (isValid) {
            let requestBody = {
                ...gift,
                available_for_birthday_gift: gift?.available_for_birthday_gift.toString() === 'true'
            }

            getPromiseApi(buttonType, requestBody).then(() => {
                showAlertModal(getSuccessMessage(buttonType))
            }).catch(err => {
                dispatch(setGiftErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getSuccessMessage = (type) => {
        switch (type) {
            case 'publish':
                return 'Gift Published'
            case 'update':
                return 'Gift Updated'
            default:
                return 'Gift Drafted'
        }
    }

    const getPromiseApi = (type, requestBody) => {
        switch (type) {
            case 'publish':
                return postCreateGift(requestBody)
            case 'update':
                return postUpdateGift(requestBody)
            default:
                return postSaveAsDraft(requestBody)
        }
    }

    return (
        <div className={"col px-10 mt-4"}>
            <form id={"form"} className={"col space-y-4 mb-10"}>
                <TabToggleComponent
                    title={"Informasi Gift"}
                    content={<InformationForm fieldList={informationField}/>}
                />
                <div className={"row font-semibold justify-center space-x-4 py-8"}>
                    <button onClick={(e) => onSubmitHandler('draft', e)}
                            className={"w-60 py-2 border border-fortress-grey hover:text-white hover:bg-dazzle rounded-lg"}>
                        {loadingSaveAsDraft ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save As Draft'}
                    </button>
                    <button onClick={(e) => onSubmitHandler(!!id ? 'update' : 'publish', e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        {loadingCreateGift || loadingUpdateGift ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : !!id ? 'Update' : 'Publish'}
                    </button>
                </div>
            </form>
            <UploadImageForm/>
        </div>
    )
}

export default CreateGiftPage
