import React, {useRef, useState} from "react";
import {LoadingSvg, TrashIcon, UploadIcon, UserProfileDefault} from "../../utils/image";
import InputComponent from "../../components/input.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {setUserDetail, setUserProfileImage} from "../../reducers/user.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {RULES_IMAGE_UPLOAD} from "../../utils/constant";
import {imageValidate} from "../../utils/general-variable";

export const CreateUserForm = ({ fieldList }) => {
    const dispatch = useDispatch()
    const [imageProfile, setImageProfile] = useState(UserProfileDefault)
    const {userErrorMessage, userDetail} = useSelector(state => state.userReducer)
    const {fetching: uploadPicture, isLoading} = useFetch(API.USER_UPLOAD_PROFILE_PICTURE)
    const imageUploaded = useRef()

    const uploadImageProfile = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        const {isValid, message} = imageValidate(file)

        if (file) reader.readAsDataURL(file)

        if (isValid) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    uploadPicture({image: file}, null, {'Content-Type': 'multipart/form-data'}).then(response => {
                        const url = response?.data?.url
                        setImageProfile(url)
                        dispatch(setUserProfileImage(url))
                    })
                    imageUploaded.current.value = null
                }
            }
        } else {
            showAlertModal(message)
        }
    }

    const deleteImageProfile = () => {
        setImageProfile(UserProfileDefault)
        dispatch(setUserProfileImage(null))
        imageUploaded.current.value = null
    }

    const onChangeInput = (key, value) => {
        dispatch(setUserDetail({[key]: value}))
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }

    return (
        <div className={"grid grid-cols-4 px-10"}>
            <div className={"space-y-5 col items-center"}>
                <p className={"font-semibold"}>Foto Profil</p>
                {
                    isLoading ? <img src={LoadingSvg} className={"w-20 mx-auto"}/> :
                        <div className={"relative w-56 h-56"}>
                            <img src={imageProfile} className={"rounded-full w-56 h-56 border-8 border-monstera"}/>
                            <div
                                className={"w-8 h-8 absolute bg-white top-0 right-5 rounded-full col justify-center items-center border"}>
                                <img src={TrashIcon} className={"w-5 h-5 cursor-pointer"} onClick={deleteImageProfile}/>
                            </div>
                        </div>
                }
                <label
                    className={"cursor-pointer row items-center space-x-3 bg-battleship-green hover:bg-monstera mt-3 py-2 px-4 rounded-md"}>
                    <img src={UploadIcon} className={"w-4"}/>
                    <p className={"font-Montserrat-Bold text-white"}>Unggah</p>
                    <input type={"file"}
                           onChange={uploadImageProfile}
                           className={"hidden"}
                           ref={imageUploaded}
                    />
                </label>
            </div>
            <div className={"col-span-2 space-y-5 pl-10"}>
                {
                    fieldList.map((item, index) => <InputComponent item={item} key={index} data={userDetail[item.key]} error={userErrorMessage} onChange={onChangeInput}/>)
                }
            </div>
        </div>
    )
}
