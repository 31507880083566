import {FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import InputField from "./input-field";
import {setMiraculousRefiningSeriesResultDetail} from "../../../reducers/miraculous-refining-series.reducer";
import React from "react";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const Result = () => {
    const dispatch = useDispatch()
    const {resultDetail} = useSelector(state => state.miraculousRefiningSeriesReducer)
    let fieldList = FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES
    let showResult = {
        label: 'Result',
        key: 'show_result',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'Input Field',
            content: <InputField fieldList={fieldList} />
        }
    ]

    const onChange = (key, value) => {
        dispatch(setMiraculousRefiningSeriesResultDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(resultDetail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={resultDetail}
            switchToggleField={showResult}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default Result