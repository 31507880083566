import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {FIELD_LIST_RECYCLING_PROGRESS_AVO_STORIES} from "../../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import FormItemComponent from "../../../components/static-page/form-item.component";
import {setAvoStoriesErrorMessage, setAvoStoriesRecyclingProgressDetail} from "../../../reducers/avo-stories.reducer";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {cloneDeep} from "lodash";
import ItemRecyclingProgress from "./item-recycling-progress";

const RecyclingProgress = () => {
    const dispatch = useDispatch()
    const {recyclingProgressDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)
    const {fetching: getRecyclingProgress} = useFetch(API.AVO_STORIES_GET_RECYCLING_PROGRESS)
    const {fetching: updateRecyclingProgress, isLoading} = useFetch(API.AVO_STORIES_UPDATE_RECYCLING_PROGRESS)
    const itemList = [...recyclingProgressDetail.item_list]
    let fieldList = FIELD_LIST_RECYCLING_PROGRESS_AVO_STORIES
    let showRecyclingProgress = {
        label: 'Recycling Progress',
        key: 'show_recycling_progress',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStoriesRecyclingProgressDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'recycling_progress').toString(),
            title: null,
            description: null
        })
        dispatch(setAvoStoriesRecyclingProgressDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(recyclingProgressDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataRecyclingProgress = {...recyclingProgressDetail, item_list: dataItemList}
        updateRecyclingProgress(dataRecyclingProgress).then(response => {
            dispatch(setAvoStoriesErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Recycling Progress Updated'))
            dispatch(setAvoStoriesRecyclingProgressDetail({...response?.data, delete_ids: []}))
        }).catch(err => {
            dispatch(setAvoStoriesErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getRecyclingProgress().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setAvoStoriesRecyclingProgressDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setAvoStoriesRecyclingProgressDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setAvoStoriesErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={recyclingProgressDetail}
            switchToggleField={showRecyclingProgress}
            itemList={itemList}
            content={(index) => <ItemRecyclingProgress fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setAvoStoriesRecyclingProgressDetail}
            deleteList={recyclingProgressDetail?.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            errorMessage={avoStoriesErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default RecyclingProgress
