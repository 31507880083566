export const mainBannerModel = {
    show_main_banner: true,
    title: null,
    description: null,
    desktop_image: null,
    mobile_image: null,
    title_button: null,
    link_direction_button: null
}

export const sustainabilityProgressModel = {
    show_sustainability_progress: true,
    title: null,
    item_list: [{
        id: 'sustainability_progress',
        title: null,
        description: null
    }],
    delete_item: []
}

export const campaignVideoModel = {
    show_campaign_video: true,
    title: null,
    description: null,
    video_url: null
}

export const campaignBannerModel = {
    show_campaign_banner: true,
    title: null,
    item_list: [{
        id: 'campaign_banner',
        title: null,
        description: null,
        desktop_image: null,
        mobile_image: null
    }],
    delete_item: []
}

export const formModel = {
    show_form: true,
    title: null,
    description: null,
    desktop_image: null,
    mobile_image: null
}

export const articlesModel = {
    show_articles: true,
    title: null,
    description: null,
    articles: []
}
