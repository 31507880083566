import {createSlice} from '@reduxjs/toolkit'
import {notificationModel} from "../models/notification.model";

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        selectedRowKeysNotification: [],
        notificationDetail: {...notificationModel},
        notificationErrorMessage: {},
        initRoutingCustom: {
            routing_order: null,
            routing_category_ids: [],
            routing_product_ids: [],
            routing_is_discounted: null,
            routing_product_id: null
        },
        initRoutingList: {
            routing_link: null,
            routing_product_id: null
        },
        initRoutingDetail: {
            routing_link: null,
            routing_order: null,
            routing_category_ids: [],
            routing_product_ids: [],
            routing_is_discounted: null
        },
        initReceiverAll: {
            receiver_emails: [],
            receiver_product_ids: [],
            receiver_start_date: null,
            receiver_end_date: null,
            receiver_order_times: null
        },
        initReceiverEmail: {
            receiver_product_ids: [],
            receiver_start_date: null,
            receiver_end_date: null,
            receiver_order_times: null
        },
        initReceiverOrder: {
            receiver_emails: []
        }
    },
    reducers: {
        setNotificationSelectedRowKeys: (state, action) => {
            state.selectedRowKeysNotification = action.payload
        },
        setNotificationDetail: (state, action) => {
            state.notificationDetail = {...state.notificationDetail, ...action.payload}
        },
        setNotificationInitialNotificationDetail: (state) => {
            state.notificationDetail = {...notificationModel}
        },
        setNotificationErrorMessage: (state, action) => {
            state.notificationErrorMessage = action.payload
        },
    },
})

export const {
    setNotificationSelectedRowKeys,
    setNotificationDetail,
    setNotificationInitialNotificationDetail,
    setNotificationErrorMessage
} = notificationSlice.actions
const notificationReducer = notificationSlice.reducer

export default notificationReducer
