export const introModel = {
    show_intro: true,
    title: null,
    description: null,
}

export const videoModel = {
    show_video: true,
    title: null,
    description: null,
    url: null,
}

export const section1Model = {
    show_section_1: true,
    description: null,
    desktop_banner: null,
    mobile_banner: null
}

export const section2Model = {
    show_section_2: true,
    description: null,
    desktop_banner: null,
    mobile_banner: null
}

export const articlesModel = {
    show_articles: true,
    item_list: [{
        id: 'articles',
        thumbnail: null,
        title: null,
        date: null,
        url: null
    }],
    delete_ids: []
}
