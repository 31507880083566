import {useDispatch, useSelector} from "react-redux";
import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import React from "react";
import {setAvoStoriesUserSelectedRowKeys} from "../../reducers/avo-stories-user.reducer";

const AvoStoriesUserList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysAvoStoriesUser} = useSelector(state => state.avoStoriesUserReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Name',
            classNameHeader: 'pl-5',
            dataIndex: 'fullname',
            className: 'text-forged-steel capitalize pl-5'
        }, {
            name: 'Email',
            dataIndex: 'email',
            className: 'text-forged-steel'
        }, {
            name: 'No. HP',
            dataIndex: 'phone_number',
            className: 'text-forged-steel'
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setAvoStoriesUserSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Users"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysAvoStoriesUser}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default AvoStoriesUserList
