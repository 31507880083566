import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMainPageCsrDetail} from "../../../reducers/main-page.reducer";

const ItemCsr = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {csrDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(csrDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMainPageCsrDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={csrDetail?.item_list?.[index]?.[item.key]} error={mainPageErrorMessage[`item_list[${index}]`] ?? {}} onChange={onChangeInput} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default ItemCsr