import {useDispatch, useSelector} from "react-redux";
import {setOrderAdvanceFilter} from "../../reducers/order.reducer";
import {TAB_LIST_ORDER} from "../../utils/constant";

const OrderTab = () => {
    const dispatch = useDispatch()
    const {advanceFilter} = useSelector(state => state.orderReducer)

    const onChangeTab = (key) => {
        dispatch(setOrderAdvanceFilter({status: key === 'all' ? [] : [key]}))
    }

    return (
        <div className={"border-b border-placebo"}>
            <div className={"overflow-x-scroll mx-10 -mb-px"}>
                <div className={"row space-x-4 items-center text-forged-steel mt-5 font-semibold"}>
                    {
                        TAB_LIST_ORDER.map((item, index) => (
                                <div key={index} onClick={() => onChangeTab(item.key)} className={`row justify-center py-3 w-[128px] flex-shrink-0
                            ${((advanceFilter.status.length === 0 || advanceFilter.status.length > 1) ? item.key === 'all' : advanceFilter.status[0] === item.key) && 'rounded-t-lg border-x border-t border-placebo bg-white'} cursor-pointer`}>
                                    <p className={`overflow-hidden ${((advanceFilter.status.length === 0 || advanceFilter.status.length > 1) ? item.key === 'all' : advanceFilter.status[0] === item.key) && 'text-monstera font-Montserrat-Bold'}`}>{item.label}</p>
                                </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default OrderTab
