import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNotificationDetail, setNotificationErrorMessage} from "../../reducers/notification.reducer";

const GeneralContentForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {notificationDetail, notificationErrorMessage} = useSelector(state => state.notificationReducer)

    const checkErrorSchedule = (value) => {
        const HourMinute = value?.split(" ")[1]
        const minute = HourMinute?.split(":")[1]
        if (!value || minute % 10 === 0 || minute === 0) {
            dispatch(setNotificationErrorMessage({...notificationErrorMessage, ...{scheduled_at: null}}))
        } else {
            dispatch(setNotificationErrorMessage({...notificationErrorMessage, ...{scheduled_at: 'Minute must be insert interval 10 minute, example: XX:10'}}))
        }
    }
    const onChange = (key, value) => {
        if (key === 'scheduled_at') {
            checkErrorSchedule(value)
        }
        dispatch(setNotificationDetail({[key]: value}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[0].map((item, index) => (
                    <InputComponent key={index} item={item} onChange={onChange} onChangeInput={onChange} data={notificationDetail[item.key]}
                                    error={notificationErrorMessage}/>
                ))}
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, index) => (
                    <InputComponent key={index} item={item} onChange={onChange} onChangeDate={onChange} data={notificationDetail[item.key]}
                                    error={notificationErrorMessage}/>
                ))}
            </div>
        </div>
    )
}

export default GeneralContentForm
