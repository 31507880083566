import SearchComponent from "../../components/search.component";
import {SORT_LIST_PRODUCT_SOLD} from "../../utils/sort-list";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";

const ProductFilter = () => {
    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"cari product / kode sku..."}/>
                <div className={"row space-x-3"}>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <SortComponent sortList={SORT_LIST_PRODUCT_SOLD}/>
                </div>
            </div>
        </div>
    )
}

export default ProductFilter