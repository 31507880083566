import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLevelAndBenefitDetail} from "../../reducers/level-and-benefit.reducer";
import {cloneDeep} from "lodash";

const ContentItem = ({fieldList, index, itemRef}) => {
    const dispatch = useDispatch()
    const {levelAndBenefitDetail, levelAndBenefitErrorMessage} = useSelector(state => state.levelAndBenefitReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(levelAndBenefitDetail?.items)
        copyItemList[index][key] = value
        dispatch(setLevelAndBenefitDetail({items: copyItemList}))
    }

    const filterData = (item) => {
        if (item.key === 'min_total_order_amount' && levelAndBenefitDetail?.items?.[index]?.['min_total_order_amount'] === 0) {
            return '0'
        } else {
            return levelAndBenefitDetail?.items?.[index]?.[item.key]
        }
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4 p-5"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item}
                                data={item.type === 'number' ? filterData(item) : levelAndBenefitDetail?.items?.[index]?.[item.key]}
                                onChangeInput={onChangeInput} onChangeNumber={onChangeInput}
                                disabled={item.key === 'max_total_order_amount' && levelAndBenefitDetail?.items?.length === index + 1}
                                inputRef={el => item.key === 'max_total_order_amount' ? itemRef.current[index] = el : null}
                                error={levelAndBenefitErrorMessage[`items[${index}]`] ?? {}}/>
            ))}
        </div>
    )
}

export default ContentItem
