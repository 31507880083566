import React, {useRef, useState} from "react";
import {LoadingSvg} from "../../utils/image";
import InputComponent from "../../components/input.component";
import {FIELD_LIST_AUTH} from "../../utils/input-field-list";
import {checkFieldValidate} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CONFIG from "../../config/env";
import Verification from "./verification";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCaptcha, setToolsModalVisibility} from "../../reducers/tools.reducer";

const AuthPage = () => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const {captcha} = useSelector(state => state.toolsReducer)
    const [errorCaptcha, setErrorCaptcha] = useState('')
    const [fieldList, setFieldList] = useState(FIELD_LIST_AUTH)
    const {fetching: postLogin, isLoading} = useFetch(API.LOGIN)

    const setCaptcha = (value) => {
        dispatch(setToolsCaptcha(value))
    }

    const loginHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const body = {
            email: elements.email.value,
            password: elements.password.value,
            recaptcha_token: captcha
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements) && !!captcha
        if (!isValid) {
            setFieldList(fieldItemsCopy)
            !!captcha ? setErrorCaptcha('') : setErrorCaptcha('Invalid Captcha')
        } else {
            postLogin(body).then(response => {
                setErrorCaptcha('')
                localStorage.setItem('sessid_token', response?.data?.sessid_token)
                dispatch(setToolsModalVisibility({verification: true}))
            }).catch(() => {
                dispatch(setToolsCaptcha(''))
                recaptchaRef?.current?.reset()
            })
        }
    }

    return (
        <>
            <div
                className={"col space-y-8 items-center justify-center pb-14 w-screen h-screen bg-gradient-to-b from-monstera to-toad"}>
                <Link to={'/'}>
                    <p className={"font-Montserrat-Bold text-white"}>AVOSKIN ADMIN DASHBOARD</p>
                </Link>
                <div
                    className={"w-[480px] border rounded-xl border-placebo p-20 col items-center space-y-10 text-xs text-monstera bg-white shadow-xl"}>
                    <p className={"font-regular text-4xl"}>Log In</p>
                    <form onSubmit={loginHandler} className={"col items-center w-full space-y-10"}>
                        <div className={"col space-y-5 w-full"}>
                            {fieldList.map((item, index) => (
                                <InputComponent key={index} item={item} error={{[item.key]: item.errorText}}/>
                            ))}
                        </div>
                        <Link to={'/forgot-password'}>
                            <p className={"font-bold"}>Lupa Password</p>
                        </Link>
                        <ReCAPTCHA ref={recaptchaRef} sitekey={CONFIG.GOOGLE_RECAPTCHA_SITE_KEY}
                                   onChange={(e) => setCaptcha(e)}
                                   onExpired={() => setCaptcha('')}/>
                        {errorCaptcha && <p className={"font-semibold text-red-600 italic"}>{errorCaptcha}</p>}
                        <button
                            type={"submit"} disabled={isLoading}
                            className={"bg-toad text-white font-Montserrat-Bold w-1/2 h-8 rounded-full hover:bg-monstera"}>
                            {isLoading ? <img src={LoadingSvg} className={"w-7 mx-auto"}/> : 'Masuk'}
                        </button>
                    </form>
                </div>
            </div>
            <Verification recaptchaRef={recaptchaRef}/>
        </>
    )
}

export default AuthPage
