import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {TrashIconWhite} from "../../utils/image";
import {setToolsAlertMessage, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {setBirthdayGiftSelectedId} from "../../reducers/birthday-gift.reducer";

const GiftItem = (props) => {
    const dispatch = useDispatch()
    const {title, content, index, dataList = [], setDataList} = props
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const [indexItemOnDelete, setIndexItemOnDelete] = useState(null)
    const dataListCopy = [...dataList]

    const openDeleteModal = () => {
        setIndexItemOnDelete(index)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus gift yang anda pilih?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const onDeleteItem = () => {
        const updateItem = dataListCopy.filter((_,i) => i !== indexItemOnDelete)
        dispatch(setBirthdayGiftSelectedId(updateItem?.map(i => i?.gift?.id)))
        dispatch(setDataList({items: updateItem}))
        dispatch(setToolsModalVisibility({delete: false}))
    }

    useEffect(() => {
        if (!modalVisibility.delete) {
            setIndexItemOnDelete(null)
        }
    }, [modalVisibility.delete])

    return (
        <div>
            <div
                className={"col font-regular border border-fennel-stem rounded-lg"}>
                <div className={"row items-center justify-between p-2 rounded-lg"}>
                    <div className={"row space-x-5 items-center w-3/4"}>
                        <p className={"text-sm text-monstera font-Montserrat-Bold capitalize w-16"}>{title}</p>
                        <div className={"w-full"}>
                            {content}
                        </div>
                    </div>
                    <div className={"row items-center space-x-4"}>
                        <div
                            className={"row items-center space-x-2 font-semibold text-white w-fit cursor-pointer p-2 bg-spiced-coral hover:bg-glowing-scarlet border border-placebo rounded-lg"}
                            onClick={() => openDeleteModal(index)}>
                            <img src={TrashIconWhite} alt="TrashIcon" className={"w-5"}/>
                            <p>Delete Gift</p>
                        </div>
                    </div>
                </div>
            </div>
            {indexItemOnDelete === index && <DeleteConfirmationComponent onDelete={onDeleteItem}/>}
        </div>
    )
}

export default GiftItem
