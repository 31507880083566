import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    statusRenderClassName
} from "../../utils/general-variable";
import {setProductSelectedRowKeys} from "../../reducers/product.reducer";
import {Link} from "react-router-dom";
import React from "react";
import {ProductGiftDefaultIcon} from "../../utils/image";

const ProductList = ({datasource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysProduct} = useSelector(state => state.productReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Product',
            width: 250,
            classNameHeader: 'pl-5',
            render: (record) => (
                <div className={"row items-center space-x-3 pl-5"}>
                    <img src={record?.image?.url || ProductGiftDefaultIcon} className={"w-10 h-10 rounded-lg"} alt={record?.image?.alt_text}/>
                    <Link to={`/product/product-list/update-product/${record.id}`}>
                        <p className={"text-monstera capitalize hover:font-Montserrat-Bold hover:underline"}>{record.name}</p>
                    </Link>
                </div>
            )
        }, {
            name: 'SKU',
            width: 200,
            dataIndex: 'sku',
            classNameHeader: 'text-center',
            render: (sku) => (
                <p className={"text-center font-regular text-forged-steel"}>{sku}</p>
            )
        },
        {
            name: 'Category',
            width: 300,
            dataIndex: 'categories',
            render: (categories) => (
                <p className={"font-regular text-forged-steel"}>{categories}</p>
            )
        }, {
            name: 'Price',
            render: (record) => (
                <div className={"col space-y-2 font-regular text-forged-steel"}>
                    <p className={!!record.sale_price ? 'text-[10px] line-through' : 'text-xs'}>{doConvertNumberToRupiahFormat(record.price)}</p>
                    {!!record.sale_price && <p className={"font-semibold"}>{doConvertNumberToRupiahFormat(record.sale_price)}</p>}
                </div>
            )
        },
        {
            name: 'Stock',
            dataIndex: 'stock_quantity',
            classNameHeader: 'text-center',
            render: (stock_quantity) => (
                <p className={"text-center font-regular text-forged-steel"}>
                    {stock_quantity}
                </p>
            )
        },
        {
            name: 'Last Modified',
            dataIndex: 'updated_at',
            classNameHeader: 'text-center',
            render: (updated_at) => (
                <p className={"text-center font-regular text-forged-steel"}>{convertDateToCustomFormat(updated_at, "YYYY/MM/DD HH:mm")}</p>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            classNameHeader: 'text-center',
            render: (status) => (
                <div className={"row justify-center"}>
                    <div
                        className={`w-20 py-1 font-Montserrat-Bold capitalize rounded-md 
                        ${statusRenderClassName(status).className}`}>
                        <p className={"text-center"}>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setProductSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Products"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysProduct}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={datasource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default ProductList
