import {useDispatch, useSelector} from "react-redux";
import {convertDateToCustomFormat} from "../../../utils/general-variable";
import {setUserActiveTab, setUserReferralSelectedRowKeys} from "../../../reducers/user.reducer";
import TotalDataComponent from "../../../components/total-data.component";
import TableComponent from "../../../components/table-component";
import React from "react";
import {Link} from "react-router-dom";

export const ReferralList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysUserReferral} = useSelector(state => state.userReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'items-center',
        },
        {
            name: 'Nama User',
            width: 350,
            classNameHeader: 'pl-5',
            render: (record) => (
                <div className={"row items-center pl-3 space-x-10 text-forged-steel"}>
                    <Link to={`/user/user-list/user-detail/${record.id}`} onClick={() => dispatch(setUserActiveTab('profile'))}>
                        <p className={"hover:font-Montserrat-Bold hover:underline"}>{record.fullname}</p>
                    </Link>
                </div>
            )
        }, {
            name: 'Tanggal Penggunaan Link',
            classNameHeader: 'text-center',
            dataIndex: 'joined_at',
            render: (joined_at) => (
                <p className={"text-forged-steel text-center"}>{convertDateToCustomFormat(joined_at, "DD/MM/YYYY hh:mm")}</p>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setUserReferralSelectedRowKeys(array))
    }

    return (
        <>
            <TotalDataComponent subject={'Referral'}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                selectedRowKeys={selectedRowKeysUserReferral}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                pagination={true}
                loading={isLoading}
            />
        </>
    )
}
