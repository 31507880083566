import {createSlice} from '@reduxjs/toolkit'

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        currentPage: 1,
        pageSize: 20,
        totalData: 0,
        searchKey: '',
        orderBy: null,
        sort: 'desc',
        filter: [],
        filterCity: [],
        loading: false,
        dateRange: [null, null],
        modalVisibility: {
            sort: false,
            limit: false,
            filter: false,
            filterCity: false,
            dateRange: false,
            alert: false,
            confirmation: false,
            delete: false,
            verification: false,
            export: false,
            toast: false
        },
        alertMessage: '',
        alertType: '',
        isAlertImportFailed: false,
        captcha: '',
        reload: false
    },
    reducers: {
        setToolsCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setToolsPageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setToolsFilter: (state, action) => {
            state.filter = action.payload
        },
        setToolsFilterCity: (state, action) => {
            state.filterCity = action.payload
        },
        setToolsTotalData: (state, action) => {
            state.totalData = action.payload
        },
        setToolsSearchKey: (state, action) => {
            state.searchKey = action.payload
        },
        setToolsOrderBy: (state, action) => {
            state.orderBy = action.payload
        },
        setToolsSort: (state, action) => {
            state.sort = action.payload
        },
        setToolsStartDateEndDate: (state, action) => {
            state.dateRange = action.payload
        },
        setToolsLoading: (state, action) => {
            state.loading = action.payload
        },
        setToolsModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
        setToolsAlertMessage: (state, action) => {
            state.alertMessage = action.payload
        },
        setToolsAlertType: (state, action) => {
            state.alertType = action.payload
        },
        setToolsIsAlertImportFailed: (state, action) => {
            state.isAlertImportFailed = action.payload
        },
        setToolsCaptcha: (state, action) => {
            state.captcha = action.payload
        },
        setToolsReload: (state, action) => {
            state.reload = action.payload
        },
        setToolsInitialState: (state) => {
            state.currentPage = 1
            state.pageSize = 20
            state.searchKey = ''
            state.sort = 'desc'
            state.orderBy = null
            state.filter = []
            state.filterCity = []
            state.dateRange = [null, null]
        }
    },
})

export const {
    setToolsCurrentPage,
    setToolsPageSize,
    setToolsFilter,
    setToolsFilterCity,
    setToolsTotalData,
    setToolsSearchKey,
    setToolsOrderBy,
    setToolsSort,
    setToolsStartDateEndDate,
    setToolsLoading,
    setToolsModalVisibility,
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsIsAlertImportFailed,
    setToolsReload,
    setToolsCaptcha,
    setToolsInitialState
} = toolsSlice.actions
const toolsReducer = toolsSlice.reducer

export default toolsReducer
