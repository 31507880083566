import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import {
    setBeautyFridayPromotedProductDetail
} from "../../../reducers/beauty-friday.reducer";
import InputComponent from "../../../components/input.component";
import React from "react";

const ItemPromotedProduct = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {promotedProductDetail, beautyFridayErrorMessage} = useSelector(state => state.beautyFridayReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(promotedProductDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setBeautyFridayPromotedProductDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={promotedProductDetail?.item_list?.[index]?.[item.key]} error={beautyFridayErrorMessage[`item_list[${index}]`] ?? {}} onChange={onChangeInput} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default ItemPromotedProduct