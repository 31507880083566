import {setUserAddressDetail, setUserModalVisibility} from "../../../reducers/user.reducer";
import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "../../../components/modal-card.component";
import {CloseIconGreen, LoadingSvg} from "../../../utils/image";
import React, {useEffect, useRef, useState} from "react";
import {FIELD_LIST_EDIT_ADDRESS} from "../../../utils/input-field-list";
import InputComponent from "../../../components/input.component";
import {checkFieldValidate, convertErrorMessage} from "../../../utils/general-variable";
import {useBiteship} from "../../../services/useBiteship";
import {API} from "../../../services/api";
import {useFetch} from "../../../services/useFetch";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../../reducers/tools.reducer";

const EditAddressModal = () => {
    const dispatch = useDispatch()
    const refForm = useRef(null)
    const {modalVisibility, userAddressDetail} = useSelector(state => state.userReducer)
    const {reload} = useSelector(state => state.toolsReducer)
    const [fieldList, setFieldList] = useState(FIELD_LIST_EDIT_ADDRESS)
    const {fetching: searchArea} = useBiteship(API.SEARCH_AREA)
    const {fetching: postUpdateAddress, isLoading} = useFetch(API.USER_UPDATE_ADDRESS)
    const [keyword, setKeyword] = useState('')

    const onClose = () => {
        refForm.current.reset()
        setFieldList(fieldList.map(i => {
            return {...i, errorText: null}
        }))
        dispatch(setUserModalVisibility({editAddress: false}))
    }

    const onChangeSelect = ({key}) => {
        searchArea({}, key).then(response => {
            dispatch(setUserAddressDetail({zipcode: response?.areas[0]?.postal_code}))
            setFieldList(fieldList.map(item => {
                const dataZipcode = response?.areas?.map(area => {
                    return {label: area.postal_code.toString(), key: area.id}
                })
                return item.key === 'zipcode' ? {...item, ...{options: dataZipcode}} : item
            }))
        })
    }

    const onChangeSearch = (key) => {
        setKeyword(key)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const elements = e.target
        const fieldItemsCopy = [...fieldList]
        const dataConvert = elements['city_and_district'].value.split(',')
        const body = {
            title: elements.title.value,
            name: elements.name.value,
            phone_number: `62${elements.phone_number.value}`,
            address: elements.address.value,
            district: dataConvert[0],
            city: dataConvert[1],
            province: dataConvert[2],
            zipcode: elements.zipcode.value,
            note: elements.note.value || null,
            is_main_address: !!elements?.is_main_address?.value ? elements.is_main_address?.value === 'true' : true
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (isValid) {
            postUpdateAddress(body, userAddressDetail?.id).then(() => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Address Updated'))
                dispatch(setToolsReload(!reload))
                onClose()
            }).catch(err => {
                setFieldList(convertErrorMessage(err, fieldList))
            })
        } else {
            setFieldList(fieldItemsCopy)
        }
    }

    useEffect(() => {
        const payload = {
            countries: 'ID',
            input: keyword,
            type: 'double'
        }
        searchArea(payload).then(response => {
            setFieldList(fieldList.map(item => {
                const dataAddress = response?.areas?.map(area => {
                    return {label: area.name, key: area.id}
                })
                return item.key === 'city_and_district' ? {...item, ...{options: dataAddress}} : item
            }))
        })
    }, [keyword])

    useEffect(() => {
        return () => {
            setFieldList(fieldList.map(item => delete item.errorText))
        }
    }, [])

    return (
        <ModalCardComponent
            visibility={modalVisibility.editAddress}
            onClose={onClose}
            content={
                <div className={"absolute bg-white w-2/5 rounded-lg p-5 space-y-7"}>
                    <div className={"row justify-between items-center"}>
                        <div/>
                        <p className={"font-Montserrat-Bold text-monstera"}>Edit Alamat</p>
                        <img src={CloseIconGreen} className={"w-4 cursor-pointer"} onClick={onClose}/>
                    </div>
                    <form onSubmit={onSubmitHandler} className={"space-y-7"} ref={refForm}>
                        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
                            {fieldList.map((item, index) => (
                                <div key={index}
                                     className={`${(item.key === 'title' || item.key === 'is_main_address') && 'col-span-2'}`}>
                                    {item.key !== 'is_main_address' ?
                                        <InputComponent item={item}
                                                        data={modalVisibility.editAddress ? (item.key === 'city_and_district' || item.key === 'zipcode') ? userAddressDetail : userAddressDetail[item.key] : null}
                                                        onChangeSelect={item.key === 'city_and_district' && onChangeSelect}
                                                        onChangeSearch={item.key === 'city_and_district' && onChangeSearch}
                                                        error={{[item.key]: item.errorText}}/>
                                        : !userAddressDetail['is_main_address'] && <InputComponent item={item}/>}
                                </div>
                            ))}
                        </div>
                        <div className={"row justify-center"}>
                            <button
                                className={"rounded-md shadow-inner bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-Montserrat-Bold px-10 py-3"}>
                                {isLoading ? <img src={LoadingSvg} className={"w-4 mx-auto"}/> : 'Simpan'}
                            </button>
                        </div>
                    </form>
                </div>
            }
        />
    )
}

export default EditAddressModal
