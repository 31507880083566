import React, {useEffect} from "react";
import {FilterUser} from "./filter";
import {UserList} from "./user-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setUserSelectedRowKeys} from "../../reducers/user.reducer";
import {formatDateToReadable} from "../../utils/general-variable";
import {SORT_LIST_USER} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const UserPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_USER?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        dateRange,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
        filterByStatus: filter['status'] ?? [],
        filterByRole: filter['role'] ?? [],
        startDate: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
        endDate: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
    }
    const {
        data: response,
        fetching: getUserList,
        isLoading
    } = useFetch(API.USER_LIST, queryParams, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setUserSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getUserList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, dateRange, filter, reload])

    return (
        <div className={"col px-10"}>
            <FilterUser/>
            <UserList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default UserPage