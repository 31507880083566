import {PlusCircleIconWhite, TrashIcon} from "../../utils/image";
import React, {useEffect, useState} from "react";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import TextAreaComponent from "../../components/text-area.component";

const TodoListComponent = (props) => {
    const {item, onChangeTodoList, data, error} = props
    const dispatch = useDispatch()
    const [description, setDescription] = useState('')
    const [list, setList] = useState(data ?? [])

    const onChangeHandler = (key, value) => {
        setDescription(value)
    }

    const addListHandler = () => {
        if (description) {
            setList([...list, {description}])
        } else {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('warning'))
            dispatch(setToolsAlertMessage(`${item.label} can't be empty`))
        }
    }

    const deleteListHandler = (index) => {
        setList(list.filter((_, i) => i !== index))
    }

    useEffect(() => {
        onChangeTodoList(item.key, list)
    }, [list])

    return (
        <div className={"col space-y-6"}>
            <div className={"col space-y-2 w-full"}>
                <label htmlFor={item.key} className={"font-semibold text-monstera"}>{item.label}</label>
                <TextAreaComponent keyName={item.key} placeholder={item.placeholder} defaultValue={description} onChangeInput={onChangeHandler}/>
                <div
                    className={"row space-x-2 items-center w-fit bg-battleship-green hover:bg-monstera py-2 px-6 rounded-lg cursor-pointer"}
                    onClick={addListHandler}>
                    <img src={PlusCircleIconWhite} alt="PlusCircleIconWhite" className={"w-5"}/>
                    <p className={"font-semibold text-white"}>Tambah List {item.label}</p>
                </div>
            </div>
            <div className={`col space-y-2 w-full ${list.length > 0 ? 'block' : 'hidden'}`}>
                <p className={"font-semibold text-monstera"}>List {item.label}</p>
                {list.map((l, index) => (
                    <div key={index} className={"row justify-between items-center text-forged-steel border p-4 rounded-lg"}>
                        <div className={"col space-y-2 w-11/12"}>
                            <p className={"font-Montserrat-Bold"}>{item.label} {index+1}</p>
                            <p>{list[index]['description']}</p>
                        </div>
                        <div className={"row justify-center items-center space-x-2 cursor-pointer w-1/12"} onClick={() => deleteListHandler(index)}>
                            <img src={TrashIcon} alt="TrashIcon" className={"w-5"}/>
                            <p className={"font-semibold"}>Delete</p>
                        </div>
                    </div>
                ))}
            </div>
            {error[item.key] && <p className={"font-semibold text-red-600 italic"}>{error[item.key]}</p>}
        </div>

    )
}

export default TodoListComponent
