export const birthdayGiftModel = {
    items: [{
        discount_amount: null,
        gift: {
            id: null,
            name: null,
            image: {
                url: null,
                alt_text: null
            }
        }
    }]
}
