import React from "react";
import {EditIcon, KeyIcon} from "../../../utils/image";
import {useDispatch} from "react-redux";
import {setUserModalVisibility} from "../../../reducers/user.reducer";

export const Accessibilty = ({details, data}) => {
    const dispatch = useDispatch()

    const showModalHandler = (key) => {
      dispatch(setUserModalVisibility({[key]: true}))
    }

    return (
        <div className={"border rounded-md border-fennel-stem divide-y"}>
            <div className={"row items-center px-5 py-2 justify-between rounded-t-md bg-doctor"}>
                <p className={"font-Montserrat-Bold text-lg text-monstera"}>Accessibility</p>
                <button className={"row items-center space-x-2 bg-battleship-green hover:bg-monstera p-2 rounded-md"} onClick={() => showModalHandler('editAccess')}>
                    <img src={EditIcon} className={"w-4"}/>
                    <p className={"text-white font-bold text-xs"}>Edit Access</p>
                </button>
            </div>
            <div className={"px-10 py-5 space-y-5"}>
                {
                    details.map((item, index) => (
                        <div className={"row items-center"} key={index}>
                            <p className={"w-2/5 text-monstera font-Montserrat-Bold capitalize"}>{item}</p>
                            <p className={"w-3/5 text-forged-steel capitalize font-Montserrat-Bold"}>{data[item]}</p>
                        </div>
                    ))
                }
            </div>
            <div className={"px-5 pt-2 space-y-4"}>
                <p className={"font-Montserrat-Bold text-lg text-monstera"}>Change Password</p>
                <button className={"row items-center space-x-2 py-1 bg-battleship-green hover:bg-monstera w-2/5 rounded-md justify-center"} onClick={() => showModalHandler('changePassword')}>
                    <img src={KeyIcon} className={"w-4"}/>
                    <p className={"text-white font-bold text-[10px]"}>Ubah Password</p>
                </button>
            </div>
        </div>
    )
}
