import React from "react";

export const Tab = ({data, onChange, activeTab}) => {
    return (
        <div className={"row"}>
            <div className={"w-4 border-b"}/>
            {
                data.map((item, index) => (
                    <button onClick={() => onChange(item)} key={index}
                            className={`${activeTab === item ? 'border-x border-t text-monstera rounded-t-md' : ' border-b text-forged-steel'} font-Montserrat-Bold capitalize w-28 py-2`}>{item}</button>
                ))
            }
            <div className={"flex-1 border-b"}/>
        </div>
    )
}
