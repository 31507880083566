import {createSlice} from '@reduxjs/toolkit'
import {introModel, miraculousItemModel, relatedProductModel} from "../models/miraculous-series.model";

export const miraculousSeriesSlice = createSlice({
    name: 'miraculousSeries',
    initialState: {
        introDetail: {...introModel},
        miraculousItemDetail: {...miraculousItemModel},
        relatedProductDetail: {...relatedProductModel},
        miraculousSeriesErrorMessage: {},
    },
    reducers: {
        setMiraculousSeriesIntroDetail: (state, action) => {
            state.introDetail = {...state.introDetail, ...action.payload}
        },
        setMiraculousSeriesMiraculousItemDetail: (state, action) => {
            state.miraculousItemDetail = {...state.miraculousItemDetail, ...action.payload}
        },
        setMiraculousSeriesRelatedProductDetail: (state, action) => {
            state.relatedProductDetail = {...state.relatedProductDetail, ...action.payload}
        },
        setMiraculousSeriesErrorMessage: (state, action) => {
            state.miraculousSeriesErrorMessage = action.payload
        }
    },
})

export const {
    setMiraculousSeriesIntroDetail,
    setMiraculousSeriesMiraculousItemDetail,
    setMiraculousSeriesRelatedProductDetail,
    setMiraculousSeriesErrorMessage
} = miraculousSeriesSlice.actions
const miraculousSeriesReducer = miraculousSeriesSlice.reducer

export default miraculousSeriesReducer