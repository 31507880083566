import TabToggleComponent from "../../components/tab-toggle.component";
import React from "react";
import GraphAnalyticsComponent from "../../components/graph-analytics.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabRevenueGraph = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const queryParams = {
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
    }
    const {
        data: response,
        fetching: getAnalyticRevenueGraph,
        isLoading
    } = useFetch(API.ANALYTIC_REVENUE_GRAPH, queryParams)

    useEffectAfterMount(() => {
        getAnalyticRevenueGraph()
    }, [dateRange, reload])

    return (
        <TabToggleComponent
            title={"Revenue Graph"}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <GraphAnalyticsComponent title={'Revenue'} slug={'revenue'} dataList={response?.data ?? []}/>
            }
        />
    )
}

export default TabRevenueGraph