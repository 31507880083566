import {Link, useLocation} from "react-router-dom";
import {ChevronRight} from "../../utils/image";
import {BREADCRUMB} from "../../utils/constant";

export const Breadcrumb = ({data}) => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const pathMainMenu = data['SIDEBAR'].find((l, i) => l.label === data['BREADCRUMB'][pathRaw[1]])

    return (
        <div className={"row items-center text-monstera space-x-3"}>
            <Link to={`${pathRaw[1]}/${pathMainMenu?.child[0]?.path}`}>
                <p className={"font-Montserrat-Bold text-base"}>{data['BREADCRUMB'][pathRaw[1]]}</p>
            </Link>
            {pathRaw[2] && <img src={ChevronRight} className={"h-3"}/>}
            <Link to={`/${pathRaw[1]}/${pathRaw[2]}`}>
                <p>{data['BREADCRUMB'][pathRaw[2]]}</p>
            </Link>
            {pathRaw[3] && <img src={ChevronRight} className={"h-3"}/>}
            <p>{data['BREADCRUMB'][pathRaw[3]]}</p>
        </div>
    )
}
