import ReviewDetailHeader from "./review-detail-header";
import ReviewDetailList from "./review-detail-list";
import CommentDetail from "../../components/comment-detail.component";
import React, {useEffect} from "react";
import ReviewDetailFilter from "./review-detail-filter";
import ReviewDetailSidebar from "./review-detail-sidebar";
import ReviewDetailTab from "./review-detail-tab";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {
    setReviewInitialState,
    setReviewList,
    setReviewUserChoices
} from "../../reducers/review.reducer";
import {useParams} from "react-router-dom";
import {SORT_LIST_REVIEW_DETAIL} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const ProductReviewDetailPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_REVIEW_DETAIL?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {id} = useParams()
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        reload
    } = useSelector(state => state.toolsReducer)
    const {activeTab, activeSidebar} = useSelector(state => state.reviewReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        filterByCriteria: activeTab,
        filterByUserChoice: activeSidebar,
        search: searchKey
    }
    const {
        fetching: getProductReviewDetail,
        isLoading
    } = useFetch({...API.PRODUCT_REVIEW_DETAIL, ...{initPathVariable: id}}, queryParams, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setReviewInitialState())
        }
    }, [])

    useEffectAfterMount(() => {
        getProductReviewDetail().then(response => {
            const dataResponse = response?.data
            dispatch(setReviewList(dataResponse?.reviews?.result))
            dispatch(setReviewUserChoices(dataResponse?.user_choices))
        })
    }, [currentPage, pageSize, orderBy, sort, searchKey, activeTab, activeSidebar, reload])

    return (
        <div className={"mt-4"}>
            <div className={"col"}>
                <ReviewDetailHeader/>
                <ReviewDetailTab/>
                <div className={"row space-x-4 px-10"}>
                    <div className={"w-1/5"}>
                        <ReviewDetailSidebar/>
                    </div>
                    <div className={"w-4/5"}>
                        <ReviewDetailFilter/>
                        <ReviewDetailList isLoading={isLoading}/>
                    </div>
                </div>
            </div>
            <CommentDetail/>
        </div>
    )
}

export default ProductReviewDetailPage
