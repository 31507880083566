import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMiraculousRetinolSeriesBannerDetail} from "../../../reducers/miraculous-retinol-series.reducer";

const ItemBanner = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {bannerDetail, miraculousRetinolSeriesErrorMessage} = useSelector(state => state.miraculousRetinolSeriesReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(bannerDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMiraculousRetinolSeriesBannerDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={bannerDetail?.item_list?.[index]?.[item.key]} error={miraculousRetinolSeriesErrorMessage} onChangeInput={onChange} onChange={onChange}/>
            ))}
        </div>
    )
}

export default ItemBanner