import {FILTER_LIST_VOUCHER} from "./filter-list";
import {TAB_LIST_ORDER} from "./constant";

//User
const FIELD_LIST_AUTH = [
    {
        label: 'Email',
        key: 'email',
        type: 'email',
        placeholder: 'masukkan email'
    }, {
        label: 'Password',
        key: 'password',
        type: 'password',
        placeholder: 'masukkan password'
    }
]

const PROFILE_FORM_LIST = [
    {
        label: 'Nama',
        key: 'fullname',
        type: 'text',
        placeholder: 'Masukkan nama'
    }, {
        label: 'Email',
        key: 'email',
        type: 'email',
        placeholder: 'Masukkan email'
    }, {
        label: 'Tanggal Lahir',
        key: 'birthdate',
        type: 'date',
        placeholder: 'Masukkan tanggal lahir'
    }, {
        label: 'Telpon',
        key: 'phone_number',
        type: 'tel',
        pattern: '[0-9]+',
        title: 'number only',
        placeholder: 'Masukkan telpon'
    }, {
        label: 'Jenis Kelamin',
        key: 'gender',
        type: 'radio',
        radioList: [
            {
                label: 'Perempuan',
                key: 'female'
            }, {
                label: 'Laki-Laki',
                key: 'male'
            }
        ]
    }
]

const CHANGE_PASSWORD_ITEM = [{
    label: 'Password Baru',
    type: 'new_password',
    key: 'password'
}]

const FIELD_LIST_EDIT_ADDRESS = [
    {
        label: 'Nama Alamat',
        key: 'title',
        type: 'select',
        selectLabel: 'label',
        selectValue: 'key',
        placeholder: 'select address name',
        options: [
            {
                label: 'Home',
                key: 'home'
            }, {
                label: 'Flat',
                key: 'flat'
            }, {
                label: 'Office',
                key: 'office'
            }, {
                label: 'Others',
                key: 'others'
            }
        ]
    }, {
        label: 'Nama Penerima',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama penerima'
    }, {
        label: 'No. Telpon Penerima',
        key: 'phone_number',
        type: 'tel',
        pattern: '[0-9]+',
        title: 'number only',
        placeholder: 'masukkan nomor telepon penerima'
    }, {
        label: 'Kota & Kecamatan',
        key: 'city_and_district',
        type: 'search_select_address',
        selectLabel: 'label',
        placeholder: 'masukkan kota dan kecamatan',
        options: []
    }, {
        label: 'Kode Pos',
        key: 'zipcode',
        type: 'search_select_address',
        selectLabel: 'label',
        placeholder: 'masukkan kode pos',
        options: []
    }, {
        label: 'Alamat Lengkap',
        key: 'address',
        type: 'text_area',
        maxLength: 150,
        placeholder: 'tulis alamat lengkap...'
    }, {
        label: 'Catatan (Optional)',
        key: 'note',
        type: 'text_area',
        maxLength: 150,
        placeholder: 'tulis catatan...'
    }, {
        label: 'Jadikan alamat utama',
        key: 'is_main_address',
        type: 'switch_toggle',
        activeText: 'Alamat Utama',
        inActiveText: 'Bukan Alamat Utama'
    }
]

const CREATE_USER_FORM_LIST = [
    {
        label: 'Nama',
        key: 'fullname',
        type: 'text',
        placeholder: 'Masukkan nama',
        required: true
    }, {
        label: 'Email',
        key: 'email',
        type: 'email',
        placeholder: 'Masukkan email',
        required: true
    }, {
        label: 'Tanggal Lahir',
        key: 'birthdate',
        type: 'date',
        placeholder: 'Masukkan tanggal lahir',
        required: false
    }, {
        label: 'Telpon',
        key: 'phone_number',
        type: 'tel',
        pattern: '[0-9]+',
        title: 'number only',
        placeholder: 'Masukkan telpon',
        required: false
    }, {
        label: 'Jenis Kelamin',
        key: 'gender',
        type: 'radio',
        radioList: [
            {
                label: 'Perempuan',
                key: 'female'
            }, {
                label: 'Laki-Laki',
                key: 'male'
            }
        ],
        required: false
    }
]

const ACCESSIBILITY_FORM = [
    {
        label: 'User Role',
        type: 'select',
        key: 'role_id',
        options: [],
        selectLabel: 'name',
        selectValue: 'id',
        placeholder: 'select user role',
        required: true
    }, {
        label: 'Set Password',
        type: 'new_password',
        key: 'password',
        required: true
    }
]

//Product
const FIELD_LIST_CREATE_PRODUCT_GENERAL_INFORMATION = [
    [{
        label: 'Nama Produk',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama produk',
        required: true
    }, {
        label: 'Kategori',
        key: 'categories',
        type: 'multiple_select_list',
        placeholder: 'cari kategori',
        selectLabel: 'name',
        dataList: []
    }, {
        label: 'Foto Lainnya',
        key: 'another_images',
        type: 'multiple_file_image',
        maxMount: 10
    }], [{
        label: 'Foto Produk',
        key: 'main_image',
        type: 'file_main_image',
        title: 'Foto Utama Produk'
    }]
]

const FIELD_LIST_CREATE_PRODUCT_SALE_INFORMATION = [
    {
        label: 'SKU',
        key: 'sku',
        type: 'text',
        placeholder: 'masukkan kode SKU',
        required: true
    }, {
        label: 'Kode GS1',
        key: 'gs1_barcode',
        type: 'text',
        placeholder: 'masukkan kode GS1',
        required: true
    }, {
        label: 'Stok',
        key: 'stock_quantity',
        type: 'number',
        placeholder: 'masukkan stok',
        required: true
    }, {
        label: 'Harga Produk',
        key: 'price',
        type: 'number',
        placeholder: 'masukkan harga produk',
        required: true
    }, {
        label: 'Berat (Gram)',
        key: 'weight',
        type: 'number',
        placeholder: 'masukkan berat',
        required: true
    }, {
        label: 'Harga Diskon',
        key: 'sale_price',
        type: 'number',
        placeholder: 'masukkan harga diskon',
        required: false
    }, {
        label: 'Dimensi (Tinggi)',
        key: 'height',
        type: 'number',
        placeholder: 'masukkan dimensi tinggi',
        required: false
    }, {
        label: 'Start Sale',
        key: 'sale_start',
        type: 'date',
        includeTime: true,
        placeholder: 'masukkan tanggal dan waktu mulai penjualan',
        required: false
    }, {
        label: 'Dimensi (Panjang)',
        key: 'length',
        type: 'number',
        placeholder: 'masukkan dimensi panjang',
        required: false
    }, {
        label: 'End Sale',
        key: 'sale_end',
        type: 'date',
        includeTime: true,
        placeholder: 'masukkan tanggal dan waktu berakhir penjualan',
        required: false
    }, {
        label: 'Dimensi (Lebar)',
        key: 'width',
        type: 'number',
        placeholder: 'masukkan dimensi lebar',
        required: false
    },
]

const FIELD_LIST_CREATE_PRODUCT_INFORMATION = [
    {
        label: 'Description (Bahasa Indonesia)',
        key: 'description_id',
        type: 'text_editor',
        language: "id",
        placeholder: 'tulis deskripsi produk...'
    }, {
        label: 'Description (English)',
        key: 'description_en',
        type: 'text_editor',
        language: "en",
        placeholder: 'tulis deskripsi produk...'
    }, {
        label: 'For Who (Bahasa Indonesia)',
        key: 'for_who_id',
        type: 'text_editor',
        language: "id",
        placeholder: 'tulis deskripsi pengguna...'
    }, {
        label: 'For Who (English)',
        key: 'for_who_en',
        type: 'text_editor',
        language: "en",
        placeholder: 'tulis deskripsi pengguna...'
    }, {
        label: 'How To Use (Bahasa Indonesia)',
        key: 'how_to_use_id',
        type: 'text_editor',
        language: "id",
        placeholder: 'tulis cara penggunaan produk...'
    }, {
        label: 'How To Use (English)',
        key: 'how_to_use_en',
        type: 'text_editor',
        language: "en",
        placeholder: 'tulis cara penggunaan produk...'
    }, {
        label: 'Ingredients (Bahasa Indonesia)',
        key: 'ingredient_id',
        type: 'text_editor',
        language: "id",
        placeholder: 'tulis bahan produk...'
    }, {
        label: 'Ingredients (English)',
        key: 'ingredient_en',
        type: 'text_editor',
        language: "en",
        placeholder: 'tulis bahan produk...'
    }, {
        label: 'Banner Product (Bahasa Indonesia)',
        key: 'banner_url_id',
        type: 'multiple_file_image_banner',
        language: "id",
        titleList: ['Desktop Banner', 'Mobile Banner']
    }, {
        label: 'Banner Product (English)',
        key: 'banner_url_en',
        type: 'multiple_file_image_banner',
        language: "en",
        titleList: ['Desktop Banner', 'Mobile Banner']
    }
]

const FIELD_LIST_CREATE_PRODUCT_PAGE_SETTINGS = [
    {
        label: 'Empty Product Label',
        key: 'out_of_stock_label',
        type: 'text',
        placeholder: 'masukkan label produk kosong',
        required: false
    }, {
        label: 'Limit Purchase',
        key: 'limit_purchase',
        type: 'number',
        placeholder: 'masukkan limit purchase',
        required: false
    },
]

const FIELD_LIST_CREATE_PRODUCT_SEO_SETTINGS = [
    [{
        label: 'SEO Title',
        key: 'meta_title',
        type: 'text',
        placeholder: 'masukkan SEO title',
        required: false
    }, {
        label: 'Slug',
        key: 'slug',
        type: 'text',
        placeholder: 'masukkan slug',
        required: false
    }], [{
        label: 'Meta Description',
        key: 'meta_description',
        type: 'text_area',
        placeholder: 'tulis deskripsi meta SEO...',
    }]
]

const FIELD_LIST_AVAILABLE_BEAUTY_EMPTIES = [
    {
        label: 'Available for Beauty Empties',
        key: 'available_for_beauty_empties',
        type: 'radio',
        required: true,
        radioList: [
            {
                label: 'Yes',
                key: 'true'
            }, {
                label: 'No',
                key: 'false'
            }
        ]
    }
]

const FIELD_LIST_UPLOAD_PRODUCT_IMAGE = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan judul'
    }, {
        label: 'Caption',
        key: 'caption',
        type: 'text',
        placeholder: 'masukkan caption'
    }, {
        label: 'Url',
        key: 'url',
        type: 'text',
        placeholder: 'masukkan url'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'masukkan deskripsi'
    }, {
        label: 'Alt Text',
        key: 'alt_text',
        type: 'text',
        placeholder: 'masukkan alternatif text'
    },
]

const FIELD_LIST_CREATE_CATEGORY = [
    {
        label: 'Parent Category',
        key: 'parent_category',
        type: 'select',
        selectLabel: 'name',
        selectValue: 'id',
        placeholder: 'pilih parent',
        options: []
    }, {
        label: 'Category Name',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama kategori'
    }, {
        label: 'Category Slug',
        key: 'slug',
        type: 'text',
        placeholder: 'masukkan slug kategori'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'masukkan deskripsi kategori...'
    },
]

const FIELD_LIST_CREATE_GIFT = [
    [{
        label: 'Nama Gift',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama gift',
        required: true
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'deskripsi gift',
        required: false
    }], [{
        label: 'Foto Gift',
        key: 'image_url',
        type: 'file_image',
        title: 'Foto Gift',
        showAbove: true,
        slug: 'gift_image'
    }, {
        label: 'Alt Text',
        key: 'image_alt_text',
        type: 'text',
        placeholder: 'masukkan alternatif text',
        required: false
    }], [{
        label: 'SKU',
        key: 'sku',
        type: 'text',
        placeholder: 'masukkan kode SKU',
        required: true
    }, {
        label: 'Berat (Gram)',
        key: 'weight',
        type: 'number',
        placeholder: 'masukkan berat',
        required: true
    }, {
        label: 'Kode GS1',
        key: 'gs1_barcode',
        type: 'text',
        placeholder: 'masukkan kode GS1',
        required: true
    }, {
        label: 'Dimensi (Tinggi)',
        key: 'height',
        type: 'number',
        placeholder: 'masukkan dimensi tinggi',
        required: false
    }, {
        label: 'Stok',
        key: 'stock_quantity',
        type: 'number',
        placeholder: 'masukkan stok',
        required: true
    }, {
        label: 'Dimensi (Panjang)',
        key: 'length',
        type: 'number',
        placeholder: 'masukkan dimensi panjang',
        required: false
    }, {
        label: 'Harga Gift',
        key: 'price',
        type: 'number',
        placeholder: 'masukkan harga gift',
        required: false
    }, {
        label: 'Dimensi (Lebar)',
        key: 'width',
        type: 'number',
        placeholder: 'masukkan dimensi lebar',
        required: false
    }, {
        label: 'Available for Birthday Gift',
        key: 'available_for_birthday_gift',
        type: 'radio',
        required: true,
        radioList: [
            {
                label: 'Yes',
                key: 'true'
            }, {
                label: 'No',
                key: 'false'
            }
        ]
    }]
]

//Voucher
const FIELD_LIST_CREATE_VOUCHER_SETTINGS = [
    [{
        label: 'Voucher Name',
        key: 'title',
        type: 'text',
        placeholder: 'nama voucher',
        required: true
    }, {
        label: 'Voucher Code',
        key: 'code',
        type: 'text',
        placeholder: 'buat kode voucher',
        required: true
    }, {
        label: 'Voucher Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi produk',
        required: true
    }, {
        label: 'Term & Condition',
        key: 'terms_and_conditions',
        type: 'text_editor',
        placeholder: 'tulis term & condition',
    }, {
        label: 'Voucher Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'voucher_thumbnail'
    }, {
        label: 'Show in checkout page ?',
        key: 'is_shown_in_checkout',
        type: 'switch_toggle',
        activeText: 'Show',
        inActiveText: 'Hide',
        isHide: false,
    }], [{
        label: 'Voucher Type',
        key: 'type',
        type: 'select',
        selectValue: 'key',
        selectLabel: 'label',
        placeholder: 'select voucher type',
        required: true,
        options: FILTER_LIST_VOUCHER[0].list
    }, {
        label: 'Started Date',
        key: 'date_start',
        type: 'date',
        includeTime: true,
        required: true,
        placeholder: 'masukkan tanggal berlaku voucher'
    }, {
        label: 'Expired Date',
        key: 'date_expires',
        type: 'date',
        includeTime: true,
        required: false,
        placeholder: 'masukkan tanggal kadaluarsa voucher'
    }, {
        label: 'Discount Type',
        key: 'discount_type',
        type: 'radio',
        required: true,
        radioList: [
            {
                label: 'Percentage',
                key: 'percentage'
            }, {
                label: 'Nominal',
                key: 'nominal'
            }
        ]
    }, {
        label: 'Voucher Amount',
        key: 'amount',
        type: 'number',
        required: true,
        placeholder: 'jumlah voucher'
    }, {
        label: 'Max Amount',
        key: 'max_amount',
        type: 'number',
        required: false,
        placeholder: 'jumlah maksimal',
        isHide: true,
    }, {
        label: 'Select Gift',
        key: 'gifts',
        type: 'multiple_select_product',
        placeholder: 'search product',
        selectLabel: 'name',
        required: true,
        dataList: []
    }, {
        label: 'Free Shipping ?',
        key: 'free_shipping',
        type: 'checkbox',
        checkboxLabel: 'Yes'
    }]]

const FIELD_LIST_CREATE_VOUCHER_RESTRICTION = [
    {
        label: 'Minimum Spend',
        key: 'minimum_amount',
        type: 'number',
        placeholder: 'pengeluaran minimum pembelian',
        tooltipText: ''
    }, {
        label: 'Maximum Spend',
        key: 'maximum_amount',
        type: 'number',
        placeholder: 'pengeluaran maksimum pembelian',
        tooltipText: ''
    }, {
        label: 'Individual Use Only',
        key: 'individual_use',
        type: 'checkbox',
        checkboxLabel: 'Activate',
        tooltipText: ''
    }, {
        label: 'Exclude Sale Item',
        key: 'exclude_sale_items',
        type: 'checkbox',
        checkboxLabel: 'Exclude',
        tooltipText: ''
    }, {
        label: 'Includes Product',
        key: 'include_product_ids',
        type: 'multiple_select_product',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Excludes Product',
        key: 'exclude_product_ids',
        type: 'multiple_select_product',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Includes Category',
        key: 'include_category_ids',
        type: 'checkbox_list',
        placeholder: 'search category',
        selectValue: 'id',
        selectLabel: 'name',
        titleType: {
            id: 'includes_category',
            name: 'Categories',
        },
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Excludes Category',
        key: 'exclude_category_ids',
        type: 'checkbox_list',
        selectLabel: 'name',
        selectValue: 'id',
        placeholder: 'search category',
        titleType: {
            id: 'excludes_category',
            name: 'Categories',
        },
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Shipping Courier',
        key: 'shipping_courier',
        type: 'checkbox_list',
        selectLabel: 'name',
        placeholder: 'search courier',
        selectValue: 'alias',
        titleType: {
            id: 'shipping_courier',
            name: 'Courier',
        },
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Payment Method',
        key: 'payment_method',
        type: 'checkbox_list',
        placeholder: 'search payment',
        selectLabel: 'name',
        selectValue: 'midtrans_alias',
        titleType: {
            id: 'payment_method',
            name: 'Payment Method',
        },
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Allowed Email',
        key: 'allowed_email',
        type: 'multiple_select_search',
        subType: 'email',
        placeholder: 'search email',
        dataList: [],
        selectLabel: 'email',
        tooltipText: ''
    },
]

const FIELD_LIST_CREATE_VOUCHER_USAGE_LIMIT = [
    {
        label: 'Usage Limit per Voucher',
        key: 'usage_limit',
        type: 'number',
        placeholder: 'voucher dapat digunakan x kali',
        tooltipText: ''
    }, {
        label: 'Usage Limit per User',
        key: 'usage_limit_per_user',
        type: 'number',
        placeholder: 'voucher dapat digunakan x kali untuk 1 user',
        tooltipText: ''
    }, {
        label: 'Usage Limit to X item',
        key: 'usage_limit_to_x_item',
        type: 'number',
        placeholder: 'voucher dapat digunakan untuk x item di dalam pembelanjaan',
        tooltipText: ''
    }
]

const FIELD_LIST_CREATE_VOUCHER_AUTO_APPLY = [
    {
        label: 'Voucher Auto Apply',
        key: 'auto_apply',
        type: 'switch_toggle',
        activeText: 'Active',
        inActiveText: 'Inactive'
    }
]

const FIELD_LIST_GENERATE_VOUCHER_DUPLICATOR = [
    {
        label: 'Character Amount ',
        key: 'character_amount',
        type: 'number',
        placeholder: 'character amount'
    }, {
        label: 'Prefix',
        key: 'prefix',
        type: 'text',
        placeholder: 'prefix voucher'
    }, {
        label: 'Total Voucher',
        key: 'total_voucher',
        type: 'number',
        placeholder: 'total voucher'
    }, {
        label: 'Voucher Code',
        key: 'voucher_code',
        type: 'text',
        placeholder: 'insert voucher code'
    }, {
        label: 'Select Voucher (Duplicate)',
        key: 'select_voucher_duplicate',
        type: 'search_select',
        selectLabel: 'label',
        placeholder: 'insert voucher code',
        options: []
    },
]

//Avostore
const FIELD_LIST_ADD_NEW_AREA = [
    {
        label: 'Area Name',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama area',
        required: true
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi area'
    }
]

const FIELD_LIST_GENERAL = [
    [{
        label: 'Nama Store',
        key: 'name',
        type: 'text',
        placeholder: 'masukkan nama store',
        required: true
    }, {
        label: 'Area',
        key: 'avostore_area_id',
        type: 'select',
        options: [],
        selectLabel: 'name',
        selectValue: 'id',
        placeholder: 'select area',
        required: true
    }, {
        label: 'Instagram Username',
        key: 'ig_username',
        type: 'text',
        placeholder: 'masukkan username instagram'
    }, {
        label: 'Instagram Link',
        key: 'ig_url',
        type: 'text',
        placeholder: 'masukkan link instagram'
    }], [{
        label: 'Store Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi store'
    }], [{
        label: 'Offline Store',
        key: 'offline_stores',
        placeholder: 'ketik nama offline store'
    }]
]

const FIELD_LIST_MARKETPLACE_AND_WEBSITE = [
    {
        label: 'Shopee Link',
        key: 'shopee_url',
        type: 'text',
        placeholder: 'masukkan link shopee'
    }, {
        label: 'Tokopedia Link',
        key: 'tokopedia_url',
        type: 'text',
        placeholder: 'masukkan link tokopedia'
    }, {
        label: 'Lazada Link',
        key: 'lazada_url',
        type: 'text',
        placeholder: 'masukkan link lazada'
    }, {
        label: 'Blibli Link',
        key: 'blibli_url',
        type: 'text',
        placeholder: 'masukkan link blibli'
    }, {
        label: 'Bukalapak Link',
        key: 'bukalapak_url',
        type: 'text',
        placeholder: 'masukkan link bukalapak'
    }, {
        label: 'JD ID Link',
        key: 'jdid_url',
        type: 'text',
        placeholder: 'masukkan link jd id'
    }, {
        label: 'Tiktok Shop Link',
        key: 'tiktok_url',
        type: 'text',
        placeholder: 'masukkan link toktok shop'
    }, {
        label: 'Go Shop Link',
        key: 'goshop_url',
        type: 'text',
        placeholder: 'masukkan link go shop'
    }, {
        label: 'Website Link',
        key: 'website_url',
        type: 'text',
        placeholder: 'masukkan link website'
    }
]

//Settings
const FIELD_LIST_MOBILE_VERSION = [
    {
        label: 'Recommended Version',
        key: 'ios',
        type: 'text',
        placeholder: 'masukkan recommended version'
    }, {
        label: 'Recommended Version',
        key: 'android',
        type: 'text',
        placeholder: 'masukkan recommended version'
    }
]

const FIELD_LIST_MAINTENANCE_PAGE = [
    {
        label: 'Image',
        key: 'image_url',
        type: 'file_image',
        showAbove: true,
        slug: 'page'
    },
    [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan title',
        required: true
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi...'
    }]
]

//Notification
const FIELD_LIST_GENERAL_CONTENT = [
    [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'inputkan title'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'inputkan deskripsi'
    }, {
        label: 'Upload Gambar',
        key: 'image_url',
        type: 'file_image',
        showAbove: true,
        slug: 'notification'
    }], [{
        label: 'Schedule',
        key: 'scheduled_at',
        type: 'date',
        includeTime: true,
        placeholder: 'minute must be insert interval 10 minute, example: XX:10'
    }, {
        label: 'Type',
        key: 'type',
        type: 'radio',
        radioList: [
            {
                label: 'Information',
                key: 'information'
            }, {
                label: 'Promo',
                key: 'promo'
            }
        ]
    }, {
        label: 'Icon',
        key: 'icon',
        type: 'radio',
        radioList: [
            {
                label: 'Lonceng',
                key: 'bell'
            }, {
                label: 'Heart',
                key: 'heart'
            }, {
                label: 'Voucher',
                key: 'voucher'
            }
        ]
    }]
]

const FIELD_LIST_ROUTING = [
    {
        label: 'Redirection Type',
        key: 'routing_type',
        type: 'select',
        selectLabel: 'label',
        selectValue: 'key',
        placeholder: 'pilih type',
        options: [
            {
                label: 'Custom Link',
                key: 'custom'
            }, {
                label: 'Product List',
                key: 'list'
            }, {
                label: 'Product Detail',
                key: 'detail'
            }
        ]
    }, {
        label: 'Custom Link',
        key: 'routing_link',
        type: 'text',
        placeholder: 'inputkan link'
    }, [
        {
            label: 'Sorting By',
            key: 'routing_order',
            type: 'select',
            selectLabel: 'label',
            selectValue: 'key',
            placeholder: 'pilih sorting',
            options: [
                {
                    label: 'Created Date Terbaru - Terlama',
                    key: 'latest'
                }, {
                    label: 'Created Date Terlama - Terbaru',
                    key: 'oldest'
                }, {
                    label: 'Review Tertinggi - Terendah',
                    key: 'most-reviewed'
                }, {
                    label: 'Review Terendah - Tertinggi',
                    key: 'least-reviewed'
                }, {
                    label: 'Price Tertinggi - Terendah',
                    key: 'most-expensive'
                }, {
                    label: 'Price Terendah - Tertinggi',
                    key: 'cheapest'
                },
            ],
        }, {
            label: 'Category',
            key: 'routing_category_ids',
            type: 'multiple_select_list',
            placeholder: 'search category',
            selectLabel: 'name',
            dataList: []
        }
    ], {
        label: 'Product',
        key: 'routing_product_ids',
        type: 'multiple_select_product',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }, {
        label: 'Discounted Product',
        key: 'routing_is_discounted',
        type: 'select',
        selectLabel: 'label',
        selectValue: 'key',
        placeholder: 'apakah diskon ?',
        options: [
            {
                label: 'Yes',
                key: 'true'
            }, {
                label: 'No',
                key: 'false'
            }, {
                label: 'All',
                key: 'all'
            }
        ],
    }, {
        label: 'Product',
        key: 'routing_product_id',
        type: 'select',
        selectLabel: 'name',
        selectValue: 'key',
        placeholder: 'pilih product',
        options: [],
    }
]

const FIELD_LIST_RECEIVER = [
    {
        label: 'Receiver Type',
        key: 'receiver_type',
        type: 'select',
        selectLabel: 'label',
        selectValue: 'key',
        placeholder: 'pilih type',
        options: [
            {
                label: 'All User',
                key: 'all'
            }, {
                label: 'Specific Email',
                key: 'email'
            }, {
                label: 'Includes User',
                key: 'order'
            }
        ],
    }, {
        label: 'Email',
        key: 'receiver_emails',
        type: 'multiple_select_search',
        placeholder: 'search email',
        selectLabel: 'email',
        dataList: []
    }, {
        label: 'Product',
        key: 'receiver_product_ids',
        type: 'multiple_select_product',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: [],
        description: 'Only user who purchased this product'
    }, [
        {
            label: 'Month',
            key: 'month',
            type: 'date_range',
            description: 'Only user who order at the month and year on selected date'
        }, {
            label: 'Order Times',
            key: 'receiver_order_times',
            type: 'number',
            placeholder: 'inputkan order times',
            description: 'Only user who order for X times'
        }
    ]
]

const ADVANCE_FILTER_ORDER_LIST = [
    [{
        label: 'Filter by order start date',
        key: 'startDate',
        type: 'date',
        placeholder: 'selected date'
    }, {
        label: 'Filter by order end date',
        key: 'endDate',
        type: 'date',
        placeholder: 'selected date'
    }], {
        label: 'Filter by product',
        key: 'productId',
        type: 'multiple_select_filter',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }, {
        label: 'Filter by voucher',
        key: 'voucherId',
        type: 'multiple_select_filter',
        placeholder: 'search voucher',
        selectLabel: 'code',
        dataList: []
    }, {
        label: 'Filter by Affiliator',
        key: 'affiliatorId',
        type: 'multiple_select_filter',
        placeholder: 'search Affiliator',
        selectLabel: 'code',
        dataList: []
    }, {
        label: 'Filter by status',
        key: 'status',
        type: 'checkbox_list_filter',
        placeholder: 'search status',
        selectLabel: 'label',
        selectValue: 'key',
        titleType: {
            id: 'status',
            name: 'Status',
        },
        dataList: [],
        tooltipText: ''
    }, [{
        label: 'Filter by payment method',
        key: 'paymentMethod',
        type: 'checkbox_list_filter',
        placeholder: 'search payment',
        selectLabel: 'name',
        selectValue: 'midtrans_alias',
        titleType: {
            id: 'payment_method',
            name: 'Payment Method',
        },
        dataList: [],
        tooltipText: ''
    }, {
        label: 'Filter by shipping method',
        key: 'shippingMethod',
        type: 'checkbox_list_filter',
        selectLabel: 'name',
        placeholder: 'search courier',
        selectValue: 'alias',
        titleType: {
            id: 'shipping_courier',
            name: 'Courier',
        },
        dataList: [],
        tooltipText: ''
    }]
]

const ADVANCE_FILTER_ANALYTICS_ORDER = [
    [{
        label: 'Filter by voucher',
        key: 'voucher_codes',
        type: 'multiple_select_filter',
        placeholder: 'search voucher',
        selectLabel: 'code',
        dataList: []
    }, {
        label: 'Filter by payment method',
        key: 'payment_methods',
        type: 'checkbox_list_filter',
        placeholder: 'search payment',
        selectLabel: 'name',
        selectValue: 'midtrans_alias',
        titleType: {
            id: 'payment_method',
            name: 'Payment Method',
        },
        dataList: [],
        tooltipText: ''
    }], {
        label: 'Filter by shipping method',
        key: 'shipping_methods',
        type: 'checkbox_list_filter',
        selectLabel: 'name',
        placeholder: 'search courier',
        selectValue: 'alias',
        titleType: {
            id: 'shipping_methods',
            name: 'Courier',
        },
        dataList: [],
        tooltipText: ''
    }
]

const FIELD_LIST_CREATE_AFFILIATE = [
    {
        label: 'Nama Afiliator',
        key: 'name',
        type: 'text',
        placeholder: 'Inputkan nama afiliator',
        required: true
    }, {
        label: 'Nama Campaign',
        key: 'campaign',
        type: 'text',
        placeholder: 'Inputkan nama campaign'
    }, {
        label: 'Kode Afiliasi',
        key: 'code',
        type: 'text',
        placeholder: 'Inputkan kode afiliasi',
        required: true
    }, {
        label: 'Campaign Link',
        key: 'campaign_link',
        type: 'text',
        placeholder: 'Inputkan link campaign',
        required: true
    }, {
        label: 'Link Afiliasi',
        key: 'affiliate_link',
        type: 'text',
        disabled: true,
        copyText: true,
    }, {
        label: 'Tanggal Mulai',
        key: 'start_date',
        type: 'date',
        placeholder: 'Pilih tanggal',
        includeTime: true,
    }, {
        label: 'Tanggal Berakhir',
        key: 'end_date',
        type: 'date',
        placeholder: 'Pilih tanggal',
        includeTime: true,
    }, {
        label: 'Email',
        key: 'email',
        type: 'email',
        placeholder: 'Inputkan email'
    }, {
        label: 'Nomor Telepon',
        key: 'phone_number',
        type: 'tel',
        pattern: '[0-9]+',
        placeholder: 'Inputkan nomor telpon'
    }
]

const FIELD_LIST_CREATE_LEVEL_AND_BENEFIT = [
    {
        label: 'Nama Level',
        key: 'name',
        type: 'text',
        placeholder: 'inputkan nama level'
    }, {
        label: 'Membership Discount',
        key: 'discount_amount',
        type: 'number',
        placeholder: 'inputkan nilai membership discount'
    }, {
        label: 'Minimum Order',
        key: 'min_total_order_amount',
        type: 'number',
        placeholder: 'inputkan nilai minimum order'
    }, {
        label: 'Maximum Redeem Point',
        key: 'max_redeem_amount',
        type: 'number',
        placeholder: 'inputkan nilai maximum redeem point'
    }, {
        label: 'Maximum Order',
        key: 'max_total_order_amount',
        type: 'number',
        placeholder: 'inputkan nilai maximum order'
    }
]

const FIELD_LIST_CREATE_BIRTHDAY_GIFT = [
    {
        key: 'gift_id',
        type: 'select',
        selectLabel: 'name',
        placeholder: 'pilih Gift',
        options: [],
    }, {
        key: 'discount_amount',
        type: 'number',
        placeholder: 'inputkan potongan pembelanjaan'
    }
]

export {
    //User
    FIELD_LIST_AUTH,
    PROFILE_FORM_LIST,
    CHANGE_PASSWORD_ITEM,
    FIELD_LIST_EDIT_ADDRESS,
    CREATE_USER_FORM_LIST,
    ACCESSIBILITY_FORM,
    //Product
    FIELD_LIST_CREATE_PRODUCT_GENERAL_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_SALE_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_PAGE_SETTINGS,
    FIELD_LIST_CREATE_PRODUCT_SEO_SETTINGS,
    FIELD_LIST_AVAILABLE_BEAUTY_EMPTIES,
    FIELD_LIST_UPLOAD_PRODUCT_IMAGE,
    FIELD_LIST_CREATE_CATEGORY,
    FIELD_LIST_CREATE_GIFT,
    //Voucher
    FIELD_LIST_CREATE_VOUCHER_SETTINGS,
    FIELD_LIST_CREATE_VOUCHER_RESTRICTION,
    FIELD_LIST_CREATE_VOUCHER_USAGE_LIMIT,
    FIELD_LIST_CREATE_VOUCHER_AUTO_APPLY,
    FIELD_LIST_GENERATE_VOUCHER_DUPLICATOR,
    //Avostore
    FIELD_LIST_ADD_NEW_AREA,
    FIELD_LIST_GENERAL,
    FIELD_LIST_MARKETPLACE_AND_WEBSITE,
    //Settings
    FIELD_LIST_MOBILE_VERSION,
    FIELD_LIST_MAINTENANCE_PAGE,
    //Notification
    FIELD_LIST_GENERAL_CONTENT,
    FIELD_LIST_ROUTING,
    FIELD_LIST_RECEIVER,
    //Order
    ADVANCE_FILTER_ORDER_LIST,
    //Analytics
    ADVANCE_FILTER_ANALYTICS_ORDER,
    //Affiliate
    FIELD_LIST_CREATE_AFFILIATE,
    //Loyalty Program
    FIELD_LIST_CREATE_LEVEL_AND_BENEFIT,
    FIELD_LIST_CREATE_BIRTHDAY_GIFT
}
