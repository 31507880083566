import React, {useEffect} from "react";
import GeneralForm from "./general-form";
import BestSellerProductForm from "./best-seller-product-form";
import {FIELD_LIST_BEST_SELLER_GENERAL, FIELD_SELECT_PRODUCT} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setMainPageBestSellerDetail, setMainPageErrorMessage} from "../../../reducers/main-page.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {convertErrorMessage} from "../../../utils/general-variable";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const BestSeller = () => {
    const dispatch = useDispatch()
    const {bestSellerDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {fetching: getBestSeller} = useFetch(API.HOMEPAGE_GET_BEST_SELLER)
    const {fetching: updateBestSeller, isLoading} = useFetch(API.HOMEPAGE_UPDATE_BEST_SELLER)
    let fieldListGeneral = FIELD_LIST_BEST_SELLER_GENERAL
    let fieldListBestSellerProduct = FIELD_SELECT_PRODUCT
    let showBestSeller = {
        label: 'Best Seller',
        key: 'show_best_seller',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'General',
            content: <GeneralForm fieldList={fieldListGeneral}/>
        }, {
            title: 'Best Seller Product',
            content: <BestSellerProductForm fieldList={fieldListBestSellerProduct[0]}/>
        }
    ]

    const onChange = (key, value) => {
        dispatch(setMainPageBestSellerDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const requestBody = {
            ...bestSellerDetail,
            select_product: bestSellerDetail.select_product.map(item => item.id) ?? []
        }
        updateBestSeller(requestBody).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Best Seller Updated'))
            dispatch(setMainPageBestSellerDetail({...response?.data, delete_product: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        Promise.all([getBestSeller(), getProductList()]).then(response => {
            dispatch(setMainPageBestSellerDetail({...response[0].data, delete_product: []}))
            fieldListBestSellerProduct[0].dataList = response[1].data
        }).catch(() => {
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={bestSellerDetail}
            switchToggleField={showBestSeller}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default BestSeller