import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../components/table-component";
import React from "react";
import {setAffiliateDetail, setAffiliateModalVisibility, setAffiliateSelectedRowKeys} from "../../reducers/affiliate.reducer";
import TotalDataComponent from "../../components/total-data.component";
import {convertDateToCustomFormat} from "../../utils/general-variable";
import {CopyIcon} from "../../utils/image";
import {setToolsAlertMessage, setToolsModalVisibility} from "../../reducers/tools.reducer";

const AffiliateList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysAffiliate} = useSelector(state => state.affiliateReducer)

    const copyText = (value) => {
        dispatch(setToolsModalVisibility({toast: true}))
        dispatch(setToolsAlertMessage('Link Telah Disalin'))
        navigator.clipboard.writeText(value)
    }

    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Name',
            classNameHeader: 'pl-5',
            className: 'pl-5',
            render: (record) => (
                <button onClick={() => showModalHandler(record)}>
                    <p className={"font-regular capitalize text-forged-steel hover:font-Montserrat-Bold hover:underline p-4"}>{record.name}</p>
                </button>
            )
        }, {
            name: 'Campaign',
            dataIndex: 'campaign',
            render: (value) => value || '-'
        }, {
            name: 'Affiliate Code',
            dataIndex: 'code'
        }, {
            name: 'Affiliate Link',
            dataIndex: 'affiliate_link',
            render: (value) => <div className={'row gap-2'}>
                <a className="hover:underline" href={value} target="_blank" rel="noreferrer">{value}</a>
                <img className={'cursor-pointer'} src={CopyIcon} width={16} height={16} alt='copy' onClick={() => copyText(value)}/>
            </div>

        }, {
            name: 'Effective Date',
            render: (record) => {
                if (record.start_date && record.end_date) {
                    return (
                        <p>{`${convertDateToCustomFormat(record.start_date, 'YYYY/MM/DD')} - ${convertDateToCustomFormat(record.end_date, 'YYYY/MM/DD')}`}</p>
                    )
                }
                if (!record.start_date && record.end_date) {
                    return (
                        <p>{`Berakhir sampai ${convertDateToCustomFormat(record.end_date, 'YYYY/MM/DD')}`}</p>
                    )
                }
                if (record.start_date && !record.end_date) {
                    return (
                        <p>{`Berlaku mulai ${convertDateToCustomFormat(record.start_date, 'YYYY/MM/DD')}`}</p>
                    )
                }
                return '-'
            }

        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setAffiliateSelectedRowKeys(array))
    }

    const showModalHandler = (item) => {
        dispatch(setAffiliateModalVisibility({updateAffiliate: true}))
        dispatch(setAffiliateDetail(item))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Affiliate"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysAffiliate}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default AffiliateList
