import {createSlice} from '@reduxjs/toolkit'
import {personalizeModel} from "../models/your-skin-bae.model";

export const yourSkinBaeSlice = createSlice({
    name: 'yourSkinBae',
    initialState: {
        personalizeDetail: {...personalizeModel},
        yourSkinBaeErrorMessage: {},
    },
    reducers: {
        setYourSkinBaePersonalizeDetail: (state, action) => {
            state.personalizeDetail = {...state.personalizeDetail, ...action.payload}
        },
        setYourSkinBaeErrorMessage: (state, action) => {
            state.yourSkinBaeErrorMessage = action.payload
        }
    },
})

export const {
    setYourSkinBaePersonalizeDetail,
    setYourSkinBaeErrorMessage
} = yourSkinBaeSlice.actions
const yourSkinBaeReducer = yourSkinBaeSlice.reducer

export default yourSkinBaeReducer