import React, {useEffect, useState} from "react";
import {SearchIcon} from "../utils/image";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage, setToolsSearchKey} from "../reducers/tools.reducer";

const SearchComponent = (props) => {
    const {placeholder} = props
    const dispatch = useDispatch()
    const {searchKey} = useSelector(state => state.toolsReducer)
    const [searchValue, setSearchValue] = useState(searchKey)

    const doSearch = (value) => {
        dispatch(setToolsSearchKey(value))
        dispatch(setToolsCurrentPage(1))
    }

    const onKeyUpHandler = (event) => {
        if (event.key === 'Enter') {
            doSearch(searchValue)
        }
    }

    const onSearch = () => {
        doSearch(searchValue)
    }

    const onChangeHandler = (event) => {
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        setSearchValue(searchKey)
    }, [searchKey])

    return (
        <div className={"row space-x-2 h-9"}>
            <div className={"row items-center px-2 rounded-lg w-[170px] border border-desolace-dew bg-ceramic"}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input
                    placeholder={!!placeholder ? placeholder : 'cari pesanan...'}
                    className={"bg-ceramic font-normal border-0 outline-0 px-3 w-full placeholder:italic"}
                    value={searchValue.toString()}
                    onChange={onChangeHandler}
                    onKeyUp={onKeyUpHandler}
                />
            </div>
            <div
                className={"col justify-center rounded-lg text-xs text-white px-2 bg-battleship-green hover:bg-monstera font-semibold cursor-pointer"}
                onClick={onSearch}>
                Search
            </div>
        </div>
    )
}

export default SearchComponent
