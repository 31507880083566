import InputComponent from "../../components/input.component";
import React from "react";
import {useSelector} from "react-redux";

const UsageLimitForm = ({fieldList}) => {
    const {voucherErrorMessage, voucherDetail} = useSelector(state => state.voucherReducer)

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} data={voucherDetail.limit[item.key]} error={voucherErrorMessage}/>
            ))}
        </div>
    )
}

export default UsageLimitForm
