import React, {useEffect, useState} from "react";

const NumberInputComponent = (props) => {
    const {keyName, pattern, title, disabled, inputRef, maxLength, placeholder, defaultValue, onChangeInput} = props
    const [val, setVal] = useState()

    const setValHandler = (key, val) => {
        const value = !!val ? val : null
        if (!(val.match(/^[0-9]+$/)) && val[0]) {
            return
        }
        setVal(value)
        !!onChangeInput && onChangeInput(key, value)
    }

    useEffect(() => {
        if (!!defaultValue) {
            setVal(defaultValue)
        } else {
            setVal(null)
        }
    }, [defaultValue])

    return (
        <div
            className={`row items-center px-2 h-9 border border-placebo text-forged-steel rounded-lg ${disabled && 'bg-[#f6f6f6]'}`}>
            <input type={'text'} name={keyName} pattern={pattern} title={title}
                   className={"border-0 outline-0 px-3 w-full placeholder:italic"}
                   placeholder={placeholder} onWheel={(e) => e.target.blur()}
                   ref={inputRef}
                   value={val || ''}
                   defaultValue={val}
                   disabled={disabled}
                   maxLength={maxLength}
                   onChange={(e) => setValHandler(keyName, e.target.value)}
            />
        </div>
    )
}

export default NumberInputComponent
