import React, {useEffect} from "react";
import GeneralForm from "./general-form";
import FlashSaleProductForm from "./flash-sale-product-form";
import {FIELD_LIST_FLASH_SALE_GENERAL, FIELD_SELECT_PRODUCT} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setMainPageErrorMessage, setMainPageFlashSaleDetail} from "../../../reducers/main-page.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {convertErrorMessage, convertUtcToCustomFormat} from "../../../utils/general-variable";
import moment from "moment";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const FlashSale = () => {
    const dispatch = useDispatch()
    const {flashSaleDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {fetching: getFlashSale} = useFetch(API.HOMEPAGE_GET_FLASH_SALE)
    const {fetching: updateFlashSale, isLoading} = useFetch(API.HOMEPAGE_UPDATE_FLASH_SALE)
    let fieldListGeneral = FIELD_LIST_FLASH_SALE_GENERAL
    let fieldListFlashSaleProduct = FIELD_SELECT_PRODUCT
    let showFlashSale = {
        label: 'Flash Sale',
        key: 'show_flash_sale',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'General',
            content: <GeneralForm fieldList={fieldListGeneral}/>
        }, {
            title: 'Flash Sale Product',
            content: <FlashSaleProductForm fieldList={fieldListFlashSaleProduct[0]}/>
        }
    ]

    useEffect(() => {
        Promise.all([getFlashSale(), getProductList()]).then(response => {
            dispatch(setMainPageFlashSaleDetail({...response[0].data, delete_product: []}))
            fieldListFlashSaleProduct[0].dataList = response[1].data
        }).catch(() => {
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    const onChange = (key, value) => {
        dispatch(setMainPageFlashSaleDetail({[key]: value}))
    }

    const dataChecking = (field, value) => {
        dispatch(setMainPageErrorMessage({}))
        const flashSaleDetailCopy = cloneDeep(flashSaleDetail)
        let error = {}
        let dates = []
        field.forEach((item) => {
            switch (item.key){
                case 'start_date_time':
                case 'end_date_time':
                    if(value[item.key]?.value) {
                        flashSaleDetailCopy[item.key] = convertUtcToCustomFormat(value[item.key]?.value, "YYYY-MM-DD HH:mm")
                        dates.push(value[item.key]?.value)
                    } else {
                        flashSaleDetailCopy[item.key] = null
                    }

                    if(dates.length === 2){
                        const errorMessage = 'End Date Must be Greater Than Start Date'
                        if(!moment(dates[0]).isBefore(dates[1])) {
                            error['start_date_time'] = errorMessage
                            error['end_date_time'] = errorMessage
                        }
                    }
                    break
                default:
                    if (item.type === 'multiple_select_product') {
                        flashSaleDetailCopy[item.key] = flashSaleDetailCopy[item.key].map(item => item.id) ?? []
                    } else if (item.type === 'file_image') {
                        flashSaleDetailCopy[item.key] = flashSaleDetailCopy[item.key] || null
                    } else {
                        flashSaleDetailCopy[item.key] = value[item.key]?.value || null
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setMainPageErrorMessage({}))
            return {isValid: true, flash_sale: flashSaleDetailCopy}
        } else {
            dispatch(setMainPageErrorMessage(error))
            return {isValid: false, flash_sale: flashSaleDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let formValue = document.getElementById("form").elements
        const {isValid, flash_sale} = dataChecking([...fieldListGeneral, ...fieldListFlashSaleProduct], formValue)
        if (isValid) {
            updateFlashSale(flash_sale).then(response => {
                dispatch(setMainPageErrorMessage({}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Flash Sale Updated'))
                dispatch(setMainPageFlashSaleDetail({...response?.data, delete_product: []}))
            }).catch(err => {
                dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    return (
        <FormGeneralComponent
            data={flashSaleDetail}
            switchToggleField={showFlashSale}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default FlashSale