import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setAvoStoriesRecyclingProgressDetail} from "../../../reducers/avo-stories.reducer";

const ItemRecyclingProgress = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {recyclingProgressDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(recyclingProgressDetail?.item_list)
        copyItemList[index][key] = value
        dispatch(setAvoStoriesRecyclingProgressDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={recyclingProgressDetail?.item_list?.[index]?.[item.key]} error={avoStoriesErrorMessage[`item_list[${index}]`] ?? {}} onChangeInput={onChange}/>
            ))}
        </div>
    )
}

export default ItemRecyclingProgress
