import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMiraculousRetinolSeriesIntroDetail} from "../../../reducers/miraculous-retinol-series.reducer";

const InputField = ({fieldList}) => {
    const dispatch = useDispatch()
    const {introDetail, miraculousRetinolSeriesErrorMessage} = useSelector(state => state.miraculousRetinolSeriesReducer)

    const onChange = (key, value) => {
        dispatch(setMiraculousRetinolSeriesIntroDetail({[key]: value}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                <InputComponent item={fieldList[0]} data={introDetail?.[fieldList[0].key]} error={miraculousRetinolSeriesErrorMessage} onChange={onChange}/>
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, i) => (
                    <InputComponent key={i} item={item} data={introDetail?.[item.key]} error={miraculousRetinolSeriesErrorMessage} onChangeInput={onChange} onChange={onChange}/>
                ))}
            </div>
        </div>
    )
}

export default InputField