import {useDispatch, useSelector} from "react-redux";
import {
    FIELD_LIST_TIME_LINE_AVO_STORIES,
    FIELD_LIST_TIME_LINE_GENERAL_AVO_STORIES
} from "../../../utils/input-field-list-static-page";
import FormItemComponent from "../../../components/static-page/form-item.component";
import React, {useEffect} from "react";
import ItemTimeline from "./item-timeline";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {setAvoStoriesErrorMessage, setAvoStoriesTimelineDetail} from "../../../reducers/avo-stories.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";

const TimeLine = () => {
    const dispatch = useDispatch()
    const {timelineDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)
    const {fetching: getTimeline} = useFetch(API.AVO_STORIES_GET_TIMELINE)
    const {fetching: updateTimeline, isLoading} = useFetch(API.AVO_STORIES_UPDATE_TIMELINE)
    const itemList = [...timelineDetail.timelines]
    let fieldListGeneral = FIELD_LIST_TIME_LINE_GENERAL_AVO_STORIES
    let fieldList = FIELD_LIST_TIME_LINE_AVO_STORIES
    let showTimeline = {
        label: 'Timeline',
        key: 'show_timeline',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStoriesTimelineDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'timeline').toString(),
            year: null,
            image: null,
            short_description: null,
            long_description: null
        })
        dispatch(setAvoStoriesTimelineDetail({timelines: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(timelineDetail.timelines)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataTimeline = {...timelineDetail, timelines: dataItemList}
        updateTimeline(dataTimeline).then(response => {
            dispatch(setAvoStoriesErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Timeline Updated'))
            dispatch(setAvoStoriesTimelineDetail({...response?.data, delete_ids: []}))
        }).catch(err => {
            dispatch(setAvoStoriesErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getTimeline().then(response => {
            if (response?.data?.timelines?.length > 0) {
                dispatch(setAvoStoriesTimelineDetail({...response?.data, delete_ids: []}))
            } else {
                const data = response?.data
                delete data?.timelines
                dispatch(setAvoStoriesTimelineDetail({...data, delete_ids: []}))
            }
        })

        return () => {
            dispatch(setAvoStoriesErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={timelineDetail}
            switchToggleField={showTimeline}
            fieldListGeneral={fieldListGeneral}
            itemList={itemList}
            content={(index) => <ItemTimeline fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setAvoStoriesTimelineDetail}
            deleteList={timelineDetail?.delete_ids}
            objectName={'timelines'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            errorMessage={avoStoriesErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default TimeLine
