import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import FilterComponent from "../../components/filter.component";
import {SORT_LIST_VOUCHER} from "../../utils/sort-list";
import {STATE_LIST_GENERAL} from "../../utils/state-list";
import {DATA_LIMIT_LIST, FILTER_LIST_VOUCHER} from "../../utils/filter-list";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setVoucherSelectedRowKeys} from "../../reducers/voucher.reducer";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import React from "react";

const VoucherFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysVoucher} = useSelector(state => state.voucherReducer)
    const {fetching: changeStateApi} = useFetch(API.BULK_ACTION_VOUCHER, {}, {isFetching: false})
    const {fetching: exportVoucherList, isLoading: loadingExport} = useFetch(API.EXPORT_VOUCHER_LIST)
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const applyStatusHandler = (listId, status) => {
        let requestBody = {
            voucher_ids: listId,
            action: status
        }
        const message = status === 'delete' ? 'Delete Data Successfully' : 'Voucher State Updated'
        changeStateApi(requestBody)
            .then(() => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage(message))
                dispatch(setVoucherSelectedRowKeys([]))
                dispatch(setToolsReload(!reload))
            })
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_type: filter['tipe voucher'] ?? [],
            search: searchKey,
            voucher_ids: selectedRowKeysVoucher
        }
        exportVoucherList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Voucher-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"cari voucher..."}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_VOUCHER}/>
                    <FilterComponent filterList={FILTER_LIST_VOUCHER} labelName={'label'} keyName={'key'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data voucher ?`}
                            countSelected={selectedRowKeysVoucher.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
            <ChangeStateComponent
                wordingConfirmation={"Apakah anda ingin mengubah status voucher yang anda pilih ?"}
                wordingDeleteConfirmation={"Apakah anda yakin menghapus voucher yang anda pilih ?"}
                selectedRow={selectedRowKeysVoucher}
                stateList={STATE_LIST_GENERAL}
                applyStatusHandler={applyStatusHandler}/>
        </div>
    )
}

export default VoucherFilter
