import SearchComponent from "../../components/search.component";
import {SORT_LIST_PRODUCT} from "../../utils/sort-list";
import {STATE_LIST_GENERAL} from "../../utils/state-list";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import FilterComponent from "../../components/filter.component";
import React, {useEffect, useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {setProductSelectedRowKeys} from "../../reducers/product.reducer";
import {convertDateToCustomFormat, downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";
import AlertConfirmationComponent from "../../components/alert-confirmation.component";
import {LoadingSvg} from "../../utils/image";
import ModalCardComponent from "../../components/modal-card.component";
import ProgressBarComponent from "../../components/progress-bar.component";

const ProductFilter = ({lastSync}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysProduct} = useSelector(state => state.productReducer)
    const {data: categories} = useFetch(API.CATEGORIES, {}, {isFetching: true})
    const {fetching: postUpdateStatus, isLoading: loadingUpdateState} = useFetch(API.PRODUCT_UPDATE_STATUS)
    const {fetching: syncProducts, isLoading: loadingSyncProduct} = useFetch(API.SYNC_PRODUCTS)
    const {fetching: exportProductList, isLoading: loadingExport} = useFetch(API.EXPORT_PRODUCT_LIST)
    const [isProgress, setIsProgress] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const applyStatusHandler = (listId, status) => {
        const body = {
            product_ids: listId,
            action: status
        }
        postUpdateStatus(body).then(() => {
            const message = status === 'delete' ? 'Delete Data Successfully' : 'Product State Updated'
            showAlertModal('success', message)
        }).catch(err => {
            showAlertModal('warning', `${err?.message}`)
        })
        dispatch(setProductSelectedRowKeys([]))
    }

    const showAlertModal = (type, message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType(type))
        dispatch(setToolsAlertMessage(message))
        dispatch(setToolsReload(!reload))
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_category: filter['category'] ?? [],
            filter_by_status: filter['status'] ?? [],
            search: searchKey,
            product_ids: selectedRowKeysProduct
        }
        exportProductList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Product-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    const filterListProduct = [
        {
            title: 'category',
            list: categories?.data || []
        }, {
            title: 'status',
            list: [
                {
                    name: 'publish',
                    id: 'publish'
                }, {
                    name: 'draft',
                    id: 'draft'
                }
            ]
        }
    ]

    const onClickSyncProduct = () => {
        dispatch(setToolsAlertMessage(`Apakah anda yakin ingin melakukan sinkronisasi \ndata product Website Avoskin ke Qiscus Shop ?\n
         Last Sync: ${lastSync ? convertDateToCustomFormat(lastSync, "YYYY/MM/DD HH:mm") : ''}`))
        dispatch(setToolsModalVisibility({confirmation: true}))
        dispatch(setProductSelectedRowKeys([]))
    }

    const applySyncProduct = () => {
        setIsProgress(true)
        dispatch(setToolsModalVisibility({confirmation: false}))
    }

    useEffect(() => {
        if (percentage === 20) {
            syncProducts().then().catch(err => {
                setIsProgress(false)
                setPercentage(0)
                showAlertModal('warning', `${err?.message}`)
            })
        }
    }, [percentage])

    useEffect(() => {
        if (isProgress) {
            let timeOut = null

            if (percentage < 80) {
                timeOut = setTimeout(() => {
                    setPercentage(percentage + 20)
                }, 1000)
            } else if (!loadingSyncProduct) {
                timeOut = setTimeout(() => {
                    if (percentage === 100) {
                        setIsProgress(false)
                        setPercentage(0)
                        showAlertModal('success', 'Sync Product Successfully')
                    } else {
                        setPercentage(percentage + 20)
                    }
                }, 1000)
            }

            return () => {
                clearTimeout(timeOut)
            }
        }
    }, [isProgress, percentage, loadingSyncProduct])

    return (
        <div className={"row gap-y-3 justify-between my-5 flex-wrap"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={'cari product...'}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_PRODUCT}/>
                    <FilterComponent filterList={filterListProduct} labelName={'name'} keyName={'id'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data product ?`}
                            countSelected={selectedRowKeysProduct.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
            <div className={"row space-x-5"}>
                <div className={"col w-[100px] justify-center rounded-lg text-xs text-white px-2 bg-battleship-green hover:bg-monstera font-semibold cursor-pointer"}
                    onClick={!loadingSyncProduct ? onClickSyncProduct : undefined}>
                    {loadingSyncProduct ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : <p>Sync Product</p>}
                </div>
                <ChangeStateComponent
                    wordingConfirmation={"Apakah anda ingin mengubah status product yang anda pilih ?"}
                    wordingDeleteConfirmation={"Apakah anda yakin menghapus product yang anda pilih ?"}
                    selectedRow={selectedRowKeysProduct}
                    stateList={STATE_LIST_GENERAL}
                    isLoading={loadingUpdateState}
                    applyStatusHandler={applyStatusHandler}
                />
            </div>
            {selectedRowKeysProduct.length === 0 && <AlertConfirmationComponent onApply={applySyncProduct}/>}
            <ModalCardComponent
                visibility={isProgress}
                onClose={() => {}}
                content={
                    <div className={"relative bg-white rounded-xl"}>
                        <ProgressBarComponent percentage={percentage}/>
                    </div>
                }
            />
        </div>
    )
}

export default ProductFilter
