import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {cloneDeep} from "lodash";
import {setMainPageClaimDetail} from "../../../reducers/main-page.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {claimDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(claimDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMainPageClaimDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={claimDetail?.item_list?.[index]?.[item.key]} error={mainPageErrorMessage[`item_list[${index}]`] ?? {}} onChange={onChangeInput} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default Item