import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setBeautyFridayFlashSaleDetail} from "../../../reducers/beauty-friday.reducer";

const FlashSaleProductForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {flashSaleDetail, beautyFridayErrorMessage} = useSelector(state => state.beautyFridayReducer)

    const onChangeInput = (key, value, id) => {
        let deleteList = flashSaleDetail?.delete_product || []
        dispatch(setBeautyFridayFlashSaleDetail({[key]: value, delete_product: [...deleteList, id]}))
    }

    const onChangeProduct = (key, value) => {
        let deleteList = flashSaleDetail?.delete_product?.filter(item => item !== value?.id)
        let currentProduct = flashSaleDetail.select_product
        dispatch(setBeautyFridayFlashSaleDetail({[key]: currentProduct.concat(value), delete_product: deleteList || []}))
    }

    return (
        <InputComponent item={fieldList} data={flashSaleDetail[fieldList.key]} onChange={onChangeInput} onChangeProduct={onChangeProduct} error={beautyFridayErrorMessage}/>
    )
}

export default FlashSaleProductForm