const Sidebar = ({section, onChange, activeSidebar}) => {
    return (
        <div className={"col bg-doctor border border-fennel-stem rounded-md sticky top-[4.5rem] h-[80vh] overflow-hidden"}>
            <div className={`row justify-between text-monstera text-sm p-3 border-b border-fennel-stem bg-liberated-lime sticky top-0 ${section.page === 'homepage' ? 'block' : 'hidden'}`}>
                <p className={"font-semibold"}>Section Order</p>
            </div>
            <div className={"col space-y-2 text-monstera mx-2 py-4 overflow-y-scroll"}>
                {section?.list?.map((section, index) => (
                    <div key={index} onClick={() => onChange(section.key)}
                         className={`row p-4 cursor-pointer hover:bg-windmill-wings hover:rounded-md hover:border hover:border-fennel-stem ${activeSidebar === section.key && 'bg-windmill-wings rounded-md border border-fennel-stem'}`}>
                        <p className={"font-Montserrat-Bold"}>{section.label}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Sidebar
