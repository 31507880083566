import React from "react";
import TableComponent from "../../../components/table-component";

export const PointList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Point',
            dataIndex: 'point',
            classNameHeader: 'text-center',
            width: 200,
            render: (point) => <p className={`font-semibold text-center ${point < 0 ? 'text-spiced-coral' : 'text-dazzle'}`}>{point} Poin</p>
        }, {
            name: 'Description',
            dataIndex: 'description',
            className: 'text-forged-steel h-20'
        }, {
            name: 'Tanggal',
            dataIndex: 'created_at',
            classNameHeader: 'text-center',
            className: 'text-forged-steel text-center'
        }
    ]
  return (
      <TableComponent
          columns={columns}
          tableClassName={"font-Regular"}
          headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
          dataSource={dataSource}
          pagination={true}
          loading={isLoading}
      />
  )
}

export default PointList
