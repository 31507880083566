import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_INPUT_PRODUCT_RECOMMENDATION} from "../../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import InputField from "./input-field";
import {
    setBeautyFridayErrorMessage,
    setBeautyFridayProductRecommendationDetail
} from "../../../reducers/beauty-friday.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {convertErrorMessage} from "../../../utils/general-variable";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const ProductRecommendation = () => {
    const dispatch = useDispatch()
    const {productRecommendationDetail} = useSelector(state => state.beautyFridayReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {fetching: getProductRecommendation} = useFetch(API.BF_GET_PRODUCT_RECOMMENDATION)
    const {fetching: updateProductRecommendation, isLoading} = useFetch(API.BF_UPDATE_PRODUCT_RECOMMENDATION)
    let fieldListInput = FIELD_LIST_INPUT_PRODUCT_RECOMMENDATION
    let showProductRecommendation = {
        label: 'Product Recommendation',
        key: 'show_product_recommendation',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'Product Recommendation',
            content: <InputField fieldList={fieldListInput} />
        }
    ]

    const onChange = (key, value) => {
        dispatch(setBeautyFridayProductRecommendationDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const requestBody = {
            ...productRecommendationDetail,
            select_product: productRecommendationDetail.select_product.map(item => item.id) ?? []
        }
        updateProductRecommendation(requestBody).then(response => {
            dispatch(setBeautyFridayErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Product Recommendation Updated'))
            dispatch(setBeautyFridayProductRecommendationDetail({...response?.data, delete_product: []}))
        }).catch(err => {
            dispatch(setBeautyFridayErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        Promise.all([getProductRecommendation(), getProductList()]).then(response => {
            dispatch(setBeautyFridayProductRecommendationDetail({...response[0].data, delete_product: []}))
            fieldListInput[2].dataList = response[1].data
        }).catch(() => {
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setBeautyFridayErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={productRecommendationDetail}
            switchToggleField={showProductRecommendation}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default ProductRecommendation