import TabRevenueAndComparation from "./tab-revenue-and-comparation";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import ExportComponent from "../../components/export.component";
import TabRevenuePerDay from "./tab-revenue-per-day";
import TabRevenueGraph from "./tab-revenue-graph";
import {
    setToolsCurrentPage,
    setToolsInitialState, setToolsModalVisibility,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {dateRangeToday, downloadFileExport, formatDateToReadable} from "../../utils/general-variable";
import moment from "moment";

const RevenuePage = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {fetching: exportRevenue, isLoading: loadingExport} = useFetch(API.EXPORT_REVENUE)

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const exportDataHandler = () => {
        const queryParams = {
            startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
            endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
        }
        exportRevenue(queryParams, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Revenue-Analytic-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    useEffect(() => {
        dispatch(setToolsStartDateEndDate(dateRangeToday))
        return () => {
            dispatch(setToolsInitialState())
        }
    }, [])

    return (
        <div className={"col space-y-4 px-10 my-4"}>
            <div className={"row items-center justify-center space-x-4"}>
                <DateRangePickerComponent slug={'analytics'} onChange={getValueFromRangePickerSingle}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data analytics revenue ?`}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <TabRevenueAndComparation/>
            <TabRevenueGraph/>
            <TabRevenuePerDay/>
        </div>
    )
}

export default RevenuePage
