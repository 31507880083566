import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {cloneDeep} from "lodash";
import {setMainPageTestimonialDetail} from "../../../reducers/main-page.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {testimonialDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(testimonialDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMainPageTestimonialDetail({item_list: copyItemList}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[0].map((item, i) => (
                    <InputComponent key={i} data={testimonialDetail?.item_list?.[index]?.[item.key]} item={item} error={mainPageErrorMessage[`item_list[${index}]`] ?? {}} onChange={onChangeInput}/>
                ))}
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, i) => (
                    <InputComponent key={i} data={testimonialDetail?.item_list?.[index]?.[item.key]} item={item} error={mainPageErrorMessage[`item_list[${index}]`] ?? {}} onChangeInput={onChangeInput}/>
                ))}
            </div>
        </div>
    )
}

export default Item