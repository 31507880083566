import {convertDateToCustomFormat} from "../../utils/general-variable";
import React from "react";
import {useSelector} from "react-redux";

const HistoryBeautyEmpties = () => {
    const {beautyEmptiesDetail} = useSelector(state => state.beautyEmptiesReducer)

    return (
        <div className={"col space-y-8 font-regular py-5 bg-doctor border border-fennel-stem rounded-lg"}>
            <p className={"font-Montserrat-Bold text-sm text-monstera text-center"}>Beauty Empties History</p>
            <div className={"px-5 max-h-[18rem] overflow-scroll"}>
                {beautyEmptiesDetail?.logs.map((history, index) => (
                    <div key={index} className={"row items-start space-x-5"}>
                        <div className={"col items-center"}>
                            <div className={`border-2 p-1 rounded-full 
                            ${index === 0 ? 'border-monstera bg-white' : 'border-doctor bg-doctor'}`}>
                                <div className={`p-[6px] rounded-full 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                            </div>
                            {beautyEmptiesDetail?.logs.length - 1 !== index && (
                                <div className={`w-[2px] h-14 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                            )}
                        </div>
                        <div className={"col space-y-1 text-eerie-black"}>
                            <div className={"max-h-12 overflow-scroll"}>
                                <p>{history.title}</p>
                                <p>{history.description}</p>
                            </div>
                            <p className={"italic"}>{convertDateToCustomFormat(history.created_at, 'DD MMMM, HH:mm')}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HistoryBeautyEmpties
