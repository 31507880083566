import React, {useEffect, useRef, useState} from "react";
import {useFetch} from "../services/useFetch";
import {SearchIcon, XCircleIcon} from "../utils/image";
import {useSelector} from "react-redux";
import {useClickOutside} from "../services/useClickOutside";

const MultipleSelectFilterComponent = (props) => {
    const searchData = useRef()
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const {item, onChange, data, api} = props
    const [listSelected, setListSelected] = useState([])
    const [isShowListOption, setIsShowListOption] = useState(false)
    const [listOption, setListOption] = useState([])
    const {fetching: searchList} = useFetch(api)

    useEffect(() => {
        setListSelected(data)
    }, [data])

    useEffect(() => {
        searchData.current.value = ''
    }, [modalVisibility.filter])

    const searchListOption = (e) => {
        if (e.key === 'Enter') {
            let searchKey = e.target.value
            searchList({search: searchKey})
                .then(response => {
                    const listIdSelected = listSelected.map(list => list.id || list.code)
                    const filterListOption = response?.data?.filter(list => {
                        return !listIdSelected.includes(list.id)
                    })
                    setListOption(filterListOption)
                    setIsShowListOption(true)
                })
                .catch(err => console.log(err))
        }
    }

    const deleteItem = (itemSelected) => {
        const deleteDataSelected = listSelected.filter(list => list.id !== itemSelected?.id || list.code !== itemSelected?.code)
        setListSelected(deleteDataSelected)
        onChange(item.key, deleteDataSelected)
    }

    const selectOption = (itemSelected) => {
        setIsShowListOption(false)
        let newDataSelected = listSelected.concat(itemSelected)
        setListSelected(newDataSelected)
        onChange(item.key, newDataSelected)
    }

    useClickOutside(`multiple-select-component-${item.key}`, isShowListOption, setIsShowListOption)

    return (
        <div id={`multiple-select-component-${item.key}`} className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${listSelected.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} name={item.key} id={item.key}
                       placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                       onKeyUp={searchListOption} ref={searchData}/>
            </div>
            <div className={listSelected.length > 0 ? 'block' : 'hidden'}>
                <div
                    className={"col space-y-5 p-5 bg-white border-x border-b rounded-b-lg border-placebo max-h-32 overflow-scroll"}>
                    {listSelected.map((list, index) => (
                        <div key={index} className={"row items-center space-x-4 mr-4"}>
                            <img src={XCircleIcon} alt="deleteList" className={"h-5 cursor-pointer"}
                                 onClick={() => deleteItem(list)}/>
                            <p className={"w-fit"}>{list[item.selectLabel]}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${isShowListOption ? 'block' : 'hidden'} col absolute w-11/12 top-8 left-8 bg-white shadow rounded-lg max-h-52 overflow-scroll z-10`}>
                {listOption.length > 0 ? listOption?.map((list, index) => (
                    <div key={index}
                         className={"row hover:bg-apple-flower hover:text-monstera items-center p-3 space-x-4 cursor-pointer"}
                         onClick={() => selectOption(list)}>
                        <p>{list[item.selectLabel]}</p>
                    </div>
                )) : <p className={"p-3"}>Not Found</p>}
            </div>
        </div>
    )
}

export default MultipleSelectFilterComponent
