import SearchComponent from "../../components/search.component";
import React from "react";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";

const OrderFilter = () => {
    return (
        <div className={"row space-x-4 w-fit"}>
            <SearchComponent placeholder={'cari nomor order...'}/>
            <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
        </div>
    )
}

export default OrderFilter