import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNotificationDetail} from "../../reducers/notification.reducer";
import {formatDateToReadable} from "../../utils/general-variable";
import SelectDateRange from "./select-date-range";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";

const ReceiverForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {notificationDetail, notificationErrorMessage} = useSelector(state => state.notificationReducer)

    const onChange = (key, value) => {
        dispatch(setNotificationDetail({[key]: value}))
    }

    const onChangeProduct = (key, value) => {
        const currentProduct = notificationDetail.receiver_product_ids
        dispatch(setNotificationDetail({[key]: currentProduct.concat(value)}))
    }

    const onChangeSelectType = (dataItem, key) => {
        dispatch(setNotificationDetail({[key]: dataItem?.key}))
    }

    const onChangeDateRangePicker = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setNotificationDetail({
            receiver_start_date: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            receiver_end_date: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null
        }))
    }

    const showField = () => {
        switch (notificationDetail?.receiver_type) {
            case 'email':
                return (
                    <div className={"w-full"}>
                        <InputComponent item={fieldList[1]} onChange={onChange} data={notificationDetail[fieldList[1].key] ?? []}
                                        error={notificationErrorMessage}/>
                    </div>
                )
            case 'order':
                return (
                    <div className={"row space-x-16"}>
                        <div className={"w-1/2"}>
                            <InputComponent item={fieldList[2]} onChange={onChange} onChangeProduct={onChangeProduct} data={notificationDetail[fieldList[2].key] ?? []}
                                            error={notificationErrorMessage}/>
                        </div>
                        <div className={"col space-y-4 w-1/2"}>
                            <SelectDateRange item={fieldList[3][0]} onChange={onChangeDateRangePicker} error={notificationErrorMessage}/>
                            <InputComponent item={fieldList[3][1]} data={notificationDetail[fieldList[3][1].key]} error={notificationErrorMessage}/>
                        </div>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <div className={"col space-y-4"}>
            <div className={"col w-1/2"}>
                <InputComponent item={fieldList[0]} onChangeSelect={onChangeSelectType} data={notificationDetail[fieldList[0].key]}
                                error={notificationErrorMessage}/>
            </div>
            {showField()}
        </div>
    )
}

export default ReceiverForm
