import {SORT_LIST_REVENUE} from "../../utils/sort-list";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";

const RevenueFilter = () => {
    return (
        <div className={"row space-x-4 w-fit"}>
            <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
            <SortComponent sortList={SORT_LIST_REVENUE}/>
        </div>
    )
}

export default RevenueFilter