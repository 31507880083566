import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_INPUT_BENEFIT_HOME_PAGE} from "../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import {setMainPageBenefitDetail, setMainPageErrorMessage} from "../../reducers/main-page.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Benefit = () => {
    const dispatch = useDispatch()
    const {benefitDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)
    const {fetching: getBenefit} = useFetch(API.HOMEPAGE_GET_BENEFIT)
    const {fetching: updateBenefit, isLoading} = useFetch(API.HOMEPAGE_UPDATE_BENEFIT)
    let fieldListInput = FIELD_LIST_INPUT_BENEFIT_HOME_PAGE
    let showBenefit = {
        label: 'Benefit',
        key: 'show_benefit',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageBenefitDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateBenefit(benefitDetail).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Benefit Updated'))
            dispatch(setMainPageBenefitDetail(response?.data))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getBenefit().then(response => {
            dispatch(setMainPageBenefitDetail(response?.data))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={benefitDetail}
            switchToggleField={showBenefit}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={mainPageErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Benefit