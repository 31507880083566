import {Link} from "react-router-dom";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {
    setAnalyticsOrderAdvanceFilter,
    setAnalyticsOrderDateRangeAnalyticVoucher
} from "../../reducers/analytics-order.reducer";

const VoucherList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)

    const setFilterAnalyticsVoucher = (voucher_code) => {
        dispatch(setAnalyticsOrderAdvanceFilter({voucher_codes: [voucher_code]}))
        dispatch(setAnalyticsOrderDateRangeAnalyticVoucher(dateRange))
    }

    const columns = [
        {
            name: 'Voucher Code',
            classNameHeader: 'text-center',
            width: 450,
            dataIndex: 'voucher_code',
            className: 'pl-4 font-semibold'
        }, {
            name: 'Voucher Type',
            classNameHeader: 'text-center',
            dataIndex: 'voucher_type'
        }, {
            name: 'Total Order',
            classNameHeader: 'text-center',
            render: (record) => (
                <Link to={`/analytics/order`} onClick={() => setFilterAnalyticsVoucher(record?.voucher_code)}>
                    <p className={"font-regular text-dazzle text-center hover:font-Montserrat-Bold hover:underline p-4"}>{record?.total_order}</p>
                </Link>
            )
        }, {
            name: 'Amount Discount',
            classNameHeader: 'text-center',
            dataIndex: 'total_amount',
            render: (amount_discount) => (
                <p className={"font-regular text-forged-steel text-end"}>{doConvertNumberToRupiahFormat(amount_discount)}</p>
            )
        }
    ]

    return (
        <div className={"col space-y-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default VoucherList
