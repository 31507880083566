import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsModalVisibility} from "../reducers/tools.reducer";
import {WarningCircleIcon} from "../utils/image";

const ToastComponent = () => {
    const dispatch = useDispatch()
    const {modalVisibility, alertMessage} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        if (modalVisibility.toast) {
           setTimeout(() => {
               dispatch(setToolsModalVisibility({toast: false}))
           }, 1000)
        }
    }, [modalVisibility.toast])

    return (
        <div className={`col fixed top-5 w-full z-20 ${modalVisibility.toast ? 'block' : 'hidden'}`}>
            <div className={"row justify-center"}>
                <div className={"row space-x-2 items-center text-sm bg-white shadow-xl border border-placebo rounded-lg p-1"}>
                    <img src={WarningCircleIcon} alt="WarningIcon" className={"w-8"}/>
                    <p className={"pr-2"}>{alertMessage}</p>
                </div>
            </div>
        </div>
    )
}

export default ToastComponent
