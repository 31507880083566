import {FIELD_LIST_VIDEO_MIRACULOUS_RETINOL_SERIES} from "../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setMiraculousRetinolSeriesVideoDetail} from "../../reducers/miraculous-retinol-series.reducer";
import FormGeneralComponent from "../../components/static-page/form-general.component";
import React from "react";

const Video = () => {
    const dispatch = useDispatch()
    const {videoDetail, miraculousRetinolSeriesErrorMessage} = useSelector(state => state.miraculousRetinolSeriesReducer)
    let fieldList = FIELD_LIST_VIDEO_MIRACULOUS_RETINOL_SERIES
    let showVideo = {
        label: 'Video',
        key: 'show_video',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMiraculousRetinolSeriesVideoDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(videoDetail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={videoDetail}
            switchToggleField={showVideo}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={miraculousRetinolSeriesErrorMessage}
        />
    )
}

export default Video