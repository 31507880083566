import DateRangePickerComponent from "../../components/date-range-picker.component";
import React from "react";

const SelectDateRange = ({item, onChange, error}) => {
    return (
        <div className={"col space-y-2"}>
            <label className={"font-semibold text-monstera"}>{item.label}</label>
            <DateRangePickerComponent onChange={onChange}/>
            {!!item.description && <p className={"italic mt-2"}>{item.description}</p>}
            {error[item.key] && <p className={"font-semibold text-red-600 italic mt-2"}>{error[item.key]}</p>}
        </div>
    )
}

export default SelectDateRange
