import {infoIcon, questionIcon} from "../utils/image"
import {useState} from "react";

const TooltipComponent = (props) => {
    const {text, slug, iconType} = props
    const [showModal, setShowModal] = useState(false)

    const showHideModal = () => {
        setShowModal(!showModal)
    }

    return (
        <div className={"relative cursor-pointer font-regular"} onClick={showHideModal}>
            <img src={iconType === 'question' ? questionIcon : infoIcon} alt="info" className={"w-4"}/>
            <div className={`absolute left-0 z-10 ${slug === 'table' ? 'w-24' : 'w-56'} ${showModal ? 'block' : 'hidden'}`}>
                <div className={"mt-2 p-2 bg-white text-forged-steel border border-monstera rounded-lg"}>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default TooltipComponent