import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../components/table-component";
import React from "react";
import {setAffiliatePerformanceSelectedRowKeys} from "../../reducers/affiliate.reducer";
import TotalDataComponent from "../../components/total-data.component";
import { doConvertNumberToRupiahFormat } from "../../utils/general-variable";
import {Link} from "react-router-dom";
import {setOrderAdvanceFilter} from "../../reducers/order.reducer";

const AffiliatePerformanceList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysAffiliatePerformance} = useSelector(state => state.affiliateReducer)

    const setAdvanceFilterTotalOrder = (id, code, status) => {
        dispatch(setOrderAdvanceFilter({affiliatorId: [{id, code}], status}))
    }

    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Name',
            classNameHeader: 'pl-5',
            className: 'pl-5 capitalize',
            dataIndex: 'name'
        }, {
            name: 'Campaign',
            dataIndex: 'campaign',
            render: (value) => value || '-'
        }, {
            name: 'Unique Visit',
            dataIndex: 'unique_visit',
            classNameHeader: 'text-center',
            className: 'text-center',
        }, {
            name: 'Total Order',
            classNameHeader: 'text-center',
            className: 'text-center',
            render: (record) => (
                <Link to={`/order/order-list`} onClick={() => setAdvanceFilterTotalOrder(record?.id, record?.code, [])}>
                    <p className={"text-dazzle text-center hover:font-Montserrat-Bold hover:underline p-4"}>{record?.total_order}</p>
                </Link>
            )
        }, {
            name: 'Total Conversion',
            classNameHeader: 'text-center',
            className: 'text-center',
            render: (record) => (
                <Link to={`/order/order-list`}
                      onClick={() => setAdvanceFilterTotalOrder(record?.id, record?.code, ['processing', 'packing', 'on-delivery', 'completed'])}>
                    <p className={"text-dazzle text-center hover:font-Montserrat-Bold hover:underline p-4"}>{record?.total_order_convertion}</p>
                </Link>
            )
        }, {
            name: 'Total Revenue',
            dataIndex: 'total_revenue',
            classNameHeader: 'text-center',
            render: (total_revenue) => (
                <p className={"text-right font-regular text-forged-steel pr-5"}>
                    {doConvertNumberToRupiahFormat(total_revenue)}
                </p>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setAffiliatePerformanceSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Affiliate"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysAffiliatePerformance}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default AffiliatePerformanceList
