const STATE_LIST_USER = [
    {
        label: 'Active',
        key: 'active'
    }, {
        label: 'Inactive',
        key: 'inactive'
    }, {
        label: 'Delete',
        key: 'delete'
    }
]

const STATE_LIST_ORDER = [
    {
        label: 'On Hold',
        key: 'on-hold'
    }, {
        label: 'Pending Payment',
        key: 'pending-payment'
    }, {
        label: 'Already Paid',
        key: 'processing'
    }, {
        label: 'Packing',
        key: 'packing'
    }, {
        label: 'On Delivery',
        key: 'on-delivery'
    }, {
        label: 'Completed',
        key: 'completed'
    }, {
        label: 'Cancelled',
        key: 'cancelled'
    }
]

//State List for Product, Avostore, and Voucher
const STATE_LIST_GENERAL = [
    {
        label: 'Publish',
        key: 'publish'
    }, {
        label: 'Save As Draft',
        key: 'draft'
    }, {
        label: 'Delete',
        key: 'delete'
    }
]

const STATE_LIST_BEAUTY_EMPTIES = [
    {
        label: 'Accepted',
        key: 'accepted'
    }, {
        label: 'Rejected',
        key: 'rejected'
    }
]

export {
    STATE_LIST_USER,
    STATE_LIST_ORDER,
    STATE_LIST_GENERAL,
    STATE_LIST_BEAUTY_EMPTIES
}
