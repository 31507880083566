import {createSlice} from '@reduxjs/toolkit'

export const reviewSlice = createSlice({
    name: 'review',
    initialState: {
        selectedRowKeysReview: [],
        activeTab: 'skin-types',
        activeSidebar: '',
        reviewData: {},
        reviewList: [],
        user_choices: []
    },
    reducers: {
        setReviewSelectedRowKeys: (state, action) => {
            state.selectedRowKeysReview = action.payload
        },
        setReviewActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setReviewActiveSidebar: (state, action) => {
            state.activeSidebar = action.payload
        },
        setReviewUserChoices: (state, action) => {
            state.user_choices = action.payload
        },
        setReviewList: (state, action) => {
            state.reviewList = action.payload
        },
        setReviewData: (state, action) => {
            state.reviewData = action.payload
        },
        setReviewInitialState: (state) => {
            state.selectedRowKeysReview = []
            state.selectedRowKeysUserReview = []
            state.activeTab = 'skin-types'
            state.activeSidebar = ''
        }
    },
})

export const {
    setReviewSelectedRowKeys,
    setReviewActiveTab,
    setReviewActiveSidebar,
    setReviewList,
    setReviewUserChoices,
    setReviewData,
    setReviewInitialState
} = reviewSlice.actions
const reviewReducer = reviewSlice.reducer

export default reviewReducer
