import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetail} from "../../reducers/product.reducer";

const ProductInformationForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {productDetail, productErrorMessage} = useSelector(state => state.productReducer)

    const onChangeInput = (key, value) => {
        dispatch(setProductDetail({[key]: value}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} onChange={onChangeInput}
                                data={productDetail[item.key]}
                                error={productErrorMessage}/>
            ))}
        </div>
    )
}

export default ProductInformationForm
