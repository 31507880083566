import React from "react";
import {ImportAvostoreSuccessIcon} from "../../utils/image";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Done = () => {
    const {importResult} = useSelector(state => state.avostoreReducer)

    return (
        <div className={"col space-y-8 py-6 border border-whisper-green rounded-lg"}>
            <div className={"col items-center justify-center space-y-4 border-b border-whisper-green py-10"}>
                <img src={ImportAvostoreSuccessIcon} alt="ImportSuccess" className={"w-40"}/>
                <p className={"text-lg text-monstera"}>Import Complete</p>
                <p className={"text-red-600"}>{importResult?.message}</p>
            </div>
            <div className={"row justify-center cursor-pointer"}>
                <Link to={'/avostore/avostore-list'} className={"font-Montserrat-Bold text-white bg-battleship-green hover:bg-monstera px-16 py-4 rounded-lg"}>
                    <p>View Avostore List</p>
                </Link>
            </div>
        </div>
    )
}

export default Done
