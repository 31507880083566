import {useDispatch, useSelector} from "react-redux";
import React, {useRef} from "react";
import {LoadingSvg, PlusCircleIcon, TrashIcon} from "../../../utils/image";
import {setWaste4ChangeBenefitDetail} from "../../../reducers/waste-4-change.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {RULES_IMAGE_UPLOAD} from "../../../utils/constant";
import {imageValidate} from "../../../utils/general-variable";

const UploadMultipleImageBenefit = ({itemKey}) => {
    const dispatch = useDispatch()
    const {images} = useSelector(state => state.waste4ChangeReducer.benefitDetail)
    const {fetching: uploadPicture, isLoading} = useFetch(API.PAGE_UPLOAD_IMAGE)
    const imageUploaded = useRef()

    const uploadImageHandler = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        const {isValid, message} = imageValidate(file)

        if (file) reader.readAsDataURL(file)

        if (isValid) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    uploadPicture({image: file}, null, {'Content-Type': 'multipart/form-data'}).then(response => {
                        const url = response?.data?.url
                        dispatch(setWaste4ChangeBenefitDetail({[itemKey]: [...images, url]}))
                    })
                    imageUploaded.current.value = null
                }
            }
        } else {
            showAlertModal(message)
        }
    }

    const removeImageHandler = (id) => {
        dispatch(setWaste4ChangeBenefitDetail({[itemKey]: images.filter((_, index) => index !== id)}))
        imageUploaded.current.value = null
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }

    return (
        <>
            <div className={"col space-y-2"}>
                <div className={"grid grid-cols-8 gap-y-4"}>
                    {images.map((i, index) => (
                        <div key={index} className={"w-fit relative group"}>
                            <img src={i} alt="imageUploaded"
                                 className={"w-20 h-20 border border-placebo rounded-full group-hover:border-monstera group-hover:opacity-50"}/>
                            <div className={"invisible group-hover:visible"}>
                                <img src={TrashIcon} alt={"MinusCircleIcon"}
                                     className={"w-8 absolute top-0 -right-1 cursor-pointer bg-white rounded-full p-2 hover:shadow-lg"}
                                     onClick={() => removeImageHandler(index)}/>
                            </div>
                        </div>
                    ))}
                    {isLoading &&
                        <div className={"w-fit relative group"}>
                            <img src={LoadingSvg} className={"w-20 mx-auto"}/>
                        </div>
                    }
                    <label htmlFor={itemKey}
                           className={"col items-center justify-center w-20 h-20 border border-monstera rounded-md bg-windmill-wings"}>
                        <img src={PlusCircleIcon} alt="PlusCircleIcon" className={"w-7 cursor-pointer"}/>
                    </label>
                    <input id={itemKey} type={"file"} className={'absolute top-1 invisible'} ref={imageUploaded}
                           onChange={uploadImageHandler}/>
                </div>
            </div>
        </>
    )
}

export default UploadMultipleImageBenefit