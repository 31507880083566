import {convertDateToCustomFormat, getExpeditionLogo} from "../../utils/general-variable";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import React from "react";

const TrackingOrder = () => {
    const {id} = useParams()
    const {
        data: orderTracking,
        isLoading
    } = useFetch({...API.ORDER_TRACKING, ...{initPathVariable: id}}, {}, {isFetching: true})

    return (
        <div className={"col divide-y divide-fortress-grey font-regular border border-fortress-grey rounded-lg"}>
            {
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <>
                        <div className={"col items-center space-y-5 py-5"}>
                            <p className={"font-Montserrat-Bold text-sm text-monstera"}>Expedition</p>
                            <div className={"row space-x-4 items-center text-forged-steel"}>
                                <img src={getExpeditionLogo(orderTracking?.data?.shippingMethod)} alt="expeditionLogo"
                                     className={"w-16"}/>
                                <div className={"col space-y-1"}>
                                    <p className={"font-semibold"}>No Resi</p>
                                    <p>{orderTracking?.data?.awb}</p>
                                </div>
                                <div className={"col space-y-1"}>
                                    <p className={"font-semibold"}>{orderTracking?.data?.shippingMethod}</p>
                                    <p>{orderTracking?.data?.destination}</p>
                                </div>
                            </div>
                        </div>
                        <div className={"px-5 py-8 max-h-[22rem] overflow-scroll"}>
                            {
                                orderTracking?.data?.history.length > 0 ?
                                    orderTracking?.data?.history.map((trackingOrder, index) => (
                                        <div key={index} className={"row items-start space-x-5"}>
                                            <div className={"col items-center"}>
                                                <div className={`border-2 p-1 rounded-full 
                                                ${index === 0 ? 'border-monstera bg-white' : 'border-white bg-white'}`}>
                                                    <div className={`p-[6px] rounded-full 
                                                    ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                                                </div>
                                                {orderTracking?.data?.history.length - 1 !== index && (
                                                    <div className={`w-[2px] h-14 
                                                    ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                                                )}
                                            </div>
                                            <div className={"col space-y-1 text-eerie-black"}>
                                                <p className={"max-h-12 overflow-scroll"}>{trackingOrder.description}</p>
                                                <p className={"italic"}>{convertDateToCustomFormat(trackingOrder.timestamp, 'DD MMMM, HH:mm')}</p>
                                            </div>
                                        </div>
                                    )) : <p className={"text-monstera"}>Tracking Not Found</p>
                            }
                        </div>
                    </>
            }
        </div>
    )
}
export default TrackingOrder