import SearchComponent from "../../components/search.component";
import {SORT_LIST_GIFT} from "../../utils/sort-list";
import {STATE_LIST_GENERAL} from "../../utils/state-list";
import {DATA_LIMIT_LIST, FILTER_LIST_GIFT} from "../../utils/filter-list";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import React from "react";
import FilterComponent from "../../components/filter.component";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setGiftSelectedRowKeys} from "../../reducers/gift.reducer";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";

const GiftFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysGift} = useSelector(state => state.giftReducer)
    const {fetching: postUpdateStatus, isLoading: loadingUpdateState} = useFetch(API.GIFT_UPDATE_STATUS)
    const {fetching: exportGiftList, isLoading: loadingExport} = useFetch(API.EXPORT_GIFT_LIST)
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const applyStatusHandler = (listId, status) => {
        const body = {
            gift_ids: listId,
            action: status
        }
        dispatch(setGiftSelectedRowKeys([]))
        postUpdateStatus(body).then(() => {
            const message = status === 'delete' ? 'Delete Data Successfully' : 'Gift State Updated'
            showAlertModal('success', message)
        }).catch(err => {
            showAlertModal('warning', `${err?.message}`)
        })
    }

    const showAlertModal = (type, message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType(type))
        dispatch(setToolsAlertMessage(message))
        dispatch(setToolsReload(!reload))
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_status: filter['status'] ?? [],
            search: searchKey,
            gift_ids: selectedRowKeysGift
        }
        exportGiftList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Gift-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={'cari gift...'}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_GIFT}/>
                    <FilterComponent filterList={FILTER_LIST_GIFT} labelName={'name'} keyName={'id'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data gift ?`}
                            countSelected={selectedRowKeysGift.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
            <ChangeStateComponent
                wordingConfirmation={"Apakah anda ingin mengubah status gift yang anda pilih ?"}
                wordingDeleteConfirmation={"Apakah anda yakin menghapus gift yang anda pilih ?"}
                selectedRow={selectedRowKeysGift}
                stateList={STATE_LIST_GENERAL}
                isLoading={loadingUpdateState}
                applyStatusHandler={applyStatusHandler}
            />
        </div>
    )
}

export default GiftFilter
