import {useDispatch} from "react-redux";
import {FIELD_LIST_MOBILE_VERSION} from "../../utils/input-field-list";
import React, {useEffect, useState} from "react";
import InputComponent from "../../components/input.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {checkFieldValidate} from "../../utils/general-variable";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {LoadingSvg} from "../../utils/image";

const MobileVersionPage = () => {
    const dispatch = useDispatch()
    const {data: mobileVersionDetail} = useFetch(API.GET_APP_VERSION, {}, {isFetching: true})
    const {fetching: updateAppVersion, isLoading} = useFetch(API.UPDATE_APP_VERSION)
    const [fieldList, setFieldList] = useState(FIELD_LIST_MOBILE_VERSION)

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const requestBody = {
            ios: elements.ios.value,
            android: elements.android.value
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            updateAppVersion(requestBody).then(() => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('App Version Updated'))
            })
        }
    }

    useEffect(() => {
        return () => {
            setFieldList(fieldList.map(item => delete item.errorText))
        }
    }, [])

    return (
        <div className={"col px-10"}>
            {!!mobileVersionDetail?.data?.length > 0 &&
                <form onSubmit={onSubmitHandler} className={"space-y-4"}>
                    <div className={"col space-y-5 w-1/2"}>
                        {fieldList.map((item, index) => (
                            <div key={index} className={"col space-y-3"}>
                                <div className={"col space-y-1"}>
                                    <p className={"font-semibold text-md capitalize"}>{mobileVersionDetail?.data[index]?.platform}</p>
                                    <p className={"font-medium text-forged-steel"}>App-versioning for <span className={"capitalize"}>{mobileVersionDetail?.data[index]?.platform}</span></p>
                                </div>
                                <InputComponent item={item} data={mobileVersionDetail?.data[index]?.latest_version} error={{[item.key]: item.errorText}}/>
                            </div>
                        ))}
                    </div>
                    <div className={"row font-semibold justify-center py-8"}>
                        <button className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                            {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Update Version'}
                        </button>
                    </div>
                </form>
            }
        </div>
    )
}

export default MobileVersionPage
