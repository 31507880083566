import UploadImageComponent from "./upload-image.component";
import SwitchToggleComponent from "./switch-toggle.component";
import SelectComponent from "./select.component";
import MultipleSelectProductComponent from "./multiple-select-product.component";
import CheckboxListComponent from "./checkbox-list.component";
import DatePickerComponent from "./date-picker.component";
import React, {useRef} from "react";
import TextEditorComponent from "./text-editor.component";
import UploadMultipleImageComponent from "./upload-multiple-image.component";
import TooltipComponent from "./tooltip.component";
import {KeyIcon} from "../utils/image";
import {convertDateToCustomFormat, randomCode} from "../utils/general-variable";
import UploadMainImageComponent from "./upload-main-image.component";
import SearchSelectComponent from "./search-select.component";
import SearchSelectAddressComponent from "./search-select-address.component";
import MultipleSelectListComponent from "./multiple-select-list.component";
import UploadImageBannerProductComponent from "./upload-image-banner-product.component";
import CheckboxItemComponent from "./checkbox-item.component";
import RadioComponent from "./radio.component";
import DefaultInputComponent from "./default-input.component";
import NumberInputComponent from "./number-input.component";
import TextAreaComponent from "./text-area.component";
import MultipleSelectSearchComponent from "./multiple-select-search.component";
import CheckboxListFilterComponent from "./checkbox-list-filter.component";
import MultipleSelectFilterComponent from "./multiple-select-filter.component";

const InputComponent = (props) => {
    const {
        item,
        voucherType = '',
        onChange,
        data,
        disabled,
        inputRef,
        onChangeSelect,
        onChangeSearch,
        error,
        onChangeProduct,
        onChangeInput,
        onChangeDate,
        onChangeNumber,
        dateRange,
        api
    } = props
    const {
        label,
        key,
        placeholder,
        type,
        subType,
        checkboxLabel,
        tooltipText,
        pattern,
        title,
        selectLabel,
        isHide,
        selectValue,
        maxLength,
        description
    } = item
    const passwordRef = useRef()
    let defaultData = null

    const generatePasswordHandler = () => {
        passwordRef.current.value = randomCode(8, 1, '', true).toString()
    }

    switch (item.type) {
        case 'checkbox':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <div className={"row items-center space-x-4"}>
                        <CheckboxItemComponent itemKey={key} data={data}/>
                        <label htmlFor={key} className={"text-forged-steel"}>{checkboxLabel}</label>
                    </div>
                </div>
            )
        case 'radio':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <div className={"row space-x-4"}>
                        {item.radioList.map((list, index) => <RadioComponent key={index} name={key} radioItem={list}
                                                                             data={data} onChange={onChange}/>)}
                    </div>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'text_editor':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <TextEditorComponent item={item} onChangeTextEditor={onChange}
                                         defaultValue={!!data ? data : null}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'file_image':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <UploadImageComponent item={item} onChangeUploadImage={onChange}
                                          dataImage={!!data ? data : null}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'file_main_image':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <UploadMainImageComponent item={item}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_file_image':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <UploadMultipleImageComponent item={item}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_file_image_banner':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <UploadImageBannerProductComponent item={item} onChangeMultipleImage={onChange}
                                                       defaultValue={data}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'switch_toggle':
            if (!isHide) {
                return (
                    <div
                        className={`${key === 'voucher_auto_apply' ? 'row items-center justify-between' : 'col space-y-2'}`}>
                        <div className={"row items-center space-x-4"}>
                            <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                            {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                        </div>
                        <SwitchToggleComponent item={item} defaultValue={!!data ? data : false}
                                               onChangeSwitchToggle={onChange}/>
                    </div>
                )
            }
            break;
        case 'select':
            if (!!data) {
                defaultData = item?.options.find(i => i[selectValue] === data || i[selectLabel] === data)
            }
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <SelectComponent item={item} onChangeSelect={onChangeSelect} defaultValue={defaultData}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'search_select':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <SearchSelectComponent item={item} onChangeSelect={onChange}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'search_select_address':
            if (!!data) {
                switch (key) {
                    case 'city_and_district':
                        defaultData = data?.district && {label: `${data?.district}, ${data?.city}, ${data?.province}`}
                        break
                    case 'zipcode':
                        defaultData = data?.zipcode && {label: data?.zipcode}
                        break
                }
            }
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <SearchSelectAddressComponent item={item} onChangeSelect={onChangeSelect}
                                                  onChangeSearch={onChangeSearch} defaultValue={defaultData}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_select_filter':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <MultipleSelectFilterComponent item={item} data={data} onChange={onChange} api={api}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_select_product':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <MultipleSelectProductComponent item={item} data={data} type={subType || voucherType}
                                                    onChangeMultipleSelect={onChangeProduct} onChange={onChange}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_select_list':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <MultipleSelectListComponent item={item} type={subType}
                                                 onChangeMultipleSelect={onChange}
                                                 defaultValue={!!data ? data : null}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'multiple_select_search':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <MultipleSelectSearchComponent item={item} type={subType}
                                                 fetchValue={onChange}
                                                 defaultValue={!!data ? data : null}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'checkbox_list':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <CheckboxListComponent item={item} data={data}/>
                </div>
            )
        case 'checkbox_list_filter':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <CheckboxListFilterComponent item={item} data={data} onChange={onChange}/>
                </div>
            )
        case 'date':
            if (!!data) {
                switch (key) {
                    case 'sale_start':
                    case 'sale_end':
                    case 'date_start':
                    case 'date_expires':
                    case 'start_date_time':
                    case 'end_date_time':
                    case 'scheduled_at':
                    case 'end_date':
                    case 'start_date':
                        defaultData = data && convertDateToCustomFormat(data, 'YYYY-MM-DD HH:mm')
                        break
                    default:
                        defaultData = data
                        break
                }
            }
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <DatePickerComponent item={item} defaultValue={defaultData} onChangeDate={onChangeDate} dateRange={dateRange}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'text_area':
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <TextAreaComponent keyName={key} placeholder={placeholder} maxLength={maxLength} defaultValue={data}
                                       onChangeInput={onChangeInput}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'new_password':
            return (
                <div className={"space-y-2"}>
                    <p className={"font-semibold text-monstera"}>{label}</p>
                    <div className={"row items-center border-fennel-stem border h-10 rounded-lg"}>
                        <input name={key} type={'text'} ref={passwordRef}
                               className={"w-3/5 h-9 outline-none rounded-lg px-3"}/>
                        <button type={'button'}
                                className={"row items-center justify-center h-10 space-x-2 text-white bg-battleship-green hover:bg-monstera w-2/5 font-semibold rounded-r-lg"}
                                onClick={generatePasswordHandler}>
                            <img src={KeyIcon} className={"w-5"}/>
                            <p>Generate</p>
                        </button>
                    </div>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        case 'number':
            if (!isHide) {
                return (
                    <div className={"col space-y-2"}>
                        <div className={"row items-center space-x-4"}>
                            <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                            {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                        </div>
                        <NumberInputComponent keyName={key} pattern={pattern} title={title} inputRef={inputRef} maxLength={maxLength}
                                              placeholder={placeholder} disabled={disabled} defaultValue={data} onChangeInput={onChangeNumber}/>
                        {!!description && <p>{description}</p>}
                        {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                    </div>
                )
            }
            break;
        case 'tel':
            if (data?.startsWith(62)) {
                defaultData = data.substring(2)
            } else {
                defaultData = data || null
            }
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <div className={"row items-center px-2 h-9 border border-placebo text-forged-steel rounded-lg"}>
                        <div className={"font-Montserrat-Bold"}>+62</div>
                        <input type={type} name={key} pattern={pattern} title={title}
                               className={"border-0 outline-0 px-2 w-full placeholder:italic"}
                               placeholder={placeholder}
                               defaultValue={defaultData}
                        />
                    </div>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
        default:
            return (
                <div className={"col space-y-2"}>
                    <div className={"row items-center space-x-4"}>
                        <label htmlFor={key} className={"font-semibold text-monstera"}>{label}</label>
                        {tooltipText ? <TooltipComponent text={tooltipText}/> : undefined}
                    </div>
                    <DefaultInputComponent type={type} keyName={key} inputRef={inputRef} pattern={pattern} title={title}
                                           placeholder={placeholder} disabled={disabled} defaultValue={data}
                                           onChangeInput={onChangeInput} copyText={item?.copyText}/>
                    {error[key] && <p className={"font-semibold text-red-600 italic"}>{error[key]}</p>}
                </div>
            )
    }
}


export default InputComponent
