import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import React from "react";
import {useParams} from "react-router-dom";
import {convertDateToCustomFormat} from "../../utils/general-variable";

const HistoryOrder = () => {
    const {id} = useParams()
    const {data: orderLog, isLoading} = useFetch({...API.ORDER_HISTORY, ...{initPathVariable: id}}, {}, {isFetching: true})

    return (
        <div className={"col space-y-8 font-regular py-5 bg-doctor border border-fennel-stem rounded-lg"}>
            <p className={"font-Montserrat-Bold text-sm text-monstera text-center"}>Order History</p>
            {
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <div className={"px-5 max-h-[18rem] overflow-scroll"}>
                        {orderLog?.data?.logs.map((history, index) => (
                            <div key={index} className={"row items-start space-x-5"}>
                                <div className={"col items-center"}>
                                    <div className={`border-2 p-1 rounded-full 
                            ${index === 0 ? 'border-monstera bg-white' : 'border-doctor bg-doctor'}`}>
                                        <div className={`p-[6px] rounded-full 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                                    </div>
                                    {orderLog?.data?.logs.length - 1 !== index && (
                                        <div className={`w-[2px] h-14 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                                    )}
                                </div>
                                <div className={"col space-y-1 text-eerie-black"}>
                                    <p className={"max-h-12 overflow-scroll"}>{history.text}</p>
                                    <p className={"italic"}>{convertDateToCustomFormat(history.created_at, 'DD MMMM, HH:mm')}</p>
                                </div>
                            </div>
                        ))}
                    </div>
            }
        </div>
    )
}

export default HistoryOrder
