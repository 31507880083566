import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FIELD_LIST_AFFILIATION} from "../../utils/input-field-list-static-page";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import React, {useEffect} from "react";
import InputComponent from "../../components/input.component";
import {LoadingSvg} from "../../utils/image";
import {setAffiliationDetail, setAffiliationErrorMessage} from "../../reducers/affiliation.reducer";

const AffiliationPage = () => {
    const dispatch = useDispatch()
    const {affiliationDetail, affiliationErrorMessage} = useSelector(state => state.affiliationReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {fetching: getAffiliation} = useFetch(API.AFFILIATION_GET_AFFILIATION)
    const {fetching: updateAffiliation, isLoading} = useFetch(API.AFFILIATION_UPDATE_AFFILIATION)
    let fieldList = FIELD_LIST_AFFILIATION

    const onChangeInput = (key, value, id) => {
        if(key === 'select_product'){
            let deleteList = affiliationDetail?.delete_product || []
            dispatch(setAffiliationDetail({[key]: value, delete_product: [...deleteList, id]}))
        } else {
            dispatch(setAffiliationDetail({[key]: value}))
        }
    }

    const onChangeProduct = (key, value) => {
        let deleteList = affiliationDetail?.delete_product?.filter(item => item !== value?.id)
        let currentProduct = affiliationDetail.select_product
        dispatch(setAffiliationDetail({[key]: currentProduct.concat(value), delete_product: deleteList || []}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const requestBody = {
            ...affiliationDetail,
            select_product: affiliationDetail.select_product.map(item => item.id) ?? []
        }
        updateAffiliation(requestBody).then(response => {
            dispatch(setAffiliationErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Landing Page Affiliation Updated'))
            dispatch(setAffiliationDetail({...response?.data, delete_product: []}))
        }).catch(err => {
            dispatch(setAffiliationErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        Promise.all([getAffiliation(), getProductList()]).then(response => {
            dispatch(setAffiliationDetail({...response[0]?.data, delete_product: []}))
            fieldList[3].dataList = response[1]?.data
        }).catch(() => {
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setAffiliationErrorMessage({}))
        }
    }, [])

    return (
        <div className={"col px-10"}>
            <form className={"space-y-4"}>
                <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
                    {fieldList.map((item, index) => (
                        <div key={index} className={item.key === 'select_product' || item.key === 'title_affiliate'  ? 'col-span-2' : ''}>
                            <InputComponent key={index} item={item} data={affiliationDetail[item.key]} onChange={onChangeInput} onChangeInput={onChangeInput} onChangeProduct={onChangeProduct} error={affiliationErrorMessage}/>
                        </div>
                    ))}
                </div>
                <div className={"row font-semibold justify-center py-8"}>
                    <button onClick={(e) => onSubmitHandler(e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AffiliationPage
