import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../components/input.component";
import React from "react";
import {cloneDeep} from "lodash";
import {setMoreInfoDetail} from "../../reducers/more-info.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {moreInfoDetail, moreInfoItemErrorMessage} = useSelector(state => state.moreInfoReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(moreInfoDetail.itemList)
        copyItemList[index][key] = value
        dispatch(setMoreInfoDetail({itemList: copyItemList}))
    }

    return (
        <div className={"col space-y-4 text-xs"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={moreInfoDetail?.itemList[index][item.key]} error={moreInfoItemErrorMessage[index] ?? {}} onChange={onChangeInput} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default Item