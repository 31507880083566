import {useState} from "react";
import Banner from "./banner";
import Poster from "./poster";
import PromotedProduct from "./promoted-product";
import ProductRecommendation from "./product-recommendation";
import FlashSale from "./flash-sale";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_BEAUTY_FRIDAY_LIST} from "../../utils/sidebar-section-list";

const BeautyFriday = () => {
    const [activeSidebar, setActiveSidebar] = useState('banner')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        banner: <Banner/>,
        poster: <Poster/>,
        promoted_product: <PromotedProduct/>,
        product_recommendation: <ProductRecommendation/>,
        flash_sale: <FlashSale/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'beauty_friday', list: SECTION_BEAUTY_FRIDAY_LIST}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default BeautyFriday