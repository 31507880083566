import React, {useEffect} from "react";
import {FIELD_LIST_PRODUCT_RECOMMENDATION, FIELD_LIST_RECOMMENDATION_GENERAL} from "../../../utils/input-field-list-static-page";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {
    setMainPageErrorMessage,
    setMainPageRecommendationDetail
} from "../../../reducers/main-page.reducer";
import Item from "./item";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {cloneDeep} from "lodash";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Recommendation = () => {
    const dispatch = useDispatch()
    const {recommendationDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {fetching: getRecommendation} = useFetch(API.HOMEPAGE_GET_RECOMMENDATION)
    const {fetching: updateRecommendation, isLoading} = useFetch(API.HOMEPAGE_UPDATE_RECOMMENDATION)
    const itemList = [...recommendationDetail.item_list]
    let fieldListGeneral = FIELD_LIST_RECOMMENDATION_GENERAL
    let fieldListItem = FIELD_LIST_PRODUCT_RECOMMENDATION
    let showRecommendation = {
        label: 'Recommendation',
        key: 'show_recommendation',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageRecommendationDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'recommendation').toString(),
            desktop_mini_banner: null,
            mobile_mini_banner: null,
            recommendation_label: null,
            title_button: null,
            link_direction_button: null,
            select_product: [],
            delete_product: []
        })
        dispatch(setMainPageRecommendationDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(recommendationDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            item.select_product = item.select_product.map(item => item.id) ?? []
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataRecommendation = {...recommendationDetail, item_list: dataItemList}
        updateRecommendation(dataRecommendation).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Recommendation Updated'))
            dispatch(setMainPageRecommendationDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        Promise.all([getRecommendation(), getProductList()]).then(response => {
            if (response[0]?.data?.item_list?.length > 0) {
                dispatch(setMainPageRecommendationDetail({...response[0].data, delete_item: []}))
            } else {
                const data = response[0]?.data
                delete data?.item_list
                dispatch(setMainPageRecommendationDetail({...data, delete_item: []}))
            }
            fieldListItem[5].dataList = response[1].data
        }).catch(() => {
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            title={'Product Recommendation'}
            data={recommendationDetail}
            switchToggleField={showRecommendation}
            fieldListGeneral={fieldListGeneral}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldListItem} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageRecommendationDetail}
            deleteList={recommendationDetail.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={mainPageErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Recommendation
