export const sliderModel = {
    show_slider: false,
    item_list: [{
        id: 'slider',
        desktop_banner: null,
        mobile_banner: null,
        link_banner: null
    }],
    delete_ids: []
}

export const seriesModel = {
    show_series: false,
    title_section: null,
    description_section: null,
    item_list: [{
        id: 'series',
        desktop_mini_banner: null,
        mobile_mini_banner: null,
        link_mini_banner: null
    }],
    delete_item: []
}

export const flashSaleModel = {
    show_flash_sale: false,
    desktop_mini_banner: null,
    mobile_mini_banner: null,
    icon_flash_sale: null,
    start_date_time: null,
    end_date_time: null,
    title_flash_sale_button: null,
    link_flash_sale_button: null,
    select_product: [],
    delete_product: []
}

export const theCarouselModel = {
    show_the_carousel: false,
    title: null,
    description: null,
    total_article: null,
    title_link_button: null,
    link_direction: null
}

export const recommendationModel = {
    show_recommendation: false,
    title_section: null,
    description_section: null,
    item_list: [{
        id: 'recommendation',
        desktop_mini_banner: null,
        mobile_mini_banner: null,
        recommendation_label: null,
        title_button: null,
        link_direction_button: null,
        select_product: [],
        delete_product: []
    }],
    delete_item: []
}

export const bestSellerModel = {
    show_best_seller: false,
    title_section: null,
    link_direction: null,
    description_section: null,
    title_link_button: null,
    select_product: [],
    delete_product: []
}

export const testimonialModel = {
    show_testimonial: false,
    item_list: [{
        id: 'testimonial',
        testimonial_image: null,
        title: null,
        description: null
    }],
    delete_item: []
}

export const shopByCategoryModel = {
    show_shop_by_category: false,
    item_list: [{
        id: 'shop_by_category',
        product_image: null,
        title: null,
        link_product: null
    }],
    delete_item: []
}

export const benefitModel = {
    show_benefit: false,
    desktop_mini_banner: null,
    mobile_mini_banner: null
}

export const sustainabilityModel = {
    show_sustainability: false,
    desktop_poster: null,
    mobile_poster: null,
    link_poster: null
}

export const rubiModel = {
    show_rubi: false,
    desktop_poster: null,
    mobile_poster: null,
    link_poster: null
}

export const csrModel = {
    show_csr: false,
    item_list: [{
        id: 'csr',
        desktop_banner: null,
        mobile_banner: null,
        link_banner: null
    }],
    delete_item: []
}

export const claimModel = {
    show_claim: false,
    title_section: false,
    item_list: [{
        id: 'claim',
        icon: null,
        title: null
    }],
    delete_item: []
}

export const seoModel = {
    show_seo: false,
    title: null,
    description: null
}
