import React, {useEffect} from "react";
import CommentDetail from "../../components/comment-detail.component";
import ReviewsFilter from "./reviews-filter";
import ReviewsList from "./reviews-list";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setCommentSelectedRowKeys} from "../../reducers/comment.reducer";
import {SORT_LIST_COMMENT} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const AllReviewPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_COMMENT?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
        filterByRate: filter['rating'] ?? []
    }
    const {
        data: response,
        fetching: getReviewList,
        isLoading
    } = useFetch(API.REVIEW_LIST, queryParams, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setCommentSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getReviewList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, filter, reload])

    return (
        <div className={"col px-10"}>
            <ReviewsFilter/>
            <ReviewsList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
            <CommentDetail/>
        </div>
    )
}

export default AllReviewPage