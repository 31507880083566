import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {TrashIconWhite} from "../../utils/image";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {setToolsAlertMessage, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";

const LevelItem = (props) => {
    const dispatch = useDispatch()
    const {title, content, index, dataList = [], setDataList, deleteList = []} = props
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const [indexItemOnDelete, setIndexItemOnDelete] = useState(null)
    const dataListCopy = [...dataList]
    const contentId = dataListCopy[index]?.id
    const {fetching: checkLevelOnDelete} = useFetch({...API.CHECK_DELETE_LEVEL_AND_BENEFIT, ...{initPathVariable: contentId}})

    const openDeleteModal = () => {
        setIndexItemOnDelete(index)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus level yang anda pilih?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const onDeleteItem = () => {
        const updateItem = dataListCopy.filter((_,i) => i !== indexItemOnDelete)
        if (Number(contentId)) {
            checkLevelOnDelete().then(() => {
                dispatch(setDataList({items: updateItem, delete_ids: [...deleteList, contentId]}))
            }).catch(err => {
                if (err?.code === '400-L005') {
                    dispatch(setToolsAlertMessage('Tidak dapat menghapus level'))
                } else {
                    dispatch(setToolsAlertMessage('Terjadi kesalahan'))
                }
            })
        } else {
            dispatch(setDataList({items: updateItem, delete_ids: deleteList}))
        }
        dispatch(setToolsModalVisibility({delete: false}))
    }

    useEffect(() => {
        if (!modalVisibility.delete) {
            setIndexItemOnDelete(null)
        }
    }, [modalVisibility.delete])

    return (
        <div>
            <div
                className={"col divide-y divide-fennel-stem font-regular text-monstera border border-fennel-stem rounded-lg"}>
                <div className={"row items-center justify-between p-2 bg-doctor rounded-lg"}>
                    <div className={"row space-x-3 items-center"}>
                        <p className={"text-sm font-Montserrat-Bold capitalize"}>{title}</p>
                    </div>
                    <div className={"row items-center space-x-4"}>
                        <div
                            className={"row items-center space-x-2 font-semibold text-white w-fit cursor-pointer p-2 bg-spiced-coral hover:bg-glowing-scarlet border border-placebo rounded-lg"}
                            onClick={() => openDeleteModal(index)}>
                            <img src={TrashIconWhite} alt="TrashIcon" className={"w-5"}/>
                            <p>Delete Level</p>
                        </div>
                    </div>
                </div>
                <div>
                    {content}
                </div>
            </div>
            {indexItemOnDelete === index && <DeleteConfirmationComponent onDelete={onDeleteItem}/>}
        </div>
    )
}

export default LevelItem
