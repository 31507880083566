import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMainPageBestSellerDetail} from "../../../reducers/main-page.reducer";

const GeneralForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {bestSellerDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value) => {
        dispatch(setMainPageBestSellerDetail({[key]: value}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} data={bestSellerDetail[item.key]} onChangeInput={onChangeInput} error={mainPageErrorMessage}/>
            ))}
        </div>
    )
}

export default GeneralForm