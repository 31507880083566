import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setProductDetail, setProductInitialProductDetail} from "../../reducers/product.reducer";
import {useParams} from "react-router-dom";
import CreateProductPage from "../create-product";
import {LoadingSvg} from "../../utils/image";

const UpdateProductPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {
        fetching: getProductDetail,
        isLoading
    } = useFetch({...API.PRODUCT_DETAIL, ...{initPathVariable: id}})

    useEffect(() => {
        getProductDetail().then((response) => {
            dispatch(setProductDetail(response?.data))
        })
        return () => {
            dispatch(setProductInitialProductDetail())
        }
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className={"row justify-center w-full h-1/2"}>
                        <img src={LoadingSvg} className={"w-12 mx-auto"}/>
                    </div> :
                    <CreateProductPage/>
            }
        </>
    )
}

export default UpdateProductPage
