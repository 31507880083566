import React, {useEffect, useRef, useState} from "react";
import {SearchIcon, XCircleIcon} from "../utils/image";
import {useFetch} from "../services/useFetch";
import {API} from "../services/api";
import {useClickOutside} from "../services/useClickOutside";

const MultipleSelectSearchComponent = (props) => {
    const searchData = useRef()
    const {item, fetchValue, defaultValue} = props
    const [listSelected, setListSelected] = useState([])
    const [isShowListOption, setIsShowListOption] = useState(false)
    const [listData, setListData] = useState([])
    const {fetching: searchEmail} = useFetch(API.SEARCH_EMAIL, {}, {isFetching: false})
    const {fetching: searchEmailNotification} = useFetch(API.NOTIFICATION_SEARCH_EMAIL, {}, {isFetching: false})

    useEffect(() => {
        setListSelected(defaultValue)
    }, [defaultValue])

    const fetchApi = (payload) => {
        if (item.key === 'receiver_emails') {
            return searchEmailNotification(payload)
        } else {
            return searchEmail(payload)
        }
    }

    const searchListOption = (e) => {
        if (e.key === 'Enter') {
            let searchKey = e.target.value
            fetchApi({search: searchKey})
                .then(response => {
                    const listIdSelected = listSelected.map(list => list.id)
                    const filterListOption = response?.data?.filter(list => {
                        return !listIdSelected.includes(list.id)
                    })
                    setIsShowListOption(true)
                    setListData(filterListOption)
                })
                .catch(err => console.log(err))
        }
    }

    const deleteItem = (indexItem) => {
        const deleteDataSelected = listSelected.filter((list, index) => index !== indexItem)
        setListSelected(deleteDataSelected)
        fetchValue(item.key, deleteDataSelected)
    }

    const selectedListHandler = (itemSelected) => {
        setIsShowListOption(false)
        let newDataSelected = listSelected.concat(itemSelected)
        setListSelected(newDataSelected)
        fetchValue(item.key, newDataSelected)
        searchData.current.value = ''
    }

    useClickOutside(`multiple-select-list-search-component-${item.key}`, isShowListOption, setIsShowListOption)

    return (
        <div id={`multiple-select-list-search-component-${item.key}`} className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${listSelected.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} name={item.key} id={item.key}
                       placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                       onKeyUp={searchListOption} ref={searchData}/>
            </div>
            <div className={listSelected.length > 0 ? 'block' : 'hidden'}>
                <div
                    className={"row flex-wrap p-5 bg-white border-x border-b rounded-b-lg border-placebo max-h-64 overflow-scroll"}>
                    {listSelected.map((list, index) => (
                        <div key={index}
                             className={"row items-center space-x-4 mr-4 h-7 bg-doctor rounded-lg border border-liberated-lime mb-2"}>
                            <img src={XCircleIcon} alt="deleteList" className={"h-5 cursor-pointer order-last px-4"}
                                 onClick={() => deleteItem(index)}/>
                            <p className={"w-fit"}>{list[item.selectLabel] ?? list}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${isShowListOption ? 'block' : 'hidden'} 
                col absolute w-11/12 top-8 left-10 bg-white shadow rounded-lg max-h-52 overflow-scroll z-10`}>
                {listData.length > 0 ? listData.map((list, index) => (
                    <div key={index}
                         className={"row hover:bg-apple-flower hover:text-monstera items-center p-3 space-x-4 cursor-pointer"}
                         onClick={() => selectedListHandler([list])}>
                        <p>{list[item.selectLabel]}</p>
                    </div>
                )) : <p className={"p-3"}>Not Found</p>}
            </div>
        </div>
    )
}

export default MultipleSelectSearchComponent
