import {createSlice} from '@reduxjs/toolkit'
import {advanceFilterAnalyticsOrderModel} from "../models/advance-filter";

export const analyticsOrderSlice = createSlice({
    name: 'analyticsOrder',
    initialState: {
        advanceFilter: {...advanceFilterAnalyticsOrderModel},
        dateRangeAnalyticVoucher: [null, null]
    },
    reducers: {
        setAnalyticsOrderAdvanceFilter: (state, action) => {
            state.advanceFilter = {...state.advanceFilter, ...action.payload}
        },
        setAnalyticsOrderDateRangeAnalyticVoucher: (state, action) => {
            state.dateRangeAnalyticVoucher = action.payload
        }
    },
})

export const {setAnalyticsOrderAdvanceFilter, setAnalyticsOrderDateRangeAnalyticVoucher} = analyticsOrderSlice.actions
const analyticsOrderReducer = analyticsOrderSlice.reducer

export default analyticsOrderReducer
