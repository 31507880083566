import TabToggleComponent from "../../components/tab-toggle.component";
import VoucherList from "./voucher-list";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";
import SearchComponent from "../../components/search.component";

const TabOrdersVoucherList = () => {
    const {
        currentPage,
        pageSize,
        searchKey,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey,
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
    }
    const {
        data: response,
        fetching: getOrdersVoucherList,
        isLoading
    } = useFetch(API.ANALYTIC_ORDERS_VOUCHER_LIST, queryParams, {isWithTotalData: true})

    useEffectAfterMount(() => {
        getOrdersVoucherList()
    }, [currentPage, pageSize, searchKey, dateRange, reload])

    return (
        <TabToggleComponent
            title={'Orders Voucher'}
            content={
                <div className={"col space-y-4"}>
                    <SearchComponent placeholder={"cari kode voucher..."}/>
                    <VoucherList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
                </div>
            }
        />
    )
}

export default TabOrdersVoucherList
