import React from "react";

const RadioComponent = ({name, radioItem, data, onChange}) => {

    const onChangeRadio = () => {
        onChange(name, radioItem.key)
    }

    return (
        <div className={"row items-center h-9 items-center space-x-2"}>
            <input type={'radio'} id={radioItem.key} className={`accent-monstera`} name={name} value={radioItem.key}
                   checked={data === radioItem.key} onChange={onChangeRadio}
            />
            <label htmlFor={radioItem.key}
                   className={"text-forged-steel font-regular"}>{radioItem.label}</label>
        </div>
    )
}

export default RadioComponent
