import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_CLAIM_ITEM} from "../../../utils/input-field-list-static-page";
import {setMainPageClaimDetail, setMainPageErrorMessage} from "../../../reducers/main-page.reducer";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {cloneDeep} from "lodash";
import React, {useEffect} from "react";
import Item from "./item";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Claim = () => {
    const dispatch = useDispatch()
    const {claimDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)
    const {fetching: getClaim} = useFetch(API.HOMEPAGE_GET_CLAIM)
    const {fetching: updateClaim, isLoading} = useFetch(API.HOMEPAGE_UPDATE_CLAIM)
    const itemList = [...claimDetail.item_list]
    let fieldListGeneral = [{
        label: 'Title Section',
        key: 'title_section',
        type: 'text',
        placeholder: 'masukkan judul section'
    }]
    let fieldListItem = FIELD_LIST_CLAIM_ITEM
    let showClaim = {
        label: 'Claim',
        key: 'show_claim',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageClaimDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'claim').toString(),
            icon: null,
            title: null
        })
        dispatch(setMainPageClaimDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(claimDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataClaim = {...claimDetail, item_list: dataItemList}
        updateClaim(dataClaim).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Claim Updated'))
            dispatch(setMainPageClaimDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getClaim().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setMainPageClaimDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setMainPageClaimDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={claimDetail}
            switchToggleField={showClaim}
            fieldListGeneral={fieldListGeneral}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldListItem} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageClaimDetail}
            deleteList={claimDetail.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            errorMessage={mainPageErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Claim
