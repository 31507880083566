import {FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES} from "../../../utils/input-field-list-static-page";
import FormGeneralComponent from "../../../components/static-page/form-general.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setMiraculousRefiningSeriesIntroDetail} from "../../../reducers/miraculous-refining-series.reducer";
import InputField from "./input-field";

const Intro = () => {
    const dispatch = useDispatch()
    const {introDetail} = useSelector(state => state.miraculousRefiningSeriesReducer)
    let fieldList = FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES
    let showIntro = {
        label: 'Intro',
        key: 'show_intro',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'Input Field',
            content: <InputField fieldList={fieldList} />
        }
    ]

    const onChange = (key, value) => {
        dispatch(setMiraculousRefiningSeriesIntroDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(introDetail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={introDetail}
            switchToggleField={showIntro}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default Intro