import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_LOYALTY_PROGRAM} from "../../utils/sidebar-section-list";
import MainBanner from "./main-banner";
import MemberFlow from "./member-flow";
import Benefit from "./benefit";
import Leveling from "./leveling";
import JoinMember from "./join-member";
import Faq from "./faq";
import Seo from "./seo";

const LoyaltyProgramPage = () => {
    const [activeSidebar, setActiveSidebar] = useState('main_banner')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        main_banner: <MainBanner/>,
        member_flow: <MemberFlow/>,
        benefit: <Benefit/>,
        leveling: <Leveling/>,
        join_member: <JoinMember/>,
        faq: <Faq/>,
        seo: <Seo/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'loyalty-program', list: SECTION_LOYALTY_PROGRAM}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default LoyaltyProgramPage
