import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_INPUT_POSTER} from "../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import {setBeautyFridayErrorMessage, setBeautyFridayPosterDetail} from "../../reducers/beauty-friday.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Poster = () => {
    const dispatch = useDispatch()
    const {posterDetail, beautyFridayErrorMessage} = useSelector(state => state.beautyFridayReducer)
    const {fetching: getPoster} = useFetch(API.BF_GET_POSTER)
    const {fetching: updatePoster, isLoading} = useFetch(API.BF_UPDATE_POSTER)
    let fieldListInput = FIELD_LIST_INPUT_POSTER
    let showPoster = {
        label: 'Poster',
        key: 'show_poster',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setBeautyFridayPosterDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updatePoster(posterDetail).then(response => {
            dispatch(setBeautyFridayErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Poster Updated'))
            dispatch(setBeautyFridayPosterDetail(response?.data))
        }).catch(err => {
            dispatch(setBeautyFridayErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getPoster().then(response => {
            dispatch(setBeautyFridayPosterDetail(response?.data))
        })

        return () => {
            dispatch(setBeautyFridayErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={posterDetail}
            switchToggleField={showPoster}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={beautyFridayErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Poster