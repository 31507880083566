import TableComponent from "../../components/table-component";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import {Link} from "react-router-dom";

const OrderList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Tanggal Order',
            classNameHeader: 'pl-4',
            dataIndex: 'formated_date',
            render: (formated_date) => (
                <p className={"font-regular text-forged-steel p-4"}>{formated_date}</p>
            )
        }, {
            name: 'Nomor Order',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"justify-center font-regular text-forged-steel pl-5"}>
                    <Link to={`/order/order-list/order-detail/${record.id}`}>
                        <p className={"hover:font-Montserrat-Bold hover:underline"}>{record.order_code}</p>
                    </Link>
                </div>
            )
        }, {
            name: 'Status Order',
            classNameHeader: 'text-center',
            dataIndex: 'status',
            render: (status) => (
                <p className={"font-regular text-forged-steel text-center"}>{status}</p>
            )
        }, {
            name: 'Customer',
            classNameHeader: 'text-center',
            dataIndex: 'customer',
            render: (customer) => (
                <p className={`font-regular text-center capitalize ${customer === 'new' ? 'text-dazzle' : 'text-lavish-spending'}`}>{customer}</p>
            )
        }, {
            name: 'Product',
            classNameHeader: 'text-center',
            dataIndex: 'product',
            width: 300,
            render: (product) => (
                <p className={"font-semibold text-monstera p-2"}>{product}</p>
            )
        }, {
            name: 'Item Sold',
            classNameHeader: 'text-center',
            dataIndex: 'quantity',
            render: (quantity) => (
                <p className={"font-regular text-forged-steel text-center"}>{quantity}</p>
            )
        }, {
            name: 'Voucher Code',
            classNameHeader: 'text-center',
            dataIndex: 'vouchers',
            width: 200,
            render: (vouchers) => (
                <p className={"font-regular text-forged-steel text-center"}>{vouchers}</p>
            )
        }, {
            name: 'Total Sales',
            classNameHeader: 'text-center',
            dataIndex: 'net_sales',
            render: (net_sales) => (
                <p className={`font-semibold text-center ${net_sales < 0 ? 'text-red-600' : 'text-forged-steel'}`}>{doConvertNumberToRupiahFormat(net_sales)}</p>
            )
        }
    ]

    return (
        <div className={"col space-y-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default OrderList
