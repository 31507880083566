export const generalModel = {
    show_general: true,
    title: null,
    description: null,
    mobile_image: null,
    desktop_image: null
}

export const recyclingProgressModel = {
    show_recycling_progress: true,
    item_list: [{
        id: 'recycling_progress',
        title: null,
        description: null
    }],
    delete_item: []
}

export const campaignBannerModel = {
    show_campaign_banner: true,
    mobile_mini_banner: null,
    desktop_mini_banner: null
}

export const timelineModel = {
    show_timeline: true,
    title_section: null,
    timelines: [{
        id: 'timeline',
        year: null,
        image: null,
        short_description: null,
        long_description: null
    }],
    delete_ids: []
}

export const ctaModel = {
    show_cta: true,
    mobile_image: null,
    desktop_image: null,
    url: null
}
