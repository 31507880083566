import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FIELD_LIST_INPUT_SEO_LOYALTY_PROGRAM} from "../../utils/input-field-list-static-page";
import {setLoyaltyProgramErrorMessage, setLoyaltyProgramSeoDetail} from "../../reducers/loyalty-program.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import React, {useEffect} from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Seo = () => {
    const dispatch = useDispatch()
    const {seoDetail, loyaltyProgramErrorMessage} = useSelector(state => state.loyaltyProgramReducer)
    const {fetching: getSeo} = useFetch(API.LOYALTY_PROGRAM_GET_SEO)
    const {fetching: updateSeo, isLoading} = useFetch(API.LOYALTY_PROGRAM_UPDATE_SEO)
    let fieldListInput = FIELD_LIST_INPUT_SEO_LOYALTY_PROGRAM
    let showSeo = {
        label: 'SEO',
        key: 'show_seo',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setLoyaltyProgramSeoDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateSeo(seoDetail).then(response => {
            dispatch(setLoyaltyProgramErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('SEO Updated'))
            dispatch(setLoyaltyProgramSeoDetail(response?.data))
        }).catch(err => {
            dispatch(setLoyaltyProgramErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getSeo().then(response => {
            dispatch(setLoyaltyProgramSeoDetail(response?.data))
        })

        return () => {
            dispatch(setLoyaltyProgramErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={seoDetail}
            switchToggleField={showSeo}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={loyaltyProgramErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Seo
