const validate = (field, inputValue) => {
    const REGEX_EMAIL = /^\S+@\S+\.\S+$/
    const REGEX_SLUG = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/

    switch (field.key) {
        case 'email':
            return !REGEX_EMAIL.test(inputValue) && `${field.label} is not valid`
        case 'password':
            return inputValue.length < 8 && 'Password must be 8 characters long'
        case 'title':
        case 'url':
        case 'fullname':
        case 'name':
        case 'phone_number':
        case 'city_and_district':
        case 'zipcode':
        case 'address':
        case 'character_amount':
        case 'prefix':
        case 'total_voucher':
        case 'voucher_code':
        case 'select_voucher_duplicate':
        case 'ios':
        case 'android':
        case 'otp_code':
            return !inputValue && `${field.label} is required`
        case 'slug':
            if (!!inputValue) {
                return !REGEX_SLUG.test(inputValue) && `${field.label} is not valid, example: some-thing-about-avoskin`
            } else {
                return false
            }
        default:
            return false
    }
}

export default validate
