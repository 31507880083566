import {createSlice} from '@reduxjs/toolkit'
import {
    articlesModel,
    campaignBannerModel,
    campaignVideoModel,
    formModel,
    mainBannerModel,
    sustainabilityProgressModel
} from "../models/avo-stories-2.model";

export const avoStories2Slice = createSlice({
    name: 'avoStories2',
    initialState: {
        mainBannerDetail: {...mainBannerModel},
        sustainabilityProgressDetail: {...sustainabilityProgressModel},
        campaignVideoDetail: {...campaignVideoModel},
        campaignBannerDetail: {...campaignBannerModel},
        formDetail: {...formModel},
        articlesDetail: {...articlesModel},
        avoStories2ErrorMessage: {},
    },
    reducers: {
        setAvoStories2MainBannerDetail: (state, action) => {
            state.mainBannerDetail = {...state.mainBannerDetail, ...action.payload}
        },
        setAvoStories2SustainabilityProgressDetail: (state, action) => {
            state.sustainabilityProgressDetail = {...state.sustainabilityProgressDetail, ...action.payload}
        },
        setAvoStories2CampaignVideoDetail: (state, action) => {
            state.campaignVideoDetail = {...state.campaignVideoDetail, ...action.payload}
        },
        setAvoStories2CampaignBannerDetail: (state, action) => {
            state.campaignBannerDetail = {...state.campaignBannerDetail, ...action.payload}
        },
        setAvoStories2FormDetail: (state, action) => {
            state.formDetail = {...state.formDetail, ...action.payload}
        },
        setAvoStories2ArticlesDetail: (state, action) => {
            state.articlesDetail = {...state.articlesDetail, ...action.payload}
        },
        setAvoStories2ErrorMessage: (state, action) => {
            state.avoStories2ErrorMessage = action.payload
        }
    },
})

export const {
    setAvoStories2MainBannerDetail,
    setAvoStories2SustainabilityProgressDetail,
    setAvoStories2CampaignVideoDetail,
    setAvoStories2CampaignBannerDetail,
    setAvoStories2FormDetail,
    setAvoStories2ArticlesDetail,
    setAvoStories2ErrorMessage,
} = avoStories2Slice.actions
const avoStories2Reducer = avoStories2Slice.reducer

export default avoStories2Reducer
