export const bannerModel = {
    show_banner: false,
    desktop_banner: null,
    mobile_banner: null,
    url: null,
}

export const descriptionModel = {
    show_description: false,
    descriptions: [{
        id: 'description',
        title: null,
        url: null,
        image_url: null,
        description: null
    }],
    delete_ids: []
}

export const benefitModel = {
    show_benefit: false,
    title: null,
    button_url: null,
    description: null,
    button_title: null,
    images: []
}

export const redeemProductModel = {
    show_redeem: false,
    title: null,
    description: null,
    placeholder: null,
    button_title: null,
    desktop_banner: null,
    mobile_banner: null,
}

export const miniBannerModel = {
    show_mini_banner: false,
    desktop_banner: null,
    mobile_banner: null,
    url: null,
}

export const giftBannerModel = {
    show_gift_banner: false,
    desktop_banner: null,
    mobile_banner: null
}
