import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMainPageFlashSaleDetail} from "../../../reducers/main-page.reducer";

const FlashSaleProductForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {flashSaleDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value, id) => {
        let deleteList = flashSaleDetail?.delete_product || []
        dispatch(setMainPageFlashSaleDetail({[key]: value, delete_product: [...deleteList, id]}))
    }

    const onChangeProduct = (key, value) => {
        let deleteList = flashSaleDetail?.delete_product?.filter(item => item !== value?.id)
        let currentProduct = flashSaleDetail.select_product
        dispatch(setMainPageFlashSaleDetail({[key]: currentProduct.concat(value), delete_product: deleteList || []}))
    }

    return (
        <InputComponent item={fieldList} data={flashSaleDetail[fieldList.key]} onChange={onChangeInput} onChangeProduct={onChangeProduct} error={mainPageErrorMessage}/>
    )
}

export default FlashSaleProductForm
