import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userReducer from "./user.reducer";
import orderReducer from "./order.reducer";
import toolsReducer from "./tools.reducer";
import commentReducer from "./comment.reducer";
import reviewReducer from "./review.reducer";
import voucherReducer from "./voucher.reducer";
import productReducer from "./product.reducer";
import giftReducer from "./gift.reducer";
import categoryReducer from "./category.reducer";
import avostoreReducer from "./avostore.reducer";
import moreInfoReducer from "./more-info.reducer";
import mainPageReducer from "./main-page.reducer";
import promoReducer from "./promo.reducer";
import waste4ChangeReducer from "./waste-4-change.reducer";
import beautyFridayReducer from "./beauty-friday.reducer";
import areaReducer from "./area.reducer";
import roleReducer from "./roles.reducer";
import aboutUsReducer from "./about-us.reducer";
import miraculousSeriesReducer from "./miraculous-series.reducer";
import miraculousRefiningSeriesReducer from "./miraculous-refining-series.reducer";
import miraculousRetinolSeriesReducer from "./miraculous-retinol-series.reducer";
import yourSkinBaeReducer from "./your-skin-bae.reducer";
import maintenancePageReducer from "./maintenance-page.reducer";
import avoStoriesReducer from "./avo-stories.reducer";
import notificationReducer from "./notification.reducer";
import analyticsOrderReducer from "./analytics-order.reducer";
import affiliateReducer from "./affiliate.reducer";
import levelAndBenefitReducer from "./level-and-benefit.reducer";
import birthdayGiftReducer from "./birthday-gift.reducer";
import beautyEmptiesReducer from "./beauty-empties.reducer";
import avoStories2Reducer from "./avo-stories-2.reducer";
import avoStoriesUserReducer from "./avo-stories-user.reducer";
import affiliationReducer from "./affiliation.reducer";
import loyaltyProgramReducer from "./loyalty-program.reducer";
import crmSettingsReducer from "./crm-settings.reducer";

const rootReducer = combineReducers({
    userReducer,
    orderReducer,
    commentReducer,
    toolsReducer,
    reviewReducer,
    voucherReducer,
    productReducer,
    giftReducer,
    categoryReducer,
    avostoreReducer,
    moreInfoReducer,
    mainPageReducer,
    promoReducer,
    waste4ChangeReducer,
    beautyFridayReducer,
    areaReducer,
    roleReducer,
    aboutUsReducer,
    miraculousSeriesReducer,
    miraculousRefiningSeriesReducer,
    miraculousRetinolSeriesReducer,
    yourSkinBaeReducer,
    maintenancePageReducer,
    avoStoriesReducer,
    avoStories2Reducer,
    notificationReducer,
    analyticsOrderReducer,
    affiliateReducer,
    levelAndBenefitReducer,
    birthdayGiftReducer,
    beautyEmptiesReducer,
    avoStoriesUserReducer,
    affiliationReducer,
    loyaltyProgramReducer,
    crmSettingsReducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false})
})

export default store
