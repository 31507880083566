import React, {useEffect, useRef, useState} from "react";
import {ArrowDownIcon, SearchIcon} from "../../utils/image";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useClickOutside} from "../../services/useClickOutside";

const SearchProductItem = (props) => {
    const {item, onChangeSelect, defaultValue, error} = props
    const [isShowModal, setIsShowModal] = useState(false)
    const searchValue = useRef()
    const [list, setList] = useState([])
    const {fetching: searchItemProduct} = useFetch(API.NOTIFICATION_PRODUCT_LIST)

    useEffect(() => {
        searchValue.current.value = defaultValue?.name || null
    }, [])

    const showHideModal = () => {
        setIsShowModal(!isShowModal)
    }

    const searchListOption = (e) => {
        const keyword = e.target.value
        let params = {
            search: keyword
        }
        if (e.key === 'Enter') {
            searchItemProduct(params)
                .then(response => {
                    setList(response.data)
                    setIsShowModal(true)
                })
        }
    }

    const onChangeData = (dataItem) => {
        !!onChangeSelect && onChangeSelect(dataItem, item.key)
        showHideModal()
        searchValue.current.value = dataItem.name
    }

    useClickOutside(`search-product-item-component-${item.key}`, isShowModal, setIsShowModal)

    return (
        <div id={`search-product-item-component-${item.key}`} className={"relative col font-regular text-forged-steel"}>
            <div className={"mb-2"}>
                <label htmlFor={item.key} className={"font-semibold text-monstera"}>{item.label}</label>
            </div>
            <div
                className={`row items-center px-2 h-9 border border-placebo bg-white ${isShowModal ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} name={item.key} id={item.key}
                       className={"border-0 outline-0 px-3 w-full disabled:bg-white placeholder:italic"}
                       ref={searchValue} onKeyUp={searchListOption} placeholder={item?.placeholder}/>
                <img className={"w-2.5 h-5"} src={ArrowDownIcon} alt={'arrowDown'}/>
            </div>
            <div className={`${isShowModal ? 'block' : 'hidden'} absolute top-14 w-full z-10`}>
                <div
                    className={"col bg-white border border-placebo mb-5 max-h-32 overflow-scroll"}>
                    {list?.length > 0 ? list?.map((l, index) => (
                        <div key={index}
                             className={"row p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => onChangeData(l)}>
                            <p>{l.name}</p>
                        </div>
                    )) : <p className={"p-3 text-center"}>Not Found</p>}
                </div>
            </div>
            {error[item.key] && <p className={"font-semibold text-red-600 italic mt-2"}>{error[item.key]}</p>}
        </div>
    )
}

export default SearchProductItem
