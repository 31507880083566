import TableComponent from "../../components/table-component";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import {useSelector} from "react-redux";

const ProductList = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const columns = [
        {
            name: 'Product',
            width: '70%',
            classNameHeader: 'pl-20',
            render: (record) => (
                <div className={"row items-center space-x-4 font-regular text-monstera pl-5 py-4"}>
                    <img src={record?.image_url} alt="imageProduct" className={"w-12"}/>
                    <p>{record.name}</p>
                </div>
            )
        }, {
            name: 'Jumlah',
            width: '13.5%',
            dataIndex: 'quantity',
            classNameHeader: 'text-center',
            render: (quantity) => (
                <p className={"font-regular text-forged-steel text-center"}>{quantity} Pcs</p>
            )
        }, {
            name: 'Price',
            render: (record) => (
                <div className={"col font-regular text-forged-steel"}>
                    {
                        record.sale_total !== record.total ?
                            <div className={"space-y-2"}>
                                <p>{doConvertNumberToRupiahFormat(record.sale_total)}</p>
                                <p className={"line-through"}>{doConvertNumberToRupiahFormat(record.total)}</p>
                            </div> :
                            <p>{doConvertNumberToRupiahFormat(record.total)}</p>
                    }
                </div>
            )
        }
    ]

    return (
        <div className={"col space-y-3 font-regular"}>
            <p className={"pl-5 font-Montserrat-Bold text-monstera text-sm"}>Pesanan</p>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={orderDetail?.transaction?.products ?? []}
                pagination={false}
                isHoverStyle={false}
            />
        </div>
    )
}

export default ProductList
