import React, {useState} from "react";
import {CloseIconGreen, LoadingSvg} from "../../../utils/image";
import ModalCardComponent from "../../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import SwitchToggleComponent from "../../../components/switch-toggle.component";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setUserProfile} from "../../../reducers/user.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";

export const EditAccessModal = (props) => {
    const {onClose, form, userId, data} = props
    const dispatch = useDispatch()
    const {editAccess} = useSelector(state => state.userReducer.modalVisibility)
    const [selectedRole, setSelectedRole] = useState(data?.role)
    const [status, setStatus] = useState(data?.status === 'active')
    const {fetching: postUpdateRoleStatus, isLoading} = useFetch(API.USER_UPDATE_USER)

    const selectRoleHandler = (key) => {
        setSelectedRole(key)
    }

    const onChangeStatus = (key, value) => {
        setStatus(value)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const body = {
            role_id: e.target.role_id.value,
            status: status ? 'active' : 'inactive'
        }
        postUpdateRoleStatus(body, userId).then(response => {
            dispatch(setUserProfile(response?.data))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Profile Updated'))
            onClose('editAccess')
        })
    }

    return (
        <ModalCardComponent
            content={
                <div className={"absolute bg-white w-80 rounded-lg p-5"}>
                    <div className={"row justify-between items-center"}>
                        <div/>
                        <p className={"font-Montserrat-Bold text-monstera"}>Edit Access</p>
                        <img src={CloseIconGreen} className={"w-4 cursor-pointer"} onClick={() => onClose('editAccess')}/>
                    </div>
                    <div className={"col mt-5"}>
                        <form onSubmit={onSubmitHandler} className={"space-y-5"}>
                            <div className={"space-y-5"}>
                                <p className={"text-monstera font-Montserrat-Bold"}>Role</p>
                                <div className={"space-y-3 max-h-52 overflow-scroll"}>
                                    {
                                        form.map((item, index) => (
                                            <label key={index}
                                                 className={`row items-center justify-between ${(selectedRole === item.id || selectedRole === item.name) ? 'bg-doctor text-monstera' : 'text-forged-steel'} hover:bg-doctor hover:text-monstera border border-whisper-green p-3 rounded-md  cursor-pointer`}
                                            >
                                                <p
                                                    className={`font-Montserrat-Bold`}>{item.name}</p>
                                                <input type={"radio"} name={"role_id"} value={item.id} defaultChecked={selectedRole === item.name}
                                                       className={"accent-monstera cursor-pointer"}
                                                       onChange={() => selectRoleHandler(item.id)}/>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={"space-y-3"}>
                                <div className={"row items-center justify-between"}>
                                    <p className={"text-monstera font-Montserrat-Bold"}>Status</p>
                                    <SwitchToggleComponent item={{key: 'status'}}
                                                           defaultValue={status}
                                                           onChangeSwitchToggle={onChangeStatus}/>
                                </div>
                                <div
                                    className={`${status ? 'text-monstera bg-diminished-mint' : 'text-spiced-coral bg-vienna-dawn'} font-Montserrat-Bold w-24 py-1 text-center rounded`}>
                                    <p>{status ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                            <div className={"col items-center"}>
                                <button
                                    className={"bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-Montserrat-Bold w-40 h-8 shadow-md rounded-md"}>
                                    {isLoading ? <img src={LoadingSvg} className={"w-4 mx-auto"}/> : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
            visibility={editAccess}
            onClose={() => onClose('editAccess')}
        />
    )
}
