export const avostoreModel = {
    latitude: 0,
    longitude: 0,
    name: null,
    description: null,
    avostore_area_id: null,
    ig_username: null,
    ig_url: null,
    shopee_url: null,
    tokopedia_url: null,
    lazada_url: null,
    blibli_url: null,
    bukalapak_url: null,
    jdid_url: null,
    tiktok_url: null,
    goshop_url: null,
    website_url: null,
    offline_stores: [],
}

export const avostoreAreaModel = {
    name: null,
    description: null
}