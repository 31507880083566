import TotalDataComponent from "../../components/total-data.component";
import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../components/table-component";
import {setOrderSelectedRowKeys} from "../../reducers/order.reducer";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    getExpeditionLogo,
    statusRenderClassName
} from "../../utils/general-variable";
import {Link} from "react-router-dom";

const OrderList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysOrder} = useSelector(state => state.orderReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nomor Pesanan',
            width: 150,
            classNameHeader: 'pl-5',
            render: (record) => (
                <div className={"pl-5"}>
                    <Link to={`/order/order-list/order-detail/${record.id}`}>
                        <p className={"font-Montserrat-Bold text-monstera hover:underline"}>{record.order_code}</p>
                    </Link>
                </div>
            )
        }, {
            name: 'Alamat Pengiriman',
            width: 300,
            render: (record) => (
                <div className={"col space-y-3 font-regular"}>
                    <p className={"text-eerie-black capitalize"}>{record.customer_name}</p>
                    <p className={"text-forged-steel"}>{record.shipping_full_address}</p>
                </div>
            )
        }, {
            name: 'Ekspedisi',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row items-center justify-center space-x-4 text-forged-steel"}>
                    <img src={getExpeditionLogo(record.shipping_service)} className={"w-10"} alt={"expeditionLogo"}/>
                    <div className={"col space-y-1"}>
                        <p className={"font-semibold"}>{record.shipping_service}</p>
                        <p>{record.shipping_destination}</p>
                    </div>
                </div>
            )
        }, {
            name: 'Grand Total',
            dataIndex: 'grand_total',
            classNameHeader: 'text-center',
            render: (grand_total) => (
                <div className={"text-center text-forged-steel"}>
                    <p>{doConvertNumberToRupiahFormat(grand_total)}</p>
                </div>
            )
        }, {
            name: 'Tanggal Order',
            dataIndex: 'created_at',
            classNameHeader: 'text-center',
            render: (created_at) => (
                <div className={"text-center text-forged-steel"}>
                    <p>{convertDateToCustomFormat(created_at, 'YYYY/MM/DD HH:mm')}</p>
                </div>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            addTooltip: true,
            tooltipText: 'Ini status',
            render: (status) => (
                <div className={`text-center font-Montserrat-Bold capitalize rounded-md py-1 mr-2 ${statusRenderClassName(status).className}`}>
                    <p>{statusRenderClassName(status).text}</p>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setOrderSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysOrder}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default OrderList
