const SORT_LIST_COMMENT = [
    {
        label: 'Tanggal Terbaru',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama',
        key: 'date_asc',
        isDefault: false
    }, {
        label: 'Rating Tertinggi',
        key: 'rate_desc',
        isDefault: false
    }, {
        label: 'Rating Terendah',
        key: 'rate_asc',
        isDefault: false
    }
]

const SORT_LIST_USER = [
    {
        label: 'Nama A-Z',
        key: 'name_asc',
        isDefault: false
    }, {
        label: 'Nama Z-A',
        key: 'name_desc',
        isDefault: false
    }, {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama Dibuat',
        key: 'date_asc',
        isDefault: false
    }
]

const SORT_LIST_ORDER = [
    {
        label: 'No. Pesanan A-Z',
        key: 'id_asc',
        isDefault: false
    }, {
        label: 'No. Pesanan Z-A',
        key: 'id_desc',
        isDefault: false
    }, {
        label: 'Tanggal Pesanan Terbaru',
        key: 'created-at_desc',
        isDefault: true
    }, {
        label: 'Tanggal Pesanan Terlama',
        key: 'created-at_asc',
        isDefault: false
    }
]

const SORT_LIST_PRODUCT = [
    {
        label: 'Nama Product A-Z',
        key: 'name_asc',
        isDefault: false
    }, {
        label: 'Nama Product Z-A',
        key: 'name_desc',
        isDefault: false
    }, {
        label: 'Price Termahal',
        key: 'price_desc',
        isDefault: false
    }, {
        label: 'Price Termurah',
        key: 'price_asc',
        isDefault: false
    }, {
        label: 'Tanggal Pembuatan Terbaru',
        key: 'date_desc',
        isDefault: true
    }
]

const SORT_LIST_REVIEW = [
    {
        label: 'Total Review Tertinggi',
        key: 'count_desc',
        isDefault: true
    }, {
        label: 'Total Review Terendah',
        key: 'count_asc',
        isDefault: false
    }, {
        label: 'Total Rating Tertinggi',
        key: 'rating_desc',
        isDefault: false
    }, {
        label: 'Total Rating Terendah',
        key: 'rating_asc',
        isDefault: false
    }
]

const SORT_LIST_REVIEW_DETAIL = [
    {
        label: 'Tanggal Terbaru',
        key: 'date_desc',
        isDefault: false
    }, {
        label: 'Tanggal Terlama',
        key: 'date_asc',
        isDefault: false
    }, {
        label: 'Rating Tertinggi',
        key: 'rate_desc',
        isDefault: true
    }, {
        label: 'Rating Terendah',
        key: 'rate_asc',
        isDefault: false
    }
]

const SORT_LIST_VOUCHER = [
    {
        label: 'Tanggal Pembuatan Terbaru',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Pembuatan Terlama',
        key: 'date_asc',
        isDefault: false
    }, {
        label: 'Tanggal Expired Terbaru',
        key: 'expiredTime_desc',
        isDefault: false
    }, {
        label: 'Tanggal Expired Terlama',
        key: 'expiredTime_asc',
        isDefault: false
    }
]

const SORT_LIST_REVENUE = [
    {
        label: 'Total Sales Tertinggi',
        key: 'total-sales_desc',
        isDefault: false
    }, {
        label: 'Total Sales Terendah',
        key: 'total-sales_asc',
        isDefault: false
    }, {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }
]

const SORT_LIST_PRODUCT_SOLD = [
    {
        label: 'Product Terjual Tertinggi',
        key: 'product-sold_desc',
        isDefault: true
    }, {
        label: 'Product Terjual Terendah',
        key: 'product-sold_asc',
        isDefault: false
    }
]

const SORT_LIST_CUSTOMER = [
    {
        label: 'Jumlah Transaksi Tertinggi',
        key: 'amount-transaction_desc',
        isDefault: false
    }, {
        label: 'Jumlah Transaksi Terendah',
        key: 'amount-transaction_asc',
        isDefault: false
    }, {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }
]

const SORT_LIST_AREA = [
    {
        label: 'Nama Area A-Z',
        key: 'name_asc',
        isDefault: false
    }, {
        label: 'Nama Area Z-A',
        key: 'name_desc',
        isDefault: false
    }, {
        label: 'Jumlah Terkecil',
        key: 'store_asc',
        isDefault: false
    }, {
        label: 'Jumlah Terbesar',
        key: 'store_desc',
        isDefault: false
    }, {
        label: 'Tanggal Create Terbaru',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Create Terlama',
        key: 'date_asc',
        isDefault: false
    }
]

const SORT_LIST_AVOSTORE = [
    {
        label: 'Nama Store A-Z',
        key: 'name_asc',
        isDefault: false
    }, {
        label: 'Nama Store Z-A',
        key: 'name_desc',
        isDefault: false
    }, {
        label: 'Tanggal Create Terbaru',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Create Terlama',
        key: 'date_asc',
        isDefault: false
    }
]

const SORT_LIST_GIFT = [
    {
        label: 'Nama Gift A-Z',
        key: 'name_asc',
        isDefault: false
    }, {
        label: 'Nama Gift Z-A',
        key: 'name_desc',
        isDefault: false
    }, {
        label: 'Price Termahal',
        key: 'price_desc',
        isDefault: false
    }, {
        label: 'Price Termurah',
        key: 'price_asc',
        isDefault: false
    }, {
        label: 'Tanggal Pembuatan Terbaru',
        key: 'date_desc',
        isDefault: true
    }
]

const SORT_LIST_NOTIFICATION = [
    {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama Dibuat',
        key: 'date_asc',
        isDefault: false
    }
]

const SORT_LIST_AFFILIATE = [
    {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama Dibuat',
        key: 'date_asc',
        isDefault: false
    }
]

const SORT_LIST_AFFILIATE_PERFORMANCE = [
    {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama Dibuat',
        key: 'date_asc',
        isDefault: false
    },
    {
        label: 'Unique Visit Terbesar',
        key: 'unique-visit_desc',
        isDefault: false
    }, {
        label: 'Unique Visit Terkecil',
        key: 'unique-visit_asc',
        isDefault: false
    },
    {
        label: 'Total Order Terbesar',
        key: 'total-order_desc',
        isDefault: false
    }, {
        label: 'Total Order Terkecil',
        key: 'total-order_asc',
        isDefault: false
    },
    {
        label: 'Total Conversion Terbesar',
        key: 'total-order-convertion_desc',
        isDefault: false
    }, {
        label: 'Total Conversion Terkecil',
        key: 'total-order-convertion_asc',
        isDefault: false
    },
    {
        label: 'Total Revenue Terbesar',
        key: 'total-revenue_desc',
        isDefault: false
    }, {
        label: 'Total Revenue Terkecil',
        key: 'total-revenue_asc',
        isDefault: false
    },
]

const SORT_LIST_BEAUTY_EMPTIES = [
    {
        label: 'Tanggal Terbaru Dibuat',
        key: 'date_desc',
        isDefault: true
    }, {
        label: 'Tanggal Terlama Dibuat',
        key: 'date_asc',
        isDefault: false
    }, {
        label: 'Jumlah Pengembalian Kemasan Terbesar',
        key: 'total-qty_desc',
        isDefault: false
    }, {
        label: 'Jumlah Pengembalian Kemasan Terkecil',
        key: 'total-qty_asc',
        isDefault: false
    }
]

export {
    SORT_LIST_COMMENT,
    SORT_LIST_USER,
    SORT_LIST_ORDER,
    SORT_LIST_PRODUCT,
    SORT_LIST_REVIEW,
    SORT_LIST_REVIEW_DETAIL,
    SORT_LIST_VOUCHER,
    SORT_LIST_REVENUE,
    SORT_LIST_PRODUCT_SOLD,
    SORT_LIST_CUSTOMER,
    SORT_LIST_AREA,
    SORT_LIST_AVOSTORE,
    SORT_LIST_GIFT,
    SORT_LIST_NOTIFICATION,
    SORT_LIST_AFFILIATE,
    SORT_LIST_AFFILIATE_PERFORMANCE,
    SORT_LIST_BEAUTY_EMPTIES
}
