import {ExportIcon, LoadingSvg} from "../utils/image"
import React from "react";
import {setToolsAlertMessage, setToolsModalVisibility} from "../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import ExportConfirmationComponent from "./export-confirmation";

const ExportComponent = (props) => {
    const {wordingConfirmation, countSelected, exportDataHandler, isLoading} = props
    const dispatch = useDispatch()

    const showModalHandler = () => {
        dispatch(setToolsAlertMessage(wordingConfirmation))
        dispatch(setToolsModalVisibility({export: true}))
    }

    return (
        <>
            <div
                className={"row items-center space-x-2 px-3 py-2 font-semibold rounded-lg border border-placebo cursor-pointer"}
                onClick={showModalHandler}>
                {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : <>
                    <img src={ExportIcon} alt="export" className={"h-5"}/>
                    <p>Export</p>
                </>}
            </div>
            <ExportConfirmationComponent onApply={exportDataHandler} countSelected={countSelected}/>
        </>
    )
}

export default ExportComponent
