import InputComponent from "../../components/input.component";
import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const MarketplaceAndWebsiteForm = ({fieldList}) => {
    const {id} = useParams()
    const {avostoreDetail, avostoreErrorMessage} = useSelector(state => state.avostoreReducer)

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} data={id && avostoreDetail[item.key]} error={avostoreErrorMessage}/>
            ))}
        </div>
    )
}

export default MarketplaceAndWebsiteForm
