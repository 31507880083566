import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setNotificationDetail, setNotificationInitialNotificationDetail} from "../../reducers/notification.reducer";
import CreateNotification from "../create-notification";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import {setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import moment from "moment";

const UpdateNotification = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {
        fetching: getNotificationDetail,
        isLoading
    } = useFetch({...API.NOTIFICATION_DETAIL, ...{initPathVariable: id}}, {}, {isLoading: true})

    useEffect(() => {
        getNotificationDetail().then((response) => {
            const data = response?.data
            dispatch(setNotificationDetail(data))
            dispatch(setToolsStartDateEndDate(data?.receiver_start_date ? [moment(data?.receiver_start_date).unix(), moment(data?.receiver_end_date).unix()] : [null, null]))
        })
        return () => {
            dispatch(setNotificationInitialNotificationDetail())
        }
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className={"row justify-center w-full h-1/2"}>
                        <img src={LoadingSvg} className={"w-12 mx-auto"}/>
                    </div> :
                    <CreateNotification/>
            }
        </>
    )
}

export default UpdateNotification
