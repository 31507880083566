import './App.css';
import {Provider} from "react-redux";
import store from "./reducers";
import {BrowserRouter} from "react-router-dom";
import RoutePage from "./routes";
import {Layout} from "./pages/layout";

function App() {
  return (
      <Provider store={store}>
        <BrowserRouter>
            <Layout>
                <RoutePage/>
            </Layout>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
