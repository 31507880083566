import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import ExportComponent from "../../components/export.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {SORT_LIST_AFFILIATE_PERFORMANCE} from "../../utils/sort-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment/moment";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";

const AffiliatePerformanceFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysAffiliatePerformance} = useSelector(state => state.affiliateReducer)
    const {fetching: exportAffiliatePerformanceList, isLoading: loadingExport} = useFetch(API.EXPORT_AFFILIATE_PERFORMANCE_LIST)
    const defaultSort = SORT_LIST_AFFILIATE_PERFORMANCE?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        orderBy,
        sort,
        searchKey,
    } = useSelector(state => state.toolsReducer)

    const exportDataHandler = () => {
        const queryParams = {
            search: searchKey,
            order_by: !!orderBy ? orderBy : getOrderBy[0],
            order_type: sort,
        }
        exportAffiliatePerformanceList({affiliator_ids: selectedRowKeysAffiliatePerformance}, '', {}, 'blob', queryParams).then(res => {
            downloadFileExport(res, `Affiliate-Performance-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"Cari nama atau campaign..."}/>
                <SortComponent sortList={SORT_LIST_AFFILIATE_PERFORMANCE}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data affiliate performance ?`}
                        countSelected={selectedRowKeysAffiliatePerformance.length}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
        </div>
    )
}

export default AffiliatePerformanceFilter
