import {FIELD_LIST_INTRO_MIRACULOUS_SERIES} from "../../utils/input-field-list-static-page";
import React from "react";
import {setMiraculousSeriesIntroDetail} from "../../reducers/miraculous-series.reducer";
import {useDispatch, useSelector} from "react-redux";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Intro = () => {
    const dispatch = useDispatch()
    const {introDetail, miraculousSeriesErrorMessage} = useSelector(state => state.miraculousSeriesReducer)
    let fieldList = FIELD_LIST_INTRO_MIRACULOUS_SERIES
    let showIntro = {
        label: 'Intro',
        key: 'show_intro',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMiraculousSeriesIntroDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(introDetail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={introDetail}
            switchToggleField={showIntro}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={miraculousSeriesErrorMessage}
        />
    )
}

export default Intro