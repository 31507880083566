import moment from "moment";
import {CalendarIcon, CloseIcon, NextIcon, PrevIcon} from "../utils/image";
import React, {useEffect, useState} from "react";
import {convertDateToCustomFormat} from "../utils/general-variable";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import InputComponent from "./input.component";
import {useClickOutside} from "../services/useClickOutside";

const DatePickerComponent = (props) => {
    const {item, defaultValue, onChangeDate, dateRange} = props
    moment.updateLocale('id', {
        weekdays: ['M', 'S', 'S', 'R', 'K', 'J', 'S']
    })
    const dispatch = useDispatch()
    const [isShowModal, setIsShowModal] = useState(false)
    const [listDate, setListDate] = useState([])
    const [singleDate, setSingleDate] = useState(moment())
    const [valueInput, setValueInput] = useState('')
    const [time, setTime] = useState('00:00')
    const [dateSelected, setDateSelected] = useState('')
    const startDay = moment(singleDate).startOf("month").startOf("week")
    const endDay = moment(singleDate).endOf("month").endOf("week")
    const listDay = moment.weekdays()
    const listMonth = moment.months().map(m => {
        return {label: m, key: m.toLowerCase()}
    })

    const clearButtonHandler = () => {
        setValueInput('')
        setTime('00:00')
        setDateSelected('')
        !!onChangeDate && onChangeDate(item.key, null)
        showHideModal()
    }

    const listYear = () => {
        const getYears = []
        const endYear = moment().year()

        for (let i = endYear; i >= 1970; i--) {
            let year = {label: i.toString(), key: i}
            getYears.push(year)
        }

        return getYears
    }

    const currentMonth = () => {
        return singleDate.format("MMMM")
    }

    const currentYear = () => {
        return singleDate.format("YYYY")
    }

    const isToday = (date) => {
        return date.isSame(moment(), "day")
    }

    const defaultDate = (afterToday, beforeToday) => {
        switch (item.key) {
            case 'startDate':
            case 'endDate':
                return afterToday
            default:
                return beforeToday
        }
    }

    const isNotValidDate = (date) => {
        const dayBeforeStartYear = date.isBefore(moment(0).startOf('year'))
        const dayAfterToday = date.isAfter(moment(), "day")
        const dayBeforeStartToday = date.isBefore(moment().startOf('day'))
        if (!!dateRange) {
            return date.isAfter(dateRange?.endDate, "day") || date.isBefore(dateRange?.startDate, "day") || defaultDate(dayAfterToday, dayBeforeStartToday)
        } else if (item.key === 'birthdate') {
            return dayAfterToday || dayBeforeStartYear
        } else if (item.key === 'date') {
            return dayBeforeStartYear
        } else {
            return dayBeforeStartToday
        }
    }

    const isCurrentDate = (date) => {
        return moment(dateSelected).isSame(date, "day")
    }

    const existToPick = (date) => {
        if (isNotValidDate(date)) {
            return 'text-gray-400 cursor-not-allowed'
        } else {
            const isInMonthDate = singleDate.isSame(date, "month")
            return !isInMonthDate && 'text-gray-400'
        }
    }

    const prevMonth = () => {
        return singleDate.clone().subtract(1, "month")
    }

    const nextMonth = () => {
        return singleDate.clone().add(1, "month")
    }

    const setDate = (date, type) => {
        if (type === 'pick') {
            if (isNotValidDate(date)) {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('warning'))
                dispatch(setToolsAlertMessage("Can't pick this date"))
            } else {
                setSingleDate(date)
                setDateSelected(`${convertDateToCustomFormat(date, "YYYY-MM-DD")} ${item?.includeTime ? time : ''}`)
            }
        } else {
            setSingleDate(date)
        }
    }

    const setTimeHandler = (event) => {
        setTime(event.target.value)
        setDateSelected(`${convertDateToCustomFormat(valueInput || singleDate, "YYYY-MM-DD")} ${event.target.value}`)
    }

    const applyHandler = () => {
        const value = !!dateSelected ? dateSelected : null
        setValueInput(value)
        !!onChangeDate && onChangeDate(item.key, value)
        showHideModal()
    }

    const showHideModal = () => {
        setIsShowModal(!isShowModal)
    }

    const onChangeMonth = (value) => {
        const pickMonth = singleDate.clone().month(value?.label)
        setDate(pickMonth)
    }

    const onChangeYear = (value) => {
        const pickYear = singleDate.clone().year(Number(value?.label))
        setDate(pickYear)
    }

    useEffect(() => {
        const day = startDay.clone().subtract(1, "day")
        const initialListDate = []

        while (day.isBefore(endDay, "day")) {
            initialListDate.push(day.add(1, "day").clone())
        }

        setListDate(initialListDate)
    }, [singleDate])

    useEffect(() => {
        setValueInput(defaultValue ?? '')
        setSingleDate(defaultValue ? moment(defaultValue) : moment())
        setDateSelected(defaultValue ?? '')
        setTime(defaultValue ? moment(defaultValue).format('HH:mm') : '00:00')
    }, [defaultValue])

    const fieldSelectMonthAndYear = [
        {
            key: 'month',
            type: 'select',
            options: listMonth,
            selectLabel: 'label'
        }, {
            key: 'year',
            type: 'select',
            options: listYear(),
            selectLabel: 'label',
        }
    ]

    useClickOutside(`date-picker-component-${item.key}`, isShowModal, setIsShowModal)

    return (
        <div id={`date-picker-component-${item.key}`} className={"col space-y-2 relative"}>
            <div className={"row items-center justify-between px-3 rounded-lg h-9 border border-placebo cursor-pointer"}
                 onClick={showHideModal}>
                <p className={`${valueInput ? 'text-forged-steel' : 'italic text-fortress-grey'}`}>{valueInput || item?.placeholder}</p>
                <img className={"h-5"} src={CalendarIcon} alt={'calendar'}/>
                <input name={item.key} id={item.key} type={"text"}
                       className={'absolute top-7 left-0 invisible'} defaultValue={valueInput} readOnly/>
            </div>
            <div className={`${isShowModal ? 'block' : 'hidden'} absolute top-8 z-10`}>
                <div
                    className={"relative grid grid-cols-7 space-y-2 w-fit py-3 px-2 mb-5 font-regular text-eerie-black bg-white shadow"}>
                    <div
                        className={"col-span-full space-y-4 font-semibold text-monstera text-sm text-center mb-4 relative"}>
                        <p>{item.label}</p>
                        <div className={"row text-xs items-center space-x-4 w-full"}>
                            <img src={PrevIcon} alt="prevIcon" className={"w-5 h-8 pt-2 cursor-pointer"}
                                 onClick={() => setDate(prevMonth())}/>
                            <div className={"w-32"}>
                                <InputComponent item={fieldSelectMonthAndYear[0]} data={currentMonth()}
                                                onChangeSelect={onChangeMonth} error={{error: ''}}/>
                            </div>
                            <div className={"w-20"}>
                                <InputComponent item={fieldSelectMonthAndYear[1]} data={currentYear()}
                                                onChangeSelect={onChangeYear} error={{error: ''}}/>
                            </div>
                            <img src={NextIcon} alt="nextIcon" className={"w-5 h-8 pt-2 text-end cursor-pointer"}
                                 onClick={() => setDate(nextMonth())}/>
                        </div>
                    </div>
                    {listDay.map((day, index) => (
                        <div key={index}>
                            <p className={"font-semibold text-center"}>{day}</p>
                        </div>
                    ))}
                    {listDate.map((date, index) => (
                        <button type={"button"} key={index} onClick={() => setDate(date, "pick")}
                                className={`row justify-center items-center`}>
                            <p className={`${isToday(date) && 'font-extrabold border border border-monstera'} ${existToPick(date)} ${isCurrentDate(date) && 'bg-monstera text-white'} 
                    w-7 py-2 rounded-sm hover:bg-monstera hover:text-white`}>{date.format("D")}</p>
                        </button>
                    ))}
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-2 -top-1 cursor-pointer`}
                         onClick={showHideModal}/>
                    <div
                        className={`col-start-3 col-end-6 space-y-2 font-semibold text-monstera text-sm ${item?.includeTime ? 'block' : 'hidden'}`}>
                        <p className={"text-center"}>Time:</p>
                        <div className={"col justify-center"}>
                            <input type="time" className={"py-1 px-4 m-auto border border-forged-steel rounded-full"}
                                   value={time} onChange={setTimeHandler}/>
                        </div>
                    </div>
                    <div className={"col-start-2 col-end-8 justify-center cursor-pointer"}>
                        <div className={"row justify-between"}>
                            <div/>
                            <div type={"button"} className={"bg-battleship-green hover:bg-monstera rounded-full px-5 py-2 text-white font-semibold"} onClick={applyHandler}>
                                Apply
                            </div>
                            <button type={"button"} className={"font-semibold"} onClick={clearButtonHandler}>
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatePickerComponent
