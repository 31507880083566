const DATA_LIMIT_LIST = [
    {
        label: 'Tampilkan 20 Items',
        key: 20
    }, {
        label: 'Tampilkan 50 Items',
        key: 50
    }, {
        label: 'Tampilkan 100 Items',
        key: 100
    }, {
        label: 'Tampilkan 250 Items',
        key: 250
    }, {
        label: 'Tampilkan 500 Items',
        key: 500
    }
]

const FILTER_LIST_COMMENT = [
    {
        title: 'rating',
        list: [
            {
                label: 'Bintang 5',
                key: '5'
            }, {
                label: 'Bintang 4',
                key: '4'
            }, {
                label: 'Bintang 3',
                key: '3'
            }, {
                label: 'Bintang 2',
                key: '2'
            }, {
                label: 'Bintang 1',
                key: '1'
            }
        ]
    }
]

const FILTER_LIST_VOUCHER = [
    {
        title: 'tipe voucher',
        list: [
            {
                label: 'Fixed Cart Discount',
                key: 'fixed_cart'
            }, {
                label: 'Fixed Product Discount',
                key: 'fixed_product'
            }, {
                label: 'Shipping Discount',
                key: 'avo_shipping'
            }, {
                label: 'Free Gift',
                key: 'free_gift'
            }, {
                label: 'Waste4Change',
                key: 'avo_wfc'
            }
        ]
    }
]

const FILTER_LIST_GIFT = [
    {
        title: 'status',
        list: [
            {
                name: 'publish',
                id: 'publish'
            }, {
                name: 'draft',
                id: 'draft'
            }
        ]
    }
]

const CITIES = [
    {
        name: "Aceh Barat",
        type: "Kabupaten"
    },
    {
        name: "Aceh Barat Daya",
        type: "Kabupaten"
    },
    {
        name: "Aceh Besar",
        type: "Kabupaten"
    },
    {
        name: "Aceh Jaya",
        type: "Kabupaten"
    },
    {
        name: "Aceh Selatan",
        type: "Kabupaten"
    },
    {
        name: "Aceh Singkil",
        type: "Kabupaten"
    },
    {
        name: "Aceh Tamiang",
        type: "Kabupaten"
    },
    {
        name: "Aceh Tengah",
        type: "Kabupaten"
    },
    {
        name: "Aceh Tenggara",
        type: "Kabupaten"
    },
    {
        name: "Aceh Timur",
        type: "Kabupaten"
    },
    {
        name: "Aceh Utara",
        type: "Kabupaten"
    },
    {
        name: "Agam",
        type: "Kabupaten"
    },
    {
        name: "Alor",
        type: "Kabupaten"
    },
    {
        name: "Ambon",
        type: "Kota"
    },
    {
        name: "Asahan",
        type: "Kabupaten"
    },
    {
        name: "Asmat",
        type: "Kabupaten"
    },
    {
        name: "Badung",
        type: "Kabupaten"
    },
    {
        name: "Balangan",
        type: "Kabupaten"
    },
    {
        name: "Balikpapan",
        type: "Kota"
    },
    {
        name: "Banda Aceh",
        type: "Kota"
    },
    {
        name: "Bandar Lampung",
        type: "Kota"
    },
    {
        name: "Bandung",
        type: "Kabupaten"
    },
    // {
    //     name: "Bandung",
    //     type: "Kota"
    // },
    {
        name: "Bandung Barat",
        type: "Kabupaten"
    },
    {
        name: "Banggai",
        type: "Kabupaten"
    },
    {
        name: "Banggai Kepulauan",
        type: "Kabupaten"
    },
    {
        name: "Bangka",
        type: "Kabupaten"
    },
    {
        name: "Bangka Barat",
        type: "Kabupaten"
    },
    {
        name: "Bangka Selatan",
        type: "Kabupaten"
    },
    {
        name: "Bangka Tengah",
        type: "Kabupaten"
    },
    {
        name: "Bangkalan",
        type: "Kabupaten"
    },
    {
        name: "Bangli",
        type: "Kabupaten"
    },
    {
        name: "Banjar",
        type: "Kabupaten"
    },
    // {
    //     name: "Banjar",
    //     type: "Kota"
    // },
    {
        name: "Banjarbaru",
        type: "Kota"
    },
    {
        name: "Banjarmasin",
        type: "Kota"
    },
    {
        name: "Banjarnegara",
        type: "Kabupaten"
    },
    {
        name: "Bantaeng",
        type: "Kabupaten"
    },
    {
        name: "Bantul",
        type: "Kabupaten"
    },
    {
        name: "Banyuasin",
        type: "Kabupaten"
    },
    {
        name: "Banyumas",
        type: "Kabupaten"
    },
    {
        name: "Banyuwangi",
        type: "Kabupaten"
    },
    {
        name: "Barito Kuala",
        type: "Kabupaten"
    },
    {
        name: "Barito Selatan",
        type: "Kabupaten"
    },
    {
        name: "Barito Timur",
        type: "Kabupaten"
    },
    {
        name: "Barito Utara",
        type: "Kabupaten"
    },
    {
        name: "Barru",
        type: "Kabupaten"
    },
    {
        name: "Batam",
        type: "Kota"
    },
    {
        name: "Batang",
        type: "Kabupaten"
    },
    {
        name: "Batang Hari",
        type: "Kabupaten"
    },
    {
        name: "Batu",
        type: "Kota"
    },
    {
        name: "Batu Bara",
        type: "Kabupaten"
    },
    {
        name: "Bau-Bau",
        type: "Kota"
    },
    {
        name: "Bekasi",
        type: "Kabupaten"
    },
    // {
    //     name: "Bekasi",
    //     type: "Kota"
    // },
    {
        name: "Belitung",
        type: "Kabupaten"
    },
    {
        name: "Belitung Timur",
        type: "Kabupaten"
    },
    {
        name: "Belu",
        type: "Kabupaten"
    },
    {
        name: "Bener Meriah",
        type: "Kabupaten"
    },
    {
        name: "Bengkalis",
        type: "Kabupaten"
    },
    {
        name: "Bengkayang",
        type: "Kabupaten"
    },
    {
        name: "Bengkulu",
        type: "Kota"
    },
    {
        name: "Bengkulu Selatan",
        type: "Kabupaten"
    },
    {
        name: "Bengkulu Tengah",
        type: "Kabupaten"
    },
    {
        name: "Bengkulu Utara",
        type: "Kabupaten"
    },
    {
        name: "Berau",
        type: "Kabupaten"
    },
    {
        name: "Biak Numfor",
        type: "Kabupaten"
    },
    {
        name: "Bima",
        type: "Kabupaten"
    },
    // {
    //     name: "Bima",
    //     type: "Kota"
    // },
    {
        name: "Binjai",
        type: "Kota"
    },
    {
        name: "Bintan",
        type: "Kabupaten"
    },
    {
        name: "Bireuen",
        type: "Kabupaten"
    },
    {
        name: "Bitung",
        type: "Kota"
    },
    {
        name: "Blitar",
        type: "Kabupaten"
    },
    // {
    //     name: "Blitar",
    //     type: "Kota"
    // },
    {
        name: "Blora",
        type: "Kabupaten"
    },
    {
        name: "Boalemo",
        type: "Kabupaten"
    },
    {
        name: "Bogor",
        type: "Kabupaten"
    },
    // {
    //     name: "Bogor",
    //     type: "Kota"
    // },
    {
        name: "Bojonegoro",
        type: "Kabupaten"
    },
    {
        name: "Bolaang Mongondow (Bolmong)",
        type: "Kabupaten"
    },
    {
        name: "Bolaang Mongondow Selatan",
        type: "Kabupaten"
    },
    {
        name: "Bolaang Mongondow Timur",
        type: "Kabupaten"
    },
    {
        name: "Bolaang Mongondow Utara",
        type: "Kabupaten"
    },
    {
        name: "Bombana",
        type: "Kabupaten"
    },
    {
        name: "Bondowoso",
        type: "Kabupaten"
    },
    {
        name: "Bone",
        type: "Kabupaten"
    },
    {
        name: "Bone Bolango",
        type: "Kabupaten"
    },
    {
        name: "Bontang",
        type: "Kota"
    },
    {
        name: "Boven Digoel",
        type: "Kabupaten"
    },
    {
        name: "Boyolali",
        type: "Kabupaten"
    },
    {
        name: "Brebes",
        type: "Kabupaten"
    },
    {
        name: "Bukittinggi",
        type: "Kota"
    },
    {
        name: "Buleleng",
        type: "Kabupaten"
    },
    {
        name: "Bulukumba",
        type: "Kabupaten"
    },
    {
        name: "Bulungan (Bulongan)",
        type: "Kabupaten"
    },
    {
        name: "Bungo",
        type: "Kabupaten"
    },
    {
        name: "Buol",
        type: "Kabupaten"
    },
    {
        name: "Buru",
        type: "Kabupaten"
    },
    {
        name: "Buru Selatan",
        type: "Kabupaten"
    },
    {
        name: "Buton",
        type: "Kabupaten"
    },
    {
        name: "Buton Utara",
        type: "Kabupaten"
    },
    {
        name: "Ciamis",
        type: "Kabupaten"
    },
    {
        name: "Cianjur",
        type: "Kabupaten"
    },
    {
        name: "Cilacap",
        type: "Kabupaten"
    },
    {
        name: "Cilegon",
        type: "Kota"
    },
    {
        name: "Cimahi",
        type: "Kota"
    },
    {
        name: "Cirebon",
        type: "Kabupaten"
    },
    // {
    //     name: "Cirebon",
    //     type: "Kota"
    // },
    {
        name: "Dairi",
        type: "Kabupaten"
    },
    {
        name: "Deiyai (Deliyai)",
        type: "Kabupaten"
    },
    {
        name: "Deli Serdang",
        type: "Kabupaten"
    },
    {
        name: "Demak",
        type: "Kabupaten"
    },
    {
        name: "Denpasar",
        type: "Kota"
    },
    {
        name: "Depok",
        type: "Kota"
    },
    {
        name: "Dharmasraya",
        type: "Kabupaten"
    },
    {
        name: "Dogiyai",
        type: "Kabupaten"
    },
    {
        name: "Dompu",
        type: "Kabupaten"
    },
    {
        name: "Donggala",
        type: "Kabupaten"
    },
    {
        name: "Dumai",
        type: "Kota"
    },
    {
        name: "Empat Lawang",
        type: "Kabupaten"
    },
    {
        name: "Ende",
        type: "Kabupaten"
    },
    {
        name: "Enrekang",
        type: "Kabupaten"
    },
    {
        name: "Fakfak",
        type: "Kabupaten"
    },
    {
        name: "Flores Timur",
        type: "Kabupaten"
    },
    {
        name: "Garut",
        type: "Kabupaten"
    },
    {
        name: "Gayo Lues",
        type: "Kabupaten"
    },
    {
        name: "Gianyar",
        type: "Kabupaten"
    },
    {
        name: "Gorontalo",
        type: "Kabupaten"
    },
    // {
    //     name: "Gorontalo",
    //     type: "Kota"
    // },
    {
        name: "Gorontalo Utara",
        type: "Kabupaten"
    },
    {
        name: "Gowa",
        type: "Kabupaten"
    },
    {
        name: "Gresik",
        type: "Kabupaten"
    },
    {
        name: "Grobogan",
        type: "Kabupaten"
    },
    {
        name: "Gunung Kidul",
        type: "Kabupaten"
    },
    {
        name: "Gunung Mas",
        type: "Kabupaten"
    },
    {
        name: "Gunungsitoli",
        type: "Kota"
    },
    {
        name: "Halmahera Barat",
        type: "Kabupaten"
    },
    {
        name: "Halmahera Selatan",
        type: "Kabupaten"
    },
    {
        name: "Halmahera Tengah",
        type: "Kabupaten"
    },
    {
        name: "Halmahera Timur",
        type: "Kabupaten"
    },
    {
        name: "Halmahera Utara",
        type: "Kabupaten"
    },
    {
        name: "Hulu Sungai Selatan",
        type: "Kabupaten"
    },
    {
        name: "Hulu Sungai Tengah",
        type: "Kabupaten"
    },
    {
        name: "Hulu Sungai Utara",
        type: "Kabupaten"
    },
    {
        name: "Humbang Hasundutan",
        type: "Kabupaten"
    },
    {
        name: "Indragiri Hilir",
        type: "Kabupaten"
    },
    {
        name: "Indragiri Hulu",
        type: "Kabupaten"
    },
    {
        name: "Indramayu",
        type: "Kabupaten"
    },
    {
        name: "Intan Jaya",
        type: "Kabupaten"
    },
    {
        name: "Jakarta Barat",
        type: "Kota"
    },
    {
        name: "Jakarta Pusat",
        type: "Kota"
    },
    {
        name: "Jakarta Selatan",
        type: "Kota"
    },
    {
        name: "Jakarta Timur",
        type: "Kota"
    },
    {
        name: "Jakarta Utara",
        type: "Kota"
    },
    {
        name: "Jambi",
        type: "Kota"
    },
    {
        name: "Jayapura",
        type: "Kabupaten"
    },
    // {
    //     name: "Jayapura",
    //     type: "Kota"
    // },
    {
        name: "Jayawijaya",
        type: "Kabupaten"
    },
    {
        name: "Jember",
        type: "Kabupaten"
    },
    {
        name: "Jembrana",
        type: "Kabupaten"
    },
    {
        name: "Jeneponto",
        type: "Kabupaten"
    },
    {
        name: "Jepara",
        type: "Kabupaten"
    },
    {
        name: "Jombang",
        type: "Kabupaten"
    },
    {
        name: "Kaimana",
        type: "Kabupaten"
    },
    {
        name: "Kampar",
        type: "Kabupaten"
    },
    {
        name: "Kapuas",
        type: "Kabupaten"
    },
    {
        name: "Kapuas Hulu",
        type: "Kabupaten"
    },
    {
        name: "Karanganyar",
        type: "Kabupaten"
    },
    {
        name: "Karangasem",
        type: "Kabupaten"
    },
    {
        name: "Karawang",
        type: "Kabupaten"
    },
    {
        name: "Karimun",
        type: "Kabupaten"
    },
    {
        name: "Karo",
        type: "Kabupaten"
    },
    {
        name: "Katingan",
        type: "Kabupaten"
    },
    {
        name: "Kaur",
        type: "Kabupaten"
    },
    {
        name: "Kayong Utara",
        type: "Kabupaten"
    },
    {
        name: "Kebumen",
        type: "Kabupaten"
    },
    {
        name: "Kediri",
        type: "Kabupaten"
    },
    // {
    //     name: "Kediri",
    //     type: "Kota"
    // },
    {
        name: "Keerom",
        type: "Kabupaten"
    },
    {
        name: "Kendal",
        type: "Kabupaten"
    },
    {
        name: "Kendari",
        type: "Kota"
    },
    {
        name: "Kepahiang",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Anambas",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Aru",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Mentawai",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Meranti",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Sangihe",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Seribu",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Siau Tagulandang Biaro (Sitaro)",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Sula",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Talaud",
        type: "Kabupaten"
    },
    {
        name: "Kepulauan Yapen (Yapen Waropen)",
        type: "Kabupaten"
    },
    {
        name: "Kerinci",
        type: "Kabupaten"
    },
    {
        name: "Ketapang",
        type: "Kabupaten"
    },
    {
        name: "Klaten",
        type: "Kabupaten"
    },
    {
        name: "Klungkung",
        type: "Kabupaten"
    },
    {
        name: "Kolaka",
        type: "Kabupaten"
    },
    {
        name: "Kolaka Utara",
        type: "Kabupaten"
    },
    {
        name: "Konawe",
        type: "Kabupaten"
    },
    {
        name: "Konawe Selatan",
        type: "Kabupaten"
    },
    {
        name: "Konawe Utara",
        type: "Kabupaten"
    },
    {
        name: "Kotabaru",
        type: "Kabupaten"
    },
    {
        name: "Kotamobagu",
        type: "Kota"
    },
    {
        name: "Kotawaringin Barat",
        type: "Kabupaten"
    },
    {
        name: "Kotawaringin Timur",
        type: "Kabupaten"
    },
    {
        name: "Kuantan Singingi",
        type: "Kabupaten"
    },
    {
        name: "Kubu Raya",
        type: "Kabupaten"
    },
    {
        name: "Kudus",
        type: "Kabupaten"
    },
    {
        name: "Kulon Progo",
        type: "Kabupaten"
    },
    {
        name: "Kuningan",
        type: "Kabupaten"
    },
    {
        name: "Kupang",
        type: "Kabupaten"
    },
    // {
    //     name: "Kupang",
    //     type: "Kota"
    // },
    {
        name: "Kutai Barat",
        type: "Kabupaten"
    },
    {
        name: "Kutai Kartanegara",
        type: "Kabupaten"
    },
    {
        name: "Kutai Timur",
        type: "Kabupaten"
    },
    {
        name: "Labuhan Batu",
        type: "Kabupaten"
    },
    {
        name: "Labuhan Batu Selatan",
        type: "Kabupaten"
    },
    {
        name: "Labuhan Batu Utara",
        type: "Kabupaten"
    },
    {
        name: "Lahat",
        type: "Kabupaten"
    },
    {
        name: "Lamandau",
        type: "Kabupaten"
    },
    {
        name: "Lamongan",
        type: "Kabupaten"
    },
    {
        name: "Lampung Barat",
        type: "Kabupaten"
    },
    {
        name: "Lampung Selatan",
        type: "Kabupaten"
    },
    {
        name: "Lampung Tengah",
        type: "Kabupaten"
    },
    {
        name: "Lampung Timur",
        type: "Kabupaten"
    },
    {
        name: "Lampung Utara",
        type: "Kabupaten"
    },
    {
        name: "Landak",
        type: "Kabupaten"
    },
    {
        name: "Langkat",
        type: "Kabupaten"
    },
    {
        name: "Langsa",
        type: "Kota"
    },
    {
        name: "Lanny Jaya",
        type: "Kabupaten"
    },
    {
        name: "Lebak",
        type: "Kabupaten"
    },
    {
        name: "Lebong",
        type: "Kabupaten"
    },
    {
        name: "Lembata",
        type: "Kabupaten"
    },
    {
        name: "Lhokseumawe",
        type: "Kota"
    },
    {
        name: "Lima Puluh Koto/Kota",
        type: "Kabupaten"
    },
    {
        name: "Lingga",
        type: "Kabupaten"
    },
    {
        name: "Lombok Barat",
        type: "Kabupaten"
    },
    {
        name: "Lombok Tengah",
        type: "Kabupaten"
    },
    {
        name: "Lombok Timur",
        type: "Kabupaten"
    },
    {
        name: "Lombok Utara",
        type: "Kabupaten"
    },
    {
        name: "Lubuk Linggau",
        type: "Kota"
    },
    {
        name: "Lumajang",
        type: "Kabupaten"
    },
    {
        name: "Luwu",
        type: "Kabupaten"
    },
    {
        name: "Luwu Timur",
        type: "Kabupaten"
    },
    {
        name: "Luwu Utara",
        type: "Kabupaten"
    },
    {
        name: "Madiun",
        type: "Kabupaten"
    },
    // {
    //     name: "Madiun",
    //     type: "Kota"
    // },
    {
        name: "Magelang",
        type: "Kabupaten"
    },
    // {
    //     name: "Magelang",
    //     type: "Kota"
    // },
    {
        name: "Magetan",
        type: "Kabupaten"
    },
    {
        name: "Majalengka",
        type: "Kabupaten"
    },
    {
        name: "Majene",
        type: "Kabupaten"
    },
    {
        name: "Makassar",
        type: "Kota"
    },
    {
        name: "Malang",
        type: "Kabupaten"
    },
    // {
    //     name: "Malang",
    //     type: "Kota"
    // },
    {
        name: "Malinau",
        type: "Kabupaten"
    },
    {
        name: "Maluku Barat Daya",
        type: "Kabupaten"
    },
    {
        name: "Maluku Tengah",
        type: "Kabupaten"
    },
    {
        name: "Maluku Tenggara",
        type: "Kabupaten"
    },
    {
        name: "Maluku Tenggara Barat",
        type: "Kabupaten"
    },
    {
        name: "Mamasa",
        type: "Kabupaten"
    },
    {
        name: "Mamberamo Raya",
        type: "Kabupaten"
    },
    {
        name: "Mamberamo Tengah",
        type: "Kabupaten"
    },
    {
        name: "Mamuju",
        type: "Kabupaten"
    },
    {
        name: "Mamuju Utara",
        type: "Kabupaten"
    },
    {
        name: "Manado",
        type: "Kota"
    },
    {
        name: "Mandailing Natal",
        type: "Kabupaten"
    },
    {
        name: "Manggarai",
        type: "Kabupaten"
    },
    {
        name: "Manggarai Barat",
        type: "Kabupaten"
    },
    {
        name: "Manggarai Timur",
        type: "Kabupaten"
    },
    {
        name: "Manokwari",
        type: "Kabupaten"
    },
    {
        name: "Manokwari Selatan",
        type: "Kabupaten"
    },
    {
        name: "Mappi",
        type: "Kabupaten"
    },
    {
        name: "Maros",
        type: "Kabupaten"
    },
    {
        name: "Mataram",
        type: "Kota"
    },
    {
        name: "Maybrat",
        type: "Kabupaten"
    },
    {
        name: "Medan",
        type: "Kota"
    },
    {
        name: "Melawi",
        type: "Kabupaten"
    },
    {
        name: "Merangin",
        type: "Kabupaten"
    },
    {
        name: "Merauke",
        type: "Kabupaten"
    },
    {
        name: "Mesuji",
        type: "Kabupaten"
    },
    {
        name: "Metro",
        type: "Kota"
    },
    {
        name: "Mimika",
        type: "Kabupaten"
    },
    {
        name: "Minahasa",
        type: "Kabupaten"
    },
    {
        name: "Minahasa Selatan",
        type: "Kabupaten"
    },
    {
        name: "Minahasa Tenggara",
        type: "Kabupaten"
    },
    {
        name: "Minahasa Utara",
        type: "Kabupaten"
    },
    {
        name: "Mojokerto",
        type: "Kabupaten"
    },
    // {
    //     name: "Mojokerto",
    //     type: "Kota"
    // },
    {
        name: "Morowali",
        type: "Kabupaten"
    },
    {
        name: "Muara Enim",
        type: "Kabupaten"
    },
    {
        name: "Muaro Jambi",
        type: "Kabupaten"
    },
    {
        name: "Muko Muko",
        type: "Kabupaten"
    },
    {
        name: "Muna",
        type: "Kabupaten"
    },
    {
        name: "Murung Raya",
        type: "Kabupaten"
    },
    {
        name: "Musi Banyuasin",
        type: "Kabupaten"
    },
    {
        name: "Musi Rawas",
        type: "Kabupaten"
    },
    {
        name: "Nabire",
        type: "Kabupaten"
    },
    {
        name: "Nagan Raya",
        type: "Kabupaten"
    },
    {
        name: "Nagekeo",
        type: "Kabupaten"
    },
    {
        name: "Natuna",
        type: "Kabupaten"
    },
    {
        name: "Nduga",
        type: "Kabupaten"
    },
    {
        name: "Ngada",
        type: "Kabupaten"
    },
    {
        name: "Nganjuk",
        type: "Kabupaten"
    },
    {
        name: "Ngawi",
        type: "Kabupaten"
    },
    {
        name: "Nias",
        type: "Kabupaten"
    },
    {
        name: "Nias Barat",
        type: "Kabupaten"
    },
    {
        name: "Nias Selatan",
        type: "Kabupaten"
    },
    {
        name: "Nias Utara",
        type: "Kabupaten"
    },
    {
        name: "Nunukan",
        type: "Kabupaten"
    },
    {
        name: "Ogan Ilir",
        type: "Kabupaten"
    },
    {
        name: "Ogan Komering Ilir",
        type: "Kabupaten"
    },
    {
        name: "Ogan Komering Ulu",
        type: "Kabupaten"
    },
    {
        name: "Ogan Komering Ulu Selatan",
        type: "Kabupaten"
    },
    {
        name: "Ogan Komering Ulu Timur",
        type: "Kabupaten"
    },
    {
        name: "Pacitan",
        type: "Kabupaten"
    },
    {
        name: "Padang",
        type: "Kota"
    },
    {
        name: "Padang Lawas",
        type: "Kabupaten"
    },
    {
        name: "Padang Lawas Utara",
        type: "Kabupaten"
    },
    {
        name: "Padang Panjang",
        type: "Kota"
    },
    {
        name: "Padang Pariaman",
        type: "Kabupaten"
    },
    {
        name: "Padang Sidempuan",
        type: "Kota"
    },
    {
        name: "Pagar Alam",
        type: "Kota"
    },
    {
        name: "Pakpak Bharat",
        type: "Kabupaten"
    },
    {
        name: "Palangka Raya",
        type: "Kota"
    },
    {
        name: "Palembang",
        type: "Kota"
    },
    {
        name: "Palopo",
        type: "Kota"
    },
    {
        name: "Palu",
        type: "Kota"
    },
    {
        name: "Pamekasan",
        type: "Kabupaten"
    },
    {
        name: "Pandeglang",
        type: "Kabupaten"
    },
    {
        name: "Pangandaran",
        type: "Kabupaten"
    },
    {
        name: "Pangkajene Kepulauan",
        type: "Kabupaten"
    },
    {
        name: "Pangkal Pinang",
        type: "Kota"
    },
    {
        name: "Paniai",
        type: "Kabupaten"
    },
    {
        name: "Parepare",
        type: "Kota"
    },
    {
        name: "Pariaman",
        type: "Kota"
    },
    {
        name: "Parigi Moutong",
        type: "Kabupaten"
    },
    {
        name: "Pasaman",
        type: "Kabupaten"
    },
    {
        name: "Pasaman Barat",
        type: "Kabupaten"
    },
    {
        name: "Paser",
        type: "Kabupaten"
    },
    {
        name: "Pasuruan",
        type: "Kabupaten"
    },
    // {
    //     name: "Pasuruan",
    //     type: "Kota"
    // },
    {
        name: "Pati",
        type: "Kabupaten"
    },
    {
        name: "Payakumbuh",
        type: "Kota"
    },
    {
        name: "Pegunungan Arfak",
        type: "Kabupaten"
    },
    {
        name: "Pegunungan Bintang",
        type: "Kabupaten"
    },
    {
        name: "Pekalongan",
        type: "Kabupaten"
    },
    // {
    //     name: "Pekalongan",
    //     type: "Kota"
    // },
    {
        name: "Pekanbaru",
        type: "Kota"
    },
    {
        name: "Pelalawan",
        type: "Kabupaten"
    },
    {
        name: "Pemalang",
        type: "Kabupaten"
    },
    {
        name: "Pematang Siantar",
        type: "Kota"
    },
    {
        name: "Penajam Paser Utara",
        type: "Kabupaten"
    },
    {
        name: "Pesawaran",
        type: "Kabupaten"
    },
    {
        name: "Pesisir Barat",
        type: "Kabupaten"
    },
    {
        name: "Pesisir Selatan",
        type: "Kabupaten"
    },
    {
        name: "Pidie",
        type: "Kabupaten"
    },
    {
        name: "Pidie Jaya",
        type: "Kabupaten"
    },
    {
        name: "Pinrang",
        type: "Kabupaten"
    },
    {
        name: "Pohuwato",
        type: "Kabupaten"
    },
    {
        name: "Polewali Mandar",
        type: "Kabupaten"
    },
    {
        name: "Ponorogo",
        type: "Kabupaten"
    },
    {
        name: "Pontianak",
        type: "Kabupaten"
    },
    // {
    //     name: "Pontianak",
    //     type: "Kota"
    // },
    {
        name: "Poso",
        type: "Kabupaten"
    },
    {
        name: "Prabumulih",
        type: "Kota"
    },
    {
        name: "Pringsewu",
        type: "Kabupaten"
    },
    {
        name: "Probolinggo",
        type: "Kabupaten"
    },
    // {
    //     name: "Probolinggo",
    //     type: "Kota"
    // },
    {
        name: "Pulang Pisau",
        type: "Kabupaten"
    },
    {
        name: "Pulau Morotai",
        type: "Kabupaten"
    },
    {
        name: "Puncak",
        type: "Kabupaten"
    },
    {
        name: "Puncak Jaya",
        type: "Kabupaten"
    },
    {
        name: "Purbalingga",
        type: "Kabupaten"
    },
    {
        name: "Purwakarta",
        type: "Kabupaten"
    },
    {
        name: "Purworejo",
        type: "Kabupaten"
    },
    {
        name: "Raja Ampat",
        type: "Kabupaten"
    },
    {
        name: "Rejang Lebong",
        type: "Kabupaten"
    },
    {
        name: "Rembang",
        type: "Kabupaten"
    },
    {
        name: "Rokan Hilir",
        type: "Kabupaten"
    },
    {
        name: "Rokan Hulu",
        type: "Kabupaten"
    },
    {
        name: "Rote Ndao",
        type: "Kabupaten"
    },
    {
        name: "Sabang",
        type: "Kota"
    },
    {
        name: "Sabu Raijua",
        type: "Kabupaten"
    },
    {
        name: "Salatiga",
        type: "Kota"
    },
    {
        name: "Samarinda",
        type: "Kota"
    },
    {
        name: "Sambas",
        type: "Kabupaten"
    },
    {
        name: "Samosir",
        type: "Kabupaten"
    },
    {
        name: "Sampang",
        type: "Kabupaten"
    },
    {
        name: "Sanggau",
        type: "Kabupaten"
    },
    {
        name: "Sarmi",
        type: "Kabupaten"
    },
    {
        name: "Sarolangun",
        type: "Kabupaten"
    },
    {
        name: "Sawah Lunto",
        type: "Kota"
    },
    {
        name: "Sekadau",
        type: "Kabupaten"
    },
    {
        name: "Selayar (Kepulauan Selayar)",
        type: "Kabupaten"
    },
    {
        name: "Seluma",
        type: "Kabupaten"
    },
    {
        name: "Semarang",
        type: "Kabupaten"
    },
    // {
    //     name: "Semarang",
    //     type: "Kota"
    // },
    {
        name: "Seram Bagian Barat",
        type: "Kabupaten"
    },
    {
        name: "Seram Bagian Timur",
        type: "Kabupaten"
    },
    {
        name: "Serang",
        type: "Kabupaten"
    },
    // {
    //     name: "Serang",
    //     type: "Kota"
    // },
    {
        name: "Serdang Bedagai",
        type: "Kabupaten"
    },
    {
        name: "Seruyan",
        type: "Kabupaten"
    },
    {
        name: "Siak",
        type: "Kabupaten"
    },
    {
        name: "Sibolga",
        type: "Kota"
    },
    {
        name: "Sidenreng Rappang/Rapang",
        type: "Kabupaten"
    },
    {
        name: "Sidoarjo",
        type: "Kabupaten"
    },
    {
        name: "Sigi",
        type: "Kabupaten"
    },
    {
        name: "Sijunjung (Sawah Lunto Sijunjung)",
        type: "Kabupaten"
    },
    {
        name: "Sikka",
        type: "Kabupaten"
    },
    {
        name: "Simalungun",
        type: "Kabupaten"
    },
    {
        name: "Simeulue",
        type: "Kabupaten"
    },
    {
        name: "Singkawang",
        type: "Kota"
    },
    {
        name: "Sinjai",
        type: "Kabupaten"
    },
    {
        name: "Sintang",
        type: "Kabupaten"
    },
    {
        name: "Situbondo",
        type: "Kabupaten"
    },
    {
        name: "Sleman",
        type: "Kabupaten"
    },
    {
        name: "Solok",
        type: "Kabupaten"
    },
    // {
    //     name: "Solok",
    //     type: "Kota"
    // },
    {
        name: "Solok Selatan",
        type: "Kabupaten"
    },
    {
        name: "Soppeng",
        type: "Kabupaten"
    },
    {
        name: "Sorong",
        type: "Kabupaten"
    },
    // {
    //     name: "Sorong",
    //     type: "Kota"
    // },
    {
        name: "Sorong Selatan",
        type: "Kabupaten"
    },
    {
        name: "Sragen",
        type: "Kabupaten"
    },
    {
        name: "Subang",
        type: "Kabupaten"
    },
    {
        name: "Subulussalam",
        type: "Kota"
    },
    {
        name: "Sukabumi",
        type: "Kabupaten"
    },
    // {
    //     name: "Sukabumi",
    //     type: "Kota"
    // },
    {
        name: "Sukamara",
        type: "Kabupaten"
    },
    {
        name: "Sukoharjo",
        type: "Kabupaten"
    },
    {
        name: "Sumba Barat",
        type: "Kabupaten"
    },
    {
        name: "Sumba Barat Daya",
        type: "Kabupaten"
    },
    {
        name: "Sumba Tengah",
        type: "Kabupaten"
    },
    {
        name: "Sumba Timur",
        type: "Kabupaten"
    },
    {
        name: "Sumbawa",
        type: "Kabupaten"
    },
    {
        name: "Sumbawa Barat",
        type: "Kabupaten"
    },
    {
        name: "Sumedang",
        type: "Kabupaten"
    },
    {
        name: "Sumenep",
        type: "Kabupaten"
    },
    {
        name: "Sungaipenuh",
        type: "Kota"
    },
    {
        name: "Supiori",
        type: "Kabupaten"
    },
    {
        name: "Surabaya",
        type: "Kota"
    },
    {
        name: "Surakarta",
        type: "Kota"
    },
    {
        name: "Tabalong",
        type: "Kabupaten"
    },
    {
        name: "Tabanan",
        type: "Kabupaten"
    },
    {
        name: "Takalar",
        type: "Kabupaten"
    },
    {
        name: "Tambrauw",
        type: "Kabupaten"
    },
    {
        name: "Tana Tidung",
        type: "Kabupaten"
    },
    {
        name: "Tana Toraja",
        type: "Kabupaten"
    },
    {
        name: "Tanah Bumbu",
        type: "Kabupaten"
    },
    {
        name: "Tanah Datar",
        type: "Kabupaten"
    },
    {
        name: "Tanah Laut",
        type: "Kabupaten"
    },
    {
        name: "Tangerang",
        type: "Kabupaten"
    },
    {
        name: "Tangerang",
        type: "Kota"
    },
    {
        name: "Tangerang Selatan",
        type: "Kota"
    },
    {
        name: "Tanggamus",
        type: "Kabupaten"
    },
    {
        name: "Tanjung Balai",
        type: "Kota"
    },
    {
        name: "Tanjung Jabung Barat",
        type: "Kabupaten"
    },
    {
        name: "Tanjung Jabung Timur",
        type: "Kabupaten"
    },
    {
        name: "Tanjung Pinang",
        type: "Kota"
    },
    {
        name: "Tapanuli Selatan",
        type: "Kabupaten"
    },
    {
        name: "Tapanuli Tengah",
        type: "Kabupaten"
    },
    {
        name: "Tapanuli Utara",
        type: "Kabupaten"
    },
    {
        name: "Tapin",
        type: "Kabupaten"
    },
    {
        name: "Tarakan",
        type: "Kota"
    },
    {
        name: "Tasikmalaya",
        type: "Kabupaten"
    },
    // {
    //     name: "Tasikmalaya",
    //     type: "Kota"
    // },
    {
        name: "Tebing Tinggi",
        type: "Kota"
    },
    {
        name: "Tebo",
        type: "Kabupaten"
    },
    {
        name: "Tegal",
        type: "Kabupaten"
    },
    // {
    //     name: "Tegal",
    //     type: "Kota"
    // },
    {
        name: "Teluk Bintuni",
        type: "Kabupaten"
    },
    {
        name: "Teluk Wondama",
        type: "Kabupaten"
    },
    {
        name: "Temanggung",
        type: "Kabupaten"
    },
    {
        name: "Ternate",
        type: "Kota"
    },
    {
        name: "Tidore Kepulauan",
        type: "Kota"
    },
    {
        name: "Timor Tengah Selatan",
        type: "Kabupaten"
    },
    {
        name: "Timor Tengah Utara",
        type: "Kabupaten"
    },
    {
        name: "Toba Samosir",
        type: "Kabupaten"
    },
    {
        name: "Tojo Una-Una",
        type: "Kabupaten"
    },
    {
        name: "Toli-Toli",
        type: "Kabupaten"
    },
    {
        name: "Tolikara",
        type: "Kabupaten"
    },
    {
        name: "Tomohon",
        type: "Kota"
    },
    {
        name: "Toraja Utara",
        type: "Kabupaten"
    },
    {
        name: "Trenggalek",
        type: "Kabupaten"
    },
    {
        name: "Tual",
        type: "Kota"
    },
    {
        name: "Tuban",
        type: "Kabupaten"
    },
    {
        name: "Tulang Bawang",
        type: "Kabupaten"
    },
    {
        name: "Tulang Bawang Barat",
        type: "Kabupaten"
    },
    {
        name: "Tulungagung",
        type: "Kabupaten"
    },
    {
        name: "Wajo",
        type: "Kabupaten"
    },
    {
        name: "Wakatobi",
        type: "Kabupaten"
    },
    {
        name: "Waropen",
        type: "Kabupaten"
    },
    {
        name: "Way Kanan",
        type: "Kabupaten"
    },
    {
        name: "Wonogiri",
        type: "Kabupaten"
    },
    {
        name: "Wonosobo",
        type: "Kabupaten"
    },
    {
        name: "Yahukimo",
        type: "Kabupaten"
    },
    {
        name: "Yalimo",
        type: "Kabupaten"
    },
    {
        name: "Yogyakarta",
        type: "Kota"
    }
]

const FILTER_LIST_BEAUTY_EMPTIES = [
    {
        title: 'status',
        list: [
            {
                name: 'Waiting Approval',
                id: 'waiting-approval'
            }, {
                name: 'Accepted',
                id: 'accepted'
            }, {
                name: 'Rejected',
                id: 'rejected'
            }
        ]
    }
]

export {
    DATA_LIMIT_LIST,
    FILTER_LIST_COMMENT,
    FILTER_LIST_VOUCHER,
    FILTER_LIST_GIFT,
    CITIES,
    FILTER_LIST_BEAUTY_EMPTIES
}
