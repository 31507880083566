import {useDispatch, useSelector} from "react-redux";
import {setReviewActiveSidebar} from "../../reducers/review.reducer";
import {setToolsCurrentPage} from "../../reducers/tools.reducer";
import {useEffect} from "react";

const ReviewDetailSidebar = () => {
    const dispatch = useDispatch()
    const {user_choices, activeSidebar} = useSelector(state => state.reviewReducer)
    const defaultChoice = user_choices[0]?.query

    const onChangeSidebar = (key) => {
        dispatch(setReviewActiveSidebar(key))
        dispatch(setToolsCurrentPage(1))
    }

    useEffect(() => {
        dispatch(setReviewActiveSidebar(defaultChoice))
    }, [defaultChoice])

    return (
        <div className={"col space-y-2 my-5 h-full font-regular bg-doctor overflow-hidden"}>
            <div className={"row justify-center py-3 bg-battleship-green rounded-t-lg"}>
                <p className={"font-semibold text-white"}>Pilihan User</p>
            </div>
            <div className={"col space-y-1 text-forged-steel"}>
                {user_choices.map((dataSelect, index) => (
                    <div key={index} onClick={() => onChangeSidebar(dataSelect.query)}
                         className={`row justify-between p-3 hover:bg-whisper-green cursor-pointer ${activeSidebar === dataSelect.query && 'bg-whisper-green text-monstera'}`}>
                        <p className={"font-semibold"}>{dataSelect.name}</p>
                        <p>({dataSelect.count})</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ReviewDetailSidebar
