import {createSlice} from '@reduxjs/toolkit'
import {advanceFilterOrderListModel} from "../models/advance-filter";

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        activeTab: 'all',
        selectedRowKeysOrder: [],
        orderDetail: {},
        advanceFilter: {...advanceFilterOrderListModel},
        errorAdvanceFilter: {}
    },
    reducers: {
        setOrderActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setOrderSelectedRowKeys: (state, action) => {
            state.selectedRowKeysOrder = action.payload
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload
        },
        setOrderInitialState: (state, action) => {
            state.selectedRowKeysOrder = []
            state.activeTab = 'all'
        },
        setOrderAdvanceFilter: (state, action) => {
            state.advanceFilter = {...state.advanceFilter, ...action.payload}
        },
        setOrderErrorAdvanceFilter: (state, action) => {
            state.errorAdvanceFilter = action.payload
        },
    },
})

export const {
    setOrderActiveTab,
    setOrderSelectedRowKeys,
    setOrderDetail,
    setOrderInitialState,
    setOrderAdvanceFilter,
    setOrderErrorAdvanceFilter
} = orderSlice.actions
const orderReducer = orderSlice.reducer

export default orderReducer
