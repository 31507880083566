import {createSlice} from '@reduxjs/toolkit'
import {
    bannerModel,
    benefitModel,
    descriptionModel,
    giftBannerModel,
    miniBannerModel,
    redeemProductModel
} from "../models/waste-4-change.model";

export const waste4ChangeSlice = createSlice({
    name: 'waste4Change',
    initialState: {
        bannerDetail: {...bannerModel},
        descriptionDetail: {...descriptionModel},
        benefitDetail: {...benefitModel},
        redeemProductDetail: {...redeemProductModel},
        miniBannerDetail: {...miniBannerModel},
        giftBannerDetail: {...giftBannerModel},
        waste4ChangeErrorMessage: {},
    },
    reducers: {
        setWaste4ChangeBannerDetail: (state, action) => {
            state.bannerDetail = {...state.bannerDetail, ...action.payload}
        },
        setWaste4ChangeDescriptionDetail: (state, action) => {
            state.descriptionDetail = {...state.descriptionDetail, ...action.payload}
        },
        setWaste4ChangeBenefitDetail: (state, action) => {
            state.benefitDetail = {...state.benefitDetail, ...action.payload}
        },
        setWaste4ChangeRedeemProductDetail: (state, action) => {
            state.redeemProductDetail = {...state.redeemProductDetail, ...action.payload}
        },
        setWaste4ChangeMiniBannerDetail: (state, action) => {
            state.miniBannerDetail = {...state.miniBannerDetail, ...action.payload}
        },
        setWaste4ChangeGiftBannerDetail: (state, action) => {
            state.giftBannerDetail = {...state.giftBannerDetail, ...action.payload}
        },
        setWaste4ChangeErrorMessage: (state, action) => {
            state.waste4ChangeErrorMessage = action.payload
        }
    },
})

export const {
    setWaste4ChangeBannerDetail,
    setWaste4ChangeDescriptionDetail,
    setWaste4ChangeBenefitDetail,
    setWaste4ChangeRedeemProductDetail,
    setWaste4ChangeMiniBannerDetail,
    setWaste4ChangeGiftBannerDetail,
    setWaste4ChangeErrorMessage,
} = waste4ChangeSlice.actions
const waste4ChangeReducer = waste4ChangeSlice.reducer

export default waste4ChangeReducer