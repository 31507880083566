import ModalCardComponent from "../../components/modal-card.component";
import {CloseIcon, LoadingSvg} from "../../utils/image";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsAlertMessage, setToolsCaptcha, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import InputCode from "./input-code";

const Verification = ({recaptchaRef}) => {
    const dispatch = useDispatch()
    const sessid_token = localStorage.getItem('sessid_token')
    const [errorOTP, setErrorOTP] = useState('')
    const [OTP, setOTP] = useState('')
    const otpLength = 4
    const {verification} = useSelector(state => state.toolsReducer.modalVisibility)
    const {fetching: postVerifyLogin, isLoading} = useFetch(API.VERIFY_LOGIN)

    const closeModalHandler = () => {
        dispatch(setToolsCaptcha(''))
        recaptchaRef?.current?.reset()
        dispatch(setToolsModalVisibility({verification: false}))
    }

    const verificationHandler = () => {
        if (OTP.length < otpLength) {
            setErrorOTP('Please Complete Input OTP')
        } else {
            setErrorOTP('')
            const body = {
                sessid_token,
                otp_code: Number(OTP)
            }
            postVerifyLogin(body).then(response => {
                localStorage.removeItem("sessid_token");
                localStorage.setItem('token', JSON.stringify(response.data))
                window.location.reload()
            }).catch(err => {
                switch (err.code) {
                    case '401-B001':
                        dispatch(setToolsAlertMessage('Invalid OTP, mohon masukkan OTP yang benar'))
                        break;
                    case '401-B003':
                    case '401-B004':
                    case '401-B006':
                        dispatch(setToolsModalVisibility({verification: false}))
                        dispatch(setToolsAlertMessage('Session OTP sudah habis, silahkan login ulang'))
                        break;
                }
                dispatch(setToolsCaptcha(''))
                recaptchaRef?.current?.reset()
            })
        }
    }

    useEffect(() => {
        setErrorOTP('')
        setOTP('')
    }, [verification])

    return (
        <>
            <ModalCardComponent
                visibility={verification}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <div className={"col justify-center w-80 h-auto p-5 bg-white rounded-xl"}>
                            <div className={"col space-y-6 justify-center items-center"}>
                                <p className={'text-sm font-semibold mb-4'}>Input OTP code from your email</p>
                                <InputCode cols={otpLength} value={OTP} onChange={(e) => setOTP(e)}/>
                                {errorOTP && <p className={"font-semibold text-red-600 italic"}>{errorOTP}</p>}
                                <button type={"button"} onClick={verificationHandler}
                                        className={"row justify-center px-16 py-2 bg-battleship-green hover:bg-monstera font-semibold text-white rounded-lg cursor-pointer"}>
                                    {isLoading ? <img src={LoadingSvg} className={"w-7 mx-auto"}/> : 'Submit'}
                                </button>
                            </div>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default Verification
