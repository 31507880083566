import {createSlice} from '@reduxjs/toolkit'
import {affiliationModel} from "../models/affiliation.model";

export const affiliationSlice = createSlice({
    name: 'affiliation',
    initialState: {
        affiliationDetail: {...affiliationModel},
        affiliationErrorMessage: {},
    },
    reducers: {
        setAffiliationDetail: (state, action) => {
            state.affiliationDetail = {...state.affiliationDetail, ...action.payload}
        },
        setAffiliationErrorMessage: (state, action) => {
            state.affiliationErrorMessage = action.payload
        }
    },
})

export const {setAffiliationDetail, setAffiliationErrorMessage} = affiliationSlice.actions
const affiliationReducer = affiliationSlice.reducer

export default affiliationReducer
