import {createSlice} from '@reduxjs/toolkit'
import {bannerModel, benefitModel, introModel, resultModel} from "../models/miraculous-refining-series.model";

export const miraculousRefiningSeriesSlice = createSlice({
    name: 'miraculousRefiningSeries',
    initialState: {
        bannerDetail: {...bannerModel},
        introDetail: {...introModel},
        benefitDetail: {...benefitModel},
        resultDetail: {...resultModel},
        miraculousRefiningSeriesErrorMessage: {},
    },
    reducers: {
        setMiraculousRefiningSeriesBannerDetail: (state, action) => {
            state.bannerDetail = {...state.bannerDetail, ...action.payload}
        },
        setMiraculousRefiningSeriesIntroDetail: (state, action) => {
            state.introDetail = {...state.introDetail, ...action.payload}
        },
        setMiraculousRefiningSeriesBenefitDetail: (state, action) => {
            state.benefitDetail = {...state.benefitDetail, ...action.payload}
        },
        setMiraculousRefiningSeriesResultDetail: (state, action) => {
            state.resultDetail = {...state.resultDetail, ...action.payload}
        },
        setMiraculousRefiningSeriesErrorMessage: (state, action) => {
            state.miraculousRefiningSeriesErrorMessage = action.payload
        }
    },
})

export const {
    setMiraculousRefiningSeriesBannerDetail,
    setMiraculousRefiningSeriesIntroDetail,
    setMiraculousRefiningSeriesBenefitDetail,
    setMiraculousRefiningSeriesResultDetail,
    setMiraculousRefiningSeriesErrorMessage
} = miraculousRefiningSeriesSlice.actions
const miraculousRefiningSeriesReducer = miraculousRefiningSeriesSlice.reducer

export default miraculousRefiningSeriesReducer