import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment/moment";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";
import React from "react";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";

const CategoryFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysCategory} = useSelector(state => state.categoryReducer)
    const {searchKey} = useSelector(state => state.toolsReducer)
    const {fetching: exportCategoryList, isLoading: loadingExport} = useFetch(API.EXPORT_CATEGORY_LIST)

    const exportDataHandler = () => {
        const body = {
            search: searchKey,
            category_ids: selectedRowKeysCategory
        }
        exportCategoryList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Category-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row space-x-5"}>
            <SearchComponent placeholder={"cari category..."}/>
            <div className={"row space-x-3"}>
                <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data category ?`}
                        countSelected={selectedRowKeysCategory.length}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
        </div>
    )
}

export default CategoryFilter
