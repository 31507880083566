import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import {setBirthdayGiftDetail} from "../../reducers/birthday-gift.reducer";
import SearchGiftItem from "./search-gift-item";

const ContentItem = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {birthdayGiftDetail, birthdayGiftDetailErrorMessage} = useSelector(state => state.birthdayGiftReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(birthdayGiftDetail?.items)
        copyItemList[index][key] = value
        dispatch(setBirthdayGiftDetail({items: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-5 gap-y-4"}>
            <SearchGiftItem item={fieldList[0]} onChangeSelect={onChangeInput}
                            data={birthdayGiftDetail?.items[index]?.gift ?? {}}
                            error={birthdayGiftDetailErrorMessage[`items[${index}]`] ?? {}}/>
            <div className={"-mt-2"}>
                <InputComponent item={fieldList[1]} onChangeNumber={onChangeInput}
                                data={birthdayGiftDetail?.items[index]?.[fieldList[1].key] === 0 ? '0' : birthdayGiftDetail?.items[index]?.[fieldList[1].key]}
                                error={birthdayGiftDetailErrorMessage[`items[${index}]`] ?? {}}/>
            </div>
        </div>
    )
}

export default ContentItem
