import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LevelList} from "./level-list";
import React from "react";
import {Link} from "react-router-dom";

const LevelAndBenefitPage = () => {
    const {data: response, isLoading} = useFetch(API.GET_LEVELS_LIST, {}, {isFetching: true, isWithTotalData: true})

    return (
        <div className={"col px-10"}>
            <div className={"row justify-end mt-5"}>
                <Link
                    to={`/loyalty-program/level-and-benefit/create-level-and-benefit`}
                    className={"text-white w-fit py-3 px-8 font-semibold rounded-lg bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera cursor-pointer"}>
                    <p>Create Levels</p>
                </Link>
            </div>
            <LevelList dataSource={response?.data ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default LevelAndBenefitPage
