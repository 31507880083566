import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMiraculousRefiningSeriesBenefitDetail} from "../../../reducers/miraculous-refining-series.reducer";

const ItemBenefit = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {benefitDetail, miraculousRefiningSeriesErrorMessage} = useSelector(state => state.miraculousRefiningSeriesReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(benefitDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMiraculousRefiningSeriesBenefitDetail({item_list: copyItemList}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                <InputComponent item={fieldList[0]} data={benefitDetail?.item_list?.[index]?.[fieldList[0].key]} error={miraculousRefiningSeriesErrorMessage} onChange={onChange}/>
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, i) => (
                    <InputComponent key={i} item={item} data={benefitDetail?.item_list?.[index]?.[item.key]} error={miraculousRefiningSeriesErrorMessage} onChangeInput={onChange} onChange={onChange}/>
                ))}
            </div>
        </div>
    )
}

export default ItemBenefit