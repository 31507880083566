import InputComponent from "../../components/input.component";
import TabToggleComponent from "../../components/tab-toggle.component";
import React, {useRef, useState} from "react";
import {FIELD_LIST_GENERATE_VOUCHER_DUPLICATOR} from "../../utils/input-field-list";
import {checkFieldValidate, randomCode} from "../../utils/general-variable";
import SearchSelectComponent from "../../components/search-select.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const GenerateVoucherPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const initialFieldList = FIELD_LIST_GENERATE_VOUCHER_DUPLICATOR
    const [fieldList, setFieldList] = useState(initialFieldList)
    const formRef = useRef()
    const fieldItemsCopy = [...fieldList]
    const {fetching: generateVoucher} = useFetch(API.GENERATE_VOUCHER, {}, {isFetching: false})

    const generateVoucherHandler = () => {
        const elements = formRef.current
        const isValid = checkFieldValidate(fieldItemsCopy.slice(0, 3), elements)
        if (!isValid) {
            setFieldList(fieldItemsCopy)
            elements['voucher_code'].value = ''
        } else {
            setFieldList(initialFieldList)
            const lengthCharacter = elements['character_amount'].value
            const totalVoucher = elements['total_voucher'].value
            const prefix = elements['prefix'].value
            elements['voucher_code'].value = randomCode(lengthCharacter, totalVoucher, prefix)
        }
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault()
        const elements = event.target
        const params = {
            new_code: elements.voucher_code.value.split(','),
            target_code: elements.select_voucher_duplicate.value
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements, params)
        if (isValid) {
            generateVoucher(params)
                .then(response => {
                    dispatch(setToolsModalVisibility({alert: true}))
                    dispatch(setToolsAlertType('success'))
                    dispatch(setToolsAlertMessage('Generate Voucher Berhasil'))
                    navigate('/voucher/voucher-list')
                })
        }
        setFieldList(fieldItemsCopy)
    }

    return (
        <div className={"col px-10 mt-4"}>
            <TabToggleComponent
                title={'Voucher Duplicator'}
                tooltipText={'Ini Voucher Duplicator'}
                content={
                    <form onSubmit={onSubmitHandler} className={"font-regular"} ref={formRef}>
                        <div className={"grid grid-cols-2 gap-x-16 items-start gap-y-4"}>
                            {fieldList.slice(0, 3).map((item, index) => (
                                <InputComponent key={index} item={item} error={{[item.key]: item.errorText}}/>
                            ))}
                            <div
                                className={`row h-full items-end ${!!fieldList[2].errorText ? 'items-center' : 'items-end'}`}>
                                <button type={"button"}
                                        className={"h-9 px-3 bg-battleship-green hover:bg-monstera font-semibold text-white rounded-lg"}
                                        onClick={generateVoucherHandler}>
                                    Generate Voucher Code
                                </button>
                            </div>
                            <div className={"col-span-2 space-y-3"}>
                                <div className={"col space-y-2"}>
                                    <div className={"row items-center space-x-4"}>
                                        <label className={"font-semibold text-monstera"}>Voucher Code</label>
                                    </div>
                                    <div
                                        className={`row items-center px-2 h-9 border border-placebo text-forged-steel rounded-lg`}>
                                        <input type={'text'} name={'voucher_code'} className={"border-0 outline-0 px-3 w-full placeholder:italic"}/>
                                    </div>
                                </div>
                                <div className={"col space-y-2"}>
                                    <div className={"row items-center space-x-4"}>
                                        <label className={"font-semibold text-monstera"}>Select Voucher (Duplicate)</label>
                                    </div>
                                    <SearchSelectComponent item={fieldList[4]}/>
                                </div>
                            </div>
                            <div className={"row items-end h-full"}>
                                <button type={"submit"}
                                        className={"w-60 h-9 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera font-semibold text-white rounded-lg"}>
                                    Duplicate Voucher
                                </button>
                            </div>
                        </div>
                    </form>
                }
            />
        </div>
    )
}

export default GenerateVoucherPage
