import {createSlice} from '@reduxjs/toolkit'
import {birthdayGiftModel} from "../models/birthday-gift.model";

export const birthdayGiftSlice = createSlice({
    name: 'birthdayGift',
    initialState: {
        birthdayGiftDetail: {...birthdayGiftModel},
        birthdayGiftSelectedId: [],
        birthdayGiftDetailErrorMessage: {}
    },
    reducers: {
        setBirthdayGiftDetail: (state, action) => {
            state.birthdayGiftDetail = {...state.birthdayGiftDetail, ...action.payload}
        },
        setBirthdayGiftSelectedId: (state, action) => {
            state.birthdayGiftSelectedId = action.payload
        },
        setBirthdayGiftErrorMessage: (state, action) => {
            state.birthdayGiftDetailErrorMessage = action.payload
        }
    },
})

export const {
    setBirthdayGiftDetail,
    setBirthdayGiftSelectedId,
    setBirthdayGiftErrorMessage
} = birthdayGiftSlice.actions
const birthdayGiftReducer = birthdayGiftSlice.reducer

export default birthdayGiftReducer
