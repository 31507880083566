import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setVoucherRestrictionDetail} from "../../reducers/voucher.reducer";

const RestrictionForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {voucherErrorMessage, voucherDetail} = useSelector(state => state.voucherReducer)
    const {exclude_product_ids, include_product_ids} = useSelector(state => state.voucherReducer.voucherDetail.restriction)

    const onChangeInput = (key, value) => {
        dispatch(setVoucherRestrictionDetail({[key]: value}))
    }

    const onChangeSelect = (key, value) => {
        let array = key === 'include_product_ids' ? include_product_ids : exclude_product_ids
        dispatch(setVoucherRestrictionDetail({[key]: array.concat(value)}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <div key={index} className={item.key === 'allowed_email' ? 'col-span-2' : ''}>
                    <InputComponent item={item} data={voucherDetail.restriction[item.key]} onChange={onChangeInput} onChangeProduct={onChangeSelect} error={voucherErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default RestrictionForm
