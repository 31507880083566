import TabToggleComponent from "../../components/tab-toggle.component";
import CardComparationComponent from "../../components/card-comparation.component";
import {ORDER_AND_COMPARATION} from "../../utils/constant";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React from "react";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabOrderAndComparation = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const {advanceFilter} = useSelector(state => state.analyticsOrderReducer)
    const {
        data: response,
        fetching: getOrderAndComparation,
        isLoading
    } = useFetch(API.ANALYTIC_ORDER_AND_COMPARATION)

    useEffectAfterMount(() => {
        const requestBody = {...{
            start_date: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
            end_date: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
        }, ...advanceFilter}
        getOrderAndComparation(requestBody)
    }, [dateRange, advanceFilter, reload])

    return (
        <TabToggleComponent
            title={'Order & Comparation'}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <div className={"grid grid-cols-2 grid-flow-row gap-6"}>
                        {ORDER_AND_COMPARATION.map((list, index) => (
                            <div key={index} className={"col border border-orochimaru"}>
                                <CardComparationComponent cardList={list} data={response?.data ?? {}}/>
                            </div>
                        ))}
                    </div>
            }
        />
    )
}

export default TabOrderAndComparation
