import React from "react";
import SearchComponent from "../../../components/search.component";
import ExportComponent from "../../../components/export.component";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {downloadFileExport} from "../../../utils/general-variable";
import moment from "moment";
import PageLimitComponent from "../../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../../utils/filter-list";
import {setToolsModalVisibility} from "../../../reducers/tools.reducer";

export const Filter = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {fetching: exportUserReviewList, isLoading: loadingExport} = useFetch({...API.EXPORT_USER_REVIEW_LIST,  ...{initPathVariable: id}})
    const {searchKey} = useSelector(state => state.toolsReducer)
    const {selectedRowKeysUserReview} = useSelector(state => state.userReducer)

    const exportDataHandler = () => {
        const body = {
            search: searchKey,
            review_ids: selectedRowKeysUserReview
        }
        exportUserReviewList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `User-Review-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row items-center space-x-5"}>
            <SearchComponent placeholder={'cari review...'}/>
            <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
            <div>
                <ExportComponent
                    wordingConfirmation={`Apakah anda yakin ingin melakukan export data review user ?`}
                    countSelected={selectedRowKeysUserReview.length}
                    exportDataHandler={exportDataHandler}
                    isLoading={loadingExport}/>
            </div>
        </div>
    )
}
