import SearchComponent from "../../components/search.component";
import {SORT_LIST_CUSTOMER} from "../../utils/sort-list";
import {CITIES, DATA_LIMIT_LIST} from "../../utils/filter-list";
import ExportComponent from "../../components/export.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCurrentPage, setToolsModalVisibility, setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import FilterCityComponent from "../../components/filter-city.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {formatDateToReadable, downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import React from "react";
import FilterComponent from "../../components/filter.component";

const CustomerFilter = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {fetching: exportCustomer, isLoading: loadingExport} = useFetch(API.EXPORT_CUSTOMER)
    const {data: response} = useFetch(API.CUSTOMER_GET_LEVELS_LIST, {}, {isFetching: true})

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const exportDataHandler = () => {
        const queryParams = {
            startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
            endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
        }
        exportCustomer(queryParams, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Customer-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    const filterListCities = [
        {
            title: 'Kota / Kabupaten',
            list: CITIES
        }
    ]

    const filterListLevel = [
        {
            title: 'levels',
            list: response?.data ?? []
        }
    ]

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"cari user..."}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_CUSTOMER}/>
                    <FilterCityComponent filterList={filterListCities} placeholder={"cari kota / kabupaten..."}
                                         labelName={'name'} keyName={'name'}/>
                    <FilterComponent filterList={filterListLevel} labelName={'name'} keyName={'id'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <DateRangePickerComponent slug={'analytics-customer'} onChange={getValueFromRangePickerSingle}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data analytics customer ?`}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerFilter
