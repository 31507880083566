import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_INPUT_BENEFIT, FIELD_LIST_INPUT_IMAGE_BENEFIT} from "../../../utils/input-field-list-static-page";
import {setWaste4ChangeBenefitDetail, setWaste4ChangeErrorMessage} from "../../../reducers/waste-4-change.reducer";
import InputField from "./input-field";
import React, {useEffect} from "react";
import InputImage from "./input-image";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {convertErrorMessage} from "../../../utils/general-variable";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const Benefit = () => {
    const dispatch = useDispatch()
    const {benefitDetail} = useSelector(state => state.waste4ChangeReducer)
    const {fetching: getBenefit} = useFetch(API.W4C_GET_BENEFIT)
    const {fetching: updateBenefit, isLoading} = useFetch(API.W4C_UPDATE_BENEFIT)
    let fieldListInput = FIELD_LIST_INPUT_BENEFIT
    let fieldInputImage = FIELD_LIST_INPUT_IMAGE_BENEFIT
    let showBenefit = {
        label: 'Benefit',
        key: 'show_benefit',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const toggleList = [
        {
            title: 'Input Field',
            content: <InputField fieldList={fieldListInput}/>
        }, {
            title: 'Input Image',
            content: <InputImage fieldList={fieldInputImage}/>
        }
    ]

    const onChange = (key, value) => {
        dispatch(setWaste4ChangeBenefitDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateBenefit(benefitDetail).then(response => {
            dispatch(setWaste4ChangeErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Benefit Updated'))
            dispatch(setWaste4ChangeBenefitDetail(response?.data))
        }).catch(err => {
            dispatch(setWaste4ChangeErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getBenefit().then(response => {
            dispatch(setWaste4ChangeBenefitDetail(response?.data))
        })

        return () => {
            dispatch(setWaste4ChangeErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={benefitDetail}
            switchToggleField={showBenefit}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Benefit