import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setWaste4ChangeDescriptionDetail} from "../../../reducers/waste-4-change.reducer";

const Section = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {descriptionDetail, waste4ChangeErrorMessage} = useSelector(state => state.waste4ChangeReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(descriptionDetail?.descriptions)
        copyItemList[index][key] = value
        dispatch(setWaste4ChangeDescriptionDetail({descriptions: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={descriptionDetail?.descriptions?.[index]?.[item.key]} error={waste4ChangeErrorMessage[`descriptions[${index}]`] ?? {}} onChange={onChangeInput} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default Section