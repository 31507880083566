import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_MIRACULOUS_REFINING_SERIES} from "../../utils/sidebar-section-list";
import Banner from "./banner";
import Benefit from "./benefit";
import Intro from "./intro";
import Result from "./result";

const MiraculousRefiningSeries = () => {
    const [activeSidebar, setActiveSidebar] = useState('banner')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        banner: <Banner/>,
        intro: <Intro/>,
        benefit: <Benefit/>,
        result: <Result/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'miraculous_refining_series', list: SECTION_MIRACULOUS_REFINING_SERIES}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default MiraculousRefiningSeries