import Slider from "./slider";
import {useState} from "react";
import Series from "./series";
import FlashSale from "./flash-sale";
import BestSeller from "./best-seller";
import Recommendation from "./recommendation";
import Testimonial from "./testimonial";
import TheCarousel from "./the-carousel";
import ShopByCategory from "./shop-by-category";
import Benefit from "./benefit";
import Sustainability from "./sustainability";
import Rubi from "./rubi";
import Csr from "./csr";
import Claim from "./claim";
import Seo from "./seo";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_HOMEPAGE_LIST} from "../../utils/sidebar-section-list";

const HomePage = () => {
    const [activeSidebar, setActiveSidebar] = useState('slider')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        slider: <Slider/>,
        series: <Series/>,
        flash_sale: <FlashSale />,
        best_seller: <BestSeller />,
        recommendation: <Recommendation/>,
        testimonial: <Testimonial/>,
        the_carousel: <TheCarousel/>,
        shop_by_category: <ShopByCategory/>,
        benefit: <Benefit/>,
        sustainability: <Sustainability/>,
        rubi: <Rubi/>,
        csr: <Csr/>,
        claim: <Claim/>,
        seo: <Seo/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'homepage', list: SECTION_HOMEPAGE_LIST}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default HomePage