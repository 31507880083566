import React, {useEffect, useState} from "react";
import {LoadingSvg} from "../../utils/image";
import {setProductActiveTab, setProductImportResult} from "../../reducers/product.reducer";
import {STEP_IMPORT_PRODUCT} from "../../utils/constant";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsIsAlertImportFailed} from "../../reducers/tools.reducer";
import ProgressBarComponent from "../../components/progress-bar.component";

const Import = () => {
    const dispatch = useDispatch()
    const {isAlertImportFailed} = useSelector(state => state.toolsReducer)
    const {importProductDetail, importResult} = useSelector(state => state.productReducer)
    const [percentage, setPercentage] = useState(0)
    const {fetching: importProduct} = useFetch(API.IMPORT_PRODUCT)

    useEffect(() => {
        let timeOut = null

        if (!isAlertImportFailed && percentage < 80) {
            timeOut = setTimeout(() => {
                setPercentage(percentage + 20)
            }, 1000)
        } else if (!!importResult.success) {
            timeOut = setTimeout(() => {
                percentage === 100 ? dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[3])) : setPercentage(percentage + 20)
            }, 1000)
        }

        return () => {
            clearTimeout(timeOut)
        }
    }, [percentage, importResult])

    useEffect(() => {
        importProduct(importProductDetail, null, {'Content-Type': 'multipart/form-data'}).then(response => {
            dispatch(setProductImportResult(response?.data))
        }).catch(() => {
            dispatch(setToolsIsAlertImportFailed(true))
        })
    }, [])

    return (
        <div className={"col space-y-8 py-6 border border-whisper-green rounded-lg"}>
            <div className={"col space-y-4 px-6"}>
                <p className={"text-lg text-monstera"}>Importing</p>
                <div className={"row items-center space-x-4"}>
                    <p>Your Product are now being imported...</p>
                    <img src={LoadingSvg} alt="LoadingIcon" className={"w-10"}/>
                </div>
            </div>
            <ProgressBarComponent percentage={percentage}/>
        </div>
    )
}

export default Import