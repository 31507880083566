import React, {useEffect} from "react";
import {Filter} from "./filter";
import {ReviewList} from "./review-list";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsInitialState} from "../../../reducers/tools.reducer";
import {setUserReviewSelectedRowKeys} from "../../../reducers/user.reducer";

const TabReview = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {currentPage, pageSize, searchKey} = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey,
    }
    const {data: response, fetching: getReviewList, isLoading} = useFetch({...API.USER_REVIEW_LIST, ...{initPathVariable: id}}, queryParams, {isWithTotalData: true})

    useEffect(() => {
        getReviewList()
    }, [currentPage, pageSize, searchKey, id])

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setUserReviewSelectedRowKeys([]))
        }
    }, [])

    return (
        <div className={"px-10 space-y-5"}>
            <Filter/>
            <ReviewList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default TabReview
