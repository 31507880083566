import TabToggleComponent from "../../components/tab-toggle.component";
import CardComparationComponent from "../../components/card-comparation.component";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React from "react";
import {PRODUCT_AND_COMPARATION} from "../../utils/constant";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabProductAndComparation = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const queryParams = {
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
    }
    const {
        data: response,
        fetching: getProductAndComparation,
        isLoading
    } = useFetch(API.ANALYTIC_PRODUCT_AND_COMPARATION, queryParams)

    useEffectAfterMount(() => {
        getProductAndComparation()
    }, [dateRange, reload])

    return (
        <TabToggleComponent
            title={'Product & Comparation'}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <div className={"grid grid-cols-2 grid-flow-row gap-6"}>
                        {PRODUCT_AND_COMPARATION.map((list, index) => (
                            <div key={index} className={"col border border-orochimaru"}>
                                <CardComparationComponent cardList={list} data={response?.data ?? {}}/>
                            </div>
                        ))}
                    </div>
            }
        />
    )
}

export default TabProductAndComparation