import {createSlice} from '@reduxjs/toolkit'
import {voucherModel} from "../models/voucher.model";

export const voucherSlice = createSlice({
    name: 'voucher',
    initialState: {
        voucherDetail: {...voucherModel},
        voucherErrorMessage: {},
        selectedRowKeysVoucher: [],
        voucherType: '',
        showVoucherAutoApply: false
    },
    reducers: {
        setVoucherDetail: (state, action) => {
            state.voucherDetail = {...state.voucherDetail, ...action.payload}
        },
        setVoucherRestrictionDetail: (state, action) => {
            state.voucherDetail.restriction = {...state.voucherDetail.restriction, ...action.payload}
        },
        setVoucherErrorMessage: (state, action) => {
            state.voucherErrorMessage = action.payload
        },
        setVoucherSelectedRowKeys: (state, action) => {
            state.selectedRowKeysVoucher = action.payload
        },
        setVoucherType: (state, action) => {
            state.voucherType = action.payload
        },
        setVoucherAutoApply: (state, action) => {
            state.showVoucherAutoApply = action.payload
        }
    },
})

export const {
    setVoucherSelectedRowKeys,
    setVoucherDetail,
    setVoucherErrorMessage,
    setVoucherRestrictionDetail,
    setVoucherType
} = voucherSlice.actions
const voucherReducer = voucherSlice.reducer

export default voucherReducer
