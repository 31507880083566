import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FIELD_LIST_INPUT_MEMBER_FLOW_LOYALTY_PROGRAM} from "../../utils/input-field-list-static-page";
import {setLoyaltyProgramErrorMessage, setLoyaltyProgramMemberFlowDetail} from "../../reducers/loyalty-program.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import React, {useEffect} from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const MemberFlow = () => {
    const dispatch = useDispatch()
    const {memberFlowDetail, loyaltyProgramErrorMessage} = useSelector(state => state.loyaltyProgramReducer)
    const {fetching: getMemberFlow} = useFetch(API.LOYALTY_PROGRAM_GET_MEMBER_FLOW)
    const {fetching: updateMemberFlow, isLoading} = useFetch(API.LOYALTY_PROGRAM_UPDATE_MEMBER_FLOW)
    let fieldListInput = FIELD_LIST_INPUT_MEMBER_FLOW_LOYALTY_PROGRAM
    let showMemberFlow = {
        label: 'Member Flow',
        key: 'show_member_flow',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setLoyaltyProgramMemberFlowDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateMemberFlow(memberFlowDetail).then(response => {
            dispatch(setLoyaltyProgramErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Member Flow Updated'))
            dispatch(setLoyaltyProgramMemberFlowDetail(response?.data))
        }).catch(err => {
            dispatch(setLoyaltyProgramErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getMemberFlow().then(response => {
            dispatch(setLoyaltyProgramMemberFlowDetail(response?.data))
        })

        return () => {
            dispatch(setLoyaltyProgramErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={memberFlowDetail}
            switchToggleField={showMemberFlow}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={loyaltyProgramErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default MemberFlow
