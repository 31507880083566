const SECTION_ABOUT_US = [
    {
        label: 'Intro',
        key: 'intro'
    }, {
        label: 'Video Section',
        key: 'video'
    }, {
        label: 'Section 1',
        key: 'section_1'
    }, {
        label: 'Section 2',
        key: 'section_2'
    }, {
        label: 'Articles',
        key: 'articles'
    }
]

const SECTION_MIRACULOUS_SERIES = [
    {
        label: 'Intro',
        key: 'intro'
    }, {
        label: 'Miraculous Item',
        key: 'item'
    }, {
        label: 'Related Product',
        key: 'related_product'
    }
]

const SECTION_MIRACULOUS_REFINING_SERIES = [
    {
        label: 'Banner',
        key: 'banner'
    }, {
        label: 'Intro',
        key: 'intro'
    }, {
        label: 'Benefit',
        key: 'benefit'
    }, {
        label: 'Result',
        key: 'result'
    }
]

const SECTION_MIRACULOUS_RETINOL_SERIES = [
    {
        label: 'Banner',
        key: 'banner'
    }, {
        label: 'Video',
        key: 'video'
    }, {
        label: 'Intro',
        key: 'intro'
    }, {
        label: 'Benefit',
        key: 'benefit'
    }, {
        label: 'Result',
        key: 'result'
    }
]

const SECTION_YOUR_SKIN_BAE_LIST = [
    {
        label: 'Personalize',
        key: 'personalize'
    }
]

const SECTION_WASTE_4_CHANGE_LIST = [
    {
        label: 'Banner',
        key: 'banner'
    }, {
        label: 'Description',
        key: 'description'
    }, {
        label: 'Benefit',
        key: 'benefit'
    }, {
        label: 'Redeem Product',
        key: 'redeem_product'
    }, {
        label: 'Mini Banner',
        key: 'mini_banner'
    }, {
        label: 'Gift Banner',
        key: 'gift_banner'
    }
]

const SECTION_BEAUTY_FRIDAY_LIST = [
    {
        label: 'Banner',
        key: 'banner'
    }, {
        label: 'Poster',
        key: 'poster'
    }, {
        label: 'Promoted Product',
        key: 'promoted_product'
    }, {
        label: 'Product Recommendation',
        key: 'product_recommendation'
    }, {
        label: 'Flash Sale',
        key: 'flash_sale'
    }
]

const SECTION_HOMEPAGE_LIST = [
    {
        label: 'Slider',
        key: 'slider'
    }, {
        label: 'Flash Sale',
        key: 'flash_sale'
    }, {
        label: 'Best Seller',
        key: 'best_seller'
    }, {
        label: 'Shop By Category',
        key: 'shop_by_category'
    }, {
        label: 'Series',
        key: 'series'
    }, {
        label: 'Recommendation',
        key: 'recommendation'
    }, {
        label: 'Benefit',
        key: 'benefit'
    }, {
        label: 'Sustainability',
        key: 'sustainability'
    }, {
        label: 'CSR',
        key: 'csr'
    }, {
        label: 'RUBI',
        key: 'rubi'
    }, {
        label: 'Testimonial',
        key: 'testimonial'
    }, {
        label: 'Claim',
        key: 'claim'
    }, {
        label: 'The Carousel',
        key: 'the_carousel'
    }, {
        label: 'SEO',
        key: 'seo'
    }
]

const SECTION_AVO_STORIES = [
    {
        label: 'General',
        key: 'general'
    }, {
        label: 'Recycling Progress',
        key: 'recycling_progress'
    }, {
        label: 'Campaign Banner',
        key: 'campaign_banner'
    }, {
        label: 'Time Line',
        key: 'time_line'
    }, {
        label: 'CTA',
        key: 'cta'
    }
]

const SECTION_AVO_STORIES_2 = [
    {
        label: 'Main Banner',
        key: 'main_banner'
    }, {
        label: 'Sustainability Progress',
        key: 'sustainability_progress'
    }, {
        label: 'Campaign Video',
        key: 'campaign_video'
    }, {
        label: 'Campaign Banner',
        key: 'campaign_banner'
    }, {
        label: 'Form',
        key: 'form'
    }, {
        label: 'Articles',
        key: 'articles'
    }
]

const SECTION_LOYALTY_PROGRAM = [
    {
        label: 'Main Banner',
        key: 'main_banner'
    }, {
        label: 'Member Flow',
        key: 'member_flow'
    }, {
        label: 'Benefit',
        key: 'benefit'
    }, {
        label: 'Leveling',
        key: 'leveling'
    }, {
        label: 'Join Member',
        key: 'join_member'
    }, {
        label: 'FAQ',
        key: 'faq'
    }, {
        label: 'SEO',
        key: 'seo'
    }
]

export {
    SECTION_HOMEPAGE_LIST,
    SECTION_WASTE_4_CHANGE_LIST,
    SECTION_BEAUTY_FRIDAY_LIST,
    SECTION_ABOUT_US,
    SECTION_MIRACULOUS_SERIES,
    SECTION_MIRACULOUS_REFINING_SERIES,
    SECTION_MIRACULOUS_RETINOL_SERIES,
    SECTION_YOUR_SKIN_BAE_LIST,
    SECTION_AVO_STORIES,
    SECTION_AVO_STORIES_2,
    SECTION_LOYALTY_PROGRAM
}
