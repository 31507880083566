import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setToolsReload} from "../reducers/tools.reducer";

export const useEffectAfterMount = (callback, dependencies) => {
    const dispatch = useDispatch()
    const {reload} = useSelector(state => state.toolsReducer)
    const mounted = useRef(false)

    useEffect(() => {
        if (mounted.current) {
            return callback()
        } else {
            dispatch(setToolsReload(!reload))
            mounted.current = true
        }
    }, dependencies)
}