import {useSelector} from "react-redux";
import React from "react";
import UploadMultipleImageBenefit from "./upload-multiple-image-benefit";

const InputImage = ({fieldList}) => {
    const {waste4ChangeErrorMessage} = useSelector(state => state.waste4ChangeReducer)

    return (
        <div className={"col space-y-2"}>
            <div className={"row items-center space-x-4"}>
                <label htmlFor={fieldList.key} className={"font-semibold text-monstera"}>{fieldList.label}</label>
            </div>
            <UploadMultipleImageBenefit itemKey={fieldList.key}/>
            {waste4ChangeErrorMessage[fieldList.key] && <p className={"font-semibold text-red-600 italic"}>{waste4ChangeErrorMessage[fieldList.key]}</p>}
        </div>
    )
}

export default InputImage