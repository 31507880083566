import {convertDateToCustomFormat, statusRenderClassName} from "../../utils/general-variable";
import ChangeStateComponent from "../../components/change-state.component";
import {STATE_LIST_ORDER} from "../../utils/state-list";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useParams} from "react-router-dom";
import {UserProfileDefault} from "../../utils/image";

const OrderDetailHeader = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {orderDetail} = useSelector(state => state.orderReducer)
    const {reload} = useSelector(state => state.toolsReducer)
    const {fetching: postUpdateStatus, isLoading} = useFetch({...API.ORDER_DETAIL_UPDATE_STATUS, ...{initPathVariable: id}})

    const applyStatusHandler = ([], status) => {
        const body = {
            status
        }
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Order State Updated'))
            dispatch(setToolsReload(!reload))
        })
    }

    return (
        <div className={"col divide-y divide-liberated-lime font-regular bg-doctor rounded-lg"}>
            <div className={"row items-center justify-between px-5 py-3"}>
                <div className={"row items-center space-x-4"}>
                    <p className={"font-semibold text-monstera"}>{orderDetail.invoice_number}</p>
                    <p className={`text-center font-Montserrat-Bold capitalize rounded-md py-1 px-8 ${statusRenderClassName(orderDetail.status).className}`}>{statusRenderClassName(orderDetail.status).text}</p>
                </div>
                <p className={"text-forged-steel"}>{convertDateToCustomFormat(orderDetail.created_at, 'YYYY/MM/DD HH:mm')}</p>
            </div>
            <div className={"row items-center justify-between px-5 py-3"}>
                <div className={"row items-center space-x-4"}>
                    <img src={orderDetail?.user?.image_url || UserProfileDefault} alt="imageUser" className={"w-14 h-14 rounded-full"}/>
                    <p className={"font-semibold text-eerie-black capitalize"}>{orderDetail?.user?.fullname}</p>
                </div>
                <div className={"col space-y-2 text-forged-steel"}>
                    <p>{orderDetail?.user?.email}</p>
                    <p>{orderDetail?.user?.phone}</p>
                </div>
                <div className={"col space-y-2 font-semibold text-eerie-black"}>
                    <p>Change Status</p>
                    <ChangeStateComponent
                        wordingConfirmation={"Apakah anda ingin mengubah status order ?"}
                        stateList={STATE_LIST_ORDER}
                        isLoading={isLoading}
                        applyStatusHandler={applyStatusHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderDetailHeader
