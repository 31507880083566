import TabToggleComponent from "../../components/tab-toggle.component";
import OrderList from "./order-list";
import React from "react";
import OrderFilter from "./order-filter";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabOrderList = () => {
    const {
        currentPage,
        pageSize,
        searchKey,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)
    const {advanceFilter} = useSelector(state => state.analyticsOrderReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey
    }
    const {
        data: response,
        fetching: getOrderDailyData,
        isLoading
    } = useFetch(API.ANALYTIC_ORDER_DAILY_DATA, {}, {isWithTotalData: true})

    useEffectAfterMount(() => {
        const requestBody = {...{
                start_date: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                end_date: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }, ...advanceFilter}
        getOrderDailyData(requestBody, '', {}, '', queryParams)
    }, [currentPage, pageSize, searchKey, dateRange, advanceFilter, reload])

    return (
        <TabToggleComponent
            title={'Order List'}
            content={
                <div className={"col space-y-4"}>
                    <OrderFilter/>
                    <OrderList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
                </div>
            }
        />
    )
}

export default TabOrderList
