import React, {useEffect} from "react";
import GeneralForm from "./general-form";
import {FIELD_LIST_THE_CAROUSEL_GENERAL} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setMainPageErrorMessage, setMainPageTheCarouselDetail} from "../../../reducers/main-page.reducer";
import {cloneDeep} from "lodash";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {convertErrorMessage} from "../../../utils/general-variable";
import FormGeneralComponent from "../../../components/static-page/form-general.component";

const TheCarousel = () => {
    const dispatch = useDispatch()
    const {theCarouselDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getTheCarousel} = useFetch(API.HOMEPAGE_GET_THE_CAROUSEL)
    const {fetching: updateTheCarousel, isLoading} = useFetch(API.HOMEPAGE_UPDATE_THE_CAROUSEL)
    let fieldListGeneral = FIELD_LIST_THE_CAROUSEL_GENERAL
    let showTheCarousel = {
        label: 'The Carousel',
        key: 'show_the_carousel',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'General',
            content: <GeneralForm fieldList={fieldListGeneral} />
        }
    ]

    const onChange = (key, value) => {
        dispatch(setMainPageTheCarouselDetail({[key]: value}))
    }

    const dataConstruct = (field, value) => {
        const theCarouselDetailCopy = cloneDeep(theCarouselDetail)
        let error = {}
        field.forEach((item) => {
            if (item.type === 'number' && Number(value[item.key]?.value) < 0) {
                error[item.key] = `${item.label} is lower than 0`
            } else if (item.type === 'number') {
                theCarouselDetailCopy[item.key] = value[item.key]?.value ? Number(value[item.key]?.value) : null
            } else {
                theCarouselDetailCopy[item.key] = value[item.key]?.value || null
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setMainPageErrorMessage({}))
            return {isValid: true, the_carousel: theCarouselDetailCopy}
        } else {
            dispatch(setMainPageErrorMessage(error))
            return {isValid: false, the_carousel: theCarouselDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let formValue = document.getElementById("form").elements
        const {isValid, the_carousel} = dataConstruct([...fieldListGeneral[0], ...fieldListGeneral[1]], formValue)
        if (isValid) {
            updateTheCarousel(the_carousel).then(response => {
                dispatch(setMainPageErrorMessage({}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('The Carousel Updated'))
                dispatch(setMainPageTheCarouselDetail(response?.data))
            }).catch(err => {
                dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    useEffect(() => {
        getTheCarousel().then(response => {
            dispatch(setMainPageTheCarouselDetail(response?.data))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={theCarouselDetail}
            switchToggleField={showTheCarousel}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default TheCarousel