import ModalCardComponent from "../../components/modal-card.component";
import React from "react";
import {setBeautyEmptiesModalVisibility} from "../../reducers/beauty-empties.reducer";
import {useDispatch, useSelector} from "react-redux";
import {CloseIcon, LinkIcon} from "../../utils/image";
import {Link} from "react-router-dom";

export const ModalSeeDetail = ({data}) => {
    const dispatch = useDispatch()
    const {isShowModalProof} = useSelector(state => state.beautyEmptiesReducer)
    const closeModalHandler = () => {
        dispatch(setBeautyEmptiesModalVisibility(false))
    }

    return (
        <ModalCardComponent
            visibility={isShowModalProof}
            onClose={closeModalHandler}
            content={
                <div className={"relative"}>
                    <div className={"col space-y-8 justify-center w-80 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-center text-md font-semibold text-forged-steel"}>Bukti Pengembalian</p>
                        <div className={"col space-y-5"}>
                            {data.map((item, index) => (
                                <Link key={index} to={item?.url} target={"_blank"} className={"row space-x-4"}>
                                    <img src={LinkIcon} alt="linkIcon" className={"w-5"}/>
                                    <p className={"text-dazzle hover:font-Montserrat-Bold hover:underline"}>Bukti Pengembalian {index + 1}</p>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModalHandler}/>
                </div>
            }
        />
    )
}
