import React, {useEffect} from "react";
import {FIELD_LIST_SERIES_GENERAL, FIELD_LIST_SERIES_ITEM} from "../../../utils/input-field-list-static-page";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import Item from "./item";
import {useDispatch, useSelector} from "react-redux";
import {
    setMainPageErrorMessage,
    setMainPageSeriesDetail
} from "../../../reducers/main-page.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Series = () => {
    const dispatch = useDispatch()
    const {seriesDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)
    const {fetching: getSeries} = useFetch(API.HOMEPAGE_GET_SERIES)
    const {fetching: updateSeries, isLoading} = useFetch(API.HOMEPAGE_UPDATE_SERIES)
    const itemList = [...seriesDetail.item_list]
    let fieldListGeneral = FIELD_LIST_SERIES_GENERAL
    let fieldListItem = FIELD_LIST_SERIES_ITEM
    let showSeries = {
        label: 'Series',
        key: 'show_series',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageSeriesDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'series').toString(),
            desktop_mini_banner: null,
            mobile_mini_banner: null,
            link_mini_banner: null
        })
        dispatch(setMainPageSeriesDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(seriesDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataSeries = {...seriesDetail, item_list: dataItemList}
        updateSeries(dataSeries).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Series Updated'))
            dispatch(setMainPageSeriesDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getSeries().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setMainPageSeriesDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setMainPageSeriesDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={seriesDetail}
            switchToggleField={showSeries}
            fieldListGeneral={fieldListGeneral}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldListItem} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageSeriesDetail}
            deleteList={seriesDetail.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={mainPageErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Series
