import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_CAMPAIGN_VIDEO_AVO_STORIES_2} from "../../utils/input-field-list-static-page";
import {setAvoStories2CampaignVideoDetail, setAvoStories2ErrorMessage} from "../../reducers/avo-stories-2.reducer";
import {useEffect} from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";

const CampaignVideo = () => {
    const dispatch = useDispatch()
    const {campaignVideoDetail, avoStories2ErrorMessage} = useSelector(state => state.avoStories2Reducer)
    const {fetching: getCampaignVideo} = useFetch(API.AVO_STORIES_2_GET_CAMPAIGN_VIDEO)
    const {fetching: updateCampaignVideo, isLoading} = useFetch(API.AVO_STORIES_2_UPDATE_CAMPAIGN_VIDEO)
    let fieldList = FIELD_LIST_CAMPAIGN_VIDEO_AVO_STORIES_2
    let showCampaignVideo = {
        label: 'Campaign Video',
        key: 'show_campaign_video',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStories2CampaignVideoDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateCampaignVideo(campaignVideoDetail).then(response => {
            dispatch(setAvoStories2ErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Campaign Video Updated'))
            dispatch(setAvoStories2CampaignVideoDetail(response?.data))
        }).catch(err => {
            dispatch(setAvoStories2ErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getCampaignVideo().then(response => {
            dispatch(setAvoStories2CampaignVideoDetail(response?.data))
        })

        return () => {
            dispatch(setAvoStories2ErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={campaignVideoDetail}
            switchToggleField={showCampaignVideo}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={avoStories2ErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default CampaignVideo
