import {useDispatch, useSelector} from "react-redux";
import React from "react";
import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {setBeautyEmptiesModalVisibility, setBeautyEmptiesSelectedRowKeys} from "../../reducers/beauty-empties.reducer";
import {Link} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {ModalSeeDetail} from "./modal-see-detail";
import {statusRenderClassName} from "../../utils/general-variable";

export const BeautyEmptiesList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysBeautyEmpties} = useSelector(state => state.beautyEmptiesReducer)
    const {data: response, fetching: getProofBeautyEmpties} = useFetch(API.GET_PROOF_BEAUTY_EMPTIES)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nama',
            classNameHeader: 'pl-5',
            width: 150,
            render: (record) => (
                <Link to={`/loyalty-program/beauty-empties/beauty-empties-detail/${record.id}`}>
                    <p className={"text-monstera capitalize hover:font-Montserrat-Bold hover:underline pl-5"}>{record.fullname}</p>
                </Link>
            )
        }, {
            name: 'Kode',
            dataIndex: 'code',
            classNameHeader: 'text-center',
            className: 'text-center text-forged-steel'
        }, {
            name: 'Product',
            dataIndex: 'product_name',
            classNameHeader: 'text-center w-80',
            render: (product_name) => (
                <div className={"line-clamp-1 w-80 text-center"}>
                    <p className={"truncate"}>{product_name}</p>
                </div>
            )
        }, {
            name: 'Total Qty',
            dataIndex: 'total_qty',
            classNameHeader: 'text-center',
            className: 'text-center text-forged-steel'
        }, {
            name: 'Bukti Pengembalian',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"text-dazzle hover:font-Montserrat-Bold hover:underline cursor-pointer"}
                     onClick={() => showModalProof(record?.id)}>
                    <p className={"text-center"}>See Detail</p>
                </div>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            classNameHeader: 'text-center',
            render: (status) => (
                <div className={"row justify-center"}>
                    <div
                        className={`w-32 py-1 px-2 font-Montserrat-Bold capitalize rounded-md ${statusRenderClassName(status).className}`}>
                        <p className={"text-center"}>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setBeautyEmptiesSelectedRowKeys(array))
    }

    const showModalProof = (id) => {
        getProofBeautyEmpties({}, id).then(() => {
            dispatch(setBeautyEmptiesModalVisibility(true))
        })
    }

    return (
        <>
            <div className={"col space-y-4"}>
                <TotalDataComponent subject={"Users"}/>
                <TableComponent
                    columns={columns}
                    tableClassName={"font-Regular"}
                    headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                    selectedRowKeys={selectedRowKeysBeautyEmpties}
                    onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                    dataSource={dataSource}
                    pagination={true}
                    loading={isLoading}
                />
            </div>
            <ModalSeeDetail data={response?.data ?? []}/>
        </>
    )
}
