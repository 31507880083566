import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {downloadFileExport} from "../../../utils/general-variable";
import moment from "moment/moment";
import {setToolsModalVisibility} from "../../../reducers/tools.reducer";
import SearchComponent from "../../../components/search.component";
import PageLimitComponent from "../../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../../utils/filter-list";
import ExportComponent from "../../../components/export.component";
import React from "react";

export const Filter = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {fetching: exportUserReferralList, isLoading: loadingExport} = useFetch({...API.EXPORT_USER_REFERRAL_LIST,  ...{initPathVariable: id}})
    const {searchKey} = useSelector(state => state.toolsReducer)
    const {selectedRowKeysUserReferral} = useSelector(state => state.userReducer)

    const exportDataHandler = () => {
        const body = {
            search: searchKey,
            user_ids: selectedRowKeysUserReferral
        }
        exportUserReferralList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `User-Referral-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row items-center space-x-5"}>
            <SearchComponent placeholder={'cari user...'}/>
            <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
            <div>
                <ExportComponent
                    wordingConfirmation={`Apakah anda yakin ingin melakukan export data referral user ?`}
                    countSelected={selectedRowKeysUserReferral.length}
                    exportDataHandler={exportDataHandler}
                    isLoading={loadingExport}/>
            </div>
        </div>
    )
}
