import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import GeneralForm from "./general-form";
import {setAvoStories2ArticlesDetail, setAvoStories2ErrorMessage} from "../../../reducers/avo-stories-2.reducer";
import FormGeneralComponent from "../../../components/static-page/form-general.component";
import {FIELD_LIST_ARTICLES_AVO_STORIES_2, FIELD_LIST_ARTICLES_GENERAL_AVO_STORIES_2} from "../../../utils/input-field-list-static-page";
import ArticlesForm from "./articles-form";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import {convertErrorMessage} from "../../../utils/general-variable";

const Articles = () => {
    const dispatch = useDispatch()
    const {articlesDetail} = useSelector(state => state.avoStories2Reducer)
    const {fetching: getArticles} = useFetch(API.AVO_STORIES_2_GET_ARTICLES)
    const {fetching: getArticlesPublished} = useFetch(API.ARTICLES_LIST)
    const {fetching: updateArticles, isLoading: loadingUpdateArticles} = useFetch(API.AVO_STORIES_2_UPDATE_ARTICLES)
    const {fetching: updateArticlesPublished, isLoading: loadingUpdateArticlesPublished} = useFetch(API.PUBLISH_ARTICLES)
    let fieldListGeneral = FIELD_LIST_ARTICLES_GENERAL_AVO_STORIES_2
    let fieldListArticles = FIELD_LIST_ARTICLES_AVO_STORIES_2
    let showArticles = {
        label: 'Articles',
        key: 'show_articles',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'General',
            content: <GeneralForm fieldList={fieldListGeneral}/>
        }, {
            title: 'Articles',
            content: <ArticlesForm fieldList={fieldListArticles}/>
        }
    ]

    const onChange = (key, value) => {
        dispatch(setAvoStories2ArticlesDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const {articles, ...dataArticles} = articlesDetail
        Promise.all([updateArticles(dataArticles), updateArticlesPublished({
            published_articles: articlesDetail?.articles.map(item => item?.id)
        })]).then(response => {
            const newDetail = {...response[0]?.data, ...{articles: response[1]?.data}}
            dispatch(setAvoStories2ErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Articles Updated'))
            dispatch(setAvoStories2ArticlesDetail(newDetail))
        }).catch(err => {
            dispatch(setAvoStories2ErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        Promise.all([getArticles(), getArticlesPublished({publish: 1})])
            .then(response => {
                const newDetail = {...response[0]?.data, ...{articles: response[1]?.data}}
                dispatch(setAvoStories2ArticlesDetail(newDetail))
            }).catch(err => {
            console.log(err)
        })

        return () => {
            dispatch(setAvoStories2ErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={articlesDetail}
            switchToggleField={showArticles}
            toggleList={toggleList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            isLoading={loadingUpdateArticles && loadingUpdateArticlesPublished}
        />
    )
}

export default Articles
