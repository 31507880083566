import React, {useEffect} from "react";
import TabToggleComponent from "../../components/tab-toggle.component";
import {CreateUserForm} from "./create-user-form";
import {AccessibilityForm} from "./accessibility-form";
import {ACCESSIBILITY_FORM, CREATE_USER_FORM_LIST} from "../../utils/input-field-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setUserErrorMessage, setUserInitialUserDetail, setUserProfileImage} from "../../reducers/user.reducer";
import _ from "lodash";
import {convertErrorMessage} from "../../utils/general-variable"

const CreateUserPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {userProfileImage, userDetail} = useSelector(state => state.userReducer)
    const {fetching: getRoleList} = useFetch(API.ROLE_LIST)
    const {fetching: postCreateUser, isLoading} = useFetch(API.USER_CREATE_USER)
    let userField = CREATE_USER_FORM_LIST
    let accessibility = ACCESSIBILITY_FORM
    const toggleList = [
        {
            title: 'create user profile',
            content: <CreateUserForm fieldList={userField}/>
        }, {
            title: 'accessibility',
            content: <AccessibilityForm fieldList={accessibility}/>
        }
    ]

    useEffect(() => {
        getRoleList().then(response => {
            accessibility[0].options = response?.data
        })
        return () => {
            dispatch(setUserErrorMessage({}))
            dispatch(setUserInitialUserDetail())
            dispatch(setUserProfileImage(null))
        }
    }, [])

    const dataChecking = (field, value) => {
        dispatch(setUserErrorMessage({}))
        const userDetailCopy = _.cloneDeep(userDetail)
        let error = {}
        field.forEach((item) => {
            if (item.required && !value[item.key]?.value) {
                error[item.key] = `${item.label} is required`
            } else if (item.key === 'password' && value[item.key]?.value.length < 8) {
                error[item.key] = `${item.label} must be 8 characters long`
            } else {
                userDetailCopy[item.key] = value[item.key]?.value || null
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setUserErrorMessage({}))
            return {isValid: true, user: userDetailCopy}
        } else {
            dispatch(setUserErrorMessage(error))
            return {isValid: false, user: userDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const field = [...userField, ...accessibility]
        let formValue = document.getElementById("form").elements
        const {isValid, user} = dataChecking(field, formValue)
        const birthdate = user.birthdate && user.birthdate.split('-')
        if (isValid) {
            let requestBody = {
                ...user,
                phone_number: !!user.phone_number ? `62${user.phone_number}` : null,
                date_of_birth: !!birthdate ? Number(birthdate[2]) : null,
                month_of_birth: !!birthdate ? Number(birthdate[1]) : null,
                year_of_birth: !!birthdate ? Number(birthdate[0]) : null,
                image_url: userProfileImage
            }
            postCreateUser(requestBody).then(() => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('User Created'))
                navigate('/user/user-list')
            }).catch(err => {
                dispatch(setUserErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    return (
        <form id={"form"} className={"px-10 space-y-5 my-5"}>
            {
                toggleList.map((item, index) =>
                    <TabToggleComponent
                        key={index}
                        title={item.title}
                        content={item.content}
                    />
                )
            }
            <div className={"row justify-center"}>
                <button
                    className={"px-20 py-2 rounded bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-semibold"}
                    onClick={(e) => onSubmitHandler(e)}>
                    {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Create User'}
                </button>
            </div>
        </form>
    )
}

export default CreateUserPage
