import {CloseIcon, FilterIcon, FilterWhiteIcon} from "../utils/image";
import {getStarRatingImage} from "../utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCurrentPage,
    setToolsFilter,
    setToolsModalVisibility
} from "../reducers/tools.reducer";
import ModalCardComponent from "./modal-card.component";
import React, {useEffect, useState} from "react";

const FilterComponent = (props) => {
    const {filterList, labelName, keyName} = props
    const dispatch = useDispatch()
    const {filter, modalVisibility} = useSelector(state => state.toolsReducer)
    const [checkList, setCheckList] = useState([])
    const filterLength = Object.keys(filter).length

    const showModalHandler = () => {
        dispatch(setToolsModalVisibility({filter: true}))
    }

    const closeModalHandler = () => {
        dispatch(setToolsModalVisibility({filter: false}))
    }

    const getFilterCount = () => {
        let count = 0
        Object.keys(filter).map(i => {
            count += filter[i].length
        })
        return count
    }

    const checkedHandler = (event) => {
        const {name, id, checked} = event.target
        if (!checked) {
            setCheckList(checkList.filter(item => item.selected !== id))
        } else {
            setCheckList([...checkList, ...[{title: name, selected: id}]])
        }
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const result = checkList.reduce((data, {title, selected}) => {
            data[title] = data[title] || []
            data[title].push(selected)
            return data
        }, {})
        dispatch(setToolsFilter(result))
        dispatch(setToolsCurrentPage(1))
        closeModalHandler()
    }

    useEffect(() => {
        const filterSelected = []
        Object.keys(filter).map(filName => {
            return filter[filName].map((item) => {
                filterSelected.push({title: filName, selected: item})
            })
        })
        setCheckList(filterSelected)
    }, [modalVisibility.filter])

    return (
        <div className={"font-regular"}>
            <div onClick={showModalHandler}
                 className={`row items-center space-x-2 px-3 py-2 font-semibold rounded-lg border border-placebo cursor-pointer ${filterLength > 0 && 'bg-battleship-green text-white'} relative`}>
                <img className={"h-5"} src={filterLength > 0 ? FilterWhiteIcon : FilterIcon} alt="filter"/>
                <p>Filter</p>
                <div className={`absolute -right-1 -top-3.5 py-1 px-2.5 bg-monstera rounded-full ${filterLength > 0 ? 'block' : 'hidden'}`}>
                    <span>{getFilterCount()}</span>
                </div>
            </div>
            <ModalCardComponent
                visibility={modalVisibility.filter}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <form onSubmit={onSubmitHandler}>
                            <div className={"col space-y-8 w-[300px] pr-5 py-5 bg-white rounded-xl"}>
                                <p className={"text-center font-semibold pl-8"}>Filter</p>
                                <div className={"col space-y-4 max-h-36 overflow-scroll pr-10"}>
                                    {filterList?.map((filter, index) => (
                                        <div key={index} className={"mb-2"}>
                                            <p className={"text-monstera capitalize font-semibold pl-5 pb-2"}>{filter.title}</p>
                                            {filter.list.map((list, index) =>
                                                !!list &&
                                                <div key={index} className={"row space-x-5 pl-8 py-3 w-full"}>
                                                    <input type="checkbox" value={list[keyName]}
                                                           className={`accent-monstera ${list[keyName]}`}
                                                           name={filter.title} id={list[keyName]}
                                                           onChange={checkedHandler}
                                                           checked={checkList.map(i => i.selected).includes(list[keyName].toString())}
                                                    />
                                                    <label htmlFor={list[keyName]}
                                                           className={"row space-x-1 justify-center text-forged-steel font-regular capitalize"}>
                                                        {filter.title === 'rating' ? getStarRatingImage(list[keyName]).map((starImage, index) => (
                                                            <img key={index} src={starImage}
                                                                 alt={list[labelName]} className={"w-3"}/>
                                                        )) : list[labelName]}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={"row justify-between items-center pl-8"}>
                                    <div
                                        className={"row justify-center px-8 py-2 border border-placebo rounded-lg hover:text-white hover:bg-dazzle cursor-pointer"}
                                        onClick={closeModalHandler}>
                                        <p>Cancel</p>
                                    </div>
                                    <button type={"submit"}
                                            className={"row justify-center px-8 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg cursor-pointer"}>
                                        <p>Apply</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={"w-5 absolute right-4 top-2 cursor-pointer"}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </div>
    )
}

export default FilterComponent
