import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {ThumbsUpIcon, ThumbsDownIcon, UserProfileDefault, ThreeDotsIcon} from "../../utils/image";
import {convertDateToCustomFormat, getStarRatingImage} from "../../utils/general-variable";
import {setReviewData, setReviewSelectedRowKeys} from "../../reducers/review.reducer";
import {setCommentModalVisibility} from "../../reducers/comment.reducer";
import React from "react";

const ReviewDetailList = ({isLoading}) => {
    const dispatch = useDispatch()
    const {reviewList, selectedRowKeysReview} = useSelector(state => state.reviewReducer)

    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nama',
            classNameHeader: 'pl-5',
            render: (record) => (
                <div className={"row items-center space-x-3 pl-5"}>
                    <img src={record.image_url || UserProfileDefault} className={"w-10 h-10 rounded-lg"} alt={"imageUser"}/>
                    <div className={"col space-y-1 font-regular"}>
                        <p className={"text-monstera capitalize"}>{record.fullname}</p>
                        <p className={"text-forged-steel"}>{record.email}</p>
                    </div>
                </div>
            )
        }, {
            name: 'Rating',
            dataIndex: 'rate',
            classNameHeader: 'text-center',
            render: (rate) => (
                <div className={"row space-x-1 justify-center"}>
                    {getStarRatingImage(rate).map((starImage, index) => (
                        <img key={index} src={starImage}
                             alt="starRating" className={"w-3"}/>
                    ))}
                </div>
            )
        }, {
            name: 'Comment',
            width: 250,
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row items-center justify-center space-x-3 p-4 text-forged-steel"}>
                    <div className={"line-clamp-3 w-5/6"}>
                        <div dangerouslySetInnerHTML={{__html: record.review}} />
                    </div>
                    <div className={"w-fit rounded-full bg-battleship-green hover:bg-monstera"}>
                        <img src={ThreeDotsIcon} alt="buttonCommentDetail"
                             className={"w-5 cursor-pointer"} onClick={() => showCommentDetail(record)}/>
                    </div>
                </div>
            )
        }, {
            name: 'Like / Dislike',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"col space-y-2"}>
                    <div className={"row justify-center"}>
                        <div className={"row space-x-2 w-11 justify-between"}>
                            <div className={"w-1/2"}>
                                <img src={ThumbsUpIcon} alt="thumbsUp" className={"w-4"}/>
                            </div>
                            <div className={"w-1/2 text-start"}>
                                <p className={"text-forged-steel"}>{record.total_like}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"row justify-center"}>
                        <div className={"row space-x-2 w-11 justify-between"}>
                            <div className={"w-1/2"}>
                                <img src={ThumbsDownIcon} alt="thumbsDown" className={"w-4"}/>
                            </div>
                            <div className={"w-1/2 text-star"}>
                                <p>{record.total_dislike}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }, {
            name: 'Created At',
            dataIndex: 'created_at',
            classNameHeader: 'text-center pl-3',
            render: (created_at) => (
                <div className={"text-center text-forged-steel pl-3"}>
                    <p>{convertDateToCustomFormat(created_at, 'YYYY-MM-DD HH:mm')}</p>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setReviewSelectedRowKeys(array))
    }

    const showCommentDetail = (data) => {
        dispatch(setReviewData(data))
        dispatch(setCommentModalVisibility({commentDetail: true}))
    }

    return (
        <div className={"col space-y-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysReview}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={reviewList}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default ReviewDetailList
