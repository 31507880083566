export const advanceFilterOrderListModel = {
    startDate: null,
    endDate: null,
    productId: [],
    voucherId: [],
    affiliatorId: [],
    status: [],
    paymentMethod: [],
    shippingMethod: []
}

export const advanceFilterAnalyticsOrderModel = {
    voucher_codes: [],
    payment_methods: [],
    shipping_methods: []
}
