import {CloseIcon, FilterIcon, FilterWhiteIcon, MinusIcon} from "../../utils/image";
import React, {useEffect, useState} from "react";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "../../components/modal-card.component";
import InputComponent from "../../components/input.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {ADVANCE_FILTER_ORDER_LIST} from "../../utils/input-field-list";
import {advanceFilterOrderListModel} from "../../models/advance-filter";
import {TAB_LIST_ORDER} from "../../utils/constant";
import {cloneDeep} from "lodash";
import {setOrderAdvanceFilter, setOrderErrorAdvanceFilter} from "../../reducers/order.reducer";

const AdvanceFilter = () => {
    const dispatch = useDispatch()
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const {advanceFilter, errorAdvanceFilter} = useSelector(state => state.orderReducer)
    const {fetching: getShippingMethod} = useFetch(API.SHIPPING_METHOD)
    const {fetching: getPaymentMethod} = useFetch(API.PAYMENT_METHOD, {withoutGrouping: true})
    const fieldList = ADVANCE_FILTER_ORDER_LIST
    const [data, setData] = useState(advanceFilterOrderListModel)
    const [prevData, setPrevData] = useState(advanceFilterOrderListModel)

    useEffect(() => {
        Promise.all([getPaymentMethod(), getShippingMethod()])
            .then(response => {
                fieldList[4].dataList = TAB_LIST_ORDER.slice(1)
                fieldList[5][0].dataList = response[0].data
                fieldList[5][1].dataList = response[1].data
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const onChange = (key, dataFilter) => {
        setData({...data, ...{[key]: dataFilter}})
    }

    const getAPIMultipleSelect = (key) => {
        switch (key) {
            case 'productId':
                return API.ORDER_PRODUCT_LIST
            case 'voucherId':
                return API.ORDER_VOUCHER_LIST
            default:
                return API.GET_AFFILIATOR_LIST
        }
    }

    const showModalHandler = () => {
        dispatch(setToolsModalVisibility({filter: true}))
    }

    const closeModalHandler = () => {
        dispatch(setToolsModalVisibility({filter: false}))
    }

    const filteredCountCheck = () => {
        let count = 0
        Object.keys(advanceFilter).map(item => {
            if (item === 'startDate') {
                count += !!advanceFilter[item] ? 1 : 0
            } else if (item !== 'endDate') {
                count += advanceFilter[item]?.length
            }
        })
        return count
    }

    const dataChecking = (field, value) => {
        dispatch(setOrderErrorAdvanceFilter({}))
        const filterDetailCopy = cloneDeep(data)
        let error = {}
        let dates = []
        field.forEach((item) => {
            switch (item.key) {
                case 'startDate':
                case 'endDate':
                    if(value[item.key]?.value) {
                        filterDetailCopy[item.key] = value[item.key]?.value
                        dates.push({key: item.key, value: value[item.key]?.value})
                    } else {
                        filterDetailCopy[item.key] = null
                    }
                    break
                default:
                    return false
            }
        })

        if (dates.length === 1) {
            const errorMessage = 'Date Must be Selected'
            dates.map(item => {
                if (item.key === 'startDate') {
                    error['endDate'] = errorMessage
                } else {
                    error['startDate'] = errorMessage
                }
            })
        }

        if (Object.keys(error).length === 0) {
            dispatch(setOrderErrorAdvanceFilter({}))
            return {isValid: true, filter: filterDetailCopy}
        } else {
            dispatch(setOrderErrorAdvanceFilter(error))
            return {isValid: false, filter: filterDetailCopy}
        }
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = fieldList[0]
        let formValue = document.getElementById("form").elements
        if (buttonType === 'submit') {
            const {isValid, filter} = dataChecking(field, formValue, buttonType)
            if (isValid) {
                setPrevData(filter)
                const mapData = {
                    ...filter,
                    productId: filter.productId.map(val => val.id),
                    voucherId: filter.voucherId.map(val => val.id)
                }
                dispatch(setOrderAdvanceFilter(mapData))
                dispatch(setToolsModalVisibility({filter: false}))
            }
        } else {
            setData(advanceFilterOrderListModel)
        }
    }

    useEffect(() => {
        if (advanceFilter.status.length === 1 || advanceFilter.affiliatorId.length === 1) {
            setData({
                ...prevData, ...{
                    status: advanceFilter.status,
                    affiliatorId: advanceFilter.affiliatorId
                },
            })
        } else {
            setData(prevData)
        }
        dispatch(setOrderErrorAdvanceFilter({}))
    }, [modalVisibility.filter])

    return (
        <>
            <div onClick={showModalHandler}
                 className={`row items-center space-x-2 px-3 py-2 font-semibold rounded-lg border border-placebo cursor-pointer ${filteredCountCheck() > 0 && 'bg-battleship-green text-white'} relative`}>
                <img className={"h-5"} src={filteredCountCheck() > 0 ? FilterWhiteIcon : FilterIcon} alt="filter"/>
                <p>Filter</p>
                <div className={`absolute -right-1 -top-3.5 py-1 px-2.5 bg-monstera rounded-full ${filteredCountCheck() > 0 ? 'block' : 'hidden'}`}>
                    <span>{filteredCountCheck()}</span>
                </div>
            </div>
            <ModalCardComponent
                visibility={modalVisibility.filter}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <form id={"form"} onSubmit={onSubmitHandler}>
                            <div
                                className={"col space-y-8 w-[1000px] h-[630px] px-5 pt-5 py-5 bg-white overflow-y-scroll rounded-xl"}>
                                <p className={"text-center text-lg text-monstera font-semibold"}>Filter Order</p>
                                <div className={"row space-x-8"}>
                                    <div className={"col space-y-4 w-7/12"}>
                                        <div className={"row justify-between space-x-2"}>
                                            <div className={"w-1/2"}>
                                                <InputComponent item={fieldList[0][0]} data={data[fieldList[0][0].key]}
                                                                error={!data?.startDate && errorAdvanceFilter} onChangeDate={onChange}
                                                                dateRange={{startDate: data?.startDate, endDate: data?.endDate}}/>
                                            </div>
                                            <div className={"col pt-9"}>
                                                <img src={MinusIcon} alt="minus" className={"w-3"}/>
                                            </div>
                                            <div className={"w-1/2"}>
                                                <InputComponent item={fieldList[0][1]} data={data[fieldList[0][1].key]}
                                                                error={!data?.endDate && errorAdvanceFilter} onChangeDate={onChange}
                                                                dateRange={{startDate: data?.startDate, endDate: data?.endDate}}/>
                                            </div>
                                        </div>
                                        {fieldList.slice(1, 5).map((item, i) => (
                                            <InputComponent key={i} item={item} data={data[item.key]} api={getAPIMultipleSelect(item.key)}
                                                            onChange={onChange} error={errorAdvanceFilter}/>
                                        ))}
                                    </div>
                                    <div className={"col space-y-4 w-5/12"}>
                                        {fieldList[5].map((item, i) => (
                                            <InputComponent key={i} item={item} data={data[item.key]}
                                                                   onChange={onChange}/>
                                        ))}
                                    </div>
                                </div>
                                <div
                                    className={"row font-semibold justify-center space-x-4"}>
                                    <button type={"button"} onClick={(e) => onSubmitHandler('clear', e)}
                                            className={"w-40 py-2 border border-fortress-grey hover:text-white hover:bg-dazzle rounded-lg"}>
                                        Clear Filter
                                    </button>
                                    <button type={"button"} onClick={(e) => onSubmitHandler('submit', e)}
                                            className={"w-40 py-2 bg-battleship-green hover:bg-monstera text-white rounded-lg"}>
                                        Filter Order
                                    </button>
                                </div>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={"w-5 absolute right-4 top-2 cursor-pointer"}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default AdvanceFilter
