import {useDispatch, useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React, {useEffect} from "react";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";
import AvoStoriesUserList from "./avo-stories-user-list";
import {setAvoStoriesUserSelectedRowKeys} from "../../reducers/avo-stories-user.reducer";
import AvoStoriesUserFilter from "./avo-stories-user-filter";

const AvoStoriesUser = () => {
    const dispatch = useDispatch()
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: 'id',
        orderType: sort,
        search: searchKey
    }
    const bodyRequest = {
        startDate: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
        endDate: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
    }
    const {
        data: response,
        fetching: getAvoStoriesUser,
        isLoading
    } = useFetch(API.AVO_STORIES_USER_LIST, bodyRequest, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setAvoStoriesUserSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getAvoStoriesUser({}, '', {}, '', queryParams)
    }, [currentPage, pageSize, orderBy, sort, searchKey, dateRange, reload])

    return (
        <div className={"col px-10"}>
            <AvoStoriesUserFilter/>
            <AvoStoriesUserList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default AvoStoriesUser
