import {LoadingSvg, TrashIcon, UploadIcon, XCircleIcon} from "../utils/image"
import React, {useEffect, useRef, useState} from "react";
import {useFetch} from "../services/useFetch";
import {API} from "../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility
} from "../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import {imageValidate} from "../utils/general-variable"

const getUrlAPI = (slug) => {
    switch (slug) {
        case 'voucher_thumbnail':
            return API.VOUCHER_UPLOAD_THUMBNAIL
        case 'gift_image':
            return API.GIFT_UPLOAD_IMAGE
        case 'page':
            return API.PAGE_UPLOAD_IMAGE
        case 'notification':
            return API.NOTIFICATION_UPLOAD_IMAGE
        default:
            return API.PRODUCT_UPLOAD_PRODUCT_PICTURE
    }
}

const UploadImageComponent = (props) => {
    const {item, onChangeUploadImage, clearImage, dataImage} = props
    const dispatch = useDispatch()
    const [imageUrl, setImageUrl] = useState(null)
    const randomId = (Math.random() + 1).toString(36).substring(7)
    const imageUploaded = useRef()
    const {fetching: uploadPicture, isLoading} = useFetch(getUrlAPI(item.slug))

    useEffect(() => {
        if (!!dataImage) {
            setImageUrl(dataImage)
        } else {
            setImageUrl(null)
        }
    }, [dataImage, clearImage])

    const uploadImageHandler = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        const {isValid, message} = imageValidate(file)

        if (file) reader.readAsDataURL(file)

        if (isValid) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    uploadPicture({image: file}, null, {'Content-Type': 'multipart/form-data'}).then(response => {
                        const url = response?.data?.url
                        setImageUrl(url)
                        !!onChangeUploadImage && onChangeUploadImage(item.key, url, 'upload')
                    })
                    imageUploaded.current.value = null
                }
            }
        } else {
            showAlertModal(message)
        }
    }

    const removeImageHandler = () => {
        setImageUrl(null)
        !!onChangeUploadImage && onChangeUploadImage(item.key, null, 'remove')
        imageUploaded.current.value = null
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }


    return (
        <div className={`${item?.showAbove ? 'col items-center' : 'row items-center'} w-fit relative`}>
            <p className={'my-1 italic text-forged-steel'}>{item?.title}</p>
            <label htmlFor={randomId}
                   className={"font-semibold bg-battleship-green hover:bg-monstera py-1.5 px-3 rounded-lg text-white cursor-pointer w-fit"}>
                <div className={"row items-center space-x-2"}>
                    <img src={UploadIcon} alt="uploadIcon" className={"w-5"}/>
                    <p>Upload</p>
                </div>
            </label>
            <input name={item.key} id={randomId} type={"file"} className={'absolute top-1 invisible'}
                   ref={imageUploaded}
                   onChange={uploadImageHandler}/>
            {
                isLoading ? <img src={LoadingSvg} className={`w-12 mx-auto ${item?.showAbove && 'order-first'}`}/> :
                    <div className={`row space-x-4 ${item?.showAbove && 'order-first'}`}>
                        {imageUrl && <img src={imageUrl} alt="imageUploaded"
                                          className={`${item?.showAbove ? 'w-56 border border-placebo rounded-md' : 'w-14 ml-4'}`}/>}
                        {imageUrl && <img src={item?.showAbove ? TrashIcon : XCircleIcon} alt="deleteImage"
                                          className={`${item?.showAbove ? 'w-7 absolute right-2 top-2 border border-placebo rounded-full p-1 bg-white' : 'w-5'} cursor-pointer`}
                                          onClick={removeImageHandler}/>}
                    </div>
            }
        </div>
    )
}

export default UploadImageComponent
