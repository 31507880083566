import {useEffect, useState} from "react";

const SwitchToggleComponent = (props) => {
    const { item, defaultValue, onChangeSwitchToggle } = props
    const [runAnimate, setRunAnimate] = useState(defaultValue)
    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(defaultValue)
        setRunAnimate(defaultValue)
    }, [defaultValue])

    const moveAnimated = () => {
        setRunAnimate(true)
        !!onChangeSwitchToggle && onChangeSwitchToggle(item.key, !active)
        setActive(!active)
    }

    return (
        <div className={"row items-center space-x-3"}>
            <div className={`px-1 py-0.5 w-12 rounded-full cursor-pointer ${active ? 'bg-liberated-lime' : 'bg-placebo'} ${item?.positionText === 'left' ? 'order-2 ml-4' : ''}`} onClick={moveAnimated}>
                <div className={`w-fit p-2.5 rounded-full ${active ? 'bg-monstera' : 'bg-forged-steel'} ${runAnimate && (active ? 'animate-moveRight' : 'animate-moveLeft')}`}/>
            </div>
            <p className={`font-Montserrat-Bold ${active ? 'text-monstera' : 'text-forged-steel'}`}>{active ? item?.activeText : item?.inActiveText}</p>
            <input type="hidden" name={item?.key}/>
        </div>
    )
}

export default SwitchToggleComponent
