import {ArrowDownIcon, LoadingSvg} from "../utils/image"
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {setToolsAlertMessage, setToolsModalVisibility} from "../reducers/tools.reducer";
import DeleteConfirmationComponent from "./delete-confirmation.component";
import AlertConfirmationComponent from "./alert-confirmation.component";
import {useClickOutside} from "../services/useClickOutside";

const ChangeStateComponent = (props) => {
    const dispatch = useDispatch()
    const {wordingConfirmation, wordingDeleteConfirmation, selectedRow = [], stateList, isLoading, applyStatusHandler} = props
    const [stateSelected, setStateSelected] = useState(stateList[0])
    const [isShowModal, setIsShowModal] = useState(false)
    const {id} = useParams()

    const showHideModal = () => {
        setIsShowModal(!isShowModal)
    }

    const onChangeState = (stateItem) => {
        setStateSelected(stateItem)
        showHideModal()
    }

    const submitHandler = () => {
        if(stateSelected.key === 'delete'){
            dispatch(setToolsAlertMessage(wordingDeleteConfirmation))
            dispatch(setToolsModalVisibility({delete: true}))
        } else {
            dispatch(setToolsAlertMessage(wordingConfirmation))
            dispatch(setToolsModalVisibility({confirmation: true}))
        }
    }

    const applyState = () => {
        if(stateSelected.key === 'delete'){
            dispatch(setToolsModalVisibility({delete: false}))
        } else {
            dispatch(setToolsModalVisibility({confirmation: false}))
        }
        !!applyStatusHandler && applyStatusHandler(selectedRow, stateSelected.key)
    }

    useClickOutside('change-state-component', isShowModal, setIsShowModal)

    return (
        <div id={"change-state-component"} className={"row items-center relative font-regular"}>
            <div className={"row items-center space-x-4"}>
                <div onClick={showHideModal}
                     className={"row space-x-1 items-center justify-between w-32 px-3 py-2 font-semibold rounded-lg border border-placebo bg-white cursor-pointer"}>
                    <p className={"truncate"}>{stateSelected.label}</p>
                    <img src={ArrowDownIcon} alt="down" className={"w-2.5 h-5"}/>
                </div>
                <div className={`row justify-center px-5 py-2.5 text-white font-semibold rounded-lg ${(selectedRow.length > 0 || !!id) ? 'bg-battleship-green hover:bg-monstera cursor-pointer' : 'bg-fortress-grey cursor-not-allowed'}`}
                     onClick={((selectedRow.length > 0 && !isLoading) || !!id) ? submitHandler : undefined}>
                     {isLoading ? <img src={LoadingSvg} className={"w-4 mx-auto"}/> : <p>Apply</p>}
                </div>
            </div>
            <div className={isShowModal ? 'block' : 'hidden'}>
                <div
                    className={"col absolute left-0 top-12 w-32 z-10 bg-white shadow-md rounded-md max-h-32 overflow-scroll"}>
                    {stateList.map((list, index) => (
                        <div key={index}
                             className={"row justify-center p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => onChangeState(list)}>
                            <p>{list.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            {(selectedRow.length > 0 || !!id) &&
                <>
                    <DeleteConfirmationComponent onDelete={applyState}/>
                    <AlertConfirmationComponent onApply={applyState}/>
                </>
            }
        </div>
    )
}

export default ChangeStateComponent
