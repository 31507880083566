import React, {useEffect} from "react";
import TotalDataComponent from "../../../components/total-data.component";
import {Waitlist} from "./waitlist";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsInitialState} from "../../../reducers/tools.reducer";

const TabWaitList = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {currentPage, pageSize} = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
    }
    const {data: response, fetching: getWaitlist, isLoading} = useFetch({...API.USER_WAITLIST, ...{initPathVariable: id}}, queryParams, {isWithTotalData: true})

    useEffect(() => {
        getWaitlist()
    }, [currentPage, id])

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
        }
    }, [])

    return (
        <div className={"px-10 space-y-5"}>
            <TotalDataComponent subject={"waitlist"}/>
            <Waitlist dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default TabWaitList
