export const voucherModel = {
    title: '',
    type: '',
    code: '',
    discount_type: 'nominal',
    amount: 0,
    max_amount: 0,
    date_start: '',
    date_expires: '',
    description: '',
    thumbnail: '',
    terms_and_conditions: '',
    combine_validation: false,
    is_shown_in_checkout: false,
    auto_apply: false,
    gifts: [],
    free_shipping: false,
    restriction: {
        minimum_amount: null,
        maximum_amount: null,
        exclude_sale_items: false,
        individual_use: false,
        include_product_ids: [],
        exclude_product_ids: [],
        include_category_ids: [],
        exclude_category_ids: [],
        shipping_courier: [],
        payment_method: [],
        allowed_email: [],
    },
    limit: {
        usage_limit: null,
        usage_limit_per_user: null,
        usage_limit_to_x_item: null,
    }
}
