import {FIELD_LIST_RELATED_PRODUCT_MIRACULOUS_SERIES} from "../../../utils/input-field-list-static-page";
import {setMiraculousSeriesRelatedProductDetail} from "../../../reducers/miraculous-series.reducer";
import {useDispatch, useSelector} from "react-redux";
import FormGeneralComponent from "../../../components/static-page/form-general.component";
import React, {useEffect} from "react";
import InputField from "./input-field";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";

const RelatedProduct = () => {
    const dispatch = useDispatch()
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const {relatedProductDetail} = useSelector(state => state.miraculousSeriesReducer)
    let fieldList = FIELD_LIST_RELATED_PRODUCT_MIRACULOUS_SERIES
    let showRelatedProduct = {
        label: 'Related Product',
        key: 'show_related_product',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }
    const toggleList = [
        {
            title: 'Related Product',
            content: <InputField fieldList={fieldList} />
        }
    ]

    const onChange = (key, value) => {
        dispatch(setMiraculousSeriesRelatedProductDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(relatedProductDetail)
        // submit form
    }

    useEffect(() => {
        getProductList().then(response => {
            fieldList[1].dataList = response.data
        })
    }, [])

    return (
        <FormGeneralComponent
            data={relatedProductDetail}
            switchToggleField={showRelatedProduct}
            onChange={onChange}
            toggleList={toggleList}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default RelatedProduct