import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setAvoStoriesTimelineDetail} from "../../../reducers/avo-stories.reducer";

const ItemTimeline = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {timelineDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(timelineDetail?.timelines)
        copyItemList[index][key] = value
        dispatch(setAvoStoriesTimelineDetail({timelines: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={timelineDetail?.timelines?.[index]?.[item.key]} error={avoStoriesErrorMessage[`timelines[${index}]`] ?? {}} onChange={onChange} onChangeInput={onChange}/>
            ))}
        </div>
    )
}

export default ItemTimeline