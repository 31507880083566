import InputComponent from "../../components/input.component";
import React from "react";
import {useSelector} from "react-redux";

const SaleInformationForm = ({fieldList}) => {
    const {productDetail, productErrorMessage} = useSelector(state => state.productReducer)

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} data={item.type === 'number' && productDetail[item.key] === 0 ? '0' : productDetail[item.key]} error={productErrorMessage}/>
            ))}
        </div>
    )
}

export default SaleInformationForm
