import React, {useEffect} from "react";
import {useFetch} from "../../services/useFetch";
import {useParams} from "react-router-dom";
import {API} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {setUserStatistic} from "../../reducers/user.reducer";
import {LoadingSvg, UserProfileDefault} from "../../utils/image";

const Profile = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {userStatistic} = useSelector(state => state.userReducer)
    const {fetching: getUserStatistic, isLoading} = useFetch({...API.USER_STATISTIC, ...{initPathVariable: id}})

    useEffect(() => {
        getUserStatistic().then(response => {
            dispatch(setUserStatistic(response.data))
        })
    }, [id])

    return (
        <div className={"px-10"}>
            <div className={"w-full bg-doctor row items-center justify-between p-10 shadow-lg rounded-lg"}>
                {isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                  <>
                      <div className={"row items-center space-x-4 text-forged-steel"}>
                          <img src={userStatistic?.image_url || UserProfileDefault} className={"w-20 h-20 rounded-full border-2 border-monstera"}/>
                          <div className={"space-y-1"}>
                              <p className={"text-monstera text-base font-semibold capitalize"}>{userStatistic?.fullname}</p>
                              <p>Bergabung sejak {userStatistic?.created_at}</p>
                              <p className={"text-monstera font-semibold"}>{userStatistic?.level_name}</p>
                          </div>
                      </div>
                      <div className={"row items-center space-x-10"}>
                          <div className={"space-y-1"}>
                              <p className={"text-forged-steel"}>Total Referral</p>
                              <p className={"text-monstera text-base font-semibold"}>{userStatistic?.total_referral} Referral</p>
                          </div>
                          <div className={"space-y-1"}>
                              <p className={"text-forged-steel"}>Total Pesanan</p>
                              <p className={"text-monstera text-base font-semibold"}>{userStatistic?.total_order} Pesanan</p>
                          </div>
                          <div className={"space-y-1"}>
                              <p className={"text-forged-steel"}>Total Ulasan</p>
                              <p className={"text-monstera text-base font-semibold"}>{userStatistic?.total_review} Ulasan</p>
                          </div>
                          <div className={"space-y-1"}>
                              <p className={"text-forged-steel"}>Total Point</p>
                              <p className={"text-monstera text-base font-semibold"}>{userStatistic?.total_point} Point</p>
                          </div>
                      </div>
                  </>
                }
            </div>
        </div>
    )
}

export default Profile
