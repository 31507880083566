import {createSlice} from '@reduxjs/toolkit'
import {
    benefitModel,
    faqModel,
    joinMemberModel,
    levelingModel,
    mainBannerModel,
    memberFlowModel,
    seoModel
} from "../models/loyalty-program.model";

export const loyaltyProgramSlice = createSlice({
    name: 'loyaltyProgram',
    initialState: {
        mainBannerDetail: {...mainBannerModel},
        memberFlowDetail: {...memberFlowModel},
        benefitDetail: {...benefitModel},
        levelingDetail: {...levelingModel},
        joinMemberDetail: {...joinMemberModel},
        faqDetail: {...faqModel},
        seoDetail: {...seoModel},
        loyaltyProgramErrorMessage: {}
    },
    reducers: {
        setLoyaltyProgramMainBannerDetail: (state, action) => {
            state.mainBannerDetail = {...state.mainBannerDetail, ...action.payload}
        },
        setLoyaltyProgramMemberFlowDetail: (state, action) => {
            state.memberFlowDetail = {...state.memberFlowDetail, ...action.payload}
        },
        setLoyaltyProgramBenefitDetail: (state, action) => {
            state.benefitDetail = {...state.benefitDetail, ...action.payload}
        },
        setLoyaltyProgramLevelingDetail: (state, action) => {
            state.levelingDetail = {...state.levelingDetail, ...action.payload}
        },
        setLoyaltyProgramJoinMemberDetail: (state, action) => {
            state.joinMemberDetail = {...state.joinMemberDetail, ...action.payload}
        },
        setLoyaltyProgramFaqDetail: (state, action) => {
            state.faqDetail = {...state.faqDetail, ...action.payload}
        },
        setLoyaltyProgramSeoDetail: (state, action) => {
            state.seoDetail = {...state.seoDetail, ...action.payload}
        },
        setLoyaltyProgramErrorMessage: (state, action) => {
            state.loyaltyProgramErrorMessage = action.payload
        }
    },
})

export const {
    setLoyaltyProgramMainBannerDetail,
    setLoyaltyProgramMemberFlowDetail,
    setLoyaltyProgramBenefitDetail,
    setLoyaltyProgramLevelingDetail,
    setLoyaltyProgramJoinMemberDetail,
    setLoyaltyProgramFaqDetail,
    setLoyaltyProgramSeoDetail,
    setLoyaltyProgramErrorMessage
} = loyaltyProgramSlice.actions
const loyaltyProgramReducer = loyaltyProgramSlice.reducer

export default loyaltyProgramReducer
