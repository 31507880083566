import SwitchToggleComponent from "../switch-toggle.component";
import TabToggleComponent from "../tab-toggle.component";
import InputComponent from "../input.component";
import React from "react";
import {LoadingSvg} from "../../utils/image";

const FormGeneralComponent = (props) => {
    const {
        title,
        data,
        switchToggleField,
        fieldList,
        toggleList,
        onChange,
        onSubmitHandler,
        inputClassName,
        itemInputClassName,
        errorMessage,
        isLoading
    } = props

    return (
        <form id={"form"}>
            <div className={"row justify-between items-center bg-doctor p-4 rounded-md border border-fennel-stem"}>
                <p className={"text-sm text-monstera font-Montserrat-Bold"}>{switchToggleField.label}</p>
                <SwitchToggleComponent item={switchToggleField} defaultValue={data[switchToggleField?.key]}
                                       onChangeSwitchToggle={onChange}/>
            </div>
            <div className={"col space-y-4 pt-4"}>
                {toggleList?.length > 0 ?
                    toggleList.map((item, index) =>
                        <TabToggleComponent
                            key={index}
                            title={item.title}
                            content={item.content}
                        />
                    )
                    :
                    <TabToggleComponent
                        title={title || 'Input Field'}
                        content={
                            <div className={inputClassName}>
                                {fieldList.map((item, index) => (
                                    <div key={index}
                                         className={item.key === itemInputClassName?.key ? itemInputClassName?.value[0] : itemInputClassName?.value[1]}>
                                        <InputComponent item={item} data={data[item.key]} onChange={onChange}
                                                        onChangeInput={onChange} error={errorMessage}/>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                }
            </div>
            <div className={"row font-semibold justify-center py-8"}>
                <button type={"button"} onClick={(e) => onSubmitHandler(e)}
                        className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                    {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                </button>
            </div>
        </form>
    )
}

export default FormGeneralComponent
