import React, {useEffect, useState} from "react";
import TabToggleComponent from "../../components/tab-toggle.component";
import {AccessForm} from "./access-form";
import InputComponent from "../../components/input.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRoleIds} from "../../reducers/roles.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {LoadingSvg} from "../../utils/image";

const CreateRole = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [inputRoleNameItem, setInputRoleNameItem] = useState({
        label: 'user name role',
        key: 'name',
        placeholder: 'Administrator',
        type: 'text'
    })
    const [role_name, setRoleName] = useState()
    const dispatch = useDispatch()
    const {selectedIds} = useSelector(state => state.roleReducer)
    const {data: response, fetching: getModules} = useFetch(API.GET_LIST_MODULES, {})
    const {fetching: getRoleDetail} = useFetch(API.GET_DETAIL_ROLE, {})
    const {fetching: setNewRole, isLoading: loadingCreateRole} = useFetch(API.CREATE_USER_ROLE, {})
    const {fetching: updateUserRole, isLoading: loadingUpdateRole} = useFetch(API.UPDATE_USER_ROLE, {})

    useEffect(() => {
        getModules()
    }, [])

    useEffect(() => {
        if (!!id) {
            getRoleDetail({}, id)
                .then(resp => {
                    dispatch(setSelectedRoleIds(resp?.data?.module_ids))
                    setRoleName(resp?.data?.name)
                })
        }
    }, [id])

    const onChangeInput = (key, value) => {
        setRoleName(value)
    }

    const onSubmitHandler = () => {
        if(!!role_name){
            if (id) {
                onUpdateNewRole()
            } else {
                onCreateNewRole()
            }
        }
        else {
            let inputRoleNameItemCopy = {...inputRoleNameItem}
            inputRoleNameItemCopy.errorText = 'Nama role wajib diisi.'
            setInputRoleNameItem(inputRoleNameItemCopy)
        }
    }

    const onCreateNewRole = () => {
        let body = {
            name: role_name,
            module_ids: selectedIds
        }

        setNewRole(body)
            .then(res => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('New Role Created'))
                navigate('/user/role-list')
            })
    }

    const onUpdateNewRole = () => {
        let body = {
            name: role_name,
            module_ids: selectedIds
        }

        updateUserRole(body, id)
            .then(res => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Role Updated'))
                navigate('/user/role-list')
            })
    }

    return (
        <div className={"col space-y-5 px-10 mt-5 mb-10"}>
            <InputComponent onChangeInput={onChangeInput} data={role_name} item={inputRoleNameItem}
                            error={{[inputRoleNameItem.key]: inputRoleNameItem?.errorText}}/>
            <TabToggleComponent title={'Access'} content={<AccessForm data={response?.data}/>}/>
            <div className={"col items-center"}>
                <button className={"w-52 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera rounded-md text-white font-semibold px-10 py-2"}
                        onClick={onSubmitHandler}
                >
                    {loadingCreateRole || loadingUpdateRole ?
                        <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                </button>
            </div>
        </div>
    )
}

export default CreateRole
