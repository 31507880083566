import React, {useEffect, useState} from "react";
import {LoadingSvg} from "../../utils/image";
import {STEP_IMPORT_AVOSTORE} from "../../utils/constant";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setAvostoreActiveTab, setAvostoreImportResult} from "../../reducers/avostore.reducer";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";
import ProgressBarComponent from "../../components/progress-bar.component";

const Import = () => {
    const dispatch = useDispatch()
    const {importAvostoreDetail} = useSelector(state => state.avostoreReducer)
    const [percentage, setPercentage] = useState(0)
    const {fetching: importAvostore} = useFetch(API.IMPORT_AVOSTORE)

    useEffect(() => {
        let timeOut = null

        if (percentage < 100){
            timeOut = setTimeout(() => {
                setPercentage(percentage + 20)
            }, 1000)
        } else if (percentage === 100) {
            timeOut = setTimeout(() => {
                dispatch(setAvostoreActiveTab(STEP_IMPORT_AVOSTORE[2]))
            }, 1000)
        }

        return () => {
            clearTimeout(timeOut)
        }
    }, [percentage])

    useEffect(() => {
        importAvostore(importAvostoreDetail, null, {'Content-Type': 'multipart/form-data'}).then()
            .catch((error) => {
                dispatch(setToolsModalVisibility({alert: false}))
                dispatch(setAvostoreImportResult(error))
            })
    }, [])

    return (
        <div className={"col space-y-8 py-6 border border-whisper-green rounded-lg"}>
            <div className={"col space-y-4 px-6"}>
                <p className={"text-lg text-monstera"}>Importing</p>
                <div className={"row items-center space-x-4"}>
                    <p>Your Avostore Data are now being imported...</p>
                    <img src={LoadingSvg} alt="LoadingIcon" className={"w-10"}/>
                </div>
            </div>
            <ProgressBarComponent percentage={percentage}/>
        </div>
    )
}

export default Import