export const bannerModel = {
    show_banner: false,
    item_list: [{
        id: 'banner',
        desktop_banner: null,
        mobile_banner: null,
        link_banner: null
    }],
    delete_ids: []
}

export const posterModel = {
    show_poster: false,
    desktop_poster: null,
    mobile_poster: null,
    link_poster: null,
}

export const promotedProductModel = {
    show_product: false,
    item_list: [{
        id: 'promoted_product',
        product_image: null,
        link_product: null
    }],
    delete_ids: []
}

export const productRecommendationModel = {
    show_product_recommendation: false,
    title: null,
    description: null,
    select_product: []
}

export const flashSaleModel = {
    show_flash_sale: false,
    desktop_mini_banner: null,
    mobile_mini_banner: null,
    icon_flash_sale: null,
    start_date_time: null,
    end_date_time: null,
    title_flash_sale_button: null,
    link_flash_sale_button: null,
    select_product: []
}
