import React, {useEffect} from "react";
import OrderTab from "./order-tab";
import OrderFilter from "./order-filter";
import OrderList from "./order-list";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setOrderAdvanceFilter, setOrderInitialState} from "../../reducers/order.reducer";
import {SORT_LIST_ORDER} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";
import {advanceFilterOrderListModel} from "../../models/advance-filter";

const OrderPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_ORDER?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        reload
    } = useSelector(state => state.toolsReducer)
    const {advanceFilter} = useSelector(state => state.orderReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey
    }
    const {
        data: response,
        fetching: getOrderList,
        isLoading
    } = useFetch(API.ORDER_LIST, {}, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setOrderInitialState())
            dispatch(setOrderAdvanceFilter(advanceFilterOrderListModel))
        }
    }, [])

    useEffectAfterMount(() => {
        getOrderList({
            ...advanceFilter,
            affiliatorId: advanceFilter?.affiliatorId?.map(i => i.id)
        }, '', {}, '', queryParams)
    }, [currentPage, pageSize, orderBy, sort, searchKey, reload, advanceFilter])

    return (
        <div className={"mt-4"}>
            <OrderTab/>
            <div className={"col px-10"}>
                <OrderFilter/>
                <OrderList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
            </div>
        </div>
    )
}

export default OrderPage
