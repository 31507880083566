import CategoryFilter from "./category-filter";
import CreateCategoryForm from "./create-category-form";
import CategoryList from "./category-list";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setCategoryInitialState} from "../../reducers/category.reducer";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const CategoryPage = () => {
    const dispatch = useDispatch()
    const {currentPage, pageSize, searchKey, reload} = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey
    }
    const {
        data: response,
        fetching: getCategoryList,
        isLoading
    } = useFetch(API.CATEGORY_LIST, queryParams, {isWithTotalData: true})

    useEffectAfterMount(() => {
        getCategoryList()
    }, [searchKey, pageSize, currentPage, reload])

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setCategoryInitialState())
        }
    }, [])

    return (
        <div className={"col px-10"}>
            <div className={"row justify-between items-center my-5"}>
                <CategoryFilter/>
                <CreateCategoryForm/>
            </div>
            <CategoryList datasource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default CategoryPage
