import React, {useEffect} from "react";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import Item from "./item";
import {FIELD_LIST_TESTIMONIAL} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {
    setMainPageErrorMessage,
    setMainPageTestimonialDetail
} from "../../../reducers/main-page.reducer";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Testimonial = () => {
    const dispatch = useDispatch()
    const {testimonialDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getTestimonial} = useFetch(API.HOMEPAGE_GET_TESTIMONIAL)
    const {fetching: updateTestimonial, isLoading} = useFetch(API.HOMEPAGE_UPDATE_TESTIMONIAL)
    const itemList = [...testimonialDetail.item_list]
    let fieldList = FIELD_LIST_TESTIMONIAL
    let showTestimonial = {
        label: 'Testimonial',
        key: 'show_testimonial',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageTestimonialDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'testimonial').toString(),
            testimonial_image: null,
            title: null,
            description: null
        })
        dispatch(setMainPageTestimonialDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(testimonialDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataTestimonial = {...testimonialDetail, item_list: dataItemList}
        updateTestimonial(dataTestimonial).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Testimonial Updated'))
            dispatch(setMainPageTestimonialDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getTestimonial().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setMainPageTestimonialDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setMainPageTestimonialDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={testimonialDetail}
            switchToggleField={showTestimonial}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageTestimonialDetail}
            deleteList={testimonialDetail.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Testimonial
