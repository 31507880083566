import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    getMethodPayment
} from "../../utils/general-variable";
import {AvoCoinImage, VoucherDefaultIcon} from "../../utils/image";
import {useSelector} from "react-redux";

const OrderDetailPayment = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const transaction = orderDetail?.transaction
    const reduce_payment = [
        {
            label: 'Ongkos Kirim',
            data: transaction?.shipping_total
        }, {
            label: 'TAX',
            data: transaction?.tax_total
        }, {
            label: 'Voucher',
            data: transaction?.voucher
        }, {
            label: 'Point',
            data: transaction?.loyalty_point
        }, {
            label: 'Membership Discount',
            membershipName: transaction?.membership_name,
            data: transaction?.discount_membership
        }, {
            label: 'Birthday Discount',
            data: transaction?.discount_birthday
        }
    ]

    return (
        <div className={"col space-y-5"}>
            <div>
                <div className={"px-8 py-3 rounded-t-lg bg-coronation"}>
                    <div className={"row justify-between font-Montserrat-Bold text-eerie-black"}>
                        <p>SUB TOTAL</p>
                        <p>{doConvertNumberToRupiahFormat(transaction?.subtotal)}</p>
                    </div>
                </div>
                {reduce_payment?.map((item, index) => (
                    <div key={index} className={`px-8 py-3 ${(index + 1) % 2 === 0 && 'bg-doctor'}`}>
                        <div className={"row justify-between text-eerie-black"}>
                            <div className={"row space-x-3"}>
                                <p>{item?.label}</p>
                                {item?.membershipName && <p className={"font-semibold text-eerie-black"}>{`[${item?.membershipName}]`}</p>}
                            </div>
                            {
                                ['Ongkos Kirim', 'TAX'].includes(item?.label) ?
                                    <p>{doConvertNumberToRupiahFormat(item?.data)}</p> :
                                    <p className={"text-spiced-coral"}>- {doConvertNumberToRupiahFormat(item?.data ?? 0)}</p>
                            }
                        </div>
                    </div>
                ))}
                <div className={"px-8 py-3 rounded-b-lg bg-windmill-wings border border-fennel-stem"}>
                    <div className={"row justify-between font-Montserrat-Bold text-monstera"}>
                        <p>GRAND TOTAL</p>
                        <p>{doConvertNumberToRupiahFormat(transaction?.grand_total)}</p>
                    </div>
                </div>
            </div>
            <div className={"col space-y-4"}>
                <div className={"px-8 py-5 rounded-lg border border-pinball"}>
                    <div className={"row items-center justify-between text-monstera"}>
                        <p className={"font-Montserrat-Bold text-sm"}>Payment Method</p>
                        <div className={"row items-center justify-between space-x-8"}>
                            <img src={getMethodPayment(transaction?.payment_service).logo} alt="logo" className={"w-16"}/>
                            <p className={"font-semibold text-forged-steel"}>{getMethodPayment(transaction?.payment_service).text}</p>
                        </div>
                    </div>
                </div>
                <div className={"row space-x-8"}>
                    <div className={"col space-y-4 py-3 px-5 w-1/2 h-fit border border-pinball"}>
                        <p className={"font-Montserrat-Bold text-monstera text-sm"}>Voucher</p>
                        {
                            transaction?.vouchers.length > 0 ?
                                transaction?.vouchers.map((voucher, index) => (
                                    <div key={index} className={"row items-center space-x-4"}>
                                        <img src={voucher.thumbnail || VoucherDefaultIcon} alt="voucherImage" className={"w-8"}/>
                                        <div className={"col space-y-1 text-forged-steel"}>
                                            <p className={"font-semibold"}>{voucher.code}</p>
                                            <p>{voucher.date_expires ? `Berlaku hingga ${convertDateToCustomFormat(voucher.date_expires, 'D MMMM YYYY')}` : ''}</p>
                                        </div>
                                    </div>
                                )) : <p className={"text-monstera"}>Empty Voucher</p>
                        }
                    </div>
                    <div className={"col space-y-4 py-3 px-5 w-1/2 h-fit border border-pinball"}>
                        <p className={"font-Montserrat-Bold text-monstera text-sm"}>Point</p>
                        <div className={"row items-center space-x-4"}>
                            <img src={AvoCoinImage} alt="coinImage"/>
                            <p className={"font-semibold text-forged-steel"}>Menggunakan {transaction?.loyalty_point} Poin</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailPayment
