import TabToggleComponent from "../../components/tab-toggle.component";
import GraphAnalyticsComponent from "../../components/graph-analytics.component";
import React from "react";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabProductSoldGraph = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const queryParams = {
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
    }
    const {
        data: response,
        fetching: getProductSoldGraph,
        isLoading
    } = useFetch(API.ANALYTIC_PRODUCT_SOLD_GRAPH, queryParams)

    useEffectAfterMount(() => {
        getProductSoldGraph()
    }, [dateRange, reload])

    return (
        <TabToggleComponent
            title={"Product Sold Graph"}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <GraphAnalyticsComponent title={'Jml. Produk Terjual'} slug={'Produk'}
                                             dataList={response?.data ?? []}/>
            }
        />
    )
}

export default TabProductSoldGraph