import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React, {useEffect} from "react";
import {LoadingSvg} from "../../utils/image";
import {setAvostoreDetail, setAvostoreInitialAvostoreDetail} from "../../reducers/avostore.reducer";
import CreateAvostore from "../create-avostore";

const UpdateAvostorePage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {
        fetching: getAvostoreDetail,
        isLoading
    } = useFetch({...API.AVOSTORE_DETAIL, ...{initPathVariable: id}})

    useEffect(() => {
        getAvostoreDetail().then((response) => {
            dispatch(setAvostoreDetail(response?.data))
        })
        return () => {
            dispatch(setAvostoreInitialAvostoreDetail())
        }
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className={"row justify-center w-full h-1/2"}>
                        <img src={LoadingSvg} className={"w-12 mx-auto"}/>
                    </div> :
                    <CreateAvostore/>
            }
        </>
    )
}

export default UpdateAvostorePage