import {
    FIELD_LIST_GENERAL_CONTENT,
    FIELD_LIST_ROUTING,
    FIELD_LIST_RECEIVER
} from "../../utils/input-field-list";
import GeneralContentForm from "./general-content-form";
import RoutingForm from "./routing-form";
import ReceiverForm from "./receiver-form";
import TabToggleComponent from "../../components/tab-toggle.component";
import {cloneDeep} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    setNotificationErrorMessage,
    setNotificationInitialNotificationDetail
} from "../../reducers/notification.reducer";
import {convertErrorMessage, convertUtcToCustomFormat} from "../../utils/general-variable";
import React, {useEffect} from "react";
import {LoadingSvg} from "../../utils/image";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import {useNavigate, useParams} from "react-router-dom";

const CreateNotificationPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const {
        notificationDetail,
        notificationErrorMessage,
        initRoutingCustom,
        initRoutingList,
        initRoutingDetail,
        initReceiverAll,
        initReceiverEmail,
        initReceiverOrder
    } = useSelector(state => state.notificationReducer)
    const {fetching: postCreateNotification, isLoading: loadingCreateNotification} = useFetch(API.CREATE_NOTIFICATION)
    const {
        fetching: postUpdateNotification,
        isLoading: loadingUpdateNotification
    } = useFetch({...API.UPDATE_NOTIFICATION, ...{initPathVariable: id}})
    const {
        fetching: postSaveAsDraft,
        isLoading: loadingSaveAsDraft
    } = useFetch({...API.SAVE_AS_DRAFT_NOTIFICATION, ...{initPathVariable: id}})
    let generalField = FIELD_LIST_GENERAL_CONTENT
    let routingField = FIELD_LIST_ROUTING
    let receiverField = FIELD_LIST_RECEIVER

    const toggleList = [
        {
            title: 'General Konten',
            content: <GeneralContentForm fieldList={generalField}/>
        }, {
            title: 'Routing',
            content: <RoutingForm fieldList={routingField}/>
        }, {
            title: 'Receiver',
            content: <ReceiverForm fieldList={receiverField}/>
        }
    ]

    useEffect(() => {
        return () => {
            dispatch(setNotificationErrorMessage({}))
            dispatch(setNotificationInitialNotificationDetail())
            dispatch(setToolsStartDateEndDate([null, null]))
        }
    }, [])

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        navigate('/notification/notification-list')
    }

    const dataChecking = (field, value, buttonType) => {
        let notificationDetailCopy = cloneDeep(notificationDetail)
        let error = notificationErrorMessage?.scheduled_at ? {scheduled_at: notificationErrorMessage?.scheduled_at} : {}
        dispatch(setNotificationErrorMessage({}))
        field.forEach((item) => {
            switch (item.key) {
                case 'title':
                case 'description':
                case 'type':
                case 'icon':
                    if (!notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'scheduled_at':
                    if (value[item.key]?.value) {
                        notificationDetailCopy[item.key] = convertUtcToCustomFormat(value[item.key]?.value, "YYYY-MM-DD HH:mm")
                    } else {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'routing_type':
                    if (!notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    } else {
                        let initRouting = {}
                        if (notificationDetail[item.key] === 'custom') {
                            initRouting = initRoutingCustom
                        } else if (notificationDetail[item.key] === 'list') {
                            initRouting = initRoutingList
                        } else {
                            initRouting = initRoutingDetail
                        }
                        notificationDetailCopy = {...notificationDetailCopy, ...initRouting}
                    }
                    break
                case 'routing_link':
                    if (notificationDetail['routing_type'] === 'custom' && !notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'routing_order':
                    if (notificationDetail['routing_type'] === 'list' && !notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'routing_product_ids':
                case 'routing_category_ids':
                    if (notificationDetail['routing_type'] === 'list' && notificationDetail['routing_product_ids'].length === 0 && notificationDetail['routing_category_ids'].length === 0) {
                        error[item.key] = `Category or Product must be required`
                    }
                    break
                case 'routing_product_id':
                    if (notificationDetail['routing_type'] === 'detail' && !notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'receiver_type':
                    if (!notificationDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    } else {
                        let initReceiver = {}
                        if (notificationDetail[item.key] === 'all') {
                            initReceiver = initReceiverAll
                        } else if (notificationDetail[item.key] === 'email') {
                            initReceiver = initReceiverEmail
                        } else {
                            initReceiver = initReceiverOrder
                        }
                        notificationDetailCopy = {...notificationDetailCopy, ...initReceiver}
                    }
                    break
                case 'receiver_emails':
                    if (notificationDetail['receiver_type'] === 'email' && notificationDetail[item.key].length === 0) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'receiver_product_ids':
                case 'month':
                case 'receiver_order_times':
                    if (notificationDetail['receiver_type'] === 'order'
                        && notificationDetail['receiver_product_ids'].length === 0
                        && !notificationDetail['receiver_start_date']
                        && !notificationDetail['receiver_end_date']
                        && !value['receiver_order_times']?.value) {
                        error[item.key] = "Product, Month, or Order Times is required"
                    } else {
                        notificationDetailCopy['receiver_order_times'] = value['receiver_order_times']?.value ? Number(value['receiver_order_times']?.value) : null
                    }
                    break
                default:
                    if (item.type === 'number' && Number(value.namedItem(item.key)?.value) < 0) {
                        error[item.key] = `${item.label} is lower than 0`
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setNotificationErrorMessage({}))
            return {isValid: true, notification: notificationDetailCopy}
        } else if (buttonType === 'draft') {
            const errorDraft = {
                title: error?.title,
                scheduled_at: !!error?.scheduled_at?.includes('interval') ? error?.scheduled_at : null
            }
            dispatch(setNotificationErrorMessage(errorDraft))
            return {isValid: !errorDraft.title && !errorDraft.scheduled_at, notification: notificationDetailCopy}
        } else {
            dispatch(setNotificationErrorMessage(error))
            return {isValid: false, notification: notificationDetailCopy}
        }
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = [
            ...generalField[0],
            ...generalField[1],
            routingField[0],
            routingField[1],
            ...routingField[2],
            routingField[3],
            routingField[4],
            routingField[5],
            receiverField[0],
            receiverField[1],
            receiverField[2],
            ...receiverField[3]
        ]
        let formValue = document.getElementById("form").elements
        const {isValid, notification} = dataChecking(field, formValue, buttonType)
        if (isValid) {
            let requestBody = {
                ...notification,
                routing_category_ids: notification?.routing_category_ids.map(val => val.id),
                routing_product_id: notification?.routing_product_id?.id || notification?.routing_product_id,
                routing_product_ids: notification?.routing_product_ids.map(val => val.id),
                receiver_product_ids: notification?.receiver_product_ids.map(val => val.id),
                receiver_emails: notification?.receiver_emails.map(val => val?.email || val),
            }
            getPromiseApi(buttonType, requestBody).then(() => {
                showAlertModal(getSuccessMessage(buttonType))
            }).catch(err => {
                dispatch(setNotificationErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getSuccessMessage = (type) => {
        switch (type) {
            case 'publish':
                return 'Notification Published'
            case 'update':
                return 'Notification Updated'
            default:
                return 'Notification Drafted'
        }
    }

    const getPromiseApi = (type, requestBody) => {
        switch (type) {
            case 'publish':
                return postCreateNotification(requestBody)
            case 'update':
                return postUpdateNotification(requestBody)
            default:
                return postSaveAsDraft(requestBody)
        }
    }

    return (
        <div className={"col px-10 mt-4"}>
            <form id={"form"} onSubmit={onSubmitHandler} className={"col space-y-4 mb-10"}>
                {
                    toggleList.map((item, index) =>
                        <TabToggleComponent
                            key={index}
                            title={item.title}
                            content={item.content}
                        />
                    )
                }
                <div className={"row font-semibold justify-center space-x-4 py-8"}>
                    <button type={"button"} onClick={(e) => onSubmitHandler('draft', e)}
                            className={`w-60 py-2 border border-fortress-grey rounded-lg ${notificationDetail?.is_sent ? 'bg-fortress-grey text-white cursor-not-allowed' : 'hover:text-white hover:bg-dazzle'}`}
                            disabled={notificationDetail?.is_sent}>
                        {loadingSaveAsDraft ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save As Draft'}
                    </button>
                    <button type={"button"} onClick={(e) => onSubmitHandler(!!id ? 'update' : 'publish', e)}
                            className={`w-60 py-2 text-white rounded-lg ${notificationDetail?.is_sent ? 'bg-fortress-grey cursor-not-allowed' : 'bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera to-monstera'}`}
                            disabled={notificationDetail?.is_sent}>
                        {loadingCreateNotification || loadingUpdateNotification ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : !!id ? 'Update' : 'Publish'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateNotificationPage
