export const mainBannerModel = {
    show_main_banner: false,
    desktop_image: null,
    mobile_image: null,
    url: null
}

export const memberFlowModel = {
    show_member_flow: false,
    desktop_image: null,
    mobile_image: null
}

export const benefitModel = {
    show_benefit: false,
    desktop_image: null,
    mobile_image: null
}

export const levelingModel = {
    show_leveling: false,
    desktop_image: null,
    mobile_image: null
}

export const joinMemberModel = {
    show_join_member: false,
    desktop_image: null,
    mobile_image: null,
    url: null
}

export const faqModel = {
    show_faq: false,
    desktop_image: null,
    mobile_image: null
}

export const seoModel = {
    show_seo: false,
    title: null,
    description: null
}
