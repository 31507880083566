import TooltipComponent from "./tooltip.component";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    doConvertNumberWithPoint,
    textPercentage
} from "../utils/general-variable";

const CardComparationComponent = ({ cardList, data }) => {
    const percentageState = data?.percentage?.[cardList.key]?.state
    const percentageValue = data?.percentage?.[cardList.key]?.value
    const currentPeriod = data?.current_period
    const previousPeriod = data?.previous_period

    return (
        <>
            <div className={"row items-center justify-between bg-quiet-whisper py-2 px-5"}>
                <div/>
                <p className={"text-sm text-monstera font-Montserrat-Bold"}>{cardList.attribute}</p>
                <TooltipComponent iconType={"question"}/>
            </div>
            <div className={"row items-center justify-between py-3 px-5"}>
                <div className={`row items-center space-x-1 h-full w-1/5 ${textPercentage(percentageState, percentageValue).className}`}>
                    {textPercentage(percentageState, percentageValue).text !== 'Konstan' &&
                        <img src={textPercentage(percentageState, percentageValue).icon} alt="arrowIcon"/>}
                    <p>{textPercentage(percentageState, percentageValue).text}</p>
                </div>
                <div className={"col space-y-3 px-4 w-2/5 border-l border-placebo"}>
                    <p className={"text-eerie-black font-semibold"}>Periode Saat Ini</p>
                    <p className={"text-lg font-Montserrat-Bold"}>{
                        cardList.type === 'price' ? doConvertNumberToRupiahFormat(currentPeriod?.[cardList.key]) :
                            doConvertNumberWithPoint(currentPeriod?.[cardList.key] || 0)
                    }</p>
                    <p className={"text-forged-steel"}>{convertDateToCustomFormat(currentPeriod?.start_date, 'DD MMM YY')} - {convertDateToCustomFormat(currentPeriod?.end_date, 'DD MMM YY')}</p>
                </div>
                <div className={"col space-y-3 px-4 w-2/5 border-l border-placebo"}>
                    <p className={"text-eerie-black font-semibold"}>Periode Sebelumnya</p>
                    <p className={"text-lg font-Montserrat-Bold"}>{
                        cardList.type === 'price' ? doConvertNumberToRupiahFormat(previousPeriod?.[cardList.key]) :
                            doConvertNumberWithPoint(previousPeriod?.[cardList.key] || 0)
                    }</p>
                    <p className={"text-forged-steel"}>{convertDateToCustomFormat(previousPeriod?.start_date, 'DD MMM YY')} - {convertDateToCustomFormat(previousPeriod?.end_date, 'DD MMM YY')}</p>
                </div>
            </div>
        </>
    )
}

export default CardComparationComponent