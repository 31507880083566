import React, {useState} from "react";
import {ChevronRightIcon} from "../../utils/image";
import {AccessItem} from "./access-item";
import {AccessAllItem} from "./access-all-item";

export const AccessForm = ({data = []}) => {
    const [activeAccess, setActiveAccess] = useState('')
    const [child, setChild] = useState([])

    const changeActiveAccess = (item) => {
        setChild(item?.child)
        setActiveAccess(item?.id)
    }

    return (
        <div className={"row"}>
            <div className={"w-1/4 border-r py-5 px-3 space-y-3"}>
                {
                    !!data?.length &&
                    data.map((item, index) => (
                        <div key={index}
                             onClick={() => changeActiveAccess(item)}
                             className={`row items-center justify-between px-3 py-2 text-monstera cursor-pointer ${activeAccess === item.id ? 'font-Montserrat-Bold bg-windmill-wings rounded-md' : ''}`}>
                            <div className={`row items-center space-x-2`}>
                                <div className={"w-6"}>
                                    {
                                        !activeAccess && index === 0 &&
                                        <img src={ChevronRightIcon} className={"h-5"}/>
                                    }
                                    {
                                        !!activeAccess && activeAccess === item.id &&
                                        <img src={ChevronRightIcon} className={"h-5"}/>}
                                </div>
                                <p className={"capitalize"}>{item?.name}</p>
                            </div>
                            <p>({item?.child_count})</p>
                        </div>
                    ))
                }
            </div>
            <div className={"flex-1"}>
                <div className={"grid grid-cols-3 gap-3 px-10 py-5"}>
                    {
                        child.length > 0 && <AccessAllItem allItem={child}/>
                    }
                    {
                        child.map((i, index) => <AccessItem item={i} key={index}/>)
                    }
                </div>
            </div>
        </div>
    )
}
