import TabToggleComponent from "../../components/tab-toggle.component";
import ProductFilter from "./product-filter";
import ProductList from "./product-list";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabProductSoldList = () => {
    const {
        currentPage,
        pageSize,
        searchKey,
        sort,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey,
        orderType: sort,
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
    }
    const {
        data: response,
        fetching: getProductDailyData,
        isLoading
    } = useFetch(API.ANALYTIC_PRODUCT_DAILY_DATA, queryParams, {isWithTotalData: true})

    useEffectAfterMount(() => {
        getProductDailyData()
    }, [currentPage, pageSize, sort, searchKey, dateRange, reload])

    return (
        <TabToggleComponent
            title={'Product Sold List'}
            content={
                <div className={"col space-y-4"}>
                    <ProductFilter/>
                    <ProductList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
                </div>
            }
        />
    )
}

export default TabProductSoldList