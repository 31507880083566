import {FIELD_LIST_BANNER_MIRACULOUS_RETINOL_SERIES} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {randomCode} from "../../../utils/general-variable";
import {setMiraculousRetinolSeriesBannerDetail} from "../../../reducers/miraculous-retinol-series.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";
import React from "react";
import ItemBanner from "./item-banner";

const Banner = () => {
    const dispatch = useDispatch()
    const {bannerDetail} = useSelector(state => state.miraculousRetinolSeriesReducer)
    const itemList = [...bannerDetail.item_list]
    let fieldList = FIELD_LIST_BANNER_MIRACULOUS_RETINOL_SERIES
    let showBanner = {
        label: 'Banner',
        key: 'show_banner',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMiraculousRetinolSeriesBannerDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'banner').toString(),
            desktop_banner: null,
            mobile_banner: null,
            url: null
        })
        dispatch(setMiraculousRetinolSeriesBannerDetail({item_list: itemList}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(bannerDetail)
        // submit form
    }

    return (
        <FormItemComponent
            data={bannerDetail}
            switchToggleField={showBanner}
            itemList={itemList}
            content={(index) => <ItemBanner fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMiraculousRetinolSeriesBannerDetail}
            deleteList={bannerDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default Banner
