import {useDispatch, useSelector} from "react-redux";
import {setWaste4ChangeBenefitDetail} from "../../../reducers/waste-4-change.reducer";
import InputComponent from "../../../components/input.component";
import React from "react";

const InputField = ({fieldList}) => {
    const dispatch = useDispatch()
    const {benefitDetail, waste4ChangeErrorMessage} = useSelector(state => state.waste4ChangeReducer)

    const onChangeInput = (key, value) => {
        dispatch(setWaste4ChangeBenefitDetail({[key]: value}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <InputComponent key={index} data={benefitDetail[item.key]} item={item} onChange={onChangeInput} onChangeInput={onChangeInput} error={waste4ChangeErrorMessage}/>
            ))}
        </div>
    )
}

export default InputField
