import {SearchIcon, XCircleIcon} from "../utils/image";
import React, {useEffect, useRef, useState} from "react";
import {cloneDeep} from "lodash";
import {useFetch} from "../services/useFetch";
import {API} from "../services/api";
import {useClickOutside} from "../services/useClickOutside";

const MultipleSelectProductComponent = (props) => {
    const {item, type, onChangeMultipleSelect, data, onChange} = props
    const searchData = useRef()
    const [isShowListOption, setIsShowListOption] = useState(false)
    const [listOption, setListOption] = useState([])
    const [listSelected, setListSelected] = useState([])
    const {fetching: getGiftList} = useFetch(API.GIFT_LIST, {}, {isFetching: false})
    const {fetching: getProductList} = useFetch(API.PRODUCT_LIST_VOUCHER, {}, {isFetching: false})
    const {fetching: getProductListNotification} = useFetch(API.NOTIFICATION_PRODUCT_LIST, {}, {isFetching: false})

    useEffect(() => {
        setListOption(item.dataList)
    }, [item.dataList])

    useEffect(() => {
        if (data.length > 0) {
            setListSelected(data)
        }
    }, [data])

    const specificKeyToEnableSearch = (key) => {
        switch (key) {
            case 'gifts':
            case 'include_product_ids':
            case 'exclude_product_ids':
            case 'routing_product_ids':
            case 'receiver_product_ids':
                return true
            default:
                return false
        }
    }

    const searchListOption = (event) => {
        const keyword = event.target.value
        if (keyword.length === 0) {
            setIsShowListOption(false)
        } else {
            if (!specificKeyToEnableSearch(item.key)) {
                searchManualOption(keyword)
            } else if (event.key === 'Enter') {
                searchByApi(keyword)
            }
        }
    }

    const searchManualOption = (keyword) => {
        const listIdSelected = listSelected.map(list => list.id)
        const filterListOption = item?.dataList.filter(list => {
            if (!!list[item.selectLabel] && list[item.selectLabel].toLowerCase().includes(keyword.toLowerCase())) {
                return !listIdSelected.includes(list.id)
            }
        })
        setListOption(filterListOption)
        setIsShowListOption(true)
    }

    const fetchApi = (payload) => {
        switch (item.key) {
            case 'gifts':
                return getGiftList(payload)
            case 'routing_product_ids':
            case 'receiver_product_ids':
                return getProductListNotification(payload)
            default:
                return getProductList(payload)
        }
    }

    const searchByApi = (keyword) => {
        fetchApi({search: keyword})
            .then(response => {
                const listIdSelected = listSelected.map(list => list.id)
                const filterListOption = response?.data?.filter(list => {
                    return !listIdSelected.includes(list.id)
                })
                setListOption(filterListOption)
                setIsShowListOption(true)
            })
    }

    const selectOption = (data) => {
        const listSelectedCopy = cloneDeep(listSelected)
        const addDataSelected = [...listSelectedCopy, ...[data]]
        setListSelected(addDataSelected)
        onChangeMultipleSelect(item.key, data)
        setIsShowListOption(false)
        searchData.current.value = ''
    }

    const deleteItem = (id) => {
        const deleteDataSelected = listSelected.filter(list => list.id !== id)
        setListSelected(deleteDataSelected)
        onChange(item.key, deleteDataSelected, id)
    }

    const getProductQuantity = (event, list, i) => {
        const newData = {...list, quantity: Number(event.target.value)}
        const updateQuantityData = [...listSelected]
        updateQuantityData[i] = newData
        setListSelected(updateQuantityData)
        onChange(item.key, updateQuantityData)
    }

    useClickOutside(`multiple-select-list-product-component-${item.key}`, isShowListOption, setIsShowListOption)

    return (
        <div id={`multiple-select-list-product-component-${item.key}`} className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${listSelected.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} name={item.key} id={item.key}
                       placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                       onKeyUp={searchListOption} ref={searchData}/>
            </div>
            <div className={listSelected.length > 0 ? 'block' : 'hidden'}>
                <div className={`${type === 'free_gift' || type === 'avo_wfc' || type === 'page' ? 'flex' : 'hidden'}
                row space-x-[270px] pl-14 text-monstera py-2 bg-doctor border-x border-placebo`}>
                    <p>Selected Product</p>
                    {type === 'free_gift' && <p>Qty</p>}
                </div>
                <div
                    className={"col space-y-5 p-5 bg-white border-x border-b rounded-b-lg border-placebo max-h-64 overflow-scroll"}>
                    {listSelected.map((list, index) => (
                        <div key={index} className={"row items-center space-x-4 mr-4"}>
                            <img src={XCircleIcon} alt="deleteList" className={"h-5 cursor-pointer"}
                                 onClick={() => deleteItem(list.id)}/>
                            <img src={list?.image?.url || list?.image} alt="imageDataSelected" className={"w-10"}/>
                            <p className={"w-fit"}>{list[item.selectLabel]}</p>
                            <div
                                className={`${(type === 'free_gift') ? 'block' : 'hidden'} border border-placebo rounded-lg overflow-hidden`}>
                                <input type={'number'} onWheel={event => event.target.blur()}
                                       onChange={(event) => getProductQuantity(event, list, index)}
                                       className={"border-0 outline-0 h-7 w-12 text-center"}
                                       defaultValue={list.quantity}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${isShowListOption ? 'block' : 'hidden'} col absolute w-11/12 top-8 left-10 bg-white shadow rounded-lg max-h-52 overflow-scroll z-10`}>
                {listOption.length > 0 ? listOption?.map((list, index) => (
                    <div key={index}
                         className={"row hover:bg-apple-flower hover:text-monstera items-center p-3 space-x-4 cursor-pointer"}
                         onClick={() => selectOption(list)}>
                        <img src={list?.image?.url || list?.image} alt="imageDataOption" className={"w-10"}/>
                        <p>{list[item.selectLabel]}</p>
                    </div>
                )) : <p className={"p-3"}>Not Found</p>}
            </div>
            {!!item.description && <p className={"italic mt-2"}>{item.description}</p>}
        </div>
    )
}

export default MultipleSelectProductComponent
