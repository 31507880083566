import React, {useEffect, useRef, useState} from "react";
import {ArrowDownIcon, SearchIcon, XCircleIcon} from "../../utils/image";
import {useDispatch, useSelector} from "react-redux";
import {setBirthdayGiftSelectedId} from "../../reducers/birthday-gift.reducer";
import {useClickOutside} from "../../services/useClickOutside";

const SearchGiftItem = (props) => {
    const {item, onChangeSelect, data, error} = props
    const dispatch = useDispatch()
    const {birthdayGiftSelectedId} = useSelector(state => state.birthdayGiftReducer)
    const [isShowList, setIsShowList] = useState(false)
    const searchValue = useRef()
    const [selectedItem, setSelectedItem] = useState({})
    const [list, setList] = useState({})

    useEffect(() => {
        setList(item?.options)
    }, [])

    useEffect(() => {
        setSelectedItem(data)
    }, [data])

    const searchListOption = (event) => {
        const keyword = event.target.value

        if (keyword.length === 0) {
            setIsShowList(false)
        } else {
            const filterListOption = item?.options?.filter(list => {
                if (list[item.selectLabel]?.toLowerCase()?.includes(keyword.toLowerCase())) {
                    return !birthdayGiftSelectedId.includes(list.id)
                }
            })
            setList(filterListOption)
            setIsShowList(true)
        }
    }

    const deleteItem = (selectedId) => {
        const filterExistingId = birthdayGiftSelectedId.filter(id => id !== selectedId)
        dispatch(setBirthdayGiftSelectedId(filterExistingId))
        setSelectedItem({})
        onChangeSelect('gift', {})
    }

    const onClickItem = (dataItem) => {
        const updateItems = selectedItem?.id ? birthdayGiftSelectedId.filter(i => i !== selectedItem?.id) : birthdayGiftSelectedId
        const findExistingId = updateItems.find(i => i === dataItem.id)
        !findExistingId && dispatch(setBirthdayGiftSelectedId([...updateItems, dataItem.id]))
        onChangeSelect('gift', dataItem)
        setSelectedItem(dataItem)
        searchValue.current.value = null
        setIsShowList(false)
    }

    useClickOutside(`search-git-component-${item.key}`, isShowList, setIsShowList)

    return (
        <div id={`search-git-component-${item.key}`} className={"relative col font-regular text-forged-steel"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo bg-white ${isShowList ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} name={item?.key} id={item?.key}
                       className={"border-0 outline-0 px-3 w-full disabled:bg-white placeholder:italic"}
                       ref={searchValue} onKeyUp={searchListOption} placeholder={item?.placeholder}/>
                <img className={"w-2.5 h-5"} src={ArrowDownIcon} alt={'arrowDown'}/>
            </div>
            {!!selectedItem?.id && (
                <div
                    className={"col space-y-5 p-5 bg-white border-x border-b rounded-b-lg border-placebo max-h-64 overflow-scroll"}>
                    <div className={"row items-center space-x-4 mr-4"}>
                        <img src={XCircleIcon} alt="deleteList" className={"h-5 cursor-pointer"}
                             onClick={() => deleteItem(selectedItem?.id)}/>
                        <img src={selectedItem?.image?.url} alt={selectedItem?.image?.alt_text} className={"w-10"}/>
                        <p className={"w-72"}>{selectedItem[item.selectLabel]}</p>
                    </div>
                </div>
            )}
            <div className={`${isShowList ? 'block' : 'hidden'} absolute top-8 w-full z-10`}>
                <div
                    className={"col bg-white border border-placebo mb-5 max-h-32 overflow-scroll"}>
                    {list?.length > 0 ? list?.map((l, index) => (
                        <div key={index}
                             className={"row space-x-4 p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => onClickItem(l)}>
                            <img src={l?.image?.url} alt={selectedItem?.image?.alt_text} className={"w-10"}/>
                            <p>{l?.name}</p>
                        </div>
                    )) : <p className={"p-3 text-center"}>Not Found</p>}
                </div>
            </div>
            {error[item?.key] && <p className={"font-semibold text-red-600 italic mt-2"}>{error[item?.key]}</p>}
        </div>
    )
}

export default SearchGiftItem
