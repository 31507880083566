import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {cloneDeep} from "lodash";
import {setMainPageRecommendationDetail} from "../../../reducers/main-page.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {recommendationDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    const onChangeInput = (key, value, id) => {
        const copyItemList = cloneDeep(recommendationDetail.item_list)
        if (key === 'select_product') {
            let deleteList = copyItemList[index].delete_product || []
            copyItemList[index].delete_product = [...deleteList, id]
            copyItemList[index].select_product = value
        } else {
            copyItemList[index][key] = value
        }
        dispatch(setMainPageRecommendationDetail({item_list: copyItemList}))
    }

    const onChangeProduct = (key, value) => {
        const copyItemList = cloneDeep(recommendationDetail.item_list)
        let deleteList = copyItemList[index]?.delete_product?.filter(item => item !== value?.id)
        copyItemList[index].delete_product = deleteList || []
        copyItemList[index].select_product = copyItemList[index][key].concat(value)
        dispatch(setMainPageRecommendationDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <div key={i} className={item.key === 'recommendation_label' || item.key === 'select_product' ? 'col-span-2' : ''}>
                    <InputComponent onChange={onChangeInput} onChangeInput={onChangeInput}
                                    onChangeProduct={onChangeProduct} item={item}
                                    data={recommendationDetail.item_list[index][item.key]}
                                    error={mainPageErrorMessage[`item_list[${index}]`] ?? {}}/>
                </div>
            ))}
        </div>
    )
}

export default Item