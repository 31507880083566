export const METHOD = {
    GET: 'get',
    POST: 'post'
}

export const API = {
    PING_SERVER: {
        api: '/api/v1/adm/ping',
        method: METHOD.GET
    },
    SEARCH_AREA: {  // BITESHIP API
        api: '/v1/maps/areas',
        method: METHOD.GET
    },
    GENERATE_CLIENT_TOKEN: {
        api: '/api/v1/adm/auth/client-token',
        method: METHOD.POST
    },
    REFRESH_TOKEN: {
        api: '/api/v1/adm/auth/refresh-token',
        method: METHOD.POST
    },
    LOGIN: {
        api: '/api/v1/adm/auth/login',
        method: METHOD.POST
    },
    VERIFY_LOGIN: {
        api: '/api/v1/adm/auth/verify-login',
        method: METHOD.POST
    },
    FORGOT_PASSWORD: {
        api: '/api/v1/adm/auth/reset-password',
        method: METHOD.POST
    },
    LOGOUT: {
        api: '/api/v1/adm/auth/logout',
        method: METHOD.POST
    },
    USER_LIST: {
        api: '/api/v1/adm/user/list',
        method: METHOD.GET
    },
    EXPORT_USER_LIST: {
        api: '/api/v1/adm/user/list/export',
        method: METHOD.POST
    },
    USER_UPDATE_STATUS: {
        api: '/api/v1/adm/user/bulk-action',
        method: METHOD.POST
    },
    ROLE_LIST: {
        api: '/api/v1/master/roles',
        method: METHOD.GET
    },
    USER_STATISTIC: {
        api: '/api/v1/adm/user/statistic',
        method: METHOD.GET
    },
    USER_PROFILE: {
        api: '/api/v1/adm/user/details',
        method: METHOD.GET
    },
    USER_ADDRESS_LIST: {
        api: '/api/v1/adm/user/address',
        method: METHOD.GET
    },
    USER_UPDATE_ADDRESS: {
        api: '/api/v1/adm/user/address/update',
        method: METHOD.POST
    },
    USER_ORDER_LIST: {
        api: '/api/v1/adm/user/order',
        method: METHOD.GET
    },
    USER_LOYALTY_POINT_LIST: {
        api: '/api/v1/adm/user/loyalty-point',
        method: METHOD.GET
    },
    USER_REVIEW_LIST: {
        api: '/api/v1/adm/user/review',
        method: METHOD.GET
    },
    EXPORT_USER_ORDER_LIST: {
        api: '/api/v1/adm/user/order/export',
        method: METHOD.POST
    },
    EXPORT_USER_REVIEW_LIST: {
        api: '/api/v1/adm/user/review/export',
        method: METHOD.POST
    },
    USER_WISHLIST: {
        api: '/api/v1/adm/user/wishlist',
        method: METHOD.GET
    },
    USER_WAITLIST: {
        api: '/api/v1/adm/user/waitlist',
        method: METHOD.GET
    },
    USER_REFERRAL_LIST: {
        api: '/api/v1/adm/user/referral',
        method: METHOD.GET
    },
    EXPORT_USER_REFERRAL_LIST: {
        api: '/api/v1/adm/user/referral/export',
        method: METHOD.POST
    },
    USER_CREATE_USER: {
        api: '/api/v1/adm/user/create',
        method: METHOD.POST
    },
    USER_UPLOAD_PROFILE_PICTURE: {
        api: '/api/v1/adm/user/upload',
        method: METHOD.POST
    },
    USER_UPDATE_USER: {
        api: '/api/v1/adm/user/update',
        method: METHOD.POST
    },
    CATEGORY_LIST: {
        api: '/api/v1/adm/product/category/list',
        method: METHOD.GET
    },
    EXPORT_CATEGORY_LIST: {
        api: '/api/v1/adm/product/category/list/export',
        method: METHOD.POST
    },
    PARENT_CATEGORY_LIST: {
        api: '/api/v1/master/categories',
        method: METHOD.GET
    },
    CREATE_CATEGORY: {
        api: '/api/v1/adm/product/category/create',
        method: METHOD.POST
    },
    UPDATE_CATEGORY: {
        api: '/api/v1/adm/product/category/update',
        method: METHOD.POST
    },
    PRODUCT_LIST: {
        api: '/api/v1/adm/product/list',
        method: METHOD.GET
    },
    EXPORT_PRODUCT_LIST: {
        api: '/api/v1/adm/product/list/export',
        method: METHOD.POST
    },
    PRODUCT_DETAIL: {
        api: '/api/v1/adm/product/details',
        method: METHOD.GET
    },
    CATEGORIES: {
        api: '/api/v1/master/categories',
        method: METHOD.GET
    },
    PRODUCT_UPDATE_STATUS: {
        api: '/api/v1/adm/product/bulk-action',
        method: METHOD.POST
    },
    SYNC_PRODUCTS: {
        api: '/api/v1/adm/product/sync',
        method: METHOD.POST
    },
    DELETE_CATEGORY: {
        api: '/api/v1/adm/product/category/delete',
        method: METHOD.POST
    },
    PRODUCT_CREATE_PRODUCT: {
        api: '/api/v1/adm/product/create',
        method: METHOD.POST
    },
    PRODUCT_SAVE_AS_DRAFT_PRODUCT: {
        api: '/api/v1/adm/product/save-as-draft',
        method: METHOD.POST
    },
    PRODUCT_UPDATE_PRODUCT: {
        api: '/api/v1/adm/product/update',
        method: METHOD.POST
    },
    PRODUCT_UPLOAD_PRODUCT_PICTURE: {
        api: '/api/v1/adm/product/upload',
        method: METHOD.POST
    },
    VOUCHER_UPLOAD_THUMBNAIL: {
        api: '/api/v1/adm/voucher/upload',
        method: METHOD.POST
    },
    GIFT_LIST: {
        api: '/api/v1/adm/voucher/list-gift',
        method: METHOD.GET
    },
    PRODUCT_LIST_VOUCHER: {
        api: '/api/v1/adm/voucher/list-product',
        method: METHOD.GET
    },
    SHIPPING_METHOD: {
        api: 'api/v1/master/shipping-methods',
        method: METHOD.GET
    },
    PAYMENT_METHOD: {
        api: 'api/v1/master/payment-methods',
        method: METHOD.GET
    },
    ALLOWED_EMAIL: {
        api: 'api/v1/adm/voucher/list-allowed-email',
        method: METHOD.GET
    },
    CREATE_VOUCHER: {
        api: 'api/v1/adm/voucher/create',
        method: METHOD.POST
    },
    DRAFT_VOUCHER: {
        api: 'api/v1/adm/voucher/save-as-draft',
        method: METHOD.POST
    },
    UPDATE_VOUCHER: {
        api: 'api/v1/adm/voucher/update',
        method: METHOD.POST
    },
    VOUCHER_LIST: {
        api: 'api/v1/adm/voucher/list',
        method: METHOD.GET
    },
    EXPORT_VOUCHER_LIST: {
        api: '/api/v1/adm/voucher/list/export',
        method: METHOD.POST
    },
    VOUCHER_DETAIL: {
        api: 'api/v1/adm/voucher/details',
        method: METHOD.GET
    },
    BULK_ACTION_VOUCHER: {
        api: 'api/v1/adm/voucher/bulk-action',
        method: METHOD.POST
    },
    SEARCH_VOUCHER: {
        api: 'api/v1/adm/voucher/list-duplicate',
        method: METHOD.GET
    },
    SEARCH_EMAIL: {
        api: 'api/v1/adm/voucher/list-allowed-email',
        method: METHOD.GET
    },
    GENERATE_VOUCHER: {
        api: 'api/v1/adm/voucher/duplicate',
        method: METHOD.POST
    },
    REVIEW_LIST: {
        api: 'api/v1/adm/review/list',
        method: METHOD.GET
    },
    EXPORT_REVIEW_LIST: {
        api: 'api/v1/adm/review/list/export',
        method: METHOD.POST
    },
    REVIEW_UPDATE_STATUS: {
        api: '/api/v1/adm/review/bulk-delete',
        method: METHOD.POST
    },
    PRODUCT_REVIEW_LIST: {
        api: '/api/v1/adm/review/list-per-product',
        method: METHOD.GET
    },
    EXPORT_PRODUCT_REVIEW_LIST: {
        api: 'api/v1/adm/review/list-per-product/export',
        method: METHOD.POST
    },
    PRODUCT_REVIEW_STATISTIC: {
        api: '/api/v1/adm/review/statistic',
        method: METHOD.GET
    },
    PRODUCT_REVIEW_DETAIL: {
        api: '/api/v1/adm/review/detail',
        method: METHOD.GET
    },
    EXPORT_PRODUCT_REVIEW_DETAIL: {
        api: 'api/v1/adm/review/detail/export',
        method: METHOD.POST
    },
    ORDER_LIST: {
        api: '/api/v1/adm/order/list',
        method: METHOD.POST
    },
    EXPORT_ORDER_LIST: {
        api: '/api/v1/adm/order/list/export',
        method: METHOD.POST
    },
    ORDER_UPDATE_STATUS: {
        api: '/api/v1/adm/order/bulk-update/status',
        method: METHOD.POST
    },
    ORDER_DETAIL: {
        api: '/api/v1/adm/order/detail',
        method: METHOD.GET
    },
    ORDER_DETAIL_UPDATE_STATUS: {
        api: '/api/v1/adm/order/update/status',
        method: METHOD.POST
    },
    ORDER_HISTORY: {
        api: '/api/v1/adm/order/detail-log',
        method: METHOD.GET
    },
    ORDER_TRACKING: {
        api: '/api/v1/adm/order/track',
        method: METHOD.GET
    },
    ANALYTIC_REVENUE_AND_COMPARATION: {
        api: '/api/v1/adm/analytic/revenue/comparation',
        method: METHOD.GET
    },
    ANALYTIC_REVENUE_GRAPH: {
        api: '/api/v1/adm/analytic/revenue/graph',
        method: METHOD.GET
    },
    ANALYTIC_REVENUE_DAILY_DATA: {
        api: '/api/v1/adm/analytic/revenue/list',
        method: METHOD.GET
    },
    EXPORT_REVENUE: {
        api: '/api/v1/adm/analytic/revenue/export',
        method: METHOD.GET
    },
    ANALYTIC_ORDER_AND_COMPARATION: {
        api: '/api/v1/adm/analytic/order/comparation',
        method: METHOD.POST
    },
    ANALYTIC_ORDER_GRAPH: {
        api: '/api/v1/adm/analytic/order/graph',
        method: METHOD.POST
    },
    ANALYTIC_ORDER_DAILY_DATA: {
        api: '/api/v1/adm/analytic/order/list',
        method: METHOD.POST
    },
    EXPORT_ORDER_ANALYTIC: {
        api: '/api/v1/adm/analytic/order/export',
        method: METHOD.POST
    },
    ANALYTIC_PRODUCT_AND_COMPARATION: {
        api: '/api/v1/adm/analytic/product/comparation',
        method: METHOD.GET
    },
    ANALYTIC_PRODUCT_SOLD_GRAPH: {
        api: '/api/v1/adm/analytic/product/graph',
        method: METHOD.GET
    },
    ANALYTIC_PRODUCT_DAILY_DATA: {
        api: '/api/v1/adm/analytic/product/list',
        method: METHOD.GET
    },
    EXPORT_PRODUCT_ANALYTIC: {
        api: '/api/v1/adm/analytic/product/export',
        method: METHOD.GET
    },
    CUSTOMER_LIST: {
        api: '/api/v1/adm/analytic/customer/list',
        method: METHOD.GET
    },
    CUSTOMER_GET_LEVELS_LIST: {
        api: 'api/v1/adm/analytic/customer/level/list',
        method: METHOD.GET
    },
    EXPORT_CUSTOMER: {
        api: '/api/v1/adm/analytic/customer/export',
        method: METHOD.GET
    },
    GIFT_LIST_PAGE: {
        api: '/api/v1/adm/gift/list',
        method: METHOD.GET
    },
    GIFT_UPDATE_STATUS: {
        api: '/api/v1/adm/gift/bulk-action',
        method: METHOD.POST
    },
    EXPORT_GIFT_LIST: {
        api: '/api/v1/adm/gift/list/export',
        method: METHOD.POST
    },
    GIFT_UPLOAD_IMAGE: {
        api: '/api/v1/adm/gift/upload',
        method: METHOD.POST
    },
    GIFT_CREATE_GIFT: {
        api: '/api/v1/adm/gift/create',
        method: METHOD.POST
    },
    GIFT_SAVE_AS_DRAFT_GIFT: {
        api: '/api/v1/adm/gift/save-as-draft',
        method: METHOD.POST
    },
    GIFT_UPDATE_GIFT: {
        api: '/api/v1/adm/gift/update',
        method: METHOD.POST
    },
    GIFT_DETAIL: {
        api: '/api/v1/adm/gift/details',
        method: METHOD.GET
    },
    AVOSTORE_LIST: {
        api: '/api/v1/adm/avostore/list',
        method: METHOD.GET
    },
    AVOSTORE_UPDATE_STATUS: {
        api: '/api/v1/adm/avostore/bulk-action',
        method: METHOD.POST
    },
    AVOSTORE_DETAIL: {
        api: '/api/v1/adm/avostore/details',
        method: METHOD.GET
    },
    EXPORT_AVOSTORE_LIST: {
        api: '/api/v1/adm/avostore/list/export',
        method: METHOD.POST
    },
    AVOSTORE_CREATE_AVOSTORE: {
        api: '/api/v1/adm/avostore/create',
        method: METHOD.POST
    },
    AVOSTORE_UPDATE_AVOSTORE: {
        api: '/api/v1/adm/avostore/update',
        method: METHOD.POST
    },
    AVOSTORE_SAVE_AS_DRAFT_AVOSTORE: {
        api: '/api/v1/adm/avostore/save-as-draft',
        method: METHOD.POST
    },
    AREA_LIST: {
        api: '/api/v1/adm/avostore/area/list',
        method: METHOD.GET
    },
    EXPORT_AREA_LIST: {
        api: '/api/v1/adm/avostore/area/list/export',
        method: METHOD.POST
    },
    CREATE_AREA: {
        api: '/api/v1/adm/avostore/area/create',
        method: METHOD.POST
    },
    UPDATE_AREA: {
        api: '/api/v1/adm/avostore/area/update',
        method: METHOD.POST
    },
    DELETE_AREA: {
        api: '/api/v1/adm/avostore/area/delete',
        method: METHOD.POST
    },
    IMPORT_AVOSTORE: {
        api: '/api/v1/adm/avostore/import',
        method: METHOD.POST
    },
    PAGE_UPLOAD_IMAGE: {
        api: '/api/v1/adm/page/image/upload',
        method: METHOD.POST
    },
    PAGE_GET_PRODUCT_LIST: {
        api: '/api/v1/adm/page/product/list',
        method: METHOD.GET
    },
    HOMEPAGE_GET_SLIDER: {
        api: '/api/v1/adm/page/homepage/slider',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_SLIDER: {
        api: '/api/v1/adm/page/homepage/slider',
        method: METHOD.POST
    },
    HOMEPAGE_GET_FLASH_SALE: {
        api: '/api/v1/adm/page/homepage/flashsale',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_FLASH_SALE: {
        api: '/api/v1/adm/page/homepage/flashsale',
        method: METHOD.POST
    },
    HOMEPAGE_GET_BEST_SELLER: {
        api: '/api/v1/adm/page/homepage/best-seller',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_BEST_SELLER: {
        api: '/api/v1/adm/page/homepage/best-seller',
        method: METHOD.POST
    },
    HOMEPAGE_GET_SHOP_BY_CATEGORY: {
        api: '/api/v1/adm/page/homepage/shop-category',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_SHOP_BY_CATEGORY: {
        api: '/api/v1/adm/page/homepage/shop-category',
        method: METHOD.POST
    },
    HOMEPAGE_GET_SERIES: {
        api: '/api/v1/adm/page/homepage/series',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_SERIES: {
        api: '/api/v1/adm/page/homepage/series',
        method: METHOD.POST
    },
    HOMEPAGE_GET_RECOMMENDATION: {
        api: '/api/v1/adm/page/homepage/recommendation',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_RECOMMENDATION: {
        api: '/api/v1/adm/page/homepage/recommendation',
        method: METHOD.POST
    },
    HOMEPAGE_GET_BENEFIT: {
        api: '/api/v1/adm/page/homepage/benefit',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_BENEFIT: {
        api: '/api/v1/adm/page/homepage/benefit',
        method: METHOD.POST
    },
    HOMEPAGE_GET_SUSTAINABILITY: {
        api: '/api/v1/adm/page/homepage/sustainability',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_SUSTAINABILITY: {
        api: '/api/v1/adm/page/homepage/sustainability',
        method: METHOD.POST
    },
    HOMEPAGE_GET_CSR: {
        api: '/api/v1/adm/page/homepage/csr',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_CSR: {
        api: '/api/v1/adm/page/homepage/csr',
        method: METHOD.POST
    },
    HOMEPAGE_GET_RUBI: {
        api: '/api/v1/adm/page/homepage/rubi',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_RUBI: {
        api: '/api/v1/adm/page/homepage/rubi',
        method: METHOD.POST
    },
    HOMEPAGE_GET_TESTIMONIAL: {
        api: '/api/v1/adm/page/homepage/testimonial',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_TESTIMONIAL: {
        api: '/api/v1/adm/page/homepage/testimonial',
        method: METHOD.POST
    },
    HOMEPAGE_GET_CLAIM: {
        api: '/api/v1/adm/page/homepage/claim',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_CLAIM: {
        api: '/api/v1/adm/page/homepage/claim',
        method: METHOD.POST
    },
    HOMEPAGE_GET_THE_CAROUSEL: {
        api: '/api/v1/adm/page/homepage/blog',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_THE_CAROUSEL: {
        api: '/api/v1/adm/page/homepage/blog',
        method: METHOD.POST
    },
    HOMEPAGE_GET_SEO: {
        api: '/api/v1/adm/page/homepage/seo',
        method: METHOD.GET
    },
    HOMEPAGE_UPDATE_SEO: {
        api: '/api/v1/adm/page/homepage/seo',
        method: METHOD.POST
    },
    W4C_GET_BANNER: {
        api: '/api/v1/adm/page/w4c/banner',
        method: METHOD.GET
    },
    W4C_UPDATE_BANNER: {
        api: '/api/v1/adm/page/w4c/banner',
        method: METHOD.POST
    },
    W4C_GET_DESCRIPTION: {
        api: '/api/v1/adm/page/w4c/description',
        method: METHOD.GET
    },
    W4C_UPDATE_DESCRIPTION: {
        api: '/api/v1/adm/page/w4c/description',
        method: METHOD.POST
    },
    W4C_GET_BENEFIT: {
        api: '/api/v1/adm/page/w4c/benefit',
        method: METHOD.GET
    },
    W4C_UPDATE_BENEFIT: {
        api: '/api/v1/adm/page/w4c/benefit',
        method: METHOD.POST
    },
    W4C_GET_REDEEM: {
        api: '/api/v1/adm/page/w4c/redeem',
        method: METHOD.GET
    },
    W4C_UPDATE_REDEEM: {
        api: '/api/v1/adm/page/w4c/redeem',
        method: METHOD.POST
    },
    W4C_GET_MINI_BANNER: {
        api: '/api/v1/adm/page/w4c/mini-banner',
        method: METHOD.GET
    },
    W4C_UPDATE_MINI_BANNER: {
        api: '/api/v1/adm/page/w4c/mini-banner',
        method: METHOD.POST
    },
    W4C_GET_GIFT_BANNER: {
        api: '/api/v1/adm/page/w4c/gift-banner',
        method: METHOD.GET
    },
    W4C_UPDATE_GIFT_BANNER: {
        api: '/api/v1/adm/page/w4c/gift-banner',
        method: METHOD.POST
    },
    BF_GET_BANNER: {
        api: '/api/v1/adm/page/bfriday/banner',
        method: METHOD.GET
    },
    BF_UPDATE_BANNER: {
        api: '/api/v1/adm/page/bfriday/banner',
        method: METHOD.POST
    },
    BF_GET_POSTER: {
        api: '/api/v1/adm/page/bfriday/poster',
        method: METHOD.GET
    },
    BF_UPDATE_POSTER: {
        api: '/api/v1/adm/page/bfriday/poster',
        method: METHOD.POST
    },
    BF_GET_PROMOTED_PRODUCT: {
        api: '/api/v1/adm/page/bfriday/promoted-product',
        method: METHOD.GET
    },
    BF_UPDATE_PROMOTED_PRODUCT: {
        api: '/api/v1/adm/page/bfriday/promoted-product',
        method: METHOD.POST
    },
    BF_GET_PRODUCT_RECOMMENDATION: {
        api: '/api/v1/adm/page/bfriday/product-recommendation',
        method: METHOD.GET
    },
    BF_UPDATE_PRODUCT_RECOMMENDATION: {
        api: '/api/v1/adm/page/bfriday/product-recommendation',
        method: METHOD.POST
    },
    BF_GET_FLASH_SALE: {
        api: '/api/v1/adm/page/bfriday/flash-sale',
        method: METHOD.GET
    },
    BF_UPDATE_FLASH_SALE: {
        api: '/api/v1/adm/page/bfriday/flash-sale',
        method: METHOD.POST
    },
    PROMO_GET_PROMO: {
        api: '/api/v1/adm/page/promo/main-content',
        method: METHOD.GET
    },
    PROMO_UPDATE_PROMO: {
        api: '/api/v1/adm/page/promo/main-content',
        method: METHOD.POST
    },
    AFFILIATION_GET_AFFILIATION: {
        api: '/api/v1/adm/page/affiliate/main-content',
        method: METHOD.GET
    },
    AFFILIATION_UPDATE_AFFILIATION: {
        api: '/api/v1/adm/page/affiliate/main-content',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_MAIN_BANNER: {
        api: '/api/v1/adm/page/loyalty/main-banner',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_MAIN_BANNER: {
        api: '/api/v1/adm/page/loyalty/main-banner',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_MEMBER_FLOW: {
        api: '/api/v1/adm/page/loyalty/member-flow',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_MEMBER_FLOW: {
        api: '/api/v1/adm/page/loyalty/member-flow',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_BENEFIT: {
        api: '/api/v1/adm/page/loyalty/benefit',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_BENEFIT: {
        api: '/api/v1/adm/page/loyalty/benefit',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_LEVELING: {
        api: '/api/v1/adm/page/loyalty/leveling',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_LEVELING: {
        api: '/api/v1/adm/page/loyalty/leveling',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_JOIN_MEMBER: {
        api: '/api/v1/adm/page/loyalty/join-member',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_JOIN_MEMBER: {
        api: '/api/v1/adm/page/loyalty/join-member',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_FAQ: {
        api: '/api/v1/adm/page/loyalty/faq',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_FAQ: {
        api: '/api/v1/adm/page/loyalty/faq',
        method: METHOD.POST
    },
    LOYALTY_PROGRAM_GET_SEO: {
        api: '/api/v1/adm/page/loyalty/seo',
        method: METHOD.GET
    },
    LOYALTY_PROGRAM_UPDATE_SEO: {
        api: '/api/v1/adm/page/loyalty/seo',
        method: METHOD.POST
    },
    GET_APP_VERSION: {
        api: '/api/v1/adm/app-version',
        method: METHOD.GET
    },
    UPDATE_APP_VERSION: {
        api: '/api/v1/adm/app-version',
        method: METHOD.POST
    },
    EXPORT_TEMPLATE: {
        api: '/api/v1/adm/product/template/export',
        method: METHOD.GET
    },
    EXPORT_AVOSTORE_TEMPLATE: {
        api: '/api/v1/adm/avostore/export',
        method: METHOD.GET
    },
    IMPORT_GET_COLUMN_NAMES: {
        api: '/api/v1/adm/product/columns',
        method: METHOD.GET
    },
    IMPORT_PRODUCT: {
        api: '/api/v1/adm/product/import',
        method: METHOD.POST
    },
    GET_LIST_MODULES: {
        api: '/api/v1/adm/user/roles/modules',
        method: METHOD.GET
    },
    GET_LIST_USER_ROLES: {
        api: '/api/v1/adm/user/roles',
        method: METHOD.GET
    },
    GET_DETAIL_ROLE: {
        api: '/api/v1/adm/user/roles/details',
        method: METHOD.GET
    },
    CREATE_USER_ROLE: {
        api: '/api/v1/adm/user/roles/create',
        method: METHOD.POST
    },
    UPDATE_USER_ROLE: {
        api: '/api/v1/adm/user/roles/update',
        method: METHOD.POST
    },
    DELETE_USER_ROLE: {
        api: '/api/v1/adm/user/roles/delete',
        method: METHOD.POST
    },
    DETAIL_MAINTENANCE_PAGE: {
        api: '/api/v1/adm/maintenance',
        method: METHOD.GET
    },
    UPDATE_MAINTENANCE_PAGE: {
        api: '/api/v1/adm/maintenance',
        method: METHOD.POST
    },
    AVO_STORIES_GET_GENERAL: {
        api: '/api/v1/adm/page/avostories/general',
        method: METHOD.GET
    },
    AVO_STORIES_UPDATE_GENERAL: {
        api: '/api/v1/adm/page/avostories/general',
        method: METHOD.POST
    },
    AVO_STORIES_GET_CTA: {
        api: '/api/v1/adm/page/avostories/cta',
        method: METHOD.GET
    },
    AVO_STORIES_UPDATE_CTA: {
        api: '/api/v1/adm/page/avostories/cta',
        method: METHOD.POST
    },
    AVO_STORIES_GET_TIMELINE: {
        api: '/api/v1/adm/page/avostories/timeline',
        method: METHOD.GET
    },
    AVO_STORIES_UPDATE_TIMELINE: {
        api: '/api/v1/adm/page/avostories/timeline',
        method: METHOD.POST
    },
    AVO_STORIES_GET_RECYCLING_PROGRESS: {
        api: '/api/v1/adm/page/avostories/recycling-progress',
        method: METHOD.GET
    },
    AVO_STORIES_UPDATE_RECYCLING_PROGRESS: {
        api: '/api/v1/adm/page/avostories/recycling-progress',
        method: METHOD.POST
    },
    AVO_STORIES_GET_CAMPAIGN_BANNER: {
        api: '/api/v1/adm/page/avostories/campaign-banner',
        method: METHOD.GET
    },
    AVO_STORIES_UPDATE_CAMPAIGN_BANNER: {
        api: '/api/v1/adm/page/avostories/campaign-banner',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_MAIN_BANNER: {
        api: '/api/v1/adm/page/avostories-2/main-banner',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_MAIN_BANNER: {
        api: '/api/v1/adm/page/avostories-2/main-banner',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_SUSTAINABILITY_PROGRESS: {
        api: '/api/v1/adm/page/avostories-2/sustainability-progress',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_SUSTAINABILITY_PROGRESS: {
        api: '/api/v1/adm/page/avostories-2/sustainability-progress',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_CAMPAIGN_VIDEO: {
        api: '/api/v1/adm/page/avostories-2/campaign-video',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_CAMPAIGN_VIDEO: {
        api: '/api/v1/adm/page/avostories-2/campaign-video',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_CAMPAIGN_BANNER: {
        api: '/api/v1/adm/page/avostories-2/campaign-banner',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_CAMPAIGN_BANNER: {
        api: '/api/v1/adm/page/avostories-2/campaign-banner',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_FORM: {
        api: '/api/v1/adm/page/avostories-2/form',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_FORM: {
        api: '/api/v1/adm/page/avostories-2/form',
        method: METHOD.POST
    },
    AVO_STORIES_2_GET_ARTICLES: {
        api: '/api/v1/adm/page/avostories-2/articles',
        method: METHOD.GET
    },
    AVO_STORIES_2_UPDATE_ARTICLES: {
        api: '/api/v1/adm/page/avostories-2/articles',
        method: METHOD.POST
    },
    AVO_STORIES_USER_LIST: {
        api: '/api/v1/adm/customer-lead/list',
        method: METHOD.POST
    },
    EXPORT_AVO_STORIES_USER_LIST: {
        api: '/api/v1/adm/customer-lead/export',
        method: METHOD.POST
    },
    NOTIFICATION_LIST: {
        api: '/api/v1/adm/notification/list',
        method: METHOD.GET
    },
    NOTIFICATION_UPLOAD_IMAGE: {
        api: '/api/v1/adm/notification/upload',
        method: METHOD.POST
    },
    NOTIFICATION_PRODUCT_LIST: {
        api: '/api/v1/adm/notification/list-product',
        method: METHOD.GET
    },
    NOTIFICATION_SEARCH_EMAIL: {
        api: 'api/v1/adm/notification/list-email',
        method: METHOD.GET
    },
    CREATE_NOTIFICATION: {
        api: 'api/v1/adm/notification/create',
        method: METHOD.POST
    },
    SAVE_AS_DRAFT_NOTIFICATION: {
        api: 'api/v1/adm/notification/save-as-draft',
        method: METHOD.POST
    },
    NOTIFICATION_DETAIL: {
        api: '/api/v1/adm/notification/detail',
        method: METHOD.GET
    },
    UPDATE_NOTIFICATION: {
        api: 'api/v1/adm/notification/update',
        method: METHOD.POST
    },
    DELETE_NOTIFICATION: {
        api: 'api/v1/adm/notification/delete',
        method: METHOD.POST
    },
    EXPORT_NOTIFICATION_LIST: {
        api: 'api/v1/adm/notification/list/export',
        method: METHOD.POST
    },
    ORDER_PRODUCT_LIST: {
        api: 'api/v1/adm/order/list-product',
        method: METHOD.GET
    },
    ORDER_VOUCHER_LIST: {
        api: 'api/v1/adm/order/list-voucher',
        method: METHOD.GET
    },
    ANALYTIC_VOUCHER_AND_COMPARISON: {
        api: '/api/v1/adm/analytic/voucher/comparison',
        method: METHOD.GET
    },
    ANALYTIC_VOUCHER_ORDERS_GRAPH: {
        api: '/api/v1/adm/analytic/voucher/graph',
        method: METHOD.GET
    },
    ANALYTIC_ORDERS_VOUCHER_LIST: {
        api: '/api/v1/adm/analytic/voucher/list',
        method: METHOD.GET
    },
    EXPORT_VOUCHER_ANALYTIC: {
        api: '/api/v1/adm/analytic/voucher/list/export',
        method: METHOD.POST
    },
    AFFILIATE_LIST: {
        api: '/api/v1/adm/affiliate/list',
        method: METHOD.GET
    },
    EXPORT_AFFILIATE_LIST: {
        api: '/api/v1/adm/affiliate/export',
        method: METHOD.POST
    },
    AFFILIATE_PERFORMANCE_LIST: {
        api: '/api/v1/adm/affiliate/performance/list',
        method: METHOD.GET
    },
    EXPORT_AFFILIATE_PERFORMANCE_LIST: {
        api: '/api/v1/adm/affiliate/performance/export',
        method: METHOD.POST
    },
    CREATE_AFFILIATE: {
        api: '/api/v1/adm/affiliate/create',
        method: METHOD.POST
    },
    UPDATE_AFFILIATE: {
        api: '/api/v1/adm/affiliate/update',
        method: METHOD.POST
    },
    GET_AFFILIATOR_LIST: {
        api: 'api/v1/adm/affiliate',
        method: METHOD.GET
    },
    GET_LEVELS_LIST: {
        api: 'api/v1/adm/level/list',
        method: METHOD.GET
    },
    CREATE_UPDATE_LEVEL_AND_BENEFIT: {
        api: '/api/v1/adm/level/upsert',
        method: METHOD.POST
    },
    CHECK_DELETE_LEVEL_AND_BENEFIT: {
        api: '/api/v1/adm/level/check-delete',
        method: METHOD.GET
    },
    GET_LEVEL_BIRTHDAY_GIFT_LIST: {
        api: 'api/v1/adm/birthday-gift/list',
        method: METHOD.GET
    },
    GET_DETAIL_BIRTHDAY_GIFT: {
        api: 'api/v1/adm/birthday-gift/detail',
        method: METHOD.GET
    },
    UPSERT_BIRTHDAY_GIFT: {
        api: 'api/v1/adm/birthday-gift/upsert',
        method: METHOD.POST
    },
    BEAUTY_EMPTIES_LIST: {
        api: '/api/v1/adm/beauty-empties/list',
        method: METHOD.GET
    },
    BEAUTY_EMPTIES_UPDATE_STATUS: {
        api: '/api/v1/adm/beauty-empties/update-status',
        method: METHOD.POST
    },
    EXPORT_BEAUTY_EMPTIES: {
        api: '/api/v1/adm/beauty-empties/export',
        method: METHOD.POST
    },
    GET_PROOF_BEAUTY_EMPTIES: {
        api: '/api/v1/adm/beauty-empties/proofs',
        method: METHOD.GET
    },
    BEAUTY_EMPTIES_DETAIL: {
        api: '/api/v1/adm/beauty-empties/detail',
        method: METHOD.GET
    },
    BEAUTY_EMPTIES_PRODUCT_LIST: {
        api: '/api/v1/adm/beauty-empties/product',
        method: METHOD.GET
    },
    EXPORT_BEAUTY_EMPTIES_PRODUCT_LIST: {
        api: '/api/v1/adm/beauty-empties/product/export',
        method: METHOD.POST
    },
    MORE_INFO_GET_TERMS_AND_CONDITION: {
        api: '/api/v1/adm/page/terms-and-conditions/main-content',
        method: METHOD.GET
    },
    MORE_INFO_UPDATE_TERMS_AND_CONDITION: {
        api: '/api/v1/adm/page/terms-and-conditions/main-content',
        method: METHOD.POST
    },
    ARTICLES_LIST: {
        api: '/api/v1/adm/page/article/list',
        method: METHOD.GET
    },
    PUBLISH_ARTICLES: {
        api: '/api/v1/adm/page/article/publish',
        method: METHOD.POST
    },
    CRM_SETTINGS_GET_HEADER_SLIDING_TEXT: {
        api: '/api/v1/adm/crm/sliding-text',
        method: METHOD.GET
    },
    CRM_SETTINGS_UPDATE_HEADER_SLIDING_TEXT: {
        api: '/api/v1/adm/crm/sliding-text',
        method: METHOD.POST
    },
}
