import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_GENERAL_AVO_STORIES} from "../../utils/input-field-list-static-page";
import FormGeneralComponent from "../../components/static-page/form-general.component";
import React, {useEffect} from "react";
import {setAvoStoriesErrorMessage, setAvoStoriesGeneralDetail} from "../../reducers/avo-stories.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";

const General = () => {
    const dispatch = useDispatch()
    const {generalDetail, avoStoriesErrorMessage} = useSelector(state => state.avoStoriesReducer)
    const {fetching: getGeneral} = useFetch(API.AVO_STORIES_GET_GENERAL)
    const {fetching: updateGeneral, isLoading} = useFetch(API.AVO_STORIES_UPDATE_GENERAL)
    let fieldList = FIELD_LIST_GENERAL_AVO_STORIES
    let showGeneral = {
        label: 'General',
        key: 'show_general',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStoriesGeneralDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateGeneral(generalDetail).then(response => {
            dispatch(setAvoStoriesErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('General Updated'))
            dispatch(setAvoStoriesGeneralDetail(response?.data))
        }).catch(err => {
            dispatch(setAvoStoriesErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getGeneral().then(response => {
            dispatch(setAvoStoriesGeneralDetail(response?.data))
        })

        return () => {
            dispatch(setAvoStoriesErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={generalDetail}
            switchToggleField={showGeneral}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={avoStoriesErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default General