import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import React, {useEffect} from "react";
import {setToolsInitialState} from "../../../reducers/tools.reducer";
import {setUserReferralSelectedRowKeys} from "../../../reducers/user.reducer";
import {Filter} from "./filter";
import {ReferralList} from "./referral-list";

const TabReferral = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {currentPage, pageSize, searchKey} = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        search: searchKey,
    }
    const {data: response, fetching: getReferralList, isLoading} = useFetch({...API.USER_REFERRAL_LIST, ...{initPathVariable: id}}, queryParams, {isWithTotalData: true})

    useEffect(() => {
        getReferralList()
    }, [currentPage, pageSize, searchKey, id])

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setUserReferralSelectedRowKeys([]))
        }
    }, [])

    return (
        <div className={"px-10 space-y-5"}>
            <Filter/>
            <ReferralList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default TabReferral
