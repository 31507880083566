import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_YOUR_SKIN_BAE_LIST} from "../../utils/sidebar-section-list";
import Personalize from "./personalize";

const YourSkinBae = () => {
    const [activeSidebar, setActiveSidebar] = useState('personalize')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        personalize: <Personalize/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'your_skin_bae', list: SECTION_YOUR_SKIN_BAE_LIST}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default YourSkinBae