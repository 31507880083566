import React, {useEffect} from "react";
import {AddressItem} from "./address-item";
import {ADDRESS_ITEM_LIST_lEFT, ADDRESS_ITEM_LIST_RIGHT} from "../../../utils/constant";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setUserAddressList} from "../../../reducers/user.reducer";
import {LoadingSvg} from "../../../utils/image";
import EditAddressModal from "./edit-address.modal";
import {setToolsLoading} from "../../../reducers/tools.reducer";

const TabAddress = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {userAddressList} = useSelector(state => state.userReducer)
    const {loading, reload} = useSelector(state => state.toolsReducer)
    const {fetching: getAddressList} = useFetch(API.USER_ADDRESS_LIST)

    useEffect(() => {
        dispatch(setToolsLoading(true))
        getAddressList({}, Number(id)).then(response => {
            dispatch(setUserAddressList(response.data))
            dispatch(setToolsLoading(response.isLoading))
        })
    }, [reload, id])

    return (
        <div className={"px-10"}>
            <div className={"space-y-5"}>
                {loading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    userAddressList.map((item, index) => (
                        <AddressItem key={index} form={[ADDRESS_ITEM_LIST_lEFT, ADDRESS_ITEM_LIST_RIGHT]} data={item}/>
                    ))
                }
            </div>
            <EditAddressModal/>
        </div>
    )
}

export default TabAddress
