import {createSlice} from '@reduxjs/toolkit'
import {giftModel} from "../models/gift.model";

export const giftSlice = createSlice({
    name: 'gift',
    initialState: {
        selectedRowKeysGift: [],
        giftDetail: {...giftModel},
        giftErrorMessage: {}
    },
    reducers: {
        setGiftSelectedRowKeys: (state, action) => {
            state.selectedRowKeysGift = action.payload
        },
        setGiftDetail: (state, action) => {
            state.giftDetail = {...state.giftDetail, ...action.payload}
        },
        setGiftErrorMessage: (state, action) => {
            state.giftErrorMessage = action.payload
        },
        setGiftInitialGiftDetail: (state, action) => {
            state.giftDetail = {...giftModel}
        }
    },
})

export const {
    setGiftSelectedRowKeys,
    setGiftDetail,
    setGiftErrorMessage,
    setGiftInitialGiftDetail
} = giftSlice.actions
const giftReducer = giftSlice.reducer

export default giftReducer