import {ArrowDownIcon} from "../utils/image";
import React, {useEffect, useState} from "react";
import {useClickOutside} from "../services/useClickOutside";

const SelectComponent = (props) => {
    const {item, onChangeSelect, defaultValue} = props
    const [isShowModal, setIsShowModal] = useState(false)
    const [dataSelected, setDataSelected] = useState('')
    const list = item?.options

    const showHideModal = () => {
        setIsShowModal(!isShowModal)
    }

    const onChangeData = (dataItem) => {
        setDataSelected(dataItem)
        !!onChangeSelect && onChangeSelect(dataItem, item.key)
        showHideModal()
    }

    useEffect(() => {
        if(!!defaultValue){
            setDataSelected(defaultValue)
        } else {
            setDataSelected('')
        }
    }, [defaultValue])

    useClickOutside(`select-component-${item.key}`, isShowModal, setIsShowModal)

    return (
        <div id={`select-component-${item.key}`} className={"relative col font-regular text-forged-steel"}>
            <div onClick={showHideModal}
                 className={"row items-center justify-between w-full px-3 py-2 rounded-lg border border-placebo bg-white cursor-pointer"}>
                <p className={`${dataSelected[item.selectLabel] || defaultValue?.[item.selectLabel] ? 'text-forged-steel' : 'italic text-fortress-grey'} pr-1`}>
                    {dataSelected[item.selectLabel] || defaultValue?.[item.selectLabel] || item?.placeholder}
                </p>
                <img src={ArrowDownIcon} alt="down" className={"w-2.5 h-5"}/>
                <input name={item.key} type={"text"} className={'absolute top-7 left-0 invisible'}
                       value={dataSelected[item.selectValue || item.selectLabel] || defaultValue?.[item.selectValue || item.selectLabel] || ''} readOnly/>
            </div>
            <div className={`${isShowModal ? 'block' : 'hidden'} absolute top-8 w-full z-10`}>
                <div
                    className={"col bg-white border border-placebo mb-5 max-h-32 overflow-scroll"}>
                    {list?.length > 0 ? list?.map((l, index) => (
                        <div key={index}
                             className={"row p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => onChangeData(l)}>
                            <p>{l[item.selectLabel]}</p>
                        </div>
                    )) : <p className={"p-3 text-center"}>Not Found</p>}
                </div>
            </div>
        </div>
    )
}

export default SelectComponent
