export const productModel = {
    name: null,
    sku: null,
    gs1_barcode: null,
    price: null,
    sale_price: null,
    sale_start: null,
    sale_end: null,
    stock_quantity: null,
    weight: null,
    height: null,
    length: null,
    width: null,
    out_of_stock_label: null,
    limit_purchase: null,
    slug: null,
    meta_title: null,
    meta_description: null,
    description_en: null,
    how_to_use_en: null,
    for_who_en: null,
    ingredient_en: null,
    banner_url_en: [],
    description_id: null,
    how_to_use_id: null,
    for_who_id: null,
    ingredient_id: null,
    banner_url_id: [],
    categories: [],
    another_images: [],
    main_image: {},
    available_for_beauty_empties: false
}
