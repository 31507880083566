import React from "react";

const ProgressBarComponent = ({percentage}) => {
    const stylePercentage = () => {
        switch (percentage){
            case 20:
                return 'w-[20%]'
            case 40:
                return 'w-[40%]'
            case 60:
                return 'w-[60%]'
            case 80:
                return 'w-[80%]'
            case 100:
                return 'w-[100%]'
            default:
                return 'w-[0%]'
        }
    }

    return (
        <div className={"col space-y-4 border-t border-whisper-green p-6"}>
            <p className={"text-monstera text-lg"}>Progress In {percentage} %</p>
            <div className={"bg-orochimaru w-full h-10 rounded-lg"}>
                <div className={`bg-monstera h-full rounded-lg ${stylePercentage()}`}></div>
            </div>
        </div>
    )
}

export default ProgressBarComponent