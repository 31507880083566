import SwitchToggleComponent from "../switch-toggle.component";
import TabToggleListComponent from "../tab-toggle-list.component";
import {LoadingSvg, PlusCircleIconWhite} from "../../utils/image";
import React from "react";
import InputComponent from "../input.component";
import TabToggleComponent from "../tab-toggle.component";

const FormItemComponent = (props) => {
    const {
        title,
        data,
        switchToggleField,
        fieldListGeneral,
        itemList,
        content,
        onChange,
        setDataList,
        deleteList,
        objectDeleteName,
        objectName,
        addItemHandler,
        onSubmitHandler,
        inputClassName,
        itemInputClassName,
        errorMessage,
        isLoading
    } = props


    return (
        <form>
            <div className={"row justify-between items-center bg-doctor p-4 rounded-md border border-fennel-stem"}>
                <p className={"text-sm text-monstera font-Montserrat-Bold"}>{switchToggleField.label}</p>
                <SwitchToggleComponent item={switchToggleField} defaultValue={data[switchToggleField?.key]} onChangeSwitchToggle={onChange}/>
            </div>
            <div className={"col space-y-4 pt-4"}>
                {fieldListGeneral?.length > 0 &&
                    <TabToggleComponent
                        title={title || 'Input Field'}
                        content={
                            <div className={inputClassName}>
                                {fieldListGeneral.map((item, index) => (
                                    <div key={index}
                                         className={item.key === itemInputClassName?.key ? itemInputClassName?.value[0] : itemInputClassName?.value[1]}>
                                        <InputComponent item={item} data={data[item.key]} onChange={onChange}
                                                        onChangeInput={onChange} error={errorMessage}/>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                }
                {
                    itemList.map((item, index) =>
                        <TabToggleListComponent
                            key={item.id}
                            index={index}
                            title={`${title || 'Item'} ${index + 1}`}
                            content={content(index)}
                            dataList={itemList}
                            setDataList={setDataList}
                            deleteList={deleteList}
                            objectDeleteName={objectDeleteName}
                            objectName={objectName}
                        />
                    )
                }
                <div
                    className={"row space-x-2 items-center w-fit bg-battleship-green hover:bg-monstera py-2 px-6 rounded-lg cursor-pointer"}
                    onClick={addItemHandler}>
                    <img src={PlusCircleIconWhite} alt="PlusCircleIconWhite" className={"w-5"}/>
                    <p className={"font-semibold text-xs text-white"}>Add New Item</p>
                </div>
            </div>
            <div className={"row font-semibold justify-center py-8"}>
                <button type={"button"} onClick={(e) => onSubmitHandler(e)}
                        className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                    {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                </button>
            </div>
        </form>
    )
}

export default FormItemComponent
