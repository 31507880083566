import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import {Link} from "react-router-dom";
import React from "react";
import RowExpandable from "./row-expandable";
import {LoadingSvg} from "../../utils/image";

const BirthdayGiftList = ({dataSource, isLoading}) => {

    const bodyComponent = (item, index) => {
        if (item?.gifts?.length > 1) {
            return (
                <RowExpandable key={index} item={item}/>
            )
        } else {
            return (
                <tr key={index} className={"h-10"}>
                    <td className={"pl-4 w-1/4"}>
                        <Link to={`/loyalty-program/birthday-gift/create-birthday-gift/${item.id}`}>
                            <p className={"text-dazzle capitalize hover:font-Montserrat-Bold hover:underline"}>{item.name}</p>
                        </Link>
                    </td>
                    <td>{!!item.gifts?.length && `[Gift] - ${item.gifts[0]?.gift_name}`}</td>
                    <td className={"text-center"}>{!!item.gifts?.length && doConvertNumberToRupiahFormat(item.gifts[0]?.discount_amount)}</td>
                </tr>
            )
        }
    }

    return (
        <>
            <table className="table-auto list">
                <thead className={"bg-windmill-wings font-semibold text-left text-monstera h-8"}>
                <tr>
                    <th className={"text-center"}>Level</th>
                    <th className={"text-center"}>Product Gift</th>
                    <th className={"text-center"}>Potongan Diskon</th>
                </tr>
                </thead>
                <tbody>
                {
                    dataSource?.map((item, index) => (
                        bodyComponent(item, index)
                    ))
                }
                </tbody>
            </table>
            {isLoading ?
                <div className={"w-full row justify-center mt-5"}>
                    <img src={LoadingSvg} className={"w-12"} alt={"loadingIcon"}/>
                </div> :
                dataSource.length === 0 &&
                    <div
                        className={"row justify-center mt-20 items-center font-regular"}>No
                        data
                    </div>
            }
        </>
    )
}

export default BirthdayGiftList
