import {CloseIcon} from "../../utils/image";
import ModalCardComponent from "../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetail, setProductModalVisibility} from "../../reducers/product.reducer";
import InputComponent from "../../components/input.component";
import React, {useRef, useState} from "react";
import {FIELD_LIST_UPLOAD_PRODUCT_IMAGE} from "../../utils/input-field-list";
import {checkFieldValidate} from "../../utils/general-variable";
import UploadImageComponent from "../../components/upload-image.component";

const UploadImageForm = () => {
    const dispatch = useDispatch()
    const urlRef = useRef(null)
    const refForm = useRef(null)
    const {uploadImage} = useSelector(state => state.productReducer.modalVisibility)
    const {productDetail, uploadFormImageType} = useSelector(state => state.productReducer)
    const [fieldList, setFieldList] = useState(FIELD_LIST_UPLOAD_PRODUCT_IMAGE)
    const [clearImage, setClearImage] = useState(false)
    const uploadImageField = {
        key: 'upload_product_picture',
        type: 'file_image',
        showAbove: true,
        title: 'Foto Produk'
    }

    const closeModalHandler = () => {
        refForm.current.reset()
        setClearImage(!clearImage)
        setFieldList(fieldList.map(i => {
            return {...i, errorText: null}
        }))
        dispatch(setProductModalVisibility({uploadImage: false}))
    }

    const onChangeInput = (key, value, type) => {
        if (type === 'upload') {
            urlRef.current.value = value
        } else {
            urlRef.current.value = ''
        }
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const formType = uploadFormImageType
        const uploadFormData = {
            title: elements.title.value,
            caption: elements.caption.value || null,
            url: elements.url.value,
            description: elements.description.value || null,
            alt_text: elements.alt_text.value || null
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            const dataImage = formType === 'another_images' ? [...productDetail.another_images, uploadFormData] : uploadFormData
            dispatch(setProductDetail({[formType]: dataImage}))
            closeModalHandler()
        }
    }

    return (
        <ModalCardComponent
            visibility={uploadImage}
            onClose={closeModalHandler}
            content={
                <div className={"col justify-center w-1/2 p-5 bg-white rounded-xl relative"}>
                    <p className={"font-Montserrat-Bold text-monstera text-center text-sm"}>Upload Product Picture</p>
                    <form onSubmit={onSubmitHandler} className={"space-y-7"} ref={refForm}>
                        <div className={"col items-center space-y-2"}>
                            <UploadImageComponent item={uploadImageField} onChangeUploadImage={onChangeInput}
                                                  clearImage={clearImage}/>
                            {fieldList[2].errorText && <p className={"font-semibold text-red-600 italic"}>{fieldList[2].errorText?.replace('Url', uploadImageField?.title)}</p>}
                        </div>
                        <div className={"row space-x-16"}>
                            <div className={"col space-y-4 w-1/2"}>
                                {fieldList.slice(0, 3).map((item, index) => (
                                    <div key={index}>
                                        <InputComponent item={item} inputRef={item.key === 'url' ? urlRef : null}
                                                        disabled={item.key === 'url'}
                                                        error={{[item.key]: item.key === 'url' ? '' : item.errorText}}/>
                                    </div>
                                ))}
                            </div>
                            <div className={"col space-y-4 w-1/2"}>
                                {fieldList.slice(3).map((item, index) => (
                                    <div key={index}>
                                        <InputComponent item={item} error={{[item.key]: item.errorText}}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"row justify-center"}>
                            <button
                                className={"rounded-md shadow-inner bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-Montserrat-Bold px-10 py-3"}>
                                Simpan
                            </button>
                        </div>
                    </form>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModalHandler}/>
                </div>
            }
        />
    )
}

export default UploadImageForm
