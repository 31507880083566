import React, {useEffect, useRef, useState} from "react";
import {SearchIcon} from "../utils/image";
import {getImageLocal} from "../utils/general-variable";
import {useSelector} from "react-redux";

const CheckboxListFilterComponent = (props) => {
    const {item, data, onChange} = props
    const searchData = useRef()
    const {modalVisibility} = useSelector(state => state.toolsReducer)
    const [list, setList] = useState([])
    const [checkData, setCheckData] = useState(data)

    const searchListOption = (event) => {
        const keyword = event.target.value
        const filterList = item?.dataList.filter(list => list[item.selectValue].toLowerCase().includes(keyword.toLowerCase()))
        setList(filterList)
    }

    const setCheck = (value, data) => {
        let checkList = []
        if (value) {
            checkList = [...checkData, data[item.selectValue]]
        } else {
            checkList = checkData.filter(i => i !== data[item.selectValue])
        }
        setCheckData(checkList)
        onChange(item.key, checkList)
    }

    useEffect(() => {
        setList(item?.dataList)
    }, [item.dataList])

    useEffect(() => {
        setCheckData(data)
    }, [data])

    useEffect(() => {
        searchData.current.value = ''
        setList(item?.dataList)
    }, [modalVisibility.filter])

    return (
        <div className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${item?.dataList.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'} ref={searchData}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"} onChange={searchListOption}/>
            </div>
            <div
                className={"col space-y-2 bg-white border-x border-b rounded-b-lg border-placebo max-h-24 overflow-scroll"}>
                {list.length > 0 ? list.map((l, index) => (
                    <div key={index}
                         className={"row space-x-5 px-5 py-2 w-full hover:bg-apple-flower hover:text-monstera"}>
                        <input id={`${l.id + item.key}`} type="checkbox" className={`accent-monstera`}
                               value={l[item.selectValue]} name={l.key}
                               checked={checkData.includes(l[item.selectValue])}
                               onChange={(e) => setCheck(e.target.checked, l)}/>
                        <label htmlFor={`${l.id + item.key}`}
                               className={"row space-x-3 items-center justify-center text-forged-steel font-regular"}>
                            {!(item.key === 'status') &&
                                <img src={getImageLocal(l[item.selectValue])} alt="imageDataOption" className={"w-8"}/>}
                            <p>{l[item.selectLabel]}</p>
                        </label>
                    </div>)
                ) : <p className={"px-3 py-3"}>Not Found</p>}
            </div>
        </div>
    )
}

export default CheckboxListFilterComponent
