import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setAboutUsArticlesDetail} from "../../../reducers/about-us.reducer";

const ItemArticles = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {articlesDetail, aboutUsErrorMessage} = useSelector(state => state.aboutUsReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(articlesDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setAboutUsArticlesDetail({item_list: copyItemList}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                <InputComponent item={fieldList[0]} data={articlesDetail?.item_list?.[index]?.[fieldList[0].key]} error={aboutUsErrorMessage} onChange={onChange}/>
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, i) => (
                    <InputComponent key={i} item={item} data={articlesDetail?.item_list?.[index]?.[item.key]} error={aboutUsErrorMessage} onChangeInput={onChange} onChangeDate={onChange}/>
                ))}
            </div>
        </div>
    )
}

export default ItemArticles