import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetail} from "../../reducers/product.reducer";

const AvailableBeautyEmptiesForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {productDetail, productErrorMessage} = useSelector(state => state.productReducer)

    const onChangeInput = (key, value) => {
        dispatch(setProductDetail({[key]: value}))
    }

    return (
        <InputComponent item={fieldList[0]} data={productDetail[fieldList[0]?.key].toString()}
                        onChange={onChangeInput} error={productErrorMessage}/>
    )
}

export default AvailableBeautyEmptiesForm
