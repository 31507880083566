import TabToggleComponent from "../../components/tab-toggle.component";
import TooltipComponent from "../../components/tooltip.component";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    formatDateToReadable,
    textPercentage
} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React from "react";
import {useSelector} from "react-redux";
import {REVENUE_AND_COMPARATION} from "../../utils/constant";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabRevenueAndComparation = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const queryParams = {
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
    }
    const {
        data: response,
        fetching: getRevenueAndComparation,
        isLoading
    } = useFetch(API.ANALYTIC_REVENUE_AND_COMPARATION, queryParams)

    useEffectAfterMount(() => {
        getRevenueAndComparation()
    }, [dateRange, reload])

    return (
        <TabToggleComponent
            title={'Revenue & Comparation'}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <table className={"w-full border-collapse analytics-table"}>
                        <thead className={"bg-quiet-whisper h-16"}>
                        <tr>
                            <th className={"px-5 border-r border-fennel-stem"}>Attribute</th>
                            <th className={"px-5 border-r border-fennel-stem"}>
                                <div className={"row items-center justify-between"}>
                                    <div className={"col space-y-1 text-left font-Montserrat-Bold"}>
                                        <p>Periode</p>
                                        <p>Saat Ini</p>
                                    </div>
                                    <div className={"row items-center space-x-4"}>
                                        <p>{convertDateToCustomFormat(response?.data?.current_period?.start_date, 'DD MMM YY')} - {convertDateToCustomFormat(response?.data?.current_period?.end_date, 'DD MMM YY')}</p>
                                        <TooltipComponent iconType={"question"}/>
                                    </div>
                                </div>
                            </th>
                            <th className={"px-5 border-r border-fennel-stem"}>
                                <div className={"row items-center justify-between"}>
                                    <div className={"col space-y-1 text-left font-Montserrat-Bold"}>
                                        <p>Periode</p>
                                        <p>Sebelumnya</p>
                                    </div>
                                    <div className={"row items-center space-x-4"}>
                                        <p>{convertDateToCustomFormat(response?.data?.previous_period?.start_date, 'DD MMM YY')} - {convertDateToCustomFormat(response?.data?.previous_period?.end_date, 'DD MMM YY')}</p>
                                        <TooltipComponent iconType={"question"}/>
                                    </div>
                                </div>
                            </th>
                            <th className={"px-5"}>
                                <div className={"row items-center justify-between"}>
                                    <p className={"font-Montserrat-Bold"}>Persentase</p>
                                    <TooltipComponent iconType={"question"}/>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {REVENUE_AND_COMPARATION.map((list, index) => (
                            <tr key={index}>
                                <td className={"text-center p-2 border border-fennel-stem font-Montserrat-Bold"}>
                                    {list.attribute}
                                </td>
                                <td className={`text-right text-forged-steel pr-12 border border-fennel-stem 
                            ${list.attribute === 'Net Sales' && 'font-Montserrat-Bold'}`}>
                                    {doConvertNumberToRupiahFormat(response?.data?.current_period[list.key])}
                                </td>
                                <td className={`text-right text-forged-steel pr-12 border border-fennel-stem
                            ${list.attribute === 'Net Sales' && 'font-Montserrat-Bold'}`}>
                                    {doConvertNumberToRupiahFormat((response?.data?.previous_period[list.key]))}
                                </td>
                                <td className={"text-right text-forged-steel pr-8 border border-fennel-stem"}>
                                    <p className={`${list.attribute === 'Total Sales' ? 'font-Montserrat-Bold' : 'font-semibold'} 
                                ${textPercentage(response?.data?.percentage[list.key]?.state, response?.data?.percentage[list.key]?.value).className}`}>
                                        {list.attribute === 'Total Sales' && 'Total '}
                                        {textPercentage(response?.data?.percentage[list.key]?.state, response?.data?.percentage[list.key]?.value).text}
                                    </p>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
            }
        />
    )
}

export default TabRevenueAndComparation