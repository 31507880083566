import React from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash'
import {setSelectedRoleIds} from "../../reducers/roles.reducer";

export const AccessItem = ({item}) => {
    const dispatch = useDispatch()
    const {selectedIds} = useSelector(state => state.roleReducer)

    const checkedHandler = (e) => {
        let newSelectedIds = []
        let findIndex = _.findIndex(selectedIds, s => s === Number(e.target.name))
        if (findIndex === -1 && !isNaN(e.target.name)) {
            newSelectedIds = [...selectedIds, Number(e.target.name)]
        } else {
            newSelectedIds = _.filter(selectedIds, s => s !== Number(e.target.name))
        }
        dispatch(setSelectedRoleIds(newSelectedIds))
    }

    return (
        <label
            className={`row items-center space-x-5 border border-pinball h-8 rounded-md px-3 font-semibold text-forged-steel ${item?.isDisabled ? 'bg-gray-200 opacity-80 cursor-not-allowed' : 'hover:border-monstera hover:bg-doctor hover:font-Montserrat-Bold hover:text-monstera cursor-pointer'}`}>
            <input type={'checkbox'} name={item?.id} className={"accent-monstera"} checked={_.findIndex(selectedIds, s => s === item?.id) !== -1}
                   onChange={checkedHandler}/>
            <p className={"capitalize"}>{item?.name}</p>
        </label>
    )
}
