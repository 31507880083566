import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload,
} from "../../reducers/tools.reducer";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment/moment";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import {SORT_LIST_BEAUTY_EMPTIES} from "../../utils/sort-list";
import FilterComponent from "../../components/filter.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST, FILTER_LIST_BEAUTY_EMPTIES} from "../../utils/filter-list";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import {STATE_LIST_BEAUTY_EMPTIES} from "../../utils/state-list";
import React from "react";
import {setBeautyEmptiesSelectedRowKeys} from "../../reducers/beauty-empties.reducer";

export const BeautyEmptiesFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysBeautyEmpties} = useSelector(state => state.beautyEmptiesReducer)
    const {fetching: postUpdateStatus, isLoading: loadingUpdateState} = useFetch(API.BEAUTY_EMPTIES_UPDATE_STATUS)
    const {fetching: exportBeautyEmptiesList, isLoading: loadingExport} = useFetch(API.EXPORT_BEAUTY_EMPTIES)
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const applyStatusHandler = (listId, status) => {
        const body = {
            beauty_empties_ids: listId,
            status
        }
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Beauty Empties State Updated'))
            dispatch(setToolsReload(!reload))
        }).catch(err => {
            if (err?.code === '400-M007') {
                dispatch(setToolsAlertMessage('Tidak dapat mengubah status'))
            } else {
                dispatch(setToolsAlertMessage('Terjadi kesalahan'))
            }
        })
        dispatch(setBeautyEmptiesSelectedRowKeys([]))
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_status: filter['status'] ?? [],
            search: searchKey,
            beauty_empties_ids: selectedRowKeysBeautyEmpties
        }
        exportBeautyEmptiesList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Beauty-Empties-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row gap-y-3 justify-between items-center my-5 flex-wrap"}>
            <div className={"row space-x-2"}>
                <SearchComponent placeholder={"cari user..."}/>
                <SortComponent sortList={SORT_LIST_BEAUTY_EMPTIES}/>
                <FilterComponent filterList={FILTER_LIST_BEAUTY_EMPTIES} labelName={'name'} keyName={'id'}/>
                <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data beauty empties ?`}
                        countSelected={selectedRowKeysBeautyEmpties.length}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <ChangeStateComponent
                wordingConfirmation={"Apakah anda ingin mengubah status pengembalian yang anda pilih ?"}
                selectedRow={selectedRowKeysBeautyEmpties}
                stateList={STATE_LIST_BEAUTY_EMPTIES}
                isLoading={loadingUpdateState}
                applyStatusHandler={applyStatusHandler}/>
        </div>
    )
}
