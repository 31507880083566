import React, {useEffect, useState} from "react";
import {STEP_IMPORT_PRODUCT} from "../../utils/constant";
import {useDispatch, useSelector} from "react-redux";
import {
    setProductActiveTab,
    setProductErrorMessage,
    setProductImportProductDetail
} from "../../reducers/product.reducer";
import InputComponent from "../../components/input.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import * as xlsx from "xlsx";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {ButtonPrevIcon} from "../../utils/image";
import {cloneDeep} from "lodash";

const ColumnMapping = () => {
    const dispatch = useDispatch()
    const {productErrorMessage} = useSelector(state => state.productReducer)
    const {file, mapping_fields} = useSelector(state => state.productReducer.importProductDetail)
    const [columnListExcel, setColumnListExcel] = useState({})
    const {data: columnsListDatabase} = useFetch(API.IMPORT_GET_COLUMN_NAMES, {}, {isFetching: true})
    const fieldColumnName = {
        type: 'select',
        key: 'column',
        placeholder: 'Column Name',
        selectLabel: 'column_name',
        options: columnsListDatabase?.data
    }

    const prevStepHandler = () => {
        dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[0]))
    }

    const onChangeSelect = (data, key) => {
        const copyMappingFields = cloneDeep(mapping_fields)
        const dataMapping = copyMappingFields.map(item => {
            if(item.ex_col === key) {
                return {...item, db_col: data?.column_name}
            } else {
                return item
            }
        })
        dispatch(setProductImportProductDetail({mapping_fields: dataMapping}))
    }

    const dataChecking = (dataMapping) => {
        dispatch(setProductErrorMessage({}))
        const error = {}
        dataMapping.map(item => {
            if (!item.db_col) error[item.ex_col] = `${item.ex_col} not mapped`
        })

        if (Object.keys(error).length === 0) {
            dispatch(setProductErrorMessage({}))
            return true
        } else {
            dispatch(setProductErrorMessage(error))
            return false
        }
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const isValid = dataChecking(mapping_fields)
        if (isValid) {
            dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[2]))
        }
    }

    useEffect(() => {
        const convertDataToJson = async () => {
            const data = await file.arrayBuffer()
            const workbook = xlsx.read(data, {type: "array"})
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet, {raw: false, defval: 'null'})[0]
            const checkId = Object.keys(json).find(item => item.toLowerCase() === 'id' && json[item] === 'null')

            if(!checkId) {
                return json
            } else {
                return {...json, ID: 1}
            }
        }

        convertDataToJson().then(response => {
            const dataColumn = Object.keys(response).map(item => {
                return {ex_col: item, db_col: ''}
            })
            setColumnListExcel(response)
            dispatch(setProductImportProductDetail({mapping_fields: dataColumn}))
        }).catch(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('failed'))
            dispatch(setToolsAlertMessage('Something Wrong!'))
        })

        return () => {
            dispatch(setProductErrorMessage({}))
        }
    }, [])

    return (
        <form onSubmit={onSubmitHandler} className={"col space-y-8 py-6 border border-whisper-green rounded-lg"}>
            <div className={"col space-y-4 px-6"}>
                <button type={"button"} onClick={prevStepHandler}
                        className={"font-Montserrat-Bold text-white bg-battleship-green hover:bg-monstera px-8 py-2 rounded-lg w-fit"}>
                    <div className={"row items-center space-x-2"}>
                        <img src={ButtonPrevIcon} className={"w-5 mx-auto"}/>
                        <p>Back</p>
                    </div>
                </button>
                <p className={"text-lg text-monstera"}>Import Product Form XLSX
                    File</p>
                <p>This tools allows you to import (or merge) Product data to
                    product list from a XLSX</p>
            </div>
            <div className={"col font-semibold text-monstera border-y border-whisper-green py-6"}>
                <div className={"row flex-wrap px-6 pb-6"}>
                    <div className={"w-1/2 font-Montserrat-Bold"}>
                        <p>Column Name</p>
                    </div>
                    <div className={"w-1/2 font-Montserrat-Bold"}>
                        <p>Map to Field</p>
                    </div>
                </div>
                {
                    (columnsListDatabase?.data?.length > 0 && Object.keys(columnListExcel).length > 0) &&
                    Object.keys(columnListExcel).map((item, index) => (
                        <div key={index} className={"row flex-wrap items-center w-full px-6 py-4 even:bg-doctor"}>
                            <div className={"w-1/2 col space-y-2 font-Montserrat-Bold"}>
                                <p className={"font-semibold"}>{item}</p>
                                <p className={"font-regular italic text-forged-steel"}>Sample: {columnListExcel[item]}</p>
                            </div>
                            <div className={"w-1/2 font-Montserrat-Bold"}>
                                <InputComponent item={{...fieldColumnName, key: item}} onChangeSelect={onChangeSelect}
                                                error={{[item]: productErrorMessage[item]}}/>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={"row justify-center cursor-pointer"}>
                <button type={"submit"}
                        className={"font-Montserrat-Bold text-white bg-battleship-green hover:bg-monstera px-20 py-4 rounded-lg"}>
                    Next
                </button>
            </div>
        </form>
    )
}

export default ColumnMapping
