import LevelItem from "./level-item";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLevelAndBenefitDetail, setLevelAndBenefitErrorMessage} from "../../reducers/level-and-benefit.reducer";
import {convertErrorMessage, randomCode} from "../../utils/general-variable";
import {LoadingSvg, PlusCircleIconWhite} from "../../utils/image";
import ContentItem from "./content-item";
import {FIELD_LIST_CREATE_LEVEL_AND_BENEFIT} from "../../utils/input-field-list";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useNavigate} from "react-router-dom";
import {levelAndBenefitModel} from "../../models/level-and-benefit.model";

const CreateLevelsPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const itemRef = useRef([])
    const {levelAndBenefitDetail} = useSelector(state => state.levelAndBenefitReducer)
    const itemList = [...levelAndBenefitDetail.items]
    const {fetching: getLevels} = useFetch(API.GET_LEVELS_LIST)
    const {fetching: upsertLevels, isLoading} = useFetch(API.CREATE_UPDATE_LEVEL_AND_BENEFIT)
    let fieldList = FIELD_LIST_CREATE_LEVEL_AND_BENEFIT

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'level').toString(),
            name: null,
            discount_amount: null,
            max_redeem_amount: null,
            min_total_order_amount: null,
            max_total_order_amount: null
        })
        dispatch(setLevelAndBenefitDetail({items: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(levelAndBenefitDetail.items)
        const result = itemListCopy?.map((item, index) => {
            if (!Number(item.id)) delete item.id
            delete item?.rank
            delete item?.total_users
            return {
                ...item,
                discount_amount: item.discount_amount ? Number(item.discount_amount) : null,
                min_total_order_amount: Number(item.min_total_order_amount),
                max_total_order_amount: itemListCopy.length === index + 1 ? 0 : Number(item.max_total_order_amount),
                max_redeem_amount: Number(item.max_redeem_amount)
            }
        })
        return result
    }

    const dataChecking = (field, dataLevels) => {
        dispatch(setLevelAndBenefitErrorMessage({}))
        let error = {}

        const addError = (index, key, message) => {
            error[`items[${index}]`] = {...error[`items[${index}]`], ...{[key]: message}}
        }

        dataLevels.map((level, index) => {
            field.forEach(item => {
                if (item.key === 'name' && !level?.[item.key]) {
                    addError(index, item.key, `${item.label} is required`)
                } else if (item.key === 'min_total_order_amount' && !level?.[item.key] && level?.[item.key] !== 0) {
                    addError(index, item.key,`${item.label} is required`)
                }
            })
        })

        if (Object.keys(error).length === 0) {
            dispatch(setLevelAndBenefitErrorMessage({}))
            return true
        } else {
            dispatch(setLevelAndBenefitErrorMessage(error))
            return false
        }
    }

    const onSubmitHandler = () => {
        const isValid = dataChecking(fieldList, levelAndBenefitDetail.items)
        if (isValid) {
            const dataLevels = itemListConstruct()
            const dataLevelAndBenefit = {...levelAndBenefitDetail, items: dataLevels}
            upsertLevels(dataLevelAndBenefit).then(() => {
                dispatch(setLevelAndBenefitErrorMessage({}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Create Level and Benefit Successfully'))
                navigate('/loyalty-program/level-and-benefit')
            }).catch(err => {
                if (err?.code === '400-L003') {
                    dispatch(setToolsAlertMessage('Terdapat gap atau irisan range order antar item'))
                } else {
                    dispatch(setToolsAlertMessage('Terjadi kesalahan'))
                }
                dispatch(setLevelAndBenefitErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    useEffect(() => {
        getLevels().then(response => {
            if (response?.data.length > 0) {
                const data = response?.data
                const dataMap = data?.map((item, index) => {
                    return {
                        ...item,
                        max_total_order_amount: data.length === index + 1 ? null : item.max_total_order_amount
                    }
                })
                dispatch(setLevelAndBenefitDetail({items: dataMap, delete_ids: []}))
            }
        })

        return () => {
            dispatch(setLevelAndBenefitErrorMessage({}))
            dispatch(setLevelAndBenefitDetail(levelAndBenefitModel))
        }
    }, [])

    useEffect(() => {
        itemRef.current[itemList?.length - 1].value = null
    }, [itemList?.length])

    return (
        <div className={"col px-10 mt-5"}>
            <div className={"col space-y-4 pt-6"}>
                <p className={"font-Montserrat-Bold text-sm text-forged-steel"}>Level & Benefit Item</p>
                {
                    itemList.map((item, index) =>
                        <LevelItem
                            key={item.id}
                            index={index}
                            title={`Level ${index + 1}`}
                            content={<ContentItem fieldList={fieldList} index={index} itemRef={itemRef}/>}
                            dataList={itemList}
                            setDataList={setLevelAndBenefitDetail}
                            deleteList={levelAndBenefitDetail?.delete_ids}
                        />
                    )
                }
                <div
                    className={"row space-x-2 items-center w-fit bg-battleship-green hover:bg-monstera py-2 px-6 rounded-lg cursor-pointer"}
                    onClick={addItemHandler}>
                    <img src={PlusCircleIconWhite} alt="PlusCircleIconWhite" className={"w-5"}/>
                    <p className={"font-semibold text-xs text-white"}>Add Level</p>
                </div>
            </div>
            <div className={"row font-semibold justify-center py-8"}>
                <button onClick={onSubmitHandler}
                        className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                    {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                </button>
            </div>
        </div>
    )
}

export default CreateLevelsPage
