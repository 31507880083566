import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setBirthdayGiftDetail,
    setBirthdayGiftErrorMessage,
    setBirthdayGiftSelectedId
} from "../../reducers/birthday-gift.reducer";
import {birthdayGiftModel} from "../../models/birthday-gift.model";
import LevelItem from "./gift-item";
import {PlusCircleIconWhite} from "../../utils/image";
import {FIELD_LIST_CREATE_BIRTHDAY_GIFT} from "../../utils/input-field-list";
import ContentItem from "./content-item";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import {cloneDeep} from "lodash";

const CreateBirthdayGift = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {birthdayGiftDetail} = useSelector(state => state.birthdayGiftReducer)
    const [levelName, setLevelName] = useState('')
    const itemList = [...birthdayGiftDetail.items]
    const {fetching: getBirthdayGift} = useFetch({...API.GET_DETAIL_BIRTHDAY_GIFT, ...{initPathVariable: id}}, {}, {isFetching: true})
    const {fetching: upsertBirthdayGift} = useFetch({...API.UPSERT_BIRTHDAY_GIFT, ...{initPathVariable: id}})
    let fieldList = FIELD_LIST_CREATE_BIRTHDAY_GIFT

    const addItemHandler = () => {
        itemList.push({
            discount_amount: null,
            gift: {
                id: null,
                name: null,
                image: {
                    url: null,
                    alt_text: null
                }
            }
        })
        dispatch(setBirthdayGiftDetail({items: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(birthdayGiftDetail.items)
        const result = itemListCopy?.map((item) => {
            return {
                discount_amount: Number(item?.discount_amount),
                gift_id: item?.gift?.id
            }
        })
        return result
    }

    const dataChecking = (field, dataGiftList) => {
        dispatch(setBirthdayGiftErrorMessage({}))
        let error = {}

        dataGiftList.map((gift, index) => {
            field.forEach(item => {
                if (item.key === 'gift_id' && !gift?.[item.key]) {
                    error[`items[${index}]`] = {...error[`items[${index}]`], ...{[item.key]: 'Gift is required'}}
                }
            })
        })

        if (Object.keys(error).length === 0) {
            dispatch(setBirthdayGiftErrorMessage({}))
            return true
        } else {
            dispatch(setBirthdayGiftErrorMessage(error))
            return false
        }
    }

    const onSubmitHandler = () => {
        const dataGiftList = itemListConstruct()
        const dataBirthdayGift = {items: dataGiftList}
        const isValid = dataChecking(fieldList, dataGiftList)
        if (isValid) {
            upsertBirthdayGift(dataBirthdayGift).then(() => {
                dispatch(setBirthdayGiftErrorMessage({}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Create Birthday Gift Successfully'))
                navigate('/loyalty-program/birthday-gift')
            }).catch(err => {
                dispatch(setBirthdayGiftErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    useEffect(() => {
        getBirthdayGift().then(response => {
            const dataResponse = response?.data
            if (dataResponse?.detail?.length > 0) {
                const selectedIds = dataResponse?.detail.map(item => item?.gift.id)
                dispatch(setBirthdayGiftDetail({items: dataResponse?.detail}))
                dispatch(setBirthdayGiftSelectedId(selectedIds))
            }
            fieldList[0].options = dataResponse?.available_gifts
            setLevelName(dataResponse?.level_name)
        })

        return () => {
            dispatch(setBirthdayGiftErrorMessage({}))
            dispatch(setBirthdayGiftDetail(birthdayGiftModel))
            dispatch(setBirthdayGiftSelectedId([]))
        }
    }, [])

    return (
        <div className={"col px-10 mt-5"}>
            <div className={"col space-y-4 pt-6"}>
                <div className={"col space-y-10 text-sm text-forged-steel"}>
                    <p className={"font-Montserrat-Bold "}>{levelName}</p>
                    <p>Pilihan Gift</p>
                </div>
                {
                    itemList.map((item, index) =>
                        <LevelItem
                            key={index}
                            index={index}
                            title={`Gift ${index + 1}`}
                            content={<ContentItem fieldList={fieldList} index={index}/>}
                            dataList={itemList}
                            setDataList={setBirthdayGiftDetail}
                        />
                    )
                }
                <div
                    className={"row space-x-2 items-center w-fit bg-battleship-green hover:bg-monstera py-2 px-6 rounded-lg cursor-pointer"}
                    onClick={addItemHandler}>
                    <img src={PlusCircleIconWhite} alt="PlusCircleIconWhite" className={"w-5"}/>
                    <p className={"font-semibold text-xs text-white"}>Add Gift</p>
                </div>
            </div>
            <div className={"row font-semibold justify-center py-8"}>
                <button onClick={onSubmitHandler}
                        className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                    Save All Changes
                </button>
            </div>
        </div>
    )
}

export default CreateBirthdayGift
