import React, {useEffect, useState} from "react";

const TextAreaComponent = (props) => {
    const {keyName, placeholder, maxLength, defaultValue, onChangeInput} = props
    const [val, setVal] = useState('')

    const setValHandler = (key, val) => {
        const value = !!val ? val : null
        !!onChangeInput && onChangeInput(key, value)
    }

    useEffect(() => {
        if (!!defaultValue) {
            setVal(defaultValue)
        } else {
            setVal('')
        }
    }, [defaultValue])

    return (
        <textarea id={keyName} name={keyName} cols="30" rows="5" placeholder={placeholder} maxLength={maxLength}
                  defaultValue={val}
                  onKeyUp={(e) => setValHandler(keyName, e.target.value)}
                  className={"border border-placebo p-2 rounded-lg placeholder:italic outline-0"}></textarea>
    )
}

export default TextAreaComponent
