import React, {useEffect, useState} from "react";
import {PlusCircleIcon, TrashIcon} from "../utils/image";
import {useFetch} from "../services/useFetch";
import {API} from "../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../reducers/tools.reducer";
import {useDispatch} from "react-redux";
import {imageValidate} from "../utils/general-variable";

const UploadImageBannerProductComponent = (props) => {
    const {item, onChangeMultipleImage, defaultValue} = props
    const dispatch = useDispatch()
    const {fetching: uploadPicture} = useFetch(API.PRODUCT_UPLOAD_PRODUCT_PICTURE)
    const [bannerImageList, setBannerImageList] = useState([null, null])

    const uploadImageHandler = (event, index) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        const {isValid, message} = imageValidate(file)

        if (file) reader.readAsDataURL(file)

        if (isValid) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    const copyImageUrlList = [...bannerImageList]
                    uploadPicture({image: file}, null, {'Content-Type': 'multipart/form-data'}).then(response => {
                        const url = response?.data?.url
                        copyImageUrlList[index] = url
                        setBannerImageList(copyImageUrlList)
                        !!onChangeMultipleImage && onChangeMultipleImage(item.key, copyImageUrlList)
                    })
                }
            }
        } else {
            showAlertModal(message)
        }
    }

    const removeImageHandler = (id) => {
        const updateData = bannerImageList.map((item, index) => {
            return index === id ? null : item
        })
        setBannerImageList(updateData)
        !!onChangeMultipleImage && onChangeMultipleImage(item.key, updateData)
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }

    useEffect(() => {
        if(!!defaultValue){
            setBannerImageList(defaultValue)
        }
    }, [defaultValue])

    return (
        <div className={"row space-x-4"}>
            {item?.titleList.map((title, index) => (
                <div key={index} className={"col space-y-3 items-center"}>
                    {bannerImageList[index] ?
                        <div className={"relative"}>
                            <img src={bannerImageList[index]} alt="ImageProduct"
                                 className={"w-40 h-56 border border-placebo rounded-lg"}/>
                            <img src={TrashIcon} alt="deleteImage"
                                 className={"w-7 absolute right-2 top-2 border border-placebo rounded-full p-1 bg-white  cursor-pointer"}
                                 onClick={() => removeImageHandler(index)}/>
                        </div>
                        : <div
                            className={"col items-center justify-center w-40 h-56 border border-monstera rounded-md bg-windmill-wings relative"}>
                            <label htmlFor={`${item.key}-${index}-${item.language}`}>
                                <img src={PlusCircleIcon} alt="PlusCircleIcon"
                                     className={"w-7 cursor-pointer"}/>
                            </label>
                            <input name={`${item.key}-${index}-${item.language}`} id={`${item.key}-${index}-${item.language}`} type={"file"}
                                   className={'absolute top-7 left-0 invisible'}
                                   onChange={(event) => uploadImageHandler(event, index)}/>
                        </div>}
                    <p className={"text-forged-steel italic"}>{item?.titleList[index]}</p>
                </div>
            ))}
        </div>
    )
}

export default UploadImageBannerProductComponent
