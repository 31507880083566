import {
    Analytics,
    AnalyticsWhite,
    AvoStore,
    AvoStoreWhite,
    Comment,
    CommentWhite,
    LandingPage,
    LandingPageWhite,
    MainPage,
    MainPageWhite,
    MoreInfoPage,
    MoreInfoPageWhite,
    Notifications,
    NotificationsWhite,
    Orders,
    OrdersWhite,
    PartnerStore,
    PartnerStoreWhite,
    Products,
    ProductsWhite,
    RecycleProgram,
    RecycleProgramWhite,
    Settings,
    SettingsWhite,
    SpecialSeries,
    SpecialSeriesWhite,
    Users,
    UsersWhite,
    Vouchers,
    VouchersWhite,
    GiftIcon,
    GiftWhite
} from "./image";
import User from "../pages/user";
import Order from "../pages/order";
import OrderDetail from "../pages/order-detail";
import AllReview from "../pages/all-review";
import ProductReview from "../pages/product-review";
import ProductReviewDetail from "../pages/product-review-detail";
import Voucher from "../pages/voucher";
import CreateVoucher from "../pages/create-voucher";
import UserDetail from "../pages/user-detail";
import GenerateVoucher from "../pages/generate-voucher";
import Revenue from "../pages/revenue";
import OrderAnalytics from "../pages/order-analytics";
import ProductAnalytics from "../pages/product-analytics";
import Customer from "../pages/customer";
import Product from "../pages/product";
import CreateProduct from "../pages/create-product";
import Category from "../pages/category";
import CreateUser from "../pages/create-user";
import UserRole from "../pages/user-role";
import CreateRole from "../pages/create-role";
import ImportProduct from "../pages/import-product";
import Avostore from "../pages/avostore";
import CreateAvostore from "../pages/create-avostore";
import UpdateProduct from "../pages/update-product";
import Area from "../pages/area";
import ImportAvostore from "../pages/import-avostore";
import UpdateVoucher from "../pages/update-voucher";
import TermsAndPolicies from "../pages/terms-and-policies";
import ShippingPolicy from "../pages/shipping-policy";
import ReturnPolicy from "../pages/return-policy";
import TermsAndCondition from "../pages/terms-and-condition";
import HowToOrder from "../pages/how-to-order";
import Error404 from "../pages/error-404";
import Faq from "../pages/faq";
import HomePage from "../pages/home-page";
import Waste4change from "../pages/waste4change";
import BeautyFriday from "../pages/beauty-friday";
import Gift from "../pages/gift";
import CreateGift from "../pages/create-gift";
import UpdateGift from "../pages/update-gift";
import UpdateAvostore from "../pages/update-avostore";
import PromoPage from "../pages/promo";
import MobileVersion from "../pages/mobile-version";
import AboutUs from "../pages/about-us";
import MiraculousSeries from "../pages/miraculous-series";
import MiraculousRefiningSeries from "../pages/miraculous-refining-series";
import MiraculousRetinolSeries from "../pages/miraculous-retinol-series";
import YourSkinBae from "../pages/your-skin-bae";
import MaintenancePage from "../pages/maintenance-page";
import AvoStories from "../pages/avo-stories";
import Notification from "../pages/notification";
import CreateNotification from "../pages/create-notification";
import UpdateNotification from "../pages/update-notification";
import VoucherAnalytics from "../pages/voucher-analytics";
import AffiliatePage from "../pages/affiliate";
import AffiliatePerformancePage from "../pages/affiliate-performance";
import LevelAndBenefit from "../pages/level-and-benefit";
import CreateLevelAndBenefit from "../pages/create-level-and-benefit";
import BirthdayGift from "../pages/birthday-gift";
import CreateBirthdayGift from "../pages/create-birthday-gift";
import BeautyEmpties from "../pages/beauty-empties";
import BeautyEmptiesDetail from "../pages/beauty-empties-detail";
import BeautyEmptiesProduct from "../pages/beauty-empties-product";
import AvoStories2 from "../pages/avo-stories-2";
import AvoStoriesUser from "../pages/avo-stories-user";
import AffiliationPage from "../pages/affiliation";
import LoyaltyProgramPage from "../pages/loyalty-program";
import HeaderSlidingText from "../pages/header-sliding-text";

const SIDEBAR = [
    {
        module_id: 21000,
        image: Users,
        imageActive: UsersWhite,
        label: 'User',
        path: 'user',
        child: [
            {
                feature_id: 21001,
                path: 'user-list',
                label: 'User List'
            }, {
                feature_id: 21013,
                path: 'create-user',
                label: 'Create User'
            }, {
                feature_id: 21017,
                path: 'role-list',
                label: 'User Role'
            }
        ]
    }, {
        module_id: 22000,
        image: Products,
        imageActive: ProductsWhite,
        label: 'Product',
        path: 'product',
        child: [
            {
                feature_id: 22001,
                path: 'product-list',
                label: 'Product List'
            },
            {
                feature_id: 22004,
                path: 'create-product',
                label: 'Create Product'
            },
            {
                feature_id: 23001,
                path: 'gift-list',
                label: 'Gift List'
            },
            {
                feature_id: 23004,
                path: 'create-gift',
                label: 'Create Gift'
            },
            {
                feature_id: 22009,
                path: 'category',
                label: 'Category'
            },
            {
                feature_id: 22018,
                path: 'import-product',
                label: 'Import Product'
            }
        ]
    }, {
        module_id: 25000,
        image: Comment,
        imageActive: CommentWhite,
        label: 'Reviews',
        path: 'reviews',
        child: [
            {
                feature_id: 25001,
                path: 'all-review',
                label: 'All Review'
            },
            {
                feature_id: 25003,
                path: 'product-review',
                label: 'Product Review'
            }
        ]
    }, {
        module_id: 26000,
        image: Orders,
        imageActive: OrdersWhite,
        label: 'Order',
        path: 'order',
        child: [
            {
                feature_id: 26001,
                path: 'order-list',
                label: 'Order List'
            }
        ]
    }, {
        module_id: 24000,
        image: Vouchers,
        imageActive: VouchersWhite,
        label: 'Voucher',
        path: 'voucher',
        child: [
            {
                feature_id: 24001,
                path: 'voucher-list',
                label: 'Voucher List'
            },
            {
                feature_id: 24008,
                path: 'create-voucher',
                label: 'Create Voucher'
            },
            {
                feature_id: 24009,
                path: 'generate-voucher',
                label: 'Generate Voucher'
            }
        ]
    }, {
        module_id: 27000,
        image: Analytics,
        imageActive: AnalyticsWhite,
        label: 'Analytics',
        path: 'analytics',
        child: [
            {
                feature_id: 27002,
                path: 'revenue',
                label: 'Revenue'
            },
            {
                feature_id: 27007,
                path: 'order',
                label: 'Order'
            },
            {
                feature_id: 27011,
                path: 'product',
                label: 'Product'
            },
            {
                feature_id: 27017,
                path: 'voucher',
                label: 'Voucher'
            },
            {
                feature_id: 27013,
                path: 'customer',
                label: 'Customer'
            }
        ]
    }, {
        module_id: 28000,
        image: AvoStore,
        imageActive: AvoStoreWhite,
        label: 'Avo Store',
        path: 'avostore',
        child: [
            {
                feature_id: 28001,
                path: 'avostore-list',
                label: 'Avostore List'
            },
            {
                feature_id: 28004,
                path: 'create-avostore',
                label: 'Create Avostore'
            },
            {
                feature_id: 28008,
                path: 'area',
                label: 'Area'
            },
            {
                feature_id: 28014,
                path: 'import-avostore',
                label: 'Import'
            }
        ]
    }, {
        module_id: 29000,
        image: PartnerStore,
        imageActive: PartnerStoreWhite,
        label: 'Partner Store',
        path: 'partner-store',
        child: []
    }, {
        module_id: 30000,
        image: MainPage,
        imageActive: MainPageWhite,
        label: 'Main Page',
        path: 'main-page',
        child: [
            {
                feature_id: 30001,
                path: 'home-page',
                label: 'Home Page'
            },
            {
                feature_id: 30002,
                path: 'about-us',
                label: 'About Us'
            },
            {
                feature_id: 30003,
                path: 'avo-stories',
                label: 'Avo Stories'
            },
            {
                feature_id: 30005,
                path: 'avo-stories-2',
                label: 'Avo Stories 2'
            },
            {
                feature_id: 30004,
                path: 'promo',
                label: 'Promo'
            }
        ]
    }, {
        module_id: 31000,
        image: SpecialSeries,
        imageActive: SpecialSeriesWhite,
        label: 'Special Series',
        path: 'special-series',
        child: [
            {
                feature_id: 31001,
                path: 'miraculous-series',
                label: 'Miraculous Series'
            }, {
                feature_id: 31002,
                path: 'miraculous-refining-series',
                label: 'Mira. Refining S.'
            }, {
                feature_id: 31003,
                path: 'miraculous-retinol-series',
                label: 'Mira. Retinol S.'
            }, {
                feature_id: 31004,
                path: 'your-skin-bae',
                label: 'Your Skin Bae'
            }
        ]
    }, {
        module_id: 32000,
        image: LandingPage,
        imageActive: LandingPageWhite,
        label: 'Landing Page',
        path: 'landing-page',
        child: [
            {
                feature_id: 32001,
                path: 'waste-4-change',
                label: 'Waste 4 Change'
            },
            {
                feature_id: 32002,
                path: 'beauty-friday',
                label: 'Beauty Friday'
            }, {
                feature_id: 32003,
                path: 'affiliation',
                label: 'Affiliation'
            }, {
                feature_id: 32004,
                path: 'loyalty-program',
                label: 'Loyalty Program'
            }
        ]
    }, {
        module_id: 33000,
        image: RecycleProgram,
        imageActive: RecycleProgramWhite,
        label: 'Recycle Program',
        path: 'recycle-program',
        child: []
    }, {
        module_id: 34000,
        image: MoreInfoPage,
        imageActive: MoreInfoPageWhite,
        label: 'More Info Page',
        path: 'more-info-page',
        child: [
            {
                feature_id: 34001,
                path: 'terms-&-policies',
                label: 'Terms & Policies'
            },
            {
                feature_id: 34002,
                path: 'shipping-policy',
                label: 'Shipping Policy'
            },
            {
                feature_id: 34003,
                path: 'return-policy',
                label: 'Return Policy'
            },
            {
                feature_id: 34004,
                path: 'terms-&-condition',
                label: 'Terms & Condition'
            },
            {
                feature_id: 34005,
                path: 'faq',
                label: 'FAQ'
            },
            {
                feature_id: 34006,
                path: 'how-to-order',
                label: 'How To Order'
            },
            {
                feature_id: 34007,
                path: 'error-404',
                label: 'Error 404'
            }
        ]
    }, {
        module_id: 36000,
        image: Notifications,
        imageActive: NotificationsWhite,
        label: 'Notification',
        path: 'notification',
        child: [
            {
                feature_id: 36001,
                path: 'notification-list',
                label: 'Notification List'
            },
            {
                feature_id: 36005,
                path: 'create-notification',
                label: 'Create Notification'
            }
        ]
    }, {
        module_id: 35000,
        image: Settings,
        imageActive: SettingsWhite,
        label: 'Settings',
        path: 'settings',
        child: [
            {
                feature_id: 35001,
                path: 'mobile-version',
                label: 'Mobile Version'
            },
            {
                feature_id: 35002,
                path: 'maintenance-page',
                label: 'Maintenance Page'
            }
        ]
    }, {
        module_id: 37000,
        image: Settings,
        imageActive: SettingsWhite,
        label: 'Affiliate',
        path: 'affiliate',
        child: [
            {
                feature_id: 37001,
                path: 'affiliate-list',
                label: 'Affiliate List'
            },
            {
                feature_id: 37006,
                path: 'affiliate-performance',
                label: 'Affiliate Performance'
            }
        ]
    }, {
        module_id: 38000,
        image: GiftIcon,
        imageActive: GiftWhite,
        label: 'Loyalty Program',
        path: 'loyalty-program',
        child: [
            {
                feature_id: 38001,
                path: 'level-and-benefit',
                label: 'Level and Benefit'
            }, {
                feature_id: 38004,
                path: 'birthday-gift',
                label: 'Birthday Gift'
            }, {
                feature_id: 38007,
                path: 'beauty-empties',
                label: 'Beauty Empties'
            }, {
                feature_id: 38012,
                path: 'beauty-empties-product',
                label: 'Beauty Empties Product'
            }
        ]
    }, {
        module_id: 39000,
        image: Users,
        imageActive: UsersWhite,
        label: 'Avo Stories user',
        path: 'avo-stories-user',
        child: [
            {
                feature_id: 39001,
                path: 'avo-stories-user-list',
                label: 'Avo Stories User List'
            }
        ]
    }, {
        module_id: 40000,
        image: Settings,
        imageActive: SettingsWhite,
        label: 'CRM Settings',
        path: 'crm-settings',
        child: [
            {
                feature_id: 40001,
                path: 'header-sliding-text',
                label: 'Header Sliding Text'
            }
        ]
    }
]

const BREADCRUMB = {
    'user': 'User',
    'user-list': 'User List',
    'user-detail': 'User Detail',
    'create-user': 'Create User',
    'role-list': 'Role List',
    'create-role': 'Create Role',
    'edit-role': 'Edit Role',
    'product': 'Product',
    'product-list': 'Product List',
    'create-product': 'Create Product',
    'update-product': 'Update Product',
    'reviews': 'Reviews',
    'all-review': 'All Review',
    'product-review': 'Product Review',
    'product-review-detail': 'Detail',
    'order': 'Order',
    'order-list': 'Order List',
    'order-detail': 'Order Detail',
    'voucher': 'Voucher',
    'voucher-list': 'Voucher List',
    'create-voucher': 'Create Voucher',
    'update-voucher': 'Update Voucher',
    'generate-voucher': 'Generate Voucher',
    'analytics': 'Analytics',
    'revenue': 'Revenue',
    'customer': 'Customer',
    'category': 'Category',
    'import-product': 'Import Product',
    'avostore': 'Avostore',
    'avostore-list': 'Avostore List',
    'create-avostore': 'Create Avostore',
    'update-avostore': 'Update Avostore',
    'area': 'Area',
    'import-avostore': 'Import Avostore',
    'more-info-page': 'More Info Page',
    'terms-&-policies': 'Terms & Policies',
    'shipping-policy': 'Shipping Policy',
    'return-policy': 'Return Policy',
    'terms-&-condition': 'Terms & condition',
    'faq': 'FAQ',
    'how-to-order': 'How To Order',
    'error-404': 'Error 404',
    'main-page': 'Main Page',
    'home-page': 'Home Page',
    'about-us': 'About Us',
    'avo-stories': 'Avo Stories',
    'avo-stories-2': 'Avo Stories 2',
    'avo-stories-user': 'Avo Stories User',
    'avo-stories-user-list': 'Avo Stories User List',
    'promo': 'Promo',
    'landing-page': 'Landing Page',
    'waste-4-change': 'Waste 4 Change',
    'beauty-friday': 'Beauty Friday',
    'gift-list': 'Gift List',
    'create-gift': 'Create Gift',
    'update-gift': 'Update Gift',
    'settings': 'Settings',
    'mobile-version': 'Mobile Version',
    'maintenance-page': 'Maintenance Page',
    'special-series': 'Special Series',
    'miraculous-series': 'Miraculous Series',
    'miraculous-refining-series': 'Miraculous Refining Series',
    'miraculous-retinol-series': 'Miraculous Retinol Series',
    'your-skin-bae': 'Your Skin Bae',
    'notification': 'Notification',
    'notification-list': 'Notification List',
    'create-notification': 'Create Notification',
    'update-notification': 'Update Notification',
    'affiliate': 'Affiliate',
    'affiliate-list': 'Affiliate List',
    'affiliate-performance': 'Affiliate Performance',
    'loyalty-program': 'Loyalty Program',
    'level-and-benefit': 'Level & Benefit List',
    'create-level-and-benefit': 'Create Level & Benefit',
    'birthday-gift': 'Birthday Gift List',
    'create-birthday-gift': 'Create Birthday Gift',
    'beauty-empties': 'Beauty Empties List',
    'beauty-empties-detail': 'Beauty Empties Detail',
    'beauty-empties-product': 'Beauty Empties Product',
    'affiliation': 'Affiliation',
    'crm-settings': 'CRM Settings',
    'header-sliding-text': 'Header Sliding Text'
}

const ROUTE_LIST = [
    {
        feature_id: 21001,
        path: '/user/user-list',
        component: <User/>
    },
    {
        feature_id: 21003,
        path: '/user/user-list/user-detail/:id',
        component: <UserDetail/>
    },
    {
        feature_id: 21013,
        path: '/user/create-user',
        component: <CreateUser/>
    },
    {
        feature_id: 21017,
        path: '/user/role-list',
        component: <UserRole/>
    },
    {
        feature_id: 21020,
        path: '/user/role-list/create-role',
        component: <CreateRole/>
    },
    {
        feature_id: 21021,
        path: '/user/role-list/edit-role/:id',
        component: <CreateRole/>
    },
    {
        feature_id: 26001,
        path: '/order/order-list',
        component: <Order/>
    },
    {
        feature_id: 26002,
        path: '/order/order-list/order-detail/:id',
        component: <OrderDetail/>
    },
    {
        feature_id: 25001,
        path: '/reviews/all-review',
        component: <AllReview/>
    },
    {
        feature_id: 25003,
        path: '/reviews/product-review',
        component: <ProductReview/>
    },
    {
        feature_id: 25005,
        path: '/reviews/product-review/product-review-detail/:id',
        component: <ProductReviewDetail/>
    },
    {
        feature_id: 24001,
        path: '/voucher/voucher-list',
        component: <Voucher/>
    },
    {
        feature_id: 24008,
        path: '/voucher/create-voucher',
        component: <CreateVoucher/>
    },
    {
        feature_id: 24010,
        path: '/voucher/voucher-list/update-voucher/:id',
        component: <UpdateVoucher/>
    },
    {
        feature_id: 24009,
        path: '/voucher/generate-voucher',
        component: <GenerateVoucher/>
    },
    {
        feature_id: 27002,
        path: '/analytics/revenue',
        component: <Revenue/>
    },
    {
        feature_id: 27007,
        path: '/analytics/order',
        component: <OrderAnalytics/>
    },
    {
        feature_id: 27011,
        path: '/analytics/product',
        component: <ProductAnalytics/>
    },
    {
        feature_id: 27017,
        path: '/analytics/voucher',
        component: <VoucherAnalytics/>
    },
    {
        feature_id: 27013,
        path: '/analytics/customer',
        component: <Customer/>
    },
    {
        feature_id: 22001,
        path: '/product/product-list',
        component: <Product/>
    },
    {
        feature_id: 22004,
        path: '/product/create-product',
        component: <CreateProduct/>
    },
    {
        feature_id: 22006,
        path: '/product/product-list/update-product/:id',
        component: <UpdateProduct/>
    },
    {
        feature_id: 22009,
        path: '/product/category',
        component: <Category/>
    },
    {
        feature_id: 22018,
        path: '/product/import-product',
        component: <ImportProduct/>
    },
    {
        feature_id: 28001,
        path: '/avostore/avostore-list',
        component: <Avostore/>
    },
    {
        feature_id: 28004,
        path: '/avostore/create-avostore',
        component: <CreateAvostore/>
    },
    {
        feature_id: 28006,
        path: '/avostore/avostore-list/update-avostore/:id',
        component: <UpdateAvostore/>
    },
    {
        feature_id: 28008,
        path: '/avostore/area',
        component: <Area/>
    },
    {
        feature_id: 28014,
        path: '/avostore/import-avostore',
        component: <ImportAvostore/>
    },
    {
        feature_id: 34001,
        path: '/more-info-page/terms-&-policies',
        component: <TermsAndPolicies/>
    },
    {
        feature_id: 34002,
        path: '/more-info-page/shipping-policy',
        component: <ShippingPolicy/>
    },
    {
        feature_id: 34003,
        path: '/more-info-page/return-policy',
        component: <ReturnPolicy/>
    },
    {
        feature_id: 34004,
        path: '/more-info-page/terms-&-condition',
        component: <TermsAndCondition/>
    },
    {
        feature_id: 34006,
        path: '/more-info-page/how-to-order',
        component: <HowToOrder/>
    },
    {
        feature_id: 34007,
        path: '/more-info-page/error-404',
        component: <Error404/>
    },
    {
        feature_id: 34005,
        path: '/more-info-page/faq',
        component: <Faq/>
    },
    {
        feature_id: 30001,
        path: '/main-page/home-page',
        component: <HomePage/>
    },
    {
        feature_id: 30004,
        path: '/main-page/promo',
        component: <PromoPage/>
    },
    {
        feature_id: 30002,
        path: '/main-page/about-us',
        component: <AboutUs/>
    },
    {
        feature_id: 30003,
        path: '/main-page/avo-stories',
        component: <AvoStories/>
    },
    {
        feature_id: 30005,
        path: '/main-page/avo-stories-2',
        component: <AvoStories2/>
    },
    {
        feature_id: 39001,
        path: '/avo-stories-user/avo-stories-user-list',
        component: <AvoStoriesUser/>
    },
    {
        feature_id: 32001,
        path: '/landing-page/waste-4-change',
        component: <Waste4change/>
    },
    {
        feature_id: 32002,
        path: '/landing-page/beauty-friday',
        component: <BeautyFriday/>
    },
    {
        feature_id: 32003,
        path: '/landing-page/affiliation',
        component: <AffiliationPage/>
    },
    {
        feature_id: 32004,
        path: '/landing-page/loyalty-program',
        component: <LoyaltyProgramPage/>
    },
    {
        feature_id: 23001,
        path: '/product/gift-list',
        component: <Gift/>
    },
    {
        feature_id: 23004,
        path: '/product/create-gift',
        component: <CreateGift/>
    },
    {
        feature_id: 23006,
        path: '/product/gift-list/update-gift/:id',
        component: <UpdateGift/>
    },
    {
        feature_id: 35001,
        path: '/settings/mobile-version',
        component: <MobileVersion/>
    },
    {
        feature_id: 35002,
        path: '/settings/maintenance-page',
        component: <MaintenancePage/>
    },
    {
        feature_id: 31001,
        path: '/special-series/miraculous-series',
        component: <MiraculousSeries/>
    },
    {
        feature_id: 31002,
        path: '/special-series/miraculous-refining-series',
        component: <MiraculousRefiningSeries/>
    },
    {
        feature_id: 31003,
        path: '/special-series/miraculous-retinol-series',
        component: <MiraculousRetinolSeries/>
    },
    {
        feature_id: 31004,
        path: '/special-series/your-skin-bae',
        component: <YourSkinBae/>
    },
    {
        feature_id: 36001,
        path: '/notification/notification-list',
        component: <Notification/>
    },
    {
        feature_id: 36005,
        path: '/notification/create-notification',
        component: <CreateNotification/>
    },
    {
        feature_id: 36004,
        path: '/notification/notification-list/update-notification/:id',
        component: <UpdateNotification/>
    },
    {
        feature_id: 37001,
        path: '/affiliate/affiliate-list',
        component: <AffiliatePage/>
    },
    {
        feature_id: 37006,
        path: '/affiliate/affiliate-performance',
        component: <AffiliatePerformancePage/>
    }, {
        feature_id: 38001,
        path: '/loyalty-program/level-and-benefit',
        component: <LevelAndBenefit/>
    }, {
        feature_id: 38002,
        path: '/loyalty-program/level-and-benefit/create-level-and-benefit',
        component: <CreateLevelAndBenefit/>
    }, {
        feature_id: 38004,
        path: '/loyalty-program/birthday-gift',
        component: <BirthdayGift/>
    }, {
        feature_id: 38006,
        path: '/loyalty-program/birthday-gift/create-birthday-gift/:id',
        component: <CreateBirthdayGift/>
    }, {
        feature_id: 38007,
        path: '/loyalty-program/beauty-empties',
        component: <BeautyEmpties/>
    }, {
        feature_id: 38010,
        path: '/loyalty-program/beauty-empties/beauty-empties-detail/:id',
        component: <BeautyEmptiesDetail/>
    }, {
        feature_id: 38012,
        path: '/loyalty-program/beauty-empties-product',
        component: <BeautyEmptiesProduct/>
    }, {
        feature_id: 40001,
        path: '/crm-settings/header-sliding-text',
        component: <HeaderSlidingText/>
    },
]

const COMMENT_DETAIL = [
    {
        question: 'What is your skin type?',
        key: 'skin_types'
    },
    {
        question: 'How is the product price?',
        key: 'product_price'
    },
    {
        question: 'How long you wear it?',
        key: 'duration_of_use'
    },
    {
        question: 'Are you recommend this product?',
        key: 'is_recommended'
    },
    {
        question: 'Package quality?',
        key: 'package_quality'
    },
    {
        question: 'Will you repurchase this product?',
        key: 'is_repurchase'
    },
]

const TAB_LIST_ORDER = [
    {
        id: 0,
        label: 'Semua',
        key: 'all'
    }, {
        id: 1,
        label: 'Pending Payment',
        key: 'pending'
    }, {
        id: 2,
        label: 'Belum Dibayar',
        key: 'on-hold'
    }, {
        id: 3,
        label: 'Sudah Dibayar',
        key: 'processing'
    }, {
        id: 4,
        label: 'Dikemas',
        key: 'packing'
    }, {
        id: 5,
        label: 'Dikirim',
        key: 'on-delivery'
    }, {
        id: 6,
        label: 'Selesai',
        key: 'completed'
    }, {
        id: 7,
        label: 'Dibatalkan',
        key: 'cancelled'
    }
]

const USER_DETAIL_TAB_LIST = ['profile', 'alamat', 'pesanan', 'poin', 'review', 'wishlist', 'waitlist', 'referral']

const USER_DETAIL_PROFILE = [
    {
        label: 'Nama',
        key: 'fullname'
    }, {
        label: 'Email',
        key: 'email'
    }, {
        label: 'Telpon',
        key: 'phone_number'
    }, {
        label: 'Tanggal Lahir',
        key: 'birthdate'
    }, {
        label: 'Jenis Kelamin',
        key: 'gender'
    }
]

const USER_DETAIL_ACCESSIBILITY = ['role', 'status']

const ADDRESS_ITEM_LIST_lEFT = [
    {
        label: 'Nama Penerima',
        key: 'name',
    }, {
        label: 'Telp. Penerima',
        key: 'phone_number',
    }, {
        label: 'Kecamatan',
        key: 'district',
    }, {
        label: 'Kabupaten',
        key: 'city',
    }, {
        label: 'Provinsi',
        key: 'province',
    }, {
        label: 'Kode Pos',
        key: 'zipcode',
    },
]

const ADDRESS_ITEM_LIST_RIGHT = [
    {
        label: 'Alamat Lengkap',
        key: 'address',
    }, {
        label: 'Catatan',
        key: 'note',
    },
]

const STEP_IMPORT_PRODUCT = [
    {
        id: 0,
        label: 'Upload XLSX File',
        key: 'upload_xlsx_file'
    }, {
        id: 1,
        label: 'Column Mapping',
        key: 'column_mapping'
    }, {
        id: 2,
        label: 'Import',
        key: 'import'
    }, {
        id: 3,
        label: 'Done',
        key: 'done'
    }
]

const STEP_IMPORT_AVOSTORE = [
    {
        id: 0,
        label: 'Upload XLSX File',
        key: 'upload_xlsx_file'
    }, {
        id: 1,
        label: 'Import',
        key: 'import'
    }, {
        id: 2,
        label: 'Done',
        key: 'done'
    }
]

const REVENUE_AND_COMPARATION = [
    {
        attribute: 'Gross Sale',
        key: 'gross_sales'
    },
    {
        attribute: 'Voucher',
        key: 'voucher'
    },
    {
        attribute: 'Point',
        key: 'point'
    },
    {
        attribute: 'Membership Discount',
        key: 'discount_membership'
    },
    {
        attribute: 'Birthday Discount',
        key: 'discount_birthday'
    },
    {
        attribute: 'Net Sales',
        key: 'net_sales'
    },
    {
        attribute: 'Taxes',
        key: 'tax'
    },
    {
        attribute: 'Shipping',
        key: 'shipping'
    },
    {
        attribute: 'Total Sales',
        key: 'total_sales'
    },
]

const ORDER_AND_COMPARATION = [
    {
        attribute: 'Total Sales',
        key: 'net_sales',
        type: 'price'
    }, {
        attribute: 'Rata-Rata Item Per Order',
        key: 'average_item_per_order',
        type: 'count'
    }, {
        attribute: 'Total Order',
        key: 'total_order',
        type: 'count'
    }, {
        attribute: 'Nilai Rata-Rata Per Order',
        key: 'average_value_per_order',
        type: 'price'
    }
]

const PRODUCT_AND_COMPARATION = [
    {
        attribute: 'Total Product Sold',
        key: 'total_product_sold',
        type: 'count'
    }, {
        attribute: 'Total Order',
        key: 'total_order',
        type: 'count'
    }, {
        attribute: 'Total Product Price',
        key: 'total_product_price',
        type: 'price'
    }
]

const VOUCHER_AND_COMPARATION = [
    {
        attribute: 'Total Order Voucher',
        key: 'total_order_voucher',
        type: 'count'
    }, {
        attribute: 'Total Voucher Amount',
        key: 'total_voucher_amount',
        type: 'price'
    }
]

const TAB_REVIEW_DETAIL = [
    {
        label: 'What is Your Skin Type ?',
        key: 'skin-types'
    }, {
        label: 'How Long You Wear It ?',
        key: 'how-long'
    }, {
        label: 'Package Quality ?',
        key: 'package'
    }, {
        label: 'How Is The Product Price?',
        key: 'product-price'
    }, {
        label: 'Are You Recommend This Product?',
        key: 'recommendation'
    }, {
        label: 'Will you repurchase this product?',
        key: 'repurchase'
    }
]

const RULES_IMAGE_UPLOAD = {
    extensions: [".jpg", ".jpeg", ".png", ".gif", ".webp"],
    size: 10000000
}

export {
    ROUTE_LIST,
    BREADCRUMB,
    SIDEBAR,
    COMMENT_DETAIL,
    TAB_LIST_ORDER,
    USER_DETAIL_TAB_LIST,
    USER_DETAIL_PROFILE,
    USER_DETAIL_ACCESSIBILITY,
    ADDRESS_ITEM_LIST_lEFT,
    ADDRESS_ITEM_LIST_RIGHT,
    STEP_IMPORT_PRODUCT,
    STEP_IMPORT_AVOSTORE,
    REVENUE_AND_COMPARATION,
    ORDER_AND_COMPARATION,
    PRODUCT_AND_COMPARATION,
    TAB_REVIEW_DETAIL,
    RULES_IMAGE_UPLOAD,
    VOUCHER_AND_COMPARATION
}
