import {createSlice} from '@reduxjs/toolkit'
import {headerSlidingTextModel} from "../models/crm-settings.model";

export const crmSettingsSlice = createSlice({
    name: 'crmSettings',
    initialState: {
        headerSlidingTextDetail: {...headerSlidingTextModel},
        crmSettingsErrorMessage: {},
    },
    reducers: {
        setCRMSettingsHeaderSlidingTextDetail: (state, action) => {
            state.headerSlidingTextDetail = {...state.headerSlidingTextDetail, ...action.payload}
        },

        setCRMSettingsErrorMessage: (state, action) => {
            state.crmSettingsErrorMessage = action.payload
        }
    },
})

export const {
    setCRMSettingsHeaderSlidingTextDetail,
    setCRMSettingsErrorMessage,
} = crmSettingsSlice.actions
const crmSettingsReducer = crmSettingsSlice.reducer

export default crmSettingsReducer
