import React, {useEffect, useRef, useState} from "react";
import ModalCardComponent from "../../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {CloseIconGreen, LoadingSvg, TrashIcon, UploadIcon, UserProfileDefault} from "../../../utils/image";
import InputComponent from "../../../components/input.component";
import {
    checkFieldValidate,
    convertDateToCustomFormat,
    convertErrorMessage,
    imageValidate
} from "../../../utils/general-variable";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility
} from "../../../reducers/tools.reducer";
import {setUserProfile, setUserStatistic} from "../../../reducers/user.reducer";

export const EditProfileModal = (props) => {
    const {onClose, form, data, userId} = props
    const dispatch = useDispatch()
    const {editProfile} = useSelector(state => state.userReducer.modalVisibility)
    const [fieldList, setFieldList] = useState(form)
    const {fetching: postUpdateProfile, isLoading} = useFetch(API.USER_UPDATE_USER)
    const [imageProfile, setImageProfile] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const [gender, setGender] = useState(data[1].gender)
    const [action, setAction] = useState('')
    const {fetching: uploadPicture} = useFetch(API.USER_UPLOAD_PROFILE_PICTURE)
    const imageUploaded = useRef()

    const uploadImageProfile = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        const {isValid, message} = imageValidate(file)

        if (file) reader.readAsDataURL(file)

        if (isValid) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    uploadPicture({image: file}, null, {'Content-Type': 'multipart/form-data'}).then(response => {
                        const url = response?.data?.url
                        setImageProfile(url)
                        setImageUrl(url)
                    })
                    imageUploaded.current.value = null
                }
            }
        } else {
            showAlertModal(message)
        }
    }

    const deleteImageProfile = () => {
        setImageProfile(UserProfileDefault)
        setImageUrl(null)
        setAction('delete')
        imageUploaded.current.value = null
    }

    const onChangeInput = (key, value) => {
        setGender(value)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const elements = e.target
        const fieldItemsCopy = [...fieldList]
        const fieldItemsFilter = fieldItemsCopy.filter(field => field.key !== 'email' && field.key !== 'phone_number')
        const birthdate = elements.birthdate.value && elements.birthdate?.value.split('-')
        const body = {
            fullname: elements.fullname.value,
            phone_number: elements.phone_number.value ? `62${elements.phone_number.value}` : null,
            date_of_birth: !!birthdate ? Number(birthdate[2]) : null,
            month_of_birth: !!birthdate ? Number(birthdate[1]) : null,
            year_of_birth: !!birthdate ? Number(birthdate[0]) : null,
            gender: gender || null,
            image_url: imageUrl || (action === 'delete' ? null : data[0]?.image_url)
        }
        const isValid = checkFieldValidate(fieldItemsFilter, elements)
        if (isValid) {
            postUpdateProfile(body, userId).then(response => {
                const dataResponse = response?.data
                dispatch(setUserProfile(dataResponse))
                dispatch(setUserStatistic({fullname: dataResponse?.fullname, image_url: dataResponse?.image_url}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Profile Updated'))
                fieldItemsCopy.map(item => delete item.errorText)
                onClose('editProfile')
            }).catch(err => {
                setFieldList(convertErrorMessage(err, fieldList))
            })
        } else {
            setFieldList(fieldItemsCopy)
        }
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }

    useEffect(() => {
        return () => {
            setFieldList(fieldList.map(item => delete item.errorText))
        }
    }, [])

    return (
        <ModalCardComponent
            content={
                <div className={"absolute bg-white w-1/3 rounded-lg p-5"}>
                    <div className={"row justify-between items-center"}>
                        <div/>
                        <p className={"font-Montserrat-Bold text-monstera"}>Edit Profile</p>
                        <img src={CloseIconGreen} className={"w-4 cursor-pointer"}
                             onClick={() => onClose('editProfile')}/>
                    </div>
                    <div className={"col items-center mt-12 overflow-y-scroll"}>
                        <div className={"relative w-20 h-20"}>
                            <img src={imageProfile || data[0]?.image_url || UserProfileDefault}
                                 className={"rounded-full w-20 h-20 border-4 border-monstera"}/>
                            <div
                                className={"w-6 h-6 absolute bg-white top-0 right-0 rounded-full col justify-center items-center"}>
                                <img src={TrashIcon} className={"w-4 h-4 cursor-pointer"} onClick={deleteImageProfile}/>
                            </div>
                        </div>
                        <label
                            className={"cursor-pointer row items-center space-x-3 bg-monstera mt-3 py-2 px-4 rounded-md"}>
                            <img src={UploadIcon} className={"w-4"}/>
                            <p className={"font-Montserrat-Bold text-white"}>Unggah</p>
                            <input type={"file"}
                                   onChange={uploadImageProfile}
                                   className={"hidden"}
                                   ref={imageUploaded}
                            />
                        </label>

                        <form className={"w-full"} onSubmit={onSubmitHandler} name={'editProfile'}>
                            <div className={"w-full px-5 space-y-4"}>
                                {
                                    fieldList.map((item, index) =>
                                        <InputComponent item={item} key={index}
                                                        data={(item.key === 'birthdate' && !!data[1][item.key]) ?
                                                            convertDateToCustomFormat(data[1][item.key], "YYYY-MM-DD") :
                                                            item.key === 'gender' ? gender : data[1][item.key]}
                                                        disabled={item.key === 'email'} onChange={onChangeInput}
                                                        error={{[item.key]: item.errorText}}/>)
                                }
                                <div className={"row justify-center"}>
                                    <button
                                        className={"rounded-md shadow-inner bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-Montserrat-Bold px-10 py-2"}>
                                        {isLoading ? <img src={LoadingSvg} className={"w-4 mx-auto"}/> : 'Simpan'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
            visibility={editProfile}
            onClose={() => onClose('editProfile')}
        />
    )
}

