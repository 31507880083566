import SearchMapComponent from "./search-map";
import TabToggleComponent from "../../components/tab-toggle.component";
import React, {useEffect} from "react";
import GeneralForm from "./general-form";
import MarketplaceAndWebsiteForm from "./marketplace-and-website-form";
import {FIELD_LIST_GENERAL, FIELD_LIST_MARKETPLACE_AND_WEBSITE} from "../../utils/input-field-list";
import {useDispatch, useSelector} from "react-redux";
import {
    setAvostoreDetail,
    setAvostoreErrorMessage,
    setAvostoreInitialAvostoreDetail
} from "../../reducers/avostore.reducer";
import {cloneDeep} from "lodash";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingSvg} from "../../utils/image";
import {convertErrorMessage} from "../../utils/general-variable";

const CreateAvoStorePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const {avostoreDetail, avostoreErrorMessage} = useSelector(state => state.avostoreReducer)
    const {fetching: areaList} = useFetch(API.AREA_LIST, {simplified: true})
    const {fetching: postCreateAvostore, isLoading: loadingCreateAvostore} = useFetch(API.AVOSTORE_CREATE_AVOSTORE)
    const {
        fetching: postUpdateAvostore,
        isLoading: loadingUpdateAvostore
    } = useFetch({...API.AVOSTORE_UPDATE_AVOSTORE, ...{initPathVariable: id}})
    const {
        fetching: postSaveAsDraft,
        isLoading: loadingSaveAsDraft
    } = useFetch({...API.AVOSTORE_SAVE_AS_DRAFT_AVOSTORE, ...{initPathVariable: id}})
    let generalField = FIELD_LIST_GENERAL
    let marketplaceAndWebsiteField = FIELD_LIST_MARKETPLACE_AND_WEBSITE
    let searchLocationField = [{
        label: 'Search Location',
        key: 'latLang'
    }]
    const toggleList = [
        {
            title: 'General',
            content: <GeneralForm fieldList={generalField}/>
        }, {
            title: 'Marketplace & Website',
            content: <MarketplaceAndWebsiteForm fieldList={marketplaceAndWebsiteField}/>
        }
    ]

    const onChangeLocation = (value) => {
        dispatch(setAvostoreDetail({
            latitude: value[0],
            longitude: value[1]
        }))
    }

    useEffect(() => {
        areaList().then(response => {
            generalField[0][1].options = response.data
        })
        return () => {
            dispatch(setAvostoreErrorMessage({}))
            dispatch(setAvostoreInitialAvostoreDetail())
        }
    }, [])

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        navigate('/avostore/avostore-list')
    }

    const dataChecking = (field, value, buttonType) => {
        dispatch(setAvostoreErrorMessage({}))
        const avostoreDetailCopy = cloneDeep(avostoreDetail)
        let error = {}
        field.forEach(item => {
            if (item.required && !value[item.key]?.value) {
                error[item.key] = `${item.label} is required`
            } else if (item.type === 'text' || item.type === 'select') {
                avostoreDetailCopy[item.key] = value[item.key]?.value?.trim() || null
            }
        })
        
        if (Object.keys(error).length === 0) {
            dispatch(setAvostoreErrorMessage({}))
            return {isValid: true, avostore: avostoreDetailCopy}
        } else if (buttonType === 'draft') {
            dispatch(setAvostoreErrorMessage({name: error?.name}))
            return {isValid: !error?.name, avostore: avostoreDetailCopy}
        } else {
            dispatch(setAvostoreErrorMessage(error))
            return {isValid: false, avostore: avostoreDetailCopy}
        }
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = [...searchLocationField, ...generalField[0], ...generalField[1], ...generalField[2], ...marketplaceAndWebsiteField]
        let formValue = document.getElementById("form").elements
        const {isValid, avostore} = dataChecking(field, formValue, buttonType)
        if (isValid) {
            getPromiseApi(buttonType, avostore).then(() => {
                showAlertModal(getSuccessMessage(buttonType))
            }).catch(err => {
                dispatch(setAvostoreErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getSuccessMessage = (type) => {
        switch (type) {
            case 'publish':
                return 'Avostore Published'
            case 'update':
                return 'Avostore Updated'
            default:
                return 'Avostore Drafted'
        }
    }

    const getPromiseApi = (type, requestBody) => {
        switch (type) {
            case 'publish':
                return postCreateAvostore(requestBody)
            case 'update':
                return postUpdateAvostore(requestBody)
            default:
                return postSaveAsDraft(requestBody)
        }
    }

    return (
        <div className={"px-10 space-y-5 my-5"}>
            <SearchMapComponent item={searchLocationField}
                                defaultValue={id && [avostoreDetail?.['latitude'], avostoreDetail?.['longitude']]}
                                error={avostoreErrorMessage} onChangeLocation={onChangeLocation}/>
            <form id={"form"} className={"space-y-5"}>
                {
                    toggleList.map((item, index) =>
                        <TabToggleComponent
                            key={index}
                            title={item.title}
                            content={item.content}
                        />
                    )
                }
                <div className={"row font-semibold justify-center space-x-4 py-8"}>
                    <button onClick={(e) => onSubmitHandler('draft', e)}
                            className={"w-60 py-2 border border-fortress-grey hover:text-white hover:bg-dazzle rounded-lg"}>
                        {loadingSaveAsDraft ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save As Draft'}
                    </button>
                    <button onClick={(e) => onSubmitHandler(!!id ? 'update' : 'publish', e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        {loadingCreateAvostore || loadingUpdateAvostore ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : !!id ? 'Update' : 'Publish'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateAvoStorePage
