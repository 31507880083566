import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {setAvostoreSelectedRowKeys} from "../../reducers/avostore.reducer";
import {convertDateToCustomFormat, statusRenderClassName} from "../../utils/general-variable";
import React from "react";
import {Link} from "react-router-dom";

const AvoStoreList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysAvostore} = useSelector(state => state.avostoreReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nama Store',
            classNameHeader: 'pl-5',
            width: 250,
            render: (record) => (
                <Link to={`/avostore/avostore-list/update-avostore/${record?.id}`}>
                    <p className={"pl-5 pr-10 text-monstera capitalize hover:font-Montserrat-Bold hover:underline"}>{record?.name}</p>
                </Link>
            )
        }, {
            name: 'Deskripsi',
            dataIndex: 'description',
            className: 'pr-10',
            width: 400,
            render: (description) => (
                <div dangerouslySetInnerHTML={{__html: description}} />
            )
        },
        {
            name: 'Area',
            dataIndex: 'area'
        }, {
            name: 'Created At',
            dataIndex: 'created_at',
            render: (created_at) => (
                <p>{convertDateToCustomFormat(created_at, 'YYYY/MM/DD HH:mm')}</p>
            )
        },
        {
            name: 'Status',
            dataIndex: 'status',
            classNameHeader: 'text-center',
            render: (status) => (
                <div className={"row justify-center"}>
                    <div
                        className={`w-20 py-1 font-Montserrat-Bold capitalize rounded-md 
                        ${statusRenderClassName(status).className}`}>
                        <p className={"text-center"}>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setAvostoreSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Store"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysAvostore}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default AvoStoreList
