import {useSelector} from "react-redux";

const TotalDataComponent = ({ subject }) => {
    const {totalData} = useSelector(state => state.toolsReducer)

    return (
        <div className={"row w-fit font-regular text-charcoal-dust italic capitalize"}>
            <p>Total {totalData} {subject || 'Order'}</p>
        </div>
    )
}

export default TotalDataComponent
