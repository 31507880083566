import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetail} from "../../reducers/product.reducer";

const SeoSettingsForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {productDetail, productErrorMessage} = useSelector(state => state.productReducer)

    const onChangeInput = (key, value) => {
        dispatch(setProductDetail({[key]: value}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[0].map((item, index) => (
                    <InputComponent key={index} item={item} data={productDetail[item.key]} error={productErrorMessage}/>
                ))}
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, index) => (
                    <InputComponent key={index} item={item} onChange={onChangeInput} onChangeInput={onChangeInput} data={productDetail[item.key]} error={productErrorMessage}/>
                ))}
            </div>
        </div>
    )
}

export default SeoSettingsForm
