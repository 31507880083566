import TableComponent from "../../components/table-component";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import {Link} from "react-router-dom";

const ProductList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Nama Produk',
            classNameHeader: 'pl-4',
            width: 450,
            render: (record) => (
                <Link to={`/product/product-list/update-product/${record.id}`}>
                    <p className={"font-regular capitalize text-forged-steel hover:font-Montserrat-Bold hover:underline p-4"}>{record.name}</p>
                </Link>
            )
        }, {
            name: 'Kode SKU',
            classNameHeader: 'text-center',
            dataIndex: 'sku',
            render: (sku) => (
                <p className={"font-regular text-forged-steel text-center"}>{sku}</p>
            )
        }, {
            name: 'Kode GS1',
            classNameHeader: 'text-center',
            dataIndex: 'gs1_barcode',
            render: (gs1_barcode) => (
                <p className={"font-regular text-forged-steel text-center"}>{gs1_barcode}</p>
            )
        }, {
            name: 'Product Terjual',
            classNameHeader: 'text-center',
            dataIndex: 'product_sold',
            render: (product_sold) => (
                <p className={"font-regular text-forged-steel text-center"}>{product_sold}</p>
            )
        }, {
            name: 'Total Order',
            classNameHeader: 'text-center',
            dataIndex: 'total_order',
            render: (total_order) => (
                <p className={"font-regular text-forged-steel text-center"}>{total_order}</p>
            )
        }, {
            name: 'Total Product Price',
            classNameHeader: 'text-center',
            dataIndex: 'total_product_price',
            render: (total_product_price) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(total_product_price)}</p>
            )
        }
    ]

    return (
        <div className={"col space-y-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default ProductList
