import {UnderMaintenanceImage} from "../../utils/image";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const UnderMaintenancePage = () => {
    const navigate = useNavigate()
    const {fetching: getPingServer} = useFetch(API.PING_SERVER)
    const [errorData, setErrorData] = useState({})

    useEffect(() => {
        getPingServer().then(() => {
            navigate('/')
        }).catch((err) => {
            setErrorData(err?.data)
        })
    }, [])

    return (
        <div className={"col h-screen items-center justify-center space-y-4"}>
            <img src={errorData?.image_url ?? UnderMaintenanceImage} alt="under-maintenance-image" className={"w-1/2 h-1/2"}/>
            <p className={"font-Montserrat-Bold text-dazzle text-lg"}>{errorData?.title ?? 'Under Maintenance'}</p>
            <div className={"text-dazzle"} dangerouslySetInnerHTML={{__html: errorData?.description ?? 'Will be Back Soon!'}} />
            <button className={"w-60 py-2 bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"} onClick={() => window.location.reload()}>Refresh Page</button>
        </div>
    )
}

export default UnderMaintenancePage