import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_AVO_STORIES} from "../../utils/sidebar-section-list";
import General from "./general";
import Cta from "./cta";
import TimeLine from "./timeline";
import RecyclingProgress from "./recycling-progress";
import CampaignBanner from "./campaign-banner";

const AvoStoriesPage = () => {
    const [activeSidebar, setActiveSidebar] = useState('general')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        general: <General/>,
        recycling_progress: <RecyclingProgress/>,
        campaign_banner: <CampaignBanner/>,
        time_line: <TimeLine/>,
        cta: <Cta/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'avo_stories', list: SECTION_AVO_STORIES}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default AvoStoriesPage
