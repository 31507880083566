import {
    CloseIcon,
    MinusCircleIcon,
    MoveArrowIcon,
    PlusCircleIcon
} from "../utils/image";
import React, {useRef, useState} from "react";
import ModalCardComponent from "./modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setProductDetail,
    setProductModalVisibility,
    setProductUploadFromImageType
} from "../reducers/product.reducer";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../reducers/tools.reducer";

const UploadMultipleImageComponent = ({item}) => {
    const dispatch = useDispatch()
    const {another_images} = useSelector(state => state.productReducer.productDetail)
    const [showImageIndex, setShowImageIndex] = useState(0)
    const {showImage} = useSelector(state => state.productReducer.modalVisibility)
    const [dragging, setDragging] = useState(false)
    const [currentDragIndex, setCurrentDragIndex] = useState(null)
    const dragNode = useRef()

    const showModalHandler = (id) => {
        setShowImageIndex(id)
        dispatch(setProductModalVisibility({showImage: true}))
    }

    const closeModalHandler = () => {
        dispatch(setProductModalVisibility({showImage: false}))
    }

    const uploadImageModal = () => {
        if (item.maxMount <= another_images.length) {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('warning'))
            dispatch(setToolsAlertMessage(`Can't upload image than ${item.maxMount}`))
        } else {
            dispatch(setProductModalVisibility({uploadImage: true}))
            dispatch(setProductUploadFromImageType('another_images'))
        }
    }

    const removeImageHandler = (id) => {
        dispatch(setProductDetail({another_images: another_images.filter((_, index) => index !== id)}))
    }

    const handleDragStart = (e, index) => {
        setCurrentDragIndex(index)
        dragNode.current = e.target
        dragNode.current.addEventListener('dragend', handleDragEnd)
        setDragging(true)
    }

    const handleDragEnter = (e, index) => {
        if(e.target !== dragNode.current){
            const imagesCopy = cloneDeep(another_images)
            imagesCopy.splice(index, 0, imagesCopy.splice(currentDragIndex, 1)[0])
            dispatch(setProductDetail({another_images: imagesCopy}))
            setCurrentDragIndex(index)
        }
    }

    const handleDragEnd = () => {
        setCurrentDragIndex(null)
        dragNode.current.removeEventListener('dragend', handleDragEnd)
        dragNode.current = null
        setDragging(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    return (
        <div>
            <div className={"col space-y-2"}>
                <div className={"grid grid-cols-5 gap-y-4 border border-placebo rounded-lg p-4"}>
                    {another_images.map((list, index) => (
                        <div draggable
                             onDragStart={(e) => handleDragStart(e, index)}
                             onDragEnter={dragging ? (e) => handleDragEnter(e, index) : null}
                             onDragOver={(e) => handleDragOver(e)}
                             key={index}
                             className={`w-fit relative bg-white ${!dragging ? 'group' : ''}`}
                        >
                            <img src={list.url} alt="imageUploaded"
                                 className={"w-20 h-20 border border-placebo rounded-md group-hover:border-monstera group-hover:opacity-50"}/>
                            <div className={"invisible group-hover:visible"}>
                                <img src={MinusCircleIcon} alt={"MinusCircleIcon"}
                                     className={"w-4 absolute -top-2 -right-2 cursor-pointer"}
                                     onClick={() => removeImageHandler(index)}/>
                                <img draggable={false} src={MoveArrowIcon} alt="MoveArrowIcon"
                                     className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-7 cursor-pointer cursor-grab"}
                                     onClick={() => showModalHandler(index)}/>
                            </div>
                        </div>
                    ))}
                    <div
                        className={"col items-center justify-center w-20 h-20 border border-monstera rounded-md bg-windmill-wings"}>
                        <img src={PlusCircleIcon} alt="PlusCircleIcon" className={"w-7 cursor-pointer"}
                             onClick={uploadImageModal}/>
                    </div>
                </div>
                <p className={"italic text-forged-steel"}>Maksimal {item?.maxMount} Foto</p>
            </div>
            <ModalCardComponent
                visibility={showImage}
                onClose={closeModalHandler}
                content={
                    <div className={"relative bg-white p-4"}>
                        <img src={another_images[showImageIndex]?.url} alt="imageUploaded"
                             className={"w-80"}/>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-2 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }/>
        </div>
    )
}

export default UploadMultipleImageComponent