import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_ABOUT_US} from "../../utils/sidebar-section-list";
import Intro from "./intro";
import Video from "./video";
import Section1 from "./section-1";
import Section2 from "./section-2";
import Articles from "./articles";

const AboutUs = () => {
    const [activeSidebar, setActiveSidebar] = useState('intro')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        intro: <Intro/>,
        video: <Video/>,
        'section_1': <Section1/>,
        'section_2': <Section2/>,
        articles: <Articles/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'about_us', list: SECTION_ABOUT_US}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default AboutUs