import {Link} from "react-router-dom";
import {LinkIcon} from "../../utils/image";
import React from "react";
import {useSelector} from "react-redux";

const ProofBeautyEmpties = () => {
    const {beautyEmptiesDetail} = useSelector(state => state.beautyEmptiesReducer)

    return (
        <div className={"col space-y-8 justify-center p-5 bg-white border border-fennel-stem rounded-lg"}>
            <p className={"font-Montserrat-Bold text-sm text-monstera text-center"}>Bukti Pengembalian</p>
            <div className={"col space-y-5"}>
                {beautyEmptiesDetail?.proofs.map((item, index) => (
                    <Link key={index} to={item?.url} target={"_blank"} className={"row space-x-4"}>
                        <img src={LinkIcon} alt="linkIcon" className={"w-5"}/>
                        <p className={"text-dazzle hover:font-Montserrat-Bold hover:underline"}>Bukti Pengembalian {index + 1}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ProofBeautyEmpties
