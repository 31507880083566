import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_AVO_STORIES_2} from "../../utils/sidebar-section-list";
import {useState} from "react";
import MainBanner from "./main-banner";
import SustainabilityProgress from "./sustainability-progress";
import CampaignVideo from "./campaign-video";
import CampaignBanner from "./campaign-banner";
import Form from "./form";
import Articles from "./articles";

const AvoStories2 = () => {
    const [activeSidebar, setActiveSidebar] = useState('main_banner')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        main_banner: <MainBanner/>,
        sustainability_progress: <SustainabilityProgress/>,
        campaign_video: <CampaignVideo/>,
        campaign_banner: <CampaignBanner/>,
        form: <Form/>,
        articles: <Articles/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'main_banner', list: SECTION_AVO_STORIES_2}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default AvoStories2
