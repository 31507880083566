import {createSlice} from '@reduxjs/toolkit'
import {MoreInfoModel, termsAndConditionModel} from "../models/more-info.model";

export const moreInfoSlice = createSlice({
    name: 'moreInfo',
    initialState: {
        moreInfoDetail: {...MoreInfoModel},
        termsAndConditionDetail: {...termsAndConditionModel},
        moreInfoErrorMessage: {},
        moreInfoItemErrorMessage: []
    },
    reducers: {
        setMoreInfoDetail: (state, action) => {
            state.moreInfoDetail = {...state.moreInfoDetail, ...action.payload}
        },
        setMoreInfoTermsAndConditionDetail: (state, action) => {
            state.termsAndConditionDetail = {...state.termsAndConditionDetail, ...action.payload}
        },
        setMoreInfoErrorMessage: (state, action) => {
            state.moreInfoErrorMessage = action.payload
        },
        setMoreInfoItemErrorMessage: (state, action) => {
            state.moreInfoItemErrorMessage = action.payload
        },
        setMoreInfoInitialMoreInfoDetail: (state) => {
            state.moreInfoDetail = {...MoreInfoModel}
        },
    },
})

export const {
    setMoreInfoDetail,
    setMoreInfoTermsAndConditionDetail,
    setMoreInfoErrorMessage,
    setMoreInfoItemErrorMessage,
    setMoreInfoInitialMoreInfoDetail
} = moreInfoSlice.actions
const moreInfoReducer = moreInfoSlice.reducer

export default moreInfoReducer
