import ModalCardComponent from "../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {CloseIcon} from "../utils/image";
import {setCommentModalVisibility} from "../reducers/comment.reducer";
import {COMMENT_DETAIL} from "../utils/constant";
import React from "react";

const CommentDetail = () => {
    const dispatch = useDispatch()
    const {commentDetail} = useSelector(state => state.commentReducer.modalVisibility)
    const {reviewData} = useSelector(state => state.reviewReducer)

    const closeModalHandler = () => {
        dispatch(setCommentModalVisibility({commentDetail: false}))
    }

    return (
        <ModalCardComponent
            visibility={commentDetail}
            onClose={closeModalHandler}
            content={
                <div
                    className={"col space-y-8 bg-white w-[40rem] max-h-[39rem] font-regular rounded-md relative p-10 overflow-y-scroll"}>
                    <p className={"text-sm text-center text-monstera font-semibold"}>Comment Detail</p>
                    <div className={"grid grid-cols-2 gap-y-5"}>
                        {COMMENT_DETAIL.map((list, index) => (
                            <div key={index} className={"col space-y-1 text-forged-steel"}>
                                <p className={"font-semibold"}>{list.question}</p>
                                <p>{(list.key === 'is_recommended' || list.key === 'is_repurchase') ? reviewData?.[list.key] ? 'Yes' : 'No' : reviewData?.[list.key]}</p>
                            </div>
                        ))}
                    </div>
                    <div className={"col space-y-4"}>
                        <p className={"text-forged-steel font-semibold"}>Comments</p>
                        <div dangerouslySetInnerHTML={{__html: reviewData?.review}} className={"leading-6"} />
                    </div>
                    <div className={"row justify-center cursor-pointer"} onClick={closeModalHandler}>
                        <p className={"py-2 px-20 font-semibold text-white bg-battleship-green hover:bg-monstera to-monstera rounded-lg"}>Tutup</p>
                    </div>
                    <img src={CloseIcon} alt="closeButton" className={"w-5 absolute right-5 -top-5 cursor-pointer"}
                         onClick={closeModalHandler}/>
                </div>
            }
        />
    )
}

export default CommentDetail
