import React from "react";
import {EditIcon} from "../../../utils/image";
import {setUserAddressDetail, setUserModalVisibility} from "../../../reducers/user.reducer";
import {useDispatch} from "react-redux";

export const AddressItem = ({form, data}) => {
    const dispatch = useDispatch()

    const showModalHandler = (data) => {
        dispatch(setUserAddressDetail(data))
        dispatch(setUserModalVisibility({editAddress: true}))
    }

    return (
        <div className={"border border-fennel-stem rounded-lg"}>
            <div
                className={"row items-center px-5 py-2 justify-between border-b rounded-t-md bg-doctor rounded-t-lg"}>
                <div className={"row items-center space-x-10 text-monstera font-Montserrat-Bold"}>
                    <p className={" text-lg"}>Alamat {data.title}</p>
                    {
                        data?.is_main_address ?
                            <p className={"bg-whisper-green px-3 py-1 rounded-md"}>Alamat Utama</p> : null
                    }
                </div>
                <button className={"row items-center space-x-2 bg-battleship-green hover:bg-monstera p-2 rounded-md"}
                        onClick={() => showModalHandler(data)}>
                    <img src={EditIcon} className={"w-4"}/>
                    <p className={"text-white font-bold text-xs"}>Edit Alamat</p>
                </button>
            </div>
            <div className={"grid grid-cols-2 gap-5 px-14 py-5"}>
                <div className={"space-y-4"}>
                    {
                        form[0].map((item, index) => (
                            <div key={index} className={"row space-x-5"}>
                                <p className={"font-Montserrat-Bold text-forged-steel w-36"}>{item.label}</p>
                                <p>{data[item.key]}</p>
                            </div>
                        ))
                    }
                </div>
                <div className={"space-y-4"}>
                    {
                        form[1].map((item, index) => (
                            <div key={index} className={"row space-x-5"}>
                                <p className={"font-Montserrat-Bold text-forged-steel w-36"}>{item.label}</p>
                                <p>{data[item.key]}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
