import CustomerFilter from "./customer-filter";
import CustomerList from "./customer-list";
import {useDispatch, useSelector} from "react-redux";
import {dateRangeToday, formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffect} from "react";
import {setToolsInitialState, setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import {SORT_LIST_CUSTOMER} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const CustomerPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_CUSTOMER?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        dateRange,
        filterCity,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
        cities: filterCity ?? [],
        levels: filter['levels'] ?? [],
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
    }
    const {
        data: response,
        fetching: getCustomerList,
        isLoading
    } = useFetch(API.CUSTOMER_LIST, queryParams, {isWithTotalData: true})

    useEffect(() => {
        dispatch(setToolsStartDateEndDate(dateRangeToday))
        return () => {
            dispatch(setToolsInitialState())
        }
    }, [])

    useEffectAfterMount(() => {
        getCustomerList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, dateRange, filterCity, filter, reload])

    return (
        <div className={"col px-10"}>
            <CustomerFilter/>
            <CustomerList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default CustomerPage
