export const headerSlidingTextModel = {
    is_show: false,
    texts: [{
        id: 'text',
        description: null,
        title: null,
        url: null
    }],
    delete_ids: []
}
