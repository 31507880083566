import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import BirthdayGiftList from "./birthday-gift-list";

const BirthdayGiftPage = () => {
    const {data: response, isLoading} = useFetch(API.GET_LEVEL_BIRTHDAY_GIFT_LIST, {}, {isFetching: true})

    return (
        <div className={"col px-10 my-5"}>
            <BirthdayGiftList dataSource={response?.data ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default BirthdayGiftPage
