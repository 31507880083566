import {createSlice} from '@reduxjs/toolkit'
import {userModel} from "../models/user.model";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        activeTab: 'profile',
        userDetail: {...userModel},
        userErrorMessage: {},
        userStatistic: {},
        userProfile: {},
        userProfileImage: null,
        userAddressList: [],
        userAddressDetail: {},
        selectedRowKeysUser: [],
        selectedRowKeysUserReview: [],
        selectedRowKeysUserReferral: [],
        modalVisibility: {
            editProfile: false,
            editAccess: false,
            changePassword: false,
            editAddress: false
        }
    },
    reducers: {
        setUserActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setUserDetail: (state, action) => {
            state.userDetail = {...state.userDetail, ...action.payload}
        },
        setUserErrorMessage: (state, action) => {
            state.userErrorMessage = action.payload
        },
        setUserStatistic: (state, action) => {
            state.userStatistic = {...state.userStatistic, ...action.payload}
        },
        setUserProfile: (state, action) => {
            state.userProfile = {...state.userProfile, ...action.payload}
        },
        setUserProfileImage: (state, action) => {
            state.userProfileImage = action.payload
        },
        setUserAddressList: (state, action) => {
            state.userAddressList = action.payload
        },
        setUserAddressDetail: (state, action) => {
            state.userAddressDetail = {...state.userAddressDetail, ...action.payload}
        },
        setUserSelectedRowKeys: (state, action) => {
            state.selectedRowKeysUser = action.payload
        },
        setUserReviewSelectedRowKeys: (state, action) => {
            state.selectedRowKeysUserReview = action.payload
        },
        setUserReferralSelectedRowKeys: (state, action) => {
            state.selectedRowKeysUserReferral = action.payload
        },
        setUserModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
        setUserInitialUserDetail: (state) => {
            state.userDetail = {...userModel}
        }
    },
})

export const {
    setUserActiveTab,
    setUserDetail,
    setUserErrorMessage,
    setUserStatistic,
    setUserProfile,
    setUserProfileImage,
    setUserAddressList,
    setUserAddressDetail,
    setUserSelectedRowKeys,
    setUserReviewSelectedRowKeys,
    setUserReferralSelectedRowKeys,
    setUserModalVisibility,
    setUserInitialUserDetail
} = userSlice.actions
const userReducer = userSlice.reducer

export default userReducer
