export const giftModel = {
    name: null,
    sku: null,
    gs1_barcode: null,
    price: null,
    stock_quantity: null,
    weight: null,
    height: null,
    length: null,
    width: null,
    description: null,
    image_url: null,
    image_alt_text: null,
    available_for_birthday_gift: false
}
