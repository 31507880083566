import {createSlice} from '@reduxjs/toolkit'
import {introModel, section1Model, section2Model, videoModel, articlesModel} from "../models/about-us.model";

export const aboutUsSlice = createSlice({
    name: 'aboutUs',
    initialState: {
        introDetail: {...introModel},
        videoDetail: {...videoModel},
        section1Detail: {...section1Model},
        section2Detail: {...section2Model},
        articlesDetail: {...articlesModel},
        aboutUsErrorMessage: {},
    },
    reducers: {
        setAboutUsIntroDetail: (state, action) => {
            state.introDetail = {...state.introDetail, ...action.payload}
        },
        setAboutUsVideoDetail: (state, action) => {
            state.videoDetail = {...state.videoDetail, ...action.payload}
        },
        setAboutUsSection1Detail: (state, action) => {
            state.section1Detail = {...state.section1Detail, ...action.payload}
        },
        setAboutUsSection2Detail: (state, action) => {
            state.section2Detail = {...state.section2Detail, ...action.payload}
        },
        setAboutUsArticlesDetail: (state, action) => {
            state.articlesDetail = {...state.articlesDetail, ...action.payload}
        },
        setAboutUsErrorMessage: (state, action) => {
            state.aboutUsErrorMessage = action.payload
        }
    },
})

export const {
    setAboutUsIntroDetail,
    setAboutUsVideoDetail,
    setAboutUsSection1Detail,
    setAboutUsSection2Detail,
    setAboutUsArticlesDetail,
    setAboutUsErrorMessage
} = aboutUsSlice.actions
const aboutUsReducer = aboutUsSlice.reducer

export default aboutUsReducer