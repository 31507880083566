import React from "react";
import TotalDataComponent from "../../../components/total-data.component";
import TableComponent from "../../../components/table-component";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    statusRenderClassName
} from "../../../utils/general-variable";

export const OrderList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Nomor Pesanan',
            dataIndex: 'order_code',
            classNameHeader: 'text-center',
            className: "text-eerie-black font-semibold text-center h-20"
        }, {
            name: 'Tanggal Order',
            dataIndex: 'created_at',
            classNameHeader: 'text-center',
            render: (created_at) => (
                <p className={"text-forged-steel text-center"}>{convertDateToCustomFormat(created_at, "DD/MM/YYYY hh:mm")}</p>
            )
        }, {
            name: 'Grand Total',
            dataIndex: 'grand_total',
            classNameHeader: 'text-center',
            render: (grand_total) => <p className={"text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(grand_total)}</p>
        }, {
            name: 'Status',
            classNameHeader: 'text-center',
            dataIndex: 'status',
            render: (status) => (
                <div className={"row justify-center "}>
                    <div
                        className={`text-center font-Montserrat-Bold capitalize py-1 mr-2 w-40 rounded-md ${statusRenderClassName(status).className}`}>
                        <p>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        }
    ]
    return (
        <div className={"space-y-5"}>
            <TotalDataComponent/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}
