import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMiraculousSeriesMiraculousItemDetail} from "../../../reducers/miraculous-series.reducer";

const ItemMiraculous = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {miraculousItemDetail, miraculousSeriesErrorMessage} = useSelector(state => state.miraculousSeriesReducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(miraculousItemDetail.item_list)
        copyItemList[index][key] = value
        dispatch(setMiraculousSeriesMiraculousItemDetail({item_list: copyItemList}))
    }

    return (
        <div className={"col space-y-4"}>
            <div className={"row space-x-16"}>
                {fieldList[0].map((item, i) => (
                    <div key={i} className={"col space-y-4 w-1/2"}>
                        <InputComponent item={item} data={miraculousItemDetail?.item_list?.[index]?.[item.key]}
                                        error={miraculousSeriesErrorMessage} onChange={onChange}/>
                    </div>
                ))}
            </div>
            <div className={"row space-x-16"}>
                <div className={"col space-y-4 w-1/2"}>
                    {fieldList[1].map((item, i) => (
                        <InputComponent key={i} item={item} data={miraculousItemDetail?.item_list?.[index]?.[item.key]}
                                        error={miraculousSeriesErrorMessage} onChangeInput={onChange}/>
                    ))}
                </div>
                <div className={"col space-y-4 w-1/2"}>
                    <InputComponent item={fieldList[2]} data={miraculousItemDetail?.item_list?.[index]?.[fieldList[2].key]}
                                    error={miraculousSeriesErrorMessage} onChange={onChange}/>
                </div>
            </div>
        </div>
    )
}

export default ItemMiraculous