import {useDispatch, useSelector} from "react-redux";
import {setMiraculousRefiningSeriesResultDetail} from "../../../reducers/miraculous-refining-series.reducer";
import InputComponent from "../../../components/input.component";
import React from "react";

const InputField = ({fieldList}) => {
    const dispatch = useDispatch()
    const {resultDetail, miraculousRefiningSeriesErrorMessage} = useSelector(state => state.miraculousRefiningSeriesReducer)

    const onChange = (key, value) => {
        dispatch(setMiraculousRefiningSeriesResultDetail({[key]: value}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                <InputComponent item={fieldList[0]} data={resultDetail?.[fieldList[0].key]} error={miraculousRefiningSeriesErrorMessage} onChange={onChange}/>
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, i) => (
                    <InputComponent key={i} item={item} data={resultDetail?.[item.key]} error={miraculousRefiningSeriesErrorMessage} onChangeInput={onChange} onChange={onChange}/>
                ))}
            </div>
        </div>
    )
}

export default InputField