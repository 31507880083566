import TabToggleComponent from "../../components/tab-toggle.component";
import RevenueFilter from "./revenue-filter";
import RevenueList from "./revenue-list";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {SORT_LIST_REVENUE} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabRevenuePerDay = () => {
    const defaultSort = SORT_LIST_REVENUE?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        dateRange,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        startDate: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
        endDate: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
    }
    const {
        data: response,
        fetching: getRevenueDailyData,
        isLoading
    } = useFetch(API.ANALYTIC_REVENUE_DAILY_DATA, queryParams, {isWithTotalData: true})

    useEffectAfterMount(() => {
        getRevenueDailyData()
    }, [currentPage, pageSize, sort, dateRange, reload])

    return (
        <TabToggleComponent
            title={'Revenue Per Day'}
            content={
                <div className={"col space-y-4"}>
                    <RevenueFilter/>
                    <RevenueList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
                </div>
            }
        />
    )
}

export default TabRevenuePerDay