import CONFIG from "../config/env";
import {API} from "../services/api";
import axios from "axios";

const BASE_URL = CONFIG.API_ENDPOINT
let instance = null;

export function createInstance(config) {
    let headers = config?.headers
    if (!headers) {
        headers = {
            Authorization: `Bearer ${getLocalAccesToken('access_token')}`
        }
    }

    return axios.create({
        baseURL: BASE_URL,
        timeout: +CONFIG.REQUEST_TIMEOUT || 60000,
        headers
    })
}


function makeInstance() {

    if (instance) return instance;

    instance = axios.create({
        baseURL: BASE_URL,
        timeout: +CONFIG.REQUEST_TIMEOUT || 60000,
    })

    instance.interceptors.request.use((config) => {
        // Do something before request is sent
        let token
        if (config.url === API.LOGOUT.api) {
            token = getLocalAccesToken('refresh_token')
        } else {
            token = getLocalAccesToken('access_token')
        }
        if (token) config.headers.Authorization = `Bearer ${token}`
        config.headers['Accept-Language'] = 'en'
        return config
    }, (error) => {
        // Do something with request error
        return Promise.reject(error);
    })

    return instance
}

export const getLocalAccesToken = (type) => {
    try {
        const token = JSON.parse(localStorage.getItem('token'))
        return token?.[type]
    } catch (error) {
        console.log('failed get local token', error)
    }
    return null
}

export const axiosInstance = makeInstance()

export const setAuthHeader = accessToken => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export const resetAuthHeader = () => {
    delete axiosInstance.defaults.headers.common.Authorization;
}

export default axiosInstance
