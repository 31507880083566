import {LightStar, LoadingSvg} from "../../utils/image";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useParams} from "react-router-dom";
import React from "react";

const ReviewDetailHeader = () => {
    const {id} = useParams()
    const {
        data: response,
        isLoading
    } = useFetch({...API.PRODUCT_REVIEW_STATISTIC, ...{initPathVariable: id}}, {}, {isFetching: true})
    const detailData = response?.data

    return (
        <div className={"row items-center justify-between font-regular py-3 px-7 bg-doctor mx-10"}>
            {isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                <>
                    <div className={"row w-7/12 items-center space-x-8"}>
                        <img src={detailData?.image?.url} alt={detailData?.image?.alt_text} className={"w-24"}/>
                        <p className={"text-xl text-monstera"}>{detailData?.name}</p>
                    </div>
                    <div className={"row space-x-4"}>
                        <div className={"col space-y-1 py-3 px-6 bg-white border border-fennel-stem rounded-lg"}>
                            <p className={"font-Montserrat-Bold text-monstera"}>Total Review</p>
                            <p className={"text-2xl text-forged-steel text-center"}>{detailData?.total_review}</p>
                        </div>
                        <div className={"col space-y-1 py-3 px-6 bg-white border border-fennel-stem rounded-lg"}>
                            <p className={"font-Montserrat-Bold text-monstera"}>Total Rating</p>
                            <div className={"row justify-center items-center space-x-2"}>
                                <img src={LightStar} alt="starRating" className={"w-6"}/>
                                <p className={"text-2xl text-forged-steel"}>{detailData?.total_rating}</p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ReviewDetailHeader