import Users from "../assets/images/svg/users.svg"
import UsersWhite from "../assets/images/svg/users-white.svg"
import Products from "../assets/images/svg/product-logo.svg"
import ProductsWhite from "../assets/images/svg/package (1).svg"
import Comment from "../assets/images/svg/message-square.svg"
import CommentWhite from "../assets/images/svg/message-square (1).svg"
import Orders from "../assets/images/svg/shopping-bag.svg"
import OrdersWhite from "../assets/images/svg/shopping-bag (1).svg"
import Vouchers from "../assets/images/svg/voucher.svg"
import VouchersWhite from "../assets/images/svg/tag (2).svg"
import Analytics from "../assets/images/svg/trending-up.svg"
import AnalyticsWhite from "../assets/images/svg/trending-up (1).svg"
import AvoStore from "../assets/images/svg/map-pin 2.svg"
import AvoStoreWhite from "../assets/images/svg/map-pin 3.svg"
import PartnerStore from "../assets/images/svg/briefcase.svg"
import PartnerStoreWhite from "../assets/images/svg/briefcase (1).svg"
import MainPage from "../assets/images/svg/home.svg"
import MainPageWhite from "../assets/images/svg/home (1).svg"
import SpecialSeries from "../assets/images/svg/star.svg"
import SpecialSeriesWhite from "../assets/images/svg/star (1).svg"
import LandingPage from "../assets/images/svg/layout.svg"
import LandingPageWhite from "../assets/images/svg/layout (1).svg"
import RecycleProgram from "../assets/images/svg/rotate-ccw.svg"
import RecycleProgramWhite from "../assets/images/svg/rotate-ccw (1).svg"
import MoreInfoPage from "../assets/images/svg/info.svg"
import MoreInfoPageWhite from "../assets/images/svg/info (1).svg"
import ImageProfile from "../assets/images/png/Group 73.png"
import SmallArrowDown from "../assets/images/svg/Polygon 6.svg"
import ChevronRight from "../assets/images/svg/Vector 2.svg"
import AvoskinLogo from "../assets/images/svg/avoskin-logo.svg"
import AvoskinLogoTransparent from "../assets/images/svg/avoskin-logo-shadow.svg"
import ArrowRight from "../assets/images/svg/chevron-right.svg"
import ChevronDownIcon from "../assets/images/svg/Group 391.png"
import XCircleIcon from "../assets/images/svg/x-circle.svg"
import CheckCircleIcon from "../assets/images/svg/check-circle.svg"
import ImageUser from "../assets/images/png/Group 70.png"
import JntLogo from "../assets/images/expedition/jnt.png"
import JneLogo from "../assets/images/expedition/jne.png"
import RpxLogo from "../assets/images/expedition/rpx.png"
import SicepatLogo from "../assets/images/expedition/sicepat.png"
import PosLogo from "../assets/images/expedition/pos.png"
import AnterAjaLogo from "../assets/images/expedition/anter-aja.png"
import LightStar from "../assets/images/svg/Star 4.svg"
import DarkStar from "../assets/images/svg/Star 5.svg"
import ImageUserIcon from "../assets/images/png/Ellipse 57.png"
import BcaLogo from "../assets/images/png/New_Logo_JNE 2.png"
import BniLogo from "../assets/images/png/bni.png"
import MandiriLogo from "../assets/images/png/mandiri.png"
import GopayLogo from "../assets/images/png/gopay_landscape.png"
import VoucherGratisOngkirImage from "../assets/images/png/Voucher - Gratis Ongkir.png"
import VoucherDiskonImage from "../assets/images/png/Voucher - Diskon.png"
import VoucherFreeGiftImage from "../assets/images/png/Voucher - Free Gift.png"
import VoucherW4ChangeGiftImage from "../assets/images/png/Voucher - Waste 4 Change.png"
import AvoCoinImage from "../assets/images/png/Avo Coin 1.png"
import ImageProductDummy from "../assets/images/png/Rectangle 145.png"
import ChevronRightIcon from "../assets/images/svg/chevron-right.svg"
import ImageProductDetailDummy from "../assets/images/png/Rectangle 402.png"
import ThumbsUpIcon from "../assets/images/svg/thumbs-up.svg"
import ThumbsDownIcon from "../assets/images/svg/thumbs-down.svg"
import ButtonPrevIcon from "../assets/images/svg/Group 470.svg"
import ButtonNextIcon from "../assets/images/svg/Group 469.svg"
import ArrowDownIcon from "../assets/images/svg/Polygon 6.svg"
import CloseIcon from "../assets/images/svg/x.svg"
import PrevIcon from "../assets/images/svg/Group 295.svg"
import NextIcon from "../assets/images/svg/Group 298.svg"
import CalendarIcon from "../assets/images/svg/calendar.svg"
import CalendarWhiteIcon from "../assets/images/svg/calendar (1).svg"
import ArrowLeftIcon from "../assets/images/svg/Group 295.svg"
import ArrowRightIcon from "../assets/images/svg/Group 298.svg"
import ExportIcon from "../assets/images/svg/external-link.svg"
import FilterIcon from "../assets/images/svg/filter.svg"
import FilterWhiteIcon from "../assets/images/svg/filter-white.svg"
import SearchIcon from "../assets/images/svg/search.svg"
import ListIcon from "../assets/images/png/list.png"
import EyeIcon from "../assets/images/svg/eye.svg"
import ChevronUpWhiteIcon from "../assets/images/png/chevron-up.png"
import ChevronDownWhiteIcon from "../assets/images/png/chevron-down.png"
import LoadingSvg from "../assets/images/svg/loading-trans.svg"
import infoIcon from "../assets/images/svg/info.svg"
import questionIcon from "../assets/images/svg/help-circle.svg"
import EditIcon from "../assets/images/svg/edit.svg"
import KeyIcon from "../assets/images/svg/key.svg"
import CloseIconGreen from "../assets/images/svg/x-green.svg"
import TrashIcon from "../assets/images/svg/trash-2.svg"
import UploadIcon from "../assets/images/svg/upload.svg"
import UserProfileDefault from "../assets/images/svg/Group 74.svg"
import SwitchBorderOn from "../assets/images/svg/Group 492.svg"
import ArrowUpGreen from "../assets/images/svg/arrow-up.svg"
import ArrowDownRed from "../assets/images/svg/arrow-down.svg"
import PlusCircleIcon from "../assets/images/svg/plus-circle.svg"
import MinusCircleIcon from "../assets/images/svg/minus-circle.svg"
import MoveArrowIcon from "../assets/images/svg/move.svg"
import EditYellowIcon from "../assets/images/svg/edit-3.svg"
import DragAndDropIcon from "../assets/images/svg/Group 93.svg"
import ImportSuccessIcon from "../assets/images/svg/Group 375.svg"
import PlusIcon from "../assets/images/svg/plus.svg"
import ImportAvostoreSuccessIcon from "../assets/images/svg/Group 2146.svg"
import SearchIconWhite from "../assets/images/svg/search (1).svg"
import PinLocationIcon from "../assets/images/svg/Group 2137.svg"
import PlusCircleIconWhite from "../assets/images/svg/plus-circle-white.svg"
import CreditCardLogo from "../assets/images/webp/credit_card.webp"
import PermataBankLogo from "../assets/images/png/permata_bank.png"
import BriLogo from "../assets/images/png/bri.png"
import AlfamartLogo from "../assets/images/png/alfamart.png"
import AkulakuLogo from "../assets/images/svg/akulaku_paylater_standard_single_graphic.svg"
import ShopeePayLogo from "../assets/images/svg/shopeepay_square_orange.svg"
import ArrowDownGreen from "../assets/images/svg/arrow-down (1).svg"
import WarningCircleIcon from "../assets/images/svg/warning.svg"
import Settings from "../assets/images/svg/settings (1).svg"
import SettingsWhite from "../assets/images/svg/settings.svg"
import DownloadIcon from "../assets/images/svg/Group 419.svg"
import ProductGiftDefaultIcon from "../assets/images/png/No Preview - Transparan.png"
import VoucherDefaultIcon from "../assets/images/png/Avoskin Voucher 2 - Tidak Miring & Transparan.png"
import UnderMaintenanceImage from "../assets/images/png/under-maintenance.png"
import Notifications from "../assets/images/svg/bell.svg"
import NotificationsWhite from "../assets/images/svg/bell-white.svg"
import MinusIcon from "../assets/images/svg/minus.svg"
import CopyIcon from "../assets/images/svg/copy.svg"
import CheckIcon from "../assets/images/svg/check.svg"
import GiftIcon from "../assets/images/svg/gift.svg"
import GiftWhite from "../assets/images/svg/gift-white.svg"
import TrashIconWhite from "../assets/images/svg/trash-2-white.svg"
import ThreeDotsIcon from "../assets/images/svg/more-horizontal.svg"
import ChevronRightIconWhite from "../assets/images/svg/chevron-right-white.svg"
import LinkIcon from "../assets/images/svg/link.svg"
import ArrowUpWhite from "../assets/images/svg/arrow-up-white.svg"
import ArrowDownWhite from "../assets/images/svg/arrow-down-white.svg"

export {
    Users,
    UsersWhite,
    Products,
    ProductsWhite,
    Comment,
    CommentWhite,
    Orders,
    OrdersWhite,
    Vouchers,
    VouchersWhite,
    AvoStore,
    AvoStoreWhite,
    PartnerStore,
    PartnerStoreWhite,
    MainPage,
    MainPageWhite,
    SpecialSeries,
    SpecialSeriesWhite,
    LandingPage,
    LandingPageWhite,
    RecycleProgram,
    RecycleProgramWhite,
    MoreInfoPage,
    MoreInfoPageWhite,
    Analytics,
    AnalyticsWhite,
    ImageProfile,
    SmallArrowDown,
    ChevronRight,
    AvoskinLogo,
    AvoskinLogoTransparent,
    ArrowRight,
    ChevronDownIcon,
    XCircleIcon,
    CheckCircleIcon,
    ImageUser,
    JntLogo,
    JneLogo,
    RpxLogo,
    SicepatLogo,
    PosLogo,
    AnterAjaLogo,
    LightStar,
    DarkStar,
    ImageUserIcon,
    BcaLogo,
    BniLogo,
    MandiriLogo,
    GopayLogo,
    VoucherGratisOngkirImage,
    VoucherDiskonImage,
    VoucherFreeGiftImage,
    VoucherW4ChangeGiftImage,
    AvoCoinImage,
    ImageProductDummy,
    ChevronRightIcon,
    ImageProductDetailDummy,
    ThumbsUpIcon,
    ThumbsDownIcon,
    ButtonPrevIcon,
    ButtonNextIcon,
    ArrowDownIcon,
    CloseIcon,
    PrevIcon,
    NextIcon,
    CalendarIcon,
    CalendarWhiteIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ExportIcon,
    FilterIcon,
    FilterWhiteIcon,
    SearchIcon,
    ListIcon,
    EyeIcon,
    ChevronUpWhiteIcon,
    ChevronDownWhiteIcon,
    LoadingSvg,
    infoIcon,
    questionIcon,
    EditIcon,
    KeyIcon,
    CloseIconGreen,
    TrashIcon,
    UploadIcon,
    UserProfileDefault,
    SwitchBorderOn,
    ArrowUpGreen,
    ArrowDownRed,
    PlusCircleIcon,
    MinusCircleIcon,
    MoveArrowIcon,
    EditYellowIcon,
    DragAndDropIcon,
    ImportSuccessIcon,
    PlusIcon,
    ImportAvostoreSuccessIcon,
    SearchIconWhite,
    PinLocationIcon,
    PlusCircleIconWhite,
    CreditCardLogo,
    PermataBankLogo,
    BriLogo,
    AlfamartLogo,
    AkulakuLogo,
    ShopeePayLogo,
    ArrowDownGreen,
    WarningCircleIcon,
    Settings,
    SettingsWhite,
    DownloadIcon,
    ProductGiftDefaultIcon,
    VoucherDefaultIcon,
    UnderMaintenanceImage,
    Notifications,
    NotificationsWhite,
    MinusIcon,
    CopyIcon,
    CheckIcon,
    GiftIcon,
    GiftWhite,
    TrashIconWhite,
    ThreeDotsIcon,
    ChevronRightIconWhite,
    LinkIcon,
    ArrowUpWhite,
    ArrowDownWhite
}
