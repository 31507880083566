import React, {useEffect, useState} from "react";
import {
    convertDateToCustomFormat,
    convertUnixToMoment, dateRangeToday,
    formatDateEndOfUnix, formatDateStartOfUnix,
    formatDateToReadable
} from "../utils/general-variable";
import {CalendarIcon, ArrowLeftIcon, ArrowRightIcon, CalendarWhiteIcon} from "../utils/image";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "./modal-card.component";
import {setToolsModalVisibility} from "../reducers/tools.reducer";

const idLocale = require('moment/locale/id');

const DateRangePickerComponent = ({slug, onChange }) => {
    // X is month before
    // Y is this month

    const dispatch = useDispatch()
    const {dateRange, modalVisibility} = useSelector(state => state.toolsReducer)
    const dateMoment = moment()
    const [y, setY] = useState(dateMoment)
    const [x, setX] = useState(moment().subtract(1, 'months'))
    const [startDateSelected, setStartDateSelected] = useState(null)
    const [endDateSelected, setEndDateSelected] = useState(null)
    const [dateArrayX, setDateArrayX] = useState([])
    const [dateArrayY, setDateArrayY] = useState([])
    const [dateBeforeArrayX, setDateBeforeArrayX] = useState([])
    const [dateBeforeArrayY, setDateBeforeArrayY] = useState([])
    const [dateAfterArrayX, setDateAfterArrayX] = useState([])
    const [dateAfterArrayY, setDateAfterArrayY] = useState([])

    useEffect(() => {
        if (!!dateRange[0]) {
            setStartDateSelected(dateRange[0])
            setEndDateSelected(dateRange[1])
        } else {
            setStartDateSelected(null)
            setEndDateSelected(null)
        }
    }, [dateRange])


    const showModalHandler = () => {
        dispatch(setToolsModalVisibility({dateRange: true}))
    }

    useEffect(() => {
        constructDateArrayX()
        constructDateArrayY()
    }, [x, y])

    const constructDateArrayX = () => {
        moment.locale('id', idLocale);
        let arrayDateX = []
        let monthBeforeArrayX = []
        let monthAfterArrayX = []
        let yearX = moment(x).get('year')
        let monthX = moment(x).get('month')
        let dateEndX = 40 - (new Date(yearX, monthX, 40).getDate())
        let dateStartX = new Date(yearX, monthX).getDay()

        //generate array for before this month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(yearX, monthX - 1), constructMonth(monthX - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStartX - 1); i <= dateBeforeEnd; i++) {
            let c = moment(String(`${constructYearNew(yearX, monthX - 1)}-${constructMonth(monthX)}-${i}`)).startOf('days')
            monthBeforeArrayX.push(c)
        }

        //generate array for this month
        for (let i = 1; i <= dateEndX; i++) {
            let c = moment(String(`${yearX}-${monthX + 1}-${i}`))
            arrayDateX.push(c)
        }

        //generate array for after this month
        let totalDate = monthBeforeArrayX.length + arrayDateX.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let c = moment(String(`${constructYear(yearX, monthX + 1, 'plus')}-${constructMonth(monthX + 2)}-${i}`))
            monthAfterArrayX.push(c)
        }

        setDateBeforeArrayX(monthBeforeArrayX)
        setDateArrayX(arrayDateX)
        setDateAfterArrayX(monthAfterArrayX)
    }

    const constructDateArrayY = () => {
        let arrayDateY = []
        let monthBeforeArrayY = []
        let monthAfterArrayY = []
        let yearY = moment(y).get('year')
        let monthY = moment(y).get('month')
        let dateEndY = 40 - (new Date(yearY, monthY, 40).getDate())
        let dateStartY = new Date(yearY, monthY).getDay()

        //generate array for before this month
        let dateBeforeEnd = 40 - (new Date(constructYearNew(yearY, monthY - 1), constructMonth(monthY - 1), 40).getDate())
        for (let i = dateBeforeEnd - (dateStartY - 1); i <= dateBeforeEnd; i++) {
            let c = moment(String(`${constructYearNew(yearY, monthY - 1)}-${constructMonth(monthY)}-${i}`)).startOf('days')
            monthBeforeArrayY.push(c)
        }

        //generate array for this month
        for (let i = 1; i <= dateEndY; i++) {
            let c = moment(String(`${yearY}-${monthY + 1}-${i}`))
            arrayDateY.push(c)
        }

        //generate array for after this month
        let totalDate = monthBeforeArrayY.length + arrayDateY.length
        let dateLeft = 42 - totalDate
        for (let i = 1; i <= dateLeft; i++) {
            let c = moment(String(`${constructYear(yearY, monthY + 1, 'plus')}-${constructMonth(monthY + 2)}-${i}`))
            monthAfterArrayY.push(c)
        }

        setDateArrayY(arrayDateY)
        setDateBeforeArrayY(monthBeforeArrayY)
        setDateAfterArrayY(monthAfterArrayY)
    }

    const constructMonth = (m) => {
        if (m === 13) {
            return 1
        } else if (m === 0) {
            return 12
        } else {
            return m
        }
    }

    const constructYearNew = (y, m) => {
        if (m === 13) {
            return y + 1
        } else if (m === -1) {
            return y - 1
        } else {
            return y
        }
    }

    const constructYear = (y, m, type) => {
        if (type === 'minus') {
            if (m === 13) {
                return y - 1
            } else {
                return y
            }
        } else {
            if (m === 12) {
                return y + 1
            } else {
                return y
            }
        }
    }

    const clearButtonHandler = () => {
        const a = moment()
        const b = moment(a).subtract(1, 'months')
        const isAnalyticsPage = slug === 'analytics' || slug === 'analytics-customer'
        onChange(isAnalyticsPage ? dateRangeToday : [null, null])
        setStartDateSelected(isAnalyticsPage ? dateRangeToday[0] : null)
        setEndDateSelected(isAnalyticsPage ? dateRangeToday[1] : null)
        setX(b)
        setY(a)
        dispatch(setToolsModalVisibility({dateRange: false}))
    }

    const decreaseIncreaseMonth = (slug) => {
        let todayX = null;
        let todayY = null;
        if (slug === 'before') {
            todayX = moment(x).subtract(1, 'months')
            todayY = moment(y).subtract(1, 'months')
        } else {
            todayX = moment(x).add(1, 'months')
            todayY = moment(y).add(1, 'months')
        }

        setY(todayY)
        setX(todayX)
    }

    const dateButtonHandler = (item, slug) => {
        if (!!startDateSelected && !endDateSelected) {
            setEndDateSelected(formatDateEndOfUnix(item))
        } else if (!!startDateSelected && !!endDateSelected) {
            setStartDateSelected(formatDateStartOfUnix(item))
            setEndDateSelected(null)
        } else {
            setStartDateSelected(formatDateStartOfUnix(item))
        }
    }

    const isDisabled = (item) => {
        if (moment(item).isAfter()) {
            return true
        } else if ((!!startDateSelected && !endDateSelected) && moment(item).isBefore(convertUnixToMoment(startDateSelected))) {
            return true
        } else {
            return false
        }
    }

    const applyHandler = () => {
        onChange([startDateSelected, endDateSelected])
        dispatch(setToolsModalVisibility({dateRange: false}))
    }

    const closeModalHandler = () => {
        dispatch(setToolsModalVisibility({dateRange: false}))
    }

    const renderButtonItem = (item, index, slug) => {
        if (slug === 'main') {
            return (
                <button type={"button"} key={index} onClick={() => dateButtonHandler(item, slug)}
                        disabled={isDisabled(item)}
                        className={`row justify-center items-center h-8 disabled:text-gray-400 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-monstera hover:rounded-sm ${moment(item).isSame(dateMoment, 'day') && 'font-semibold border border-monstera rounded-sm'} ${(moment(item).isSame(convertUnixToMoment(endDateSelected), 'day') || moment(item).isSame(convertUnixToMoment(startDateSelected), 'day')) && 'font-semibold bg-monstera text-white rounded-sm'} ${moment(item).isBetween(convertUnixToMoment(startDateSelected), convertUnixToMoment(endDateSelected), 'day') && 'bg-windmill-wings'}`}>
                    <p className={"text-xs font-regular"}>{moment(item).date()}</p>
                </button>
            )
        } else {
            return (
                <button type={"button"} key={index}
                        disabled={isDisabled(item)}
                        onClick={() => dateButtonHandler(item, slug)}
                        className={`row justify-center items-center h-8 disabled:text-gray-400 disabled:rounded-none disabled:cursor-not-allowed hover:text-white hover:bg-monstera hover:rounded-sm text-gray-300`}>
                    <p className={"text-xs font-regular"}>{moment(item).date()}</p>
                </button>
            )
        }
    }

    return (
        <div className={"text-xs font-regular text-eerie-black relative"}>
            <button
                type={"button"}
                onClick={showModalHandler}
                className={`row items-center space-x-2 rounded-lg p-2 border border-placebo whitespace-nowrap overflow-hidden w-44 
                ${slug === 'analytics' && 'bg-battleship-green hover:bg-monstera text-white'}`}>
                <img src={slug === 'analytics' ? CalendarWhiteIcon : CalendarIcon} className={"h-5"}/>
                <div className={"row font-semibold"}>{
                    !!dateRange[0] ?
                        <p>{formatDateToReadable(dateRange[0], 'DD MMM YY')} - {formatDateToReadable(dateRange[1], 'DD MMM YY')}</p> :
                        <p>Filter Tanggal</p>
                }
                </div>
            </button>
            <ModalCardComponent
                visibility={modalVisibility.dateRange}
                onClose={closeModalHandler}
                content={
                    <div
                        className={"row justify-center absolute z-10 mt-3 w-[500px]"}>
                        <div className={"bg-white rounded-b shadow-md"}>
                            <div className={"row justify-between w-full font-semibold text-monstera bg-windmill-wings"}>
                                <div className={"row justify-center w-1/2 py-3"}>
                                    <p>Dari Tanggal</p>
                                </div>
                                <div className={"row justify-center w-1/2 py-3"}>
                                    <p>Hingga Tanggal</p>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"px-5 py-2 border-r border-b border-apple-flower"}>
                                    <div className={"row items-center justify-between"}>
                                        <img src={ArrowLeftIcon} className={"w-5 cursor-pointer"}
                                             onClick={() => decreaseIncreaseMonth('before')}/>
                                        <p className={"font-semibold text-monstera text-sm"}>{convertDateToCustomFormat(x, 'MMMM YYYY')}</p>
                                        <div/>
                                    </div>
                                    <div className={"grid grid-cols-7 pt-2"}>
                                        {
                                            ['M', 'S', 'S', 'R', 'K', 'J', 'S'].map((item, index) => (
                                                <div key={index} className={"row justify-center items-center h-8 w-8"}>
                                                    <p className={"text-sm font-semibold text-black"}>{item}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={"grid grid-cols-7 gap-y-1 space-x-0.5"}>
                                        {dateBeforeArrayX.map((item, index) => renderButtonItem(item, index, 'before'))}
                                        {dateArrayX.map((item, index) => renderButtonItem(item, index, 'main'))}
                                        {dateAfterArrayX.map((item, index) => renderButtonItem(item, index, 'after'))}
                                    </div>
                                </div>
                                <div className={"px-5 py-2 border-b border-apple-flower"}>
                                    <div className={"row items-center justify-between"}>
                                        <div/>
                                        <p className={"font-semibold text-monstera text-sm"}>{convertDateToCustomFormat(y, 'MMMM YYYY')}</p>
                                        {
                                            !moment(y).isSame(moment(), 'month') ?
                                                <img src={ArrowRightIcon} className={"w-5 cursor-pointer"}
                                                     onClick={() => decreaseIncreaseMonth('after')}/> :
                                                <div/>
                                        }
                                    </div>
                                    <div className={`grid grid-cols-7 ${moment(y).isSame(moment(), 'month') ? 'pt-2' : 'pt-2'}`}>
                                        {
                                            ['M', 'S', 'S', 'R', 'K', 'J', 'S'].map((item, index) => (
                                                <div key={index} className={"row justify-center items-center h-8 w-8"}>
                                                    <p className={"text-sm font-semibold text-black"}>{item}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={"grid grid-cols-7 gap-y-1 space-x-0.5"}>
                                        {dateBeforeArrayY.map((item, index) => renderButtonItem(item, index, 'before'))}
                                        {dateArrayY.map((item, index) => renderButtonItem(item, index, 'main'))}
                                        {dateAfterArrayY.map((item, index) => renderButtonItem(item, index, 'after'))}
                                    </div>
                                </div>
                            </div>
                            <div className={"row justify-between items-center p-3"}>
                                <div/>
                                <div className={`row justify-center py-2 px-20 font-semibold text-white rounded-lg ${endDateSelected ? 'bg-battleship-green hover:bg-monstera cursor-pointer' : 'bg-fortress-grey cursor-not-allowed'}`}
                                     onClick={endDateSelected ? applyHandler : undefined}>
                                    <p>Apply</p>
                                </div>
                                <button type={"button"} className={"font-semibold"} onClick={clearButtonHandler}>
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default DateRangePickerComponent
