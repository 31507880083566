export const userModel = {
    fullname: null,
    email: null,
    password: null,
    role_id: null,
    phone_number: null,
    date_of_birth: null,
    month_of_birth: null,
    year_of_birth: null,
    gender: null,
    image_url: null
}