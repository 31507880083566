//Homepage
const FIELD_LIST_SLIDER = [
    {
        label: 'Desktop Banner (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'link_banner',
        type: 'text',
        placeholder: 'link to page / external url'
    }
]

const FIELD_LIST_SERIES_GENERAL = [
    {
        label: 'Title Section',
        key: 'title_section',
        type: 'text',
        placeholder: 'masukkan judul section'
    }, {
        label: 'Description Section',
        key: 'description_section',
        type: 'text_area',
        placeholder: 'masukkan deskripsi section'
    }
]

const FIELD_LIST_SERIES_ITEM = [
    {
        label: 'Desktop Mini Banner',
        key: 'desktop_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Mini Banner',
        key: 'mobile_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Mini Banner',
        key: 'link_mini_banner',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }
]

const FIELD_LIST_FLASH_SALE_GENERAL = [
    {
        label: 'Desktop Mini Banner (Aspect Ration 3:4)',
        key: 'desktop_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Mini Banner (Aspect Ration 1:1)',
        key: 'mobile_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Icon Flash Sale',
        key: 'icon_flash_sale',
        type: 'file_image',
        slug: 'page',
    }, {
        label: 'Start Date & Time',
        key: 'start_date_time',
        type: 'date',
        includeTime: true,
        placeholder: 'masukkan tanggal berlaku flash sale'
    }, {
        label: 'End Date & Time',
        key: 'end_date_time',
        type: 'date',
        includeTime: true,
        placeholder: 'masukkan tanggal berakhir flash sale'
    }, {
        label: 'Title Flash Sale Button',
        key: 'title_flash_sale_button',
        type: 'text',
        placeholder: 'judul flash sale',
    }, {
        label: 'Link / URL Flash Sale Button',
        key: 'link_flash_sale_button',
        type: 'text',
        placeholder: 'link to page / external url',
    }
]

const FIELD_LIST_BEST_SELLER_GENERAL = [
    {
        label: 'Title Section',
        key: 'title_section',
        type: 'text',
        placeholder: 'masukkan judul section'
    }, {
        label: 'Link / URL Direction',
        key: 'link_direction',
        type: 'text',
        placeholder: 'link to page / external url'
    }, {
        label: 'Description Section',
        key: 'description_section',
        type: 'text_area',
        placeholder: 'masukkan deskripsi section'
    }, {
        label: 'Title Link Button',
        key: 'title_link_button',
        type: 'text',
        placeholder: 'judul flash sale'
    }
]

const FIELD_LIST_RECOMMENDATION_GENERAL = [
    {
        label: 'Title Section',
        key: 'title_section',
        type: 'text',
        placeholder: 'masukkan judul section'
    }, {
        label: 'Description Section',
        key: 'description_section',
        type: 'text_area',
        placeholder: 'masukkan deskripsi section'
    }
]

const FIELD_LIST_PRODUCT_RECOMMENDATION = [
    {
        label: 'Desktop Mini Banner (Aspect Ration 3:4)',
        key: 'desktop_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Mini Banner (Aspect Ration 1:1)',
        key: 'mobile_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Recommendation Label',
        key: 'recommendation_label',
        type: 'text',
        placeholder: 'masukkan judul section'
    }, {
        label: 'Title Button',
        key: 'title_button',
        type: 'text',
        placeholder: 'judul flash sale'
    }, {
        label: 'Link / URL Direction Button',
        key: 'link_direction_button',
        type: 'text',
        placeholder: 'link to page / external url'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'home_page_sale',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

const FIELD_LIST_TESTIMONIAL = [
    [{
        label: 'Testimonial Image (Aspect Ration 1:1)',
        key: 'testimonial_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }], [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title testimoni'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'deskripsi testimoni'
    }]
]

const FIELD_LIST_THE_CAROUSEL_GENERAL = [
    [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title the carousel'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'description the carousel'
    }], [{
        label: 'Total Article',
        key: 'total_article',
        type: 'number',
        placeholder: 'jumlah artikel ditampilkan'
    }, {
        label: 'Title Link Button',
        key: 'title_link_button',
        type: 'text',
        placeholder: 'judul untuk the carousel'
    }, {
        label: 'Link / URL Direction',
        key: 'link_direction',
        type: 'text',
        placeholder: 'link to page / external url'
    }]
]

const FIELD_LIST_SHOP_BY_CATEGORY_ITEM = [
    [{
        label: 'Product Image (Aspect Ration 1:1)',
        key: 'product_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }], [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'category title'
    }, {
        label: 'Link / URL Product',
        key: 'link_product',
        type: 'text',
        placeholder: 'link to page / external url'
    }]
]

const FIELD_LIST_INPUT_BENEFIT_HOME_PAGE = [
    {
        label: 'Desktop Mini Banner (1440px x 300px)',
        key: 'desktop_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Mini Banner (393px x 737px)',
        key: 'mobile_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_SUSTAINABILITY_RUBI = [
    {
        label: 'Desktop Poster (Aspect Ration 9:16)',
        key: 'desktop_poster',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Poster (Aspect Ration 4:10)',
        key: 'mobile_poster',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Poster',
        key: 'link_poster',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }
]

const FIELD_LIST_CSR_ITEM = [
    {
        label: 'Desktop Banner (Aspect Ration 16:8)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 10:16)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'link_banner',
        type: 'text',
        placeholder: 'link to page / external url'
    }
]

const FIELD_LIST_CLAIM_ITEM = [
    {
        label: 'Icon / Image (Aspect Ration 1:1)',
        key: 'icon',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'claim title'
    }
]

const FIELD_LIST_SEO_GENERAL = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title the seo'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'description the seo'
    }
]

const FIELD_SELECT_PRODUCT = [{
    label: 'Select Product',
    key: 'select_product',
    type: 'multiple_select_product',
    subType: 'page',
    placeholder: 'search product',
    selectLabel: 'name',
    dataList: []
}]

//Waste 4 Change
const FIELD_LIST_INPUT_BANNER = [
    {
        label: 'Desktop Banner (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }
]

const FIELD_LIST_DESCRIPTION = [
    {
        label: 'Title Deskripsi',
        key: 'title',
        type: 'text',
        placeholder: 'kosongkan jika tidak perlu'
    }, {
        label: 'Link / URL Banner',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }, {
        label: 'Image (Aspect Ration 1:1)',
        key: 'image_url',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Isi Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi'
    }
]

const FIELD_LIST_INPUT_REDEEM_PRODUCT = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'link redeem',
        required: true
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi'
    }, {
        label: 'Placeholder',
        key: 'placeholder',
        type: 'text',
        placeholder: 'placeholder redeem code',
        required: true
    }, {
        label: 'Button Redeem',
        key: 'button_title',
        type: 'text',
        placeholder: 'button title',
        required: true
    }, {
        label: 'Desktop Background (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Background (Aspect Ration 10:16)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_MINI_BANNER = [
    {
        label: 'Desktop Image (Aspect Ration 10:2)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Image (Aspect Ration 16:11)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }
]

const FIELD_LIST_INPUT_GIFT_BANNER = [
    {
        label: 'Desktop Image (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Image (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_BENEFIT = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section benefit',
        required: true
    }, {
        label: 'Link / URL Setor Kemasan',
        key: 'button_url',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }, {
        label: 'Isi Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi'
    }, {
        label: 'Title Button',
        key: 'button_title',
        type: 'text',
        placeholder: 'title button',
        required: true
    }
]

const FIELD_LIST_INPUT_IMAGE_BENEFIT = {
    label: 'Image 1 (Aspect Ration 1:1)',
    key: 'images',
    type: 'multiple_file_image'
}

//Beauty Friday
const FIELD_LIST_BANNER_ITEM = [
    {
        label: 'Desktop Banner (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'link_banner',
        type: 'text',
        placeholder: 'link to page / external url'
    }
]

const FIELD_LIST_INPUT_POSTER = [
    {
        label: 'Desktop Poster (Aspect Ration 9:16)',
        key: 'desktop_poster',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Poster (Aspect Ration 4:10)',
        key: 'mobile_poster',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Poster',
        key: 'link_poster',
        type: 'text',
        placeholder: 'link to page / external url',
        required: true
    }
]

const FIELD_LIST_PROMOTED_PRODUCT = [
    {
        label: 'Product Image (Aspect Ration 1:1)',
        key: 'product_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Product',
        key: 'link_product',
        type: 'text',
        placeholder: 'link to page / external url'
    }
]

const FIELD_LIST_INPUT_PRODUCT_RECOMMENDATION = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section kontribusi'
    }, {
        label: 'Isi Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'home_page_sale',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

//Miraculous Series
const FIELD_LIST_INTRO_MIRACULOUS_SERIES = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section intro miraculous series'
    }, {
        label: 'Subtitle/Short Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }
]

const FIELD_LIST_MIRACULOUS_ITEM_MIRACULOUS_SERIES = [
    [{
        label: 'Thumbnail Desktop',
        key: 'thumbnail_desktop',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Thumbnail Mobile',
        key: 'thumbnail_mobile',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }], [{
        label: 'Title Miraculous Item Section',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan title'
    }, {
        label: 'Direction Link Miraculous Item Section',
        key: 'url',
        type: 'text',
        placeholder: 'masukkan url'
    }], {
        label: 'Subtitle/Short Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }
]

const FIELD_LIST_RELATED_PRODUCT_MIRACULOUS_SERIES = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'home_page_sale',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

//About Us
const FIELD_LIST_INTRO_ABOUT_US = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section intro about us'
    }, {
        label: 'Isi Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }
]

const FIELD_LIST_VIDEO_ABOUT_US = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section video about us'
    }, {
        label: 'Isi Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Link Youtube',
        key: 'url',
        type: 'text',
        placeholder: 'url youtube...'
    }
]

const FIELD_LIST_SECTION_ABOUT_US = [
    {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Desktop Banner',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_ARTICLES_ABOUT_US = [
    {
        label: 'Article Thumbnail (Aspect Ration 1:1)',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section title about us'
    }, {
        label: 'Date',
        key: 'date',
        type: 'date',
        placeholder: 'published date...'
    }, {
        label: 'Link/URL Article',
        key: 'url',
        type: 'text',
        placeholder: 'link to page/external url'
    }]
]

//Miraculous Retinol Series
const FIELD_LIST_BANNER_MIRACULOUS_RETINOL_SERIES = [
    {
        label: 'Desktop Banner (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'url',
        type: 'text',
        placeholder: 'link to page/external url'
    }
]

const FIELD_LIST_BENEFIT_MIRACULOUS_RETINOL_SERIES = [
    {
        label: 'Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title section benefit'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }]
]

const FIELD_LIST_VIDEO_MIRACULOUS_RETINOL_SERIES = [
    {
        label: 'Title Video',
        key: 'title',
        type: 'text',
        placeholder: 'judul section video miraculous retinol series'
    }, {
        label: 'Link Youtube',
        key: 'url',
        type: 'text',
        placeholder: 'url youtube...'
    }
]

const FIELD_LIST_INTRO_RESULT_MIRACULOUS_RETINOL_SERIES = [
    {
        label: 'Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title section intro'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }, {
        label: 'Button Title',
        key: 'button_title',
        type: 'text',
        placeholder: 'button title'
    }, {
        label: 'Button Direction Link',
        key: 'button_direction_link',
        type: 'text',
        placeholder: 'https://'
    }]
]

//Miraculous Refining Series
const FIELD_LIST_BANNER_MIRACULOUS_REFINING_SERIES = [
    {
        label: 'Desktop Banner (Aspect Ration 16:4)',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner (Aspect Ration 16:10)',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link / URL Banner',
        key: 'url',
        type: 'text',
        placeholder: 'link to page/external url'
    }
]

const FIELD_LIST_BENEFIT_MIRACULOUS_REFINING_SERIES = [
    {
        label: 'Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title section benefit'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }]
]

const FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES = [
    {
        label: 'Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title section intro'
    }, {
        label: 'Description',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis deskripsi singkat...'
    }, {
        label: 'Button Title',
        key: 'button_title',
        type: 'text',
        placeholder: 'button title'
    }, {
        label: 'Button Direction Link',
        key: 'button_direction_link',
        type: 'text',
        placeholder: 'https://'
    }]
]

//Your Skin Bae
const FIELD_LIST_PERSONALIZE_YOUR_SKIN_BAE = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'title'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'home_page_sale',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

//Promo
const FIELD_LIST_PROMO = [
    {
        label: 'Desktop Banner',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Title Promo',
        key: 'title_promo',
        type: 'text',
        placeholder: 'judul promo'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'page',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

//More Info Page
const FIELD_LIST_TERMS_AND_CONDITION = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan title',
        required: true
    }, {
        label: 'Isi Konten',
        key: 'description',
        type: 'text_editor',
        placeholder: 'tulis isi konten...'
    }
]

const FIELD_LIST_ERROR_404 = [
    [{
        label: 'Thumbnail',
        key: 'thumbnail',
        type: 'file_image',
        showAbove: true,
        title: 'Foto Thumbnail'
    }],
    [{
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan title',
        required: true
    }, {
        label: 'Slug',
        key: 'slug',
        type: 'text',
        placeholder: 'masukkan slug',
        required: true
    }, {
        label: 'Isi Konten',
        key: 'content',
        type: 'text_editor',
        placeholder: 'tulis isi konten...'
    }]
]

//Avo Stories
const FIELD_LIST_GENERAL_AVO_STORIES = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section general avo stories'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Mobile Image',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Desktop Image',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_RECYCLING_PROGRESS_AVO_STORIES = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section recycling progress avo stories'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'isi deskripsi...'
    }
]

const FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES = [
    {
        label: 'Mobile Image',
        key: 'mobile_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Desktop Image',
        key: 'desktop_mini_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_TIME_LINE_GENERAL_AVO_STORIES = [
    {
        label: 'Title Section',
        key: 'title_section',
        type: 'text',
        placeholder: 'masukkan judul section'
    }
]

const FIELD_LIST_TIME_LINE_AVO_STORIES = [
    {
        label: 'Year',
        key: 'year',
        type: 'text',
        placeholder: 'tahun stories'
    }, {
        label: 'Image',
        key: 'image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Short Description',
        key: 'short_description',
        type: 'text_editor',
        placeholder: 'tulis isi konten...'
    }, {
        label: 'Long Description',
        key: 'long_description',
        type: 'text_editor',
        placeholder: 'tulis isi konten...'
    }
]

const FIELD_LIST_CTA_AVO_STORIES = [
    {
        label: 'Mobile Image',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Desktop Image',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link/URL',
        key: 'url',
        type: 'text',
        placeholder: 'link to page/external url'
    }
]

//Avo Stories 2
const FIELD_LIST_MAIN_BANNER_AVO_STORIES_2 = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section main banner'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Title Button',
        key: 'title_button',
        type: 'text',
        placeholder: 'judul main banner button'
    }, {
        label: 'Link Button',
        key: 'link_direction_button',
        type: 'text',
        placeholder: 'link to page / external url',
    }
]

const FIELD_LIST_SUSTAINABILITY_PROGRESS_GENERAL_AVO_STORIES_2 = [
    {
        label: 'Title Section',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan judul section'
    }
]

const FIELD_LIST_SUSTAINABILITY_PROGRESS_AVO_STORIES_2 = [
    {
        label: 'Progress Update',
        key: 'title',
        type: 'number',
        placeholder: 'jumlah progress'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'isi deskripsi...'
    }
]

const FIELD_LIST_CAMPAIGN_VIDEO_AVO_STORIES_2 = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section main banner'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Link / URL Video',
        key: 'video_url',
        type: 'text',
        placeholder: 'link / url video'
    }
]

const FIELD_LIST_CAMPAIGN_BANNER_GENERAL_AVO_STORIES_2 = [
    {
        label: 'Title Section',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan judul section'
    }
]

const FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES_2 = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section main banner'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_FORM_AVO_STORIES_2 = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section form'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_editor',
        placeholder: 'isi deskripsi...'
    }, {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_ARTICLES_GENERAL_AVO_STORIES_2 = [
    {
        label: 'Title',
        key: 'title',
        type: 'text',
        placeholder: 'judul section articles'
    }, {
        label: 'Deskripsi',
        key: 'description',
        type: 'text_area',
        placeholder: 'isi deskripsi...'
    }
]

const FIELD_LIST_ARTICLES_AVO_STORIES_2 = {
    label: 'Articles',
    key: 'articles',
    type: 'multiple_select_filter',
    placeholder: 'search article',
    selectLabel: 'title',
    dataList: []
}

//Affiliation
const FIELD_LIST_AFFILIATION = [
    {
        label: 'Desktop Banner',
        key: 'desktop_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_banner',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Title Affiliate',
        key: 'title_affiliate',
        type: 'text',
        placeholder: 'judul affiliate'
    }, {
        label: 'Select Product',
        key: 'select_product',
        type: 'multiple_select_product',
        subType: 'page',
        placeholder: 'search product',
        selectLabel: 'name',
        dataList: []
    }
]

//Loyalty Program
const FIELD_LIST_INPUT_MAIN_BANNER_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link Direction',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url',
    }
]

const FIELD_LIST_INPUT_MEMBER_FLOW_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_BENEFIT_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_LEVELING_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_JOIN_MEMBER_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Link Direction',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url',
    }
]

const FIELD_LIST_INPUT_FAQ_LOYALTY_PROGRAM = [
    {
        label: 'Desktop Banner',
        key: 'desktop_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }, {
        label: 'Mobile Banner',
        key: 'mobile_image',
        type: 'file_image',
        slug: 'page',
        showAbove: true
    }
]

const FIELD_LIST_INPUT_SEO_LOYALTY_PROGRAM = [
    {
        label: 'Meta Title',
        key: 'title',
        type: 'text',
        placeholder: 'title the seo'
    }, {
        label: 'Meta Description',
        key: 'description',
        type: 'text_area',
        placeholder: 'description the seo'
    }
]

//CRM Settings
const FIELD_LIST_CRM_SETTING_HEADER_SLIDING_TEXT = [
    {
        label: 'Description Text',
        key: 'description',
        type: 'text_area',
        placeholder: 'masukkan deskripsi text'
    }, {
        label: 'Title Button',
        key: 'title',
        type: 'text',
        placeholder: 'masukkan judul button'
    }, {
        label: 'Link Button',
        key: 'url',
        type: 'text',
        placeholder: 'link to page / external url'
    }
]

export {
    //Homepage
    FIELD_LIST_SLIDER,
    FIELD_LIST_SERIES_GENERAL,
    FIELD_LIST_SERIES_ITEM,
    FIELD_LIST_FLASH_SALE_GENERAL,
    FIELD_LIST_BEST_SELLER_GENERAL,
    FIELD_LIST_RECOMMENDATION_GENERAL,
    FIELD_LIST_PRODUCT_RECOMMENDATION,
    FIELD_LIST_TESTIMONIAL,
    FIELD_LIST_THE_CAROUSEL_GENERAL,
    FIELD_LIST_SHOP_BY_CATEGORY_ITEM,
    FIELD_LIST_INPUT_BENEFIT_HOME_PAGE,
    FIELD_LIST_INPUT_SUSTAINABILITY_RUBI,
    FIELD_LIST_CSR_ITEM,
    FIELD_LIST_CLAIM_ITEM,
    FIELD_LIST_SEO_GENERAL,
    FIELD_SELECT_PRODUCT,
    //Waste 4 Change
    FIELD_LIST_INPUT_BANNER,
    FIELD_LIST_DESCRIPTION,
    FIELD_LIST_INPUT_REDEEM_PRODUCT,
    FIELD_LIST_INPUT_MINI_BANNER,
    FIELD_LIST_INPUT_GIFT_BANNER,
    FIELD_LIST_INPUT_BENEFIT,
    FIELD_LIST_INPUT_IMAGE_BENEFIT,
    //Beauty Friday
    FIELD_LIST_BANNER_ITEM,
    FIELD_LIST_INPUT_POSTER,
    FIELD_LIST_PROMOTED_PRODUCT,
    FIELD_LIST_INPUT_PRODUCT_RECOMMENDATION,
    //Miraculous Series
    FIELD_LIST_INTRO_MIRACULOUS_SERIES,
    FIELD_LIST_MIRACULOUS_ITEM_MIRACULOUS_SERIES,
    FIELD_LIST_RELATED_PRODUCT_MIRACULOUS_SERIES,
    //About Us
    FIELD_LIST_INTRO_ABOUT_US,
    FIELD_LIST_VIDEO_ABOUT_US,
    FIELD_LIST_SECTION_ABOUT_US,
    FIELD_LIST_ARTICLES_ABOUT_US,
    //Miraculous Retinol Series
    FIELD_LIST_BANNER_MIRACULOUS_RETINOL_SERIES,
    FIELD_LIST_BENEFIT_MIRACULOUS_RETINOL_SERIES,
    FIELD_LIST_VIDEO_MIRACULOUS_RETINOL_SERIES,
    FIELD_LIST_INTRO_RESULT_MIRACULOUS_RETINOL_SERIES,
    //Miraculous Refining Series
    FIELD_LIST_BANNER_MIRACULOUS_REFINING_SERIES,
    FIELD_LIST_BENEFIT_MIRACULOUS_REFINING_SERIES,
    FIELD_LIST_INTRO_RESULT_MIRACULOUS_REFINING_SERIES,
    //Your Skin Bae
    FIELD_LIST_PERSONALIZE_YOUR_SKIN_BAE,
    //Promo
    FIELD_LIST_PROMO,
    //More Info Page
    FIELD_LIST_TERMS_AND_CONDITION,
    FIELD_LIST_ERROR_404,
    //Avo Stories
    FIELD_LIST_GENERAL_AVO_STORIES,
    FIELD_LIST_RECYCLING_PROGRESS_AVO_STORIES,
    FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES,
    FIELD_LIST_TIME_LINE_GENERAL_AVO_STORIES,
    FIELD_LIST_TIME_LINE_AVO_STORIES,
    FIELD_LIST_CTA_AVO_STORIES,
    //Avo Stories 2
    FIELD_LIST_MAIN_BANNER_AVO_STORIES_2,
    FIELD_LIST_SUSTAINABILITY_PROGRESS_GENERAL_AVO_STORIES_2,
    FIELD_LIST_SUSTAINABILITY_PROGRESS_AVO_STORIES_2,
    FIELD_LIST_CAMPAIGN_VIDEO_AVO_STORIES_2,
    FIELD_LIST_CAMPAIGN_BANNER_GENERAL_AVO_STORIES_2,
    FIELD_LIST_CAMPAIGN_BANNER_AVO_STORIES_2,
    FIELD_LIST_FORM_AVO_STORIES_2,
    FIELD_LIST_ARTICLES_GENERAL_AVO_STORIES_2,
    FIELD_LIST_ARTICLES_AVO_STORIES_2,
    //Affiliation
    FIELD_LIST_AFFILIATION,
    //Loyalty Program
    FIELD_LIST_INPUT_MAIN_BANNER_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_MEMBER_FLOW_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_BENEFIT_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_LEVELING_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_JOIN_MEMBER_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_FAQ_LOYALTY_PROGRAM,
    FIELD_LIST_INPUT_SEO_LOYALTY_PROGRAM,
    //CRM Settings
    FIELD_LIST_CRM_SETTING_HEADER_SLIDING_TEXT
}
