import {FIELD_LIST_MIRACULOUS_ITEM_MIRACULOUS_SERIES} from "../../../utils/input-field-list-static-page";
import {randomCode} from "../../../utils/general-variable";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setMiraculousSeriesMiraculousItemDetail} from "../../../reducers/miraculous-series.reducer";
import ItemMiraculous from "./item-miraculous";
import FormItemComponent from "../../../components/static-page/form-item.component";

const MiraculousItem = () => {
    const dispatch = useDispatch()
    const {miraculousItemDetail} = useSelector(state => state.miraculousSeriesReducer)
    const itemList = [...miraculousItemDetail.item_list]
    let fieldList = FIELD_LIST_MIRACULOUS_ITEM_MIRACULOUS_SERIES
    let showItem = {
        label: 'Miraculous ItemMiraculous',
        key: 'show_item',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMiraculousSeriesMiraculousItemDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'miraculous_item').toString(),
            thumbnail_desktop: null,
            thumbnail_mobile: null,
            title: null,
            url: null,
            description: null
        })
        dispatch(setMiraculousSeriesMiraculousItemDetail({item_list: itemList}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(miraculousItemDetail)
        // submit form
    }

    return (
        <FormItemComponent
            data={miraculousItemDetail}
            switchToggleField={showItem}
            itemList={itemList}
            content={(index) => <ItemMiraculous fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMiraculousSeriesMiraculousItemDetail}
            deleteList={miraculousItemDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default MiraculousItem
