import {createSlice} from '@reduxjs/toolkit'
import {
    campaignBannerModel,
    ctaModel,
    generalModel,
    recyclingProgressModel,
    timelineModel
} from "../models/avo-stories.model";

export const avoStoriesSlice = createSlice({
    name: 'avoStories',
    initialState: {
        generalDetail: {...generalModel},
        recyclingProgressDetail: {...recyclingProgressModel},
        campaignBannerDetail: {...campaignBannerModel},
        timelineDetail: {...timelineModel},
        ctaDetail: {...ctaModel},
        avoStoriesErrorMessage: {},
    },
    reducers: {
        setAvoStoriesGeneralDetail: (state, action) => {
            state.generalDetail = {...state.generalDetail, ...action.payload}
        },
        setAvoStoriesRecyclingProgressDetail: (state, action) => {
            state.recyclingProgressDetail = {...state.recyclingProgressDetail, ...action.payload}
        },
        setAvoStoriesCampaignBannerDetail: (state, action) => {
            state.campaignBannerDetail = {...state.campaignBannerDetail, ...action.payload}
        },
        setAvoStoriesTimelineDetail: (state, action) => {
            state.timelineDetail = {...state.timelineDetail, ...action.payload}
        },
        setAvoStoriesCtaDetail: (state, action) => {
            state.ctaDetail = {...state.ctaDetail, ...action.payload}
        },
        setAvoStoriesErrorMessage: (state, action) => {
            state.avoStoriesErrorMessage = action.payload
        }
    },
})

export const {
    setAvoStoriesGeneralDetail,
    setAvoStoriesRecyclingProgressDetail,
    setAvoStoriesCampaignBannerDetail,
    setAvoStoriesTimelineDetail,
    setAvoStoriesCtaDetail,
    setAvoStoriesErrorMessage
} = avoStoriesSlice.actions
const avoStoriesReducer = avoStoriesSlice.reducer

export default avoStoriesReducer
