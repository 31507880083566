import {createSlice} from '@reduxjs/toolkit'
import {PromoModel} from "../models/promo.model";

export const promoSlice = createSlice({
    name: 'promo',
    initialState: {
        promoDetail: {...PromoModel},
        promoErrorMessage: {},
    },
    reducers: {
        setPromoDetail: (state, action) => {
            state.promoDetail = {...state.promoDetail, ...action.payload}
        },
        setPromoErrorMessage: (state, action) => {
            state.promoErrorMessage = action.payload
        }
    },
})

export const {setPromoDetail, setPromoErrorMessage} = promoSlice.actions
const promoReducer = promoSlice.reducer

export default promoReducer