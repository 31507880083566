import {createSlice} from '@reduxjs/toolkit'

export const roleSlice = createSlice({
    name: 'role',
    initialState: {
        selectedIds: [],
    },
    reducers: {
        setSelectedRoleIds: (state, action) => {
            state.selectedIds = action.payload
        }
    },
})

export const { setSelectedRoleIds } = roleSlice.actions
const roleReducer = roleSlice.reducer

export default roleReducer
