import React from "react";
import TableComponent from "../../../components/table-component";
import {convertDateToCustomFormat, doConvertNumberToRupiahFormat} from "../../../utils/general-variable";

export const Wishlist = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Produk',
            dataIndex: 'name',
            classNameHeader: 'pl-5',
            render: (name, record) => (
                <div className={"row items-center space-x-5 h-20 pl-5"}>
                    <img src={record?.image?.url} alt={record?.image?.alt_text} className={"w-10"}/>
                    <p>{name}</p>
                </div>
            )
        }, {
            name: 'SKU',
            dataIndex: 'sku',
            classNameHeader: 'text-center',
            className: 'text-forged-steel text-center'
        }, {
            name: 'Category',
            dataIndex: 'categories',
            classNameHeader: 'text-center',
            className: 'text-forged-steel text-center'
        }, {
            name: 'Price',
            classNameHeader: 'text-center',
            dataIndex: 'price',
            render: (price, record) => (
                <div className={"col items-center text-forged-steel justify-center"}>
                    {
                        record?.sale_price &&
                        <p className={'line-through'}>{doConvertNumberToRupiahFormat(price)}</p>
                    }
                    <p>{doConvertNumberToRupiahFormat(record?.sale_price ? record?.sale_price : price)}</p>
                </div>
            )
        }, {
            name: 'Added To Wishlist',
            dataIndex: 'created_at',
            classNameHeader: 'text-center',
            render: (created_at) => (
                <p className={"text-forged-steel text-center"}>{convertDateToCustomFormat(created_at, "DD/MM/YYYY hh:mm")}</p>
            )
        },
    ]

    return (
        <TableComponent
            columns={columns}
            tableClassName={"font-Regular"}
            headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
            dataSource={dataSource}
            pagination={true}
            loading={isLoading}
        />
    )
}
