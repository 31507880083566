import {useEffect} from "react";

export const useClickOutside = (elementId, isShow, setIsShowHandler) => {
    useEffect(() => {
        const clickOutsideListener = (e) => {
            if (document.getElementById(elementId).contains(e.target)){
                // Clicked in box
            } else {
                // Clicked outside the box
                setIsShowHandler(false)
            }
        }
        if (isShow) document.addEventListener("click", clickOutsideListener, true)
        return () => document.removeEventListener("click", clickOutsideListener, true)
    }, [isShow])
}
