import {useDispatch, useSelector} from "react-redux";
import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {EditYellowIcon, TrashIcon} from "../../utils/image";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import React, {useState} from "react";
import {setAreaDetail, setAreaModalVisibility, setAreaSelectedRowKeys} from "../../reducers/area.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";

const AreaList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const [areaId, setAreaId] = useState(null)
    const {reload} = useSelector(state => state.toolsReducer)
    const {selectedRowKeysArea} = useSelector(state => state.areaReducer)
    const {fetching: deleteArea} = useFetch(API.DELETE_AREA)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nama Area',
            dataIndex: 'name',
            width: 200,
            classNameHeader: 'pl-5',
            className: 'pl-5 capitalize'
        }, {
            name: 'Deskripsi',
            dataIndex: 'description',
            width: 600,
            render: (description) => (
                <div dangerouslySetInnerHTML={{__html: description}}/>
            )
        }, {
            name: 'Count',
            dataIndex: 'avostore_count',
            classNameHeader: 'text-center',
            render: (avostore_count) => (
                <p className={"text-center font-regular text-forged-steel"}>{avostore_count} Store</p>
            )
        }, {
            name: 'Action',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row justify-center items-center space-x-4 font-semibold"}>
                    <div className={"row items-center space-x-2 cursor-pointer"} onClick={() => openDeleteModal(record.id)}>
                        <img src={TrashIcon} alt="delete" className={"w-5"}/>
                        <p>Delete</p>
                    </div>
                    <div className={"text-placebo w-0.5 h-5 bg-placebo"}></div>
                    <div className={"row items-center space-x-2 cursor-pointer"} onClick={() => openUpdateModal(record)}>
                        <img src={EditYellowIcon} alt="edit" className={"w-5"}/>
                        <p>Edit</p>
                    </div>
                </div>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setAreaSelectedRowKeys(array))
    }

    const openUpdateModal = (data) => {
        dispatch(setAreaDetail(data))
        dispatch(setAreaModalVisibility({editArea: true}))
    }

    const openDeleteModal = (id) => {
        setAreaId(id)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus area ini?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const deleteAreaHandler = () => {
        deleteArea({}, areaId).then(() => {
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Area Deleted'))
            dispatch(setToolsModalVisibility({delete: false, alert: true}))
            dispatch(setToolsReload(!reload))
        }).catch(() => {
            dispatch(setToolsAlertMessage('Area tidak dapat dihapus'))
            dispatch(setToolsModalVisibility({delete: false}))
        })
    }

    return (
        <>
            <div className={"col space-y-4"}>
                <TotalDataComponent subject={"Store"}/>
                <TableComponent
                    columns={columns}
                    tableClassName={"font-Regular"}
                    headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                    selectedRowKeys={selectedRowKeysArea}
                    onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                    dataSource={dataSource}
                    pagination={true}
                    loading={isLoading}
                />
            </div>
            <DeleteConfirmationComponent onDelete={deleteAreaHandler}/>
        </>
    )
}

export default AreaList
