import SettingsForm from "./settings-form";
import RestrictionForm from "./restriction-form";
import UsageLimitForm from "./usage-limit-form";
import VoucherAutoApplyForm from "./voucher-auto-apply-form";
import {
    FIELD_LIST_CREATE_VOUCHER_AUTO_APPLY,
    FIELD_LIST_CREATE_VOUCHER_RESTRICTION,
    FIELD_LIST_CREATE_VOUCHER_SETTINGS,
    FIELD_LIST_CREATE_VOUCHER_USAGE_LIMIT
} from "../../utils/input-field-list";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import TabToggleComponent from "../../components/tab-toggle.component";
import {cloneDeep, every} from 'lodash'
import {setVoucherDetail, setVoucherErrorMessage, setVoucherType} from "../../reducers/voucher.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useParams} from "react-router-dom";
import {voucherModel} from "../../models/voucher.model";
import {convertErrorMessage, convertUtcToCustomFormat} from "../../utils/general-variable";

const CreateVoucherPage = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    let settingField = FIELD_LIST_CREATE_VOUCHER_SETTINGS
    let restrictionField = FIELD_LIST_CREATE_VOUCHER_RESTRICTION
    let limitField = FIELD_LIST_CREATE_VOUCHER_USAGE_LIMIT
    let autoApplyField = FIELD_LIST_CREATE_VOUCHER_AUTO_APPLY
    let {
        voucherType,
        showVoucherAutoApply,
        voucherDetail
    } = useSelector(state => state.voucherReducer)
    const {description} = voucherDetail
    const {fetching: getCategoryList} = useFetch(API.PARENT_CATEGORY_LIST, {}, {isFetching: false})
    const {fetching: getShippingMethod} = useFetch(API.SHIPPING_METHOD, {}, {isFetching: false})
    const {fetching: getPaymentMethod} = useFetch(API.PAYMENT_METHOD, {withoutGrouping: true}, {isFetching: false})
    const {fetching: getAllowedEmail} = useFetch(API.ALLOWED_EMAIL, {}, {isFetching: false})
    const {fetching: createVoucher} = useFetch(API.CREATE_VOUCHER, {}, {isFetching: false})
    const {fetching: draftVoucher} = useFetch(API.DRAFT_VOUCHER, {}, {isFetching: false})
    const {fetching: updateVoucher} = useFetch(API.UPDATE_VOUCHER, {}, {isFetching: false})

    useEffect(() => {
        Promise.all([ getCategoryList(), getShippingMethod(), getPaymentMethod(), getAllowedEmail()])
            .then(response => {
                restrictionField[6].dataList = response[0].data
                restrictionField[7].dataList = response[0].data
                restrictionField[8].dataList = response[1].data
                restrictionField[9].dataList = response[2].data
                restrictionField[10].dataList = response[3].data
            })
            .catch(err => {
                console.log(err)
            })

        return () => {
            dispatch(setVoucherErrorMessage({}))
            dispatch(setVoucherDetail({...voucherModel}))
            dispatch(setVoucherType(''))
        }
    }, [])

    const toggleList = [
        {
            title: `${voucherType === 'free_gift' || voucherType === 'waste4change' ? 'Gift' : 'General'} Settings`,
            tooltipText: 'Ini General Settings',
            content: <SettingsForm fieldList={settingField}/>,
            showToggle: true,
        }, {
            title: 'Restriction',
            content: <RestrictionForm fieldList={restrictionField}/>,
            showToggle: true,
        },
        {
            title: 'Usage Limit',
            content: <UsageLimitForm fieldList={limitField}/>,
            showToggle: true,
        }, {
            title: 'Voucher Auto Apply',
            tooltipText: 'Ini Voucher Auto Apply',
            content: <VoucherAutoApplyForm fieldList={autoApplyField}/>,
            showToggle: showVoucherAutoApply
        },
    ]

    const dataChecking = (field, value) => {
        dispatch(setVoucherErrorMessage({}))
        let error = {}
        field.forEach((item) => {
            switch (item.key) {
                case 'description':
                    if (!description) {
                        error[item.key] = `${item.label} is required`
                    }
                    break;
                case 'gifts':
                    if (value.type.value === 'free_gift') {
                        if (voucherDetail[item.key].length === 0) {
                            error[item.key] = `${item.label} is required`
                        } else if (voucherDetail[item.key].length !== 0) {
                            let check = every(voucherDetail[item.key], 'quantity')
                            if (!check) {
                                error[item.key] = `Quantity is required`
                            }
                        }
                    } else if(value.type.value === 'avo_wfc') {
                        if (voucherDetail[item.key].length === 0) {
                            error[item.key] = `${item.label} is required`
                        }
                    }
                    break;
                case 'discount_type':
                case 'amount':
                    if ((value.type.value !== 'free_gift' && value.type.value !== 'avo_wfc') && !value[item.key]?.value) {
                        error[item.key] = `${item.label} is required`
                    }
                    break;
                default:
                    if (item.required && !value[item.key]?.value) {
                        error[item.key] = `${item.label} is required`
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setVoucherErrorMessage({}))
            return true
        } else {
            dispatch(setVoucherErrorMessage(error))
            return false
        }
    }

    const dataConstruct = (field, value) => {
        const voucherDetailCopy = cloneDeep(voucherModel)
        field.forEach((item) => {
            switch (item.key) {
                case 'discount_type':
                    voucherDetailCopy[item.key] = !!value[item.key]?.value ? value[item.key]?.value : 'percentage'
                    break
                case 'is_shown_in_checkout':
                    voucherDetailCopy[item.key] = voucherDetail[item.key]
                    break;
                case 'minimum_amount':
                case 'maximum_amount':
                    voucherDetailCopy.restriction[item.key] = Number(value[item.key]?.value)
                    break;
                case 'max_amount':
                case 'amount':
                    voucherDetailCopy[item.key] = value[item.key]?.value.length > 0 ? Number(value[item.key]?.value) : 0
                    break;
                case 'usage_limit':
                case 'usage_limit_per_user':
                case 'usage_limit_to_x_item':
                    voucherDetailCopy.limit[item.key] = Number(value[item.key]?.value)
                    break;
                case 'individual_use':
                case 'exclude_sale_items':
                    voucherDetailCopy.restriction[item.key] = !!value[item.key]?.checked
                    break;
                case 'auto_apply':
                    voucherDetailCopy[item.key] = !!value[item.key]?.value
                    break;
                case 'gifts':
                    if (voucherDetail[item.key].length > 0) {
                        let gifts = voucherDetail.gifts.map(gift => {
                            let giftsItem = {
                                gift_id: gift.id,
                                quantity: Number(gift?.quantity) || 1,
                                is_free_shipping: value.free_shipping.checked
                            }
                            return giftsItem
                        })
                        voucherDetailCopy[item.key] = gifts
                    }
                    break;
                case 'include_product_ids':
                case 'exclude_product_ids':
                    if (voucherDetail.restriction[item.key].length > 0) {
                        let values = []
                        let array = voucherDetail.restriction[item.key]
                        array.forEach(i => {
                            values.push(i.id)
                        })
                        voucherDetailCopy.restriction[item.key] = values
                    }
                    break;
                case 'terms_and_conditions':
                case 'description':
                    voucherDetailCopy[item.key] = voucherDetail[item.key]
                    break;
                case 'thumbnail':
                    voucherDetailCopy[item.key] = !!voucherDetail[item.key] ? voucherDetail[item.key] : null
                    break;
                case 'include_category_ids':
                case 'exclude_category_ids':
                    if (value[item.key]?.length > 0) {
                        let values = []
                        let array = value[item.key]
                        array.forEach(i => {
                            if (i.checked) {
                                values.push(Number(i.value))
                            }
                        })
                        voucherDetailCopy.restriction[item.key] = values
                    }
                    break;
                case 'payment_method':
                case 'shipping_courier':
                    if (value[item.key]?.length > 0) {
                        let values = []
                        let array = value[item.key]
                        array.forEach(i => {
                            if (i.checked) {
                                values.push(i.value)
                            }
                        })
                        voucherDetailCopy.restriction[item.key] = values
                    }
                    break;
                case 'allowed_email':
                    if (voucherDetail.restriction[item.key]?.length > 0) {
                        let emails = []
                        voucherDetail.restriction[item.key].forEach(i => {
                            emails.push(!!i.email ? i.email : i)
                        })
                        voucherDetailCopy.restriction[item.key] = emails
                    }
                    break;
                case 'date_expires':
                case 'date_start':
                    voucherDetailCopy[item.key] = value[item.key]?.value.length > 0 ? convertUtcToCustomFormat(value[item.key]?.value, "YYYY-MM-DD HH:mm") : null
                    break
                default:
                    voucherDetailCopy[item.key] = value[item.key]?.value
            }
        })

        return voucherDetailCopy
    }

    const getCombineValidation = (detail) => {
        let valid = false
        restrictionField.map(item => {
            switch (item.key) {
                case 'include_product_ids':
                case 'exclude_product_ids':
                case 'include_category_ids':
                case 'exclude_category_ids':
                case 'shipping_courier':
                case 'payment_method':
                case 'allowed_email':
                    if (detail.restriction[item.key].length > 0) {
                        valid = true
                    }
                    break;
                default:
                    if (!!detail.restriction[item.key]) {
                        valid = true
                    }
            }
        })
        return valid
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = [...settingField[0], ...settingField[1], ...restrictionField, ...limitField, ...autoApplyField]
        let formValue = document.getElementById("form").elements

        const isValid = dataChecking(field, formValue)

        if (isValid) {
            const voucher = dataConstruct(field, formValue)
            let requestBody = {
                ...voucher,
                combine_validation: getCombineValidation(voucher)
            }

            getPromiseApi(buttonType, requestBody)
                .then((response) => {
                    let msg = getSuccessMsg(buttonType)
                    window.location.href = '/voucher/voucher-list'
                    dispatch(setToolsModalVisibility({alert: true}))
                    dispatch(setToolsAlertMessage(msg))
                }).catch(err => {
                dispatch(setVoucherErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getSuccessMsg = (buttonType) => {
        switch (true) {
            case !!id && buttonType !== 'draft':
                return 'Voucher updated!'
            case buttonType === 'draft':
                return 'Voucher drafted!'
            default:
                return 'Voucher created!'
        }
    }

    const getPromiseApi = (type, requestBody) => {
        switch (type) {
            case 'update':
                return updateVoucher(requestBody, id)
            case 'draft':
                if (!!id) {
                    return draftVoucher(requestBody, id)
                } else {
                    return draftVoucher(requestBody)
                }
            default:
                return createVoucher(requestBody)
        }
    }

    return (
        <div className={"col px-10 mt-4"}>
            <form id={'form'}>
                <div className={"col space-y-4 mb-10"}>
                    {
                        toggleList.map((item, index) =>
                            item.showToggle && (
                                <TabToggleComponent
                                    key={index}
                                    title={item.title}
                                    tooltipText={item.tooltipText}
                                    content={item.content}
                                />
                            )
                        )
                    }
                    <div className={"row justify-center space-x-4 py-8 font-semibold"}>
                        <button type={'button'} onClick={(e) => onSubmitHandler('draft', e)}
                                className={"w-60 py-2 border border-fortress-grey hover:text-white hover:bg-dazzle rounded-lg"}>
                            Save As Draft
                        </button>
                        <button type={'button'} onClick={(e) => onSubmitHandler(!!id ? 'update' : 'publish', e)}
                                className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                            {!!id ? 'Update' : 'Publish'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateVoucherPage
