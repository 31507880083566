import React, {useEffect, useState} from "react";

const CheckboxItemComponent = ({itemKey, data, value, id}) => {
    const [check, setCheck] = useState(false)

    useEffect(() => {
        setCheck(data)
    }, [data])

    return (
        <input type="checkbox" id={id || itemKey} className={`accent-monstera`} value={value} name={itemKey} checked={check} onChange={(e) => setCheck(e.target.checked)}/>
    )
}

export default CheckboxItemComponent
