import InputComponent from "../../components/input.component";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TodoListComponent from "./todo-list";
import {setAvostoreDetail} from "../../reducers/avostore.reducer";
import {useParams} from "react-router-dom";

const GeneralForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {avostoreDetail, avostoreErrorMessage} = useSelector(state => state.avostoreReducer)

    const onChangeInput = (key, value) => {
        dispatch(setAvostoreDetail({[key]: value}))
    }

    return (
        <div className={"col space-y-4"}>
            <div className={"row space-x-16"}>
                <div className={"col space-y-4 w-1/2"}>
                    {fieldList[0].map((item, index) => (
                        <InputComponent key={index} item={item} data={id && avostoreDetail[item.key]} error={avostoreErrorMessage}/>
                    ))}
                </div>
                <div className={"col space-y-4 w-1/2"}>
                    {fieldList[1].map((item, index) => (
                        <InputComponent key={index} item={item} data={avostoreDetail[item.key]} onChange={onChangeInput} error={avostoreErrorMessage}/>
                    ))}
                </div>
            </div>
            <TodoListComponent item={fieldList[2][0]} onChangeTodoList={onChangeInput} data={id && avostoreDetail['offline_stores']} error={avostoreErrorMessage}/>
        </div>
    )
}

export default GeneralForm