import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat
} from "../../utils/general-variable";
import {UserProfileDefault} from "../../utils/image";

const CustomerList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Name & Email',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row items-center space-x-3 p-5"}>
                    <img src={record.image_url || UserProfileDefault} className={"w-10 h-10 rounded-lg"} alt={"imageUser"}/>
                    <div className={"col space-y-1"}>
                        <p className={"text-monstera capitalize font-semibold"}>{record.name}</p>
                        <p className={"text-forged-steel"}>{record.email}</p>
                    </div>
                </div>
            )
        }, {
            name: 'Level',
            dataIndex: 'level_name',
            classNameHeader: 'text-center',
            render: (level_name) => (
                <p className={"text-center font-regular text-forged-steel"}>{level_name}</p>
            )
        }, {
            name: 'Registered At',
            dataIndex: 'join_date',
            classNameHeader: 'text-center',
            render: (join_date) => (
                <p className={"text-center font-regular text-forged-steel"}>{convertDateToCustomFormat(join_date, 'YYYY/MM/DD')}</p>
            )
        },
        {
            name: 'Total Order',
            dataIndex: 'total_order',
            classNameHeader: 'text-center',
            render: (total_order) => (
                <p className={"text-center font-regular text-forged-steel"}>{total_order}</p>
            )
        }, {
            name: 'Total Spend',
            dataIndex: 'total_spending',
            classNameHeader: 'text-center',
            render: (total_spending) => (
                <p className={"text-center font-regular text-forged-steel"}>
                    {doConvertNumberToRupiahFormat(total_spending)}
                </p>
            )
        },
        {
            name: 'Order Avr.',
            dataIndex: 'average_spending',
            classNameHeader: 'text-center',
            render: (average_spending) => (
                <p className={"text-center font-regular text-forged-steel"}>
                    {doConvertNumberToRupiahFormat(average_spending)}
                </p>
            )
        }, {
            name: 'Prov.',
            dataIndex: 'province',
            classNameHeader: 'text-center',
            render: (province) => (
                <p className={"text-center font-regular text-forged-steel"}>{province}</p>
            )
        }, {
            name: 'City',
            dataIndex: 'city',
            classNameHeader: 'text-center',
            render: (city) => (
                <p className={"text-center font-regular text-forged-steel"}>{city}</p>
            )
        },
    ]

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Customer"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default CustomerList
