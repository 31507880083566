import React, {useEffect} from "react";
import {FIELD_LIST_SLIDER} from "../../../utils/input-field-list-static-page";
import Item from "./item";
import {useDispatch, useSelector} from "react-redux";
import {
    setMainPageErrorMessage,
    setMainPageSliderDetail
} from "../../../reducers/main-page.reducer";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Slider = () => {
    const dispatch = useDispatch()
    const {sliderDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getSlider} = useFetch(API.HOMEPAGE_GET_SLIDER)
    const {fetching: updateSlider, isLoading} = useFetch(API.HOMEPAGE_UPDATE_SLIDER)
    const itemList = [...sliderDetail.item_list]
    let fieldList = FIELD_LIST_SLIDER
    let showSlider = {
        label: 'Slider',
        key: 'show_slider',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageSliderDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'slider').toString(),
            desktop_banner: null,
            mobile_banner: null,
            link_banner: null
        })
        dispatch(setMainPageSliderDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(sliderDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataSlider = {...sliderDetail, item_list: dataItemList}
        updateSlider(dataSlider).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Slider Updated'))
            dispatch(setMainPageSliderDetail({...response?.data, delete_ids: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getSlider().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setMainPageSliderDetail({...response?.data, delete_ids: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setMainPageSliderDetail({...data, delete_ids: []}))
            }
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={sliderDetail}
            switchToggleField={showSlider}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageSliderDetail}
            deleteList={sliderDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Slider
