export const introModel = {
    show_intro: true,
    title: null,
    description: null,
}

export const miraculousItemModel = {
    show_item: true,
    item_list: [{
        id: 'miraculous_item',
        thumbnail_desktop: null,
        thumbnail_mobile: null,
        title: null,
        url: null,
        description: null
    }],
    delete_ids: []
}

export const relatedProductModel = {
    show_related_product: true,
    title: null,
    select_product: []
}
