import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import UploadXlsxFile from "./upload-xlsx-file";
import ColumnMapping from "./column-mapping";
import Import from "./import";
import Done from "./done";
import {setProductActiveTab, setProductInitialImportProductDetail} from "../../reducers/product.reducer";
import {STEP_IMPORT_PRODUCT} from "../../utils/constant";
import StepperComponent from "../../components/stepper.component";

const ImportProductPage = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.productReducer)

    const content = {
        upload_xlsx_file: <UploadXlsxFile/>,
        column_mapping: <ColumnMapping/>,
        import: <Import/>,
        done: <Done/>
    }

    useEffect(() => {
        return () => {
            dispatch(setProductInitialImportProductDetail())
            dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[0]))
        }
    }, [])

    return (
        <div className={"col justify-center space-y-8 px-10 mt-4"}>
            <StepperComponent stepList={STEP_IMPORT_PRODUCT} activeTab={activeTab}/>
            <div className={"px-20"}>
                {content[activeTab.key]}
            </div>
        </div>
    )
}

export default ImportProductPage