import {useSelector} from "react-redux";

const OrderDetailAddress = () => {
    const {orderDetail} = useSelector(state => state.orderReducer)
    const address = orderDetail?.address

    return (
        <div className={"col space-y-3 w-full px-5 py-3 bg-white border font-regular border-fortress-grey rounded-lg"}>
            <div className={"row space-x-4"}>
                <div className={"col w-1/2 space-y-5"}>
                    <p className={"font-Montserrat-Bold text-monstera text-sm"}>Billing Address</p>
                    <div className={"col space-y-4"}>
                        <div className={"col space-y-2"}>
                            <p className={"font-semibold text-eerie-black"}>{address?.billing?.name}</p>
                            <p className={"text-monstera"}>{address?.billing?.phone}</p>
                        </div>
                        <p className={"leading-6 text-forged-steel"}>{address?.billing?.full_address}</p>
                    </div>
                </div>
                <div className={"col w-1/2 space-y-5"}>
                    <p className={"font-Montserrat-Bold text-monstera text-sm"}>Shipping Address</p>
                    <div className={"col space-y-4"}>
                        <div className={"col space-y-2"}>
                            <p className={"font-semibold text-eerie-black"}>{address?.shipping?.name}</p>
                            <p className={"text-monstera"}>{address?.shipping?.phone}</p>
                        </div>
                        <p className={"leading-6 text-forged-steel"}>{address?.shipping?.full_address}</p>
                    </div>
                </div>
            </div>
            <div className={"col space-y-2"}>
                <p className={"font-semibold text-eerie-black"}>Notes</p>
                <p className={"leading-6 text-forged-steel"}>{address?.notes}</p>
            </div>
        </div>
    )
}

export default OrderDetailAddress