import React from "react";
import ModalCardComponent from "./modal-card.component";
import {CloseIcon, WarningCircleIcon} from "../utils/image";
import {useDispatch, useSelector} from "react-redux";
import {setToolsModalVisibility} from "../reducers/tools.reducer";

const ExportConfirmationComponent = ({onApply, countSelected}) => {
    const dispatch = useDispatch()
    const {totalData, alertMessage, modalVisibility} = useSelector(state => state.toolsReducer)

    const closeModalHandler = () => {
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <ModalCardComponent
            visibility={modalVisibility.export}
            onClose={closeModalHandler}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center p-10 space-y-7 bg-white rounded-xl"}>
                        <p className={"text-eerie-black text-center text-base font-semibold"}>Confirmation</p>
                        <img src={WarningCircleIcon} alt="WarningIcon" className={"w-14 mx-auto"}/>
                        <div className={"col space-y-2 text-monstera text-center text-base"}>
                            <p>{!!alertMessage ? alertMessage : 'Apakah anda yakin ?'}</p>
                            <p>Total Data: {countSelected || totalData}</p>
                        </div>
                        <div className={"row justify-center font-semibold items-center space-x-10"}>
                            <div
                                className={"row justify-center px-8 py-2 border border-placebo text-forged-steel rounded-lg hover:text-white hover:bg-dazzle cursor-pointer"}
                                onClick={closeModalHandler}>
                                <p>Tidak</p>
                            </div>
                            <button type={"submit"} onClick={onApply}
                                    className={"row justify-center px-8 py-2 bg-monstera text-white rounded-lg cursor-pointer"}>
                                <p>Ya</p>
                            </button>
                        </div>
                    </div>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModalHandler}/>
                </div>
            }
        />
    )
}

export default ExportConfirmationComponent
