import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_FORM_AVO_STORIES_2} from "../../utils/input-field-list-static-page";
import {setAvoStories2ErrorMessage, setAvoStories2FormDetail} from "../../reducers/avo-stories-2.reducer";
import {useEffect} from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";

const Form = () => {
    const dispatch = useDispatch()
    const {formDetail, avoStories2ErrorMessage} = useSelector(state => state.avoStories2Reducer)
    const {fetching: getForm} = useFetch(API.AVO_STORIES_2_GET_FORM)
    const {fetching: updateForm, isLoading} = useFetch(API.AVO_STORIES_2_UPDATE_FORM)
    let fieldList = FIELD_LIST_FORM_AVO_STORIES_2
    let showForm = {
        label: 'Form',
        key: 'show_form',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStories2FormDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateForm(formDetail).then(response => {
            dispatch(setAvoStories2ErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Form Updated'))
            dispatch(setAvoStories2FormDetail(response?.data))
        }).catch(err => {
            dispatch(setAvoStories2ErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getForm().then(response => {
            dispatch(setAvoStories2FormDetail(response?.data))
        })

        return () => {
            dispatch(setAvoStories2ErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={formDetail}
            switchToggleField={showForm}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={avoStories2ErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Form
