import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {ArrowRight, AvoskinLogo, AvoskinLogoTransparent} from "../../utils/image";

export const Sidebar = ({data}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const [sidebar, setSidebar] = useState('')
    const [isActiveSidebar, setIsActiveSidebar] = useState('')
    const [child, setChild] = useState([])
    const token = JSON.parse(localStorage.getItem('token'))
    const feature_ids = token?.feature_ids ? token?.feature_ids : []

    const sidebarClickHandler = (item) => {
        let childMenu = []

        if(item.path === sidebar){
            setIsActiveSidebar(!isActiveSidebar)
        } else {
            setSidebar(item.path)
            setIsActiveSidebar(true)
        }

        item?.child.map(menu => {
            feature_ids.map(id => {
                if(id === menu?.feature_id) childMenu.push(menu)
            })
        })

        setChild(childMenu)
    }

    return (
        <div className={"col items-center w-64 bg-windmill-wings sticky top-0 h-screen overflow-y-scroll"}>
            <img src={AvoskinLogo} className={"aspect-square py-4"}/>
            <p className={"font-Montserrat-Bold text-monstera"}>ADMIN DASHBOARD</p>
            <div className={"col px-4 mt-6 w-full z-10"}>
                {
                    data.map((item, index) => (
                        <div key={index} className={`${sidebar === item.path ? 'rounded-lg' : 'bg-transparent'} mb-1`}>
                            <div className={`row items-center font-semibold w-full h-11 px-4 space-x-4 hover:bg-monstera hover:rounded-lg hover:shadow-md hover:text-white group cursor-pointer ${sidebar === item.path ? 'bg-monstera rounded-lg shadow-md text-white' : 'text-monstera'}`}
                            onClick={() => sidebarClickHandler(item)}>
                                {
                                    sidebar === item.path ?
                                        <img src={item.imageActive}
                                             className={"w-5"}/> :
                                        <div>
                                            <img src={item.imageActive}
                                                 className={"w-5 hidden group-hover:block"}/>
                                            <img src={item.image}
                                                 className={"w-5 block group-hover:hidden"}/>
                                        </div>
                                }
                                <p>{item.label}</p>
                            </div>
                            {
                                sidebar === item.path ?
                                    <div className={'bg-whisper-green'}>
                                        {
                                            child.length > 0 &&
                                            child.map((i, idx) => (
                                                <Link to={`/${item.path}/${i.path}`} key={idx}
                                                      className={`h-11 px-4 row items-center space-x-4 hover:font-Montserrat-Bold hover:text-monstera ${isActiveSidebar ? 'animate-transformDown' : 'animate-transformUp'}`}>
                                                    {path[2] === i.path ?
                                                        <img src={ArrowRight} className={"w-5"}/> :
                                                        <div className={"w-5"}/>}
                                                    <p className={`${path[2] === i.path && 'font-Montserrat-Bold text-monstera'}`}>{i.label}</p>
                                                </Link>
                                            ))
                                        }
                                    </div> : null
                            }
                        </div>
                    ))
                }
            </div>
            <img src={AvoskinLogoTransparent} className={"fixed w-52 bottom-0 p-2"}/>
        </div>
    )
}
