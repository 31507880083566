import InputComponent from "../../components/input.component";
import {LoadingSvg} from "../../utils/image";
import React, {useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {checkFieldValidate} from "../../utils/general-variable";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";

const ForgotPasswordPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [fieldList, setFieldList] = useState([
        {
            label: 'Email',
            key: 'email',
            type: 'email',
            placeholder: 'masukkan email'
        }
    ])
    const {fetching: postForgotPassword, isLoading} = useFetch(API.FORGOT_PASSWORD)

    const showAlertModal = (type, message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType(type))
        dispatch(setToolsAlertMessage(message))
        setTimeout(() => {
            type === 'success' && navigate('/')
        }, 1000)
    }

    const forgotPasswordHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const body = {
            email: elements.email.value
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (!isValid) {
            setFieldList(fieldItemsCopy)
        } else {
            postForgotPassword(body).then(() => {
                showAlertModal('success', 'Berhasil', )
            }).catch(() => {
                showAlertModal('failed', 'User Tidak Terdaftar')
            })
        }
    }

    return (
        <div className={"col space-y-8 items-center justify-center pb-14 w-screen h-screen bg-gradient-to-b from-monstera to-toad"}>
            <Link to={'/'}>
                <p className={"font-Montserrat-Bold text-white"}>AVOSKIN ADMIN DASHBOARD</p>
            </Link>
            <div className={"w-[480px] border rounded-xl border-placebo p-20 col items-center space-y-10 text-xs text-monstera bg-white shadow-xl"}>
                <p className={"font-regular text-4xl"}>Lupa Password</p>
                <form onSubmit={forgotPasswordHandler} className={"col items-center w-full space-y-10"}>
                    <div className={"col space-y-5 w-full"}>
                            <InputComponent item={fieldList[0]} error={{[fieldList[0].key]: fieldList[0].errorText}}/>
                    </div>
                    <button
                        type={"submit"} disabled={isLoading}
                        className={"bg-toad text-white font-Montserrat-Bold w-1/2 h-8 rounded-full hover:bg-monstera"}>
                        {isLoading ? <img src={LoadingSvg} className={"w-7 mx-auto"}/> : 'Kirim Verifikasi'}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ForgotPasswordPage