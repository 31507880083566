import {Navigate, Route, Routes} from "react-router-dom";
import Auth from "../pages/auth";
import {PrivateRoute} from "./private-route";
import {ROUTE_LIST} from "../utils/constant";
import {isLogin} from "../utils/general-variable";
import ForgotPassword from "../pages/forgot-password";
import UnderMaintenance from "../pages/under-maintenance";

export default function RoutePage(){
    const isAuthenticated = isLogin()
    const token = JSON.parse(localStorage.getItem('token'))
    const feature_ids = token?.feature_ids ? token?.feature_ids : []

    const generateRouteList = () => {
        return ROUTE_LIST.filter((routeItem) => feature_ids.includes(routeItem.feature_id))
    }

    return(
        <Routes>
            <Route path="/" element={isAuthenticated 
                ? generateRouteList()[0].component
                : <Auth />
            }/>
            <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
            <Route path={'/under-maintenance'} element={<UnderMaintenance/>}/>
            {generateRouteList().map((item, index) => (
                <Route key={index.toString()} path={item.path}
                    element={<PrivateRoute children={item.component} />}/>
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
