import {
    setAffiliateDetail,
    setAffiliateErrorMessage,
    setAffiliateInitialAffiliateDetail,
    setAffiliateModalVisibility
} from "../../reducers/affiliate.reducer";
import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "../../components/modal-card.component";
import {CloseIcon, LoadingSvg} from "../../utils/image";
import React from "react";
import {FIELD_LIST_CREATE_AFFILIATE} from "../../utils/input-field-list";
import InputComponent from "../../components/input.component";
import _ from "lodash";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {convertErrorMessage, convertUtcToCustomFormat} from "../../utils/general-variable";

const CreateAffiliateForm = () => {
    const dispatch = useDispatch()
    const {reload} = useSelector(state => state.toolsReducer)
    const {affiliateDetail, affiliateErrorMessage, modalVisibility} = useSelector(state => state.affiliateReducer)
    const {createAffiliate, updateAffiliate} = modalVisibility
    const {fetching: createAffiliateFetching, isLoading: loadingCreateAffiliate} = useFetch(API.CREATE_AFFILIATE)
    const {fetching: updateAffiliateFetching, isLoading: loadingUpdateAffiliate} = useFetch(API.UPDATE_AFFILIATE)
    const PARAM_AFFILIATE = '?affiliate='

    const showModalHandler = () => {
        dispatch(setAffiliateModalVisibility({createAffiliate: true}))
    }

    const closeModalHandler = () => {
        document.getElementById("form").reset()
        dispatch(setAffiliateErrorMessage({}))
        dispatch(setAffiliateInitialAffiliateDetail())
        dispatch(setAffiliateModalVisibility({createAffiliate: false, updateAffiliate: false}))
    }

    const onChangeInput = (key, value) => {
        if (key === 'campaign_link' || key === 'code') {
            const url = key === 'campaign_link' ? value || '' : affiliateDetail?.campaign_link || ''
            const code = key === 'code' ? value || '' : affiliateDetail?.code || ''
            const affiliateLink = `${url}${PARAM_AFFILIATE}${code}`
            dispatch(setAffiliateDetail({[key]: value, affiliate_link: affiliateLink}))
            return
        }
        dispatch(setAffiliateDetail({[key]: value}))
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        dispatch(setToolsReload(!reload))
        closeModalHandler()
    }

    const dataChecking = (field, value) => {
        dispatch(setAffiliateErrorMessage({}))
        const affiliateDetailCopy = _.cloneDeep(affiliateDetail)
        let error = {}
        field.forEach((item) => {
            if (item.required && !value[item.key]?.value) {
                error[item.key] = `${item.label} is required`
            } else if (item.type === 'text') {
                affiliateDetailCopy[item.key] = value[item.key]?.value
            }else {
                affiliateDetailCopy[item.key] = value[item.key]?.value || null
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setAffiliateErrorMessage({}))
            return {isValid: true, affiliate: affiliateDetailCopy}
        } else {
            dispatch(setAffiliateErrorMessage(error))
            return {isValid: false, affiliate: affiliateDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let formValue = document.getElementById("form").elements
        const {isValid, affiliate} = dataChecking(FIELD_LIST_CREATE_AFFILIATE, formValue)
        if (isValid) {
            getPromiseApi({
                ...affiliate,
                start_date: affiliate.start_date && convertUtcToCustomFormat(affiliate.start_date, "YYYY-MM-DD HH:mm"),
                end_date: affiliate.end_date && convertUtcToCustomFormat(affiliate.end_date, "YYYY-MM-DD HH:mm"),
                phone_number: !!affiliate.phone_number ? `62${affiliate.phone_number}` : null,
            }).then(() => {
                showAlertModal(createAffiliate ? 'Affiliate Created' : 'Affiliate Updated')
            }).catch(err => {
                dispatch(setAffiliateErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getPromiseApi = (requestBody) => {
        if (createAffiliate) return createAffiliateFetching(requestBody)
        return updateAffiliateFetching(requestBody, affiliateDetail.id)
    }


    return (
        <>
            <div
                className={`text-white py-3 px-8 font-semibold rounded-lg bg-battleship-green hover:bg-monstera cursor-pointer`}
                onClick={showModalHandler}>
                <p>Create Affiliate</p>
            </div>
            <ModalCardComponent
                visibility={createAffiliate || updateAffiliate}
                onClose={closeModalHandler}
                content={
                    <div className={"relative max-h-[90%] h-full overflow-y-scroll"}>
                        <form id={"form"} onSubmit={onSubmitHandler}>
                            <div className={"col space-y-8 w-96 px-5 py-5 bg-white rounded-xl"}>
                                <p className={"text-sm text-center text-monstera font-Montserrat-Bold"}>{createAffiliate ? 'Create Affiliate' : 'Update Affiliate'}</p>
                                <div className={"space-y-4"}>
                                    {
                                        FIELD_LIST_CREATE_AFFILIATE.map((item, index) => <InputComponent item={item} disabled={item.disabled} key={index} data={affiliateDetail[item.key]} error={affiliateErrorMessage} onChangeInput={onChangeInput} onChangeDate={onChangeInput}/>)
                                    }
                                </div>
                                <button type={"submit"}
                                        className={"mx-auto px-20 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera font-semibold text-white rounded-lg cursor-pointer"}>
                                    {loadingCreateAffiliate || loadingUpdateAffiliate ?
                                        <img src={LoadingSvg} className={"w-5 mx-auto"}/> : createAffiliate ? 'Create' : 'Update'}
                                </button>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default CreateAffiliateForm
