import {FIELD_LIST_ARTICLES_ABOUT_US} from "../../../utils/input-field-list-static-page";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {randomCode} from "../../../utils/general-variable";
import {setAboutUsArticlesDetail} from "../../../reducers/about-us.reducer";
import ItemArticles from "./Item-articles";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Articles = () => {
    const dispatch = useDispatch()
    const {articlesDetail} = useSelector(state => state.aboutUsReducer)
    const itemList = [...articlesDetail.item_list]
    let fieldList = FIELD_LIST_ARTICLES_ABOUT_US
    let showArticles = {
        label: 'Articles',
        key: 'show_articles',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAboutUsArticlesDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'articles').toString(),
            thumbnail: null,
            title: null,
            date: null,
            url: null
        })
        dispatch(setAboutUsArticlesDetail({item_list: itemList}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(articlesDetail)
        // submit form
    }

    return (
        <FormItemComponent
            data={articlesDetail}
            title={'Article'}
            switchToggleField={showArticles}
            itemList={itemList}
            content={(index) => <ItemArticles fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setAboutUsArticlesDetail}
            deleteList={articlesDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default Articles
