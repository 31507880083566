import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setYourSkinBaePersonalizeDetail} from "../../../reducers/your-skin-bae.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {personalizeDetail, yourSkinBaeErrorMessage} = useSelector(state => state.yourSkinBaeReducer)

    const onChange = (key, value, id) => {
        const copyItemList = cloneDeep(personalizeDetail.item_list)
        if (key === 'select_product') {
            let deleteList = copyItemList[index].delete_product || []
            copyItemList[index].delete_product = [...deleteList, id]
            copyItemList[index].select_product = value
        } else {
            copyItemList[index][key] = value
        }
        dispatch(setYourSkinBaePersonalizeDetail({item_list: copyItemList}))
    }

    const onChangeProduct = (key, value) => {
        const copyItemList = cloneDeep(personalizeDetail.item_list)
        let deleteList = copyItemList[index]?.delete_product?.filter(item => item !== value?.id)
        copyItemList[index].delete_product = deleteList || []
        copyItemList[index].select_product = copyItemList[index][key].concat(value)
        dispatch(setYourSkinBaePersonalizeDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <div key={i} className={"col-span-2"}>
                    <InputComponent item={item} data={personalizeDetail.item_list[index][item.key]} onChange={onChange} onChangeInput={onChange}
                                    onChangeProduct={onChangeProduct} error={yourSkinBaeErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default Item