import NotificationFilter from "./notification-filter";
import NotificationList from "./notification-list";
import {useDispatch, useSelector} from "react-redux";
import {SORT_LIST_NOTIFICATION} from "../../utils/sort-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffect} from "react";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setNotificationSelectedRowKeys} from "../../reducers/notification.reducer";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const NotificationPage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_NOTIFICATION?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
        filterByStatus: filter['status'] ?? [],
    }
    const {
        data: response,
        fetching: getNotificationList,
        isLoading
    } = useFetch(API.NOTIFICATION_LIST, queryParams, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setNotificationSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getNotificationList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, filter, reload])


    return (
        <div className={"col px-10"}>
            <NotificationFilter/>
            <NotificationList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default NotificationPage
