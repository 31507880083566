import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    setToolsCurrentPage,
    setToolsModalVisibility,
    setToolsPageSize,
} from "../reducers/tools.reducer";
import {CloseIcon, EyeIcon} from "../utils/image";
import {useClickOutside} from "../services/useClickOutside";

const PageLimitComponent = ({limitList = []}) => {
    const dispatch = useDispatch()
    const {pageSize, modalVisibility} = useSelector(state => state.toolsReducer)
    const [checkedByLimit, setCheckedByLimit] = useState(null)

    const onCheckedHandler = (event) => {
        const value = Number(event.target.value)
        setCheckedByLimit(value)
        dispatch(setToolsCurrentPage(1))
        dispatch(setToolsPageSize(value))
        closeDropdownHandler()
    }

    const showHideDropdownHandler = () => {
        dispatch(setToolsModalVisibility({limit: !modalVisibility.limit}))
    }

    const closeDropdownHandler = () => {
        dispatch(setToolsModalVisibility({limit: false}))
    }

    useEffect(() => {
        setCheckedByLimit(pageSize)
    }, [modalVisibility.limit])

    useClickOutside('page-limit-component', modalVisibility?.limit, closeDropdownHandler)

    return (
        <div id={"page-limit-component"} className={"font-regular"}>
            <div onClick={showHideDropdownHandler}
                 className={"row items-center space-x-2 px-3 py-2 font-semibold rounded-lg border border-placebo cursor-pointer"}>
                <img className={"h-5"} src={EyeIcon} alt="EyeIcon"/>
                <p>{`Show ${pageSize} Items`}</p>
            </div>
            <div
                className={`absolute z-20 mt-2 shadow-md bg-white rounded-xl ${modalVisibility?.limit ? 'block' : 'hidden'}`}>
                <div
                    className={"col space-y-8 w-[300px] pl-8 pr-5 py-5 bg-white rounded-xl"}>
                    <p className={"text-center font-semibold"}>Show Items</p>
                    <div className={"col space-y-4 pr-10"}>
                        {limitList.map((list, index) => (
                            <div key={index} className={"row space-x-5 py-1 w-full"}>
                                <input type="radio" value={list.key}
                                       className={"accent-monstera"} id={list.key}
                                       checked={checkedByLimit === list.key} onChange={onCheckedHandler}
                                />
                                <label htmlFor={list.key}
                                       className={"text-forged-steel font-regular"}>{list.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <img src={CloseIcon} alt="closeButton"
                     className={`w-5 absolute right-4 top-2 cursor-pointer`}
                     onClick={closeDropdownHandler}/>
            </div>
        </div>
    )
}

export default PageLimitComponent
