import {Breadcrumb} from "./breadcrumb";
import {BREADCRUMB, SIDEBAR} from "../../utils/constant";
import {SmallArrowDown, UserProfileDefault} from "../../utils/image";
import {useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {resetAuthHeader} from "../../services/axios";
import {useClickOutside} from "../../services/useClickOutside";

export const Header = () => {
    const [isDropdown, setIsDropdown] = useState(false)
    const {fetching: postLogout} = useFetch(API.LOGOUT)
    let token = JSON.parse(localStorage.getItem('token'))

    const showDropdownHandler = () => {
        setIsDropdown(!isDropdown)
    }

    const logoutHandler = () => {
        postLogout().then(() => {
            resetAuthHeader()
            localStorage.clear()
            window.location.reload()
        })
    }

    useClickOutside('header-component', isDropdown, setIsDropdown)

    return (
        <div className={"row items-center justify-between px-10 py-2 sticky top-0 bg-white z-20"}>
            <Breadcrumb data={{BREADCRUMB, SIDEBAR}}/>
            <div id={"header-component"} className={"h-14 row items-center space-x-10 border rounded px-2 shadow-md relative"}>
                <div className={"row items-center gap-2 space-x-3 cursor-pointer"} onClick={showDropdownHandler}>
                    <img src={token?.profile_image_url || UserProfileDefault} className={"w-9"}/>
                    {
                        !!token &&
                        <div>
                            <p className={"font-semibold"}>{token?.fullname}</p>
                            <p>{token?.role}</p>
                        </div>
                    }
                    <img src={SmallArrowDown} className={"w-3"}/>
                </div>
                <div className={`${isDropdown ? 'block': 'hidden'} absolute right-0 top-14 bg-doctor w-full h-fit`}>
                    <div className={"p-3 cursor-pointer hover:bg-monstera hover:text-white"} onClick={logoutHandler}>
                        <p>Logout</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
