import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_SUSTAINABILITY_PROGRESS_AVO_STORIES_2, FIELD_LIST_SUSTAINABILITY_PROGRESS_GENERAL_AVO_STORIES_2} from "../../../utils/input-field-list-static-page";
import {setAvoStories2ErrorMessage, setAvoStories2SustainabilityProgressDetail} from "../../../reducers/avo-stories-2.reducer";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import {cloneDeep} from "lodash";
import {useEffect} from "react";
import FormItemComponent from "../../../components/static-page/form-item.component";
import ItemSustainabilityProgress from "./item-sustainability-progress";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";

const SustainabilityProgress = () => {
    const dispatch = useDispatch()
    const {sustainabilityProgressDetail, avoStories2ErrorMessage} = useSelector(state => state.avoStories2Reducer)
    const {fetching: getSustainabilityProgress} = useFetch(API.AVO_STORIES_2_GET_SUSTAINABILITY_PROGRESS)
    const {fetching: updateSustainabilityProgress, isLoading} = useFetch(API.AVO_STORIES_2_UPDATE_SUSTAINABILITY_PROGRESS)
    const itemList = [...sustainabilityProgressDetail.item_list]
    let fieldListGeneral = FIELD_LIST_SUSTAINABILITY_PROGRESS_GENERAL_AVO_STORIES_2
    let fieldList = FIELD_LIST_SUSTAINABILITY_PROGRESS_AVO_STORIES_2
    let showSustainabilityProgress = {
        label: 'Sustainability Progress',
        key: 'show_sustainability_progress',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAvoStories2SustainabilityProgressDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'sustainability_progress').toString(),
            title: null,
            description: null
        })
        dispatch(setAvoStories2SustainabilityProgressDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(sustainabilityProgressDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataSustainabilityProgress = {...sustainabilityProgressDetail, item_list: dataItemList}
        updateSustainabilityProgress(dataSustainabilityProgress).then(response => {
            dispatch(setAvoStories2ErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Sustainability Progress Updated'))
            dispatch(setAvoStories2SustainabilityProgressDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setAvoStories2ErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getSustainabilityProgress().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setAvoStories2SustainabilityProgressDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setAvoStories2SustainabilityProgressDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setAvoStories2ErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={sustainabilityProgressDetail}
            switchToggleField={showSustainabilityProgress}
            fieldListGeneral={fieldListGeneral}
            itemList={itemList}
            content={(index) => <ItemSustainabilityProgress fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setAvoStories2SustainabilityProgressDetail}
            deleteList={sustainabilityProgressDetail?.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            errorMessage={avoStories2ErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default SustainabilityProgress
