import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setAvoStories2ArticlesDetail} from "../../../reducers/avo-stories-2.reducer";

const GeneralForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {articlesDetail, avoStories2ErrorMessage} = useSelector(state => state.avoStories2Reducer)

    const onChangeInput = (key, value) => {
        dispatch(setAvoStories2ArticlesDetail({[key]: value}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <div key={index}>
                    <InputComponent item={item} data={articlesDetail[item.key]} onChangeInput={onChangeInput} error={avoStories2ErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default GeneralForm
