import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {EditYellowIcon, TrashIcon} from "../../utils/image";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setCategoryDetail,
    setCategoryModalVisibility,
    setCategorySelectedRowKeys
} from "../../reducers/category.reducer";

const CategoryList = ({datasource, isLoading}) => {
    const dispatch = useDispatch()
    const [categoryId, setCategoryId] = useState(null)
    const {reload} = useSelector(state => state.toolsReducer)
    const {selectedRowKeysCategory} = useSelector(state => state.categoryReducer)
    const {fetching: deleteCategory} = useFetch(API.DELETE_CATEGORY)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Category',
            classNameHeader: 'pl-5',
            dataIndex: 'name',
            render: (category) => (
                <p className={"pl-5 font-regular text-monstera capitalize"}>{category}</p>
            )
        }, {
            name: 'Parent Category',
            dataIndex: 'parent_category',
            classNameHeader: 'text-center',
            render: (parent_category) => (
                <p className={"font-regular text-center text-monstera"}>{parent_category}</p>
            )
        }, {
            name: 'Description',
            dataIndex: 'description',
            render: (description) => (
                <p className={"font-regular text-forged-steel"}>{description}</p>
            )
        }, {
            name: 'Slug',
            dataIndex: 'slug',
            render: (slug) => (
                <p className={"font-regular text-forged-steel"}>{slug}</p>
            )
        }, {
            name: 'Count',
            dataIndex: 'product_count',
            classNameHeader: 'text-center',
            render: (product_count) => (
                <p className={"text-center font-regular text-forged-steel"}>{product_count} Product</p>
            )
        }, {
            name: 'Action',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row justify-center items-center space-x-4 font-semibold"}>
                    <div className={"row items-center space-x-2 cursor-pointer"} onClick={() => openDeleteModal(record.id)}>
                        <img src={TrashIcon} alt="delete" className={"w-5"}/>
                        <p>Delete</p>
                    </div>
                    <div className={"text-placebo w-0.5 h-5 bg-placebo"}></div>
                    <div className={"row items-center space-x-2 cursor-pointer"} onClick={() => openUpdateModal(record)}>
                        <img src={EditYellowIcon} alt="edit" className={"w-5"}/>
                        <p>Edit</p>
                    </div>
                </div>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setCategorySelectedRowKeys(array))
    }

    const openUpdateModal = (data) => {
        dispatch(setCategoryDetail(data))
        dispatch(setCategoryModalVisibility({updateCategory: true}))
    }

    const openDeleteModal = (id) => {
        setCategoryId(id)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus kategory ini?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const deleteCategoryHandler = () => {
        deleteCategory({}, categoryId).then(() => {
            dispatch(setToolsReload(!reload))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Category Deleted'))
            dispatch(setToolsModalVisibility({delete: false, alert: true}))
        }).catch(() => {
            dispatch(setToolsModalVisibility({delete: false}))
        })
    }

    return (
        <>
            <div className={"col space-y-4"}>
                <TotalDataComponent subject={"Categories"}/>
                <TableComponent
                    columns={columns}
                    tableClassName={"font-Regular"}
                    headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                    selectedRowKeys={selectedRowKeysCategory}
                    onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                    dataSource={datasource}
                    pagination={true}
                    loading={isLoading}
                />
            </div>
            <DeleteConfirmationComponent onDelete={deleteCategoryHandler}/>
        </>
    )
}

export default CategoryList
