import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setAvoStories2SustainabilityProgressDetail} from "../../../reducers/avo-stories-2.reducer";

const ItemSustainabilityProgress = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {sustainabilityProgressDetail, avoStories2ErrorMessage} = useSelector(state => state.avoStories2Reducer)

    const onChange = (key, value) => {
        const copyItemList = cloneDeep(sustainabilityProgressDetail?.item_list)
        copyItemList[index][key] = value
        dispatch(setAvoStories2SustainabilityProgressDetail({item_list: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={sustainabilityProgressDetail?.item_list?.[index]?.[item.key]} error={avoStories2ErrorMessage[`item_list[${index}]`] ?? {}} onChangeInput={onChange} onChangeNumber={onChange}/>
            ))}
        </div>
    )
}

export default ItemSustainabilityProgress
