import {createSlice} from '@reduxjs/toolkit'

export const beautyEmptiesSlice = createSlice({
    name: 'beautyEmpties',
    initialState: {
        selectedRowKeysBeautyEmpties: [],
        selectedRowKeysBeautyEmptiesProduct: [],
        isShowModalProof: false,
        beautyEmptiesDetail: {}
    },
    reducers: {
        setBeautyEmptiesSelectedRowKeys: (state, action) => {
            state.selectedRowKeysBeautyEmpties = action.payload
        },
        setBeautyEmptiesProductSelectedRowKeys: (state, action) => {
            state.selectedRowKeysBeautyEmptiesProduct = action.payload
        },
        setBeautyEmptiesModalVisibility: (state, action) => {
            state.isShowModalProof = action.payload
        },
        setBeautyEmptiesDetail: (state, action) => {
            state.beautyEmptiesDetail = action.payload
        },
    },
})

export const {
    setBeautyEmptiesSelectedRowKeys,
    setBeautyEmptiesProductSelectedRowKeys,
    setBeautyEmptiesModalVisibility,
    setBeautyEmptiesDetail
} = beautyEmptiesSlice.actions
const beautyEmptiesReducer = beautyEmptiesSlice.reducer

export default beautyEmptiesReducer
