import {FIELD_LIST_VIDEO_ABOUT_US} from "../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setAboutUsVideoDetail} from "../../reducers/about-us.reducer";
import React from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Video = () => {
    const dispatch = useDispatch()
    const {videoDetail, aboutUsErrorMessage} = useSelector(state => state.aboutUsReducer)
    let fieldList = FIELD_LIST_VIDEO_ABOUT_US
    let showVideo = {
        label: 'Video',
        key: 'show_video',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAboutUsVideoDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(videoDetail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={videoDetail}
            switchToggleField={showVideo}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'col space-y-4'}
            itemInputClassName={{
                key: 'description',
                value: ['w-full', 'w-1/2']
            }}
            errorMessage={aboutUsErrorMessage}
        />
    )
}

export default Video