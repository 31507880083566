import {FIELD_LIST_PERSONALIZE_YOUR_SKIN_BAE} from "../../../utils/input-field-list-static-page";
import React, {useEffect} from "react";
import Item from "./item";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {setYourSkinBaePersonalizeDetail} from "../../../reducers/your-skin-bae.reducer";
import {randomCode} from "../../../utils/general-variable";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Personalize = () => {
    const dispatch = useDispatch()
    const {personalizeDetail} = useSelector(state => state.yourSkinBaeReducer)
    const {fetching: getProductList} = useFetch(API.PAGE_GET_PRODUCT_LIST)
    const itemList = [...personalizeDetail.item_list]
    let fieldList = FIELD_LIST_PERSONALIZE_YOUR_SKIN_BAE
    let showPersonalize = {
        label: 'Personalize',
        key: 'show_personalize',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setYourSkinBaePersonalizeDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'personalize').toString(),
            title: null,
            select_product: [],
            delete_product: []
        })
        dispatch(setYourSkinBaePersonalizeDetail({item_list: itemList}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(personalizeDetail)
        // submit form
    }

    useEffect(() => {
        getProductList().then(response => {
            fieldList[1].dataList = response.data
        })
    }, [])

    return (
        <FormItemComponent
            title={'Personalize'}
            data={personalizeDetail}
            switchToggleField={showPersonalize}
            itemList={itemList}
            content={(index) => <Item fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setYourSkinBaePersonalizeDetail}
            deleteList={personalizeDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
        />
    )
}

export default Personalize
