import {createSlice} from '@reduxjs/toolkit'
import {affiliateModel} from "../models/affiliate.model";

export const affiliateSlice = createSlice({
    name: 'affiliate',
    initialState: {
        selectedRowKeysAffiliate: [],
        selectedRowKeysAffiliatePerformance: [],
        affiliateDetail: {...affiliateModel},
        affiliateErrorMessage: {},
        modalVisibility: {
            createAffiliate: false,
            updateAffiliate: false
        }
    },
    reducers: {
        setAffiliateSelectedRowKeys: (state, action) => {
            state.selectedRowKeysAffiliate = action.payload
        },
        setAffiliatePerformanceSelectedRowKeys: (state, action) => {
            state.selectedRowKeysAffiliatePerformance = action.payload
        },
        setAffiliateDetail: (state, action) => {
            state.affiliateDetail = {...state.affiliateDetail, ...action.payload}
        },
        setAffiliateErrorMessage: (state, action) => {
            state.affiliateErrorMessage = action.payload
        },
        setAffiliateInitialAffiliateDetail: (state) => {
            state.affiliateDetail = {...affiliateModel}
        },
        setAffiliateModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
    },
})

export const {
    setAffiliateSelectedRowKeys,
    setAffiliatePerformanceSelectedRowKeys,
    setAffiliateDetail,
    setAffiliateErrorMessage,
    setAffiliateInitialAffiliateDetail,
    setAffiliateModalVisibility
} = affiliateSlice.actions
const affiliateReducer = affiliateSlice.reducer

export default affiliateReducer