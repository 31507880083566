import CONFIG from "../config/env"
import bcrypt from "bcryptjs-react"
import {useEffect, useReducer} from "react"
import {useDispatch} from "react-redux"
import {API, METHOD} from "./api"
import axios, {createInstance, getLocalAccesToken, resetAuthHeader} from "./axios"
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsTotalData
} from "../reducers/tools.reducer";
import {useNavigate} from "react-router-dom";

const initialData = {
    isLoading: false,
    data: null,
    error: null,
}

const ACTION_TYPE_RESPONSE = {
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    ERROR: 'ERROR'
}

const responseReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE_RESPONSE.SUCCESS:
            return {
                isLoading: false,
                data: action.payload,
                error: null
            }
        case ACTION_TYPE_RESPONSE.LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case ACTION_TYPE_RESPONSE.ERROR:
            return {
                isLoading: false,
                data: null,
                error: action.payload,
            }
        default:
            return state
    }
}
const defaultOptions = {
    isFetching: false,
    isLoading: false,
    isWithTotalData: false
}

export const useFetch = ({api, method, initPathVariable}, initPayload, options = defaultOptions) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, dispatchResponse] = useReducer(responseReducer, {...initialData, isLoading: options.isLoading})
    let atempt = 0

    const handlingError = (error) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('failed'))
        dispatch(setToolsAlertMessage(error?.data?.code === '422' ? 'Error' : error?.data?.message || 'Terjadi Kesalahan'))
        dispatchResponse({type: ACTION_TYPE_RESPONSE.ERROR, payload: error.data})
    }

    useEffect(() => {
        if (options.isFetching) fetching()
    }, [])

    const fetching = async (payload, pathVariable, header, responseType, additionalQueryParams = {}) => {
        dispatchResponse({type: ACTION_TYPE_RESPONSE.LOADING})
        let axiosInstance = createInstance()

        const getRefreshToken = async () => {
            try {
                let token = JSON.parse(localStorage.getItem('token'))
                if (token?.refresh_token) {
                    axiosInstance = createInstance({
                        headers: {
                            Authorization: `Bearer ${token.refresh_token}`
                        }
                    })
                }

                const hashed = bcrypt.hashSync(CONFIG.PUBLIC_KEY, 10)
                const rs = await axiosInstance.post(token?.refresh_token ? API.REFRESH_TOKEN.api : API.GENERATE_CLIENT_TOKEN.api, {grant: hashed})
                token = JSON.stringify({...token, ...rs.data.data})
                localStorage.setItem('token', token)

                axiosInstance = createInstance()
            } catch (error) {
                console.log('ERR getRefreshToken', error)
                if (error.response.status === 401 && error.response.data.code === '401-A002') {
                    localStorage.clear()
                    await getRefreshToken()
                }
            }
        }

        const token = getLocalAccesToken('access_token')
        if (!token) await getRefreshToken()

        const request = async () => {
            const requestKey = (method === METHOD.GET) ? 'params' : 'data'
            try {
                const response = await axios({
                    url: `${api}${initPathVariable || pathVariable ? ('/' + (initPathVariable || pathVariable)) : ''}`,
                    method: method,
                    [requestKey]: {
                        ...initPayload,
                        ...payload
                    },
                    [Object.keys(additionalQueryParams).length > 0 ? 'params' : null]: {
                        ...additionalQueryParams
                    },
                    headers: header,
                    responseType
                })
                dispatchResponse({type: ACTION_TYPE_RESPONSE.SUCCESS, payload: response.data.data})
                if (options.isWithTotalData) dispatch(setToolsTotalData(response?.data?.data?.total_result ?? response?.data?.data?.reviews?.total_result ?? 0))
                return response.data
            } catch (error) {
                if (atempt === 2){
                    resetAuthHeader()
                    localStorage.clear()
                    window.location.reload()
                    throw error?.response?.data
                }
                if (error?.response?.status === 401) {
                    switch (error.response.data.code) {
                        case '401-A003':
                        case '401-A006':
                        case '401-A007':
                            await getRefreshToken()
                            atempt = atempt + 1
                            return await request()
                    }
                }
                if (error?.response?.data?.code === '503-0000') {
                    resetAuthHeader()
                    localStorage.clear()
                    navigate('/under-maintenance')
                    throw error?.response?.data
                }

                handlingError(error?.response)
                throw error?.response?.data
            }
        }
        return await request()
    }

    return {data, fetching, isLoading: data.isLoading}
}
