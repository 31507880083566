export const levelAndBenefitModel = {
    items: [{
        id: 'level',
        name: null,
        discount_amount: null,
        max_redeem_amount: null,
        min_total_order_amount: null,
        max_total_order_amount: null
    }],
    delete_ids: []
}
