import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    scales
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import {doConvertNumberToRupiahFormat, doConvertNumberWithPoint} from "../utils/general-variable";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    scales
)

const GraphAnalyticsComponent = (props) => {
    const {title, slug, dataList} = props
    const dataCurrent = dataList?.currentPeriod || dataList?.current_period
    const dataPrevious = dataList?.prevPeriod || dataList?.previous_period
    const dataPeriod = [
        {
            label: 'currentPeriod',
            data: dataCurrent,
            borderColor: 'rgb(95, 108, 74)',
            backgroundColor: 'rgb(95, 108, 74, 1)',
        }, {
            label: 'prevPeriod',
            data: dataPrevious?.map((d, i) => {
                return { ...d,
                    xPrev: d?.x,
                    x: dataCurrent[i]?.x,
                }
            }),
            borderColor: 'rgb(82, 136, 185)',
            backgroundColor: 'rgb(82, 136, 185, 1)',
        }
    ]
    const [data, setData] = useState([])
    const [currentGraph, setCurrentGraph] = useState(true)
    const [prevGraph, setPrevGraph] = useState(true)
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    callback: value => {
                        return slug === 'revenue' ? doConvertNumberToRupiahFormat(value) : doConvertNumberWithPoint(value)
                    }
                },
            }
        },
        plugins: {
            tooltip: {
                yAlign: 'bottom',
                callbacks: {
                    title: chart => {
                        if (slug === 'revenue') {
                            return doConvertNumberToRupiahFormat(chart[0].raw.y)
                        } else {
                            return `${doConvertNumberWithPoint(chart[0].raw.y)} ${slug}`
                        }
                    },
                    label: chart => {
                        if (chart.dataset.label === 'prevPeriod') {
                            return chart.raw.xPrev
                        } else {
                            return chart.raw.x
                        }
                    }
                }
            }
        }
    }

    const checkedGraph = (event, slug) => {
        const isChecked = event.target.checked
        if (slug === 'current-period') {
            setCurrentGraph(isChecked)
        } else {
            setPrevGraph(isChecked)
        }
    }

    useEffect(() => {
        if (currentGraph && prevGraph) {
            setData(dataPeriod)
        } else if (currentGraph) {
            setData(dataPeriod.splice(0, 1))
        } else if (prevGraph) {
            setData(dataPeriod.splice(1, 1))
        } else {
            setData(dataPeriod.map(() => []))
        }
    }, [currentGraph, prevGraph, dataList])

    return (
        <div className={"col space-y-4"}>
            <div className={"row space-x-4"}>
                <div className={`row items-center space-x-2 w-fit p-2 border rounded-lg 
                        ${currentGraph ? 'border-monstera bg-doctor' : 'border-placebo bg-white text-forged-steel'}`}>
                    <input type="checkbox" className={'accent-monstera cursor-pointer'}
                           id={'current-period'} defaultChecked
                           onClick={(event) => checkedGraph(event, 'current-period')}/>
                    <label htmlFor={'current-period'} className={"cursor-pointer"}>
                        Periode Saat Ini
                    </label>
                    <div className={"bg-monstera p-1.5"}></div>
                </div>
                <div className={`row items-center space-x-2 w-fit p-2 border rounded-lg
                        ${prevGraph ? 'border-monstera bg-doctor' : 'border-placebo bg-white text-forged-steel'}`}>
                    <input type="checkbox" className={'accent-monstera cursor-pointer'}
                           id={'previous-period'} defaultChecked
                           onClick={(event) => checkedGraph(event, 'previous-period')}/>
                    <label htmlFor={'previous-period'} className={"cursor-pointer"}>
                        Periode Sebelumnya
                    </label>
                    <div className={"bg-dazzle p-1.5"}></div>
                </div>
            </div>
            <div className={"col bg-doctor"}>
                <div className={"row w-full"}>
                    <div
                        className={"col items-center w-1/12 justify-between bg-quiet-whisper text-sm font-Montserrat-Bold"}>
                        <div/>
                        <p className={"-rotate-90 text-center"}>{title}</p>
                        <div className={"row items-center justify-center w-full h-12 p-4 bg-liberated-lime"}>
                            <p>Tanggal</p>
                        </div>
                    </div>
                    <div className={"pl-16 py-4 w-11/12 h-96"}>
                        <Line options={options} data={{datasets: data}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphAnalyticsComponent
