import GeneralInformationForm from "./general-information-form";
import SaleInformationForm from "./sale-information-form";
import ProductInformationForm from "./product-information-form";
import SeoSettingsForm from "./seo-settings-form";
import ProductPageSettingsForm from "./product-page-settings-form";
import {
    FIELD_LIST_AVAILABLE_BEAUTY_EMPTIES,
    FIELD_LIST_CREATE_PRODUCT_GENERAL_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_PAGE_SETTINGS,
    FIELD_LIST_CREATE_PRODUCT_SALE_INFORMATION,
    FIELD_LIST_CREATE_PRODUCT_SEO_SETTINGS
} from "../../utils/input-field-list";
import React, {useEffect} from "react";
import TabToggleComponent from "../../components/tab-toggle.component";
import UploadImageForm from "./upload-image-form";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import {setProductErrorMessage, setProductInitialProductDetail} from "../../reducers/product.reducer";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility
} from "../../reducers/tools.reducer";
import {useNavigate, useParams} from "react-router-dom";
import {cloneDeep} from "lodash";
import {convertErrorMessage, convertUtcToCustomFormat} from "../../utils/general-variable";
import moment from "moment/moment";
import AvailableBeautyEmptiesForm from "./available-beauty-empties-form";

const CreateProductPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const {fetching: categoryList} = useFetch(API.CATEGORIES)
    const {fetching: postCreateProduct, isLoading: loadingCreateProduct} = useFetch(API.PRODUCT_CREATE_PRODUCT)
    const {
        fetching: postUpdateProduct,
        isLoading: loadingUpdateProduct
    } = useFetch({...API.PRODUCT_UPDATE_PRODUCT, ...{initPathVariable: id}})
    const {
        fetching: postSaveAsDraft,
        isLoading: loadingSaveAsDraft
    } = useFetch({...API.PRODUCT_SAVE_AS_DRAFT_PRODUCT, ...{initPathVariable: id}})
    const {productDetail} = useSelector(state => state.productReducer)
    let generalField = FIELD_LIST_CREATE_PRODUCT_GENERAL_INFORMATION
    let saleField = FIELD_LIST_CREATE_PRODUCT_SALE_INFORMATION
    let productField = FIELD_LIST_CREATE_PRODUCT_INFORMATION
    let pageField = FIELD_LIST_CREATE_PRODUCT_PAGE_SETTINGS
    let seoField = FIELD_LIST_CREATE_PRODUCT_SEO_SETTINGS
    let availableBeautyEmptiesField = FIELD_LIST_AVAILABLE_BEAUTY_EMPTIES

    const toggleList = [
        {
            title: 'Informasi Umum',
            content: <GeneralInformationForm fieldList={generalField}/>
        }, {
            title: 'Informasi Penjualan',
            content: <SaleInformationForm fieldList={saleField}/>
        }, {
            title: 'Informasi Produk',
            content: <ProductInformationForm fieldList={productField}/>
        }, {
            title: 'Product Page Settings',
            content: <ProductPageSettingsForm fieldList={pageField}/>
        }, {
            title: 'SEO Settings',
            content: <SeoSettingsForm fieldList={seoField}/>
        }, {
            title: 'Beauty Empties Settings',
            content: <AvailableBeautyEmptiesForm fieldList={availableBeautyEmptiesField}/>
        }
    ]

    useEffect(() => {
        categoryList().then(response => {
            generalField[0][1].dataList = response.data
        })
        return () => {
            dispatch(setProductErrorMessage({}))
            dispatch(setProductInitialProductDetail())
        }
    }, [])

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        navigate('/product/product-list')
    }

    const dataChecking = (field, value, buttonType) => {
        dispatch(setProductErrorMessage({}))
        const productDetailCopy = cloneDeep(productDetail)
        let error = {}
        let dates = []
        field.forEach((item) => {
            switch (item.key) {
                case 'categories':
                    if (productDetail[item.key].length === 0) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'main_image':
                    if (Object.keys(productDetail[item.key]).length === 0) {
                        error[item.key] = `${item.label} is required`
                        productDetailCopy[item.key] = null
                    }
                    break
                case 'sale_start':
                case 'sale_end':
                    if(value[item.key]?.value) {
                        productDetailCopy[item.key] = convertUtcToCustomFormat(value[item.key]?.value, "YYYY-MM-DD HH:mm")
                        dates.push(value[item.key]?.value)
                    } else {
                        productDetailCopy[item.key] = null
                    }

                    if(dates.length === 2){
                        const errorMessage = 'End Sale Must be Greater Than Start Sale'
                        if(!moment(dates[0]).isBefore(dates[1])) {
                            error['sale_start'] = errorMessage
                            error['sale_end'] = errorMessage
                        }
                    }
                    break
                case 'ingredient_id':
                case 'ingredient_en':
                case 'description_en':
                case 'description_id':
                    if (!productDetail[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                case 'limit_purchase':
                    productDetailCopy[item.key] = Number(value[item.key]?.value)
                    break
                case 'slug':
                    const CHECK_SLUG = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
                    if (value[item.key]?.value && !CHECK_SLUG.test(value[item.key]?.value)) {
                        error[item.key] = `${item.label} is not valid, axample: facial-cleanser-avoskin-your-skin-bae-kojic-acid-100ml-kulit-kusam`
                    } else {
                        productDetailCopy[item.key] = value[item.key]?.value || null
                    }
                    break
                default:
                    if (item.required && !value[item.key]?.value) {
                        error[item.key] = `${item.label} is required`
                    } else if (item.type === 'number') {
                        productDetailCopy[item.key] = value.namedItem(item.key)?.value ? Number(value.namedItem(item.key)?.value) : null
                    } else if (item.type === 'text') {
                        productDetailCopy[item.key] = value[item.key]?.value || null
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setProductErrorMessage({}))
            return {isValid: true, product: productDetailCopy}
        } else if (buttonType === 'draft') {
            dispatch(setProductErrorMessage({
                name: error?.name,
                sale_start: error?.sale_start,
                sale_end: error?.sale_end,
            }))
            return {isValid: !error.sale_start && !error?.name, product: productDetailCopy}
        } else {
            dispatch(setProductErrorMessage(error))
            return {isValid: false, product: productDetailCopy}
        }
    }

    const onSubmitHandler = (buttonType, e) => {
        e.preventDefault()
        let field = [...generalField[0], ...generalField[1], ...saleField, ...productField, ...pageField, ...seoField[0], ...seoField[1]]
        let formValue = document.getElementById("form").elements
        const {isValid, product} = dataChecking(field, formValue, buttonType)
        if (isValid) {
            let requestBody = {
                ...product,
                categories: product.categories.map(val => val.id),
                available_for_beauty_empties: product.available_for_beauty_empties.toString() === 'true'
            }

            getPromiseApi(buttonType, requestBody).then(() => {
                showAlertModal(getSuccessMessage(buttonType))
            }).catch(err => {
                dispatch(setProductErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getSuccessMessage = (type) => {
        switch (type) {
            case 'publish':
                return 'Product Published'
            case 'update':
                return 'Product Updated'
            default:
                return 'Product Drafted'
        }
    }

    const getPromiseApi = (type, requestBody) => {
        switch (type) {
            case 'publish':
                return postCreateProduct(requestBody)
            case 'update':
                return postUpdateProduct(requestBody)
            default:
                return postSaveAsDraft(requestBody)
        }
    }

    return (
        <div className={"col px-10 mt-4"}>
            <form id={"form"} className={"col space-y-4 mb-10"}>
                {
                    toggleList.map((item, index) =>
                        <TabToggleComponent
                            key={index}
                            title={item.title}
                            tooltipText={item.tooltipText}
                            content={item.content}
                        />
                    )
                }
                <div className={"row font-semibold justify-center space-x-4 py-8"}>
                    <button onClick={(e) => onSubmitHandler('draft', e)}
                            className={"w-60 py-2 border border-fortress-grey hover:text-white hover:bg-dazzle rounded-lg"}>
                        {loadingSaveAsDraft ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save As Draft'}
                    </button>
                    <button onClick={(e) => onSubmitHandler(!!id ? 'update' : 'publish', e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        {loadingCreateProduct || loadingUpdateProduct ?
                            <img src={LoadingSvg} className={"w-5 mx-auto"}/> : !!id ? 'Update' : 'Publish'}
                    </button>
                </div>
            </form>
            <UploadImageForm/>
        </div>
    )
}

export default CreateProductPage
