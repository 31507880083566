import {SearchIcon} from "../utils/image";
import React, {useEffect, useState} from "react";
import {getImageLocal} from "../utils/general-variable";
import CheckboxItemComponent from "./checkbox-item.component";
import {useDispatch} from "react-redux";
import {setVoucherRestrictionDetail} from "../reducers/voucher.reducer";

const CheckboxListComponent = (props) => {
    const {item, data} = props
    const dispatch = useDispatch()
    const [showCheckAll, setShowCheckAll] = useState(true)
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [list, setList] = useState([])

    const searchListOption = (event) => {
        const keyword = event.target.value
        const filterList = item?.dataList.filter(list => list.name.toLowerCase().includes(keyword.toLowerCase()))
        setList(filterList)
        if (keyword.length === 0) {
            setShowCheckAll(true)
        } else {
            setShowCheckAll(false)
        }
    }

    const onCheckedAll = (value) => {
        if(value){
            let array = []
            list.forEach(i => {array.push(i[item.selectValue])})
            dispatch(setVoucherRestrictionDetail({[item.key]: array}))
        } else {
            dispatch(setVoucherRestrictionDetail({[item.key]: []}))
        }
        setIsCheckAll(value)
    }

    useEffect(() => {
        setList(item?.dataList)
    }, [item.dataList])

    return (
        <div className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${item?.dataList.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                <input type={"text"} placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"} onChange={searchListOption}/>
            </div>
            <div
                className={"col space-y-3 bg-white border-x border-b rounded-b-lg border-placebo max-h-48 overflow-scroll"}>
                <div
                    className={`${showCheckAll ? 'flex' : 'hidden'} row space-x-5 px-5 py-2 w-full bg-apple-flower text-monstera`}>
                    <input id={item?.titleType?.id} type="checkbox" className={`accent-monstera ${item?.titleType.name}`} checked={isCheckAll} onChange={e => onCheckedAll(e.target.checked)}/>
                    <label htmlFor={item?.titleType?.id}
                           className={"row space-x-1 justify-center text-forged-steel font-regular"}>
                        Select All {item?.titleType?.name}
                    </label>
                </div>
                {list.length > 0 ? list.map((l, index) => (
                    <div key={index}
                         className={"row space-x-5 px-5 py-2 w-full hover:bg-apple-flower hover:text-monstera"}>
                        <CheckboxItemComponent id={`${l?.id + item.key}`} itemKey={`${item.key}`} value={l[item.selectValue]}
                                               data={data.includes(l[item.selectValue])}/>
                        <label htmlFor={`${l?.id + item.key}`}
                               className={"row space-x-3 items-center justify-center text-forged-steel font-regular"}>
                            {!(item.key === 'include_category_ids' || item.key === 'exclude_category_ids') &&
                                <img src={getImageLocal(l[item.selectValue])} alt="imageDataOption" className={"w-8"}/>}
                            <p>{l[item.selectLabel]}</p>
                        </label>
                    </div>)
                ) : <p className={"px-3 pb-3"}>Not Found</p>}
            </div>
        </div>
    )
}

export default CheckboxListComponent
