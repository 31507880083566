import {useEffect, useState} from "react"

export const InputCode = ({cols, value, onChange}) => {
    const [sValue, setValue] = useState()
    let inputRef = {}
    const formInput = []

    useEffect(() => {
        setValue(value.split(''))
    }, [value])

    const handleOnChange = (e, index) => {
        if (e.target.value === '' && index > 0) {
            inputRef[`form-code-${index}`].focus()
        } else if (e.target.value && (sValue.length + 1) < cols) {
            inputRef[`form-code-${sValue.length + 1}`].focus()
        }

        sValue[index] = e.target.value
        onChange(sValue.join(''))
    }

    const handleKeyDown = (e, index) => {
        if (e.keyCode === 8 && !value.charAt(index) && index > 0) {
            inputRef[`form-code-${index - 1}`].focus()
        }
    }

    for (let i = 0; i < cols; i++) {
        formInput.push(
            <input
                key={i}
                type='tel'
                className='disable-select appearance-none focus:outline-none font-bold text-2xl flex items-center justify-center rounded-lg border border-neutral-30 focus:border-fennel-stem focus:bg-whisper-green h-auto aspect-square text-center'
                maxLength={1}
                ref={x => inputRef[`form-code-${i}`] = x}
                onChange={(e) => handleOnChange(e, i)}
                onKeyDown={(e) => handleKeyDown(e, i)}
                value={value.charAt(i)}
            />
        )

    }
    return (
        <div
            className={`grid gap-4`}
            style={{gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`}}>
            {formInput}
        </div>
    )
}

export default InputCode
