import {Header} from "./header";
import {Sidebar} from "./sidebar";
import {SIDEBAR} from "../../utils/constant";
import {isLogin, isStaticPage} from "../../utils/general-variable";
import AlertComponent from "../../components/alert.component";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useDispatch} from "react-redux";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";
import ToastComponent from "../../components/toast.component";

export const Layout = ({children}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const path = location.pathname
    const {fetching: checkAPI} = useFetch(API.AREA_LIST, {simplified: true})
    const isAuthenticated = isLogin()
    const token = JSON.parse(localStorage.getItem('token'))
    const module_ids = token?.module_ids ? token?.module_ids : []
    const [sidebarList, setSidebarList] = useState([])

    useEffect(() => {
        let menu = []
        SIDEBAR.map(sidebar => {
            module_ids.map(id => {
                if(sidebar?.module_id === id) menu.push(sidebar)
            })
        })
        setSidebarList(menu)
    }, [])

    useEffect(() => {
        if (isStaticPage(path)) {
            checkAPI().then().catch(() => {
                dispatch(setToolsModalVisibility({alert: false}))
            })
        }
    }, [path])

    return (
        <div>
            {!!isAuthenticated && path !== '/under-maintenance' ?
                <div className={"row text-xs"}>
                    <Sidebar data={sidebarList}/>
                    <div className={"w-full"}>
                        <Header/>
                        {children}
                    </div>
                </div> :
                <>
                    {children}
                </>
            }
            <AlertComponent/>
            <ToastComponent/>
        </div>
    )
}
