import {createSlice} from '@reduxjs/toolkit'
import {avostoreModel} from "../models/avostore.model";

export const avostoreSlice = createSlice({
    name: 'avostore',
    initialState: {
        selectedRowKeysAvostore: [],
        avostoreDetail: {...avostoreModel},
        avostoreErrorMessage: {},
        activeTab: {
            id: 0,
            label: 'Upload XLSX File',
            key: 'upload_xlsx_file'
        },
        importAvostoreDetail: {file: null},
        importResult: {}
    },
    reducers: {
        setAvostoreSelectedRowKeys: (state, action) => {
            state.selectedRowKeysAvostore = action.payload
        },
        setAvostoreDetail: (state, action) => {
            state.avostoreDetail = {...state.avostoreDetail, ...action.payload}
        },
        setAvostoreErrorMessage: (state, action) => {
            state.avostoreErrorMessage = action.payload
        },
        setAvostoreInitialAvostoreDetail: (state, action) => {
            state.avostoreDetail = {...avostoreModel}
        },
        setAvostoreActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setAvostoreImportAvostoreDetail: (state, action) => {
            state.importAvostoreDetail = {...state.importAvostoreDetail, ...action.payload}
        },
        setAvostoreImportResult: (state, action) => {
            state.importResult = action.payload
        },
        setAvostoreInitialImportAvostoreDetail: (state) => {
            state.importAvostoreDetail = {file: null}
            state.importResult = {}
        },
    },
})

export const {
    setAvostoreSelectedRowKeys,
    setAvostoreDetail,
    setAvostoreInitialAvostoreDetail,
    setAvostoreErrorMessage,
    setAvostoreActiveTab,
    setAvostoreImportAvostoreDetail,
    setAvostoreImportResult,
    setAvostoreInitialImportAvostoreDetail
} = avostoreSlice.actions
const avostoreReducer = avostoreSlice.reducer

export default avostoreReducer