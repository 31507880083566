import React, {useState} from "react";
import {Tab} from "./tab";
import {USER_DETAIL_TAB_LIST} from "../../utils/constant";
import TabProfile from "./tab-profile";
import Profile from "./profile";
import TabAddress from "./tab-address";
import TabOrder from "./tab-order";
import TabPoint from "./tab-point";
import TabReview from "./tab-review";
import TabWishlist from "./tab-wishlist";
import TabWaitlist from "./tab-waitlist";
import CommentDetail from "../../components/comment-detail.component";
import TabReferral from "./tab-referral";
import {useDispatch, useSelector} from "react-redux";
import {setUserActiveTab} from "../../reducers/user.reducer";

const UserDetail = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.userReducer)

    const changeActiveTab = (item) => {
        dispatch(setUserActiveTab(item))
    }

    const content = {
        profile: <TabProfile/>,
        alamat: <TabAddress/>,
        pesanan: <TabOrder/>,
        poin: <TabPoint/>,
        review: <TabReview/>,
        wishlist: <TabWishlist/>,
        waitlist: <TabWaitlist/>,
        referral: <TabReferral/>
    }

    return (
        <>
            <div className={"my-5 space-y-5"}>
                <Profile/>
                <Tab data={USER_DETAIL_TAB_LIST} onChange={changeActiveTab} activeTab={activeTab}/>
                {content[activeTab]}
            </div>
            <CommentDetail/>
        </>
    )
}

export default UserDetail
