import SearchComponent from "../../components/search.component";
import {SORT_LIST_AREA} from "../../utils/sort-list";
import ExportComponent from "../../components/export.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";
import React from "react";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";

const AreaFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysArea} = useSelector(state => state.areaReducer)
    const {orderBy, sort} = useSelector(state => state.toolsReducer)
    const {fetching: exportAreaList, isLoading: loadingExport} = useFetch(API.EXPORT_AREA_LIST)

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy ?? 'id',
            order_type: sort,
            avostore_area_ids: selectedRowKeysArea
        }
        exportAreaList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Area-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"cari area..."}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_AREA}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data area ?`}
                            countSelected={selectedRowKeysArea.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AreaFilter
