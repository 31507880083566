import DateRangePickerComponent from "../../components/date-range-picker.component";
import React from "react";
import {setToolsCurrentPage, setToolsModalVisibility, setToolsStartDateEndDate} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import ExportComponent from "../../components/export.component";
import {downloadFileExport, formatDateToReadable} from "../../utils/general-variable";
import moment from "moment/moment";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import SearchComponent from "../../components/search.component";

const AvoStoriesUserFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysAvoStoriesUser} = useSelector(state => state.avoStoriesUserReducer)
    const {fetching: exportAvoStoriesUserList, isLoading: loadingExport} = useFetch(API.EXPORT_AVO_STORIES_USER_LIST)
    const {dateRange} = useSelector(state => state.toolsReducer)

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const exportDataHandler = () => {
        const body = {
            start_date: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            end_date: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            customerLeadIds: selectedRowKeysAvoStoriesUser
        }
        exportAvoStoriesUserList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Avo-Stories-User-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row space-x-2 items-center my-5"}>
            <SearchComponent placeholder={"cari user..."}/>
            <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
            <ExportComponent
                wordingConfirmation={`Apakah anda yakin ingin melakukan export data avo stories user ?`}
                countSelected={selectedRowKeysAvoStoriesUser.length}
                exportDataHandler={exportDataHandler}
                isLoading={loadingExport}
            />
        </div>
    )
}

export default AvoStoriesUserFilter
