import {useDispatch, useSelector} from "react-redux";
import UploadXlsxFile from "./upload-xlsx-file";
import Import from "./import";
import Done from "./done";
import React, {useEffect} from "react";
import {STEP_IMPORT_AVOSTORE} from "../../utils/constant";
import {setAvostoreActiveTab, setAvostoreInitialImportAvostoreDetail} from "../../reducers/avostore.reducer";
import StepperComponent from "../../components/stepper.component";

const ImportStorePage = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.avostoreReducer)

    const content = {
        upload_xlsx_file: <UploadXlsxFile/>,
        import: <Import/>,
        done: <Done/>
    }

    useEffect(() => {
        return () => {
            dispatch(setAvostoreInitialImportAvostoreDetail())
            dispatch(setAvostoreActiveTab(STEP_IMPORT_AVOSTORE[0]))
        }
    }, [])

    return (
        <div className={"col justify-center space-y-8 px-10 mt-4"}>
            <StepperComponent stepList={STEP_IMPORT_AVOSTORE} activeTab={activeTab}/>
            <div className={"px-20"}>
                {content[activeTab.key]}
            </div>
        </div>
    )
}

export default ImportStorePage