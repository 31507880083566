import {useDispatch, useSelector} from "react-redux";
import {setReviewActiveTab} from "../../reducers/review.reducer";
import {TAB_REVIEW_DETAIL} from "../../utils/constant";
import {setToolsCurrentPage} from "../../reducers/tools.reducer";

const ReviewDetailTab = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.reviewReducer)

    const onChangeTab = (key) => {
        dispatch(setReviewActiveTab(key))
        dispatch(setToolsCurrentPage(1))
    }

    return (
        <div className={"border-b border-placebo"}>
            <div className={"overflow-x-scroll mx-10 -mb-px"}>
                <div
                    className={"row space-x-4 items-center text-forged-steel mt-5 font-semibold"}>
                    {TAB_REVIEW_DETAIL.map((item, index) => (
                        <div key={index} onClick={() => onChangeTab(item.key)} className={`row justify-center p-3 flex-shrink-0 
                        ${activeTab === item.key && 'rounded-t-lg border-x border-t border-placebo bg-white'} cursor-pointer`}>
                            <p className={`overflow-hidden ${activeTab === item.key && 'text-monstera font-Montserrat-Bold'}`}>{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ReviewDetailTab
