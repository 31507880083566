import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setBeautyFridayFlashSaleDetail} from "../../../reducers/beauty-friday.reducer";

const GeneralForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {flashSaleDetail, beautyFridayErrorMessage} = useSelector(state => state.beautyFridayReducer)

    const onChangeInput = (key, value) => {
        dispatch(setBeautyFridayFlashSaleDetail({[key]: value}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <div key={index} className={item.key === 'icon_flash_sale' ? 'col-span-2' : ''}>
                    <InputComponent item={item} data={flashSaleDetail[item.key]} onChange={onChangeInput} error={beautyFridayErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default GeneralForm