import React from "react";
import InputComponent from "../../components/input.component";
import {useSelector} from "react-redux";

export const AccessibilityForm = ({fieldList}) => {
    const {userErrorMessage} = useSelector(state => state.userReducer)
    return (
        <div className={"grid grid-cols-2 px-10 gap-20"}>
            {
                fieldList.map((item, index) => <InputComponent item={item} key={index} error={userErrorMessage}/>)
            }
        </div>
    )
}
