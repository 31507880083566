import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    setToolsModalVisibility,
    setToolsOrderBy,
    setToolsSort
} from "../reducers/tools.reducer";
import {CloseIcon, ListIcon} from "../utils/image";
import {useClickOutside} from "../services/useClickOutside";

const SortComponent = ({ sortList = [] }) => {
    const dispatch = useDispatch()
    const {orderBy, sort, modalVisibility} = useSelector(state => state.toolsReducer)
    const defaultSort = sortList?.find(item => item?.isDefault)
    const [checkedBySort, setCheckedBySort] = useState('')
    const [selectedItem, setSelectedItem] = useState(defaultSort.label)

    const onCheckedHandler = (event, label) => {
        const value = event.target.value
        const splitText = value.split('_')
        dispatch(setToolsOrderBy(splitText[0]))
        dispatch(setToolsSort(splitText[1]))
        setSelectedItem(label)
        closeDropdownHandler()
    }

    const showHideDropdownHandler = () => {
        dispatch(setToolsModalVisibility({sort: !modalVisibility.sort}))
    }

    const closeDropdownHandler = () => {
        dispatch(setToolsModalVisibility({sort: false}))
    }

    useEffect(() => {
        setCheckedBySort(orderBy ? `${orderBy}_${sort}` : defaultSort?.key)
    }, [modalVisibility.sort])

    useClickOutside('sort-component', modalVisibility?.sort, closeDropdownHandler)

    return (
        <div id={"sort-component"} className={"font-regular"}>
            <div onClick={showHideDropdownHandler}
                 className={"row items-center space-x-2 px-3 py-2 h-9.5 w-fit font-semibold rounded-lg border border-placebo cursor-pointer"}>
                <img className={"h-5"} src={ListIcon} alt="listIcon"/>
                <p className={"shrink-0"}>{selectedItem}</p>
            </div>
            <div className={`absolute z-20 mt-2 shadow-md bg-white rounded-xl ${modalVisibility?.sort ? 'block' : 'hidden'}`}>
                    <div
                        className={"relative col space-y-8 w-[300px] pl-8 pr-5 py-5"}>
                        <p className={"text-center font-semibold"}>Sort</p>
                        <div className={"col space-y-4 pr-10"}>
                            {sortList.map((list, index) => (
                                <div key={index} className={"row space-x-5 py-1 w-full"}>
                                    <input type="radio" value={list.key}
                                           className={"accent-monstera"} id={list.key}
                                           checked={checkedBySort === list.key} onChange={(e) => onCheckedHandler(e, list.label)}
                                    />
                                    <label htmlFor={list.key}
                                           className={"text-forged-steel font-regular"}>{list.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                <img src={CloseIcon} alt="closeButton"
                     className={"w-5 absolute right-4 top-2 cursor-pointer"}
                     onClick={closeDropdownHandler}/>
            </div>
        </div>
    )
}

export default SortComponent
