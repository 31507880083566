import AffiliateFilter from "./affiliate-filter";
import {useDispatch, useSelector} from "react-redux";
import {SORT_LIST_AFFILIATE} from "../../utils/sort-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffect} from "react";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";
import AffiliateList from "./affiliate-list";
import CreateAffiliateForm from "./create-affiliate-form";
import {setAffiliateSelectedRowKeys} from "../../reducers/affiliate.reducer";

const AffiliatePage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_AFFILIATE?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
    }
    const {
        data: response,
        fetching: getAffiliateList,
        isLoading
    } = useFetch(API.AFFILIATE_LIST, queryParams, {isWithTotalData: true})


    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setAffiliateSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getAffiliateList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, reload])


    return (
        <div className={"col px-10"}>
            <div className={"row justify-between items-center"}>
                <AffiliateFilter/>
                <CreateAffiliateForm/>
            </div>
            <AffiliateList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default AffiliatePage
