import {createSlice} from '@reduxjs/toolkit'
import {levelAndBenefitModel} from "../models/level-and-benefit.model";

export const levelAndBenefitSlice = createSlice({
    name: 'levelAndBenefit',
    initialState: {
        levelAndBenefitDetail: {...levelAndBenefitModel},
        levelAndBenefitErrorMessage: {}
    },
    reducers: {
        setLevelAndBenefitDetail: (state, action) => {
            state.levelAndBenefitDetail = {...state.levelAndBenefitDetail, ...action.payload}
        },
        setLevelAndBenefitErrorMessage: (state, action) => {
            state.levelAndBenefitErrorMessage = action.payload
        }
    },
})

export const {
    setLevelAndBenefitDetail,
    setLevelAndBenefitErrorMessage
} = levelAndBenefitSlice.actions
const levelAndBenefitReducer = levelAndBenefitSlice.reducer

export default levelAndBenefitReducer
