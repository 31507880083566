import React, {useEffect, useRef, useState} from "react";
import {ArrowDownIcon} from "../utils/image";
import {useClickOutside} from "../services/useClickOutside";

const SearchSelectAddressComponent = (props) => {
    const {item, onChangeSelect, onChangeSearch, defaultValue} = props
    const [isShowModal, setIsShowModal] = useState(false)
    const searchValue = useRef()
    const [list, setList] = useState([])

    const showHideModal = () => {
        setIsShowModal(!isShowModal)
    }

    const searchListOption = (event) => {
        const keyword = event.target.value
        const filterList = item?.options?.filter(list => list[item.selectLabel].toLowerCase().includes(keyword.toLowerCase()))
        !!onChangeSearch && onChangeSearch(keyword)
        if (keyword.length === 0) {
            setIsShowModal(false)
        } else {
            setList(filterList)
            setIsShowModal(true)
        }
    }

    const onChangeData = (dataItem) => {
        !!onChangeSelect && onChangeSelect(dataItem)
        showHideModal()
        searchValue.current.value = dataItem[item.selectLabel]
    }

    useEffect(() => {
        setList(item?.options)
        if (item.key === 'zipcode' && defaultValue) {
            searchValue.current.value = defaultValue[item.selectLabel]
        }
    }, [item])

    useClickOutside(`search-select-address-component-${item.key}`, isShowModal, setIsShowModal)

    return (
        <div id={`search-select-address-component-${item.key}`} className={"relative col font-regular text-forged-steel"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo bg-white ${isShowModal ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <input type={"text"} name={item.key} id={item.key}
                       className={"border-0 outline-0 px-3 w-full disabled:bg-white placeholder:italic"}
                       ref={searchValue} onChange={searchListOption} placeholder={item?.placeholder}
                       defaultValue={defaultValue?.[item.selectLabel]}/>
                <img className={"w-2.5 h-5"} src={ArrowDownIcon} alt={'arrowDown'}/>
            </div>
            <div className={`${isShowModal ? 'block' : 'hidden'} absolute top-8 w-full z-10`}>
                <div
                    className={"col bg-white border border-placebo mb-5 max-h-32 overflow-scroll"}>
                    {list?.length > 0 ? list?.map((l, index) => (
                        <div key={index}
                             className={"row p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => onChangeData(l)}>
                            <p>{l[item.selectLabel]}</p>
                        </div>
                    )) : <p className={"p-3 text-center"}>Not Found</p>}
                </div>
            </div>
        </div>
    )
}

export default SearchSelectAddressComponent
