import {
    setAreaDetail,
    setAreaErrorMessage,
    setAreaInitialAreaDetail,
    setAreaModalVisibility
} from "../../reducers/area.reducer";
import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "../../components/modal-card.component";
import {CloseIcon, LoadingSvg, PlusIcon} from "../../utils/image";
import React from "react";
import {FIELD_LIST_ADD_NEW_AREA} from "../../utils/input-field-list";
import InputComponent from "../../components/input.component";
import _ from "lodash";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";

const CreateAreaForm = () => {
    const dispatch = useDispatch()
    const {reload} = useSelector(state => state.toolsReducer)
    const {areaDetail, areaErrorMessage, modalVisibility} = useSelector(state => state.areaReducer)
    const {addArea, editArea} = modalVisibility
    const {fetching: addAreaFetching, isLoading: loadingAddArea} = useFetch(API.CREATE_AREA)
    const {fetching: EditAreaFetching, isLoading: loadingEditArea} = useFetch(API.UPDATE_AREA)
    let addAreaField = FIELD_LIST_ADD_NEW_AREA

    const showModalHandler = () => {
        dispatch(setAreaModalVisibility({addArea: true}))
    }

    const closeModalHandler = () => {
        document.getElementById("form").reset()
        dispatch(setAreaErrorMessage({}))
        dispatch(setAreaInitialAreaDetail())
        dispatch(setAreaModalVisibility({addArea: false, editArea: false}))
    }

    const onChangeInput = (key, value) => {
        dispatch(setAreaDetail({[key]: value}))
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        dispatch(setToolsReload(!reload))
        closeModalHandler()
    }

    const dataChecking = (field, value) => {
        dispatch(setAreaErrorMessage({}))
        const areaDetailCopy = _.cloneDeep(areaDetail)
        let error = {}
        field.forEach((item) => {
            if (item.required && !value[item.key]?.value) {
                error[item.key] = `${item.label} is required`
            } else if (item.type === 'text') {
                areaDetailCopy[item.key] = value[item.key]?.value
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setAreaErrorMessage({}))
            return {isValid: true, area: areaDetailCopy}
        } else {
            dispatch(setAreaErrorMessage(error))
            return {isValid: false, area: areaDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let field = [...addAreaField]
        let formValue = document.getElementById("form").elements
        const {isValid, area} = dataChecking(field, formValue)
        if (isValid) {
            let requestBody = {...area}
            getPromiseApi(requestBody).then(() => {
                showAlertModal(addArea ? 'Area Created' : 'Area Updated')
            }).catch(err => {
                dispatch(setAreaErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    const getPromiseApi = (requestBody) => {
        if (addArea) {
            return addAreaFetching(requestBody)
        } else {
            return EditAreaFetching(requestBody, areaDetail.id)
        }
    }

    return (
        <>
            <div
                className={"row items-center space-x-2 text-white bg-battleship-green hover:bg-monstera py-2 px-8 font-semibold rounded-lg cursor-pointer"}
                onClick={showModalHandler}>
                <img src={PlusIcon} alt="PlusIcon" className={"w-5"}/>
                <p>Tambah Area</p>
            </div>
            <ModalCardComponent
                visibility={addArea || editArea}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <form id={"form"}>
                            <div className={"col space-y-8 w-96 px-5 py-5 bg-white rounded-xl"}>
                                <p className={"text-sm text-center text-monstera font-Montserrat-Bold"}>{addArea ? 'Add New Area' : 'Edit Area'}</p>
                                {addAreaField.map((item, index) => (
                                    <InputComponent key={index} item={item} data={areaDetail[item.key]}
                                                    onChange={onChangeInput}
                                                    error={areaErrorMessage}/>
                                ))}
                                <button onClick={(e) => onSubmitHandler(e)}
                                        className={"mx-auto px-20 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera font-semibold text-white rounded-lg cursor-pointer"}>
                                    {loadingAddArea || loadingEditArea ?
                                        <img src={LoadingSvg} className={"w-5 mx-auto"}/> : addArea ? 'Add Area' : 'Edit Area'}
                                </button>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default CreateAreaForm
