import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setMiraculousSeriesRelatedProductDetail} from "../../../reducers/miraculous-series.reducer";

const InputField = ({fieldList}) => {
    const dispatch = useDispatch()
    const {relatedProductDetail, miraculousSeriesErrorMessage} = useSelector(state => state.miraculousSeriesReducer)

    const onChange = (key, value, id) => {
        if(key === 'select_product'){
            let deleteList = relatedProductDetail?.delete_product || []
            dispatch(setMiraculousSeriesRelatedProductDetail({[key]: value, delete_product: [...deleteList, id]}))
        } else {
            dispatch(setMiraculousSeriesRelatedProductDetail({[key]: value}))
        }
    }

    const onChangeProduct = (key, value) => {
        let deleteList = relatedProductDetail?.delete_product?.filter(item => item !== value?.id)
        let currentProduct = relatedProductDetail.select_product
        dispatch(setMiraculousSeriesRelatedProductDetail({[key]: currentProduct.concat(value), delete_product: deleteList || []}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, index) => (
                <div key={index} className={"col-span-2"}>
                    <InputComponent item={item} data={relatedProductDetail[item.key]} onChange={onChange} onChangeInput={onChange}
                                    onChangeProduct={onChangeProduct} error={miraculousSeriesErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default InputField