export const notificationModel = {
    title: null,
    description: null,
    image_url: null,
    scheduled_at: null,
    type: null,
    icon: null,
    routing_type: null,
    routing_link: null,
    routing_order: null,
    routing_category_ids: [],
    routing_product_ids: [],
    routing_is_discounted: null,
    routing_product_id: null,
    receiver_type: null,
    receiver_emails: [],
    receiver_product_ids: [],
    receiver_start_date: null,
    receiver_end_date: null,
    receiver_order_times: null
}
