import {createSlice} from '@reduxjs/toolkit'
import {
    bannerModel,
    benefitModel,
    introModel,
    resultModel,
    videoModel
} from "../models/miraculous-retinol-series.model";

export const miraculousRetinolSeriesSlice = createSlice({
    name: 'miraculousRetinolSeries',
    initialState: {
        bannerDetail: {...bannerModel},
        videoDetail: {...videoModel},
        introDetail: {...introModel},
        benefitDetail: {...benefitModel},
        resultDetail: {...resultModel},
        miraculousRetinolSeriesErrorMessage: {},
    },
    reducers: {
        setMiraculousRetinolSeriesBannerDetail: (state, action) => {
            state.bannerDetail = {...state.bannerDetail, ...action.payload}
        },
        setMiraculousRetinolSeriesVideoDetail: (state, action) => {
            state.videoDetail = {...state.videoDetail, ...action.payload}
        },
        setMiraculousRetinolSeriesIntroDetail: (state, action) => {
            state.introDetail = {...state.introDetail, ...action.payload}
        },
        setMiraculousRetinolSeriesBenefitDetail: (state, action) => {
            state.benefitDetail = {...state.benefitDetail, ...action.payload}
        },
        setMiraculousRetinolSeriesResultDetail: (state, action) => {
            state.resultDetail = {...state.resultDetail, ...action.payload}
        },
        setMiraculousRetinolSeriesErrorMessage: (state, action) => {
            state.miraculousRetinolSeriesErrorMessage = action.payload
        }
    },
})

export const {
    setMiraculousRetinolSeriesBannerDetail,
    setMiraculousRetinolSeriesVideoDetail,
    setMiraculousRetinolSeriesIntroDetail,
    setMiraculousRetinolSeriesBenefitDetail,
    setMiraculousRetinolSeriesResultDetail,
    setMiraculousRetinolSeriesErrorMessage
} = miraculousRetinolSeriesSlice.actions
const miraculousRetinolSeriesReducer = miraculousRetinolSeriesSlice.reducer

export default miraculousRetinolSeriesReducer