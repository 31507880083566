import {createSlice} from '@reduxjs/toolkit'
import {categoryModel} from "../models/category.model";

export const categorySlice = createSlice({
    name: 'category',
    initialState: {
        selectedRowKeysCategory: [],
        categoryDetail: {...categoryModel},
        modalVisibility: {
            createCategory: false,
            updateCategory: false
        }
    },
    reducers: {
        setCategorySelectedRowKeys: (state, action) => {
            state.selectedRowKeysCategory = action.payload
        },
        setCategoryDetail: (state, action) => {
            state.categoryDetail = {...state.categoryDetail, ...action.payload}
        },
        setCategoryModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
        setCategoryInitialState: (state, action) => {
            state.selectedRowKeysCategory = []
        }
    },
})

export const {
    setCategorySelectedRowKeys,
    setCategoryDetail,
    setCategoryModalVisibility,
    setCategoryInitialState
} = categorySlice.actions
const categoryReducer = categorySlice.reducer

export default categoryReducer
