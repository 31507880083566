import {useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";

const GeneralForm = ({fieldList}) => {
    const {theCarouselDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[0].map((item, index) => (
                    <InputComponent key={index} item={item} data={theCarouselDetail[item.key]} error={mainPageErrorMessage}/>
                ))}
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[1].map((item, index) => (
                    <InputComponent key={index} item={item} data={(item.type === 'number' && theCarouselDetail[item.key] === 0) ? '0' : theCarouselDetail[item.key]} error={mainPageErrorMessage}/>
                ))}
            </div>
        </div>
    )
}

export default GeneralForm