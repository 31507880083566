import React from "react";
import {EditIcon} from "../../../utils/image";
import {useDispatch} from "react-redux";
import {setUserModalVisibility} from "../../../reducers/user.reducer";
import {convertDateToCustomFormat} from "../../../utils/general-variable";

export const ProfileDetail = ({details, data}) => {
    const dispatch = useDispatch()

    const showModalHandler = () => {
        dispatch(setUserModalVisibility({editProfile: true}))
    }

    return (
        <div className={"border rounded-md border-fennel-stem"}>
            <div className={"row items-center px-5 py-2 justify-between border-b rounded-t-md bg-doctor"}>
                <p className={"font-Montserrat-Bold text-lg text-monstera"}>Profile</p>
                <button className={"row items-center space-x-2 bg-battleship-green hover:bg-monstera p-2 rounded-md"}
                        onClick={showModalHandler}>
                    <img src={EditIcon} className={"w-4"}/>
                    <p className={"text-white font-bold text-xs"}>Edit Profile</p>
                </button>
            </div>
            <div className={"px-10 py-5 space-y-5"}>
                {
                    details.map((item, index) => (
                        <div className={"row items-center"} key={index}>
                            <p className={"w-2/5 text-monstera font-Montserrat-Bold"}>{item.label}</p>
                            <p className={`w-3/5 text-forged-steel ${item.key !== 'email' && 'capitalize'}`}>{item.key === 'birthdate' && !!data[item.key] ? convertDateToCustomFormat(data[item.key], "DD MMMM YYYY") : data[item.key]}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
