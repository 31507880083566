import {OpenStreetMapProvider} from "leaflet-geosearch";
import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {SearchIcon, SearchIconWhite} from "../../utils/image";

const LocationMarker = ({setPosition}) => {
    useMapEvents({
        click(e) {
            const clickPosition = e.latlng
            setPosition([clickPosition.lat, clickPosition.lng])
        }
    })
}

const RecenterAutomatically = ({lat, lng}) => {
    const map = useMap()
    map.setView([lat, lng])
}

const SearchMapComponent = (props) => {
    const {item, defaultValue, onChangeLocation, error} = props
    // Regular expression to check if string is a latitude and longitude
    const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi
    const provider = new OpenStreetMapProvider()
    const [showModal, setShowModal] = useState(false)
    const defaultLocation = [-7.797068, 110.370529]
    const [position, setPosition] = useState(defaultValue ?? defaultLocation)
    const [listAddress, setListAddress] = useState([])
    const [keyword, setKeyword] = useState('')

    const searchByLatLong = () => {
        const data = Array.from(keyword.split(','), Number)
        setPosition(data)
        setShowModal(false)
    }

    const onKeyupHandler = (event) => {
        if(event.key === 'Enter'){
            regexExp.test(keyword) ? searchByLatLong() : searchHandler()
        }
    }

    const onChangeSearch = (event) => {
        const key = event.target.value
        setKeyword(key)
    }

    const searchHandler = async () => {
        if(regexExp.test(keyword)){
            searchByLatLong()
        } else {
            const results = await provider.search({query: keyword})
            setListAddress(results)
            setShowModal(true)
        }
    }

    const pickLocation = (item) => {
        setPosition([item.y, item.x])
        setShowModal(false)
    }

    const dragHandler = (event) => {
        const dataPosition = event.target._latlng
        setPosition([dataPosition.lat, dataPosition.lng])
    }

    useEffect(() => {
        !!onChangeLocation && onChangeLocation(position)
    }, [position])

    return (
        <div className={"col space-y-4 relative"}>
            <p className={"font-semibold text-monstera"}>{item[0].label}</p>
            <div className={`row items-center pl-2 h-9 border border-placebo ${showModal ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <img src={SearchIcon} alt={'search'} className={"w-5"} />
                <input type={"text"} name={"searchLocation"}
                       placeholder={'nama lokasi, daerah atau lattitude, longitude'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                       onChange={onChangeSearch}
                       onKeyUp={onKeyupHandler}/>
                <div
                    className={"row space-x-2 justify-center items-center w-52 h-full bg-battleship-green hover:bg-monstera rounded-r-lg cursor-pointer"}
                    onClick={searchHandler}>
                    <img src={SearchIconWhite} alt="SearchIconWhite" className={"w-5"}/>
                    <p className={"font-semibold text-white"}>Temukan Lokasi</p>
                </div>
            </div>
            <div className={`${showModal ? 'block' : 'hidden'} absolute top-12 z-10`}>
                <div
                    className={"col bg-white border-x border-b border-placebo mb-5 max-h-32 overflow-scroll"}>
                    {listAddress?.length > 0 ? listAddress?.map((l, index) => (
                        <div key={index}
                             className={"row p-2 cursor-pointer hover:bg-apple-flower hover:text-monstera hover:font-Montserrat-Bold"}
                             onClick={() => pickLocation(l)}>
                            <p>{l.label}</p>
                        </div>
                    )) : <p className={"p-3 text-center"}>Not Found</p>}
                </div>
            </div>
            <div className={"z-0"}>
                <MapContainer center={position} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} draggable={true} eventHandlers={{dragend: (e) => dragHandler(e)}}>
                        <Popup>
                            lat: {position[0]}, lng: {position[1]}
                        </Popup>
                    </Marker>
                    <RecenterAutomatically lat={position[0]} lng={position[1]}/>
                    <LocationMarker setPosition={setPosition} />
                </MapContainer>
            </div>
            {error['latLang'] && <p className={"font-semibold text-red-600 italic"}>{error['latLang']}</p>}
        </div>
    )
}

export default SearchMapComponent