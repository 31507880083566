import DateRangePickerComponent from "../../components/date-range-picker.component";
import ExportComponent from "../../components/export.component";
import {
    setToolsCurrentPage,
    setToolsInitialState,
    setToolsModalVisibility,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {dateRangeToday, downloadFileExport, formatDateToReadable} from "../../utils/general-variable";
import TabVoucherAndComparation from "./tab-voucher-and-comparation";
import TabVoucherOrdersGraph from "./tab-voucher-orders-graph";
import TabOrdersVoucherList from "./tab-orders-voucher-list";
import moment from "moment/moment";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";

const VoucherAnalyticsPage = () => {
    const dispatch = useDispatch()
    const {dateRange, searchKey} = useSelector(state => state.toolsReducer)
    const {fetching: exportVoucherAnalytic, isLoading: loadingExport} = useFetch(API.EXPORT_VOUCHER_ANALYTIC)

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const exportDataHandler = () => {
        const requestBody = {
            start_date: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
            end_date: formatDateToReadable(dateRange[1], "YYYY-MM-DD"),
            search: searchKey
        }
        exportVoucherAnalytic(requestBody, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Voucher-Analytic-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    useEffect(() => {
        dispatch(setToolsStartDateEndDate(dateRangeToday))
        return () => {
            dispatch(setToolsInitialState())
        }
    }, [])

    return (
        <div className={"col space-y-4 px-10 my-4"}>
            <div className={"row items-center justify-center space-x-4"}>
                <DateRangePickerComponent slug={'analytics'} onChange={getValueFromRangePickerSingle}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data analytics voucher ?`}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <TabVoucherAndComparation/>
            <TabVoucherOrdersGraph/>
            <TabOrdersVoucherList/>
        </div>
    )
}

export default VoucherAnalyticsPage
