import {Link} from "react-router-dom";
import React from "react";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import TableComponent from "../../components/table-component";

export const LevelList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Level',
            classNameHeader: 'pl-4 text-center',
            dataIndex: 'name',
            render: (name) => (
                <Link to={`/loyalty-program/level-and-benefit/create-level-and-benefit`}>
                    <p className={"text-dazzle capitalize hover:font-Montserrat-Bold hover:underline p-4"}>{name}</p>
                </Link>
            )
        }, {
            name: 'Nilai Order',
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row justify-center"}>
                    <p>{doConvertNumberToRupiahFormat(record.min_total_order_amount)}</p> -
                    <p>{doConvertNumberToRupiahFormat(record.max_total_order_amount)}</p>
                </div>
            )
        }, {
            name: 'Membership Disc.',
            classNameHeader: 'text-center',
            dataIndex: 'discount_amount',
            render: (discount_amount) => (
                <p className={"text-forged-steel text-center"}>{discount_amount || 0} %</p>
            )
        }, {
            name: 'Max. Redeem',
            classNameHeader: 'text-center',
            dataIndex: 'max_redeem_amount',
            render: (max_redeem_amount) => (
                <p className={"text-forged-steel text-center"}>{max_redeem_amount || 0} Point</p>
            )
        }, {
            name: 'Total User',
            classNameHeader: 'text-center',
            dataIndex: 'total_users',
            render: (total_users) => (
                <p className={"text-forged-steel text-center"}>{total_users}</p>
            )
        }
    ]

    return (
        <div className={"pt-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={false}
                loading={isLoading}
            />
        </div>
    )
}
