export const personalizeModel = {
    show_personalize: true,
    item_list: [{
        id: 'personalize',
        title: null,
        select_product: [],
        delete_product: []
    }],
    delete_ids: []
}
