import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_DESCRIPTION} from "../../../utils/input-field-list-static-page";
import {setWaste4ChangeDescriptionDetail, setWaste4ChangeErrorMessage} from "../../../reducers/waste-4-change.reducer";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import React, {useEffect} from "react";
import Section from "./section";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Description = () => {
    const dispatch = useDispatch()
    const {descriptionDetail} = useSelector(state => state.waste4ChangeReducer)
    const {fetching: getDescription} = useFetch(API.W4C_GET_DESCRIPTION)
    const {fetching: updateDescription, isLoading} = useFetch(API.W4C_UPDATE_DESCRIPTION)
    const itemList = [...descriptionDetail?.descriptions]
    let fieldList = FIELD_LIST_DESCRIPTION
    let showDescription = {
        label: 'Description',
        key: 'show_description',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setWaste4ChangeDescriptionDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'description').toString(),
            title: null,
            url: null,
            image_url: null,
            description: null
        })
        dispatch(setWaste4ChangeDescriptionDetail({descriptions: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(descriptionDetail.descriptions)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataSlider = {...descriptionDetail, descriptions: dataItemList}
        updateDescription(dataSlider).then(response => {
            dispatch(setWaste4ChangeErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Description Updated'))
            dispatch(setWaste4ChangeDescriptionDetail({...response?.data, delete_ids: []}))
        }).catch(err => {
            dispatch(setWaste4ChangeErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getDescription().then(response => {
            if (response?.data?.descriptions?.length > 0) {
                dispatch(setWaste4ChangeDescriptionDetail({...response?.data, delete_ids: []}))
            } else {
                const data = response?.data
                delete data?.descriptions
                dispatch(setWaste4ChangeDescriptionDetail({...data, delete_ids: []}))
            }
        })

        return () => {
            dispatch(setWaste4ChangeErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={descriptionDetail}
            title={'Section'}
            switchToggleField={showDescription}
            itemList={itemList}
            content={(index) => <Section fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setWaste4ChangeDescriptionDetail}
            deleteList={descriptionDetail?.delete_ids}
            objectName={'descriptions'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Description
