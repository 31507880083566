import {FIELD_LIST_SECTION_ABOUT_US} from "../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setAboutUsSection1Detail} from "../../reducers/about-us.reducer";
import React from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Section1 = () => {
    const dispatch = useDispatch()
    const {section1Detail, aboutUsErrorMessage} = useSelector(state => state.aboutUsReducer)
    let fieldList = FIELD_LIST_SECTION_ABOUT_US
    let showSection1 = {
        label: 'Section 1',
        key: 'show_section_1',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setAboutUsSection1Detail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(section1Detail)
        // submit form
    }

    return (
        <FormGeneralComponent
            data={section1Detail}
            switchToggleField={showSection1}
            fieldList={fieldList}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            itemInputClassName={{
                key: 'description',
                value: ['col-span-2', '']
            }}
            errorMessage={aboutUsErrorMessage}
        />
    )
}

export default Section1