import {createSlice} from '@reduxjs/toolkit'
import {
    bannerModel,
    flashSaleModel,
    posterModel,
    productRecommendationModel,
    promotedProductModel
} from "../models/beauty-friday.model";

export const beautyFridaySlice = createSlice({
    name: 'beautyFriday',
    initialState: {
        bannerDetail: {...bannerModel},
        posterDetail: {...posterModel},
        promotedProductDetail: {...promotedProductModel},
        productRecommendationDetail: {...productRecommendationModel},
        flashSaleDetail: {...flashSaleModel},
        beautyFridayErrorMessage: {},
    },
    reducers: {
        setBeautyFridayBannerDetail: (state, action) => {
            state.bannerDetail = {...state.bannerDetail, ...action.payload}
        },
        setBeautyFridayPosterDetail: (state, action) => {
            state.posterDetail = {...state.posterDetail, ...action.payload}
        },
        setBeautyFridayPromotedProductDetail: (state, action) => {
            state.promotedProductDetail = {...state.promotedProductDetail, ...action.payload}
        },
        setBeautyFridayProductRecommendationDetail: (state, action) => {
            state.productRecommendationDetail = {...state.productRecommendationDetail, ...action.payload}
        },
        setBeautyFridayFlashSaleDetail: (state, action) => {
            state.flashSaleDetail = {...state.flashSaleDetail, ...action.payload}
        },
        setBeautyFridayErrorMessage: (state, action) => {
            state.beautyFridayErrorMessage = action.payload
        }
    },
})

export const {
    setBeautyFridayBannerDetail,
    setBeautyFridayPosterDetail,
    setBeautyFridayPromotedProductDetail,
    setBeautyFridayProductRecommendationDetail,
    setBeautyFridayFlashSaleDetail,
    setBeautyFridayErrorMessage
} = beautyFridaySlice.actions
const beautyFridayReducer = beautyFridaySlice.reducer

export default beautyFridayReducer