import React from "react";
import TotalDataComponent from "../../../components/total-data.component";
import {convertDateToCustomFormat, getStarRatingImage} from "../../../utils/general-variable";
import TableComponent from "../../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {setReviewData} from "../../../reducers/review.reducer";
import {ChevronDownIcon} from "../../../utils/image";
import {setCommentModalVisibility} from "../../../reducers/comment.reducer";
import {setUserReviewSelectedRowKeys} from "../../../reducers/user.reducer";

export const ReviewList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysUserReview} = useSelector(state => state.userReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'items-center',
        },
        {
            name: 'Produk',
            dataIndex: 'name',
            width: 350,
            classNameHeader: 'pl-5',
            render: (name, record) => (
                <div className={"row items-center pl-3 space-x-10 text-forged-steel"}>
                    <img src={record?.image?.url} alt={record?.image?.alt_text} className={"w-10"}/>
                    <p>{name}</p>
                </div>
            )
        }, {
            name: 'Rating',
            dataIndex: 'rate',
            classNameHeader: 'text-center',
            className: 'text-forged-steel text-center',
            render: (rate) => (
                <div className={"row space-x-1 justify-center"}>
                    {getStarRatingImage(rate).map((starImage, index) => (
                        <img key={index} src={starImage}
                             alt="starRating" className={"w-3"}/>
                    ))}
                </div>
            )
        }, {
            name: 'Review',
            width: 400,
            classNameHeader: 'text-center',
            render: (record) => (
                <div className={"row items-center justify-center space-x-3 py-4 text-forged-steel"}>
                    <p className={"line-clamp-3"}>{record.review}</p>
                    <img src={ChevronDownIcon} alt="buttonCommentDetail"
                         className={"w-5 cursor-pointer"} onClick={() => showCommentDetail(record)}/>
                </div>
            )
        }, {
            name: 'Tanggal Review',
            classNameHeader: 'text-center',
            dataIndex: 'created_at',
            render: (created_at) => (
                <p className={"text-forged-steel text-center"}>{convertDateToCustomFormat(created_at, "DD/MM/YYYY hh:mm")}</p>
            )
        }
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setUserReviewSelectedRowKeys(array))
    }

    const showCommentDetail = (data) => {
        dispatch(setReviewData(data))
        dispatch(setCommentModalVisibility({commentDetail: true}))
    }

    return (
        <>
            <TotalDataComponent subject={'review'}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                selectedRowKeys={selectedRowKeysUserReview}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                pagination={true}
                loading={isLoading}
            />
        </>
    )
}
