import {createSlice} from '@reduxjs/toolkit'

export const commentSlice = createSlice({
    name: 'comment',
    initialState: {
        selectedRowKeysComment: [],
        modalVisibility: {
            commentDetail: false,
        }
    },
    reducers: {
        setCommentSelectedRowKeys: (state, action) => {
            state.selectedRowKeysComment = action.payload
        },
        setCommentModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        }
    },
})

export const {
    setCommentSelectedRowKeys,
    setCommentModalVisibility
} = commentSlice.actions
const commentReducer = commentSlice.reducer

export default commentReducer