import React, {useState} from "react";
import {
    ArrowDownGreen,
    ArrowDownWhite,
    ArrowUpGreen,
    ArrowUpWhite,
    ChevronDownWhiteIcon,
    ChevronUpWhiteIcon,
    TrashIconWhite
} from "../utils/image";
import {useDispatch} from "react-redux";
import {setToolsAlertMessage, setToolsModalVisibility} from "../reducers/tools.reducer";
import DeleteConfirmationComponent from "./delete-confirmation.component";

const TabToggleListComponent = (props) => {
    const {title, content, index, dataList, setDataList, deleteList = [], objectName = 'item_list', objectDeleteName = 'delete_ids'} = props
    const dispatch = useDispatch()
    const [show, setShow] = useState(true)
    const [indexItemOnDelete, setIndexItemOnDelete] = useState(null)
    const dataListCopy = [...dataList]
    const getContent = dataListCopy[index]

    const checkNewPosition = (type, index) => {
        const newPosition = type === 'up' ? index - 1 : index + 1
        if(type === 'up') {
            return newPosition < 0 ? 0 : newPosition
        } else {
            return newPosition === dataList.length ? index : newPosition
        }
    }

    const openDeleteModal = () => {
        setIndexItemOnDelete(index)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus item yang anda pilih?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const onChangePosition = (index, type) => {
        dataListCopy[index] = dataListCopy[checkNewPosition(type, index)]
        dataListCopy[checkNewPosition(type, index)] = getContent
        dispatch(setDataList({[objectName]: dataListCopy}))
    }

    const onDeleteItem = () => {
        const updateItem = dataListCopy.filter((_,i) => i !== indexItemOnDelete)
        const deleteIds = Number(getContent.id) ? [...deleteList, getContent.id] : deleteList
        dispatch(setDataList({[objectName]: updateItem, [objectDeleteName]: deleteIds}))
    }

    const showHideContent = () => {
        setShow(!show)
    }

    return (
        <div>
            <div
                className={"col divide-y divide-fennel-stem font-regular text-monstera border border-fennel-stem rounded-lg"}>
                <div className={"row items-center justify-between p-4 bg-doctor rounded-lg"}>
                    <div className={"row space-x-3 items-center"}>
                        <p className={"text-sm font-Montserrat-Bold capitalize"}>{title}</p>
                    </div>
                    <div className={"row items-center space-x-4"}>
                        <div className={`row items-center space-x-4 ${dataList.length > 1 ? 'flex' : 'hidden'}`}>
                            <div className={"group"}>
                                <img src={ArrowUpGreen} alt="ArrowUpGreen"
                                     className={"bg-white border border-placebo rounded-lg cursor-pointer block group-hover:hidden"}
                                     onClick={() => onChangePosition(index, 'up')}/>
                                <img src={ArrowUpWhite} alt="ArrowUpGreen"
                                     className={"bg-monstera border border-placebo rounded-lg cursor-pointer hidden group-hover:block"}
                                     onClick={() => onChangePosition(index, 'up')}/>
                            </div>
                            <div className={"group"}>
                                <img src={ArrowDownGreen} alt="ArrowDownGreen"
                                     className={"bg-white border border-placebo rounded-lg cursor-pointer block group-hover:hidden"}
                                     onClick={() => onChangePosition(index, 'down')}/>
                                <img src={ArrowDownWhite} alt="ArrowDownGreen"
                                     className={"bg-monstera border border-placebo rounded-lg cursor-pointer hidden group-hover:block"}
                                     onClick={() => onChangePosition(index, 'down')}/>
                            </div>
                        </div>
                        <img src={show ? ChevronUpWhiteIcon : ChevronDownWhiteIcon} alt="arrowUp"
                             className={"bg-battleship-green hover:bg-monstera rounded-full cursor-pointer"} onClick={showHideContent}/>
                    </div>
                </div>
                <div className={`p-4 ${show ? 'block' : 'hidden'}`}>
                    {content}
                </div>
                <div className={show ? 'block' : 'hidden'}>
                    <div className={"row justify-end p-3 text-xs"}>
                        <div
                            className={"row items-center space-x-2 font-semibold text-white w-fit cursor-pointer p-2 bg-spiced-coral hover:bg-glowing-scarlet border border-placebo rounded-lg"}
                            onClick={() => openDeleteModal(index)}>
                            <img src={TrashIconWhite} alt="TrashIcon" className={"w-5"}/>
                            <p>Delete</p>
                        </div>
                    </div>
                </div>
            </div>
            {indexItemOnDelete === index && <DeleteConfirmationComponent onDelete={onDeleteItem}/>}
        </div>
    )
}

export default TabToggleListComponent
