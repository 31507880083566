import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditorComponent = (props) => {
    const { item, onChangeTextEditor, defaultValue } = props
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ],
    }
    const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link']

    const onChangeHandler = (value) => {
        const regex = /(<([^>]+)>)/ig
        const hasValue = !!value.replace(regex, "").length ? value : null
        onChangeTextEditor(item.key, hasValue)
    }

    return (
        <div className="text-editor">
            <ReactQuill
                value={defaultValue}
                theme="snow"
                placeholder={item?.placeholder}
                onChange={onChangeHandler}
                modules={modules}
                formats={formats}
                className={"text-forged-steel"}
            />
        </div>
    )
}

export default TextEditorComponent
