import {DownloadIcon, DragAndDropIcon, LoadingSvg, UploadIcon, XCircleIcon} from "../../utils/image";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setProductActiveTab, setProductFileName, setProductImportProductDetail} from "../../reducers/product.reducer";
import {STEP_IMPORT_PRODUCT} from "../../utils/constant";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import AlertConfirmationComponent from "../../components/alert-confirmation.component";
import {downloadFileExport} from "../../utils/general-variable";

const UploadXlsxFile = () => {
    const dispatch = useDispatch()
    const {fileName} = useSelector(state => state.productReducer)
    const {is_update} = useSelector(state => state.productReducer.importProductDetail)
    const {fetching: exportTemplate, isLoading} = useFetch(API.EXPORT_TEMPLATE)
    const [isDrag, setIsDrag] = useState(false)
    const fileUploaded = useRef()

    const uploadFileHandler = (event) => {
        const validExtension = [".xlsx"]
        const fileSource = event.dataTransfer?.files[0] || event.target.files[0]
        const fileName = fileSource?.name
        const fileSize = fileSource?.size
        const fileExtension = fileName?.substring(fileName.lastIndexOf('.')).toLowerCase()
        if(validExtension.indexOf(fileExtension) < 0 && !!fileExtension){
            showAlertModal(`Invalid file selected, valid files are of ${validExtension.toString()} types`)
        } else if(fileSize > 64e6) {
            showAlertModal('Invalid file size, valid size are not greater than 64 mb')
        } else {
            const reader = new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2){
                    dispatch(setProductFileName(fileName))
                    dispatch(setProductImportProductDetail({file: fileSource}))
                }
            }
            if (fileSource) {
                reader.readAsDataURL(fileSource)
            }
        }

        setIsDrag(false)
    }

    const dragAndDropHandler = (event, key) => {
        event.preventDefault()

        switch (key) {
            case 'drop':
                uploadFileHandler(event)
                break;
            case 'dragOver':
                setIsDrag(true)
                break
            case 'dragLeave':
                setIsDrag(false)
                break
        }
    }

    const removeImageHandler = () => {
        dispatch(setProductFileName(''))
        dispatch(setProductImportProductDetail({file: {}}))
        fileUploaded.current.value = null
    }

    const setChecked = (value) => {
        dispatch(setProductImportProductDetail({is_update: value}))
    }

    const downloadFormatHandler = () => {
        exportTemplate({}, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Template-Import-Product-${Date.now()}.xlsx`)
        })
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        if(!!fileName){
            openConfirmationModal()
        } else {
            showAlertModal('File imported is empty')
        }
    }

    const doNextStep = () => {
        dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[1]))
        dispatch(setToolsModalVisibility({confirmation: false}))
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('warning'))
        dispatch(setToolsAlertMessage(message))
    }

    const openConfirmationModal = () => {
        dispatch(setToolsAlertMessage('Apakah anda yakin ingin melakukan import product?'))
        dispatch(setToolsModalVisibility({confirmation: true}))
    }

    return (
        <>
            <form onSubmit={onSubmitHandler} className={"col space-y-8 py-6 border border-whisper-green rounded-lg"}>
                <div className={"row justify-between px-6"}>
                    <div className={"col space-y-4"}>
                        <p className={"text-lg text-monstera"}>Import Product Form XLSX File</p>
                        <p>This tools allows you to import (or merge) Product data to Product list from a XLSX file</p>
                    </div>
                    <div>
                        <button type={"button"} onClick={downloadFormatHandler}
                                className={"font-Montserrat-Bold text-white bg-battleship-green hover:bg-monstera px-8 py-2 rounded-lg"}>
                            {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> :
                                <div className={"row items-center space-x-2"}>
                                    <img src={DownloadIcon} className={"w-5 mx-auto"}/>
                                    <p>Download Template</p>
                                </div>
                            }
                        </button>
                    </div>
                </div>
                <div className={"row flex-wrap font-semibold text-monstera border-t border-whisper-green bg-doctor"}>
                    <div className={"w-1/2 border-b border-r border-whisper-green p-6"}>
                        <div className={"col space-y-4 relative"}>
                            <p className={"font-Montserrat-Bold"}>Choose XLSX File</p>
                            <div className={"row space-x-6 items-center"}>
                                <label htmlFor={"xlsx_file"}
                                       className={"font-semibold bg-monstera py-1.5 px-5 rounded-lg text-white cursor-pointer w-fit"}>
                                    <div className={"row items-center space-x-2"}>
                                        <img src={UploadIcon} alt="uploadIcon" className={"w-5"}/>
                                        <p>Browse</p>
                                    </div>
                                </label>
                                <input name={"xlsx_file"} id={"xlsx_file"} type={"file"} accept={".xlsx"}
                                       className={'absolute top-5 invisible'} ref={fileUploaded}
                                       onChange={uploadFileHandler}/>
                                <p className={"text-forged-steel"}>Maximum Size 64 MB</p>
                            </div>
                        </div>
                    </div>
                    <div className={"w-1/2 border-b border-whisper-green px-8 py-6"}
                         onDrop={(event) => dragAndDropHandler(event, 'drop')}
                         onDragOver={(event) => dragAndDropHandler(event, 'dragOver')}
                         onDragLeave={(event) => dragAndDropHandler(event, 'dragLeave')}>
                        <div
                            className={"row justify-center items-center space-x-4 border-dashed border border-fennel-stem rounded-lg py-4"}>
                            <img src={DragAndDropIcon} alt="DragAndDropIcon" className={isDrag ? "animate-bounce" : null}/>
                            <div className={"col space-y-2"}>
                                <p>Drag File Here</p>
                                <p className={"font-regular italic"}>XLSX File Format</p>
                            </div>
                        </div>
                    </div>
                    <div className={`w-full row items-center space-x-12 p-6 ${fileName ? 'flex' : 'hidden'}`}>
                        <p>File Imported :</p>
                        <div className={"row items-center space-x-4 py-2 px-4 border border-liberated-lime bg-white rounded-lg"}>
                            <p className={"text-forged-stee w-40 truncate"}>{fileName}</p>
                            <img src={XCircleIcon} alt="deleteSource" className={"w-5 cursor-pointer"} onClick={removeImageHandler}/>
                        </div>
                    </div>
                </div>
                <div className={"row items-center justify-between px-6 pb-16 border-b border-whisper-green"}>
                    <div className={"w-1/2 text-monstera font-Montserrat-Bold"}>
                        <p>Update Existing Products</p>
                    </div>
                    <div className={"w-1/2 row items-center space-x-4 px-8"}>
                        <input id={"import_checkbox"} type="checkbox" className={"accent-monstera"} name={'import_checkbox'}
                               checked={is_update} onChange={(e) => setChecked(e.target.checked)}/>
                        <label htmlFor={'import_checkbox'} className={"text-forged-steel leading-relaxed"}>
                            Existing products thet match by ID or SKU will be Updated. Products that do not exist
                            will be skipped.
                        </label>
                    </div>
                </div>
                <div className={"row justify-center cursor-pointer"}>
                    <button type={"submit"} className={"font-Montserrat-Bold text-white bg-battleship-green hover:bg-monstera px-20 py-4 rounded-lg"}>
                        <p>Next</p>
                    </button>
                </div>
            </form>
            <AlertConfirmationComponent onApply={doNextStep}/>
        </>
    )
}

export default UploadXlsxFile
