const StepperComponent = ({stepList, activeTab}) => {
    return (
        <div className={"col items-center space-y-4 max-w-full"}>
            <div className={"row justify-center"}>
                {stepList.map((step, index) => (
                    <div key={index} className={"col items-center space-y-4"}>
                        <p className={`px-20 text-monstera ${activeTab.key === step.key ? 'font-Montserrat-Bold' : 'font-regular'}`}>{step.label}</p>
                        <div className={`row items-center w-full ${stepList.length - 1 !== index ? 'ml-60' : 'ml-48'}`}>
                            <div className={`w-fit border-2 p-1 rounded-full
                            ${activeTab.key === step.key ? 'border-monstera bg-white' : 'border-white bg-white'}`}>
                                <div className={`p-[6px] rounded-full
                                ${activeTab.key === step.key || activeTab.id > index ? 'bg-monstera' : 'bg-fortress-grey'}`}></div>
                            </div>
                            {stepList.length - 1 !== index && (
                                <div className={`w-full h-[2px] 
                                ${activeTab.key === step.key || activeTab.id > index ? 'bg-monstera' : 'bg-fortress-grey'}`}></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StepperComponent