import {FIELD_LIST_MAINTENANCE_PAGE} from "../../utils/input-field-list";
import InputComponent from "../../components/input.component";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setMaintenanceErrorMessage, setMaintenancePageDetail} from "../../reducers/maintenance-page.reducer";
import {cloneDeep} from "lodash";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import {LoadingSvg} from "../../utils/image";

const MaintenancePage = () => {
    const dispatch = useDispatch()
    const {maintenancePageDetail, maintenancePageErrorMessage} = useSelector(state => state.maintenancePageReducer)
    const {fetching: getDetailMaintenancePage} = useFetch(API.DETAIL_MAINTENANCE_PAGE)
    const {fetching: updateDetailMaintenancePage, isLoading} = useFetch(API.UPDATE_MAINTENANCE_PAGE)
    let fieldList = FIELD_LIST_MAINTENANCE_PAGE

    const onChange = (key, value) => {
        dispatch(setMaintenancePageDetail({[key]: value}))
    }

    const onChangePlatforms = (index, value) => {
        const copyMaintenancePageDetail = cloneDeep(maintenancePageDetail?.platforms)
        copyMaintenancePageDetail[index].value = value
        dispatch(setMaintenancePageDetail({platforms: copyMaintenancePageDetail}))
    }

    const dataChecking = (field) => {
        dispatch(setMaintenanceErrorMessage({}))
        const maintenancePageDetailCopy = cloneDeep(maintenancePageDetail)
        let error = {}
        field.forEach((item) => {
            switch (item.key) {
                case 'image_url':
                case 'title':
                case 'description':
                    if (!maintenancePageDetailCopy[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setMaintenanceErrorMessage({}))
            return {isValid: true, maintenancePage: maintenancePageDetailCopy}
        } else {
            dispatch(setMaintenanceErrorMessage(error))
            return {isValid: false, maintenancePage: maintenancePageDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const {isValid, maintenancePage} = dataChecking([fieldList[0], ...fieldList[1]])
        if (isValid) {
            updateDetailMaintenancePage(maintenancePage).then(response => {
                dispatch(setMaintenanceErrorMessage({}))
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Maintenance Page Updated'))
                dispatch(setMaintenancePageDetail(response?.data))
            }).catch(err => {
                dispatch(setMaintenanceErrorMessage(convertErrorMessage(err)))
            })
        }
    }

    useEffect(() => {
        getDetailMaintenancePage().then(response => {
            dispatch(setMaintenancePageDetail(response?.data))
        })

        return () => {
            dispatch(setMaintenanceErrorMessage({}))
        }
    }, [])

    return (
        <div className={"col px-10"}>
            <form className={"space-y-4"}>
                <div className={"row space-x-5"}>
                    <div className={"col space-y-4 w-1/4"}>
                        <InputComponent item={fieldList[0]} data={maintenancePageDetail[fieldList[0].key]}
                                        onChange={onChange} error={maintenancePageErrorMessage}/>
                    </div>
                    <div className={"col space-y-4 w-3/4"}>
                        {fieldList[1].map((item, index) => (
                            <InputComponent key={index} item={item} data={maintenancePageDetail[item.key]}
                                            onChange={onChange} onChangeInput={onChange}
                                            error={maintenancePageErrorMessage}/>
                        ))}
                        <div className={"col space-y-4"}>
                            <p className={"font-semibold text-monstera"}>Platform List</p>
                            {maintenancePageDetail?.platforms?.map((item, index) => (
                                <div key={index} className={"row space-x-4"}>
                                    <input type={'checkbox'} className={"accent-monstera"}
                                           checked={item?.value}
                                           onChange={(e) => onChangePlatforms(index, e.target.checked)}/>
                                    <p className={"capitalize"}>{item?.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"row font-semibold justify-center py-8"}>
                    <button onClick={(e) => onSubmitHandler(e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        {isLoading ? <img src={LoadingSvg} className={"w-5 mx-auto"}/> : 'Save All Changes'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MaintenancePage
