import {FIELD_LIST_BENEFIT_MIRACULOUS_RETINOL_SERIES} from "../../../utils/input-field-list-static-page";
import {useDispatch, useSelector} from "react-redux";
import {setMiraculousRetinolSeriesBenefitDetail} from "../../../reducers/miraculous-retinol-series.reducer";
import {randomCode} from "../../../utils/general-variable";
import ItemBenefit from "./item-benefit";
import FormItemComponent from "../../../components/static-page/form-item.component";
import React from "react";

const Benefit = () => {
    const dispatch = useDispatch()
    const {benefitDetail} = useSelector(state => state.miraculousRetinolSeriesReducer)
    const itemList = [...benefitDetail.item_list]
    let fieldList = FIELD_LIST_BENEFIT_MIRACULOUS_RETINOL_SERIES
    let showBenefit = {
        label: 'Benefit',
        key: 'show_benefit',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMiraculousRetinolSeriesBenefitDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'benefit').toString(),
            thumbnail: null,
            title: null,
            description: null
        })
        dispatch(setMiraculousRetinolSeriesBenefitDetail({item_list: itemList}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(benefitDetail)
        // submit form
    }

    return (
        <FormItemComponent
            data={benefitDetail}
            switchToggleField={showBenefit}
            itemList={itemList}
            content={(index) => <ItemBenefit fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMiraculousRetinolSeriesBenefitDetail}
            deleteList={benefitDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={false}
        />
    )
}

export default Benefit
