import InputComponent from "../../components/input.component";
import React from "react";
import {useSelector} from "react-redux";

const VoucherAutoApplyForm = ({fieldList}) => {
    const {voucherErrorMessage} = useSelector(state => state.voucherReducer)
    return (
        <>
            {fieldList.map((item, index) => (
                <InputComponent key={index} item={item} error={voucherErrorMessage}/>
            ))}
        </>
    )
}

export default VoucherAutoApplyForm