import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../../components/input.component";
import React from "react";
import {setBeautyFridayProductRecommendationDetail} from "../../../reducers/beauty-friday.reducer";

const InputField = ({fieldList}) => {
    const dispatch = useDispatch()
    const {productRecommendationDetail, beautyFridayErrorMessage} = useSelector(state => state.beautyFridayReducer)

    const onChangeInput = (key, value, id) => {
        if(key === 'select_product'){
            let deleteList = productRecommendationDetail?.delete_product || []
            dispatch(setBeautyFridayProductRecommendationDetail({[key]: value, delete_product: [...deleteList, id]}))
        } else {
            dispatch(setBeautyFridayProductRecommendationDetail({[key]: value}))
        }
    }

    const onChangeProduct = (key, value) => {
        let deleteList = productRecommendationDetail?.delete_product?.filter(item => item !== value?.id)
        let currentProduct = productRecommendationDetail.select_product
        dispatch(setBeautyFridayProductRecommendationDetail({[key]: currentProduct.concat(value), delete_product: deleteList || []}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            <InputComponent item={fieldList[0]} data={productRecommendationDetail[fieldList[0].key]} onChangeInput={onChangeInput} error={beautyFridayErrorMessage}/>
            {fieldList.slice(1).map((item, index) => (
                <div key={index} className={"col-span-2"}>
                    <InputComponent item={item} data={productRecommendationDetail[item.key]} onChange={onChangeInput}
                                    onChangeProduct={onChangeProduct} error={beautyFridayErrorMessage}/>
                </div>
            ))}
        </div>
    )
}

export default InputField