import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsAlertMessage, setToolsAlertType,
    setToolsModalVisibility, setToolsReload,
} from "../../reducers/tools.reducer";
import {SORT_LIST_ORDER} from "../../utils/sort-list";
import {STATE_LIST_ORDER} from "../../utils/state-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setOrderSelectedRowKeys} from "../../reducers/order.reducer";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";
import AdvanceFilter from "./advance-filter";
import React from "react";

const OrderFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysOrder, advanceFilter} = useSelector(state => state.orderReducer)
    const {reload, searchKey} = useSelector(state => state.toolsReducer)
    const {fetching: postUpdateStatus, isLoading: loadingUpdateState} = useFetch(API.ORDER_UPDATE_STATUS)
    const {fetching: exportOrderList, isLoading: loadingExport} = useFetch(API.EXPORT_ORDER_LIST)

    const applyStatusHandler = (listId, status) => {
        const body = {
            order_ids: listId,
            status
        }
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Order State Updated'))
            dispatch(setToolsReload(!reload))
        })
        dispatch(setOrderSelectedRowKeys([]))
    }

    const exportDataHandler = () => {
        const body = {
            ...advanceFilter,
            affiliatorId: advanceFilter?.affiliatorId.map(i => i.id),
            order_ids: selectedRowKeysOrder
        }
        const queryParams = {
            search: searchKey
        }
        exportOrderList(body, '', {}, 'blob', queryParams).then(res => {
            downloadFileExport(res, `Order-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent/>
                <div className={"row"}>
                    <div className={"mr-3"}>
                        <SortComponent sortList={SORT_LIST_ORDER}/>
                    </div>
                    <AdvanceFilter/>
                    <div className={"row space-x-3 ml-3"}>
                        <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                        <div>
                            <ExportComponent
                                wordingConfirmation={`Apakah anda yakin ingin melakukan export data order ?`}
                                countSelected={selectedRowKeysOrder.length}
                                exportDataHandler={exportDataHandler}
                                isLoading={loadingExport}/>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeStateComponent
                wordingConfirmation={"Apakah anda ingin mengubah status order yang anda pilih ?"}
                selectedRow={selectedRowKeysOrder}
                stateList={STATE_LIST_ORDER}
                isLoading={loadingUpdateState}
                applyStatusHandler={applyStatusHandler}/>
        </div>
    )
}

export default OrderFilter
