import React, {useEffect} from "react";
import CreateVoucher from "../create-voucher";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setVoucherDetail, setVoucherType} from "../../reducers/voucher.reducer";
import {voucherModel} from "../../models/voucher.model";

const UpdateVoucherPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {fetching: getVoucherDetail} = useFetch(API.VOUCHER_DETAIL, {}, {isFetching: false})

    useEffect(() => {
        getVoucherDetail({}, id)
            .then(response => {
                dispatch(setVoucherType(response?.data?.type))
                dispatch(setVoucherDetail(response?.data))
            })

        return () => {
            dispatch(setVoucherDetail({...voucherModel}))
            dispatch(setVoucherType(''))
        }
    }, [])

    return (
        <CreateVoucher/>
    )
}

export default UpdateVoucherPage
