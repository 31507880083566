import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {convertDateToCustomFormat, statusRenderClassName} from "../../utils/general-variable";
import {UserProfileDefault} from "../../utils/image";
import ChangeStateComponent from "../../components/change-state.component";
import {STATE_LIST_BEAUTY_EMPTIES} from "../../utils/state-list";

const BeautyEmptiesDetailHeader = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {beautyEmptiesDetail} = useSelector(state => state.beautyEmptiesReducer)
    const {reload} = useSelector(state => state.toolsReducer)
    const {fetching: postUpdateStatus, isLoading} = useFetch(API.BEAUTY_EMPTIES_UPDATE_STATUS)

    const applyStatusHandler = (_, status) => {
        const body = {
            beauty_empties_ids: [id],
            status
        }
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Beauty Empties State Updated'))
            dispatch(setToolsReload(!reload))
        })
    }

    return (
        <div className={"col divide-y divide-liberated-lime font-regular bg-doctor rounded-lg"}>
            <div className={"row items-center justify-between px-5 py-3"}>
                <div className={"row items-center"}>
                    <p className={`text-center font-Montserrat-Bold capitalize rounded-md py-1 px-8 ${statusRenderClassName(beautyEmptiesDetail.status).className}`}>{statusRenderClassName(beautyEmptiesDetail.status).text}</p>
                </div>
                <p className={"text-forged-steel"}>{convertDateToCustomFormat(beautyEmptiesDetail.created_at, 'YYYY/MM/DD HH:mm')}</p>
            </div>
            <div className={"row items-center justify-between px-5 py-3"}>
                <div className={"row items-center space-x-4"}>
                    <img src={beautyEmptiesDetail?.user?.image_url || UserProfileDefault} alt="imageUser" className={"w-14 h-14 rounded-full"}/>
                    <div className={"col space-y-2 text-forged-steel"}>
                        <p className={"font-semibold text-eerie-black capitalize"}>{beautyEmptiesDetail?.user?.fullname}</p>
                        <p>{beautyEmptiesDetail?.user?.email}</p>
                    </div>
                </div>
                <div className={"col items-center space-y-2 text-forged-steel"}>
                    <p className={"font-semibold text-eerie-black"}>Kode Pengembalian</p>
                    <p className={"font-semibold text-monstera"}>{beautyEmptiesDetail.code}</p>
                </div>
                <div className={"col space-y-2 font-semibold text-eerie-black"}>
                    <p>Change Status</p>
                    <ChangeStateComponent
                        wordingConfirmation={"Apakah anda ingin mengubah status pengembalian ?"}
                        stateList={STATE_LIST_BEAUTY_EMPTIES}
                        isLoading={isLoading}
                        applyStatusHandler={applyStatusHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default BeautyEmptiesDetailHeader
