import OrderDetailHeader from "./order-detail-header";
import OrderDetailAddress from "./order-detail-address";
import ProductList from "./product-list";
import OrderDetailPayment from "./order-detail-payment";
import TrackingOrder from "./tracking-order";
import HistoryOrder from "./history-order";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React, {useEffect} from "react";
import {setOrderDetail} from "../../reducers/order.reducer";
import {LoadingSvg} from "../../utils/image";

const OrderDetailPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {reload} = useSelector(state => state.toolsReducer)
    const {fetching: getOrderDetail, isLoading} = useFetch({...API.ORDER_DETAIL, ...{initPathVariable: id}}, {}, {isLoading: true})

    useEffect(() => {
        getOrderDetail().then(response => {
            dispatch(setOrderDetail(response?.data))
        })
    },[reload])

    return (
        <div className={"col space-y-4 px-10 mb-10 mt-4"}>
            {
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                <>
                    <OrderDetailHeader/>
                    <div className={"row space-x-8"}>
                        <div className={"col w-2/3 space-y-4"}>
                            <OrderDetailAddress/>
                            <ProductList/>
                            <OrderDetailPayment/>
                        </div>
                        <div className={"col w-1/3 space-y-4"}>
                            <TrackingOrder/>
                            <HistoryOrder/>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default OrderDetailPage