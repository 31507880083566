import React, {useEffect} from "react";
import {EditIcon} from "../../utils/image";
import {RoleList} from "./role-list";
import {Link} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useSelector} from "react-redux";

const UserRole = () => {
    const {reload} = useSelector(state => state.toolsReducer)
    const {data: response, fetching: getUserRole, isLoading} = useFetch(API.GET_LIST_USER_ROLES, {})

    useEffect(() => {
        getUserRole()
    }, [reload])

    return (
        <div className={"px-10 space-y-5 mt-5"}>
            <div className={"row items-center justify-between bg-windmill-wings px-10 py-4 rounded-lg"}>
                <p className={"text-monstera text-lg font-Montserrat-Bold"}>User Role</p>
                <Link className={"row items-center space-x-3 bg-battleship-green hover:bg-monstera px-7 py-2 rounded-md"} to={"create-role"}>
                    <img src={EditIcon} className={"w-4"}/>
                    <p className={"text-white font-bold text-xs"}>Create User Role</p>
                </Link>
            </div>
            <RoleList data={response?.data} isLoading={isLoading}/>
        </div>
    )
}

export default UserRole
