import React from "react";
import TabToggleComponent from "../../components/tab-toggle.component";
import GraphAnalyticsComponent from "../../components/graph-analytics.component";
import {useSelector} from "react-redux";
import {formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const TabOrderGraph = () => {
    const {dateRange, reload} = useSelector(state => state.toolsReducer)
    const {advanceFilter} = useSelector(state => state.analyticsOrderReducer)
    const {
        data: response,
        fetching: getAnalyticOrderGraph,
        isLoading
    } = useFetch(API.ANALYTIC_ORDER_GRAPH)

    useEffectAfterMount(() => {
        const requestBody = {...{
                start_date: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                end_date: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }, ...advanceFilter}
        getAnalyticOrderGraph(requestBody)
    }, [dateRange, advanceFilter, reload])

    return (
        <TabToggleComponent
            title={"Order Graph"}
            content={
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <GraphAnalyticsComponent title={'Jumlah Order'} slug={'Orders'} dataList={response?.data ?? {}}/>
            }
        />
    )
}

export default TabOrderGraph
