import {useState} from "react";
import Banner from "./banner";
import Description from "./description";
import RedeemProduct from "./redeem-product";
import MiniBanner from "./mini-banner";
import Benefit from "./benefit";
import GiftBanner from "./gift-banner";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_WASTE_4_CHANGE_LIST} from "../../utils/sidebar-section-list";

const Waste4change = () => {
    const [activeSidebar, setActiveSidebar] = useState('banner')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        banner: <Banner/>,
        description: <Description/>,
        benefit: <Benefit/>,
        redeem_product: <RedeemProduct/>,
        mini_banner: <MiniBanner/>,
        gift_banner: <GiftBanner/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'w4c', list: SECTION_WASTE_4_CHANGE_LIST}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default Waste4change