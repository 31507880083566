import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../components/input.component";
import React from "react";
import {cloneDeep} from "lodash";
import {setCRMSettingsHeaderSlidingTextDetail} from "../../reducers/crm-settings.reducer";

const Item = ({fieldList, index}) => {
    const dispatch = useDispatch()
    const {headerSlidingTextDetail, crmSettingsErrorMessage} = useSelector(state => state.crmSettingsReducer)

    const onChangeInput = (key, value) => {
        const copyItemList = cloneDeep(headerSlidingTextDetail.texts)
        copyItemList[index][key] = value
        dispatch(setCRMSettingsHeaderSlidingTextDetail({texts: copyItemList}))
    }

    return (
        <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
            {fieldList.map((item, i) => (
                <InputComponent key={i} item={item} data={headerSlidingTextDetail?.texts?.[index]?.[item.key]} error={crmSettingsErrorMessage[`texts[${index}]`] ?? {}} onChangeInput={onChangeInput}/>
            ))}
        </div>
    )
}

export default Item
