import TooltipComponent from "./tooltip.component";
import {ChevronUpWhiteIcon, ChevronDownWhiteIcon} from "../utils/image";
import {useState} from "react";

const TabToggleComponent = (props) => {
    const {title, tooltipText, content} = props
    const [show, setShow] = useState(true)

    const showHideContent = () => {
        setShow(!show)
    }

    return (
        <div className={"col divide-y divide-fennel-stem font-regular text-monstera border border-fennel-stem rounded-lg"}>
            <div className={"row items-center justify-between p-4 bg-doctor rounded-lg"}>
                <div className={"row space-x-3 items-center"}>
                    <p className={"text-sm font-Montserrat-Bold capitalize"}>{title}</p>
                    {tooltipText && <TooltipComponent text={tooltipText}/>}
                </div>
                <div className={"row items-center space-x-4"}>
                    <img src={show ? ChevronUpWhiteIcon : ChevronDownWhiteIcon} alt="arrowUp"
                         className={"bg-battleship-green hover:bg-monstera rounded-full cursor-pointer"} onClick={showHideContent}/>
                </div>
            </div>
            <div className={`p-4 ${show ? 'block' : 'hidden'}`}>
                {content}
            </div>
        </div>
    )
}

export default TabToggleComponent
