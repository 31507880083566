export const affiliateModel = {
    name: null,
    code: null,
    campaign_link: null,
    affiliate_link: null,
    campaign: null,
    start_date: '',
    end_date: '',
    email: null,
    phone_number: null,
}