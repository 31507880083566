import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsModalVisibility} from "../../reducers/tools.reducer";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment/moment";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import {SORT_LIST_PRODUCT} from "../../utils/sort-list";
import FilterComponent from "../../components/filter.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";
import ExportComponent from "../../components/export.component";
import React from "react";

const BeautyEmptiesProductFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysBeautyEmptiesProduct} = useSelector(state => state.beautyEmptiesReducer)
    const {data: categories} = useFetch(API.CATEGORIES, {}, {isFetching: true})
    const {fetching: exportProductList, isLoading: loadingExport} = useFetch(API.EXPORT_BEAUTY_EMPTIES_PRODUCT_LIST)
    const {
        orderBy,
        sort,
        searchKey,
        filter
    } = useSelector(state => state.toolsReducer)

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_category: filter['category'] ?? [],
            filter_by_status: filter['status'] ?? [],
            search: searchKey,
            product_ids: selectedRowKeysBeautyEmptiesProduct
        }
        exportProductList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Beauty-Empties-Product-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    const filterListProduct = [
        {
            title: 'category',
            list: categories?.data || []
        }, {
            title: 'status',
            list: [
                {
                    name: 'publish',
                    id: 'publish'
                }, {
                    name: 'draft',
                    id: 'draft'
                }
            ]
        }
    ]

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={'cari product...'}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_PRODUCT}/>
                    <FilterComponent filterList={filterListProduct} labelName={'name'} keyName={'id'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data beauty empties product ?`}
                            countSelected={selectedRowKeysBeautyEmptiesProduct.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BeautyEmptiesProductFilter
