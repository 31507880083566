import {createSlice} from '@reduxjs/toolkit'
import {maintenancePageModel} from "../models/maintenance-page.model";

export const maintenancePageSlice = createSlice({
    name: 'maintenancePage',
    initialState: {
        maintenancePageDetail: {...maintenancePageModel},
        maintenancePageErrorMessage: {},
    },
    reducers: {
        setMaintenancePageDetail: (state, action) => {
            state.maintenancePageDetail = {...state.maintenancePageDetail, ...action.payload}
        },
        setMaintenanceErrorMessage: (state, action) => {
            state.maintenancePageErrorMessage = action.payload
        }
    },
})

export const {setMaintenancePageDetail, setMaintenanceErrorMessage} = maintenancePageSlice.actions
const maintenancePageReducer = maintenancePageSlice.reducer

export default maintenancePageReducer