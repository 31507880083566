import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_CSR_ITEM} from "../../../utils/input-field-list-static-page";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import React, {useEffect} from "react";
import {
    setMainPageCsrDetail,
    setMainPageErrorMessage,
} from "../../../reducers/main-page.reducer";
import ItemCsr from "./item-csr";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Csr = () => {
    const dispatch = useDispatch()
    const {csrDetail} = useSelector(state => state.mainPageReducer)
    const {fetching: getCSR} = useFetch(API.HOMEPAGE_GET_CSR)
    const {fetching: updateCSR, isLoading} = useFetch(API.HOMEPAGE_UPDATE_CSR)
    const itemList = [...csrDetail.item_list]
    let fieldList = FIELD_LIST_CSR_ITEM
    let showCsr = {
        label: 'CSR',
        key: 'show_csr',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageCsrDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'csr').toString(),
            desktop_banner: null,
            mobile_banner: null,
            link_banner: null
        })
        dispatch(setMainPageCsrDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(csrDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataCSR = {...csrDetail, item_list: dataItemList}
        updateCSR(dataCSR).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('CSR Updated'))
            dispatch(setMainPageCsrDetail({...response?.data, delete_item: []}))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getCSR().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setMainPageCsrDetail({...response?.data, delete_item: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setMainPageCsrDetail({...data, delete_item: []}))
            }
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={csrDetail}
            switchToggleField={showCsr}
            itemList={itemList}
            content={(index) => <ItemCsr fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setMainPageCsrDetail}
            deleteList={csrDetail.delete_item}
            objectDeleteName={'delete_item'}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Csr
