import InputComponent from "../../components/input.component";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNotificationDetail} from "../../reducers/notification.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import SearchProductItem from "./search-product-item";

const RoutingForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {notificationDetail, notificationErrorMessage} = useSelector(state => state.notificationReducer)
    const {fetching: categoryList} = useFetch(API.CATEGORIES)

    const onChange = (key, value) => {
        dispatch(setNotificationDetail({[key]: value}))
    }

    const onChangeProduct = (key, value) => {
        const currentProduct = notificationDetail.routing_product_ids
        dispatch(setNotificationDetail({[key]: currentProduct.concat(value)}))
    }

    const onChangeSelect = (item, key) => {
        let value = ''
        switch (key) {
            case 'routing_product_id':
                value = item?.id
                break
            case 'routing_order':
                value = item?.key
                break
            default:
                if (item?.key === 'true') {
                    value = true
                } else if (item?.key === 'false') {
                    value = false
                } else {
                    value = null
                }
        }
        dispatch(setNotificationDetail({[key]: value}))
    }

    const onChangeSelectType = (dataItem, key) => {
        dispatch(setNotificationDetail({[key]: dataItem?.key}))
    }

    const showField = () => {
        switch (notificationDetail?.routing_type) {
            case 'custom':
                return (
                    <div className={"w-1/2"}>
                        <InputComponent item={fieldList[1]} onChangeInput={onChange} data={notificationDetail[fieldList[1].key]}
                                        error={notificationErrorMessage}/>
                    </div>
                )
            case 'list':
                return (
                    <div className={"row space-x-16"}>
                        <div className={"col space-y-4 w-1/2"}>
                            {fieldList[2].map((item, index) => (
                                <InputComponent key={index} item={item} onChange={onChange} onChangeSelect={onChangeSelect} data={notificationDetail[item.key]}
                                                error={notificationErrorMessage}/>
                            ))}
                        </div>
                        <div className={"col space-y-4 w-1/2"}>
                            <InputComponent item={fieldList[3]} onChange={onChange} onChangeProduct={onChangeProduct} data={notificationDetail[fieldList[3].key] ?? []}
                                            error={notificationErrorMessage}/>
                            <InputComponent item={fieldList[4]} onChangeSelect={onChangeSelect} data={notificationDetail[fieldList[4].key] === null ? 'all' : notificationDetail[fieldList[4].key].toString()}
                                                error={notificationErrorMessage}/>
                        </div>
                    </div>
                )
            case 'detail':
                return (
                    <div className={"w-1/2"}>
                        <SearchProductItem item={fieldList[5]} onChangeSelect={onChangeSelect} defaultValue={notificationDetail[fieldList[5].key] ?? []}
                                        error={notificationErrorMessage}/>
                    </div>
                )
            default:
                return <></>
        }
    }

    useEffect(() => {
        if (notificationDetail?.routing_type === 'list') {
            categoryList().then(response => {
                fieldList[2][1].dataList = response.data
            })
        }
    }, [notificationDetail?.routing_type])

    return (
        <div className={"col space-y-4"}>
            <div className={"col w-1/2"}>
                <InputComponent item={fieldList[0]} onChangeSelect={onChangeSelectType} data={notificationDetail[fieldList[0].key]}
                                error={notificationErrorMessage}/>
            </div>
            {showField()}
        </div>
    )
}

export default RoutingForm
