import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_BANNER_ITEM} from "../../../utils/input-field-list-static-page";
import {convertErrorMessage, randomCode} from "../../../utils/general-variable";
import React, {useEffect} from "react";
import {setBeautyFridayBannerDetail, setBeautyFridayErrorMessage} from "../../../reducers/beauty-friday.reducer";
import ItemBanner from "./item-banner";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {cloneDeep} from "lodash";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../../reducers/tools.reducer";
import FormItemComponent from "../../../components/static-page/form-item.component";

const Banner = () => {
    const dispatch = useDispatch()
    const {bannerDetail} = useSelector(state => state.beautyFridayReducer)
    const {fetching: getBanner} = useFetch(API.BF_GET_BANNER)
    const {fetching: updateBanner, isLoading} = useFetch(API.BF_UPDATE_BANNER)
    const itemList = [...bannerDetail.item_list]
    let fieldList = FIELD_LIST_BANNER_ITEM
    let showBanner = {
        label: 'Banner',
        key: 'show_banner',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setBeautyFridayBannerDetail({[key]: value}))
    }

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'banner').toString(),
            desktop_banner: null,
            mobile_banner: null,
            link_banner: null
        })
        dispatch(setBeautyFridayBannerDetail({item_list: itemList}))
    }

    const itemListConstruct = () => {
        const itemListCopy = cloneDeep(bannerDetail.item_list)
        const result = itemListCopy?.map((item, index) => {
            if(!Number(item.id)) delete item.id
            item.sequence = index
            return item
        })
        return result
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        const dataItemList = itemListConstruct()
        const dataBanner = {...bannerDetail, item_list: dataItemList}
        updateBanner(dataBanner).then(response => {
            dispatch(setBeautyFridayErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Banner Updated'))
            dispatch(setBeautyFridayBannerDetail({...response?.data, delete_ids: []}))
        }).catch(err => {
            dispatch(setBeautyFridayErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getBanner().then(response => {
            if (response?.data?.item_list?.length > 0) {
                dispatch(setBeautyFridayBannerDetail({...response?.data, delete_ids: []}))
            } else {
                const data = response?.data
                delete data?.item_list
                dispatch(setBeautyFridayBannerDetail({...data, delete_ids: []}))
            }
        })

        return () => {
            dispatch(setBeautyFridayErrorMessage({}))
        }
    }, [])

    return (
        <FormItemComponent
            data={bannerDetail}
            title={'Banner'}
            switchToggleField={showBanner}
            itemList={itemList}
            content={(index) => <ItemBanner fieldList={fieldList} index={index}/>}
            onChange={onChange}
            setDataList={setBeautyFridayBannerDetail}
            deleteList={bannerDetail?.delete_ids}
            addItemHandler={addItemHandler}
            onSubmitHandler={onSubmitHandler}
            isLoading={isLoading}
        />
    )
}

export default Banner
