import {TrashIcon, UploadIcon} from "../utils/image";
import {
    setProductDetail,
    setProductModalVisibility,
    setProductUploadFromImageType
} from "../reducers/product.reducer";
import {useDispatch, useSelector} from "react-redux";

const UploadMainImageComponent = (props) => {
    const {item} = props
    const dispatch = useDispatch()
    const {main_image} = useSelector(state => state.productReducer.productDetail)

    const uploadImageModal = () => {
        dispatch(setProductModalVisibility({uploadImage: true}))
        dispatch(setProductUploadFromImageType('main_image'))
    }

    const removeImageHandler = () => {
        dispatch(setProductDetail({main_image: {}}))
    }

    return (
        <div className={"col items-center w-fit relative"}>
             <p className={'my-2 italic text-forged-steel'}>{item?.title}</p>
            <div className={"font-semibold bg-battleship-green hover:bg-monstera py-1.5 px-3 rounded-lg text-white cursor-pointer w-fit"}>
                <div className={"row items-center space-x-2"} onClick={uploadImageModal}>
                    <img src={UploadIcon} alt="uploadIcon" className={"w-5"}/>
                    <p>Upload</p>
                </div>
            </div>
            <div className={"row space-x-4 order-first"}>
                    <div className={Object.keys(main_image).length ? 'block' : 'hidden'}>
                        <img src={main_image.url} alt="imageUploaded"
                             className={"w-56 border border-placebo rounded-md"}/>
                        <img src={TrashIcon} alt="deleteImage"
                             className={"w-7 absolute right-2 top-2 border border-placebo rounded-full p-1 bg-white cursor-pointer"}
                             onClick={removeImageHandler}/>
                    </div>
            </div>
        </div>
    )
}

export default UploadMainImageComponent
