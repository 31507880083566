import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {LoadingSvg} from "../../utils/image";
import React, {useEffect} from "react";
import {setGiftDetail, setGiftInitialGiftDetail} from "../../reducers/gift.reducer";
import CreateGift from "../create-gift";

const UpdateGiftPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {
        fetching: getGiftDetail,
        isLoading
    } = useFetch({...API.GIFT_DETAIL, ...{initPathVariable: id}})

    useEffect(() => {
        getGiftDetail().then((response) => {
            dispatch(setGiftDetail(response?.data))
        })
        return () => {
            dispatch(setGiftInitialGiftDetail())
        }
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div className={"row justify-center w-full h-1/2"}>
                        <img src={LoadingSvg} className={"w-12 mx-auto"}/>
                    </div> :
                    <CreateGift/>
            }
        </>
    )
}

export default UpdateGiftPage