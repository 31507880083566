import {useDispatch, useSelector} from "react-redux";
import InputComponent from "../../components/input.component";
import React from "react";
import {setGiftDetail} from "../../reducers/gift.reducer";

const InformationForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {giftDetail, giftErrorMessage} = useSelector(state => state.giftReducer)

    const onChangeInput = (key, value) => {
        dispatch(setGiftDetail({[key]: value}))
    }

    return (
        <div className={"col space-y-8"}>
            <div className={"row space-x-16"}>
                <div className={"col space-y-4 w-1/2"}>
                    {fieldList[0].map((item, index) => (
                        <InputComponent key={index} item={item} data={giftDetail[item.key]}
                                        error={giftErrorMessage}/>
                    ))}
                </div>
                <div className={"col space-y-4 w-1/2"}>
                    {fieldList[1].map((item, index) => (
                        <InputComponent key={index} item={item} data={giftDetail[item.key]}
                                        onChange={onChangeInput} error={giftErrorMessage}/>
                    ))}
                </div>
            </div>
            <div className={"grid grid-cols-2 gap-x-16 gap-y-4"}>
                {fieldList[2].map((item, index) => (
                    <InputComponent key={index} item={item} data={item.type === 'number' && giftDetail[item.key] === 0 ? '0' : giftDetail[item.key]?.toString()}
                                    onChange={onChangeInput} error={giftErrorMessage}/>
                ))}
            </div>
        </div>
    )
}

export default InformationForm
