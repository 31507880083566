import ModalCardComponent from "./modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsIsAlertImportFailed,
    setToolsModalVisibility
} from "../reducers/tools.reducer";
import {CheckCircleIcon, CloseIcon, WarningCircleIcon, XCircleIcon} from "../utils/image";
import React from "react";
import {setProductActiveTab} from "../reducers/product.reducer";
import {STEP_IMPORT_PRODUCT} from "../utils/constant";

const AlertComponent = () => {
    const dispatch = useDispatch()
    const {modalVisibility, alertMessage, alertType, isAlertImportFailed} = useSelector(state => state.toolsReducer)

    const closeModalHandler = () => {
        if(isAlertImportFailed){
            dispatch(setProductActiveTab(STEP_IMPORT_PRODUCT[0]))
            dispatch(setToolsIsAlertImportFailed(false))
        }
        dispatch(setToolsModalVisibility({alert: false}))
    }

    const getImageByType = () => {
        switch (alertType) {
            case 'success':
                return CheckCircleIcon
            case 'failed':
                return XCircleIcon
            case 'warning':
                return WarningCircleIcon
            default:
                return CheckCircleIcon
        }
    }

    return (
        <>
            <ModalCardComponent
                visibility={modalVisibility.alert}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <div className={"col justify-center w-80 h-auto p-5 bg-white rounded-xl"}>
                                <div className={"col space-y-6 justify-center items-center"}>
                                    <p className={"text-eerie-black font-semibold capitalize"}>{alertType}</p>
                                    <img src={getImageByType()} alt="CheckCircleIcon" className={"w-10"}/>
                                    <p className={"text-forged-steel text-center"}>{alertMessage}</p>
                                    <button type={"submit"} onClick={closeModalHandler}
                                            className={"row justify-center px-16 py-2 bg-battleship-green hover:bg-monstera font-semibold text-white rounded-lg cursor-pointer"}>
                                        <p>{isAlertImportFailed ? 'Back' : 'OK'}</p>
                                    </button>
                                </div>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default AlertComponent
