import {useState} from "react";
import Sidebar from "../../components/static-page/sidebar.component";
import {SECTION_MIRACULOUS_SERIES} from "../../utils/sidebar-section-list";
import Intro from "./intro";
import MiraculousItem from "./miraculous-item";
import RelatedProduct from "./related product";

const MiraculousSeries = () => {
    const [activeSidebar, setActiveSidebar] = useState('intro')

    const onChangeSidebar = (key) => {
        setActiveSidebar(key)
    }

    const content = {
        intro: <Intro/>,
        item: <MiraculousItem/>,
        related_product: <RelatedProduct/>
    }

    return (
        <div className={"row px-10 space-x-5 py-5"}>
            <div className={"w-1/5"}>
                <Sidebar section={{page: 'miraculous_series', list: SECTION_MIRACULOUS_SERIES}} onChange={onChangeSidebar} activeSidebar={activeSidebar}/>
            </div>
            <div className={"w-4/5"}>
                {content[activeSidebar]}
            </div>
        </div>
    )
}

export default MiraculousSeries