import React from "react";
import SearchComponent from "../../components/search.component";
import {SORT_LIST_USER} from "../../utils/sort-list";
import {STATE_LIST_USER} from "../../utils/state-list";
import FilterComponent from "../../components/filter.component";
import ExportComponent from "../../components/export.component";
import ChangeStateComponent from "../../components/change-state.component";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import {
    setToolsAlertMessage, setToolsAlertType,
    setToolsCurrentPage,
    setToolsModalVisibility, setToolsReload,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setUserSelectedRowKeys} from "../../reducers/user.reducer";
import {downloadFileExport, formatDateToReadable} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";
import {DATA_LIMIT_LIST} from "../../utils/filter-list";

export const FilterUser = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysUser} = useSelector(state => state.userReducer)
    const {data: roleList} = useFetch(API.ROLE_LIST, {}, {isFetching: true})
    const {fetching: postUpdateStatus, isLoading: loadingUpdateState} = useFetch(API.USER_UPDATE_STATUS)
    const {fetching: exportUserList, isLoading: loadingExport} = useFetch(API.EXPORT_USER_LIST)
    const {
        orderBy,
        sort,
        searchKey,
        dateRange,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const applyStatusHandler = (listId, status) => {
        const body = {
            user_ids: listId,
            action: status
        }
        const message = status === 'delete' ? 'Delete Data Successfully' : 'User State Updated'
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage(message))
            dispatch(setToolsReload(!reload))
        })
        dispatch(setUserSelectedRowKeys([]))
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_role: filter['role'] ?? [],
            filter_by_status: filter['status'] ?? [],
            search: searchKey,
            start_date: dateRange[0] ? formatDateToReadable(dateRange[0], "YYYY-MM-DD") : null,
            end_date: dateRange[1] ? formatDateToReadable(dateRange[1], "YYYY-MM-DD") : null,
            user_ids: selectedRowKeysUser
        }
        exportUserList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `User-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    const filterListUser = [
        {
            title: 'role',
            list: roleList?.data ?? []
        }, {
            title: 'status',
            list: [
                {
                    name: 'active',
                }, {
                    name: 'inactive',
                }
            ]
        }
    ]

    return (
        <div className={"row gap-y-3 justify-between items-center my-5 flex-wrap"}>
            <div className={"row space-x-2"}>
                <SearchComponent placeholder={"cari user..."}/>
                <SortComponent sortList={SORT_LIST_USER}/>
                <FilterComponent filterList={filterListUser} labelName={'name'} keyName={'name'}/>
                <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                <DateRangePickerComponent onChange={getValueFromRangePickerSingle}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data user ?`}
                        countSelected={selectedRowKeysUser.length}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <ChangeStateComponent
                wordingConfirmation={"Apakah anda ingin mengubah status user yang anda pilih ?"}
                wordingDeleteConfirmation={"Apakah anda yakin menghapus user yang anda pilih ?"}
                selectedRow={selectedRowKeysUser}
                stateList={STATE_LIST_USER}
                isLoading={loadingUpdateState}
                applyStatusHandler={applyStatusHandler}/>
        </div>
    )
}
