import TableComponent from "../../components/table-component";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";

const RevenueList = ({dataSource, isLoading}) => {
    const columns = [
        {
            name: 'Tanggal',
            classNameHeader: 'pl-4',
            dataIndex: 'formated_date',
            render: (formated_date) => (
                <p className={"font-regular text-forged-steel p-4"}>{formated_date}</p>
            )
        }, {
            name: 'Total Order',
            classNameHeader: 'text-center',
            dataIndex: 'total_order',
            render: (total_order) => (
                <p className={"font-regular text-forged-steel text-center"}>{total_order}</p>
            )
        }, {
            name: 'Gross Sales',
            classNameHeader: 'text-center',
            dataIndex: 'gross_sales',
            render: (gross_sales) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(gross_sales)}</p>
            )
        }, {
            name: 'Voucher',
            classNameHeader: 'text-center',
            dataIndex: 'voucher',
            render: (voucher) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(voucher)}</p>
            )
        }, {
            name: 'Point',
            classNameHeader: 'text-center',
            dataIndex: 'point',
            render: (point) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(point)}</p>
            )
        }, {
            name: 'Birthday Discount',
            classNameHeader: 'text-center',
            dataIndex: 'discount_birthday',
            render: (discount_birthday) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(discount_birthday)}</p>
            )
        }, {
            name: 'Membership Discount',
            classNameHeader: 'text-center',
            dataIndex: 'discount_membership',
            render: (discount_membership) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(discount_membership)}</p>
            )
        }, {
            name: 'Net Sales',
            classNameHeader: 'text-center',
            dataIndex: 'net_sales',
            render: (net_sales) => (
                <p className={`font-regular text-center ${net_sales < 0 ? 'text-red-600' : 'text-forged-steel'}`}>{doConvertNumberToRupiahFormat(net_sales)}</p>
            )
        }, {
            name: 'Taxes',
            classNameHeader: 'text-center',
            dataIndex: 'tax',
            render: (tax) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(tax)}</p>
            )
        }, {
            name: 'Shipping',
            classNameHeader: 'text-center',
            dataIndex: 'shipping',
            render: (shipping) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(shipping)}</p>
            )
        }, {
            name: 'Total Sales',
            classNameHeader: 'text-center',
            dataIndex: 'total_sales',
            render: (total_sales) => (
                <p className={"font-regular text-forged-steel text-center"}>{doConvertNumberToRupiahFormat(total_sales)}</p>
            )
        },
    ]

    return (
        <div className={"col space-y-4"}>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default RevenueList
