import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {LightStar, ChevronRightIconWhite} from "../../utils/image";
import {setReviewSelectedRowKeys} from "../../reducers/review.reducer";
import {Link} from "react-router-dom";

const ProductReviewList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysReview} = useSelector(state => state.reviewReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Nama Produk',
            width: 500,
            classNameHeader: 'pl-5',
            render: (record) => (
                <div className={"row items-center space-x-3 pl-5"}>
                    <img src={record.image_url} className={"w-7 rounded-lg"} alt={record.image_alt_text}/>
                    <p className={"text-forged-steel capitalize"}>{record.name}</p>
                </div>
            )
        }, {
            name: 'Total Rating',
            dataIndex: 'total_rating',
            classNameHeader: 'text-center',
            render: (total_rating) => (
                <div className={"row items-center justify-center space-x-3 text-forged-steel"}>
                    <img src={LightStar} alt={"starRating"} className={"w-4 rounded-lg"}/>
                    <p className={"font-semibold"}>{total_rating}</p>
                </div>
            )
        }, {
            name: 'Total Review',
            width: 425,
            dataIndex: 'total_review',
            classNameHeader: 'text-center',
            render: (total_review) => (
                <p className={"text-center font-regular text-forged-steel"}>{total_review} Reviews</p>
            )
        }, {
            name: '',
            classNameHeader: 'text-center',
            render: (record) => (
                <Link to={`/reviews/product-review/product-review-detail/${record.id}`} className={"row justify-center space-x-1 w-fit bg-battleship-green hover:bg-monstera p-2 rounded-lg cursor-pointer"}>
                    <p className={"font-Montserrat-Bold text-white"}>DETAIL</p>
                    <img src={ChevronRightIconWhite} alt="buttonCommentDetail" className={"w-4"}/>
                </Link>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setReviewSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Comments"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysReview}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default ProductReviewList
