import AvostoreFilter from "./avostore-filter";
import AvostoreList from "./avostore-list";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {useEffect} from "react";
import {setToolsInitialState} from "../../reducers/tools.reducer";
import {setAvostoreSelectedRowKeys} from "../../reducers/avostore.reducer";
import {SORT_LIST_AVOSTORE} from "../../utils/sort-list";
import {useEffectAfterMount} from "../../services/useEffectAfterMount";

const AvoStorePage = () => {
    const dispatch = useDispatch()
    const defaultSort = SORT_LIST_AVOSTORE?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        currentPage,
        pageSize,
        orderBy,
        sort,
        searchKey,
        filterCity,
        reload
    } = useSelector(state => state.toolsReducer)
    const queryParams = {
        page: currentPage,
        perPage: pageSize,
        orderBy: !!orderBy ? orderBy : getOrderBy[0],
        orderType: sort,
        search: searchKey,
        filterByAreaId: filterCity ?? []
    }
    const {
        data: response,
        fetching: getAvostoreList,
        isLoading
    } = useFetch(API.AVOSTORE_LIST, queryParams, {isWithTotalData: true})

    useEffect(() => {
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setAvostoreSelectedRowKeys([]))
        }
    }, [])

    useEffectAfterMount(() => {
        getAvostoreList()
    }, [currentPage, pageSize, orderBy, sort, searchKey, filterCity, reload])

    return (
        <div className={"col px-10"}>
            <AvostoreFilter/>
            <AvostoreList dataSource={response?.data?.result ?? []} isLoading={isLoading}/>
        </div>
    )
}

export default AvoStorePage
