import React, {useEffect, useState} from "react";
import ModalCardComponent from "../../../components/modal-card.component";
import {useDispatch, useSelector} from "react-redux";
import {CloseIconGreen, LoadingSvg} from "../../../utils/image";
import InputComponent from "../../../components/input.component";
import {checkFieldValidate, convertErrorMessage} from "../../../utils/general-variable";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility
} from "../../../reducers/tools.reducer";

export const ChangePasswordModal = (props) => {
    const {onClose, form, userId} = props
    const dispatch = useDispatch()
    const [fieldList, setFieldList] = useState(form)
    const {changePassword} = useSelector(state => state.userReducer.modalVisibility)
    const {fetching: postUpdatePassword, isLoading} = useFetch(API.USER_UPDATE_USER)

    const onCloseModal = () => {
      onClose('changePassword')
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const body = {
            password: elements.password.value
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (isValid) {
            postUpdatePassword(body, userId).then(() => {
                dispatch(setToolsModalVisibility({alert: true}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Profile Updated'))
                onCloseModal()
            }).catch(err => {
                setFieldList(convertErrorMessage(err, fieldList))
            })
        } else {
            setFieldList(fieldItemsCopy)
        }
    }

    useEffect(() => {
        return () => {
            setFieldList(fieldList.map(item => delete item.errorText))
        }
    }, [])

    return (
        <ModalCardComponent
            content={
                <form onSubmit={onSubmitHandler} className={"absolute bg-white w-80 rounded-lg p-5 space-y-7"}>
                    <div className={"row justify-between items-center"}>
                        <div/>
                        <p className={"font-Montserrat-Bold text-monstera"}>Change Password</p>
                        <img src={CloseIconGreen} className={"w-4 cursor-pointer"} onClick={onCloseModal}/>
                    </div>
                    {fieldList.map((item, index) => (
                        <InputComponent key={index} item={item} error={{[item.key]: item.errorText}}/>
                    ))}
                    <div className={"row justify-center"}>
                        <button
                            className={"rounded-md shadow-inner bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white font-Montserrat-Bold px-10 py-3"}>
                            {isLoading ? <img src={LoadingSvg} className={"w-4 mx-auto"}/> : 'Save Password'}
                        </button>
                    </div>
                </form>
            }
            visibility={changePassword}
            onClose={onCloseModal}
        />
    )
}
