import React, {useState} from "react";
import {EditYellowIcon, LoadingSvg, TrashIcon} from "../../utils/image";
import {Link} from "react-router-dom";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";

export const RoleList = ({data = [], isLoading = false}) => {
    const [selectedRole, setSelectedRole] = useState()
    const dispatch = useDispatch()
    const {reload} = useSelector(state => state.toolsReducer)
    const {fetching: deleteUserRole} = useFetch(API.DELETE_USER_ROLE, {})

    const onShowDeleteAlert = (item) => {
        setSelectedRole(item)
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus role ini?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const onDeleteRole = () => {
        deleteUserRole({}, selectedRole?.id)
            .then(resp => {
                dispatch(setToolsModalVisibility({alert: true, delete: false}))
                dispatch(setToolsAlertType('success'))
                dispatch(setToolsAlertMessage('Role Deleted'))
                dispatch(setToolsReload(!reload))
            })
    }

    return (
        <div className={"col space-y-3"}>
            {
                !!isLoading &&
                <div className={"w-full row justify-center mt-5"}>
                    <img src={LoadingSvg} className={"w-12"} alt={"loadingIcon"}/>
                </div>
            }
            {
                !isLoading && data?.length > 0 &&
                data.map((item, index) => (
                    <div
                        className={"row items-center text-base text-forged-steel justify-between px-10 py-4 rounded-lg border border-placebo grid grid-cols-5"}
                        key={index}>
                        <p className={"font-Montserrat-Bold col-span-2"}>{item.name}</p>
                        <p className={"font-semibold text-black-mana"}>{item.user_count} Users</p>
                        <p className={"font-semibold text-black-mana"}>{item.module_count} Access</p>
                        <div className={"row items-center space-x-3 text-xs font-semibold justify-end"}>
                            <button className={"row items-center space-x-2"} onClick={() => onShowDeleteAlert(item)}>
                                <img src={TrashIcon} className={"w-4"}/>
                                <p>Delete</p>
                            </button>
                            <div className={"w-[1px] h-5 bg-slate-300"}/>
                            <Link to={`/user/role-list/edit-role/${item?.id}`}>
                                <button className={"row items-center space-x-2"}>
                                    <img src={EditYellowIcon} className={"w-4"}/>
                                    <p>Edit</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                ))
            }
            <DeleteConfirmationComponent onDelete={onDeleteRole}/>
        </div>
    )
}
