import React, {useEffect, useState} from "react";
import InputComponent from "../../components/input.component";
import {useDispatch, useSelector} from "react-redux";
import {setVoucherDetail, setVoucherType} from "../../reducers/voucher.reducer";

const SettingsForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {voucherType, voucherErrorMessage, voucherDetail} = useSelector(state => state.voucherReducer)
    let wfcField = fieldList[1].filter((i, index) => ![3, 4, 5].includes(index))
    const otherField = fieldList[1].filter((i, index) => ![6, 7].includes(index))
    const [rightTabField, setRightTabField] = useState(otherField)

    useEffect(() => {
        if (voucherDetail.type === 'free_gift' || voucherDetail.type === 'avo_wfc') {
            setRightTabField(wfcField)
        }
        if(voucherDetail.discount_type === 'percentage'){
            fieldList[1][5].isHide = false
        }
    }, [voucherDetail?.type])

    const onChangeSelect = (item) => {
        if (item.key === 'free_gift' || item.key === 'avo_wfc') {
            setRightTabField(wfcField)
        } else {
            dispatch(setVoucherDetail({'gifts': [], discount_type: 'nominal', type: item?.key}))
            setRightTabField(otherField)
        }
        dispatch(setVoucherType(item.key))
    }

    const onChangeInput = (key, value) => {
        dispatch(setVoucherDetail({[key]: value}))
        if (key === 'discount_type') {
            fieldList[1][5].isHide = (value === 'nominal') ? true : false
        }
    }

    const onChangeProduct = (key, value) => {
        dispatch(setVoucherDetail({[key]: voucherDetail.gifts.concat(value)}))
    }

    return (
        <div className={"row space-x-16"}>
            <div className={"col space-y-4 w-1/2"}>
                {fieldList[0].map((item, index) => (
                    <InputComponent key={index} item={item} data={voucherDetail[item.key]} onChange={onChangeInput}
                                    error={voucherErrorMessage}/>
                ))}
            </div>
            <div className={"col space-y-4 w-1/2"}>
                {rightTabField.map((item, index) => (
                    <InputComponent key={index} item={item}
                                    data={item.key === 'free_shipping' ? !!voucherDetail?.gifts[0]?.is_free_shipping : voucherDetail[item.key]}
                                    voucherType={voucherType}
                                    onChange={onChangeInput} onChangeSelect={onChangeSelect}
                                    onChangeProduct={onChangeProduct}
                                    error={voucherErrorMessage}/>
                ))}
            </div>
        </div>
    )
}

export default SettingsForm
