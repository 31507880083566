import {createSlice} from '@reduxjs/toolkit'
import {productModel} from "../models/product.model";

const ImportProductModel = {
    file: {},
    is_update: true,
    mapping_fields: []
}

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        productDetail: {...productModel},
        productErrorMessage: {},
        selectedRowKeysProduct: [],
        productList: [],
        modalVisibility: {
            showImage: false,
            uploadImage: false
        },
        uploadFormImageType: '',
        activeTab: {
            id: 0,
            label: 'Upload XLSX File',
            key: 'upload_xlsx_file'
        },
        fileName: '',
        importProductDetail: {...ImportProductModel},
        importResult: {}
    },
    reducers: {
        setProductDetail: (state, action) => {
            state.productDetail = {...state.productDetail, ...action.payload}
        },
        setProductFileName: (state, action) => {
            state.fileName = action.payload
        },
        setProductImportResult: (state, action) => {
            state.importResult = action.payload
        },
        setProductErrorMessage: (state, action) => {
            state.productErrorMessage = action.payload
        },
        setProductSelectedRowKeys: (state, action) => {
            state.selectedRowKeysProduct = action.payload
        },
        setProductModalVisibility: (state, action) => {
            state.modalVisibility = {...state.modalVisibility, ...action.payload}
        },
        setProductActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setProductUploadFromImageType: (state, action) => {
            state.uploadFormImageType = action.payload
        },
        setProductInitialProductDetail: (state) => {
            state.productDetail = {...productModel}
        },
        setProductImportProductDetail: (state, action) => {
            state.importProductDetail = {...state.importProductDetail, ...action.payload}
        },
        setProductInitialImportProductDetail: (state) => {
            state.fileName = ''
            state.importProductDetail = {...ImportProductModel}
            state.importResult = {}
        },
    },
})

export const {
    setProductDetail,
    setProductFileName,
    setProductErrorMessage,
    setProductSelectedRowKeys,
    setProductModalVisibility,
    setProductActiveTab,
    setProductUploadFromImageType,
    setProductInitialProductDetail,
    setProductImportProductDetail,
    setProductInitialImportProductDetail,
    setProductImportResult
} = productSlice.actions
const productReducer = productSlice.reducer

export default productReducer
