import TotalDataComponent from "../../components/total-data.component";
import TableComponent from "../../components/table-component";
import {useDispatch, useSelector} from "react-redux";
import {setVoucherSelectedRowKeys} from "../../reducers/voucher.reducer";
import {Link} from "react-router-dom";
import {convertDateToCustomFormat} from "../../utils/general-variable";
import React from "react";
import {VoucherDefaultIcon} from "../../utils/image";

const VoucherList = ({data, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysVoucher} = useSelector(state => state.voucherReducer)
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: '',
            render: (record) => (
                <div className={"px-3"}>
                    <img src={record.thumbnail || VoucherDefaultIcon} className={"w-10"} alt={"imageVoucher"}/>
                </div>
            )
        }, {
            name: 'Kode Voucher',
            render: (record) => (
                <Link to={`/voucher/voucher-list/update-voucher/${record.id}`}>
                    <div className={"row items-center space-x-1 font-Montserrat-Bold text-eerie-black"}>
                        <p className={"uppercase"}>{record.code}</p>
                        {record?.status === 'draft' && <p className={"italic"}>- Draft</p>}
                    </div>
                </Link>
            )
        }, {
            name: 'Tipe',
            dataIndex: 'type',
            className: 'text-forged-steel capitalize'
        }, {
            name: 'Nilai',
            dataIndex: 'amount',
            classNameHeader: 'text-center',
            className: 'text-forged-steel text-center',
            width: 150
        }, {
            name: 'Deskripsi',
            dataIndex: 'description',
            className: 'text-forged-steel',
            render: (description) => (
                <div dangerouslySetInnerHTML={{__html: description}} />
            )
        }, {
            name: 'Usage / Limit',
            classNameHeader: 'text-center',
            render: (record) => (
                <p className={"text-center"}>{record.usage_count} / {record.usage_limit}</p>
            )
        }, {
            name: 'Expired Date',
            dataIndex: 'date_expires',
            classNameHeader: 'text-center',
            width: 150,
            render: (date_expires) => (
                <p className={"text-center text-forged-steel"}>{!!date_expires && convertDateToCustomFormat(date_expires, "YYYY/MM/DD HH:mm")}</p>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setVoucherSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Vouchers"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysVoucher}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={data}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default VoucherList
