import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    setToolsCurrentPage,
    setToolsFilterCity,
    setToolsModalVisibility
} from "../reducers/tools.reducer";
import {CloseIcon, FilterIcon, FilterWhiteIcon, SearchIcon} from "../utils/image";
import ModalCardComponent from "./modal-card.component";

const FilterCityComponent = (props) => {
    const {filterList, labelName, keyName, placeholder} = props
    const dispatch = useDispatch()
    const {filterCity, modalVisibility} = useSelector(state => state.toolsReducer)
    const [checkList, setCheckList] = useState([])
    const [cityList, setCityList] = useState(filterList)
    const filterCityLength = filterCity?.length

    const showModalHandler = () => {
        dispatch(setToolsModalVisibility({filterCity: true}))
    }

    const closeModalHandler = () => {
        dispatch(setToolsModalVisibility({filterCity: false}))
    }

    useEffect(() => {
        setCityList(filterList)
    }, [filterList])

    const checkedHandler = (event) => {
        const {id, checked} = event.target
        if (!checked) {
            setCheckList(checkList.filter(item => item !== id))
        } else {
            setCheckList([...checkList, id])
        }
    }

    const clearFilterHandler = () => {
        setCheckList([])
    }

    const searchListOption = (event) => {
        const keyword = event.target.value
        const filterData = cityList.map(list => {
            return {
                title: list.title,
                list: filterList[0].list.filter(list =>
                    list[labelName].toLowerCase().includes(keyword.toLowerCase())
                )
            }
        })
        setCityList(filterData)
    }

    const filterItem = (filter, list) => {
        return (
            <>
                <input type="checkbox" value={list[keyName]}
                       className={"accent-monstera"} id={list[keyName]}
                       onChange={checkedHandler}
                       checked={checkList.includes(list[keyName].toString())}
                />
                <label htmlFor={list[keyName]}
                       className={"row space-x-1 justify-center text-forged-steel font-regular capitalize"}>
                    {list[labelName]}
                </label>
            </>
        )
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        dispatch(setToolsFilterCity(checkList))
        dispatch(setToolsCurrentPage(1))
        closeModalHandler()
    }

    useEffect(() => {
        setCheckList(filterCity)
    }, [modalVisibility.filterCity])

    return (
        <div className={"font-regular"}>
            <div onClick={showModalHandler}
                 className={`row items-center space-x-2 px-3 py-2 font-semibold rounded-lg border border-placebo cursor-pointer ${filterCityLength > 0 && 'bg-battleship-green text-white'} relative`}>
                <img className={"h-5"} src={filterCityLength > 0 ? FilterWhiteIcon : FilterIcon} alt="filter"/>
                <p>Filter City</p>
                <div className={`absolute -right-1 -top-3.5 py-1 px-2.5 bg-monstera rounded-full ${filterCityLength > 0 ? 'block' : 'hidden'}`}>
                    <span>{filterCityLength}</span>
                </div>
            </div>
            <ModalCardComponent
                visibility={modalVisibility.filterCity}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <form onSubmit={onSubmitHandler}>
                            <div className={"col space-y-8 w-[300px] pr-5 py-5 bg-white rounded-xl"}>
                                <p className={"text-center font-semibold pl-8"}>Filter</p>
                                <div className={"col space-y-3 ml-5"}>
                                    <div className={"row items-center px-2 h-9 border border-placebo rounded-lg"}>
                                        <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                                        <input type={"text"} name={"search"}
                                               placeholder={!!placeholder ? placeholder : 'cari...'}
                                               className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                                               onChange={searchListOption}/>
                                    </div>
                                    <div className={`row items-center justify-between ${checkList.length > 0 ? 'flex' : 'hidden'}`}>
                                        <p className={"font-semibold text-monstera"}>Terpilih</p>
                                        <button type={"button"} onClick={clearFilterHandler}
                                                className={"text-spiced-coral bg-pink-lemonade py-1 px-2 font-semibold rounded-full"}>
                                            Clear
                                        </button>
                                    </div>
                                    {cityList?.map((city, index) => (
                                        <div key={index} className={"max-h-28 overflow-scroll"}>
                                            {city.list.map((list, index) =>
                                                checkList.includes(list[keyName].toString()) &&
                                                <div key={index} className={"row space-x-5 pl-3 py-3 w-full"}>
                                                    {filterItem(city, list)}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={"col space-y-4 max-h-36 overflow-scroll pr-10"}>
                                    {cityList?.map((city, index) => (
                                        <div key={index} className={"mb-2"}>
                                            <p className={"text-monstera capitalize font-semibold pl-5 pb-2"}>{city.title}</p>
                                            {city.list.map((list, index) =>
                                                !checkList.includes(list[keyName].toString()) &&
                                                <div key={index} className={"row space-x-5 pl-8 py-3 w-full"}>
                                                    {filterItem(city, list)}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={"row justify-between items-center pl-8"}>
                                    <div
                                        className={"row justify-center px-8 py-2 border border-placebo hover:text-white hover:bg-dazzle rounded-lg cursor-pointer"}
                                        onClick={closeModalHandler}>
                                        <p>Cancel</p>
                                    </div>
                                    <button type={"submit"}
                                            className={"row justify-center px-8 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg cursor-pointer"}>
                                        <p>Apply</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={"w-5 absolute right-4 top-2 cursor-pointer"}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </div>
    )
}

export default FilterCityComponent
