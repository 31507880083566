import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import FilterComponent from "../../components/filter.component";
import {SORT_LIST_COMMENT} from "../../utils/sort-list";
import {DATA_LIMIT_LIST, FILTER_LIST_COMMENT} from "../../utils/filter-list";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {setCommentSelectedRowKeys} from "../../reducers/comment.reducer";
import React from "react";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment";
import SortComponent from "../../components/sort.component";
import PageLimitComponent from "../../components/page-limit.component";

const ReviewsFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysComment} = useSelector(state => state.commentReducer)
    const {fetching: postUpdateStatus} = useFetch(API.REVIEW_UPDATE_STATUS)
    const {fetching: exportReviewList, isLoading: loadingExport} = useFetch(API.EXPORT_REVIEW_LIST)
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const openDeleteModal = () => {
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus review yang anda pilih?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const deleteReviewHandler = () => {
        const body = {
            review_ids: selectedRowKeysComment
        }
        dispatch(setCommentSelectedRowKeys([]))
        postUpdateStatus(body).then(() => {
            dispatch(setToolsModalVisibility({delete: false, alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Delete Review Successfully'))
            dispatch(setToolsReload(!reload))
        }).catch(() => {
            dispatch(setToolsModalVisibility({delete: false}))
        })
    }

    const exportDataHandler = () => {
        const body = {
            order_by: orderBy,
            order_type: sort,
            filter_by_rate: filter['rating'] ?? [],
            search: searchKey,
            review_ids: selectedRowKeysComment
        }
        exportReviewList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `All-Review-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"cari komentar..."}/>
                <div className={"row space-x-3"}>
                    <SortComponent sortList={SORT_LIST_COMMENT}/>
                    <FilterComponent filterList={FILTER_LIST_COMMENT} labelName={'label'} keyName={'key'}/>
                    <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
                    <div>
                        <ExportComponent
                            wordingConfirmation={`Apakah anda yakin ingin melakukan export data review ?`}
                            countSelected={selectedRowKeysComment.length}
                            exportDataHandler={exportDataHandler}
                            isLoading={loadingExport}/>
                    </div>
                </div>
            </div>
            <div
                className={`text-white py-3 px-8 font-semibold rounded-lg ${selectedRowKeysComment.length > 0 ? 'bg-battleship-green hover:bg-monstera cursor-pointer' : 'bg-fortress-grey cursor-not-allowed'}`}
                onClick={selectedRowKeysComment.length > 0 ? openDeleteModal : undefined}>
                <p>Delete Review</p>
            </div>
            <DeleteConfirmationComponent onDelete={deleteReviewHandler}/>
        </div>
    )
}

export default ReviewsFilter
