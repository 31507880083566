import React, {useEffect} from "react";
import {ProfileDetail} from "./profile-detail";
import {USER_DETAIL_ACCESSIBILITY, USER_DETAIL_PROFILE} from "../../../utils/constant";
import {CHANGE_PASSWORD_ITEM, PROFILE_FORM_LIST} from "../../../utils/input-field-list";
import {Accessibilty} from "./accessibilty";
import {EditProfileModal} from "./edit-profile.modal";
import {EditAccessModal} from "./edit-access.modal";
import {ChangePasswordModal} from "./change-password.modal";
import {setUserModalVisibility, setUserProfile} from "../../../reducers/user.reducer";
import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {useParams} from "react-router-dom";
import {LoadingSvg} from "../../../utils/image";

const TabProfile = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {userStatistic, userProfile} = useSelector(state => state.userReducer)
    const {data: roleList} = useFetch(API.ROLE_LIST, {}, {isFetching: true})
    const {fetching: getUserProfile, isLoading} = useFetch(API.USER_PROFILE)

    const onClose = (key) => {
        dispatch(setUserModalVisibility({[key]: false}))
    }

    useEffect(() => {
        getUserProfile({}, id).then(response => {
            dispatch(setUserProfile(response.data))
        })
    }, [id])

    return (
        <>
            {isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> : (
                <div className={"px-4 grid grid-cols-2 gap-5"}>
                    <ProfileDetail details={USER_DETAIL_PROFILE} data={userProfile}/>
                    <Accessibilty details={USER_DETAIL_ACCESSIBILITY} data={userProfile}/>
                    <EditProfileModal form={PROFILE_FORM_LIST} onClose={onClose} userId={id} data={[userStatistic, userProfile]}/>
                    <EditAccessModal form={roleList?.data ?? []} onClose={onClose} userId={id} data={userProfile}/>
                    <ChangePasswordModal form={CHANGE_PASSWORD_ITEM} onClose={onClose} userId={id}/>
                </div>
            )}
        </>
    )
}

export default TabProfile
