import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_INPUT_SUSTAINABILITY_RUBI} from "../../utils/input-field-list-static-page";
import {setMainPageErrorMessage, setMainPageSustainabilityDetail} from "../../reducers/main-page.reducer";
import React, {useEffect} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Sustainability = () => {
    const dispatch = useDispatch()
    const {sustainabilityDetail, mainPageErrorMessage} = useSelector(state => state.mainPageReducer)
    const {fetching: getSustainability} = useFetch(API.HOMEPAGE_GET_SUSTAINABILITY)
    const {fetching: updateSustainability, isLoading} = useFetch(API.HOMEPAGE_UPDATE_SUSTAINABILITY)
    let fieldListInput = FIELD_LIST_INPUT_SUSTAINABILITY_RUBI
    let showSustainability = {
        label: 'Sustainability',
        key: 'show_sustainability',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setMainPageSustainabilityDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateSustainability(sustainabilityDetail).then(response => {
            dispatch(setMainPageErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Sustainability Updated'))
            dispatch(setMainPageSustainabilityDetail(response?.data))
        }).catch(err => {
            dispatch(setMainPageErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getSustainability().then(response => {
            dispatch(setMainPageSustainabilityDetail(response?.data))
        })

        return () => {
            dispatch(setMainPageErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={sustainabilityDetail}
            switchToggleField={showSustainability}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={mainPageErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Sustainability