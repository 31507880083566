import {useDispatch, useSelector} from "react-redux";
import ModalCardComponent from "../../components/modal-card.component";
import {setCategoryDetail, setCategoryModalVisibility} from "../../reducers/category.reducer";
import {CloseIcon, LoadingSvg} from "../../utils/image";
import {FIELD_LIST_CREATE_CATEGORY} from "../../utils/input-field-list";
import InputComponent from "../../components/input.component";
import React, {useEffect, useRef, useState} from "react";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {checkFieldValidate, convertErrorMessage} from "../../utils/general-variable";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {categoryModel} from "../../models/category.model";

const CreateCategoryForm = () => {
    const dispatch = useDispatch()
    const initFieldList = FIELD_LIST_CREATE_CATEGORY
    const refForm = useRef(null)
    const {reload} = useSelector(state => state.toolsReducer)
    const {modalVisibility, categoryDetail} = useSelector(state => state.categoryReducer)
    const {createCategory, updateCategory} = modalVisibility
    const [fieldList, setFieldList] = useState(initFieldList)
    const {data: parentCategoryList, fetching: parentCategory} = useFetch(API.PARENT_CATEGORY_LIST, {scope: 'parentOnly'}, {isFetching: true})
    const {fetching: createCategoryFetching, isLoading: loadingCreateCategory} = useFetch(API.CREATE_CATEGORY)
    const {fetching: updateCategoryFetching, isLoading: loadingUpdateCategory} = useFetch(API.UPDATE_CATEGORY)

    const showModalHandler = () => {
        dispatch(setCategoryModalVisibility({createCategory: true}))
    }

    const onChangeSelect = (item, key) => {
        dispatch(setCategoryDetail({[key]: item.id}))
    }

    useEffect(() => {
        fieldList[0].options = parentCategoryList?.data
    }, [parentCategoryList])

    useEffect(() => {
        if (updateCategory && !categoryDetail?.parent_category) {
            setFieldList(fieldList.slice(1))
        }
    }, [updateCategory])

    const closeModalHandler = () => {
        refForm.current.reset()
        setFieldList(initFieldList.map(i => {
            return {...i, errorText: null}
        }))
        dispatch(setCategoryModalVisibility({createCategory: false, updateCategory: false}))
        dispatch(setCategoryDetail({...categoryModel}))
    }

    const showAlertModal = (message) => {
        dispatch(setToolsModalVisibility({alert: true}))
        dispatch(setToolsAlertType('success'))
        dispatch(setToolsAlertMessage(message))
        dispatch(setToolsReload(!reload))
        closeModalHandler()
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        const elements = event.target
        const fieldItemsCopy = [...fieldList]
        const requestBody = {
            parent_id: elements?.parent_category?.value || null,
            name: elements?.name?.value,
            slug: elements?.slug?.value,
            description: elements?.description?.value || null,
        }
        const isValid = checkFieldValidate(fieldItemsCopy, elements)
        if (isValid) {
            getPromiseApi(requestBody).then(() => {
                showAlertModal(createCategory ? 'Category Created' : 'Category Updated')
                createCategory && parentCategory()
            }).catch(err => {
                setFieldList(convertErrorMessage(err, fieldList))
            })
        } else {
            setFieldList(fieldItemsCopy)
        }
    }

    const getPromiseApi = (requestBody) => {
        if (createCategory) {
            return createCategoryFetching(requestBody)
        } else {
            return updateCategoryFetching(requestBody, categoryDetail.id)
        }
    }

    return (
        <>
            <div
                className={"text-white bg-battleship-green hover:bg-monstera py-2 px-8 font-semibold rounded-lg cursor-pointer"}
                onClick={showModalHandler}>
                <p>Create Category</p>
            </div>
            <ModalCardComponent
                visibility={createCategory || updateCategory}
                onClose={closeModalHandler}
                content={
                    <div className={"relative"}>
                        <form onSubmit={onSubmitHandler} ref={refForm}>
                            <div className={"col space-y-8 w-96 px-5 py-5 bg-white rounded-xl"}>
                                <p className={"text-sm text-center text-monstera font-Montserrat-Bold"}>{createCategory ? 'Create Category' : 'Update Category'}</p>
                                {fieldList.map((item, index) => (
                                    <InputComponent key={index} item={item}
                                                    data={categoryDetail[item.key]}
                                                    onChangeSelect={onChangeSelect}
                                                    error={{[item.key]: item.errorText}}/>
                                ))}
                                <button type={"submit"}
                                        className={"mx-auto px-20 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera font-semibold text-white rounded-lg cursor-pointer"}>
                                    {loadingCreateCategory || loadingUpdateCategory ?
                                        <img src={LoadingSvg} className={"w-5 mx-auto"}/> : createCategory ? 'Create' : 'Update'}
                                </button>
                            </div>
                        </form>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                }
            />
        </>
    )
}

export default CreateCategoryForm
