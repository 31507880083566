import TabOrderGraph from "./tab-order-graph";
import DateRangePickerComponent from "../../components/date-range-picker.component";
import ExportComponent from "../../components/export.component";
import {useDispatch, useSelector} from "react-redux";
import {
    setToolsCurrentPage,
    setToolsInitialState, setToolsModalVisibility,
    setToolsStartDateEndDate
} from "../../reducers/tools.reducer";
import TabOrderList from "./tab-order-list";
import TabOrderAndComparation from "./tab-order-and-comparation";
import React, {useEffect} from "react";
import {dateRangeToday, downloadFileExport, formatDateToReadable} from "../../utils/general-variable";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import moment from "moment";
import AdvanceFilter from "./advance-filter";
import {advanceFilterAnalyticsOrderModel} from "../../models/advance-filter";
import {
    setAnalyticsOrderAdvanceFilter,
    setAnalyticsOrderDateRangeAnalyticVoucher
} from "../../reducers/analytics-order.reducer";

const OrderAnalyticsPage = () => {
    const dispatch = useDispatch()
    const {dateRange} = useSelector(state => state.toolsReducer)
    const {advanceFilter, dateRangeAnalyticVoucher} = useSelector(state => state.analyticsOrderReducer)
    const {fetching: exportOrderAnalytic, isLoading: loadingExport} = useFetch(API.EXPORT_ORDER_ANALYTIC)

    const getValueFromRangePickerSingle = (dateRange) => {
        dispatch(setToolsStartDateEndDate(dateRange))
        dispatch(setToolsCurrentPage(1))
    }

    const exportDataHandler = () => {
        const requestBody = {...{
                start_date: formatDateToReadable(dateRange[0], "YYYY-MM-DD"),
                end_date: formatDateToReadable(dateRange[1], "YYYY-MM-DD")
            }, ...advanceFilter}
        exportOrderAnalytic(requestBody, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Order-Analytic-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    useEffect(() => {
        if (!!dateRangeAnalyticVoucher[0]) {
            dispatch(setToolsStartDateEndDate(dateRangeAnalyticVoucher))
        } else {
            dispatch(setToolsStartDateEndDate(dateRangeToday))
        }
        return () => {
            dispatch(setToolsInitialState())
            dispatch(setAnalyticsOrderAdvanceFilter(advanceFilterAnalyticsOrderModel))
            dispatch(setAnalyticsOrderDateRangeAnalyticVoucher([null, null]))
        }
    }, [])

    return (
        <div className={"col space-y-4 px-10 my-4"}>
            <div className={"row items-center justify-center"}>
                <div className={"mr-5"}>
                    <DateRangePickerComponent slug={'analytics'} onChange={getValueFromRangePickerSingle}/>
                </div>
                <AdvanceFilter/>
                <div className={"ml-5"}>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data analytics order ?`}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <TabOrderAndComparation/>
            <TabOrderGraph/>
            <TabOrderList/>
        </div>
    )
}

export default OrderAnalyticsPage
