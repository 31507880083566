import {ChevronDownWhiteIcon, ChevronUpWhiteIcon} from "../../utils/image";
import {Link} from "react-router-dom";
import {doConvertNumberToRupiahFormat} from "../../utils/general-variable";
import React, {useState} from "react";

const RowExpandable = ({item}) => {
    const [isShow, setIsShow] = useState(false)

    const onClickHandler = () => {
        setIsShow(!isShow)
    }

    return (
        <>
            <tr className={"h-10"}>
                <td className={"pl-4"}>
                    <Link to={`/loyalty-program/birthday-gift/create-birthday-gift/${item.id}`}>
                        <p className={"text-dazzle capitalize hover:font-Montserrat-Bold hover:underline"}>{item.name}</p>
                    </Link>
                </td>
                <td className={"text-center"}>
                    {item.gifts.length} Gift
                </td>
                <td>
                    <div className={"row justify-end cursor-pointer"} onClick={onClickHandler}>
                        <img src={isShow ? ChevronUpWhiteIcon : ChevronDownWhiteIcon} alt="expand"
                             className={"rounded-full bg-battleship-green hover:bg-monstera"}/>
                    </div>
                </td>
            </tr>
            {item.gifts?.map((gift, i) => (
                <tr key={i} className={`h-10 ${isShow ? 'table-row' : 'hidden'}`}>
                    <td className={"pl-4 w-1/4"}>Gift {i + 1}</td>
                    <td>[Gift] - {gift?.gift_name}</td>
                    <td className={"text-center"}>{doConvertNumberToRupiahFormat(gift?.discount_amount)}</td>
                </tr>
            ))}
        </>
    )
}

export default RowExpandable
