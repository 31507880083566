import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import React, {useEffect} from "react";
import {setBeautyEmptiesDetail} from "../../reducers/beauty-empties.reducer";
import {LoadingSvg} from "../../utils/image";
import BeautyEmptiesDetailHeader from "./beauty-empties-detail-header";
import ProductList from "./product-list";
import HistoryBeautyEmpties from "./history-beauty-empties";
import ProofBeautyEmpties from "./proof-beauty-empties";

const BeautyEmptiesDetail = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const {reload} = useSelector(state => state.toolsReducer)
    const {fetching: getBeautyEmptiesDetail, isLoading} = useFetch({...API.BEAUTY_EMPTIES_DETAIL, ...{initPathVariable: id}}, {}, {isLoading: true})

    useEffect(() => {
        getBeautyEmptiesDetail().then(response => {
            dispatch(setBeautyEmptiesDetail(response?.data[0]))
        })
    },[reload])

    return (
        <div className={"col space-y-6 px-10 mb-10 mt-4"}>
            {
                isLoading ? <img src={LoadingSvg} className={"w-12 mx-auto"}/> :
                    <>
                        <BeautyEmptiesDetailHeader/>
                        <div className={"row space-x-8"}>
                            <div className={"w-2/3 space-y-4"}>
                                <ProductList/>
                            </div>
                            <div className={"col w-1/3 space-y-4"}>
                                <HistoryBeautyEmpties/>
                                <ProofBeautyEmpties/>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default BeautyEmptiesDetail
