import {
    JntLogo,
    JneLogo,
    RpxLogo,
    SicepatLogo,
    AnterAjaLogo,
    PosLogo,
    LightStar,
    DarkStar,
    ArrowDownRed,
    ArrowUpGreen,
    MandiriLogo,
    BcaLogo,
    BniLogo,
    GopayLogo,
    CreditCardLogo,
    PermataBankLogo,
    BriLogo,
    AlfamartLogo,
    AkulakuLogo,
    ShopeePayLogo
} from "./image"
import moment from "moment/moment";
import validate from "./validate";
import {getLocalAccesToken} from "../services/axios";
import {RULES_IMAGE_UPLOAD} from "./constant";

const idLocale = require('moment/locale/id')

const doConvertNumberToRupiahFormat = (numberToFormat) => {
    return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(numberToFormat)
}

const convertDateToCustomFormat = (date, format) => {
    moment.locale('id', idLocale)
    return moment(date).format(format)
}

const convertUtcToCustomFormat = (date, format) => {
    return moment(date).utc().format(format)
}

const convertUnixToMoment = (date) => {
    return moment.unix(date)
}

const formatDateStartOfUnix = (date) => {
    return moment(date).startOf('day').unix()
}

const formatDateEndOfUnix = (date) => {
    return moment(date).endOf('day').unix()
}

const formatDateToReadable = (date, format) => {
    return moment.unix(date).format(!!format ? format : "DD-MM-YYYY")
}

const getExpeditionLogo = (expedition) => {
    const expeditionName = expedition?.split('-')[0].split(' ').join('').toLowerCase()

    switch (expeditionName) {
        case 'jnt':
            return JntLogo
        case 'jne':
            return JneLogo
        case 'rpx':
            return RpxLogo
        case 'sicepat':
            return SicepatLogo
        case 'pos':
            return PosLogo
        case 'anteraja':
            return AnterAjaLogo
    }
}

const getMethodPayment = (methodPayment) => {
    let logo = ''
    let text = ''
    switch (methodPayment) {
        case 'credit_card':
            logo = CreditCardLogo
            text = 'Credit card'
            break
        case 'echannel':
            logo = MandiriLogo
            text = 'Mandiri Virtual Account'
            break
        case 'bca_va':
            logo = BcaLogo
            text = 'BCA Virtual Account'
            break
        case 'permata_va':
            logo = PermataBankLogo
            text = 'Permata Virtual Account'
            break
        case 'bni_va':
            logo = BniLogo
            text = 'BNI Virtual Account'
            break
        case 'bri_va':
            logo = BriLogo
            text = 'BRI Virtual Account'
            break
        case 'gopay':
            logo = GopayLogo
            text = 'GoPay'
            break
        case 'shopeepay':
            logo = ShopeePayLogo
            text = 'ShopeePay'
            break
        case 'alfamart':
            logo = AlfamartLogo
            text = 'Alfamart'
            break
        case 'akulaku':
            logo = AkulakuLogo
            text = 'Akulaku'
            break
    }
    return {
        logo,
        text
    }
}

const statusRenderClassName = (status) => {
    let text = ''
    let className = ''
    switch (status) {
        case 'on-hold':
        case 'waiting-approval':
            className = 'text-glitzy-gold bg-dipped-in-cream'
            text = status.replace('-', ' ')
            break;
        case 'pending-payment':
            className = 'text-safe-harbour bg-powder-white'
            text = status.replace('-', ' ')
            break;
        case 'processing':
        case 'active':
        case 'publish':
        case 'accepted':
            className = 'text-toad bg-apple-flower'
            text = status
            break;
        case 'packing':
            className = 'text-old-guitar bg-placebo-orange'
            text = status
            break;
        case 'on-delivery':
        case 'draft':
            className = 'text-chinese-bellflower bg-silver-phoenix'
            text = status.replace('-', ' ')
            break;
        case 'completed':
            className = 'text-forged-steel bg-bleached-silk'
            text = status
            break;
        case 'inactive':
        case 'rejected':
        case 'cancelled':
            className = 'text-glowing-scarlet bg-crystal-clear'
            text = status
            break;
        default:
            return 0
    }
    return {
        text,
        className
    }
}

const getStarRatingImage = (count) => {
    const star = []
    for (let i = 1; i <= 5; i++) {
        if (i <= count) {
            star.push(LightStar)
        } else {
            star.push(DarkStar)
        }
    }
    return star
}

const textPercentage = (trend, value) => {
    let icon = ''
    let text = ''
    let className = ''
    switch (trend) {
        case 'up':
            icon = ArrowUpGreen
            text = `Naik ${value?.toFixed(2)}%`
            className = 'text-toad'
            break
        case 'down':
            icon = ArrowDownRed
            text = `Turun ${value?.toFixed(2)}%`
            className = 'text-spiced-coral'
            break
        case 'constant':
            text = 'Konstan'
            className = 'text-forged-steel'
            break
        default:
            return 0
    }
    return {icon, text, className}
}

const checkFieldValidate = (fieldList, elements) => {
    let isValid = true;

    fieldList.forEach((field) => {
        let result = validate(field, elements[field.key]?.value);
        if (result) {
            isValid = false;
            field.errorText = result
        } else {
            field.errorText = null
        }
    })

    return isValid
}

const randomCode = (length, total, prefix, isAddSpecialCharacter) => {
    const listVoucher = []
    const characters = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '0123456789']
    const specialCharacters = '!@#$%^&*(),.?":{}|<>_+=\\-[\\]\\\\/\'~`'
    for (let j = 0; j < total; j++) {
        let result = prefix
        let specialChar = specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length))
        let lengthChar = isAddSpecialCharacter ? length - 1 : length
        let indexChar = 0
        for (let j = 0; j < lengthChar; j++) {
            result += characters[indexChar].charAt(Math.floor(Math.random() * characters[indexChar].length))
            if(indexChar < characters.length - 1) {
                indexChar += 1
            } else {
                indexChar = 0
            }
        }
        result = isAddSpecialCharacter ? result+specialChar : result
        listVoucher.push(result)
    }
    return listVoucher
}

const isLogin = () => {
    return !!getLocalAccesToken('refresh_token')
}

const getImageLocal = (shipping) => {
  switch (shipping) {
      case 'jnt':
          return JntLogo
      case 'jne':
          return JneLogo
      case 'sicepat':
          return SicepatLogo
      case 'rpx':
          return RpxLogo
      case 'echannel':
          return MandiriLogo
      case 'bca_va':
          return BcaLogo
      case 'bni_va':
          return BniLogo
      case 'gopay':
          return GopayLogo
      case 'credit_card':
          return CreditCardLogo
      case 'permata_va':
          return PermataBankLogo
      case 'bri_va':
          return BriLogo
      case 'shopeepay':
          return ShopeePayLogo
      case 'alfamart':
          return AlfamartLogo
      case 'akulaku':
          return AkulakuLogo
      default:
          return MandiriLogo
  }
}

const doConvertNumberWithPoint = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const dateRangeToday = [moment().unix(), moment().unix()]

const convertErrorMessage = (error, initData = {}) => {
    let fieldError = initData
    if (error?.code === '422') {
        fieldError = error?.message?.reduce((data, {param, msg}) => {
            const field = param?.split('.')
            if (!!data[0]?.key) {
                data = data.map(item => item.key === field[0] ? {...item, ...{errorText: msg}} : {...item, ...{errorText: null}})
            } else {
                data[field[0]] = data[field[0]] || {}
                field.length > 1 ? data[field[0]][field[1]] = msg : data[field[0]] = msg
            }
            return data
        }, initData)
    }
    return fieldError
}

const imageValidate = (file) => {
    const validExtension = RULES_IMAGE_UPLOAD.extensions
    const fileExtension = file?.name?.substring(file?.name?.lastIndexOf('.')).toLowerCase()

    if (validExtension.indexOf(fileExtension) < 0 && !!fileExtension) {
       return {isValid: false, message: `Invalid file selected, valid files are of ${validExtension.toString()} types`}
    } else if (file?.size > RULES_IMAGE_UPLOAD.size) {
        return {isValid: false, message: 'Image must be smaller than 10MB'}
    } else {
        return {isValid: true, message: ''}
    }

}

const downloadFileExport = (blobData, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blobData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
}

const isStaticPage = (pathName) => {
    const listStaticPage = [
        '/product/create-gift',
        '/product/import-product',
        '/avostore/import-avostore',
        '/voucher/generate-voucher',
        '/notification/create-notification'
    ]
    return listStaticPage.includes(pathName)
}

export {
    isLogin,
    doConvertNumberToRupiahFormat,
    convertDateToCustomFormat,
    convertUtcToCustomFormat,
    convertUnixToMoment,
    formatDateStartOfUnix,
    formatDateEndOfUnix,
    formatDateToReadable,
    getExpeditionLogo,
    statusRenderClassName,
    getStarRatingImage,
    textPercentage,
    checkFieldValidate,
    randomCode,
    getImageLocal,
    getMethodPayment,
    doConvertNumberWithPoint,
    dateRangeToday,
    convertErrorMessage,
    imageValidate,
    downloadFileExport,
    isStaticPage
}
