import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {setAvoStories2ArticlesDetail} from "../../../reducers/avo-stories-2.reducer";
import MultipleSelectArticles from "./multiple-select-articles";

const ArticlesForm = ({fieldList}) => {
    const dispatch = useDispatch()
    const {articlesDetail} = useSelector(state => state.avoStories2Reducer)

    const onChange = (key, value) => {
        dispatch(setAvoStories2ArticlesDetail({[key]: value}))
    }

    return (
        <MultipleSelectArticles item={fieldList} data={articlesDetail[fieldList?.key]} onChange={onChange} />
    )
}

export default ArticlesForm
