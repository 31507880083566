import React from "react";
import SearchComponent from "../../../components/search.component";
import ExportComponent from "../../../components/export.component";
import {downloadFileExport} from "../../../utils/general-variable";
import moment from "moment/moment";
import {useFetch} from "../../../services/useFetch";
import {API} from "../../../services/api";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {DATA_LIMIT_LIST} from "../../../utils/filter-list";
import PageLimitComponent from "../../../components/page-limit.component";
import {setToolsModalVisibility} from "../../../reducers/tools.reducer";

export const Filter = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {searchKey} = useSelector(state => state.toolsReducer)
    const {fetching: exportUserOrderList, isLoading: loadingExport} = useFetch({...API.EXPORT_USER_ORDER_LIST,  ...{initPathVariable: id}})

    const exportDataHandler = () => {
        const body = {
            search: searchKey
        }
        exportUserOrderList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `User-Order-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    return (
        <div className={"row items-center space-x-5"}>
            <SearchComponent placeholder={"cari nomor order"}/>
            <PageLimitComponent limitList={DATA_LIMIT_LIST}/>
            <div>
                <ExportComponent
                    wordingConfirmation={`Apakah anda yakin ingin melakukan export data order user ?`}
                    exportDataHandler={exportDataHandler}
                    isLoading={loadingExport}/>
            </div>
        </div>
    )
}
