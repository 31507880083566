export const bannerModel = {
    show_banner: true,
    item_list: [{
        id: 'banner',
        desktop_banner: null,
        mobile_banner: null,
        url: null
    }],
    delete_ids: []
}

export const introModel = {
    show_intro: true,
    thumbnail: null,
    title: null,
    description: null,
    button_title: null,
    button_direction_link: null
}

export const resultModel = {
    show_result: true,
    thumbnail: null,
    title: null,
    description: null,
    button_title: null,
    button_direction_link: null
}

export const benefitModel = {
    show_benefit: true,
    item_list: [{
        id: 'benefit',
        thumbnail: null,
        title: null,
        description: null
    }],
    delete_ids: []
}
