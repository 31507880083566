import SearchComponent from "../../components/search.component";
import FilterComponent from "../../components/filter.component";
import SortComponent from "../../components/sort.component";
import ExportComponent from "../../components/export.component";
import React from "react";
import DeleteConfirmationComponent from "../../components/delete-confirmation.component";
import {
    setToolsAlertMessage,
    setToolsAlertType,
    setToolsModalVisibility,
    setToolsReload
} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {SORT_LIST_NOTIFICATION} from "../../utils/sort-list";
import {setNotificationSelectedRowKeys} from "../../reducers/notification.reducer";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {downloadFileExport} from "../../utils/general-variable";
import moment from "moment/moment";

const NotificationFilter = () => {
    const dispatch = useDispatch()
    const {selectedRowKeysNotification} = useSelector(state => state.notificationReducer)
    const {fetching: postDeleteNotification} = useFetch(API.DELETE_NOTIFICATION)
    const {fetching: exportNotificationList, isLoading: loadingExport} = useFetch(API.EXPORT_NOTIFICATION_LIST)
    const defaultSort = SORT_LIST_NOTIFICATION?.find(item => item.isDefault)
    const getOrderBy = defaultSort?.key.split('_')
    const {
        orderBy,
        sort,
        searchKey,
        filter,
        reload
    } = useSelector(state => state.toolsReducer)

    const exportDataHandler = () => {
        const body = {
            search: searchKey,
            order_by: !!orderBy ? orderBy : getOrderBy[0],
            order_type: sort,
            filter_by_status: filter['status'] ?? [],
            notification_ids: selectedRowKeysNotification
        }
        exportNotificationList(body, '', {}, 'blob').then(res => {
            downloadFileExport(res, `Notification-List-Export-${moment().format()}.xlsx`)
        })
        dispatch(setToolsModalVisibility({export: false}))
    }

    const openDeleteModal = () => {
        dispatch(setToolsAlertMessage('Apakah anda ingin menghapus notification yang anda pilih?'))
        dispatch(setToolsModalVisibility({delete: true}))
    }

    const deleteNotificationHandler = () => {
        const body = {
            notification_ids: selectedRowKeysNotification
        }
        dispatch(setNotificationSelectedRowKeys([]))
        postDeleteNotification(body).then(() => {
            dispatch(setToolsModalVisibility({delete: false, alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Delete Notification Successfully'))
            dispatch(setToolsReload(!reload))
        }).catch(() => {
            dispatch(setToolsModalVisibility({delete: false}))
        })
    }

    const filterListNotification = [
        {
            title: 'status',
            list: [
                {
                    name: 'publish',
                }, {
                    name: 'draft',
                }
            ]
        }
    ]

    return (
        <div className={"row justify-between my-5"}>
            <div className={"row space-x-5"}>
                <SearchComponent placeholder={"Cari nama notifikasi..."}/>
                <FilterComponent filterList={filterListNotification} labelName={'name'} keyName={'name'}/>
                <SortComponent sortList={SORT_LIST_NOTIFICATION}/>
                <div>
                    <ExportComponent
                        wordingConfirmation={`Apakah anda yakin ingin melakukan export data notification ?`}
                        countSelected={selectedRowKeysNotification.length}
                        exportDataHandler={exportDataHandler}
                        isLoading={loadingExport}/>
                </div>
            </div>
            <div
                className={`text-white py-3 px-8 font-semibold rounded-lg ${selectedRowKeysNotification.length > 0 ? 'bg-battleship-green hover:bg-monstera cursor-pointer' : 'bg-fortress-grey cursor-not-allowed'}`}
                onClick={selectedRowKeysNotification.length > 0 ? openDeleteModal : undefined}>
                <p>Delete Notification</p>
            </div>
            <DeleteConfirmationComponent onDelete={deleteNotificationHandler}/>
        </div>
    )
}

export default NotificationFilter
