import {useDispatch, useSelector} from "react-redux";
import {FIELD_LIST_TERMS_AND_CONDITION} from "../../utils/input-field-list-static-page";
import InputComponent from "../../components/input.component";
import React, {useEffect} from "react";
import Item from "./item";
import {PlusCircleIconWhite} from "../../utils/image";
import TabToggleListComponent from "../../components/tab-toggle-list.component";
import {randomCode} from "../../utils/general-variable";
import {
    setMoreInfoDetail,
    setMoreInfoErrorMessage,
    setMoreInfoInitialMoreInfoDetail
} from "../../reducers/more-info.reducer";
import {cloneDeep} from "lodash";

const FaqPage = () => {
    const dispatch = useDispatch()
    const {moreInfoDetail, moreInfoErrorMessage} = useSelector(state => state.moreInfoReducer)
    const itemList = [...moreInfoDetail.itemList]
    let fieldList = FIELD_LIST_TERMS_AND_CONDITION

    const onChangeInput = (key, value) => {
        dispatch(setMoreInfoDetail({[key]: value}))
    }

    useEffect(() => {
        return () => {
            dispatch(setMoreInfoErrorMessage({}))
            dispatch(setMoreInfoInitialMoreInfoDetail())
        }
    }, [])

    const addItemHandler = () => {
        itemList.push({
            id: randomCode(5, 1, 'faq').toString(),
            title: null,
            slug: null,
            content: null
        })
        dispatch(setMoreInfoDetail({itemList: itemList}))
    }

    const dataChecking = (field, value) => {
        dispatch(setMoreInfoErrorMessage({}))
        const moreInfoDetailCopy = cloneDeep(moreInfoDetail)
        let error = {}
        field.forEach((item) => {
            switch (item.key) {
                case 'content':
                    if (!moreInfoDetailCopy[item.key]) {
                        error[item.key] = `${item.label} is required`
                    }
                    break
                default:
                    if (value[item.key]?.length > 0) {
                        if (item.required && !value[item.key][0]?.value) {
                            error[item.key] = `${item.label} is required`
                        } else {
                            moreInfoDetailCopy[item.key] = value[item.key][0]?.value || null
                        }
                    } else if (item.required && !value[item.key]?.value) {
                        error[item.key] = `${item.label} is required`
                    } else {
                        moreInfoDetailCopy[item.key] = value[item.key]?.value || null
                    }
            }
        })

        if (Object.keys(error).length === 0) {
            dispatch(setMoreInfoErrorMessage({}))
            return {isValid: true, faq: moreInfoDetailCopy}
        } else {
            dispatch(setMoreInfoErrorMessage(error))
            return {isValid: false, faq: moreInfoDetailCopy}
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        let formValue = document.getElementById("form").elements
        const {isValid, faq} = dataChecking([...fieldList], formValue)
        if (isValid) {
            console.log(faq)
        }
    }

    return (
        <div className={"col px-10"}>
            <form id={"form"} className={"space-y-4"}>
                {fieldList.map((item, index) => (
                    <InputComponent key={index} item={item} data={moreInfoDetail[item.key]} onChange={onChangeInput}
                                    error={moreInfoErrorMessage}/>
                ))}
                <div className={"col space-y-4 pt-6"}>
                    <p className={"font-Montserrat-Bold text-sm text-forged-steel"}>FAQ Item</p>
                    {
                        itemList.map((item, index) =>
                            <TabToggleListComponent
                                key={item.id}
                                index={index}
                                title={`Item ${index + 1}`}
                                content={<Item fieldList={fieldList} index={index}/>}
                                dataList={itemList}
                                setDataList={setMoreInfoDetail}
                            />
                        )
                    }
                    <div
                        className={"row space-x-2 items-center w-fit bg-battleship-green hover:bg-monstera py-2 px-6 rounded-lg cursor-pointer"}
                        onClick={addItemHandler}>
                        <img src={PlusCircleIconWhite} alt="PlusCircleIconWhite" className={"w-5"}/>
                        <p className={"font-semibold text-xs text-white"}>Add New Item</p>
                    </div>
                </div>
                <div className={"row font-semibold justify-center py-8"}>
                    <button onClick={(e) => onSubmitHandler(e)}
                            className={"w-60 py-2 bg-battleship-green hover:bg-gradient-to-b from-battleship-green to-monstera text-white rounded-lg"}>
                        Save All Changes
                    </button>
                </div>
            </form>
        </div>
    )
}

export default FaqPage
