import {useDispatch, useSelector} from "react-redux";
import {useFetch} from "../../services/useFetch";
import {API} from "../../services/api";
import {FIELD_LIST_INPUT_BENEFIT_LOYALTY_PROGRAM} from "../../utils/input-field-list-static-page";
import {setLoyaltyProgramBenefitDetail, setLoyaltyProgramErrorMessage} from "../../reducers/loyalty-program.reducer";
import {setToolsAlertMessage, setToolsAlertType, setToolsModalVisibility} from "../../reducers/tools.reducer";
import {convertErrorMessage} from "../../utils/general-variable";
import React, {useEffect} from "react";
import FormGeneralComponent from "../../components/static-page/form-general.component";

const Benefit = () => {
    const dispatch = useDispatch()
    const {benefitDetail, loyaltyProgramErrorMessage} = useSelector(state => state.loyaltyProgramReducer)
    const {fetching: getBenefit} = useFetch(API.LOYALTY_PROGRAM_GET_BENEFIT)
    const {fetching: updateBenefit, isLoading} = useFetch(API.LOYALTY_PROGRAM_UPDATE_BENEFIT)
    let fieldListInput = FIELD_LIST_INPUT_BENEFIT_LOYALTY_PROGRAM
    let showBenefit = {
        label: 'Benefit',
        key: 'show_benefit',
        activeText: 'Show',
        inActiveText: 'Hide',
        positionText: 'left'
    }

    const onChange = (key, value) => {
        dispatch(setLoyaltyProgramBenefitDetail({[key]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        updateBenefit(benefitDetail).then(response => {
            dispatch(setLoyaltyProgramErrorMessage({}))
            dispatch(setToolsModalVisibility({alert: true}))
            dispatch(setToolsAlertType('success'))
            dispatch(setToolsAlertMessage('Benefit Updated'))
            dispatch(setLoyaltyProgramBenefitDetail(response?.data))
        }).catch(err => {
            dispatch(setLoyaltyProgramErrorMessage(convertErrorMessage(err)))
        })
    }

    useEffect(() => {
        getBenefit().then(response => {
            dispatch(setLoyaltyProgramBenefitDetail(response?.data))
        })

        return () => {
            dispatch(setLoyaltyProgramErrorMessage({}))
        }
    }, [])

    return (
        <FormGeneralComponent
            data={benefitDetail}
            switchToggleField={showBenefit}
            fieldList={fieldListInput}
            onChange={onChange}
            onSubmitHandler={onSubmitHandler}
            inputClassName={'grid grid-cols-2 gap-x-16 gap-y-4'}
            errorMessage={loyaltyProgramErrorMessage}
            isLoading={isLoading}
        />
    )
}

export default Benefit
