import {useDispatch, useSelector} from "react-redux";
import TableComponent from "../../components/table-component";
import React from "react";
import {setNotificationSelectedRowKeys} from "../../reducers/notification.reducer";
import {Link} from "react-router-dom";
import TotalDataComponent from "../../components/total-data.component";
import {convertDateToCustomFormat, statusRenderClassName} from "../../utils/general-variable";
import {CheckIcon, MinusIcon} from "../../utils/image";

const NotificationList = ({dataSource, isLoading}) => {
    const dispatch = useDispatch()
    const {selectedRowKeysNotification} = useSelector(state => state.notificationReducer)

    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        }, {
            name: 'Title',
            classNameHeader: 'pl-5',
            render: (record) => (
                <Link to={`/notification/notification-list/update-notification/${record.id}`}>
                    <p className={"pl-5 capitalize text-monstera hover:font-Montserrat-Bold hover:underline"}>{record.title}</p>
                </Link>
            )
        }, {
            name: 'Type Broadcast',
            dataIndex: 'type'
        }, {
            name: 'Receiver',
            dataIndex: 'receiver'
        }, {
            name: 'Routing',
            dataIndex: 'routing'
        }, {
            name: 'Schedule',
            dataIndex: 'scheduled_at',
            render: (scheduled_at) => (
                <p>{!!scheduled_at && convertDateToCustomFormat(scheduled_at, 'YYYY/MM/DD HH:mm')}</p>
            )
        }, {
            name: 'Sent Status',
            dataIndex: 'is_sent',
            classNameHeader: 'text-center',
            render: (is_sent) => (
                <img src={is_sent ? CheckIcon : MinusIcon} alt="sent_status" className={"w-5 mx-auto"}/>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            classNameHeader: 'text-center',
            render: (status) => (
                <div className={"row justify-center"}>
                    <div
                        className={`w-20 py-1 font-Montserrat-Bold capitalize rounded-md 
                        ${statusRenderClassName(status).className}`}>
                        <p className={"text-center"}>{statusRenderClassName(status).text}</p>
                    </div>
                </div>
            )
        },
    ]

    const onChangeSelectedRowKeys = (array) => {
        dispatch(setNotificationSelectedRowKeys(array))
    }

    return (
        <div className={"col space-y-4"}>
            <TotalDataComponent subject={"Notifications"}/>
            <TableComponent
                columns={columns}
                tableClassName={"font-Regular"}
                headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                selectedRowKeys={selectedRowKeysNotification}
                onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                dataSource={dataSource}
                pagination={true}
                loading={isLoading}
            />
        </div>
    )
}

export default NotificationList
