export const MoreInfoModel = {
    title: null,
    slug: null,
    content: null,
    thumbnail: null,
    itemList: [{
        id: 'faq',
        title: null,
        slug: null,
        content: null
    }]
}

export const termsAndConditionModel = {
    title: null,
    description: null
}
