import {createSlice} from '@reduxjs/toolkit'

export const avoStoriesUserSlice = createSlice({
    name: 'avoStoriesUser',
    initialState: {
        selectedRowKeysAvoStoriesUser: []
    },
    reducers: {
        setAvoStoriesUserSelectedRowKeys: (state, action) => {
            state.selectedRowKeysAvoStoriesUser = action.payload
        }
    },
})

export const {
    setAvoStoriesUserSelectedRowKeys
} = avoStoriesUserSlice.actions
const avoStoriesUserReducer = avoStoriesUserSlice.reducer

export default avoStoriesUserReducer
